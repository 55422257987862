import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal, { swalError } from "./../../../components/swal";
import {
  sendMultiPartData,
  sendData,
  getData,
  pureGetData,
} from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";
import {
  mCEP,
  mCPF,
  mTel,
  mDDD,
  formatCurrency,
  formatFloat,
  mDate,
  getCookie,
  HORARIOLIST,
  Aguarde,
  width
} from "../../../helpers/general";
import {
  validateCPF,
  validateDate,
  validateEmail,
} from "../../../helpers/validation";
import { connect } from "react-redux";
import { isInvalid } from "../../../helpers/core";

import Data from "../simulator/data";
import Address from "../simulator/address";
import Bank from "../simulator/bank";
import Complement from "../simulator/complement";
import Contact from "../simulator/contact";
import ComplementSicoob from "../simulator/complement_sicoob";
import ComplementOriginal from "../simulator/complement_original";

import * as moment from "moment";

let tipos = [
  {
    id: "CLIENTE",
    nome: "Cliente",
  },
  {
    id: "OPERADOR",
    nome: "Operador",
  },
  {
    id: "AGENTE",
    nome: "Agente",
  },
  {
    id: "ADMINISTRADOR",
    nome: "Administrador",
  },
];

const swalOptions = {
  title: "Deseja realmente excluir o registro selecionado?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

const warningIni = {
  cpf: 0,
  nome: 0,
  ddn: 0,
  data_exp: 0,
};

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    pessoas,
    actualPage,
    sortArrObj,
    handlePage,
    callback,
    setClickedTr,
    clickedTr,
    setPessoas,
    handlePesquisar,
    // noLimit,
    // setNolimit,
    loading,
    handleLimpar
  } = props;

  const [show, setShow] = useState(false);
  const [gravando, setGravando] = useState(false);
  const [method, setMethod] = useState("POST");
  const [key, setKey] = useState("data");
  const [data, setData] = useState(getDataIni());
  const [warning, setWarning] = useState(warningIni);
  const [msgErro, setMsgErro] = useState("");
  const [editando, setEditando] = useState(false);
  const [getCep, setGetCep] = useState(0);
  const [modal, setModal] = useState("");

  function getDataIni() {
    return {
      nome: "",
      cpf: "",
      civil: "casado",
      sexo: "masculino",
      ddn: "",
      matricula: "",
      matricula_pensionista: "",
      senha: "",
      mae: "",
      ufn: "AC",
      cidaden: "",
      salario: "0",
      cep: "",
      t_log: "rua",
      logradouro: "",
      complemento: "",
      numero: "",
      bairro: "",
      uf: "AC",
      cidade: "",
      nome_pai: "",
      tipo_renda: props.tipo_renda && props.tipo_renda[0].CODIGO,
      ocupacao: props.ocupacao && props.ocupacao[0].CODIGO,
      profissao: props.profissao && props.profissao[0].CODIGO,
      escolaridade: props.escolaridade && props.escolaridade[0].CODIGO,
      atividade: props.atividade && props.atividade[0].CODIGO,
      tax_ir: props.tax_ir && props.tax_ir[0].CODIGO,
      pep: props.pep && props.pep[0].SIGLA,
      situacao_documento:
        props.situacao_documento && props.situacao_documento[0].CODIGO,
      permitir_bacen: props.permitir_bacen && props.permitir_bacen[0].CODIGO,
      permitir_pcam: props.permitir_pcam && props.permitir_pcam[0].CODIGO,
      conjuge_nome: "",
      escola: "",
      patrimonio: 0,
      data_situacao: null,
      apelido: "",
      conjuge_cpf: "",
      conjuge_regime: props.conjuge_regime && props.conjuge_regime[0].SIGLA,
      referencia1_nome: "",
      referencia1_telefone: "",
      referencia2_nome: "",
      referencia2_telefone: "",
      dependentes: 0,
      telefone_rh: "",
      data_admissao: null,
      telefone_comercial: "",

      ip: getCookie({ label: "ip" }),
    };
  }

  const handleClose = () => {
    setWarning(warningIni);
    setShow(false);
    setKey("data");
  };

  useEffect(() => {
    let _warning = warning;
    for (let field in data) if (data[field]) _warning[field] = 0;

    setWarning(_warning);
  }, [data]);

  const handleEdit = async (id) => {
    getData({ uri: uriList("pessoas") + "/" + id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        AtribuirDados(res);

        setEditando(true);
        setShow(true);
      })
      .catch((e) => {
        console.log("erro: ", e);
        setEditando(false);
        setShow(false);
        return false;
      });
  };

  const handleDelete = async (isConfirm, swal, id) => {
    if (isConfirm) {
      await sendData({
        uri: uriList("pessoas") + "/" + id,
        content: {},
        method: "DELETE",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          callback();
        })
        .catch((e) => {
          console.log(e);
          swalError({ title: `Erro ao excluir pessoa!` });
          // alert('Erro ao excluir pessoa.')
        });
    }
  };

  const handlePost = async () => {
    let invalid = isInvalid(data);
    console.log("pessoas.handlePost(Dados): \n", data)
    if (invalid) {
      setWarning(invalid);
      console.log("pessoas.handlePost(Campos inválidos): \n", invalid)
      swalError({ title: `Preencha os campos obrigatórios!` });
    }
    else {
      setGravando(true);
      let dObj = new FormData();

      for (var campo in data) {
        if (data[campo]) {
          if (["patrimonio", "salario"].indexOf(campo) >= 0)
            dObj.append(campo, formatFloat(data[campo]));
          else dObj.append(campo, data[campo]);
        }
      }

      await sendMultiPartData({
        uri: uriList("pessoas") + (method === "PUT" ? "/" + data.id : ""),
        content: dObj,
        method,
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200)
            throw new Error(res.message[0].message);
          callback();
          setShow(false);
          setGravando(false);
          setKey("data");
          setData({ ...data, ["senhaUser"]: null });
        })
        .catch((e) => {
          setGravando(false);
          setKey("data");
          // alert(e)
          swalError({
            title: `Erro ao gravar pessoa! Verifique os campos obrigatórios e campos de data!`,
          });
        });
      // }
      // else {
      //     console.log(invalid)
      //     setWarning(invalid)
      //     swalError({ title: "Erro no preenchimento dos campos" })
      // }
    }
  };

  const handleNew = () => {
    const dataIni = {
      id: 0,
      nome: "",
      cpf: "",
      tipo: "CLIENTE",
      civil: "solteiro",
      sexo: "masculino",
      status: "ATIVO",
      ddn: null,
      matricula: "",
      matricula_pensionista: "",
      horario: "",
      mae: "",
      cep: "",
      t_log: "RUA",
      lograduro: "",
      complemento: "",
      numero: "",
      endereco: "",
      bairro: "",
      uf: "AC",
      cidade: "",
      celular: "",
      telefone: "",
      email: "",
      obs: "",
      banco: "",
      agencia: "",
      conta: "",
      t_conta: "corrente",
      t_doc: "CNH",
      num_doc: "",
      orgao: "",
      uf_exp: "AC",
      data_exp: null,
      lotacao: "",
      salario: "0,00",
      cidaden: "",
      ufn: "AC",
      senhaUser: "",
    };

    setEditando(false);
    setData(dataIni);
    setShow(true);
  };

  const handleChange = (e, userData) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;
    // console.log(`Pessoas - handleChange(${name}): `, value);
    // if (name !== "email" && value) value = value.toUpperCase();
    if (target.name === "email") {
      setData({ ...data, [target.name]: value.toLowerCase() });
      console.log("Pessoas.handleChange(target): ", target.value)
      if (
        target.value &&
        !validateEmail(target.value, props.user, data)
      ) {
        console.log("Pessoas.handleChange(email inválido): ", target.value)
        setWarning({ ...warning, email: 1 });
      }
    } else if (target.name === "dependentes") {
      setData({ ...data, [target.name]: value.replace(/\D/g, "") });
    } else if (
      ["ddn", "data_exp", "data_situacao", "data_admissao"].indexOf(name) >= 0
    ) {
      setData({ ...data, [name]: mDate(e.currentTarget.value) });
    } else if (
      [
        "referencia1_telefone",
        "referencia2_telefone",
        "telefone_rh",
        "telefone_comercial",
        "celular",
        "telefone",
      ].indexOf(target.name) >= 0
    )
      setData({ ...data, [name]: mTel(value) });
    else if (["salario", "patrimonio"].indexOf(name) >= 0)
      setData({ ...data, [name]: formatCurrency(value) });
    else if (name == "cep") setData({ ...data, [name]: mCEP(target.value) });
    else if (["conjuge_cpf", "cpf"].indexOf(name) >= 0) {
      let cpf = mCPF(e.currentTarget.value);
      if (cpf.length <= 14) {
        setData({ ...data, [target.name]: cpf });
        setWarning({
          ...warning,
          [target.name]: cpf.length === 14 && !validateCPF(cpf) ? 1 : 0,
        });
      }
    } else if (name === "uf")
      setData({
        ...data,
        [name]: value,
        cidade: getFirstCity(value),
      });
    else if (name === "ufn")
      setData({
        ...data,
        [name]: value,
        cidaden: getFirstCity(value),
      });
    else setData({ ...data, [name]: value });

    setWarning({ ...warning, [target.name]: 0 })
    // console.log(`***Pessoas - handleChange(${name}): `, value);
  };

  function handleDate(e) {
    let nums = /[0-9]/;
    let target = e.currentTarget;
    let ddn = mDate(target.value);

    handleChange(e, ddn);
    setWarning({ ...warning, [target.name]: 0 });

    if (nums.test(target.value)) {
      // console.log("Validate - date", target);

      if (ddn.length === 10 && !validateDate(ddn))
        setWarning({ ...warning, [target.name]: 1 });

      return false;
    }

    return false;
  }

  function handleCEP(e) {
    let nums = /\d/;
    let target = e.currentTarget;

    if (nums.test(target.value)) {
      let cep = mCEP(target.value);
      target.name === "cep" ? handleChange(e, cep) : handleChange(e);
      target.name === "cep" && setGetCep(1);

      return false;
    }

    if (data[target.name].length === 1 && target.value === "") {
      handleChange(e);

      return false;
    }

    return false;
  }

  const handleBlur = (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let valor = target.value && target.value.toUpperCase();
    setWarning({ ...warning, [name]: 0 });
    console.log(`name: ${name}, valor: ${valor}`);
    handleChange(e);

    if (name === "cpf" && valor && valor.length > 0) {
      let isValid = validateCPF(valor);

      if (!isValid) {
        setWarning({ ...warning, ["cpf"]: 1 });
        setMsgErro("CPF Inválido!");
      } else if (!editando && !data["nome"]) {
        sendData({
          uri: uriList("pessoa"),
          content: { cpf: valor },
          signal: null,
          method: "POST",
        })
          .then((res) => {
            if (res.status && res.status > 200) throw new Error(res.message);

            if (res.cpf) {
              setEditando(true);
              AtribuirDados(res);
            }
          })
          .catch((e) => { });
      }
    } else if (name === "ddn" && valor) {
      if (!validateDate(valor)) setWarning({ ...warning, ["ddn"]: 1 });
    } else if (
      name === "cep" &&
      valor &&
      valor.length > 0 &&
      (!data || !data["logradouro"])
    )
      setGetCep(1);
  };

  const AtribuirDados = (res) => {
    let _data = data;
    for (var campo in res) {
      if (["banco"].indexOf(campo) >= 0) {
        console.log("pessoas.AtribuirDados: ", _data[campo])
        if (res[campo])
          _data[campo] = String(res[campo]).padStart(3, '0')
      }
      else if (["salario", "patrimonio"].indexOf(campo) >= 0)
        _data[campo] = formatCurrency(formatFloat(res[campo]));
      else if (
        ["ddn", "data_exp", "data_situacao", "data_admissao"].indexOf(campo) >=
        0
      ) {
        let date = new Date(res[campo]);
        if (!isNaN(date)) date = date.toLocaleDateString();
        else date = mDate(res[campo]);

        _data[campo] = date;
      } else _data[campo] = res[campo];
    }

    setData(_data);
  };

  function getFirstCity(value) {
    let result;
    for (let estado of props.estados.estados) {
      if (estado.sigla === value && estado.cidades && estado.cidades.length > 0)
        result = estado.cidades[0];
    }

    return result;
  }

  const openInfoModal = ({ title, text, list, bold }) => {
    setModal(
      <Modal
        title={title}
        text={text}
        list={list}
        bold={bold}
        onClick={() => setModal("")}
      />
    );
  };

  useEffect(() => {
    console.log("pessoas(components).useEffect: ", props.estados)
  }, [])

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (data && data.cep && data.cep.length === 9 && getCep) {
      pureGetData({
        uri: `https://viacep.com.br/ws/${data.cep.replace("-", "")}/json/`,
        signal,
      })
        .then((resData) => {
          setGetCep(0);

          if (!resData)
            throw new Error("CEPError: CEP não encontrado pelo servidor");

          let logradouro = resData.logradouro;

          logradouro = logradouro.replace(" ", ",").split(",");

          setData({
            ...data,
            bairro: resData.bairro,
            cidade: resData.localidade,
            logradouro: logradouro[1],
            t_log: logradouro[0].toUpperCase(),
            uf: resData.uf,
          });
        })
        .catch((e) => {
          setGetCep(0);
        });
    }

    return function cleanup() {
      abortController.abort();
    };
  }, [getCep]);

  return (
    <>
      {loading && <Aguarde legenda={"Aguarde, carregando dados..."} />}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        animation={false}
      // backdrop="static"
      >
        {!gravando && (
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Cadastro de Pessoas</span>
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="maxWidth750">
          {gravando ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              Aguarde, gravando registro...
            </div>
          ) : (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              // style={{ border: "none" }}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="data" title="Dados Pessoais">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <Data
                    estados={props.estados}
                    data={data}
                    change={handleChange}
                    CPF={handleChange}
                    onBlur={handleBlur}
                    ddn={handleChange}
                    nome={handleChange}
                    warning={warning}
                    alternate={msgErro}
                    alternateMessage={msgErro}
                  />
                </div>
              </Tab>

              <Tab eventKey="address" title="Endereço">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <Address
                    estados={props.estados}
                    logradouros={props.logradouros}
                    data={data}
                    changeData={handleChange}
                    CEP={handleCEP}
                    tipo_endereco={props.tipos_endereco}
                    warning={warning}
                  />
                </div>
              </Tab>

              <Tab eventKey="contact" title="Contato">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <Contact
                    estados={props.estados}
                    data={data}
                    changeData={handleChange}
                    changeTel={handleChange}
                    changeDDD={handleChange}
                    warning={warning}
                    alternate={msgErro}
                    horarioList={HORARIOLIST}
                    alternateMessage={msgErro}
                    email={handleChange}
                  />
                </div>
              </Tab>

              <Tab eventKey="complement" title="Complemento">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <Complement
                    estados={props.estados}
                    data={data}
                    changeDoc={handleChange}
                    warning={warning}
                    nums={handleDate}
                    alpha={handleChange}
                    alternate={msgErro}
                    alternateMessage={msgErro}
                  />
                </div>
              </Tab>

              <Tab eventKey="bank" title="Dados Bancários">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <Bank
                    data={data}
                    handleChange={handleChange}
                    modal={openInfoModal}
                    warning={warning}
                    nums={handleChange}
                    alpha={handleChange}
                    bancos={props.bancos}
                  />
                </div>
              </Tab>

              <Tab eventKey="complementSicoob" title="Complemento Sicoob">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <ComplementSicoob
                    data={data}
                    handle={handleChange}
                    warning={warning}
                    handleDate={handleDate}
                    alternate={msgErro}
                    alternateMessage={msgErro}
                    escolaridade={props.escolaridade}
                    profissao={props.profissao}
                    conjuge_regime={props.conjuge_regime}
                  />
                </div>
              </Tab>

              <Tab eventKey="complementOriginal" title="Complemento Original">
                <div
                  className="form"
                  style={{
                    overflow: "hidden",
                    border: "0.5px solid",
                    borderTop: "0",
                    borderColor: "lightGray",
                    padding: "10px",
                    minHeight: "400px",
                  }}
                >
                  <ComplementOriginal
                    data={data}
                    handle={handleChange}
                    warning={warning}
                    handleDate={handleDate}
                    alternate={msgErro}
                    alternateMessage={msgErro}
                    tipo_renda={props.tipo_renda}
                    ocupacao={props.ocupacao}
                    profissao={props.profissao}
                    escolaridade={props.escolaridade}
                    atividade={props.atividade}
                    tax_ir={props.tax_ir}
                    pep={props.pep}
                    situacao_documento={props.situacao_documento}
                    permitir_bacen={props.permitir_bacen}
                    permitir_pcam={props.permitir_pcam}
                  />
                </div>
              </Tab>
            </Tabs>
          )}
        </Modal.Body>
        {!gravando && (
          <Modal.Footer>
            <Button color="success" onClick={handlePost}>
              Gravar
            </Button>
            <Button color="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <div className="col-md-4 py-1 px-0">
        <button
          onClick={() => handleNew()}
          className="btn btn-sm text-light"
          title="Nova Pessoa"
          style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
        >
          <i className="mdi mdi-plus-box" />
        </button>
        <button
          onClick={() => window.print()}
          className="btn btn-sm text-light"
          title="Imprimir"
          style={{ backgroundColor: "#00bdff" }}
        >
          <i className="mdi mdi-printer" />
        </button>
      </div>

      <div className="row">
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Nome</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="nome"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">CPF</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="cpf"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Email</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="email"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Cidade</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="cidade"
          />
        </div>
        <div className={`col-md-1`}>
          <label className="m-0 font-90">UF</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="uf"
          />
        </div>
        <div className={`col-md-1`}>
          <label className="m-0 font-90">Tipo</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="tipo"
          />
        </div>

        <div className={`col-md-1`}>
          <label className="m-0 font-90">Status</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="status"
          />
        </div>
        <div className={`col-md-1`}>
          <button
            onClick={handleLimpar}
            style={{ width: "100%", height: width() == "mobile" ? "100%" : "60%", marginTop: width() == "mobile" ? "10px" : "23px" }}
            className="btn btn-sm btn-danger"
          >
            Limpar
          </button>
        </div>
      </div>
      <div className="row py-2" style={{ marginTop: width() == "mobile" ? "10px" : "" }}>

        {/* <div className={`col-md-2`}>
          <label className="m-0 font-90">Exibir Todos</label>
          <FilterSelect
            detailed={{ noLimit: ["Sim", "Nao"], }}
            handleOption={setNolimit}
            value={noLimit}
            ocultarTodos={true}
            oLabel="noLimit"
          />
        </div> */}
        <div className={`col-md-4`}>
          <InputLabelAlert
            className="m-0 font-90"
            type="text"
            label="Nome/CPF/Email"
            id="pessoa_filter"
            cifrao={false}
            value={props.pessoa_filter}
            // onChange={props.handlePessoa}
            handle={props.handlePessoa}
            focus={true}
          />
        </div>
        <div className={`col-md-1`}>
          <button
            onClick={handlePesquisar}
            style={{ width: "100%", height: width() == "mobile" ? "100%" : "70%", marginTop: width() == "mobile" ? "10px" : "20px" }}
            className="btn btn-sm btn-secondary"
          >
            Pesquisar
          </button>
        </div>
      </div>

      <table className="table table-bordered table-striped" style={{ marginTop: width() == "mobile" ? "10px" : "" }}>
        <thead>
          <tr
            title="Clique para ordenar"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <th scope="col" className="font-80">
              #
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Nome
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("cpf", "string")}
            >
              CPF
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("email", "string")}
            >
              Email
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("tipo", "string")}
            >
              Tipo
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("status", "string")}
            >
              Status
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("cidade", "string")}
            >
              Cidade
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("uf", "string")}
            >
              UF
            </th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {pessoas && pessoas.length > 0 ? (
            pessoas.map(
              (pessoas, i) =>
                i >= actualPage * 10 &&
                i < (actualPage + 1) * 10 && (
                  <tr
                    key={i}
                    className={`${clickedTr === pessoas.id ? "bg-secondary text-light" : ""
                      }`}
                    onClick={() => {
                      setClickedTr(pessoas.id);
                    }}
                    onDoubleClick={() => {
                      handleEdit(pessoas.id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClickedTr(pessoas.id);
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{pessoas.nome}</td>
                    <td className="table-vertical ">{pessoas.cpf}</td>

                    <td>{pessoas.email}</td>
                    <td>{pessoas.tipo}</td>
                    <td>{pessoas.status}</td>
                    <td>{pessoas.cidade}</td>
                    <td>{pessoas.uf}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-warning"
                        title="Altera um registro"
                        onClick={(e) => handleEdit(pessoas.id)}
                      >
                        <i className="mdi mdi-file-edit-outline"></i>
                      </button>
                      <Swal
                        options={swalOptions}
                        id={pessoas.id}
                        title="Exclusão de Registro"
                        callback={handleDelete}
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                      >
                        <i className="mdi mdi-delete"></i>
                      </Swal>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td>Sem dados</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-left">
              Total de Pessoas: {pessoas && pessoas.length}
            </td>

            <td colSpan="8"></td>
          </tr>
        </tfoot>
      </table>

      <Pagination
        detailed={pessoas}
        actual={actualPage}
        changePage={handlePage}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
  };
}

// export default Form;

export default connect(mapStateToProps)(Form);
