import React from "react";
import DashboadDataProvider from './hooks/DashboardDataContext'
import Dashboard from './index'

function Charts({ ...props }) {
    return (    
        <DashboadDataProvider>
            <Dashboard />
        </DashboadDataProvider>
    );
}

export default Charts;
