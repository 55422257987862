import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/complement";

import { Buttons } from "../../components/layout";
import { cidades } from "../../helpers/cidades";
import { mDate, getCookie } from "../../helpers/general";
import { sendData, setSession } from "../../helpers/auth";
import { validateMonthDate } from "../../helpers/validation";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";
import { setTDoc } from "../../store/actions/dados";

import { connect } from "react-redux";

function Complement({ ...props }) {
  let estados = cidades();

  const [data, setData] = useState({
    t_doc: "",
    num_doc: "",
    orgao: "",
    uf_exp: "",
    data_exp: "",
    id: props.cliente,
    ip: getCookie({ label: "ip" }),
    proposta_uuid: props.proposta,
  });

  const [showAlert, setAlert] = useState({
    t_doc: 0,
    num_doc: 0,
    orgao: 0,
    uf_exp: 0,
    data_exp: 0,
  });

  const [alternate, setAlternate] = useState({
    data_exp: 0,
  });

  const [alternateMessage, setAlternateMessage] = useState({
    data_exp: "",
  });

  function handleChange(e, innerData) {
    let target = e.currentTarget;
    let value = innerData || target.value;

    setData({ ...data, [target.name]: value });

    if (
      data[target.name] &&
      data[target.name].length === 0 &&
      target.value === ""
    ) {
      setAlert({ ...showAlert, [target.name]: 1 });
    } else {
      clearAlert(e);
    }
  }

  function handleDate(e) {
    let nums = /[0-9]/;

    if (nums.test(e.currentTarget.value)) {
      let ddn = mDate(e.currentTarget.value);

      handleChange(e, ddn);
      setAlternate(0);

      if (ddn.length === 10 && !validateMonthDate(ddn)) {
        setAlternate({
          data_exp: 1,
        });

        setAlternateMessage({
          data_exp: "Data inválida",
        });
      }

      return false;
    }

    if (data.data_exp.length === 1 && e.currentTarget.value === "") {
      handleChange(e);
      return false;
    }

    return false;
  }

  function handleAlpha(e) {
    let target = e.currentTarget;
    let nums = /[0-9]/;

    if (nums.exec(target.value) === null) {
      handleChange(e);

      return false;
    }

    return false;
  }

  function clearAlert(e) {
    let target = e.currentTarget;

    setAlert({ ...showAlert, [target.name]: 0 });
  }

  async function submit(callback) {
    let invalid = isInvalid(data);

    if (!invalid) {
      let newData = Object.assign(data);

      for (let n in newData) {
        if (newData[n] && typeof newData[n] === "string")
          newData[n] = newData[n].toUpperCase();
      }

      await sendData({
        uri: uriList("pessoas"),
        content: { ...newData, proposta_uuid: props.proposta, },
        method: "POST",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setSession({
            obj: newData,
            key: "complemento",
          });

          console.log("simulator.complement.submit(0)");
          props.toggleStep(1);
          console.log("simulator.complement.submit(1)");
          props.alterarTDoc(data?.t_doc);
          console.log("simulator.complement.submit(2)");
        })
        .catch((e) => {
          if (callback) callback(false);

          alert("simulator.complement.submit(erro)");
          console.log(e);
        });

      for (let d in data) props.clienteDados[d] = data[d];

      return false;
    } else if (callback) callback(false);

    setAlert(invalid);
  }

  useEffect(() => {
    // if ("data_exp" == d)
    //   setData((previous) => ({
    //     ...previous,
    //     [d]: mDate(props.clienteDados[d]),
    //   }));
    // else
    for (let d in data)
      if (props.clienteDados[d])
        if ("data_exp" == d) {
          let date = new Date(props.clienteDados[d]);
          date = date.toLocaleDateString();

          setData((previous) => ({ ...previous, [d]: date }));
        } else
          setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));
  }, []);

  return (
    <fieldset className="col-12"
      style={{
        overflowY: props.preencherDados ? 'auto' : 'visible',
        maxHeight: props.preencherDados ? '700px' : 'none',
      }}>
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>
      <div className="form">
        <Form
          estados={estados}
          data={data}
          changeDoc={handleChange}
          warning={showAlert}
          nums={handleDate}
          alpha={handleAlpha}
          alternate={alternate}
          alternateMessage={alternateMessage}
        />
      </div>

      <Buttons
        goBack={() => goBack(data, "complemento", props.toggleStep)}
        submit={submit}
      />
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarTDoc(t_doc) {
      dispatch(setTDoc(t_doc));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Complement);
