import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { uriList } from "../../../../helpers/config";
import { sendData, getData } from "../../../../helpers/auth";
import Swal from "../../../swal";
import { confirmSwal } from "../../../swal";
import {
    getCookie,
    Aguarde,
    DefaultOrder,
    sortByString,
    sortByNumber,
    compareDate,
} from "../../../../helpers/general";
import './checklist.css'

import { Pagination, FilterSelect, } from "../../../layout";

const swalOptions = {
    title: "Deseja excluir o registro?",
    icon: "warning",
    buttons: {
        cancel: {
            text: "Não",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
        },
        confirm: {
            text: "Sim",
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true,
        },
    },
};

const dataIni = {
    // data: new Date().toISOString().substr(0, 10),
    descricao: "",
    convenio_id: null,
    tabela_id: null,
    operacao: "",
    valoracima: 0,
    aplicacao: "",
    impeditivo: 'nao',
    conformidade: 'nao',
    ip: getCookie({ label: "ip" }),
}

const operacoes = [
    { operacao: "CREDITO PESSOAL" },
    { operacao: "FGTS" },
    { operacao: "REFINANCIAMENTO" },
    { operacao: "PORTABILIDADE" },
    { operacao: "CONTRATO NOVO" },
]
const aplicacoes = [
    { descricao: "Solicitar Pagto Saldo", value: 'pagarPortabilidade' },
    { descricao: "Enviar para Banco", value: 'enviarParaBanco' },
]

const IncluirChecklist = ({ ...props }) => {
    const [warning, setWarning] = useState({ descricao: 0, observacao: 0 })
    const [show, setShow] = useState(false);
    const [gravando, setGravando] = useState(false);
    const [checkLists, setCheckLists] = useState([]);
    const [checkListsC, setCheckListsC] = useState([]);
    const [ascDesc, setAscDesc] = useState(true);
    const [clickedTr, setClickedTr] = useState("");
    const [filterOptions, setFilterOptions] = useState(false);
    const [actualPage, setActualPage] = useState(0);
    const [data, setData] = useState({ ...dataIni });
    const [convenios, setConvenios] = useState(null);
    const [tabelas, setTabelas] = useState(null);
    const [checkPoints, setCheckPoints] = useState(null)
    const [checkPointsG, setCheckPointsG] = useState(null)
    const [itens, setItens] = useState([])
    const [item, setItem] = useState(null)
    const [incluido, setIncluido] = useState(true)


    const [showAlert, setAlert] = useState({
        file: 0,
    });

    useEffect(() => {
        getCheckLists();
    }, []);

    function handleClose(e, close, update) {
        if (e) e.preventDefault();
        setItens([])
        setShow(false);

        if (props.onCloseEv && close) props.onCloseEv(close);

        if (update)
            getCheckLists();
    }

    const handlePage = (e) => {
        setActualPage(e.currentTarget.value);
    };

    function validarDados() {
        let _warning = { descricao: 0, observacao: 0 }

        if (!data.descricao)
            _warning = { descricao: 1 }

        // if (!data.observacao)
        //     _warning = { ..._warning, observacao: 1 }

        console.log("IncluirChecklist.validarDados():", _warning)
        setWarning(_warning)

        return !_warning.descricao && !_warning.observacao;
    }

    function handleChange(e) {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    async function handlePost(e) {
        e.preventDefault();
        console.log("Auxiliar.handlePost(1):", data);

        if (validarDados()) {
            setGravando(true);
            console.log("Auxiliar.handlePost(2): ", itens);

            sendData({
                uri: uriList('checklist'),
                content: { ...data, itens },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    setGravando(false);
                    console.log("Auxiliar.handlePost(3)");
                    handleClose(null, false, true);
                })
                .catch((e) => {
                    setGravando(false);
                    console.log("Auxiliar.handlePost(4)");
                    // console.log("Deu errado")
                    console.log(e);
                    return false;
                });
        }
    }

    const getCheckLists = async () => {
        await getData({ uri: uriList("checklist") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                DefaultOrder(
                    "checklist",
                    res.map((checklists) => checklists),
                    setCheckLists
                );
                createFilter(res.map((checklists) => checklists));
                setCheckListsC(res);
            })
            .catch((e) => {
                return false;
            });
    };

    const getDados = async (checklist) => {
        if (checklist?.id)
            await sendData({
                uri: uriList("checklistItens"),
                content: { checklist_id: checklist?.id },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    if (res.status && res.status > 200) throw new Error();
                    console.log("checklist.handleEdit(itens):", res)
                    setItens(res);
                })
                .catch((e) => {
                    console.log("checklist.handleEdit(itens - error):", e)
                    return false;
                });

        if (tabelas)
            return

        await getData({ uri: uriList("convenios") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                setConvenios(res);
            })
            .catch((e) => {
                return false;
            });

        await getData({ uri: uriList("tabelas") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                setTabelas(res);
            })
            .catch((e) => {
                return false;
            });

        await getData({ uri: uriList("checkpoint") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                setCheckPoints(res);
                setCheckPointsG(res);
                console.log("checklist.getDados(checkpoints):", res)
            })
            .catch((e) => {
                return false;
            });
    };

    const createFilter = (obj) => {
        let newObj = {
            descricao: [],
            tipo: [],
            funcao: [],
            impeditivo: [],
            conformidade: [],
        };

        for (let o of obj) {
            for (let i in o) {
                if (newObj[i]) {
                    newObj[i].push(o[i]);
                }
            }
        }

        for (let i in newObj) {
            newObj[i] = [...new Set(newObj[i])];
        }

        setFilterOptions(newObj);
    };

    const sortArrObj = (key, type) => {
        let arr = [...checkLists];
        let asc = !ascDesc;

        arr = arr.sort((a, b) => {
            if (type === "string") {
                if (asc) {
                    setAscDesc(asc);
                    return sortByString(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByString(b, a, key, type);
                }
            } else if (type === "numeric") {
                if (asc) {
                    setAscDesc(asc);
                    return sortByNumber(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByNumber(b, a, key, type);
                }
            } else {
                if (asc) {
                    setAscDesc(asc);
                    return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
                } else {
                    setAscDesc(asc);
                    return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
                }
            }
        });

        setCheckLists(arr);
    };

    const handleNew = async () => {
        setData(dataIni)
        getDados();
        setShow(true);
    };

    const handleEdit = async (id) => {
        console.log("checklist.handleEdit(1):", id)
        getData({ uri: uriList("checklist") + "/" + id })
            .then(async (res) => {
                if (res.status && res.status > 200) throw new Error();

                console.log("checklist.handleEdit(ok):", res)
                getDados(res);
                setData(res)
                setShow(true);
            })
            .catch((e) => {
                return false;
            });
    };

    const handleOption = (e) => {
        let label = e.currentTarget.dataset.label || e.currentTarget.name;
        let value = e?.currentTarget?.value
        let newDetailed;

        if (value !== "todos" && value !== "" && value !== "Todos") {
            newDetailed = checkListsC.filter((proposta) => {
                return proposta[label] === value;
            });

            setCheckLists(newDetailed);
            setActualPage(0);
        } else {
            setCheckLists(checkListsC);
            setActualPage(0);
        }
    };

    const handleDelete = async (isConfirm, swal, id) => {
        if (isConfirm) {
            await sendData({
                uri: uriList("checklist") + "/" + id,
                content: {},
                method: "DELETE",
            })
                .then((res) => {
                    if (res.status && parseInt(res.status) > 200) throw new Error();
                    getCheckLists();
                })
                .catch((e) => {
                    alert("Erro ao excluir Checklist.");
                });
        }
    };

    const handleChangeItem = (e) => {
        const value = e.target.value
        if (checkPoints && value) {
            let _item = checkPoints[value]
            setIncluido(_item)
            const _itens = [...itens, { checkpoint_id: _item.id, descricao: _item.descricao, id: null }]
            setItens(_itens)
            setItem(null)
            console.log("Checklists.handleChangeItem(1):", checkPoints[value])
            if (_item?.impeditivo == 'sim')
                setData({ ...data, impeditivo: 'sim' })
            setCheckPoints(checkPoints.filter((i) => i.id !== _item.id))
        }
    }

    const handleRemoveItem = async (item) => {
        console.log("Checklists.handleRemoveItem(0):", item)
        function callback(confirmado) {
            console.log("Checklists.callback(0):", confirmado)
            if (!confirmado) return;

            console.log("Checklists.callback(1):", item)
            let _checkpoint = checkPointsG.find((i) => i.id === item?.checkpoint_id)

            if (item?.id)
                sendData({
                    uri: uriList("checklistitem") + "/" + item?.id,
                    content: {},
                    method: "DELETE",
                })
                    .then((res) => {
                        if (res.status && parseInt(res.status) > 200) throw new Error();
                        if (item?.checkpoint_id) {
                            setItens(itens.filter((i) => i.checkpoint_id !== item.checkpoint_id))
                            setCheckPoints([...checkPoints, _checkpoint])
                        }

                        console.log("Checklists.callback(2):", res)
                    })
                    .catch((e) => {
                        alert("Erro ao excluir ChecklistItem.");
                    });
            else {
                setItens(itens.filter((i) => i.checkpoint_id !== item.checkpoint_id))
                setCheckPoints([...checkPoints, _checkpoint])
            }
        }

        confirmSwal({ callback, title: "Confirma a exclusão do checkpoint?" });
    }

    return (
        <>
            {gravando && <Aguarde legenda={"Aguarde, gravando dados..."} />}
            <Modal
                size="lg"
                show={show}
                centered
                onHide={(e) => {
                    handleClose(e, true);
                }}
                animation={false}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {/* <span>{props.tela === 'Checklistr' ? "Checklistr Proposta" : props.tela === "bloquear" ? "Bloquear Proposta" : "Cancelar Proposta"}</span> */}
                        <span>Cadastro de Checklists</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="maxWidth650">
                    {!tabelas && <div className="col-md-12" style={{ textAlign: 'center' }}>Aguarde, carregando dados...</div>}
                    {tabelas &&
                        <>
                            <div className="col-md-12">
                                <label className="m-0 font-90">Descrição</label>
                                <input
                                    type="text"
                                    name="descricao"
                                    className="form-control"
                                    autoFocus={true}
                                    value={data.descricao}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-12">
                                <label className="m-0 font-90">Convênio</label>
                                <select
                                    className="form-control"
                                    name="convenio_id"
                                    defaultValue={data.convenio_id}
                                    style={{ borderColor: warning?.tipo ? "red" : "", }}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" selected={!data.convenio_id}>Todos</option>
                                    {convenios?.map((convenio) => {
                                        return (
                                            <option key={convenio.id} value={convenio.id} selected={data.convenio_id == convenio.id}>
                                                {convenio.nome}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="d-flex">
                                <div className="col-md-6">
                                    <label className="m-0 font-90">Tabela</label>
                                    <select
                                        className="form-control"
                                        name="tabela_id"
                                        defaultValue={data.tabela_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" selected={!data.tabela_id}>Todos</option>
                                        {tabelas?.map((tabela) => {
                                            return (
                                                <option key={tabela.id} value={tabela.id} selected={data.tabela_id == tabela.id}>
                                                    {tabela.nome}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-3">
                                    <label className="m-0 font-90">Impeditivo</label>
                                    <select
                                        className="form-control"
                                        name="impeditivo"
                                        defaultValue={data.impeditivo}
                                        onChange={handleChange}
                                        title="Se o checklist for impeditivo e não for preenchido, não será possível prosseguir."
                                        required
                                    >
                                        <option value="nao" selected={!data.impeditivo || data.impeditivo == "nao"}>Não</option>
                                        <option value="sim" selected={data.impeditivo == "sim"}>Sim</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className="m-0 font-90">Conformidade</label>
                                    <select
                                        className="form-control"
                                        name="conformidade"
                                        defaultValue={data.conformidade}
                                        onChange={handleChange}
                                        title="Se o checklist for Conformidade abrirá opções de avaliação de conformidade."
                                        required
                                    >
                                        <option value="nao" selected={!data.conformidade || data.conformidade == "nao"}>Não</option>
                                        <option value="sim" selected={data.conformidade == "sim"}>Sim</option>
                                    </select>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="col-md-6">
                                    <label className="m-0 font-90">Operação</label>
                                    <select
                                        className="form-control"
                                        name="operacao"
                                        defaultValue={data.operacao}
                                        style={{ borderColor: warning?.tipo ? "red" : "", }}
                                        onChange={handleChange}

                                        required
                                    >
                                        <option value="todos" >Todos</option>
                                        {operacoes?.map((operacao, index) => {
                                            return (
                                                <option key={index} value={operacao.operacao} selected={data.operacao == operacao.operacao}>
                                                    {operacao.operacao}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <label className="m-0 font-90">Aplicação</label>
                                    <select
                                        className="form-control"
                                        name="aplicacao"
                                        defaultValue={data.aplicacao}
                                        style={{ borderColor: warning?.tipo ? "red" : "", }}
                                        onChange={handleChange}

                                        required
                                    >
                                        <option value="todos">Todos</option>
                                        {aplicacoes?.map((aplicacao, index) => {
                                            return (
                                                <option key={index} value={aplicacao.value} selected={data.aplicacao == aplicacao.value}>
                                                    {aplicacao.descricao}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <div className="col-md-12" style={{ textAlign: "center", fontWeight: "bold", borderBottom: "1px solid #eeed" }}>CheckPoints</div>
                                <div className="col-md-12 d-flex">
                                    <div className="col-md-12">
                                        <label className="m-0 font-90">Checkpoint</label>
                                        <select
                                            className="form-control"
                                            name="aplicacao"
                                            style={{ borderColor: warning?.tipo ? "red" : "", }}
                                            onChange={handleChangeItem}

                                            required
                                        >
                                            <option value="" selected={incluido}>Escolha uma opção</option>
                                            {checkPoints?.map((checkpoint, index) => {
                                                return (
                                                    <option key={index} value={index} >
                                                        {checkpoint.descricao}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {/* <button className="btn btn-sm text-light ml-1" onClick={handleAddItem} style={{ backgroundColor: "#00bdff", marginTop: "25px" }}>Add</button> */}
                                </div>

                                <div className="col-md-12 mt-3 p-1" style={{ border: "1px solid #eeeD" }}>
                                    <ul>
                                        {itens?.map((item, index) => {
                                            return (
                                                <li>
                                                    <div className="col-md-12 d-flex mb-1" >
                                                        <div style={{ width: "95%" }}>{item.descricao || item.checkpoint}</div>
                                                        <div style={{ width: "5%" }}>
                                                            <button
                                                                onClick={() => handleRemoveItem(item)}
                                                                className="btn btn-sm"
                                                                title="Remover"
                                                                style={{ color: "#A91306", border: "1px solid #dddd" }}
                                                            >
                                                                <i className="mdi mdi-delete" />
                                                            </button>

                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button color="success" onClick={handlePost}>
                        Gravar
                    </Button>
                    <Button
                        color="secondary"
                        onClick={(e) => {
                            handleClose(e, true);
                        }}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-4 py-1 px-0">
                <button
                    onClick={() => handleNew()}
                    className="btn btn-sm text-light"
                    title="Novo Checklist"
                    style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
                >
                    <i className="mdi mdi-plus-box" />
                </button>
                <button
                    onClick={() => window.print()}
                    className="btn btn-sm text-light"
                    title="Imprimir"
                    style={{ backgroundColor: "#00bdff" }}
                >
                    <i className="mdi mdi-printer" />
                </button>
            </div>

            <div className="row ">
                <div className={`col-md-2`}>
                    <label className="m-0 font-90">CheckList</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="descricao"
                    />
                </div>

                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Convênio</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="convenio"
                    />
                </div>

                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Tabela</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="tabela"
                    />
                </div>

                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Aplicação</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="aplicacao"
                    />
                </div>

                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Impeditivo</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="impeditivo"
                    />
                </div>
                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Conformidade</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="conformidade"
                    />
                </div>
            </div>

            <table className="table table-bordered table-striped mt-2">
                <thead>
                    <tr
                        title="Clique para ordenar"
                        style={{ backgroundColor: "#f2f2f2" }}
                    >
                        <th scope="col" className="font-80">
                            #
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("descricao", "string")}
                        >
                            Descrição
                        </th>

                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("convenio", "string")}
                        >
                            Convênio
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("tabela", "string")}
                        >
                            Tabela
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("aplicacao", "string")}
                        >
                            Aplicação
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("impeditivo", "string")}
                        >
                            Impeditivo
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("conformidade", "string")}
                        >
                            Conformidade
                        </th>
                        <th>Opções</th>
                    </tr>
                </thead>
                <tbody>
                    {checkLists.length > 0 ? (
                        checkLists.map(
                            (checklist, i) =>
                                i >= actualPage * 10 &&
                                i < (actualPage + 1) * 10 && (
                                    <tr
                                        key={i}
                                        className={`${clickedTr === checklist.id ? "bg-secondary text-light" : ""
                                            }`}
                                        onClick={() => {
                                            setClickedTr(checklist.id);
                                        }}
                                        onDoubleClick={() => {
                                            handleEdit(checklist.id);
                                        }}
                                        onContextMenu={(e) => {
                                            e.preventDefault();
                                            setClickedTr(checklist.id);
                                        }}
                                    >
                                        <td>{i + 1}</td>
                                        <td>{checklist.descricao}</td>
                                        <td>{checklist.convenio}</td>
                                        <td>{checklist.tabela}</td>
                                        <td>{checklist.aplicacao}</td>
                                        <td>{checklist.impeditivo}</td>
                                        <td>{checklist.conformidade}</td>
                                        <td className="text-center">
                                            <button
                                                className="btn btn-sm btn-warning"
                                                title="Altera um registro"
                                                onClick={(e) => handleEdit(checklist.id)}
                                            >
                                                <i className="mdi mdi-file-edit-outline"></i>
                                            </button>
                                            <Swal
                                                options={swalOptions}
                                                id={checklist.id}
                                                title="Exclusão de Registro"
                                                callback={handleDelete}
                                                className="btn btn-sm btn-danger"
                                                style={{ marginLeft: "2px", cursor: "pointer" }}
                                            >
                                                <i className="mdi mdi-delete"></i>
                                            </Swal>
                                        </td>
                                    </tr>
                                )
                        )
                    ) : (
                        <tr>
                            <td>Sem dados</td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2" className="text-left">
                            Total de CheckLists: {checkLists?.length}
                        </td>

                        <td colSpan="7"></td>
                    </tr>
                </tfoot>
            </table>
            <Pagination
                detailed={checkLists}
                actual={actualPage}
                changePage={handlePage}
            />


        </>
    );
};

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        user: state.dados.user,
    };
}

export default connect(mapStateToProps)(IncluirChecklist);