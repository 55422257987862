import React from "react";
import { createContext, useContext, useEffect, useState } from 'react'
import { format, subMonths } from 'date-fns'

import { getItemCrypt, sendData } from '../../../../helpers/auth'
import { obterQuantidadeDiasMesAtual, calcularDiferencaEmDias } from '../../../../helpers/Uteis'
import { uriList } from "../../../../helpers/config";
import { Aguarde, } from "../../../../helpers/general";

const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
const DashboardDataContext = createContext(null)

export default function DashboadDataProvider({ children }) {
    const [productionsAmount, setProductionsAmount] = useState(null)
    const [monthStatistic, setMonthStatistic] = useState(null)
    const [businessDateInformation, setBusinessDateInformation] = useState(null)

    const [productionByCategory, setProductionByCategory] = useState(null)
    const [productionByAgreement, setProductionByAgreement] = useState(null)
    const [productionByAgent, setProductionByAgent] = useState(null)
    const [productionMonth, setProductionMonth] = useState(null)
    const [selects, setSelects] = useState(null)
    const [carregando, setCarregando] = useState(false)

    const [startDate, setStartDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'))
    const [finalDate, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [agente, setAgente] = useState('')
    const [convenio, setConvenio] = useState('')

    const renameKey = (obj, oldKey, newKey) => {
        if (obj.hasOwnProperty(oldKey)) {
            obj[newKey] = obj[oldKey];
            delete obj[oldKey];
        }
        return obj;
    }

    const getDashboardData = async (_startDate, _finalDate) => {
        setCarregando(true)
        let data = null
        setMonthStatistic(null)
        setProductionByAgent(null)
        setProductionByAgreement(null)
        setProductionByCategory(null)
        setBusinessDateInformation(null)
        setProductionsAmount(null)
        setProductionMonth(null)


        const content = {
            periodo: 1,
            dateVisible: 'status',
            startDate: _startDate || startDate,
            finalDate: _finalDate || finalDate,
            agente,
            convenio
        }
        console.log("DashboardDataContext(content): ", content)

        await sendData({
            uri: uriList("dashboardTotais"),
            content,
            signal: null,
            method: "POST",
        })
            .then((res) => {
                data = res
                console.log(res)
                setMonthStatistic({
                    totalProduction: data.totais[0].total_pagto,
                    operationQuantity: data.totais[0].qtd_propostas,
                    averageTicket: data.totais[0].ticket_medio,
                    averageRate: data.totais[0].taxa_jurosm,
                    averageLeadTime: data.totais[0].prazo,
                    customerQuantity: data.totais[0].qtd_clientes,
                })

                //setProductionByCategory(dashboardData.productionByCategory)
                let _dataAgent = data.totaisAgenteMaster.sort((a, b) => parseFloat(b.total_liquido) - parseFloat(a.total_liquido))
                _dataAgent = _dataAgent.map(_item => {
                    _item['total_liquido'] = parseFloat(_item.total_liquido)
                    return _item
                })
                setProductionByAgent(_dataAgent.map(_item => renameKey(_item, 'tabela', 'categoria')).slice(0, 10))

                let _dataAgreement = data.totaisConvenios.sort((a, b) => b.total_liquido - a.total_liquido)
                _dataAgreement = _dataAgreement.map(_item => {
                    _item['total_liquido'] = parseFloat(_item.total_liquido)
                    return _item
                })
                setProductionByAgreement(_dataAgreement.map(_item => renameKey(_item, 'convenio', 'categoria')).slice(0, 10))

                let _dataTables = data.totaisTabelas.sort((a, b) => b.total_liquido - a.total_liquido)
                _dataTables = _dataTables.map(_item => {
                    _item['total_liquido'] = parseFloat(_item.total_liquido)
                    return _item
                })
                setProductionByCategory(_dataTables.map(_item => renameKey(_item, 'tabela', 'categoria')).slice(0, 10))

                let _data = {
                    days: data.totaisDiarios.length,
                    last7: data.totaisDiarios.slice(0, 7).reduce((total, _item) => parseFloat(total) + parseFloat(_item.total_liquido), 0),
                    lastMonth7: data.totaisDiarios.filter(_item => {
                        let _data = _item.to_char.substring(0, 4) + '-' + _item.to_char.substring(4, 6) + '-' + _item.to_char.substring(6, 9)
                        return new Date(_data) < subMonths(new Date(), 1)
                    }).slice(0, 7).reduce((total, _item) => total + parseFloat(_item.total_liquido), 0),
                    perc: data.totaisDiarios.slice(0, 7).reduce((total, _item) => total + parseFloat(_item?.perc || 0), 0),
                }
                setBusinessDateInformation(_data)

                const balancePaid = data.totais.reduce((total, _item) => total + parseFloat(_item.saldo_pago), 0)
                const averageBalance = parseFloat(balancePaid / calcularDiferencaEmDias(_startDate, _finalDate) * obterQuantidadeDiasMesAtual())
                _data = {
                    balancePaid,
                    averageBalance,
                    // averageBalance: data.totais.reduce((total, _item) => total + parseFloat(_item.saldo_medio), 0) * obterQuantidadeDiasMesAtual(),
                    grossProduction: data.totais.reduce((total, _item) => total + parseFloat(_item.total), 0),
                    netProduction: data.totais.reduce((total, _item) => total + parseFloat(_item.total_liquido), 0),
                    grossProductionMonth: parseFloat(data.totais[0].total_pagto),
                    netProductionMonth: parseFloat(data.totais[0].total_liquido),
                    conversionTx: parseFloat(parseFloat(data.totais[0].total_liquido) ?
                        parseFloat(data.totais[0].total_pagto) / parseFloat(data.totais[0].total_liquido) * 100 : 0).toFixed(2) + '%'
                }
                setProductionsAmount(_data)

                let _totalMonth = data.totaisMeses.map(_item => {
                    let _new = {}
                    _new['date'] = _item.mesano.substring(2, 6) + '-' + _item.mesano.substring(0, 2)
                    _new['month'] = months[parseInt(_item.mesano.substring(0, 2)) - 1]
                    _new['year'] = parseInt(_item.mesano.substring(2, 6))
                    _new['net'] = parseFloat(_item.total_liquido)
                    _new['gross'] = parseFloat(_item.total)

                    return _new
                })
                setProductionMonth(_totalMonth)

                _data = {
                    convenio: data.totaisConvenios,
                    correspondente: data.totaisAgenteMaster
                }
                setSelects(_data)
                console.log('Dados do Dashboard carregados com sucesso', data)
            })
            .catch((e) => {
                console.log('Erro ao carregar dados do Dashboard', e)
            });

        setCarregando(false)
        return data
    }

    return (
        <DashboardDataContext.Provider
            value={{
                productionsAmount,
                monthStatistic,
                businessDateInformation,
                productionByCategory,
                productionByAgreement,
                productionByAgent,
                productionMonth,
                selects,
                setStartDate,
                setFinalDate,
                setConvenio,
                setAgente,
                getDashboardData
            }}
        >
            {carregando && <Aguarde legenda={"Aguarde, carregando dados..."} />}
            {children}
        </DashboardDataContext.Provider>
    )
}

export function useDashboardData() {
    const context = useContext(DashboardDataContext)
    if (!context)
        throw new Error('useDashboard data precisa está inserida em um DashboadDataProvider')

    return context
}
