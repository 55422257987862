import React, { useState, useEffect } from 'react';

import SimulatorLayout from '../components/layout';
import { connect } from 'react-redux'
import { setUser, setAlterarProposta, setCliente, setProposta } from '../store/actions/dados'

import { sendData } from "../helpers/auth";
import { uriList } from '../helpers/config';
import AutInss from '../components/form/admin/AutorizacaoInss'
// import AutInss from '../views/simulator/confirmAutorizacaoInss'

function AutorizacaoInss({ ...props }) {
  const [data, setData] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [result, setResult] = useState({})
  const [enviando, setEnviando] = useState(false)
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 7
  });

  useEffect(() => {
    if (!props.alterar)
      _iniciar()
    else {
      console.log("AutorizacaoInss - detalhes: ", props.detalhes.posicao)
      if (["simulacao"].indexOf(props.detalhes.posicao.toLowerCase()) >= 0)
        setStep({ ...step, cur: 1 })
      else
        setStep({ ...step, cur: step.maxStep })
    }
  }, []);

  function _iniciar() {
    props.setProposta("limpar")
    props.setAlterarProposta(false, props.detalhes)
    props.alterarCliente(null)
  }

  const reRender = () => {
    setStep({ ...step, cur: -1 });
    setStep({ ...step, cur: 0 });
  }

  const RenderScreen = ({ ...props }) => ({
    '0': <AutInss data={data} reRender={reRender} handleDisabled={setDisabled} />,

  })[props.step] || (<><h1>404!</h1><h4>Esse formulário: {props.step}não existe! </h4></>);

  return (
    <>
      {step.cur >= step.maxStep ?
        <RenderScreen step={step.cur} /> :
        <SimulatorLayout admin={true} disabledSecond={disabled}>
          <RenderScreen step={step.cur} />
        </SimulatorLayout>}
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes
  }
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta))
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser))
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar))
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente))
    },
  }
}

export default connect(
  mapStateToProps,
  mapActionToProps
)(AutorizacaoInss)
