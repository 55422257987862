import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { TOKEN_KEY, TOKEN_CAPTCHA, uriList } from "./../helpers/config";
import { sendData, getItemCrypt } from "../helpers/auth";
import { connect } from "react-redux";
import { setUser, setPendencias } from "./../store/actions/dados";

import Admin from "./admin";

function AdminRoute({ component: Component, ...rest }) {
  const [loaded, setLoaded] = useState(false);
  const [auth, setAuth] = useState(false);

  const isAuthenticated = () => {
    console.log("auth.isAuthenticated(0)")
    let _auth =
      localStorage.getItem(TOKEN_KEY) !== null &&
      (rest.user || localStorage.getItem("manter_conectado"));
    console.log("auth.isAuthenticated(1): ", _auth)
    if (_auth) {
      sendData({
        uri: uriList("getUserByToken"),
        content: { token: getItemCrypt(TOKEN_KEY), captcha: getItemCrypt(TOKEN_CAPTCHA) },
        signal: null,
        method: "POST",
      })
        .then(async (res) => {
          if (res && res.status && res.status.toLowerCase() == "ativo") {
            // await getPendencias();
            // console.log("auth.isAuthenticated(Usuário): ", res);
            rest.alterarUser(res);
            setAuth(true);
            // getPendencias();
          }

          setLoaded(true);
        })
        .catch((e) => {
          setLoaded(true);
        });
    } else setLoaded(true);

    return _auth;
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  const getPendencias = async () => {
    let content = { userid: rest.user.id }
    console.log("admin.getPendencias(0): ", content)
    await sendData({
      uri: uriList('pendencias'),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        console.log("admin.getPendencias(1): ", res);
        // console.log("auth.getPendencias(2): ", );
        // this.state.pendencias = res?.length
        if (rest.setPendencias)
          rest.setPendencias(res?.length)
      })
      .catch((e) => {
        console.log("Pendencias.erro(0)");
        // console.log("Deu errado")
        console.log(e);
        return false;
      });
  }

  return rest.admin ? (
    <Admin />
  ) : !loaded ? ""
    // <p>Aguarde carregando sistema...</p>
    : auth ? (
      <Route>{Component}</Route>
    ) : (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setPendencias(pendencia) {
      dispatch(setPendencias(pendencia));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(AdminRoute);
