import React, { useEffect, useState } from "react";

import { handleFocus, width, mCPF } from "../../../helpers/general";
import { InputAlert } from "../../layout.js";
import { connect } from "react-redux";
import { validateCPF } from "../../../helpers/validation";

const Form = ({ ...props }) => {
  function handleCPF(e) {
    // console.log("HandleCPF")
    let nums = /\d/;
    let cpf = "";
    if (nums.test(e.currentTarget.value)) {
      // console.log("HandleCPF - 1", cpf.length)
      cpf = mCPF(e.currentTarget.value);

      if (e.currentTarget.value.length <= 14) props.cpf(cpf);
    } else if (!cpf) props.cpf("");

    if (cpf && cpf.length == 14) validarCPF(cpf);
    else props.setWarningCPF(false);

    return false;
  }

  const handleBlurCPF = (e) => {
    let target = e.currentTarget;
    let valor = target.value;
    validarCPF(valor);
  };

  const validarCPF = (valor) => {
    props.setWarningCPF(false);

    if (!validateCPF(valor)) props.setWarningCPF(true);
  };

  return (
    <div className="form" style={{ height: "100% !important" }}>
      <div className="form-group" style={{ position: "relative" }}>
        <select
          className="form-control"
          value={props.data.tipo}
          onChange={props.changeLabel}
          name="tipo"
          style={{
            borderColor: props.warning.convenio ? "red" : "",
            backgroundColor: "#EBEEE9",
            borderRadius: "4px",
            border: "1.5px solid #727476",
            backgroundImage: "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
            width: width() === "mobile" || props.alteracao ? "100%" : "95%",
          }}
        >
          <option value="valor">
            {props.user ? "Simular por Crédito" : "Quero Simular Crédito"}
          </option>
          <option value="parcela">
            {props.user ? "Simular por Parcela" : "Quero Simular Parcela"}
          </option>
        </select>

        {!props.alteracao &&
          <span
            className="custom-info"
            onClick={() =>
              props.modal({
                title: "Saiba mais",
                list: true,
                text: [
                  "Você tem a opção de simular o empréstimo pelo Valor de Crédito ou Valor de Parcela",
                  "Valores por CPF: R$ 500,00 a R$ 500.000,00 (incluso IOF)",
                  "Parcela Mínima: R$ 50,00",
                  "Prazo máximo: 120 meses",
                  "Convênio: Onde você é vinculado.",
                  "Valores e prazos variam de acordo com o convênio",
                ],
              })
            }
          >
            ?
          </span>}
      </div>

      {
        <div className="row" style={{ position: "relative" }}>
          <div
            // className={props.user ? "form-group col-8" : "form-group col-12"}
            className={"form-group col-12"}
          >
            <label htmlFor="convenio_id" className="font-weight-bold custom-p">
              {props.user ? "Selecionar Convênio" : "Qual o seu Convênio?"}
            </label>
            <select
              className="form-control text-capitalize"
              value={props.convenio_id}
              onChange={props.convenio}
              onFocus={props.clearWarning}
              name="convenio_id"
              id="convenio_id"
              style={{
                borderColor: props.warning.convenio ? "red" : "",
                backgroundColor: "#EBEEE9",
                borderRadius: "4px",
                border: "1.5px solid #727476",
                backgroundImage:
                  "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
              }}
            >
              {props.convenioList ? (
                props.convenioList.map((convenio, index) => (
                  <option
                    key={index}
                    title={convenio.sigla}
                    value={convenio.id}
                  >
                    {convenio.nome.toUpperCase()}
                  </option>
                ))
              ) : (
                <option>Carregando</option>
              )}
            </select>

            <InputAlert display={props.warning.convenio} field={"Convênio!"} />
          </div>

          {props.user ? (
            <div
              className={
                "form-group col-12"
                // width() == "mobile" ? "form-group col-12" : "form-group col-4"
              }
            >
              <label htmlFor="tabela_id" className="font-weight-bold custom-p">
                Tabela
              </label>
              <select
                className="form-control text-capitalize"
                // value={props.data.tabela_id}
                defaultValue={props.tabela_id}
                onChange={props.tabela}
                name="tabela_id"
                id="tabela_id"
                style={{
                  backgroundColor: "#EBEEE9",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                  backgroundImage:
                    "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                }}
              >
                {props.tabelaList ? (
                  props.tabelaList.map((tabela, index) => (
                    <option
                      key={`tabela_id-${tabela.id}`}
                      title={tabela.nome}
                      value={tabela.id}
                    >
                      {tabela.nome}
                    </option>
                  ))
                ) : (
                  <option>Carregando</option>
                )}
              </select>
            </div>
          ) : (
            ""
          )}
        </div>
      }

      <div className="row">
        <div
          className={
            "form-group col-12"
            // props.user && width() && props.embedded !== "mobile"
            //   ? "form-group col-12"
            //   : "form-group col-12"
          }
        >
          <label htmlFor="valor" className="font-weight-bold custom-p">
            {props.label.message || props.label.def}
          </label>

          {/* <span>R$</span> */}

          <input
            type="tel"
            value={props.data.valor}
            onClick={handleFocus}
            onFocus={handleFocus}
            style={{
              marginLeft: "0.3em",
              width: "120px",
              borderRadius: "4px",
              border: "1.5px solid #727476",
            }}
            // className="custom-input"
            onChange={props.valor}
            name="valor"
            id="valor"
            autoFocus={width() !== "mobile" && !props.warningCPF}
          />
        </div>

        {/* {props.user && props.data &&  ? ( */}
        {props.user && props.operacao == "REFINANCIAMENTO" ? (
          <>
            <div
              className={
                "form-group col-12"
                // width() !== "mobile" ? "form-group col-4" : "form-group col-12"
              }
            >
              <label htmlFor="contrato" className="font-weight-bold custom-p">
                Número do Contrato
              </label>
              <input
                type="tel"
                value={props.data.contrato}
                onClick={handleFocus}
                onFocus={handleFocus}
                style={{
                  marginLeft: "0.3em",
                  width: "95%",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                }}
                className="custom-input"
                onChange={props.contrato}
                name="contrato"
                id="contrato"
              />
            </div>
            <div
              className={
                "form-group col-12"
                // width() !== "mobile" ? "form-group col-4" : "form-group col-12"
              }
            >
              <label htmlFor="cpf" className="font-weight-bold custom-p">
                CPF do Cliente
              </label>

              <input
                type="tel"
                value={props.data.cpf}
                onClick={handleFocus}
                onFocus={handleFocus}
                onBlur={handleBlurCPF}
                autoComplete="off"
                style={{
                  marginLeft: "0.3em",
                  width: "95%",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                  borderColor: props.warningCPF ? "red" : "black",
                }}
                className="custom-input"
                autoFocus={props.warningCPF}
                onChange={handleCPF}
                name="cpf"
                id="cpf"
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      {!props.alteracao &&
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <input
            id="rangeValor"
            type="range"
            className="custom-range1"
            // className="range"
            // style={{width: "70%", cursor: "pointer"}}
            min="0"
            max="500000"
            step="500"
            value={props.data.rangeValor}
            // onInput={props.valor}
            onChange={props.valor}
            name="rangeValor"
          />

          <InputAlert
            display={props.warning.valor}
            field={"Crédito/Parcela (Se atente aos valores permitidos)"}
          />
        </div>}

      <div style={{ position: "relative" }}>
        <label htmlFor="prazo" className="font-weight-bold custom-p">
          {props.user ? "Quantidade de Parcelas" : "Em quantas parcelas?"}
        </label>

        <select
          value={props.data.rangePrazo}
          // className='custom-input'
          name="prazo"
          id="prazo"
          onChange={props.ft}
          style={{
            width: "120px",
            backgroundColor: "#EBEEE9",
            borderRadius: "4px",
            border: "1.5px solid #727476",
            backgroundImage: "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
          }}
        >
          {props &&
            props.prazoSteps &&
            props.prazoSteps.map((prazo, i) => (
              <option key={`prazo-${i}`} label={prazo.prazo} value={i}>
                {prazo.prazo}
              </option>
            ))}
        </select>
      </div>

      {!props.alteracao &&
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <input
            type="range"
            // className="custom-range"
            className="custom-range1"
            // style={{width: "70%", cursor: "pointer"}}
            list="prazoDataList"
            value={props.data.rangePrazo}
            step="1"
            min="0"
            max={props.prazoSteps ? props.prazoSteps.length - 1 : 0}
            onChange={props.ft}
            disabled={props.prazoSteps.length === 1}
          />

          <datalist id="prazoDataList">
            {props.prazoSteps &&
              props.prazoSteps.map((prazo, i) => (
                <option
                  key={`prazo-${i}`}
                  label={prazo.prazo}
                  value={prazo.prazo}
                >
                  {prazo.prazo}
                </option>
              ))}
          </datalist>
        </div>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);
