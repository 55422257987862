import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal, { swalError, confirmSwal } from "../../../swal";
import { sendData, getData } from "../../../../helpers/auth";
import { getAcls } from "../../../../helpers/acls";
import { Aguarde, width } from "../../../../helpers/general";
import { uriList, } from "../../../../helpers/config";
import { connect } from "react-redux";

const Form = ({ ...props }) => {
    const [usuario, setusuario] = useState(props.usuario);
    const [gravando, setGravando] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [multSelect, setMultSelect] = useState(true);
    const [permissoes, setPermissoes] = useState(null);
    const [clickedTr, setClickedTr] = useState({});

    useEffect(() => {
        getData()
    }, []);

    async function getData() {
        let uri = uriList("buscaraclusuario");
        let permissoes = getAcls();
        let _permissoes;
        console.log("usuarios.getData(1): ", permissoes);
        return await sendData({ uri, content: { ...usuario }, method: "POST" })
            .then((res) => {
                console.log("usuarios.getData(OK): ", res);
                if (res.status == 200) {
                    _permissoes = res.data;
                    console.log("usuarios.getData(permissoes): ", _permissoes);
                    if (_permissoes)
                        for (let i = 0; i < permissoes.length; i++) {
                            permissoes[i].checked = false;
                            let _permissao = _permissoes.find((permissao) => permissao.name == permissoes[i].name);
                            if (_permissao)
                                permissoes[i].checked = true;
                        }
                    else
                        for (let i = 0; i < permissoes.length; i++)
                            permissoes[i].checked = false;
                    console.log("usuarios.getData(_acls): ", permissoes);
                    setPermissoes(permissoes);
                }
                else
                    swalError({ title: "Erro ao buscar permissões do usuário", text: res._status })
            })
            .catch((e) => {
                console.log("usuarios.getData(error)");
                console.log(e);
            });
    }

    async function handlePost() {
        async function callback(e) {
            if (!e)
                return;
            let uri = uriList("gravaraclusuario");
            let _permissoes = permissoes.filter((permissao) => permissao.checked);
            let content = { usuario, permissoes: _permissoes };
            setGravando(true);
            return await sendData({ uri, content, method: "POST" })
                .then((res) => {
                    if (res._status == 200) {
                        handleClose();
                    } else {
                        swalError({ title: "Erro ao gravar permissões do usuário", text: res.message });
                    }
                    setGravando(false);
                })
                .catch((e) => {
                    console.log("usuariosacl.handlePost(error)");
                    console.log(e)
                    setGravando(false);
                });
        }

        confirmSwal({ title: "Confirma a gravação das permissões?", text: "Confirma a gravação das permissões?", callback });
    }

    function handleClose() {
        if (props.onCloseEv)
            props.onCloseEv();
    }

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        let _permissoes = permissoes.map((permissao) => {
            permissao.checked = !selectAll;
            return permissao;
        });
        setPermissoes(_permissoes);
    }

    const handlePermissao = (permissao) => {
        console.log("handlePermissao: ", permissao)
        let _permissoes = permissoes.map((_permissao) => {
            if (_permissao.name == permissao.name) {
                _permissao.checked = !_permissao.checked;
                setClickedTr(_permissao);
            }

            console.log("handlePermissao(1): ", _permissao)
            return _permissao;
        });

        setPermissoes(_permissoes);
    }

    return (
        <>
            {!permissoes && <Aguarde legenda={"Carregando informações..."} />}
            <Modal
                size="lg"
                show={true}
                onHide={handleClose}
                centered
                animation={false}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span>Configuração de Permissões</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="maxWidth750">
                    <div>
                        Usuário: <label><strong>{usuario?.nome || usuario?.name}</strong></label>
                    </div>
                    <div
                        // className="mt-2 mb-2 border  pt-1 pl-2 pb-1"
                        // className="mb-2 border pt-1 pb-1"
                        style={{ backgroundColor: "rgba(231, 230, 230,  0.18)" }}
                    >
                        <div
                            className="form"
                            style={{
                                maxHeight: width() == "mobile" ? "100px" : "150px",
                                fontSize: width() == "mobile" ? "x-small" : "small",
                                overflowY: "hide",
                            }}
                        >

                            {permissoes &&
                                <table
                                    className="table-hover table-bordered table-responsive"
                                    border="1"
                                    style={{ cursor: "pointer" }}
                                >
                                    <thead style={{ position: "sticky", top: "0px" }}>
                                        <tr style={{ backgroundColor: "#f2f2f2" }}>

                                            <td
                                                className="text-center font-weight-bold"
                                                style={{ width: "35px" }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <input
                                                        // type="switch"
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        style={{ width: "15px", height: "15px" }}
                                                        // value={simulacao.selected}
                                                        onChange={() => handleSelectAll()}
                                                    />
                                                </div>
                                            </td>

                                            <td
                                                className="font-weight-bold"
                                            // style={{ width: "120px" }}
                                            >
                                                Permissão
                                            </td>
                                            <td className="font-weight-bold">
                                                Tipo
                                            </td>
                                            <td className="font-weight-bold">
                                                Grupo
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {permissoes &&
                                            permissoes.map((permissao, i) => (
                                                <tr
                                                    key={i}
                                                    className={`${clickedTr.name === permissao.name
                                                        ? "bg-secondary text-light"
                                                        : ""
                                                        }`}

                                                    onClick={() => {
                                                        handlePermissao(permissao);
                                                    }}
                                                    onDoubleClick={() => {
                                                        handlePermissao(permissao);
                                                    }}
                                                >
                                                    {multSelect && (
                                                        <td
                                                            className="text-center"
                                                            style={{
                                                                width: "35px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <input
                                                                    // type="switch"
                                                                    type="checkbox"
                                                                    checked={permissao.checked}
                                                                    style={{ width: "15px", height: "15px" }}
                                                                />
                                                            </div>
                                                        </td>
                                                    )}
                                                    <td id={i} style={{ width: "500px" }}>
                                                        {
                                                            permissao.description
                                                        }
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        {
                                                            permissao.name
                                                        }
                                                    </td>
                                                    <td style={{ width: "300px" }}>
                                                        {
                                                            permissao.group
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="success" onClick={handlePost}>
                        Gravar
                    </Button>
                    <Button color="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

function mapStateToProps(state) {
    return {
        user: state.dados.user,
    };
}

export default connect(mapStateToProps)(Form);


