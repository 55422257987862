import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { width } from './../../helpers/general';


const Dashboard = ({ ...props }) => {
    return (
        <>
            <Row>
                <Col sm="12">
                    <div className="page-title-box">
                        <div className="float-right">
                            <ol className="breadcrumb hide-phone p-0 m-0">
                                <li className="breadcrumb-item"><Link to="/admin">Admin</Link></li>
                                <li className="breadcrumb-item active">{props.title}</li>
                            </ol>
                        </div>
                        <h4 className="page-title">{props.title}</h4>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;   
