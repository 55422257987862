import React from "react";

import AsideStatistic from './AsideStatistic'
import DailyAverageTable from './DailyAverageTable'
import ProductionInfo from './ProductionInfo'
import SelectInputs from './SelectInputs'
import DailyProductionGraph from './DailyProduction'
import HorizontalBarInfo from './HorizontalBarInfo'
import LineChartInfo from './LineChartInfo'
import { useDashboardData } from './hooks/DashboardDataContext'

import style from './dashboard.module.css'

export default function Dashboard() {

    const { productionByAgent, productionByAgreement, productionByCategory, productionMonth, selects } =
        useDashboardData()

    return (
        <div
            style={{ backgroundColor: '#f7f8f9', width: '100%' }}
            className="container-fluid d-flex justify-content-center py-lg-3"
        >
            <main
                style={{
                    maxWidth: '1440px',
                    width: '100%',
                }}
            >

                <div className="row mr-0">
                    <div className="col-sm-12 col-md-12 col-lg-2 d-flex justify-content-center">
                        <SelectInputs data={selects} />
                    </div>
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-sm-12 col-lg-8">
                                <DailyProductionGraph
                                    title="Produção Mensal"
                                    data={productionMonth ? productionMonth : []}
                                />
                            </div>
                            <div className="col-sm-12 col-lg-4 ">
                                <LineChartInfo
                                    data={productionMonth ? productionMonth : []}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mr-0">
                    <div className="col-sm-12 col-lg-2 d-flex justify-content-center" >
                        <AsideStatistic />
                    </div>
                    <div className={`col-sm-12 col-lg-10 ${style.boxPosition}`}>
                        <div className="row">
                            <div className="col-sm-12 col-lg-8 col-md-12">
                                <ProductionInfo />
                            </div>
                            <div className="col-sm-12 col-lg-4 col-md-12">
                                <DailyAverageTable />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "32px" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 row-md mb-3 d-flex justify-content-center">
                                <HorizontalBarInfo
                                    title="Produção por Tabelas"
                                    data={productionByCategory ? productionByCategory : []}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4  mb-3 d-flex justify-content-center">
                                <HorizontalBarInfo
                                    title="Produção por Convênios"
                                    data={productionByAgreement ? productionByAgreement : []}
                                />
                            </div>
                            <div className="col-sm-12 col-lg-4  mb-3 d-flex justify-content-center">
                                <HorizontalBarInfo
                                    title="Produção por Agentes"
                                    data={productionByAgent ? productionByAgent : []}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <main></main>
            </main>
        </div>
    )
}
