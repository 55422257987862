import React, { useState, useEffect } from "react";
import ModalContent from "../../../components/layout/ModalContent";
import { connect } from "react-redux";
import { sendData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";
import { width } from "../../../helpers/general";
import { swalError } from "../../../components/swal";

function ConsultarMargem({ ...props }) {
  const [dados, setDados] = useState(null);
  const [data, setData] = useState({});
  const [msgErro, setMsgErro] = useState(false);
  const [showData, setShowData] = useState(props.showData);
  const [valorMargem, setValorMargem] = useState(0)
  const [buscando, setBuscando] = useState(false);
  const [mensagem, setMensagem] = useState(false);
  const [nomeTela, setNomeTela] = useState("Consulta de Margem");
  const [bloqueado, setBloqueado] = useState("");
  const [tipoBloqueio, setTipoBloqueio] = useState("");
  const [elegivel, setElegivel] = useState("");

  useEffect(() => {
    async function _sendData() {
      console.log("consultarMargem: ", props.proposta);
      setMensagem(false);
      if (props.proposta) {
        setBuscando(true);
        console.log("consultarMargem: ", props.detalhes);
        let content = { uuid: props.proposta };
        let uri = uriList("consultarMargem");
        let _portabilidadeOutros = (props.detalhes.averbacao_solicitada || props.detalhes.averbada ||
          ['Aguardando Refin', 'Portabilidade Averbada', 'Aguardando Pagto Saldo', 'Pago'].indexOf(props.detalhes.posicao) >= 0) &&
          props.detalhes.operacao == 'PORTABILIDADE' && props.detalhes.tipo_convenio != "INSS"

        if (_portabilidadeOutros) {
          setNomeTela("Consulta de Portabilidade");
          uri = uriList("consultarAverbacaoPortabilidade");
        }

        await sendData({ uri, content, method: "POST" })
          .then((res) => {
            setBuscando(false);
            if (res.status && parseInt(res.status) != 200) {
              swalError({
                title: "Erro ao Consultar Margem do Cliente.",
                text: JSON.stringify(res.data),
              });
              throw new Error();
            }
            setData(res.data);
            console.log("consultaAverbaçãoPortabilidade: ", res.data);

            if (_portabilidadeOutros) {
              setMensagem(res.data.dsSituacao);
            } else setDados(res);
            console.log("[CONSULTAR_MARGEM]-dados: ", res);

            if ("bloqueadoParaEmprestimo" in res?.data) {
              setBloqueado(
                (res.data.bloqueadoParaEmprestimo && "!!! Sim !!!") || "Não"
              );

              setTipoBloqueio(res.data?.tipoBloqueio?.descricao || res.data?.tipoBloqueio)
            }

            if ("elegivelEmprestimo" in res?.data)
              setElegivel(
                (res.data.elegivelEmprestimo && "Sim") || "!!! Não !!!"
              );

            // if ("valorDisponivelAverbacaoEmprestimo" in res?.data)
            // console.log("[CONSULTAR_MARGEM]: ", res.data.produto?.[0].vlMargemDisp)
            setValorMargem(parseFloat((props?.detalhes?.convenio?.toLowerCase() == "inss" ? res.data.valorDisponivelAverbacaoEmprestimo : res.valor) || 0).toFixed(2))
          })
          .catch((e) => {
            setBuscando(false);
            console.log("ConsultarMargem - erro: Erro na consulta de margem");
            console.log(e);
            setMsgErro(true);
            // alert('Erro ao excluir Configuracoes.')
          });
      }
    }

    _sendData();
  }, []);

  const ExibirData = ({ json }) => {
    if (!json) return;
    const highlightedJSON = (jsonObj) =>
      Object.keys(jsonObj).map((key) => {
        const value = jsonObj[key];
        let valueType = typeof value;
        const isSimpleValue =
          ["string", "number", "boolean"].includes(valueType) || !value;
        if (isSimpleValue && valueType === "object") {
          valueType = "null";
        }
        return (
          <div key={key} className="line" style={{ fontSize: "18px !important" }}>
            <span className="key" style={{ fontSize: "18px !important" }}>{key}:</span>
            {isSimpleValue ? (
              <span className={valueType} style={{ fontSize: "18px !important" }}>{`${value}`}</span>
            ) : (
              highlightedJSON(value)
            )}
          </div>
        );
      });
    return (
      <div className="mt-1">
        <div className="col-md-12 m-auto custom-status card">
          <div style={{ fontSize: "18px !important", width: "100%" }}>
            {highlightedJSON(json)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalContent
      title={nomeTela}
      onCloseEv={props.onCloseEv}
      size="md"
      showInfo={() => setShowData(!showData)}
    >
      {!showData && (
        <div className="col-md-12 m-auto custom-status card mb-2">
          {mensagem && (
            <div style={{ height: "100%", width: "100%", textAlign: "center" }}>
              <h5>{mensagem}</h5>
            </div>
          )}
          {dados && (
            <div>
              <div
                style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                className={`row ${width() === "mobile" ? "mb-1" : "mb-2"}`}
              >
                <div className="col-md-9 px-0">
                  <h6 className="no-margin">Nome</h6>
                  <h6 className="font-weight-bold m-0">
                    {props.detalhes.nome || props.detalhes.cliente}
                  </h6>
                </div>
              </div>

              <div
                style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
              >
                <div className="col-md-4 col-4 px-0">
                  <h6 className="no-margin">CPF</h6>
                  <h6 className="font-weight-bold m-0">{props.detalhes.cpf}</h6>
                </div>
              </div>

              <div
                style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
              >
                <div className="col-md-4 col-4 px-0">
                  <h6 className="no-margin" style={{ color: valorMargem >= 0 ? "" : "#DC0303" }}>Margem</h6>
                  <h6 className="font-weight-bold m-0" style={{ color: valorMargem >= 0 ? "" : "#DC0303", textDecoration: valorMargem >= 0 ? "" : "underline" }}>{valorMargem}</h6>
                </div>
              </div>

              {("matricula" in dados ||
                "cdMatricula" in dados ||
                "codigo_orgao" in dados ||
                "codOrgao" in dados) && (
                  <div
                    style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                  >
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Matricula</h6>
                      <h6 className="font-weight-bold m-0">
                        {dados.matricula || dados.cdMatricula}
                      </h6>
                    </div>
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Órgão</h6>
                      <h6 className="font-weight-bold m-0">
                        {dados.codigo_orgao || dados.codOrgao}
                      </h6>
                    </div>
                  </div>
                )}
              {(elegivel || bloqueado) && (
                <div
                  style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                  className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                >
                  <div className="col-md-6 col-6 px-0">
                    <h6 className="no-margin">Bloqueado</h6>
                    <h6
                      className="font-weight-bold m-0"
                      style={{ color: bloqueado == "Não" ? "" : "#DC0303" }}
                    >
                      {bloqueado}
                    </h6>
                  </div>
                  {tipoBloqueio && bloqueado != "Não" &&
                    <div className="col-md-6 col-6 px-0" >
                      <h6 className="no-margin">Motivo Bloqueio</h6>
                      <h6 className="font-weight-bold m-0" style={{ color: "#DC0303", }}>
                        {JSON.stringify(tipoBloqueio)}
                      </h6>
                    </div>}
                  <div className="col-md-6 col-6 px-0">
                    <h6 className="no-margin">Elegível</h6>
                    <h6
                      className="font-weight-bold m-0"
                      style={{ color: elegivel == "Sim" ? "" : "#DC0303" }}
                    >
                      {elegivel}
                    </h6>
                  </div>

                </div>
              )}
            </div>
          )}
          {buscando && (
            <h4 className="text-center">Aguarde, buscando dados...</h4>
          )}
          {msgErro && (
            <div
              className="my-3"
              style={{ backgroundColor: "#EEEEEE", width: "100%" }}
            >
              <h4
                className="text-center font-weight-bold"
                style={{ color: "#DC0303" }}
              >
                Erro ao consultar margem do cliente.
              </h4>
            </div>
          )}
        </div>
      )}

      {showData && data && <ExibirData json={data} />}
    </ModalContent>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(ConsultarMargem);
