import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "../../../layout";
import {
  mCPF,
  getCookie,
  mDate,
  mTel,
  formatFloat,
  formatCurrency,
  Aguarde,
} from "../../../../helpers/general";
import {
  validateCPF,
  validateDate,
  validateEmail,
} from "../../../../helpers/validation";
import {
  sendData,
  sendMultiPartData,
  setSession,
} from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import {
  setProposta,
  setClienteDados,
  setSimulacao,
  setAlterarProposta,
} from "../../../../store/actions/dados";
import { swalError, confirmSwal } from "../../../swal";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import Simulacao from "./selSimulacao";
import * as moment from "moment";

const dataIni = { cpf: "", nome: "", ddn: "", telefone: "" };
function Fgts({ ...props }) {
  const [warning, setWarning] = useState({});
  const [consultando, setConsultando] = useState(false);
  const [dados, setDados] = useState(dataIni);
  const [simulacoes, setSimulacoes] = useState([]);
  const [show, setShow] = useState(false);
  // const [simulacao, setSimulacao] = useState({});
  const [gravando, setGravando] = useState(false);
  const [data, setData] = useState({});
  const [tabela_id, setTabelaID] = useState(0);
  const [tabelaList, setTabelaList] = useState([]);

  useEffect(() => {
    const getTabelas = async () => {
      await _getTabelas();
    };

    getTabelas();
  }, []);

  async function _getTabelas() {
    setTabelaList([]);
    setTabelaID(0);
    await sendData({
      uri: uriList("listarTabelas"),
      content: { tipo: "FGTS" },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setTabelaList(res);
        setTabelaID(res[0].id);
      })
      .catch((e) => {
        // alert('Erro ao obter Tabelas.\n' + e.message)
      });
  }

  const validarDados = () => {
    let result = true;
    let _warning = {};

    if (!dados.cpf || !validateCPF(dados.cpf)) {
      _warning = { ..._warning, cpf: 1 };
      result = false;
    }

    console.log("Fgts.validarDados", _warning);
    setWarning(_warning);
    return result;
  };

  const handleTabela = (e) => {
    let _value = e?.currentTarget?.value;
    setTabelaID(_value);
  };

  const Simular = (cpf) => {
    if (!validarDados()) return;

    setConsultando(true);
    setSimulacoes([]);
    let _dados = {
      cpf: dados.cpf,
      // nome: dados.nome,
      // ddn: dados.ddn,
    };
    // _dados["ddn"] = _dados.ddn.split("/").reverse().join("-");
    _dados["cpf"] = _dados.cpf.replace(/\D/g, "");
    _dados["tabela_id"] = tabela_id;
    console.log("Fgts.Simular", _dados);

    sendData({
      uri: uriList("simulacaoFgts"),
      content: _dados,
      signal: null,
      method: "POST",
    })
      .then(async (res) => {
        setConsultando(false);
        console.log("Fgts.Simular - res: ", res);
        if (res.status && res.status > 200) {
          let erro =
            (res.errors && res.errors[0]?.message) ||
            "Erro ao fazer simulação!";

          console.log(erro + ": ", res.message);

          swalError({ title: erro, text: res.message });
          // throw new Error();
        } else if (res.data) {
          let _simulacoes = res.data;
          console.log(_simulacoes);
          console.log(res.data);
          setConsultando(false);
          setSimulacoes(_simulacoes);
          setData(res);
          setShow(true);
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: JSON.stringify(e.message) });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  };

  // const Aguarde = (props) => {
  //   return (
  //     <Modal
  //       size={"md"}
  //       scrollable
  //       show={true}
  //       animation={false}
  //       backdrop="static"
  //       centered
  //     >
  //       <Modal.Body
  //         className="maxWidth750"
  //         style={{
  //           width: "100%",
  //           justifyContent: "center",
  //           textAlign: "center",
  //         }}
  //       >
  //         <Spinner animation="border" />
  //         {"  "}
  //         {props.legenda || "Aguarde enquanto o processo é finalizado..."}
  //       </Modal.Body>
  //     </Modal>
  //   );
  // };

  const carregarDados = async (cpf) => {
    console.log("Fgts.carregarDados", cpf);
    if (cpf)
      await sendData({
        uri: uriList("pessoa"),
        content: { cpf },
        signal: null,
        method: "POST",
      })
        .then(async (res) => {
          console.log("Fgts - carregarDados - res: ", res);
          setDados({
            ...dados,
            cpf,
            nome: res.data.nome,
            ddn: moment(res.data.ddn).format("DD/MM/YYYY"),
          });
        })
        .catch((e) => {
          console.log("Fgts - carregarDados - error: ", e);
        });
  };

  const handleCpf = (e) => {
    setWarning({ ...warning, cpf: 0 });
    let nums = /\d/;
    setDados({});
    let cpf = e.currentTarget.value;
    let valid = true;
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setDados({ ...dados, cpf });
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (!valid) setWarning({ ...warning, cpf: 1 });
        else carregarDados(cpf);
      }

      return false;
    } else if (!e.currentTarget.value) setDados({ ...dados, cpf: "" });

    return false;
  };

  const handleChange = (e) => {
    setWarning({ ...warning, [e.target.name]: 0 });
    let target = e.currentTarget;
    let name = target.name;
    let value = target.value;
    if (name === "ddn") setDados({ ...dados, [name]: mDate(value) });
    else setDados({ ...dados, [name]: value });
  };

  const handleBlur = (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let valor = target.value.toUpperCase();
    setWarning({ ...warning, [name]: 0 });
    handleChange(e);

    if (name === "ddn" && valor) {
      if (!validateDate(valor)) setWarning({ ...warning, ["ddn"]: 1 });
    } else if (name === "cpf" && valor) {
      if (!validateCPF(valor)) setWarning({ ...warning, ["cpf"]: 1 });
    }
  };

  const handleSimulation = async (_simulacao) => {
    console.log("handleSimulation", _simulacao);
    setGravando(true);
    dados.agente_id =
      ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0
        ? props.user?.pessoa_id
        : null;
    dados.operador_id = props.user?.pessoa_id;
    dados["tabela_id"] = tabela_id;
    await sendData({
      uri: uriList("gravarPropostaFgts"),
      content: { dados, simulacoes: _simulacao },
      method: "POST",
    })
      .then((res) => {
        setGravando(false);
        if (res.status && parseInt(res.status) > 200) throw new Error();

        setShow(false);
        console.log("Fgts - handleSimulation - res: ", res);
        props.alterarProposta(res.data);

        if (props.toggleStep) props.toggleStep(1);
      })
      .catch((e) => {
        console.log("Fgts.index - Erro ao gravar proposta: ", e);
        setGravando(false);
      });
  };

  return (
    <>
      {show && (
        <Simulacao
          simulacoes={simulacoes}
          data={data}
          dados={dados}
          onCloseEv={() => setShow(false)}
          handle={handleSimulation}
        />
      )}
      {
        <fieldset className="col-12" style={{ height: "100%" }}>
          <h4
            className="display-4 text-center font-weight-bold"
            style={{ marginBottom: "10px", color: "#3E6C84" }}
          >
            Saque FGTS
          </h4>

          <div className="form ml-1" style={{ overflow: "hidden" }}>
            <div className="row ">
              <div className="col-12">
                <div className="form-group col-md-4">
                  <InputLabelAlert
                    type="tel"
                    label="CPF"
                    id="cpf"
                    value={dados.cpf}
                    handle={handleCpf}
                    autoComplete="off"
                    onBlur={handleBlur}
                    length="14"
                    focus={true}
                    onContextMenu={(e) => e.preventDefault()}
                  />

                  <InputAlert
                    display={warning.cpf}
                    messages={"CPF inválido."}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group col-md-8">
                  <label className="no-margin" htmlFor="tabela_id">
                    Tabela
                  </label>
                  <select
                    className="form-control"
                    // value={props.data.tabela_id}
                    defaultValue={tabela_id}
                    onChange={handleTabela}
                    name="tabela_id"
                    id="tabela_id"
                    style={{
                      backgroundColor: "#EBEEE9",
                      borderRadius: "4px",
                      border: "1.5px solid #727476",
                      backgroundImage:
                        "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                    }}
                  >
                    {tabelaList ? (
                      tabelaList.map((tabela, index) => (
                        <option
                          key={`tabela_id-${tabela.id}`}
                          title={tabela.nome}
                          value={tabela.id}
                        >
                          {tabela.nome}
                        </option>
                      ))
                    ) : (
                      <option>Carregando</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div className="row form-group mt-5 d-flex ml-1 justify-content-center">
              <button
                style={{ backgroundColor: "#3E6C84", width: "30%" }}
                onClick={Simular}
                className="btn btn-md btn-info font-weight-bold mr-2"
              >
                Simular
              </button>
              <button
                style={{ backgroundColor: "#3E6C84", width: "30%" }}
                onClick={props.reRender}
                className="btn btn-md btn-info font-weight-bold mr-2"
              >
                Limpar
              </button>
            </div>
          </div>
        </fieldset>
      }
      {consultando && <Aguarde />}
      {gravando && <Aguarde legenda={"Aguarde, gravando proposta..."} />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    setAlterarProposta(alterar, proposta) {
      dispatch(setAlterarProposta(alterar, proposta));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Fgts);
