import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { uriList } from "../../../../helpers/config";
import { sendData } from "../../../../helpers/auth";
import { formatCurrency, width } from "../../../../helpers/general";
import ModalContent from "../../../../components/layout/ModalContent";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import AntiFraude from "../../../../components/form/admin/AntiFraude";
import { confirmSwal, swalError } from "../../../../components/swal";
import * as moment from "moment";
import MapView from "./MapView";
import HistoricoPendencia from "../Pendencias/HistoricoPendencia";
import PreencherChecklist from "../Conformidade/PreencherChecklist";

const Log = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [antiFraude, setAntiFraude] = useState(false);
  const [geolocalizacao, setGeolocalizacao] = useState(false);
  const [pendencia, setPendencia] = useState(false)
  const [checklist, setChecklist] = useState(null)
  const [checkPoints, setCheckPoints] = useState(null)

  function handleClose(e, close) {
    if (e) e.preventDefault();
    if (props.onCloseEv) props.onCloseEv(close);
  }

  useEffect(() => {
    sendData({
      uri: uriList("log"),
      content: { uuid: props.proposta },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("LogProposta.index - data: ", res);
        if (res?.length > 0)
          setData(res)
        else
          throw new Error("Não foi possível carregar o log da proposta")

      })
      .catch((e) => {
        // swalError({ title: "Erro ao buscar propostas.", text: e?.message || e?.data || "Erro desconhecido!" });
        console.log(e);
        return false;
      });
  }, []);

  async function handleChecklist(option) {
    setChecklist(option.checklist_id)
    // if (option) {
    //   sendData({
    //     uri: uriList("getChecklist"),
    //     content: { uuid: props.proposta, checklist_id: option.checklist_id, showAll: true },
    //     signal: null,
    //     method: "POST",
    //   })
    //     .then((res) => {
    //       console.log("LogProposta.index - handleCheckList(res): ", res);
    //       setCheckPoints(res);
    //       setChecklist(option)
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       return false;
    //     });
    // }
    // else {
    //   setChecklist(null)
    //   setCheckPoints(null)
    // }
  }

  // function GetCheckList() {
  //   if (checklist.checklist_id) {
  //     return (
  //       <div title={"Clique para ocultar o checklist"}
  //         onClick={() => handleChecklist(null)} style={{ cursor: "pointer", fontSize: "10px", fontWeight: "bold", }}>
  //         <ul>
  //           {checkPoints?.map((item, i) => {
  //             return (
  //               <li key={i}>
  //                 <div>
  //                   {item.descricao} - {item.responsavel}
  //                 </div>
  //                 <div>
  //                   {item.updated_at} - {item.checado ? "OK" : "NOK"}
  //                 </div>
  //               </li>
  //             )
  //           })}
  //         </ul>
  //       </div>
  //     )
  //   }
  // }

  function Content() {
    return (
      <>
        <div>
          <ListGroup>
            <ListGroup.Item
              style={{ backgroundColor: "#E8E8E8", color: "#636363" }}
            >
              <strong>Cliente:</strong>{" "}
              {props.detalhes
                ? props.detalhes.nome +
                " - " +
                props.detalhes.cpf +
                " - " +
                props.detalhes.celular
                : ""}
              <br />
              <strong>Tipo de Operação:</strong>{" "}
              {props.detalhes ? props.detalhes.operacao : ""}
              <br />
              <strong>Status Atual:</strong>{" "}
              {props.detalhes ? props.detalhes.posicao : ""}
              <strong> Data:</strong>{" "}
              {props.detalhes
                ? moment(props.detalhes.status_data).format("DD/MM/YYYY")
                : ""}
              <br />
              <strong>Valor R$:</strong>{" "}
              {props.detalhes ? formatCurrency(props.detalhes.valor_liquido) : ""}
              <strong> Parcela R$:</strong>{" "}
              {props.detalhes
                ? formatCurrency(props.detalhes.valor_parcelas)
                : ""}
              <strong> Prazo:</strong>{" "}
              {props.detalhes ? props.detalhes.prazo : ""}
              <br />
              <strong>Proposta:</strong>{" "}
              {props.detalhes ? props.detalhes.proposta_id : ""}
              <strong> Ccb:</strong>{" "}
              {props.detalhes ? props.detalhes.cedula_numero : ""}
              <strong> uuid:</strong>{" "}
              {props.detalhes ? props.detalhes.proposta_uuid : ""}
            </ListGroup.Item>
            {data &&
              data.map((option, i) => {
                let d = new Date(option.created_at);
                if (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) < 0 && option.checklist_id)
                  return true

                return (
                  <ListGroup.Item
                    key={i}
                    style={{ color: "#636363", padding: "0px" }}
                  >
                    <div className="row">
                      <div
                        className={` ${width() == "mobile" ? "col-12" : "col-9"
                          } `}
                      >
                        <div className="row ml-1 col-9 d-flex justify-content-between p-0 mt-2">
                          <div className="col-12">
                            <strong>Status</strong>
                            <br></br>
                            {option.log.replace(
                              /Alteração de status da Proposta - /i,
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row ml-1 col-12 mb-2">
                          <div className="row col-12">
                            <strong>
                              {option.usarioi ? "Usuário e Data" : "Data"}{" "}
                            </strong>
                          </div>
                          <div className="row col-12">
                            {option.usarioi ? (option.old_name || option.usarioi) + " | " : ""}
                            {moment(d).format("DD/MM/YYYY") +
                              " | " +
                              moment(d).format("LTS")}
                            {/* {option.created_at} */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={` mt-2 ${width() == "mobile" ? "col-12" : "col-3"
                          } `}
                      >
                        <div className="mb-1 col-12">
                          <Button
                            color="success"
                            style={{ width: "100%" }}
                            onClick={() => setGeolocalizacao(option)}
                          >
                            Geolocalização
                          </Button>
                        </div>
                        {['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0 &&
                          option.checklist_id && !checklist &&
                          <div className="mb-1 col-12">
                            <Button
                              color="info"
                              style={{ width: "100%" }}
                              onClick={() => handleChecklist(option)}
                            >
                              Checklist
                            </Button>
                          </div>
                        }
                        {option.pendenciaid &&
                          <div className="mb-1 col-12">
                            <Button
                              // className="btn btn-sm btn-secondary"
                              color="secondary"
                              style={{ width: "100%" }}
                              onClick={() => setPendencia(option)}
                            >
                              Pendência
                            </Button>
                          </div>
                        }
                        {(option.antifraude && (
                          <div className="col-12 mb-1">
                            <Button
                              color="info"
                              style={{ width: "100%" }}
                              onClick={() => setAntiFraude(true)}
                            >
                              AntiFraude
                            </Button>
                          </div>
                        )) ||
                          ""}
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </div>
      </>
    );
  }

  return (
    <>
      {checklist && <PreencherChecklist checklist={checklist} consulta={true} handleCloseEv={() => setChecklist(null)} />}
      {!antiFraude && !geolocalizacao && !pendencia && !checklist && (
        <ModalContent
          title="Histórico da Proposta"
          onCloseEv={handleClose}
          size="lg"
          concorda={false}
        >
          <Content />
        </ModalContent>
      )}
      {antiFraude && (
        <AntiFraude
          onCloseEv={() => {
            setAntiFraude(false);
          }}
          res={antiFraude}
        />
      )}
      {pendencia && (
        <HistoricoPendencia
          onCloseEv={() => {
            setPendencia(false);
          }}
          dados={pendencia}
        />
      )}
      {geolocalizacao && (
        <MapView
          data={geolocalizacao}
          onCloseEv={() => setGeolocalizacao(false)}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Log);
