import React, { useState, useEffect } from 'react'
import { format, subMonths } from 'date-fns'
import style from './dashboard.module.css'
import { useDashboardData } from './hooks/DashboardDataContext'

export default function SelectInputs({ data }) {
    const [startDate, setstartDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'))
    const [finalDate, setfinalDate] = useState(format(new Date(), 'yyyy-MM-dd'))

    const handleSearch = () => {
        setStartDate(startDate)
        setFinalDate(setFinalDate)
        getDashboardData(startDate, finalDate)
    }

    const { setStartDate, setFinalDate, getDashboardData, setConvenio, setAgente } = useDashboardData()


    return (
        <div className={`${style.selectInputsArea} row`}>
            <div className={`${style.selectContainer} justify-content-center`}>
                <div style={{ width: "100%" }}>
                    <input style={{ width: "100%" }} type="date" className="form-control" value={startDate} onChange={e => setstartDate(e.target.value)} />
                </div>
            </div>
            <div className={`${style.selectContainer} justify-content-center`}>
                <div style={{ width: "100%" }}>
                    <input style={{ width: "100%" }} type="date" className="form-control " value={finalDate} onChange={e => setfinalDate(e.target.value)} />
                </div>
            </div>

            <div className={`${style.selectContainer} `}>
                <select className={`form-select ${style.select}`} onChange={(e) => setAgente(e.currentTarget.value)}>
                    <option selected>Agente</option>
                    {(data?.correspondente.sort((a, b) => a.categoria.localeCompare(b.categoria || '')) || []).map((_correspondente, i) => {
                        return <option value={_correspondente.id}>{_correspondente.categoria}</option>
                    })}
                </select>
            </div>

            <div className={`${style.selectContainer} `}>
                <select className={`form-select ${style.select}`} onChange={(e) => setConvenio(e.currentTarget.value)}>
                    <option selected>Convênio</option>
                    {(data?.convenio.sort((a, b) => a.categoria.localeCompare(b.categoria || '') || []).map((_convenio, i) => {
                        return <option value={_convenio.id}>{_convenio.categoria}</option>
                    }))}
                </select>
            </div>

            <div className={`${style.selectContainer} `}>
                <button className="btn" style={{ background: "transparent", width: "100%" }} type="button" onClick={e => handleSearch()}>
                    <i className="mdi mdi-database-search" style={{ color: "#fff", fontSize: "22px" }} /><span style={{ color: "#fff", fontSize: "18px" }}> Pesquisar</span>
                </button>
            </div>
        </div>
    )
}
