import React from "react";
import { width } from "../helpers/general";
import { connect } from "react-redux";
import { Buttons } from "../components/layout";
import Logo from '../images/logos/financeira-brb.png';

function Status({ ...props }) {
  return (
    <>
      <div
        style={{
          overflowY: width() === "mobile" ? "auto" : "visible",
          minHeight: width() === "mobile" ? "700px" : "none",
          maxHeight: width() === "mobile" ? "700px" : "none",
          padding: "20px",
        }}
      >
        <h3 className="text-center">
          <img
            src={Logo}
            style={{ width: width() !== "mobile" ? "200px" : "150px" }}
          />
        </h3>

        <br />
        <br />
        <br />
        <div className="text-center" style={{ fontFamily: "Roboto Mono" }}>
          <h2
            className="text-justify"
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
              color: "#333",
            }}
          >
            Olá, Sr(a): <br />
            <div
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "#f0f0f0",
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                marginTop: "10px",
                color: "#000",
              }}
            >
              {props.detalhes?.cliente}
            </div>
          </h2>
          <h4 style={{ marginTop: "50px" }}>
            Obrigado por escolher a Financeira BRB.
          </h4>
          <h4>
            Em poucos cliques realize a contratação de seu empréstimo e receba o
            valor em conta.
          </h4>
        </div>
        <br />
        <br />

        <Buttons submit={() => props.toggleStep(1)} disabledFirst={true} />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(Status);


// import React, { useState, useEffect } from "react";
// import { width, } from "../helpers/general";
// import { connect } from "react-redux";
// import { Buttons } from "../components/layout";
// import Logo from '../images/logos/financeira-brb.png';


// function Status({ ...props }) {

//   return (
//     <>
//       <div style={{
//         // backgroundColor: '#f0f0f0', // fundo cinza claro
//         overflowY: width() === "mobile" ? 'auto' : 'visible',
//         minHeight: width() === "mobile" ? '600px' : 'none',
//         maxHeight: width() === "mobile" ? '600px' : 'none',
//         // height: '100vh',
//         padding: '20px',
//       }}>
//         <h3 className="text-center ">
//           <img
//             src={Logo}
//             style={{ width: width() !== "mobile" ? "200px" : "150px", }}
//           />
//         </h3>

//         <br />
//         <br />
//         <div className="text-center" style={{ fontFamily: "Roboto Mono" }}>
//           <h2 >
//             Olá, Sr(a): {props.detalhes?.cliente}
//           </h2>
//           <h4 style={{ marginTop: "50px" }}>Obrigado por escolher a Financeira BRB.</h4>
//           <h4>Em poucos cliques realize a contratação de seu empréstimo e receba o valor em conta.</h4>
//         </div>
//         <br />
//         <br />

//         <Buttons submit={() => props.toggleStep(1)} disabledFirst={true} />
//       </div>
//     </>
//   );
// }


// function mapStateToProps(state) {
//   return {
//     proposta: state.dados.proposta,
//     detalhes: state.dados.detalhes,
//   };
// }

// function mapActionToProps(dispatch) {
//   return {

//   };
// }

// export default connect(mapStateToProps, mapActionToProps)(Status);
