import React, { useState, useEffect, useCallback } from "react";
import Form from "../../components/form/admin/pessoas";
import { cidades } from "../../helpers/cidades";
import { bancos } from "../../helpers/bancos";
import { getData, sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import { obterDominio } from "../../helpers/original";

import {
  DefaultOrder,
  LOGRADOUROS,
  HORARIOLIST,
  sortByString,
  sortByNumber,
  compareDate,
} from "../../helpers/general";
function Pessoas({ ...props }) {
  let estados = cidades();
  let logradouros = LOGRADOUROS.replace(/\s/g, "").split(",");
  let _bancos = bancos();

  const [pessoasC, setPessoasC] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [total, setTotal] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState(false);
  const [dateVisible, setDateVisible] = useState("todos");
  const [modal, setModal] = useState(false);
  const [clickedTr, setClickedTr] = useState("");
  const [ascDesc, setAscDesc] = useState(true);
  const [pessoa_filter, setPessoaFilter] = useState("");
  const [tipo_renda, setTipoRenda] = useState(obterDominio("TIPO_FONTE_RENDA"));
  const [ocupacao, setOcupacao] = useState(obterDominio("OCUPACAO"));
  const [profissao, setProfissao] = useState(obterDominio("PROFISSAO"));
  const [escolaridade, setEscolaridade] = useState(
    obterDominio("ESCOLARIDADE")
  );
  const [atividade, setAtividade] = useState(obterDominio("TIPO_ATIVIDADE"));
  const [tax_ir, setTaxIR] = useState(obterDominio("IR_IOF"));
  const [pep, setPep] = useState(obterDominio("PEP"));
  const [situacao_documento, setSituacaoDocumento] = useState(
    obterDominio("SITUACAO_CPF")
  );
  const [permitir_bacen, setPermitirBacen] = useState(obterDominio("PEP"));
  const [permitir_pcam, setPermitirPcam] = useState(obterDominio("PEP"));
  const [conjuge_regime, setConjugeRegime] = useState(
    obterDominio("CONJUGE_REGIME")
  );
  const [tipos_endereco, setTiposEndereco] = useState(
    obterDominio("TIPO_ENDERECO")
  );
  const [noLimit, setNolimit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOption = (e) => {
    let label = e.currentTarget.dataset.label || e.currentTarget.name;
    let value = e.currentTarget.value;
    let newDetailed;
    console.log("pessoas - handleOption", value);

    if (value !== "todos" && value !== "") {
      newDetailed = pessoasC.filter((pessoas) => {
        if (label === "pessoa_filter") {
          let result =
            (pessoas["cpf"] &&
              value.replace(/\D/g, "") != "" &&
              pessoas["cpf"]
                .replace(/\D/g, "")
                .indexOf(value.replace(/\D/g, "")) >= 0) ||
            (pessoas["nome"] &&
              pessoas["nome"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (pessoas["email"] &&
              pessoas["email"].toLowerCase().indexOf(value.toLowerCase()) >= 0);
          return result;
        } else return pessoas[label] === value;
      });

      setPessoas(newDetailed);
      setActualPage(0);
    } else {
      setPessoas(pessoasC);
      setActualPage(0);
    }
  };

  const handleLimpar = () => {
    setPessoaFilter("");
    setPessoas([])
    setPessoasC([])
    setFilterOptions(false)
  }

  const handlePessoa = (e) => {
    let value = e.currentTarget.value;
    setPessoaFilter(value);
    // handleOption(e);
  };

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  useEffect(() => {
    console.log("pessoas(viewsAdmin).useEffect: ", props.estados)
  }, [])

  const createFilter = (obj) => {
    let newObj = {
      nome: [],
      cpf: [],
      email: [],
      tipo: [],
      status: [],
      cidade: [],
      uf: [],
    };

    for (let o of obj) {
      for (let i in o) {
        if (newObj[i]) {
          newObj[i].push(o[i]);
        }
      }
    }

    for (let i in newObj) {
      newObj[i] = [...new Set(newObj[i])];
    }

    setFilterOptions(newObj);
  };

  const _pessoas = useCallback(() => {
    setLoading(true)
    let content = { filtro: pessoa_filter, noLimit }
    sendData({ uri: uriList("listarPessoas"), content, method: "POST" })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        DefaultOrder(
          "pessoas",
          res.map((pessoas) => pessoas),
          setPessoas
        );
        createFilter(res.map((pessoas) => pessoas));
        setPessoasC(res);
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        return false;
      });
  });

  // noLimit
  const handleNoLimit = (e) => {
    setNolimit(e.currentTarget.value);
  }

  const handlePesquisar = async (e) => {
    setLoading(true)
    const content = { filtro: pessoa_filter, noLimit }
    sendData({ uri: uriList("listarPessoas"), content, method: "POST" })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        DefaultOrder(
          "pessoas",
          res.map((pessoas) => pessoas),
          setPessoas
        );
        createFilter(res.map((pessoas) => pessoas));
        setPessoasC(res);
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        return false;
      });
  }

  // useEffect(() => {
  //   _pessoas();
  // }, []);

  // useEffect(() => {
  //   _pessoas();
  // }, [noLimit]);

  const sortArrObj = (key, type) => {
    let arr = [...pessoas];
    let asc = !ascDesc;

    arr = arr.sort((a, b) => {
      if (type === "string") {
        if (asc) {
          setAscDesc(asc);
          return sortByString(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByString(b, a, key, type);
        }
      } else if (type === "numeric") {
        if (asc) {
          setAscDesc(asc);
          return sortByNumber(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByNumber(b, a, key, type);
        }
      } else {
        if (asc) {
          setAscDesc(asc);
          return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
        } else {
          setAscDesc(asc);
          return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
        }
      }
    });

    setPessoas(arr);
  };

  return (
    <>
      <Form
        filterOptions={filterOptions}
        handleOption={handleOption}
        setDateVisible={setDateVisible}
        setModal={setModal}
        handlePessoa={handlePessoa}
        setClickedTr={setClickedTr}
        clickedTr={clickedTr}
        setPessoas={setPessoas}
        pessoas={pessoas}
        pessoasC={pessoasC}
        sortArrObj={sortArrObj}
        actualPage={actualPage}
        handlePage={handlePage}
        dateVisible={dateVisible}
        estados={estados}
        logradouros={logradouros}
        horarioList={HORARIOLIST}
        pessoa_filter={pessoa_filter}
        bancos={_bancos}
        total={total}
        callback={_pessoas}
        context={props.context}
        tipo_renda={tipo_renda}
        ocupacao={ocupacao}
        profissao={profissao}
        escolaridade={escolaridade}
        atividade={atividade}
        tax_ir={tax_ir}
        pep={pep}
        situacao_documento={situacao_documento}
        permitir_bacen={permitir_bacen}
        permitir_pcam={permitir_pcam}
        conjuge_regime={conjuge_regime}
        tipos_endereco={tipos_endereco}
        handlePesquisar={handlePesquisar}
        noLimit={noLimit}
        setNolimit={handleNoLimit}
        loading={loading}
        handleLimpar={handleLimpar}
      />

      {modal}
    </>
  );
}

export default Pessoas;
