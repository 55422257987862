import React, { useState, useEffect, useCallback } from "react";

import Form from '../../components/form/admin/configuracoes';

function Configuracoes({ ...props }) {
    const [data, setData] = useState({})

    useEffect(() => {

    }, []);


    return (
        <>
            <Form
                data={data}
            />

        </>
    );
};

export default Configuracoes;
