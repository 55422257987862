import React, { useState, useEffect, useCallback } from "react";
import { Button } from "reactstrap";
import { sendData } from "../../../../helpers/auth";
import { Aguarde } from "../../../../helpers/general";
import { uriList, } from "../../../../helpers/config";
import Card from "../CardDocs/Card";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import Upload from "./uploadDocumentos";

const Form = ({ ...props }) => {
  const [deleteDoc, setDeleteDoc] = useState(false);
  const [message, setMessage] = useState("Carregando documentos...");
  const [upload, setUpload] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [load, setLoad] = useState(false);

  const handleUpload = () => {
    setUpload(false);
    setMessage("Carregando documentos...");
    _documentos();
  };

  const WaitDelete = () => {
    return <Aguarde />;
  };

  const aprovarDocumentos = () => {
    setMessage("aprovarDocumentos...");
    console.log("aprovarDocumentos(0)");

    sendData({
      uri: uriList("aprovardocumentos"),
      proposta_uuid: props.proposta,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        console.log("aprovarDocumentos(1)");
        // _documentos();
      })
      .catch((e) => {
        setMessage("");
        console.log("aprovarDocumentos(erro)");
        return false;
      });
  }

  const uploadNuvem = () => {
    setMessage("Fazendo upload dos documentos para nuvem...");
    console.log("documentos-nuvem (0)");
    sendData({
      uri: uriList("documentos-nuvem"),
      method: "POST",
    })
      .then((res) => {
        setMessage("");
        console.log("documentos-nuvem (1)");
        _documentos();
      })
      .catch((e) => {
        setMessage("");
        console.log("documentos-nuvem (erro)");
        return false;
      });
  };

  const _documentos = async () => {
    setLoad(false);
    let uri = uriList("documentos-list");
    console.log("Documentos.gerirDocumentos(_documentos): ", uri);
    await sendData({
      uri,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        if (res.length > 0) {
          setDocumentos(res);
          setMessage("");
          setLoad(true);
          console.log("Documentos.gerirDocumentos(res): ", res);
        } else {
          setMessage("Não existem documentos!");
          setDocumentos(false);
        }
      })
      .catch((e) => {
        setMessage("Erro ao buscar documentos!");
        return false;
      });
  };

  useEffect(() => {
    _documentos();
  }, []);

  const handleClose = () => {
    if (props.onCloseEv) props.onCloseEv();
  };

  const handleDelete = (doc) => {
    setDeleteDoc(true);
    sendData({
      uri: uriList("documentos-remove") + "/" + doc.id,
      content: { id: doc.id },
      method: "DELETE",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        setDeleteDoc(false);
        _documentos();
      })
      .catch((e) => {
        setDeleteDoc(false);
        console.log("Erro ao remover: ", e);
      });
  };

  const Render = () => {
    return (
      <>
        {message ? (
          <div>{message}</div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              justifyContent: "center",
            }}
            name="modalContainer"
          >
            {documentos.map((row, i) => {
              return (
                <>
                  <Card
                    titulo={row.tipo}
                    url={row.url}
                    descricao={row.descricao || row.tipo}
                    onDelete={() => {
                      handleDelete(row);
                    }}
                    normal
                  />
                </>
              );
            })}
          </div>
        )}{" "}
      </>
    );
  };

  return (
    <>
      {upload ? (
        <Upload onCloseEv={handleUpload} />
      ) : (
        <>
          <Modal
            size="md"
            show={true}
            onHide={handleClose}
            centered
            animation={false}
            scrollable
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Documentos</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="col-12">
              <Render />
            </Modal.Body>
            <Modal.Footer>
              {props.user?.tipo != "AGENTE" && (
                <Button color="success" onClick={aprovarDocumentos}>
                  Aprovar
                </Button>
              )}
              {load && documentos && documentos.length > 0 && (
                <Button color="success" onClick={uploadNuvem}>
                  {" "}
                  Nuvem{" "}
                </Button>
              )}
              {props.user?.tipo != "AGENTE" && (
                <Button color="success" onClick={() => setUpload(true)}>
                  Upload
                </Button>
              )}

              <Button color="secondary" onClick={handleClose}>
                {" "}
                Fechar{" "}
              </Button>
            </Modal.Footer>
          </Modal>

          {deleteDoc && <WaitDelete />}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);
