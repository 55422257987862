import React from "react";

import { InputAlert, InputLabelAlert } from "../../layout";

const Form = ({ ...props }) => (
  <>
    <div className="row">
      <div className="form-group col-md-4">
        <InputLabelAlert
          type="text"
          label="Apelido"
          id="apelido"
          length="50"
          minLength={3}
          warning={props.warning.apelido}
          value={props.data.apelido}
          handle={props.handle}
          focus={true}
        />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="escolaridade" className="no-margin">
          Escolaridade
        </label>
        <select
          id="escolaridade"
          name="escolaridade"
          value={props.data.escolaridade}
          onChange={props.handle}
          style={{
            borderColor: props.warning.escolaridade ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.escolaridade &&
            props.escolaridade.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="profissao" className="no-margin">
          Profissão
        </label>
        <select
          id="profissao"
          name="profissao"
          value={props.data.profissao}
          onChange={props.handle}
          style={{
            borderColor: props.warning.profissao ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.profissao &&
            props.profissao.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-5">
        <InputLabelAlert
          type="text"
          label="Nome do Conjuge"
          id="conjuge_nome"
          length="50"
          minLength={3}
          warning={props.warning.conjuge_nome}
          value={props.data.conjuge_nome}
          handle={props.handle}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Cpf do Conjuge"
          id="conjuge_cpf"
          value={props.data.conjuge_cpf}
          handle={props.handle}
          warning={props.warning.conjuge_cpf}
          autoComplete="off"
          length="24"
          msgErro={"Cpf inválido!"}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <div className="form-group col-md-4">
        <label htmlFor="conjuge_regime" className="no-margin">
          Regime de Comunhão
        </label>
        <select
          id="conjuge_regime"
          name="conjuge_regime"
          value={props.data.conjuge_regime}
          onChange={props.handle}
          style={{
            borderColor: props.warning.conjuge_regime ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.conjuge_regime &&
            props.conjuge_regime.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.SIGLA}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="text"
          label="Referência 1 - Nome"
          id="referencia1_nome"
          value={props.data.referencia1_nome}
          handle={props.handle}
          warning={props.warning.referencia1_nome}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Ref. 1 - Telefone"
          id="referencia1_telefone"
          value={props.data.referencia1_telefone}
          handle={props.handle}
          warning={props.warning.referencia1_telefone}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="text"
          label="Referência 2 - Nome"
          id="referencia2_nome"
          value={props.data.referencia2_nome}
          handle={props.handle}
          warning={props.warning.referencia2_nome}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Ref. 2 - Telefone"
          id="referencia2_telefone"
          value={props.data.referencia2_telefone}
          handle={props.handle}
          warning={props.warning.referencia2_telefone}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Qtdade Dependentes"
          id="dependentes"
          value={props.data.dependentes}
          step="0.020"
          handle={props.handle}
          warning={props.warning.dependentes}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Telefone RH"
          id="telefone_rh"
          value={props.data.telefone_rh}
          handle={props.handle}
          warning={props.warning.telefone_rh}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Data de Admissão"
          id="data_admissao"
          value={props.data.data_admissao}
          handle={props.handleDate}
          warning={props.warning.data_admissao}
          msgErro={"Data inválida!"}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Telefone Comercial"
          id="telefone_comercial"
          value={props.data.telefone_comercial}
          handle={props.handle}
          warning={props.warning.telefone_comercial}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
    </div>
  </>
);

export default Form;
