import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'reactstrap';

export default (props) => {
  function toggleClose() {
    if (props.onCloseEv)
      props.onCloseEv()
  }

  function toggleAccept() {
    if (props.onAccept)
      props.onAccept()
  }

  function Content() {
    return (
      <>
        <Modal size={props.size ? props.size : 'md'} scrollable show={true} centered animation={false} backdrop="static">
          {props.title ? <Modal.Header style={{ fontSize: 30, padding: 1, color: "#4B4C4D", justifyContent: "center", backgroundColor: "lightGray" }}>
            <strong>{props.title}</strong></Modal.Header> : ""}

          <Modal.Body className="maxWidth750"
            style={{ width: '100%', align: "center", maxHeigth: "400px", textAlign: "justify" }}
          >
            {props.children}
          </Modal.Body>
          <Modal.Footer style={{ padding: "0px", display: "flex", backgroundColor: "#EAEAEA" }}>
            {props.concorda &&
              <Button color="primary" onClick={toggleAccept} style={{ marginRight: "auto", }}>
                Eu li e Concordo
              </Button>
            }
            {props.showInfo &&
              <Button color="primary" onClick={() => props.showInfo()} style={{ marginRight: "auto", }}>
                Exibir
              </Button>
            }
            {props.procredito &&
              <Button color="primary" onClick={toggleAccept} style={{ marginRight: "auto", }} title="Integrar com o Procrédito">
                Procredito
              </Button>
            }
            <Button color="secondary" onClick={toggleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </ >
    )
  }

  return (
    <Content />
  )
}
