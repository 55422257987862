let dominios = [
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "86",
    DESCRICAO: "DESCRICAO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "87",
    DESCRICAO: "ENSINO FUNDAMENTAL COMPLETO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "88",
    DESCRICAO: "ENSINO MEDIO (2.ºGRAU)",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "89",
    DESCRICAO: "EDUCACAO SUPERIOR",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "90",
    DESCRICAO: "MESTRADO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "91",
    DESCRICAO: "DOUTORADO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4130",
    DESCRICAO: "ENSINO SUPERIOR COM PÓS GRADUAÇÃO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4131",
    DESCRICAO: "NÃO ALFABETIZADO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4132",
    DESCRICAO: "ALFABETIZADO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4133",
    DESCRICAO: "ENSINO FUNDAMENTAL ATE 5º ANO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4134",
    DESCRICAO: "ANTIGO GINÁSIO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4135",
    DESCRICAO: "ANTIGO CIENTÍFICO, CLÁSSICO OU NORMAL",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "5017",
    DESCRICAO: "NÃO IDENTIFICADO",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "93",
    DESCRICAO: "COMPANHEIRO(A)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "94",
    DESCRICAO: "DIVORCIADO(A)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "95",
    DESCRICAO: "SEPARADO(A)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "96",
    DESCRICAO: "SOLTEIRO(A)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "97",
    DESCRICAO: "UNIAO ESTAVEL",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "98",
    DESCRICAO: "VIUVO(A)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3068",
    DESCRICAO: "CASADO(A) - COMUNHÃO PARCIAL DE BENS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3069",
    DESCRICAO: "CASADO(A) - COMUNHÃO UNIVERSAL DE BENS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3071",
    DESCRICAO: "CASADO(A) - SEPARACAO DE BENS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3182",
    DESCRICAO: "CASADO(A)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3183",
    DESCRICAO: "OUTROS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3715",
    DESCRICAO: "CASADO(A) - SEPARACAO DE BENS (OBRIG.)",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "4136",
    DESCRICAO: "CASADO(A) COM PARTICIPAÇÃO FINAL DOS AQUESTOS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "5019",
    DESCRICAO: "NÃO IDENTIFICADO",
  },
  {
    DOMINIO: "IR_IOF",
    CODIGO: "6",
    DESCRICAO: "Isento",
    SIGLA: "IR",
  },
  {
    DOMINIO: "IR_IOF",
    CODIGO: "7",
    DESCRICAO: "Não isento",
    SIGLA: "IR",
  },
  {
    DOMINIO: "IR_IOF",
    CODIGO: "8",
    DESCRICAO: "Isento",
    SIGLA: "IOF",
  },
  {
    DOMINIO: "IR_IOF",
    CODIGO: "9",
    DESCRICAO: "Não isento",
    SIGLA: "IOF",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1",
    DESCRICAO: "ABADIA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2",
    DESCRICAO: "ABADIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3",
    DESCRICAO: "ACREUNA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4",
    DESCRICAO: "ADELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5",
    DESCRICAO: "AGUA FRIA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "6",
    DESCRICAO: "AGUA LIMPA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "7",
    DESCRICAO: "AGUAS LINDAS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "8",
    DESCRICAO: "ALEXANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "9",
    DESCRICAO: "ALOANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "10",
    DESCRICAO: "ALTO HORIZONTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "11",
    DESCRICAO: "ALTO PARAISO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "12",
    DESCRICAO: "ALVORADA DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "13",
    DESCRICAO: "AMARALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "14",
    DESCRICAO: "AMERICANO DO BRASIL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "15",
    DESCRICAO: "AMORINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "16",
    DESCRICAO: "ANAPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "17",
    DESCRICAO: "ANHANGUERA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "18",
    DESCRICAO: "ANICUNS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "19",
    DESCRICAO: "APARECIDA DE GOIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "20",
    DESCRICAO: "APARECIDA DO RIO DOCE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "21",
    DESCRICAO: "APORE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "22",
    DESCRICAO: "ARACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "23",
    DESCRICAO: "ARAGARCAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "24",
    DESCRICAO: "ARAGOIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "25",
    DESCRICAO: "ARAGUAPAZ",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "26",
    DESCRICAO: "ARENOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "27",
    DESCRICAO: "ARUANA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "28",
    DESCRICAO: "AURILANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "29",
    DESCRICAO: "AVELINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "30",
    DESCRICAO: "BALIZA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "31",
    DESCRICAO: "BARRO ALTO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "32",
    DESCRICAO: "BELA VISTA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "33",
    DESCRICAO: "BOM JARDIM DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "34",
    DESCRICAO: "BOM JESUS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "35",
    DESCRICAO: "BONFINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "36",
    DESCRICAO: "BONOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "37",
    DESCRICAO: "BRAZABRANTES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "38",
    DESCRICAO: "BRITANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "39",
    DESCRICAO: "BURITI ALEGRE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "40",
    DESCRICAO: "BURITI DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "41",
    DESCRICAO: "BURITINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "42",
    DESCRICAO: "CABECEIRAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "43",
    DESCRICAO: "CACHOEIRA ALTA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "44",
    DESCRICAO: "CACHOEIRA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "45",
    DESCRICAO: "CACHOEIRA DOURADA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "46",
    DESCRICAO: "CACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "47",
    DESCRICAO: "CAIAPONIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "48",
    DESCRICAO: "CALDAS NOVAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "49",
    DESCRICAO: "CALDAZINHA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "50",
    DESCRICAO: "CAMPESTRE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "51",
    DESCRICAO: "CAMPINACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "52",
    DESCRICAO: "CAMPINORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "53",
    DESCRICAO: "CAMPO ALEGRE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "54",
    DESCRICAO: "CAMPO LIMPO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "55",
    DESCRICAO: "CAMPOS BELOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "56",
    DESCRICAO: "CAMPOS VERDES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "57",
    DESCRICAO: "CARMO DO RIO VERDE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "58",
    DESCRICAO: "CASTELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "59",
    DESCRICAO: "CATALAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "60",
    DESCRICAO: "CATURAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "61",
    DESCRICAO: "CAVALCANTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "62",
    DESCRICAO: "CERES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "63",
    DESCRICAO: "CEZARINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "64",
    DESCRICAO: "CHAPADAO DO CEU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "65",
    DESCRICAO: "CIDADE OCIDENTAL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "66",
    DESCRICAO: "COCALZINHO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "67",
    DESCRICAO: "COLINAS DO SUL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "68",
    DESCRICAO: "CORREGO DO OURO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "69",
    DESCRICAO: "CORUMBA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "70",
    DESCRICAO: "CORUMBAIBA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "71",
    DESCRICAO: "CRISTALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "72",
    DESCRICAO: "CRISTIANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "73",
    DESCRICAO: "CRIXAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "74",
    DESCRICAO: "CROMINIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "75",
    DESCRICAO: "CUMARI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "76",
    DESCRICAO: "DAMIANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "77",
    DESCRICAO: "DAMOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "78",
    DESCRICAO: "DAVINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "79",
    DESCRICAO: "DIORAMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "80",
    DESCRICAO: "DOVERLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "81",
    DESCRICAO: "EDEALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "82",
    DESCRICAO: "EDEIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "83",
    DESCRICAO: "ESTRELA DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "84",
    DESCRICAO: "FAINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "85",
    DESCRICAO: "FAZENDA NOVA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "86",
    DESCRICAO: "FIRMINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "87",
    DESCRICAO: "FLORES DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "88",
    DESCRICAO: "FORMOSA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "89",
    DESCRICAO: "FORMOSO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "90",
    DESCRICAO: "GAMELEIRA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "91",
    DESCRICAO: "DIVINOPOLIS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "92",
    DESCRICAO: "GOIANAPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "93",
    DESCRICAO: "GOIANDIRA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "94",
    DESCRICAO: "GOIANESIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "95",
    DESCRICAO: "GOIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "96",
    DESCRICAO: "GOIANIRA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "97",
    DESCRICAO: "GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "98",
    DESCRICAO: "GOIATUBA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "99",
    DESCRICAO: "GOUVELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "100",
    DESCRICAO: "GUAPO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "101",
    DESCRICAO: "GUARAITA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "102",
    DESCRICAO: "GUARANI DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "103",
    DESCRICAO: "GUARINOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "104",
    DESCRICAO: "HEITORAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "105",
    DESCRICAO: "HIDROLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "106",
    DESCRICAO: "HIDROLINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "107",
    DESCRICAO: "IACIARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "108",
    DESCRICAO: "INACIOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "109",
    DESCRICAO: "INDIARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "110",
    DESCRICAO: "INHUMAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "111",
    DESCRICAO: "IPAMERI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "112",
    DESCRICAO: "IPIRANGA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "113",
    DESCRICAO: "IPORA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "114",
    DESCRICAO: "ISRAELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "115",
    DESCRICAO: "ITABERAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "116",
    DESCRICAO: "ITAGUARI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "117",
    DESCRICAO: "ITAGUARU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "118",
    DESCRICAO: "ITAJA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "119",
    DESCRICAO: "ITAPACI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "120",
    DESCRICAO: "ITAPIRAPUA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "121",
    DESCRICAO: "ITAPURANGA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "122",
    DESCRICAO: "ITARUMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "123",
    DESCRICAO: "ITAUCU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "124",
    DESCRICAO: "ITUMBIARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "125",
    DESCRICAO: "IVOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "126",
    DESCRICAO: "JANDAIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "127",
    DESCRICAO: "JARAGUA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "128",
    DESCRICAO: "JATAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "129",
    DESCRICAO: "JAUPACI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "130",
    DESCRICAO: "JESUPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "131",
    DESCRICAO: "JOVIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "132",
    DESCRICAO: "JUSSARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "133",
    DESCRICAO: "LAGOA SANTA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "134",
    DESCRICAO: "LEOPOLDO DE BULHOES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "135",
    DESCRICAO: "LUZIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "136",
    DESCRICAO: "MAIRIPOTABA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "137",
    DESCRICAO: "MAMBAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "138",
    DESCRICAO: "MARA ROSA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "139",
    DESCRICAO: "MARZAGAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "140",
    DESCRICAO: "MATRINCHA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "141",
    DESCRICAO: "MAURILANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "142",
    DESCRICAO: "MIMOSO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "143",
    DESCRICAO: "MINACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "144",
    DESCRICAO: "MINEIROS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "145",
    DESCRICAO: "MOIPORA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "146",
    DESCRICAO: "MONTE ALEGRE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "147",
    DESCRICAO: "MONTES CLAROS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "148",
    DESCRICAO: "MONTIVIDIU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "149",
    DESCRICAO: "MONTIVIDIU DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "150",
    DESCRICAO: "MORRINHOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "151",
    DESCRICAO: "MORRO AGUDO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "152",
    DESCRICAO: "MOSSAMEDES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "153",
    DESCRICAO: "MOZARLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "154",
    DESCRICAO: "MUNDO NOVO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "155",
    DESCRICAO: "MUTUNOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "156",
    DESCRICAO: "NAZARIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "157",
    DESCRICAO: "NEROPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "158",
    DESCRICAO: "NIQUELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "159",
    DESCRICAO: "NOVA AMERICA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "160",
    DESCRICAO: "NOVA AURORA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "161",
    DESCRICAO: "NOVA CRIXAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "162",
    DESCRICAO: "NOVA GLORIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "163",
    DESCRICAO: "NOVA IGUACU DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "164",
    DESCRICAO: "NOVA ROMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "165",
    DESCRICAO: "NOVA VENEZA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "166",
    DESCRICAO: "NOVO BRASIL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "167",
    DESCRICAO: "NOVO GAMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "168",
    DESCRICAO: "NOVO PLANALTO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "169",
    DESCRICAO: "ORIZONA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "170",
    DESCRICAO: "OURO VERDE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "171",
    DESCRICAO: "OUVIDOR",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "172",
    DESCRICAO: "PADRE BERNARDO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "173",
    DESCRICAO: "PALESTINA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "174",
    DESCRICAO: "PALMEIRAS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "175",
    DESCRICAO: "PALMELO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "176",
    DESCRICAO: "PALMINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "177",
    DESCRICAO: "PANAMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "178",
    DESCRICAO: "PARANAIGUARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "179",
    DESCRICAO: "PARAUNA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "180",
    DESCRICAO: "PEROLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "181",
    DESCRICAO: "PETROLINA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "182",
    DESCRICAO: "PILAR DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "183",
    DESCRICAO: "PIRACANJUBA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "184",
    DESCRICAO: "PIRANHAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "185",
    DESCRICAO: "PIRENOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "186",
    DESCRICAO: "PIRES DO RIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "187",
    DESCRICAO: "PLANALTINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "188",
    DESCRICAO: "PONTALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "189",
    DESCRICAO: "PORANGATU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "190",
    DESCRICAO: "PORTEIRAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "191",
    DESCRICAO: "PORTELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "192",
    DESCRICAO: "POSSE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "193",
    DESCRICAO: "PROFESSOR JAMIL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "194",
    DESCRICAO: "QUIRINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "195",
    DESCRICAO: "RIALMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "196",
    DESCRICAO: "RIANAPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "197",
    DESCRICAO: "RIO QUENTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "198",
    DESCRICAO: "RIO VERDE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "199",
    DESCRICAO: "RUBIATABA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "200",
    DESCRICAO: "SANCLERLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "201",
    DESCRICAO: "SANTA BARBARA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "202",
    DESCRICAO: "SANTA CRUZ DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "203",
    DESCRICAO: "SANTA FE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "204",
    DESCRICAO: "SANTA HELENA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "205",
    DESCRICAO: "SANTA ISABEL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "206",
    DESCRICAO: "SANTA RITA DO ARAGUAIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "207",
    DESCRICAO: "SANTA RITA DO NOVO DESTINO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "208",
    DESCRICAO: "SANTA ROSA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "209",
    DESCRICAO: "SANTA TEREZA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "210",
    DESCRICAO: "SANTA TEREZINHA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "211",
    DESCRICAO: "SANTO ANTONIO DA BARRA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "212",
    DESCRICAO: "SANTO ANTONIO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "213",
    DESCRICAO: "SANTO ANTONIO DO DESCOBERTO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "214",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "215",
    DESCRICAO: "SAO FRANCISCO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "216",
    DESCRICAO: "SAO JOAO DALIANCA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "217",
    DESCRICAO: "SAO JOAO DA PARAUNA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "218",
    DESCRICAO: "SAO LUIS DE MONTES BELOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "219",
    DESCRICAO: "SAO LUIZ DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "220",
    DESCRICAO: "SAO MIGUEL DO ARAGUAIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "221",
    DESCRICAO: "SAO MIGUEL DO PASSA QUATRO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "222",
    DESCRICAO: "SAO PATRICIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "223",
    DESCRICAO: "SAO SIMAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "224",
    DESCRICAO: "SENADOR CANEDO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "225",
    DESCRICAO: "SERRANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "226",
    DESCRICAO: "SILVANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "227",
    DESCRICAO: "SIMOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "228",
    DESCRICAO: "SITIO DABADIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "229",
    DESCRICAO: "TAQUARAL DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "230",
    DESCRICAO: "TERESINA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "231",
    DESCRICAO: "TEREZOPOLIS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "232",
    DESCRICAO: "TRES RANCHOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "233",
    DESCRICAO: "TRINDADE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "234",
    DESCRICAO: "TROMBAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "235",
    DESCRICAO: "TURVANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "236",
    DESCRICAO: "TURVELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "237",
    DESCRICAO: "UIRAPURU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "238",
    DESCRICAO: "URUACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "239",
    DESCRICAO: "URUANA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "240",
    DESCRICAO: "URUTAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "241",
    DESCRICAO: "VALPARAISO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "242",
    DESCRICAO: "VARJAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "243",
    DESCRICAO: "VIANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "244",
    DESCRICAO: "VICENTINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "245",
    DESCRICAO: "VILA BOA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "246",
    DESCRICAO: "VILA PROPICIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "247",
    DESCRICAO: "ACORIZAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "248",
    DESCRICAO: "AGUA BOA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "249",
    DESCRICAO: "ALTA FLORESTA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "250",
    DESCRICAO: "ALTO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "251",
    DESCRICAO: "ALTO BOA VISTA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "252",
    DESCRICAO: "ALTO GARCAS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "253",
    DESCRICAO: "ALTO PARAGUAI",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "254",
    DESCRICAO: "ALTO TAQUARI",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "255",
    DESCRICAO: "APIACAS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "256",
    DESCRICAO: "ARAGUAIANA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "257",
    DESCRICAO: "ARAGUAINHA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "258",
    DESCRICAO: "ARAPUTANGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "259",
    DESCRICAO: "ARENAPOLIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "260",
    DESCRICAO: "ARIPUANA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "261",
    DESCRICAO: "BARAO DE MELGACO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "262",
    DESCRICAO: "BARRA DO BUGRES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "263",
    DESCRICAO: "BARRA DO GARCAS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "264",
    DESCRICAO: "BOM JESUS DO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "265",
    DESCRICAO: "BRASNORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "266",
    DESCRICAO: "CACERES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "267",
    DESCRICAO: "CAMPINAPOLIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "268",
    DESCRICAO: "CAMPO NOVO DO PARECIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "269",
    DESCRICAO: "CAMPO VERDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "270",
    DESCRICAO: "CAMPOS DE JULIO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "271",
    DESCRICAO: "CANABRAVA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "272",
    DESCRICAO: "CANARANA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "273",
    DESCRICAO: "CARLINDA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "274",
    DESCRICAO: "CASTANHEIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "275",
    DESCRICAO: "CHAPADA DOS GUIMARAES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "276",
    DESCRICAO: "CLAUDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "277",
    DESCRICAO: "COCALINHO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "278",
    DESCRICAO: "COLIDER",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "279",
    DESCRICAO: "COLNIZA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "280",
    DESCRICAO: "COMODORO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "281",
    DESCRICAO: "CONFRESA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "282",
    DESCRICAO: "CONQUISTA DOESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "283",
    DESCRICAO: "COTRIGUACU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "284",
    DESCRICAO: "CUIABA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "285",
    DESCRICAO: "CURVELANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "286",
    DESCRICAO: "DENISE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "287",
    DESCRICAO: "DIAMANTINO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "288",
    DESCRICAO: "DOM AQUINO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "289",
    DESCRICAO: "FELIZ NATAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "290",
    DESCRICAO: "FIGUEIROPOLIS DOESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "291",
    DESCRICAO: "GAUCHA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "292",
    DESCRICAO: "GENERAL CARNEIRO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "293",
    DESCRICAO: "GLORIA DOESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "294",
    DESCRICAO: "GUARANTA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "295",
    DESCRICAO: "GUIRATINGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "296",
    DESCRICAO: "INDIAVAI",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "297",
    DESCRICAO: "IPIRANGA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "298",
    DESCRICAO: "ITANHANGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "299",
    DESCRICAO: "ITAUBA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "300",
    DESCRICAO: "ITIQUIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "301",
    DESCRICAO: "JACIARA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "302",
    DESCRICAO: "JANGADA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "303",
    DESCRICAO: "JAURU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "304",
    DESCRICAO: "JUARA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "305",
    DESCRICAO: "JUINA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "306",
    DESCRICAO: "JURUENA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "307",
    DESCRICAO: "JUSCIMEIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "308",
    DESCRICAO: "LAMBARI DOESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "309",
    DESCRICAO: "LUCAS DO RIO VERDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "310",
    DESCRICAO: "LUCIARA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "311",
    DESCRICAO: "VILA BELA DA SANTISSIMA TRINDADE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "312",
    DESCRICAO: "MARCELANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "313",
    DESCRICAO: "MATUPA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "314",
    DESCRICAO: "MIRASSOL DOESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "315",
    DESCRICAO: "NOBRES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "316",
    DESCRICAO: "NORTELANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "317",
    DESCRICAO: "NOSSA SENHORA DO LIVRAMENTO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "318",
    DESCRICAO: "NOVA BANDEIRANTES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "319",
    DESCRICAO: "NOVA NAZARE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "320",
    DESCRICAO: "NOVA LACERDA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "321",
    DESCRICAO: "NOVA SANTA HELENA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "322",
    DESCRICAO: "NOVA BRASILANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "323",
    DESCRICAO: "NOVA CANAA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "324",
    DESCRICAO: "NOVA MUTUM",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "325",
    DESCRICAO: "NOVA OLIMPIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "326",
    DESCRICAO: "NOVA UBIRATA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "327",
    DESCRICAO: "NOVA XAVANTINA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "328",
    DESCRICAO: "NOVO MUNDO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "329",
    DESCRICAO: "NOVO HORIZONTE DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "330",
    DESCRICAO: "NOVO SAO JOAQUIM",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "331",
    DESCRICAO: "PARANAITA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "332",
    DESCRICAO: "PARANATINGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "333",
    DESCRICAO: "NOVO SANTO ANTONIO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "334",
    DESCRICAO: "PEDRA PRETA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "335",
    DESCRICAO: "PEIXOTO DE AZEVEDO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "336",
    DESCRICAO: "PLANALTO DA SERRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "337",
    DESCRICAO: "POCONE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "338",
    DESCRICAO: "PONTAL DO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "339",
    DESCRICAO: "PONTE BRANCA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "340",
    DESCRICAO: "PONTES E LACERDA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "341",
    DESCRICAO: "PORTO ALEGRE DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "342",
    DESCRICAO: "PORTO DOS GAUCHOS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "343",
    DESCRICAO: "PORTO ESPERIDIAO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "344",
    DESCRICAO: "PORTO ESTRELA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "345",
    DESCRICAO: "POXOREO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "346",
    DESCRICAO: "PRIMAVERA DO LESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "347",
    DESCRICAO: "QUERENCIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "348",
    DESCRICAO: "SAO JOSE DOS QUATRO MARCOS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "349",
    DESCRICAO: "RESERVA DO CABACAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "350",
    DESCRICAO: "RIBEIRAO CASCALHEIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "351",
    DESCRICAO: "RIBEIRAOZINHO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "352",
    DESCRICAO: "RIO BRANCO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "353",
    DESCRICAO: "SANTA CARMEM",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "354",
    DESCRICAO: "SANTO AFONSO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "355",
    DESCRICAO: "SAO JOSE DO POVO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "356",
    DESCRICAO: "SAO JOSE DO RIO CLARO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "357",
    DESCRICAO: "SAO JOSE DO XINGU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "358",
    DESCRICAO: "SAO PEDRO DA CIPA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "359",
    DESCRICAO: "RONDOLANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "360",
    DESCRICAO: "RONDONOPOLIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "361",
    DESCRICAO: "ROSARIO OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "362",
    DESCRICAO: "SANTA CRUZ DO XINGU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "363",
    DESCRICAO: "SALTO DO CEU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "364",
    DESCRICAO: "SANTA RITA DO TRIVELATO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "365",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "366",
    DESCRICAO: "SANTO ANTONIO DO LESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "367",
    DESCRICAO: "SANTO ANTONIO DO LEVERGER",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "368",
    DESCRICAO: "SAO FELIX DO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "369",
    DESCRICAO: "SAPEZAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "370",
    DESCRICAO: "SERRA NOVA DOURADA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "371",
    DESCRICAO: "SINOP",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "372",
    DESCRICAO: "SORRISO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "373",
    DESCRICAO: "TABAPORA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "374",
    DESCRICAO: "TANGARA DA SERRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "375",
    DESCRICAO: "TAPURAH",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "376",
    DESCRICAO: "TERRA NOVA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "377",
    DESCRICAO: "TESOURO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "378",
    DESCRICAO: "TORIXOREU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "379",
    DESCRICAO: "UNIAO DO SUL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "380",
    DESCRICAO: "VALE DE SAO DOMINGOS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "381",
    DESCRICAO: "VARZEA GRANDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "382",
    DESCRICAO: "VERA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "383",
    DESCRICAO: "VILA RICA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "384",
    DESCRICAO: "NOVA GUARITA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "385",
    DESCRICAO: "NOVA MARILANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "386",
    DESCRICAO: "NOVA MARINGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "387",
    DESCRICAO: "NOVA MONTE VERDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "388",
    DESCRICAO: "BRASILIA",
    SIGLA: "DF",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "389",
    DESCRICAO: "AGUA CLARA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "390",
    DESCRICAO: "ALCINOPOLIS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "391",
    DESCRICAO: "AMAMBAI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "392",
    DESCRICAO: "ANASTACIO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "393",
    DESCRICAO: "ANAURILANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "394",
    DESCRICAO: "ANGELICA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "395",
    DESCRICAO: "ANTONIO JOAO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "396",
    DESCRICAO: "APARECIDA DO TABOADO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "397",
    DESCRICAO: "AQUIDAUANA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "398",
    DESCRICAO: "ARAL MOREIRA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "399",
    DESCRICAO: "BANDEIRANTES",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "400",
    DESCRICAO: "BATAGUASSU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "401",
    DESCRICAO: "BATAYPORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "402",
    DESCRICAO: "BELA VISTA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "403",
    DESCRICAO: "BODOQUENA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "404",
    DESCRICAO: "BONITO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "405",
    DESCRICAO: "BRASILANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "406",
    DESCRICAO: "CAARAPO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "407",
    DESCRICAO: "CAMAPUA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "408",
    DESCRICAO: "CAMPO GRANDE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "409",
    DESCRICAO: "CARACOL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "410",
    DESCRICAO: "CASSILANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "411",
    DESCRICAO: "CHAPADAO DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "412",
    DESCRICAO: "CORGUINHO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "413",
    DESCRICAO: "CORONEL SAPUCAIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "414",
    DESCRICAO: "CORUMBA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "415",
    DESCRICAO: "COSTA RICA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "416",
    DESCRICAO: "COXIM",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "417",
    DESCRICAO: "DEODAPOLIS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "418",
    DESCRICAO: "DOIS IRMAOS DO BURITI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "419",
    DESCRICAO: "DOURADINA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "420",
    DESCRICAO: "DOURADOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "421",
    DESCRICAO: "ELDORADO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "422",
    DESCRICAO: "FATIMA DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "423",
    DESCRICAO: "FIGUEIRAO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "424",
    DESCRICAO: "GLORIA DE DOURADOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "425",
    DESCRICAO: "GUIA LOPES DA LAGUNA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "426",
    DESCRICAO: "IGUATEMI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "427",
    DESCRICAO: "INOCENCIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "428",
    DESCRICAO: "ITAPORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "429",
    DESCRICAO: "ITAQUIRAI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "430",
    DESCRICAO: "IVINHEMA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "431",
    DESCRICAO: "JAPORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "432",
    DESCRICAO: "JARAGUARI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "433",
    DESCRICAO: "JARDIM",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "434",
    DESCRICAO: "JATEI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "435",
    DESCRICAO: "JUTI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "436",
    DESCRICAO: "LADARIO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "437",
    DESCRICAO: "LAGUNA CARAPA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "438",
    DESCRICAO: "MARACAJU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "439",
    DESCRICAO: "MIRANDA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "440",
    DESCRICAO: "MUNDO NOVO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "441",
    DESCRICAO: "NAVIRAI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "442",
    DESCRICAO: "NIOAQUE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "443",
    DESCRICAO: "NOVA ALVORADA DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "444",
    DESCRICAO: "NOVA ANDRADINA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "445",
    DESCRICAO: "NOVO HORIZONTE DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "446",
    DESCRICAO: "PARANAIBA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "447",
    DESCRICAO: "PARANHOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "448",
    DESCRICAO: "PEDRO GOMES",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "449",
    DESCRICAO: "PONTA PORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "450",
    DESCRICAO: "PORTO MURTINHO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "451",
    DESCRICAO: "RIBAS DO RIO PARDO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "452",
    DESCRICAO: "RIO BRILHANTE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "453",
    DESCRICAO: "RIO NEGRO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "454",
    DESCRICAO: "RIO VERDE DE MATO GROSSO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "455",
    DESCRICAO: "ROCHEDO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "456",
    DESCRICAO: "SANTA RITA DO PARDO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "457",
    DESCRICAO: "SAO GABRIEL DO OESTE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "458",
    DESCRICAO: "SETE QUEDAS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "459",
    DESCRICAO: "SELVIRIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "460",
    DESCRICAO: "SIDROLANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "461",
    DESCRICAO: "SONORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "462",
    DESCRICAO: "TACURU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "463",
    DESCRICAO: "TAQUARUSSU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "464",
    DESCRICAO: "TERENOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "465",
    DESCRICAO: "TRES LAGOAS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "466",
    DESCRICAO: "VICENTINA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "467",
    DESCRICAO: "ADAMANTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "468",
    DESCRICAO: "ADOLFO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "469",
    DESCRICAO: "AGUAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "470",
    DESCRICAO: "AGUAS DA PRATA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "471",
    DESCRICAO: "AGUAS DE LINDOIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "472",
    DESCRICAO: "AGUAS DE SANTA BARBARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "473",
    DESCRICAO: "AGUAS DE SAO PEDRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "474",
    DESCRICAO: "AGUDOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "475",
    DESCRICAO: "ALAMBARI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "476",
    DESCRICAO: "ALFREDO MARCONDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "477",
    DESCRICAO: "ALTAIR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "478",
    DESCRICAO: "ALTINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "479",
    DESCRICAO: "ALTO ALEGRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "480",
    DESCRICAO: "ALUMINIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "481",
    DESCRICAO: "ALVARES FLORENCE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "482",
    DESCRICAO: "ALVARES MACHADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "483",
    DESCRICAO: "ALVARO DE CARVALHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "484",
    DESCRICAO: "ALVINLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "485",
    DESCRICAO: "AMERICANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "486",
    DESCRICAO: "AMERICO BRASILIENSE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "487",
    DESCRICAO: "AMERICO DE CAMPOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "488",
    DESCRICAO: "AMPARO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "489",
    DESCRICAO: "ANALANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "490",
    DESCRICAO: "ANDRADINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "491",
    DESCRICAO: "ANGATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "492",
    DESCRICAO: "ANHEMBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "493",
    DESCRICAO: "ANHUMAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "494",
    DESCRICAO: "APARECIDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "495",
    DESCRICAO: "APARECIDA DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "496",
    DESCRICAO: "APIAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "497",
    DESCRICAO: "ARACARIGUAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "498",
    DESCRICAO: "ARACATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "499",
    DESCRICAO: "ARACOIABA DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "500",
    DESCRICAO: "ARAMINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "501",
    DESCRICAO: "ARANDU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "502",
    DESCRICAO: "ARAPEI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "503",
    DESCRICAO: "ARARAQUARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "504",
    DESCRICAO: "ARARAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "505",
    DESCRICAO: "ARCO-IRIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "506",
    DESCRICAO: "AREALVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "507",
    DESCRICAO: "AREIAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "508",
    DESCRICAO: "AREIOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "509",
    DESCRICAO: "ARIRANHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "510",
    DESCRICAO: "ARTUR NOGUEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "511",
    DESCRICAO: "ARUJA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "512",
    DESCRICAO: "ASPASIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "513",
    DESCRICAO: "ASSIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "514",
    DESCRICAO: "ATIBAIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "515",
    DESCRICAO: "AURIFLAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "516",
    DESCRICAO: "AVAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "517",
    DESCRICAO: "AVANHANDAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "518",
    DESCRICAO: "AVARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "519",
    DESCRICAO: "BADY BASSITT",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "520",
    DESCRICAO: "BALBINOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "521",
    DESCRICAO: "BALSAMO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "522",
    DESCRICAO: "BANANAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "523",
    DESCRICAO: "BARAO DE ANTONINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "524",
    DESCRICAO: "BARBOSA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "525",
    DESCRICAO: "BARIRI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "526",
    DESCRICAO: "BARRA BONITA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "527",
    DESCRICAO: "BARRA DO CHAPEU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "528",
    DESCRICAO: "BARRA DO TURVO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "529",
    DESCRICAO: "BARRETOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "530",
    DESCRICAO: "BARRINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "531",
    DESCRICAO: "BARUERI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "532",
    DESCRICAO: "BASTOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "533",
    DESCRICAO: "BATATAIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "534",
    DESCRICAO: "BAURU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "535",
    DESCRICAO: "BEBEDOURO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "536",
    DESCRICAO: "BENTO DE ABREU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "537",
    DESCRICAO: "BERNARDINO DE CAMPOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "538",
    DESCRICAO: "BERTIOGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "539",
    DESCRICAO: "BILAC",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "540",
    DESCRICAO: "BIRIGUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "541",
    DESCRICAO: "BIRITIBA-MIRIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "542",
    DESCRICAO: "BOA ESPERANCA DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "543",
    DESCRICAO: "BOCAINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "544",
    DESCRICAO: "BOFETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "545",
    DESCRICAO: "BOITUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "546",
    DESCRICAO: "BOM JESUS DOS PERDOES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "547",
    DESCRICAO: "BOM SUCESSO DE ITARARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "548",
    DESCRICAO: "BORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "549",
    DESCRICAO: "BORACEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "550",
    DESCRICAO: "BORBOREMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "551",
    DESCRICAO: "BOREBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "552",
    DESCRICAO: "BOTUCATU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "553",
    DESCRICAO: "BRAGANCA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "554",
    DESCRICAO: "BRAUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "555",
    DESCRICAO: "BREJO ALEGRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "556",
    DESCRICAO: "BRODOWSKI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "557",
    DESCRICAO: "BROTAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "558",
    DESCRICAO: "BURI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "559",
    DESCRICAO: "BURITAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "560",
    DESCRICAO: "BURITIZAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "561",
    DESCRICAO: "CABRALIA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "562",
    DESCRICAO: "CABREUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "563",
    DESCRICAO: "CACAPAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "564",
    DESCRICAO: "CACHOEIRA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "565",
    DESCRICAO: "CACONDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "566",
    DESCRICAO: "CAFELANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "567",
    DESCRICAO: "CAIABU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "568",
    DESCRICAO: "CAIEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "569",
    DESCRICAO: "CAIUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "570",
    DESCRICAO: "CAJAMAR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "571",
    DESCRICAO: "CAJATI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "572",
    DESCRICAO: "CAJOBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "573",
    DESCRICAO: "CAJURU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "574",
    DESCRICAO: "CAMPINA DO MONTE ALEGRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "575",
    DESCRICAO: "CAMPINAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "576",
    DESCRICAO: "CAMPO LIMPO PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "577",
    DESCRICAO: "CAMPOS DO JORDAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "578",
    DESCRICAO: "CAMPOS NOVOS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "579",
    DESCRICAO: "CANANEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "580",
    DESCRICAO: "CANAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "581",
    DESCRICAO: "CANDIDO MOTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "582",
    DESCRICAO: "CANDIDO RODRIGUES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "583",
    DESCRICAO: "CANITAR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "584",
    DESCRICAO: "CAPAO BONITO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "585",
    DESCRICAO: "CAPELA DO ALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "586",
    DESCRICAO: "CAPIVARI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "587",
    DESCRICAO: "CARAGUATATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "588",
    DESCRICAO: "CARAPICUIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "589",
    DESCRICAO: "CARDOSO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "590",
    DESCRICAO: "CASA BRANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "591",
    DESCRICAO: "CASSIA DOS COQUEIROS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "592",
    DESCRICAO: "CASTILHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "593",
    DESCRICAO: "CATANDUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "594",
    DESCRICAO: "CATIGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "595",
    DESCRICAO: "CEDRAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "596",
    DESCRICAO: "CERQUEIRA CESAR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "597",
    DESCRICAO: "CERQUILHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "598",
    DESCRICAO: "CESARIO LANGE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "599",
    DESCRICAO: "CHARQUEADA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "600",
    DESCRICAO: "CLEMENTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "601",
    DESCRICAO: "COLINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "602",
    DESCRICAO: "COLOMBIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "603",
    DESCRICAO: "CONCHAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "604",
    DESCRICAO: "CONCHAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "605",
    DESCRICAO: "CORDEIROPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "606",
    DESCRICAO: "COROADOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "607",
    DESCRICAO: "CORONEL MACEDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "608",
    DESCRICAO: "CORUMBATAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "609",
    DESCRICAO: "COSMOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "610",
    DESCRICAO: "COSMORAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "611",
    DESCRICAO: "COTIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "612",
    DESCRICAO: "CRAVINHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "613",
    DESCRICAO: "CRISTAIS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "614",
    DESCRICAO: "CRUZALIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "615",
    DESCRICAO: "CRUZEIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "616",
    DESCRICAO: "CUBATAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "617",
    DESCRICAO: "CUNHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "618",
    DESCRICAO: "DESCALVADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "619",
    DESCRICAO: "DIADEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "620",
    DESCRICAO: "DIRCE REIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "621",
    DESCRICAO: "DIVINOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "622",
    DESCRICAO: "DOBRADA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "623",
    DESCRICAO: "DOIS CORREGOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "624",
    DESCRICAO: "DOLCINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "625",
    DESCRICAO: "DOURADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "626",
    DESCRICAO: "DRACENA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "627",
    DESCRICAO: "DUARTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "628",
    DESCRICAO: "DUMONT",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "629",
    DESCRICAO: "ECHAPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "630",
    DESCRICAO: "ELDORADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "631",
    DESCRICAO: "ELIAS FAUSTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "632",
    DESCRICAO: "ELISIARIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "633",
    DESCRICAO: "EMBAUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "634",
    DESCRICAO: "EMBU DAS ARTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "635",
    DESCRICAO: "EMBU-GUACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "636",
    DESCRICAO: "EMILIANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "637",
    DESCRICAO: "ENGENHEIRO COELHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "638",
    DESCRICAO: "ESPIRITO SANTO DO PINHAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "639",
    DESCRICAO: "ESPIRITO SANTO DO TURVO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "640",
    DESCRICAO: "ESTRELA DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "641",
    DESCRICAO: "ESTRELA DO NORTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "642",
    DESCRICAO: "EUCLIDES DA CUNHA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "643",
    DESCRICAO: "FARTURA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "644",
    DESCRICAO: "FERNANDOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "645",
    DESCRICAO: "FERNANDO PRESTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "646",
    DESCRICAO: "FERNAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "647",
    DESCRICAO: "FERRAZ DE VASCONCELOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "648",
    DESCRICAO: "FLORA RICA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "649",
    DESCRICAO: "FLOREAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "650",
    DESCRICAO: "FLORIDA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "651",
    DESCRICAO: "FLORINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "652",
    DESCRICAO: "FRANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "653",
    DESCRICAO: "FRANCISCO MORATO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "654",
    DESCRICAO: "FRANCO DA ROCHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "655",
    DESCRICAO: "GABRIEL MONTEIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "656",
    DESCRICAO: "GALIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "657",
    DESCRICAO: "GARCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "658",
    DESCRICAO: "GASTAO VIDIGAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "659",
    DESCRICAO: "GAVIAO PEIXOTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "660",
    DESCRICAO: "GENERAL SALGADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "661",
    DESCRICAO: "GETULINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "662",
    DESCRICAO: "GLICERIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "663",
    DESCRICAO: "GUAICARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "664",
    DESCRICAO: "GUAIMBE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "665",
    DESCRICAO: "GUAIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "666",
    DESCRICAO: "GUAPIACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "667",
    DESCRICAO: "GUAPIARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "668",
    DESCRICAO: "GUARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "669",
    DESCRICAO: "GUARACAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "670",
    DESCRICAO: "GUARACI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "671",
    DESCRICAO: "GUARANI DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "672",
    DESCRICAO: "GUARANTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "673",
    DESCRICAO: "GUARARAPES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "674",
    DESCRICAO: "GUARAREMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "675",
    DESCRICAO: "GUARATINGUETA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "676",
    DESCRICAO: "GUAREI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "677",
    DESCRICAO: "GUARIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "678",
    DESCRICAO: "GUARUJA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "679",
    DESCRICAO: "GUARULHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "680",
    DESCRICAO: "GUATAPARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "681",
    DESCRICAO: "GUZOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "682",
    DESCRICAO: "HERCULANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "683",
    DESCRICAO: "HOLAMBRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "684",
    DESCRICAO: "HORTOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "685",
    DESCRICAO: "IACANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "686",
    DESCRICAO: "IACRI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "687",
    DESCRICAO: "IARAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "688",
    DESCRICAO: "IBATE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "689",
    DESCRICAO: "IBIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "690",
    DESCRICAO: "IBIRAREMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "691",
    DESCRICAO: "IBITINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "692",
    DESCRICAO: "IBIUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "693",
    DESCRICAO: "ICEM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "694",
    DESCRICAO: "IEPE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "695",
    DESCRICAO: "IGARACU DO TIETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "696",
    DESCRICAO: "IGARAPAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "697",
    DESCRICAO: "IGARATA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "698",
    DESCRICAO: "IGUAPE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "699",
    DESCRICAO: "ILHABELA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "700",
    DESCRICAO: "ILHA COMPRIDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "701",
    DESCRICAO: "ILHA SOLTEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "702",
    DESCRICAO: "INDAIATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "703",
    DESCRICAO: "INDIANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "704",
    DESCRICAO: "INDIAPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "705",
    DESCRICAO: "INUBIA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "706",
    DESCRICAO: "IPAUSSU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "707",
    DESCRICAO: "IPERO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "708",
    DESCRICAO: "IPEUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "709",
    DESCRICAO: "IPIGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "710",
    DESCRICAO: "IPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "711",
    DESCRICAO: "IPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "712",
    DESCRICAO: "IRACEMAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "713",
    DESCRICAO: "IRAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "714",
    DESCRICAO: "IRAPURU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "715",
    DESCRICAO: "ITABERA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "716",
    DESCRICAO: "ITAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "717",
    DESCRICAO: "ITAJOBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "718",
    DESCRICAO: "ITAJU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "719",
    DESCRICAO: "ITANHAEM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "720",
    DESCRICAO: "ITAOCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "721",
    DESCRICAO: "ITAPECERICA DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "722",
    DESCRICAO: "ITAPETININGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "723",
    DESCRICAO: "ITAPEVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "724",
    DESCRICAO: "ITAPEVI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "725",
    DESCRICAO: "ITAPIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "726",
    DESCRICAO: "ITAPIRAPUA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "727",
    DESCRICAO: "ITAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "728",
    DESCRICAO: "ITAPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "729",
    DESCRICAO: "ITAPUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "730",
    DESCRICAO: "ITAPURA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "731",
    DESCRICAO: "ITAQUAQUECETUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "732",
    DESCRICAO: "ITARARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "733",
    DESCRICAO: "ITARIRI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "734",
    DESCRICAO: "ITATIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "735",
    DESCRICAO: "ITATINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "736",
    DESCRICAO: "ITIRAPINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "737",
    DESCRICAO: "ITIRAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "738",
    DESCRICAO: "ITOBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "739",
    DESCRICAO: "ITU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "740",
    DESCRICAO: "ITUPEVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "741",
    DESCRICAO: "ITUVERAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "742",
    DESCRICAO: "JABORANDI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "743",
    DESCRICAO: "JABOTICABAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "744",
    DESCRICAO: "JACAREI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "745",
    DESCRICAO: "JACI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "746",
    DESCRICAO: "JACUPIRANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "747",
    DESCRICAO: "JAGUARIUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "748",
    DESCRICAO: "JALES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "749",
    DESCRICAO: "JAMBEIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "750",
    DESCRICAO: "JANDIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "751",
    DESCRICAO: "JARDINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "752",
    DESCRICAO: "JARINU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "753",
    DESCRICAO: "JAU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "754",
    DESCRICAO: "JERIQUARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "755",
    DESCRICAO: "JOANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "756",
    DESCRICAO: "JOAO RAMALHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "757",
    DESCRICAO: "JOSE BONIFACIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "758",
    DESCRICAO: "JULIO MESQUITA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "759",
    DESCRICAO: "JUMIRIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "760",
    DESCRICAO: "JUNDIAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "761",
    DESCRICAO: "JUNQUEIROPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "762",
    DESCRICAO: "JUQUIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "763",
    DESCRICAO: "JUQUITIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "764",
    DESCRICAO: "LAGOINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "765",
    DESCRICAO: "LARANJAL PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "766",
    DESCRICAO: "LAVINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "767",
    DESCRICAO: "LAVRINHAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "768",
    DESCRICAO: "LEME",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "769",
    DESCRICAO: "LENCOIS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "770",
    DESCRICAO: "LIMEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "771",
    DESCRICAO: "LINDOIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "772",
    DESCRICAO: "LINS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "773",
    DESCRICAO: "LORENA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "774",
    DESCRICAO: "LOURDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "775",
    DESCRICAO: "LOUVEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "776",
    DESCRICAO: "LUCELIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "777",
    DESCRICAO: "LUCIANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "778",
    DESCRICAO: "LUIS ANTONIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "779",
    DESCRICAO: "LUIZIANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "780",
    DESCRICAO: "LUPERCIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "781",
    DESCRICAO: "LUTECIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "782",
    DESCRICAO: "MACATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "783",
    DESCRICAO: "MACAUBAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "784",
    DESCRICAO: "MACEDONIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "785",
    DESCRICAO: "MAGDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "786",
    DESCRICAO: "MAIRINQUE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "787",
    DESCRICAO: "MAIRIPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "788",
    DESCRICAO: "MANDURI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "789",
    DESCRICAO: "MARABA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "790",
    DESCRICAO: "MARACAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "791",
    DESCRICAO: "MARAPOAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "792",
    DESCRICAO: "MARIAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "793",
    DESCRICAO: "MARILIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "794",
    DESCRICAO: "MARINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "795",
    DESCRICAO: "MARTINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "796",
    DESCRICAO: "MATAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "797",
    DESCRICAO: "MAUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "798",
    DESCRICAO: "MENDONCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "799",
    DESCRICAO: "MERIDIANO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "800",
    DESCRICAO: "MESOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "801",
    DESCRICAO: "MIGUELOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "802",
    DESCRICAO: "MINEIROS DO TIETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "803",
    DESCRICAO: "MIRACATU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "804",
    DESCRICAO: "MIRA ESTRELA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "805",
    DESCRICAO: "MIRANDOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "806",
    DESCRICAO: "MIRANTE DO PARANAPANEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "807",
    DESCRICAO: "MIRASSOL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "808",
    DESCRICAO: "MIRASSOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "809",
    DESCRICAO: "MOCOCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "810",
    DESCRICAO: "MOGI DAS CRUZES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "811",
    DESCRICAO: "MOGI GUACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "812",
    DESCRICAO: "MOGI MIRIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "813",
    DESCRICAO: "MOMBUCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "814",
    DESCRICAO: "MONCOES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "815",
    DESCRICAO: "MONGAGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "816",
    DESCRICAO: "MONTE ALEGRE DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "817",
    DESCRICAO: "MONTE ALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "818",
    DESCRICAO: "MONTE APRAZIVEL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "819",
    DESCRICAO: "MONTE AZUL PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "820",
    DESCRICAO: "MONTE CASTELO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "821",
    DESCRICAO: "MONTEIRO LOBATO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "822",
    DESCRICAO: "MONTE MOR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "823",
    DESCRICAO: "MORRO AGUDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "824",
    DESCRICAO: "MORUNGABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "825",
    DESCRICAO: "MOTUCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "826",
    DESCRICAO: "MURUTINGA DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "827",
    DESCRICAO: "NANTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "828",
    DESCRICAO: "NARANDIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "829",
    DESCRICAO: "NATIVIDADE DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "830",
    DESCRICAO: "NAZARE PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "831",
    DESCRICAO: "NEVES PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "832",
    DESCRICAO: "NHANDEARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "833",
    DESCRICAO: "NIPOA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "834",
    DESCRICAO: "NOVA ALIANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "835",
    DESCRICAO: "NOVA CAMPINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "836",
    DESCRICAO: "NOVA CANAA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "837",
    DESCRICAO: "NOVA CASTILHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "838",
    DESCRICAO: "NOVA EUROPA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "839",
    DESCRICAO: "NOVA GRANADA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "840",
    DESCRICAO: "NOVA GUATAPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "841",
    DESCRICAO: "NOVA INDEPENDENCIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "842",
    DESCRICAO: "NOVAIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "843",
    DESCRICAO: "NOVA LUZITANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "844",
    DESCRICAO: "NOVA ODESSA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "845",
    DESCRICAO: "NOVO HORIZONTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "846",
    DESCRICAO: "NUPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "847",
    DESCRICAO: "OCAUCU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "848",
    DESCRICAO: "OLEO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "849",
    DESCRICAO: "OLIMPIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "850",
    DESCRICAO: "ONDA VERDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "851",
    DESCRICAO: "ORIENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "852",
    DESCRICAO: "ORINDIUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "853",
    DESCRICAO: "ORLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "854",
    DESCRICAO: "OSASCO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "855",
    DESCRICAO: "OSCAR BRESSANE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "856",
    DESCRICAO: "OSVALDO CRUZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "857",
    DESCRICAO: "OURINHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "858",
    DESCRICAO: "OUROESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "859",
    DESCRICAO: "OURO VERDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "860",
    DESCRICAO: "PACAEMBU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "861",
    DESCRICAO: "PALESTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "862",
    DESCRICAO: "PALMARES PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "863",
    DESCRICAO: "PALMEIRA DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "864",
    DESCRICAO: "PALMITAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "865",
    DESCRICAO: "PANORAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "866",
    DESCRICAO: "PARAGUACU PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "867",
    DESCRICAO: "PARAIBUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "868",
    DESCRICAO: "PARAISO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "869",
    DESCRICAO: "PARANAPANEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "870",
    DESCRICAO: "PARANAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "871",
    DESCRICAO: "PARAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "872",
    DESCRICAO: "PARDINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "873",
    DESCRICAO: "PARIQUERA-ACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "874",
    DESCRICAO: "PARISI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "875",
    DESCRICAO: "PATROCINIO PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "876",
    DESCRICAO: "PAULICEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "877",
    DESCRICAO: "PAULINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "878",
    DESCRICAO: "PAULISTANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "879",
    DESCRICAO: "PAULO DE FARIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "880",
    DESCRICAO: "PEDERNEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "881",
    DESCRICAO: "PEDRA BELA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "882",
    DESCRICAO: "PEDRANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "883",
    DESCRICAO: "PEDREGULHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "884",
    DESCRICAO: "PEDREIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "885",
    DESCRICAO: "PEDRINHAS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "886",
    DESCRICAO: "PEDRO DE TOLEDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "887",
    DESCRICAO: "PENAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "888",
    DESCRICAO: "PEREIRA BARRETO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "889",
    DESCRICAO: "PEREIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "890",
    DESCRICAO: "PERUIBE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "891",
    DESCRICAO: "PIACATU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "892",
    DESCRICAO: "PIEDADE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "893",
    DESCRICAO: "PILAR DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "894",
    DESCRICAO: "PINDAMONHANGABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "895",
    DESCRICAO: "PINDORAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "896",
    DESCRICAO: "PINHALZINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "897",
    DESCRICAO: "PIQUEROBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "898",
    DESCRICAO: "PIQUETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "899",
    DESCRICAO: "PIRACAIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "900",
    DESCRICAO: "PIRACICABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "901",
    DESCRICAO: "PIRAJU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "902",
    DESCRICAO: "PIRAJUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "903",
    DESCRICAO: "PIRANGI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "904",
    DESCRICAO: "PIRAPORA DO BOM JESUS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "905",
    DESCRICAO: "PIRAPOZINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "906",
    DESCRICAO: "PIRASSUNUNGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "907",
    DESCRICAO: "PIRATININGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "908",
    DESCRICAO: "PITANGUEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "909",
    DESCRICAO: "PLANALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "910",
    DESCRICAO: "PLATINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "911",
    DESCRICAO: "POA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "912",
    DESCRICAO: "POLONI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "913",
    DESCRICAO: "POMPEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "914",
    DESCRICAO: "PONGAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "915",
    DESCRICAO: "PONTAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "916",
    DESCRICAO: "PONTALINDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "917",
    DESCRICAO: "PONTES GESTAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "918",
    DESCRICAO: "POPULINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "919",
    DESCRICAO: "PORANGABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "920",
    DESCRICAO: "PORTO FELIZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "921",
    DESCRICAO: "PORTO FERREIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "922",
    DESCRICAO: "POTIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "923",
    DESCRICAO: "POTIRENDABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "924",
    DESCRICAO: "PRACINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "925",
    DESCRICAO: "PRADOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "926",
    DESCRICAO: "PRAIA GRANDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "927",
    DESCRICAO: "PRATANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "928",
    DESCRICAO: "PRESIDENTE ALVES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "929",
    DESCRICAO: "PRESIDENTE BERNARDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "930",
    DESCRICAO: "PRESIDENTE EPITACIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "931",
    DESCRICAO: "PRESIDENTE PRUDENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "932",
    DESCRICAO: "PRESIDENTE VENCESLAU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "933",
    DESCRICAO: "PROMISSAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "934",
    DESCRICAO: "QUADRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "935",
    DESCRICAO: "QUATA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "936",
    DESCRICAO: "QUEIROZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "937",
    DESCRICAO: "QUELUZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "938",
    DESCRICAO: "QUINTANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "939",
    DESCRICAO: "RAFARD",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "940",
    DESCRICAO: "RANCHARIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "941",
    DESCRICAO: "REDENCAO DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "942",
    DESCRICAO: "REGENTE FEIJO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "943",
    DESCRICAO: "REGINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "944",
    DESCRICAO: "REGISTRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "945",
    DESCRICAO: "RESTINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "946",
    DESCRICAO: "RIBEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "947",
    DESCRICAO: "RIBEIRAO BONITO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "948",
    DESCRICAO: "RIBEIRAO BRANCO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "949",
    DESCRICAO: "RIBEIRAO CORRENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "950",
    DESCRICAO: "RIBEIRAO DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "951",
    DESCRICAO: "RIBEIRAO DOS INDIOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "952",
    DESCRICAO: "RIBEIRAO GRANDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "953",
    DESCRICAO: "RIBEIRAO PIRES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "954",
    DESCRICAO: "RIBEIRAO PRETO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "955",
    DESCRICAO: "RIVERSUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "956",
    DESCRICAO: "RIFAINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "957",
    DESCRICAO: "RINCAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "958",
    DESCRICAO: "RINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "959",
    DESCRICAO: "RIO CLARO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "960",
    DESCRICAO: "RIO DAS PEDRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "961",
    DESCRICAO: "RIO GRANDE DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "962",
    DESCRICAO: "RIOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "963",
    DESCRICAO: "ROSANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "964",
    DESCRICAO: "ROSEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "965",
    DESCRICAO: "RUBIACEA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "966",
    DESCRICAO: "RUBINEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "967",
    DESCRICAO: "SABINO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "968",
    DESCRICAO: "SAGRES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "969",
    DESCRICAO: "SALES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "970",
    DESCRICAO: "SALES OLIVEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "971",
    DESCRICAO: "SALESOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "972",
    DESCRICAO: "SALMOURAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "973",
    DESCRICAO: "SALTINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "974",
    DESCRICAO: "SALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "975",
    DESCRICAO: "SALTO DE PIRAPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "976",
    DESCRICAO: "SALTO GRANDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "977",
    DESCRICAO: "SANDOVALINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "978",
    DESCRICAO: "SANTA ADELIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "979",
    DESCRICAO: "SANTA ALBERTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "980",
    DESCRICAO: "SANTA BARBARA DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "981",
    DESCRICAO: "SANTA BRANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "982",
    DESCRICAO: "SANTA CLARA DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "983",
    DESCRICAO: "SANTA CRUZ DA CONCEICAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "984",
    DESCRICAO: "SANTA CRUZ DA ESPERANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "985",
    DESCRICAO: "SANTA CRUZ DAS PALMEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "986",
    DESCRICAO: "SANTA CRUZ DO RIO PARDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "987",
    DESCRICAO: "SANTA ERNESTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "988",
    DESCRICAO: "SANTA FE DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "989",
    DESCRICAO: "SANTA GERTRUDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "990",
    DESCRICAO: "SANTA ISABEL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "991",
    DESCRICAO: "SANTA LUCIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "992",
    DESCRICAO: "SANTA MARIA DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "993",
    DESCRICAO: "SANTA MERCEDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "994",
    DESCRICAO: "SANTANA DA PONTE PENSA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "995",
    DESCRICAO: "SANTANA DE PARNAIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "996",
    DESCRICAO: "SANTA RITA DOESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "997",
    DESCRICAO: "SANTA RITA DO PASSA QUATRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "998",
    DESCRICAO: "SANTA ROSA DE VITERBO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "999",
    DESCRICAO: "SANTA SALETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1000",
    DESCRICAO: "SANTO ANASTACIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1001",
    DESCRICAO: "SANTO ANDRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1002",
    DESCRICAO: "SANTO ANTONIO DA ALEGRIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1003",
    DESCRICAO: "SANTO ANTONIO DE POSSE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1004",
    DESCRICAO: "SANTO ANTONIO DO ARACANGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1005",
    DESCRICAO: "SANTO ANTONIO DO JARDIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1006",
    DESCRICAO: "SANTO ANTONIO DO PINHAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1007",
    DESCRICAO: "SANTO EXPEDITO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1008",
    DESCRICAO: "SANTOPOLIS DO AGUAPEI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1009",
    DESCRICAO: "SANTOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1010",
    DESCRICAO: "SAO BENTO DO SAPUCAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1011",
    DESCRICAO: "SAO BERNARDO DO CAMPO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1012",
    DESCRICAO: "SAO CAETANO DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1013",
    DESCRICAO: "SAO CARLOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1014",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1015",
    DESCRICAO: "SAO JOAO DA BOA VISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1016",
    DESCRICAO: "SAO JOAO DAS DUAS PONTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1017",
    DESCRICAO: "SAO JOAO DE IRACEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1018",
    DESCRICAO: "SAO JOAO DO PAU DALHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1019",
    DESCRICAO: "SAO JOAQUIM DA BARRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1020",
    DESCRICAO: "SAO JOSE DA BELA VISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1021",
    DESCRICAO: "SAO JOSE DO BARREIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1022",
    DESCRICAO: "SAO JOSE DO RIO PARDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1023",
    DESCRICAO: "SAO JOSE DO RIO PRETO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1024",
    DESCRICAO: "SAO JOSE DOS CAMPOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1025",
    DESCRICAO: "SAO LOURENCO DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1026",
    DESCRICAO: "SAO LUIS DO PARAITINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1027",
    DESCRICAO: "SAO MANUEL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1028",
    DESCRICAO: "SAO MIGUEL ARCANJO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1029",
    DESCRICAO: "SAO PAULO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1030",
    DESCRICAO: "SAO PEDRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1031",
    DESCRICAO: "SAO PEDRO DO TURVO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1032",
    DESCRICAO: "SAO ROQUE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1033",
    DESCRICAO: "SAO SEBASTIAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1034",
    DESCRICAO: "SAO SEBASTIAO DA GRAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1035",
    DESCRICAO: "SAO SIMAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1036",
    DESCRICAO: "SAO VICENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1037",
    DESCRICAO: "SARAPUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1038",
    DESCRICAO: "SARUTAIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1039",
    DESCRICAO: "SEBASTIANOPOLIS DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1040",
    DESCRICAO: "SERRA AZUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1041",
    DESCRICAO: "SERRANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1042",
    DESCRICAO: "SERRA NEGRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1043",
    DESCRICAO: "SERTAOZINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1044",
    DESCRICAO: "SETE BARRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1045",
    DESCRICAO: "SEVERINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1046",
    DESCRICAO: "SILVEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1047",
    DESCRICAO: "SOCORRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1048",
    DESCRICAO: "SOROCABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1049",
    DESCRICAO: "SUD MENNUCCI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1050",
    DESCRICAO: "SUMARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1051",
    DESCRICAO: "SUZANO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1052",
    DESCRICAO: "SUZANAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1053",
    DESCRICAO: "TABAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1054",
    DESCRICAO: "TABATINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1055",
    DESCRICAO: "TABOAO DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1056",
    DESCRICAO: "TACIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1057",
    DESCRICAO: "TAGUAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1058",
    DESCRICAO: "TAIACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1059",
    DESCRICAO: "TAIUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1060",
    DESCRICAO: "TAMBAU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1061",
    DESCRICAO: "TANABI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1062",
    DESCRICAO: "TAPIRAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1063",
    DESCRICAO: "TAPIRATIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1064",
    DESCRICAO: "TAQUARAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1065",
    DESCRICAO: "TAQUARITINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1066",
    DESCRICAO: "TAQUARITUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1067",
    DESCRICAO: "TAQUARIVAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1068",
    DESCRICAO: "TARABAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1069",
    DESCRICAO: "TARUMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1070",
    DESCRICAO: "TATUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1071",
    DESCRICAO: "TAUBATE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1072",
    DESCRICAO: "TEJUPA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1073",
    DESCRICAO: "TEODORO SAMPAIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1074",
    DESCRICAO: "TERRA ROXA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1075",
    DESCRICAO: "TIETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1076",
    DESCRICAO: "TIMBURI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1077",
    DESCRICAO: "TORRE DE PEDRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1078",
    DESCRICAO: "TORRINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1079",
    DESCRICAO: "TRABIJU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1080",
    DESCRICAO: "TREMEMBE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1081",
    DESCRICAO: "TRES FRONTEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1082",
    DESCRICAO: "TUIUTI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1083",
    DESCRICAO: "TUPA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1084",
    DESCRICAO: "TUPI PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1085",
    DESCRICAO: "TURIUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1086",
    DESCRICAO: "TURMALINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1087",
    DESCRICAO: "UBARANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1088",
    DESCRICAO: "UBATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1089",
    DESCRICAO: "UBIRAJARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1090",
    DESCRICAO: "UCHOA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1091",
    DESCRICAO: "UNIAO PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1092",
    DESCRICAO: "URANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1093",
    DESCRICAO: "URU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1094",
    DESCRICAO: "URUPES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1095",
    DESCRICAO: "VALENTIM GENTIL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1096",
    DESCRICAO: "VALINHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1097",
    DESCRICAO: "VALPARAISO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1098",
    DESCRICAO: "VARGEM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1099",
    DESCRICAO: "VARGEM GRANDE DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1100",
    DESCRICAO: "VARGEM GRANDE PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1101",
    DESCRICAO: "VARZEA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1102",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1103",
    DESCRICAO: "VINHEDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1104",
    DESCRICAO: "VIRADOURO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1105",
    DESCRICAO: "VISTA ALEGRE DO ALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1106",
    DESCRICAO: "VITORIA BRASIL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1107",
    DESCRICAO: "VOTORANTIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1108",
    DESCRICAO: "VOTUPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1109",
    DESCRICAO: "ZACARIAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1110",
    DESCRICAO: "CHAVANTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1111",
    DESCRICAO: "ESTIVA GERBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1112",
    DESCRICAO: "ABADIA DOS DOURADOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1113",
    DESCRICAO: "ABAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1114",
    DESCRICAO: "ABRE CAMPO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1115",
    DESCRICAO: "ACAIACA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1116",
    DESCRICAO: "ACUCENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1117",
    DESCRICAO: "AGUA BOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1118",
    DESCRICAO: "AGUA COMPRIDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1119",
    DESCRICAO: "AGUANIL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1120",
    DESCRICAO: "AGUAS FORMOSAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1121",
    DESCRICAO: "AGUAS VERMELHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1122",
    DESCRICAO: "AIMORES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1123",
    DESCRICAO: "AIURUOCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1124",
    DESCRICAO: "ALAGOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1125",
    DESCRICAO: "ALBERTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1126",
    DESCRICAO: "ALEM PARAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1127",
    DESCRICAO: "ALFENAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1128",
    DESCRICAO: "ALFREDO VASCONCELOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1129",
    DESCRICAO: "ALMENARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1130",
    DESCRICAO: "ALPERCATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1131",
    DESCRICAO: "ALPINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1132",
    DESCRICAO: "ALTEROSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1133",
    DESCRICAO: "ALTO CAPARAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1134",
    DESCRICAO: "ALTO RIO DOCE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1135",
    DESCRICAO: "ALVARENGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1136",
    DESCRICAO: "ALVINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1137",
    DESCRICAO: "ALVORADA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1138",
    DESCRICAO: "AMPARO DO SERRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1139",
    DESCRICAO: "ANDRADAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1140",
    DESCRICAO: "CACHOEIRA DE PAJEU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1141",
    DESCRICAO: "ANDRELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1142",
    DESCRICAO: "ANGELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1143",
    DESCRICAO: "ANTONIO CARLOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1144",
    DESCRICAO: "ANTONIO DIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1145",
    DESCRICAO: "ANTONIO PRADO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1146",
    DESCRICAO: "ARACAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1147",
    DESCRICAO: "ARACITABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1148",
    DESCRICAO: "ARACUAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1149",
    DESCRICAO: "ARAGUARI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1150",
    DESCRICAO: "ARANTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1151",
    DESCRICAO: "ARAPONGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1152",
    DESCRICAO: "ARAPORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1153",
    DESCRICAO: "ARAPUA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1154",
    DESCRICAO: "ARAUJOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1155",
    DESCRICAO: "ARAXA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1156",
    DESCRICAO: "ARCEBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1157",
    DESCRICAO: "ARCOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1158",
    DESCRICAO: "AREADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1159",
    DESCRICAO: "ARGIRITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1160",
    DESCRICAO: "ARICANDUVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1161",
    DESCRICAO: "ARINOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1162",
    DESCRICAO: "ASTOLFO DUTRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1163",
    DESCRICAO: "ATALEIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1164",
    DESCRICAO: "AUGUSTO DE LIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1165",
    DESCRICAO: "BAEPENDI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1166",
    DESCRICAO: "BALDIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1167",
    DESCRICAO: "BAMBUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1168",
    DESCRICAO: "BANDEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1169",
    DESCRICAO: "BANDEIRA DO SUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1170",
    DESCRICAO: "BARAO DE COCAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1171",
    DESCRICAO: "BARAO DE MONTE ALTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1172",
    DESCRICAO: "BARBACENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1173",
    DESCRICAO: "BARRA LONGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1174",
    DESCRICAO: "BARROSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1175",
    DESCRICAO: "BELA VISTA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1176",
    DESCRICAO: "BELMIRO BRAGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1177",
    DESCRICAO: "BELO HORIZONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1178",
    DESCRICAO: "BELO ORIENTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1179",
    DESCRICAO: "BELO VALE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1180",
    DESCRICAO: "BERILO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1181",
    DESCRICAO: "BERTOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1182",
    DESCRICAO: "BERIZAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1183",
    DESCRICAO: "BETIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1184",
    DESCRICAO: "BIAS FORTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1185",
    DESCRICAO: "BICAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1186",
    DESCRICAO: "BIQUINHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1187",
    DESCRICAO: "BOA ESPERANCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1188",
    DESCRICAO: "BOCAINA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1189",
    DESCRICAO: "BOCAIUVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1190",
    DESCRICAO: "BOM DESPACHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1191",
    DESCRICAO: "BOM JARDIM DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1192",
    DESCRICAO: "BOM JESUS DA PENHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1193",
    DESCRICAO: "BOM JESUS DO AMPARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1194",
    DESCRICAO: "BOM JESUS DO GALHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1195",
    DESCRICAO: "BOM REPOUSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1196",
    DESCRICAO: "BOM SUCESSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1197",
    DESCRICAO: "BONFIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1198",
    DESCRICAO: "BONFINOPOLIS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1199",
    DESCRICAO: "BONITO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1200",
    DESCRICAO: "BORDA DA MATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1201",
    DESCRICAO: "BOTELHOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1202",
    DESCRICAO: "BOTUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1203",
    DESCRICAO: "BRASILANDIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1204",
    DESCRICAO: "BRASILIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1205",
    DESCRICAO: "BRAS PIRES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1206",
    DESCRICAO: "BRAUNAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1207",
    DESCRICAO: "BRAZOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1208",
    DESCRICAO: "BRUMADINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1209",
    DESCRICAO: "BUENO BRANDAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1210",
    DESCRICAO: "BUENOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1211",
    DESCRICAO: "BUGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1212",
    DESCRICAO: "BURITIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1213",
    DESCRICAO: "BURITIZEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1214",
    DESCRICAO: "CABECEIRA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1215",
    DESCRICAO: "CABO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1216",
    DESCRICAO: "CACHOEIRA DA PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1217",
    DESCRICAO: "CACHOEIRA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1218",
    DESCRICAO: "CACHOEIRA DOURADA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1219",
    DESCRICAO: "CAETANOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1220",
    DESCRICAO: "CAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1221",
    DESCRICAO: "CAIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1222",
    DESCRICAO: "CAJURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1223",
    DESCRICAO: "CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1224",
    DESCRICAO: "CAMACHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1225",
    DESCRICAO: "CAMANDUCAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1226",
    DESCRICAO: "CAMBUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1227",
    DESCRICAO: "CAMBUQUIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1228",
    DESCRICAO: "CAMPANARIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1229",
    DESCRICAO: "CAMPANHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1230",
    DESCRICAO: "CAMPESTRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1231",
    DESCRICAO: "CAMPINA VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1232",
    DESCRICAO: "CAMPO AZUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1233",
    DESCRICAO: "CAMPO BELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1234",
    DESCRICAO: "CAMPO DO MEIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1235",
    DESCRICAO: "CAMPO FLORIDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1236",
    DESCRICAO: "CAMPOS ALTOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1237",
    DESCRICAO: "CAMPOS GERAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1238",
    DESCRICAO: "CANAA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1239",
    DESCRICAO: "CANAPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1240",
    DESCRICAO: "CANA VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1241",
    DESCRICAO: "CANDEIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1242",
    DESCRICAO: "CANTAGALO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1243",
    DESCRICAO: "CAPARAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1244",
    DESCRICAO: "CAPELA NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1245",
    DESCRICAO: "CAPELINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1246",
    DESCRICAO: "CAPETINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1247",
    DESCRICAO: "CAPIM BRANCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1248",
    DESCRICAO: "CAPINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1249",
    DESCRICAO: "CAPITAO ANDRADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1250",
    DESCRICAO: "CAPITAO ENEAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1251",
    DESCRICAO: "CAPITOLIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1252",
    DESCRICAO: "CAPUTIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1253",
    DESCRICAO: "CARAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1254",
    DESCRICAO: "CARANAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1255",
    DESCRICAO: "CARANDAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1256",
    DESCRICAO: "CARANGOLA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1257",
    DESCRICAO: "CARATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1258",
    DESCRICAO: "CARBONITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1259",
    DESCRICAO: "CAREACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1260",
    DESCRICAO: "CARLOS CHAGAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1261",
    DESCRICAO: "CARMESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1262",
    DESCRICAO: "CARMO DA CACHOEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1263",
    DESCRICAO: "CARMO DA MATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1264",
    DESCRICAO: "CARMO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1265",
    DESCRICAO: "CARMO DO CAJURU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1266",
    DESCRICAO: "CARMO DO PARANAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1267",
    DESCRICAO: "CARMO DO RIO CLARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1268",
    DESCRICAO: "CARMOPOLIS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1269",
    DESCRICAO: "CARNEIRINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1270",
    DESCRICAO: "CARRANCAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1271",
    DESCRICAO: "CARVALHOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1272",
    DESCRICAO: "CARVALHOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1273",
    DESCRICAO: "CASA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1274",
    DESCRICAO: "CASCALHO RICO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1275",
    DESCRICAO: "CASSIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1276",
    DESCRICAO: "CONCEICAO DA BARRA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1277",
    DESCRICAO: "CATAGUASES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1278",
    DESCRICAO: "CATAS ALTAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1279",
    DESCRICAO: "CATAS ALTAS DA NORUEGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1280",
    DESCRICAO: "CATUJI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1281",
    DESCRICAO: "CATUTI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1282",
    DESCRICAO: "CAXAMBU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1283",
    DESCRICAO: "CEDRO DO ABAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1284",
    DESCRICAO: "CENTRAL DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1285",
    DESCRICAO: "CENTRALINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1286",
    DESCRICAO: "CHACARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1287",
    DESCRICAO: "CHALE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1288",
    DESCRICAO: "CHAPADA DO NORTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1289",
    DESCRICAO: "CHAPADA GAUCHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1290",
    DESCRICAO: "CHIADOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1291",
    DESCRICAO: "CIPOTANEA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1292",
    DESCRICAO: "CLARAVAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1293",
    DESCRICAO: "CLARO DOS POCOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1294",
    DESCRICAO: "CLAUDIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1295",
    DESCRICAO: "COIMBRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1296",
    DESCRICAO: "COLUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1297",
    DESCRICAO: "COMENDADOR GOMES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1298",
    DESCRICAO: "COMERCINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1299",
    DESCRICAO: "CONCEICAO DA APARECIDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1300",
    DESCRICAO: "CONCEICAO DAS PEDRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1301",
    DESCRICAO: "CONCEICAO DAS ALAGOAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1302",
    DESCRICAO: "CONCEICAO DE IPANEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1303",
    DESCRICAO: "CONCEICAO DO MATO DENTRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1304",
    DESCRICAO: "CONCEICAO DO PARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1305",
    DESCRICAO: "CONCEICAO DO RIO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1306",
    DESCRICAO: "CONCEICAO DOS OUROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1307",
    DESCRICAO: "CONEGO MARINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1308",
    DESCRICAO: "CONFINS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1309",
    DESCRICAO: "CONGONHAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1310",
    DESCRICAO: "CONGONHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1311",
    DESCRICAO: "CONGONHAS DO NORTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1312",
    DESCRICAO: "CONQUISTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1313",
    DESCRICAO: "CONSELHEIRO LAFAIETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1314",
    DESCRICAO: "CONSELHEIRO PENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1315",
    DESCRICAO: "CONSOLACAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1316",
    DESCRICAO: "CONTAGEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1317",
    DESCRICAO: "COQUEIRAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1318",
    DESCRICAO: "CORACAO DE JESUS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1319",
    DESCRICAO: "CORDISBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1320",
    DESCRICAO: "CORDISLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1321",
    DESCRICAO: "CORINTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1322",
    DESCRICAO: "COROACI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1323",
    DESCRICAO: "COROMANDEL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1324",
    DESCRICAO: "CORONEL FABRICIANO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1325",
    DESCRICAO: "CORONEL MURTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1326",
    DESCRICAO: "CORONEL PACHECO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1327",
    DESCRICAO: "CORONEL XAVIER CHAVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1328",
    DESCRICAO: "CORREGO DANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1329",
    DESCRICAO: "CORREGO DO BOM JESUS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1330",
    DESCRICAO: "CORREGO FUNDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1331",
    DESCRICAO: "CORREGO NOVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1332",
    DESCRICAO: "COUTO DE MAGALHAES DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1333",
    DESCRICAO: "CRISOLITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1334",
    DESCRICAO: "CRISTAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1335",
    DESCRICAO: "CRISTALIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1336",
    DESCRICAO: "CRISTIANO OTONI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1337",
    DESCRICAO: "CRISTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1338",
    DESCRICAO: "CRUCILANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1339",
    DESCRICAO: "CRUZEIRO DA FORTALEZA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1340",
    DESCRICAO: "CRUZILIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1341",
    DESCRICAO: "CUPARAQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1342",
    DESCRICAO: "CURRAL DE DENTRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1343",
    DESCRICAO: "CURVELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1344",
    DESCRICAO: "DATAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1345",
    DESCRICAO: "DELFIM MOREIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1346",
    DESCRICAO: "DELFINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1347",
    DESCRICAO: "DELTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1348",
    DESCRICAO: "DESCOBERTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1349",
    DESCRICAO: "DESTERRO DE ENTRE RIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1350",
    DESCRICAO: "DESTERRO DO MELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1351",
    DESCRICAO: "DIAMANTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1352",
    DESCRICAO: "DIOGO DE VASCONCELOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1353",
    DESCRICAO: "DIONISIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1354",
    DESCRICAO: "DIVINESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1355",
    DESCRICAO: "DIVINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1356",
    DESCRICAO: "DIVINO DAS LARANJEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1357",
    DESCRICAO: "DIVINOLANDIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1358",
    DESCRICAO: "DIVINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1359",
    DESCRICAO: "DIVISA ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1360",
    DESCRICAO: "DIVISA NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1361",
    DESCRICAO: "DIVISOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1362",
    DESCRICAO: "DOM BOSCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1363",
    DESCRICAO: "DOM CAVATI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1364",
    DESCRICAO: "DOM JOAQUIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1365",
    DESCRICAO: "DOM SILVERIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1366",
    DESCRICAO: "DOM VICOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1367",
    DESCRICAO: "DONA EUSEBIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1368",
    DESCRICAO: "DORES DE CAMPOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1369",
    DESCRICAO: "DORES DE GUANHAES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1370",
    DESCRICAO: "DORES DO INDAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1371",
    DESCRICAO: "DORES DO TURVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1372",
    DESCRICAO: "DORESOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1373",
    DESCRICAO: "DOURADOQUARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1374",
    DESCRICAO: "DURANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1375",
    DESCRICAO: "ELOI MENDES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1376",
    DESCRICAO: "ENGENHEIRO CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1377",
    DESCRICAO: "ENGENHEIRO NAVARRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1378",
    DESCRICAO: "ENTRE FOLHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1379",
    DESCRICAO: "ENTRE RIOS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1380",
    DESCRICAO: "ERVALIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1381",
    DESCRICAO: "ESMERALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1382",
    DESCRICAO: "ESPERA FELIZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1383",
    DESCRICAO: "ESPINOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1384",
    DESCRICAO: "ESPIRITO SANTO DO DOURADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1385",
    DESCRICAO: "ESTIVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1386",
    DESCRICAO: "ESTRELA DALVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1387",
    DESCRICAO: "ESTRELA DO INDAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1388",
    DESCRICAO: "ESTRELA DO SUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1389",
    DESCRICAO: "EUGENOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1390",
    DESCRICAO: "EWBANK DA CAMARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1391",
    DESCRICAO: "EXTREMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1392",
    DESCRICAO: "FAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1393",
    DESCRICAO: "FARIA LEMOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1394",
    DESCRICAO: "FELICIO DOS SANTOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1395",
    DESCRICAO: "SAO GONCALO DO RIO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1396",
    DESCRICAO: "FELISBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1397",
    DESCRICAO: "FELIXLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1398",
    DESCRICAO: "FERNANDES TOURINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1399",
    DESCRICAO: "FERROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1400",
    DESCRICAO: "FERVEDOURO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1401",
    DESCRICAO: "FLORESTAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1402",
    DESCRICAO: "FORMIGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1403",
    DESCRICAO: "FORMOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1404",
    DESCRICAO: "FORTALEZA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1405",
    DESCRICAO: "FORTUNA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1406",
    DESCRICAO: "FRANCISCO BADARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1407",
    DESCRICAO: "FRANCISCO DUMONT",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1408",
    DESCRICAO: "FRANCISCO SA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1409",
    DESCRICAO: "FRANCISCOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1410",
    DESCRICAO: "FREI GASPAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1411",
    DESCRICAO: "FREI INOCENCIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1412",
    DESCRICAO: "FREI LAGONEGRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1413",
    DESCRICAO: "FRONTEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1414",
    DESCRICAO: "FRONTEIRA DOS VALES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1415",
    DESCRICAO: "FRUTA DE LEITE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1416",
    DESCRICAO: "FRUTAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1417",
    DESCRICAO: "FUNILANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1418",
    DESCRICAO: "GALILEIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1419",
    DESCRICAO: "GAMELEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1420",
    DESCRICAO: "GLAUCILANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1421",
    DESCRICAO: "GOIABEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1422",
    DESCRICAO: "GOIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1423",
    DESCRICAO: "GONCALVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1424",
    DESCRICAO: "GONZAGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1425",
    DESCRICAO: "GOUVEIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1426",
    DESCRICAO: "GOVERNADOR VALADARES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1427",
    DESCRICAO: "GRAO MOGOL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1428",
    DESCRICAO: "GRUPIARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1429",
    DESCRICAO: "GUANHAES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1430",
    DESCRICAO: "GUAPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1431",
    DESCRICAO: "GUARACIABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1432",
    DESCRICAO: "GUARACIAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1433",
    DESCRICAO: "GUARANESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1434",
    DESCRICAO: "GUARANI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1435",
    DESCRICAO: "GUARARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1436",
    DESCRICAO: "GUARDA-MOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1437",
    DESCRICAO: "GUAXUPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1438",
    DESCRICAO: "GUIDOVAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1439",
    DESCRICAO: "GUIMARANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1440",
    DESCRICAO: "GUIRICEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1441",
    DESCRICAO: "GURINHATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1442",
    DESCRICAO: "HELIODORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1443",
    DESCRICAO: "IAPU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1444",
    DESCRICAO: "IBERTIOGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1445",
    DESCRICAO: "IBIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1446",
    DESCRICAO: "IBIAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1447",
    DESCRICAO: "IBIRACATU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1448",
    DESCRICAO: "IBIRACI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1449",
    DESCRICAO: "IBIRITE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1450",
    DESCRICAO: "IBITIURA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1451",
    DESCRICAO: "IBITURUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1452",
    DESCRICAO: "ICARAI DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1453",
    DESCRICAO: "IGARAPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1454",
    DESCRICAO: "IGARATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1455",
    DESCRICAO: "IGUATAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1456",
    DESCRICAO: "IJACI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1457",
    DESCRICAO: "ILICINEA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1458",
    DESCRICAO: "IMBE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1459",
    DESCRICAO: "INCONFIDENTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1460",
    DESCRICAO: "INDAIABIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1461",
    DESCRICAO: "INDIANOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1462",
    DESCRICAO: "INGAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1463",
    DESCRICAO: "INHAPIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1464",
    DESCRICAO: "INHAUMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1465",
    DESCRICAO: "INIMUTABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1466",
    DESCRICAO: "IPABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1467",
    DESCRICAO: "IPANEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1468",
    DESCRICAO: "IPATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1469",
    DESCRICAO: "IPIACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1470",
    DESCRICAO: "IPUIUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1471",
    DESCRICAO: "IRAI DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1472",
    DESCRICAO: "ITABIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1473",
    DESCRICAO: "ITABIRINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1474",
    DESCRICAO: "ITABIRITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1475",
    DESCRICAO: "ITACAMBIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1476",
    DESCRICAO: "ITACARAMBI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1477",
    DESCRICAO: "ITAGUARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1478",
    DESCRICAO: "ITAIPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1479",
    DESCRICAO: "ITAJUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1480",
    DESCRICAO: "ITAMARANDIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1481",
    DESCRICAO: "ITAMARATI DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1482",
    DESCRICAO: "ITAMBACURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1483",
    DESCRICAO: "ITAMBE DO MATO DENTRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1484",
    DESCRICAO: "ITAMOGI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1485",
    DESCRICAO: "ITAMONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1486",
    DESCRICAO: "ITANHANDU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1487",
    DESCRICAO: "ITANHOMI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1488",
    DESCRICAO: "ITAOBIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1489",
    DESCRICAO: "ITAPAGIPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1490",
    DESCRICAO: "ITAPECERICA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1491",
    DESCRICAO: "ITAPEVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1492",
    DESCRICAO: "ITATIAIUCU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1493",
    DESCRICAO: "ITAU DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1494",
    DESCRICAO: "ITAUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1495",
    DESCRICAO: "ITAVERAVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1496",
    DESCRICAO: "ITINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1497",
    DESCRICAO: "ITUETA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1498",
    DESCRICAO: "ITUIUTABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1499",
    DESCRICAO: "ITUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1500",
    DESCRICAO: "ITURAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1501",
    DESCRICAO: "ITUTINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1502",
    DESCRICAO: "JABOTICATUBAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1503",
    DESCRICAO: "JACINTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1504",
    DESCRICAO: "JACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1505",
    DESCRICAO: "JACUTINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1506",
    DESCRICAO: "JAGUARACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1507",
    DESCRICAO: "JAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1508",
    DESCRICAO: "JAMPRUCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1509",
    DESCRICAO: "JANAUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1510",
    DESCRICAO: "JANUARIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1511",
    DESCRICAO: "JAPARAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1512",
    DESCRICAO: "JAPONVAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1513",
    DESCRICAO: "JECEABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1514",
    DESCRICAO: "JENIPAPO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1515",
    DESCRICAO: "JEQUERI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1516",
    DESCRICAO: "JEQUITAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1517",
    DESCRICAO: "JEQUITIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1518",
    DESCRICAO: "JEQUITINHONHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1519",
    DESCRICAO: "JESUANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1520",
    DESCRICAO: "JOAIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1521",
    DESCRICAO: "JOANESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1522",
    DESCRICAO: "JOAO MONLEVADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1523",
    DESCRICAO: "JOAO PINHEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1524",
    DESCRICAO: "JOAQUIM FELICIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1525",
    DESCRICAO: "JORDANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1526",
    DESCRICAO: "JOSE GONCALVES DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1527",
    DESCRICAO: "JOSE RAYDAN",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1528",
    DESCRICAO: "JOSENOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1529",
    DESCRICAO: "NOVA UNIAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1530",
    DESCRICAO: "JUATUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1531",
    DESCRICAO: "JUIZ DE FORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1532",
    DESCRICAO: "JURAMENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1533",
    DESCRICAO: "JURUAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1534",
    DESCRICAO: "JUVENILIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1535",
    DESCRICAO: "LADAINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1536",
    DESCRICAO: "LAGAMAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1537",
    DESCRICAO: "LAGOA DA PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1538",
    DESCRICAO: "LAGOA DOS PATOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1539",
    DESCRICAO: "LAGOA DOURADA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1540",
    DESCRICAO: "LAGOA FORMOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1541",
    DESCRICAO: "LAGOA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1542",
    DESCRICAO: "LAGOA SANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1543",
    DESCRICAO: "LAJINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1544",
    DESCRICAO: "LAMBARI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1545",
    DESCRICAO: "LAMIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1546",
    DESCRICAO: "LARANJAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1547",
    DESCRICAO: "LASSANCE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1548",
    DESCRICAO: "LAVRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1549",
    DESCRICAO: "LEANDRO FERREIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1550",
    DESCRICAO: "LEME DO PRADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1551",
    DESCRICAO: "LEOPOLDINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1552",
    DESCRICAO: "LIBERDADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1553",
    DESCRICAO: "LIMA DUARTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1554",
    DESCRICAO: "LIMEIRA DO OESTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1555",
    DESCRICAO: "LONTRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1556",
    DESCRICAO: "LUISBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1557",
    DESCRICAO: "LUISLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1558",
    DESCRICAO: "LUMINARIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1559",
    DESCRICAO: "LUZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1560",
    DESCRICAO: "MACHACALIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1561",
    DESCRICAO: "MACHADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1562",
    DESCRICAO: "MADRE DE DEUS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1563",
    DESCRICAO: "MALACACHETA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1564",
    DESCRICAO: "MAMONAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1565",
    DESCRICAO: "MANGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1566",
    DESCRICAO: "MANHUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1567",
    DESCRICAO: "MANHUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1568",
    DESCRICAO: "MANTENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1569",
    DESCRICAO: "MARAVILHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1570",
    DESCRICAO: "MAR DE ESPANHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1571",
    DESCRICAO: "MARIA DA FE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1572",
    DESCRICAO: "MARIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1573",
    DESCRICAO: "MARILAC",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1574",
    DESCRICAO: "MARIO CAMPOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1575",
    DESCRICAO: "MARIPA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1576",
    DESCRICAO: "MARLIERIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1577",
    DESCRICAO: "MARMELOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1578",
    DESCRICAO: "MARTINHO CAMPOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1579",
    DESCRICAO: "MARTINS SOARES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1580",
    DESCRICAO: "MATA VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1581",
    DESCRICAO: "MATERLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1582",
    DESCRICAO: "MATEUS LEME",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1583",
    DESCRICAO: "MATIAS BARBOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1584",
    DESCRICAO: "MATIAS CARDOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1585",
    DESCRICAO: "MATIPO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1586",
    DESCRICAO: "MATO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1587",
    DESCRICAO: "MATOZINHOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1588",
    DESCRICAO: "MATUTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1589",
    DESCRICAO: "MEDEIROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1590",
    DESCRICAO: "MEDINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1591",
    DESCRICAO: "MENDES PIMENTEL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1592",
    DESCRICAO: "MERCES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1593",
    DESCRICAO: "MESQUITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1594",
    DESCRICAO: "MINAS NOVAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1595",
    DESCRICAO: "MINDURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1596",
    DESCRICAO: "MIRABELA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1597",
    DESCRICAO: "MIRADOURO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1598",
    DESCRICAO: "MIRAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1599",
    DESCRICAO: "MIRAVANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1600",
    DESCRICAO: "MOEDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1601",
    DESCRICAO: "MOEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1602",
    DESCRICAO: "MONJOLOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1603",
    DESCRICAO: "MONSENHOR PAULO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1604",
    DESCRICAO: "MONTALVANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1605",
    DESCRICAO: "MONTE ALEGRE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1606",
    DESCRICAO: "MONTE AZUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1607",
    DESCRICAO: "MONTE BELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1608",
    DESCRICAO: "MONTE CARMELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1609",
    DESCRICAO: "MONTE FORMOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1610",
    DESCRICAO: "MONTE SANTO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1611",
    DESCRICAO: "MONTES CLAROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1612",
    DESCRICAO: "MONTE SIAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1613",
    DESCRICAO: "MONTEZUMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1614",
    DESCRICAO: "MORADA NOVA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1615",
    DESCRICAO: "MORRO DA GARCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1616",
    DESCRICAO: "MORRO DO PILAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1617",
    DESCRICAO: "MUNHOZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1618",
    DESCRICAO: "MURIAE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1619",
    DESCRICAO: "MUTUM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1620",
    DESCRICAO: "MUZAMBINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1621",
    DESCRICAO: "NACIP RAYDAN",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1622",
    DESCRICAO: "NANUQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1623",
    DESCRICAO: "NAQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1624",
    DESCRICAO: "NATALANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1625",
    DESCRICAO: "NATERCIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1626",
    DESCRICAO: "NAZARENO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1627",
    DESCRICAO: "NEPOMUCENO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1628",
    DESCRICAO: "NINHEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1629",
    DESCRICAO: "NOVA BELEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1630",
    DESCRICAO: "NOVA ERA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1631",
    DESCRICAO: "NOVA LIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1632",
    DESCRICAO: "NOVA MODICA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1633",
    DESCRICAO: "NOVA PONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1634",
    DESCRICAO: "NOVA PORTEIRINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1635",
    DESCRICAO: "NOVA RESENDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1636",
    DESCRICAO: "NOVA SERRANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1637",
    DESCRICAO: "NOVO CRUZEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1638",
    DESCRICAO: "NOVO ORIENTE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1639",
    DESCRICAO: "NOVORIZONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1640",
    DESCRICAO: "OLARIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1641",
    DESCRICAO: "OLHOS-DAGUA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1642",
    DESCRICAO: "OLIMPIO NORONHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1643",
    DESCRICAO: "OLIVEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1644",
    DESCRICAO: "OLIVEIRA FORTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1645",
    DESCRICAO: "ONCA DE PITANGUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1646",
    DESCRICAO: "ORATORIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1647",
    DESCRICAO: "ORIZANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1648",
    DESCRICAO: "OURO BRANCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1649",
    DESCRICAO: "OURO FINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1650",
    DESCRICAO: "OURO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1651",
    DESCRICAO: "OURO VERDE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1652",
    DESCRICAO: "PADRE CARVALHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1653",
    DESCRICAO: "PADRE PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1654",
    DESCRICAO: "PAINEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1655",
    DESCRICAO: "PAINS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1656",
    DESCRICAO: "PAI PEDRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1657",
    DESCRICAO: "PAIVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1658",
    DESCRICAO: "PALMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1659",
    DESCRICAO: "PALMOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1660",
    DESCRICAO: "PAPAGAIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1661",
    DESCRICAO: "PARACATU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1662",
    DESCRICAO: "PARA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1663",
    DESCRICAO: "PARAGUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1664",
    DESCRICAO: "PARAISOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1665",
    DESCRICAO: "PARAOPEBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1666",
    DESCRICAO: "PASSABEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1667",
    DESCRICAO: "PASSA QUATRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1668",
    DESCRICAO: "PASSA TEMPO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1669",
    DESCRICAO: "PASSA-VINTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1670",
    DESCRICAO: "PASSOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1671",
    DESCRICAO: "PATIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1672",
    DESCRICAO: "PATOS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1673",
    DESCRICAO: "PATROCINIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1674",
    DESCRICAO: "PATROCINIO DO MURIAE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1675",
    DESCRICAO: "PAULA CANDIDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1676",
    DESCRICAO: "PAULISTAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1677",
    DESCRICAO: "PAVAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1678",
    DESCRICAO: "PECANHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1679",
    DESCRICAO: "PEDRA AZUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1680",
    DESCRICAO: "PEDRA BONITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1681",
    DESCRICAO: "PEDRA DO ANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1682",
    DESCRICAO: "PEDRA DO INDAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1683",
    DESCRICAO: "PEDRA DOURADA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1684",
    DESCRICAO: "PEDRALVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1685",
    DESCRICAO: "PEDRAS DE MARIA DA CRUZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1686",
    DESCRICAO: "PEDRINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1687",
    DESCRICAO: "PEDRO LEOPOLDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1688",
    DESCRICAO: "PEDRO TEIXEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1689",
    DESCRICAO: "PEQUERI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1690",
    DESCRICAO: "PEQUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1691",
    DESCRICAO: "PERDIGAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1692",
    DESCRICAO: "PERDIZES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1693",
    DESCRICAO: "PERDOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1694",
    DESCRICAO: "PERIQUITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1695",
    DESCRICAO: "PESCADOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1696",
    DESCRICAO: "PIAU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1697",
    DESCRICAO: "PIEDADE DE CARATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1698",
    DESCRICAO: "PIEDADE DE PONTE NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1699",
    DESCRICAO: "PIEDADE DO RIO GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1700",
    DESCRICAO: "PIEDADE DOS GERAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1701",
    DESCRICAO: "PIMENTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1702",
    DESCRICAO: "PINGO-DAGUA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1703",
    DESCRICAO: "PINTOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1704",
    DESCRICAO: "PIRACEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1705",
    DESCRICAO: "PIRAJUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1706",
    DESCRICAO: "PIRANGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1707",
    DESCRICAO: "PIRANGUCU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1708",
    DESCRICAO: "PIRANGUINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1709",
    DESCRICAO: "PIRAPETINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1710",
    DESCRICAO: "PIRAPORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1711",
    DESCRICAO: "PIRAUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1712",
    DESCRICAO: "PITANGUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1713",
    DESCRICAO: "PIUMHI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1714",
    DESCRICAO: "PLANURA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1715",
    DESCRICAO: "POCO FUNDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1716",
    DESCRICAO: "POCOS DE CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1717",
    DESCRICAO: "POCRANE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1718",
    DESCRICAO: "POMPEU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1719",
    DESCRICAO: "PONTE NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1720",
    DESCRICAO: "PONTO CHIQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1721",
    DESCRICAO: "PONTO DOS VOLANTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1722",
    DESCRICAO: "PORTEIRINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1723",
    DESCRICAO: "PORTO FIRME",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1724",
    DESCRICAO: "POTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1725",
    DESCRICAO: "POUSO ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1726",
    DESCRICAO: "POUSO ALTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1727",
    DESCRICAO: "PRADOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1728",
    DESCRICAO: "PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1729",
    DESCRICAO: "PRATAPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1730",
    DESCRICAO: "PRATINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1731",
    DESCRICAO: "PRESIDENTE BERNARDES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1732",
    DESCRICAO: "PRESIDENTE JUSCELINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1733",
    DESCRICAO: "PRESIDENTE KUBITSCHEK",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1734",
    DESCRICAO: "PRESIDENTE OLEGARIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1735",
    DESCRICAO: "ALTO JEQUITIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1736",
    DESCRICAO: "PRUDENTE DE MORAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1737",
    DESCRICAO: "QUARTEL GERAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1738",
    DESCRICAO: "QUELUZITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1739",
    DESCRICAO: "RAPOSOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1740",
    DESCRICAO: "RAUL SOARES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1741",
    DESCRICAO: "RECREIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1742",
    DESCRICAO: "REDUTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1743",
    DESCRICAO: "RESENDE COSTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1744",
    DESCRICAO: "RESPLENDOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1745",
    DESCRICAO: "RESSAQUINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1746",
    DESCRICAO: "RIACHINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1747",
    DESCRICAO: "RIACHO DOS MACHADOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1748",
    DESCRICAO: "RIBEIRAO DAS NEVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1749",
    DESCRICAO: "RIBEIRAO VERMELHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1750",
    DESCRICAO: "RIO ACIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1751",
    DESCRICAO: "RIO CASCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1752",
    DESCRICAO: "RIO DOCE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1753",
    DESCRICAO: "RIO DO PRADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1754",
    DESCRICAO: "RIO ESPERA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1755",
    DESCRICAO: "RIO MANSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1756",
    DESCRICAO: "RIO NOVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1757",
    DESCRICAO: "RIO PARANAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1758",
    DESCRICAO: "RIO PARDO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1759",
    DESCRICAO: "RIO PIRACICABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1760",
    DESCRICAO: "RIO POMBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1761",
    DESCRICAO: "RIO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1762",
    DESCRICAO: "RIO VERMELHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1763",
    DESCRICAO: "RITAPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1764",
    DESCRICAO: "ROCHEDO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1765",
    DESCRICAO: "RODEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1766",
    DESCRICAO: "ROMARIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1767",
    DESCRICAO: "ROSARIO DA LIMEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1768",
    DESCRICAO: "RUBELITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1769",
    DESCRICAO: "RUBIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1770",
    DESCRICAO: "SABARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1771",
    DESCRICAO: "SABINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1772",
    DESCRICAO: "SACRAMENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1773",
    DESCRICAO: "SALINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1774",
    DESCRICAO: "SALTO DA DIVISA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1775",
    DESCRICAO: "SANTA BARBARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1776",
    DESCRICAO: "SANTA BARBARA DO LESTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1777",
    DESCRICAO: "SANTA BARBARA DO MONTE VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1778",
    DESCRICAO: "SANTA BARBARA DO TUGURIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1779",
    DESCRICAO: "SANTA CRUZ DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1780",
    DESCRICAO: "SANTA CRUZ DE SALINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1781",
    DESCRICAO: "SANTA CRUZ DO ESCALVADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1782",
    DESCRICAO: "SANTA EFIGENIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1783",
    DESCRICAO: "SANTA FE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1784",
    DESCRICAO: "SANTA HELENA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1785",
    DESCRICAO: "SANTA JULIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1786",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1787",
    DESCRICAO: "SANTA MARGARIDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1788",
    DESCRICAO: "SANTA MARIA DE ITABIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1789",
    DESCRICAO: "SANTA MARIA DO SALTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1790",
    DESCRICAO: "SANTA MARIA DO SUACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1791",
    DESCRICAO: "SANTANA DA VARGEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1792",
    DESCRICAO: "SANTANA DE CATAGUASES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1793",
    DESCRICAO: "SANTANA DE PIRAPAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1794",
    DESCRICAO: "SANTANA DO DESERTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1795",
    DESCRICAO: "SANTANA DO GARAMBEU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1796",
    DESCRICAO: "SANTANA DO JACARE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1797",
    DESCRICAO: "SANTANA DO MANHUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1798",
    DESCRICAO: "SANTANA DO PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1799",
    DESCRICAO: "SANTANA DO RIACHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1800",
    DESCRICAO: "SANTANA DOS MONTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1801",
    DESCRICAO: "SANTA RITA DE CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1802",
    DESCRICAO: "SANTA RITA DE JACUTINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1803",
    DESCRICAO: "SANTA RITA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1804",
    DESCRICAO: "SANTA RITA DE IBITIPOCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1805",
    DESCRICAO: "SANTA RITA DO ITUETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1806",
    DESCRICAO: "SANTA RITA DO SAPUCAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1807",
    DESCRICAO: "SANTA ROSA DA SERRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1808",
    DESCRICAO: "SANTA VITORIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1809",
    DESCRICAO: "SANTO ANTONIO DO AMPARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1810",
    DESCRICAO: "SANTO ANTONIO DO AVENTUREIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1811",
    DESCRICAO: "SANTO ANTONIO DO GRAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1812",
    DESCRICAO: "SANTO ANTONIO DO ITAMBE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1813",
    DESCRICAO: "SANTO ANTONIO DO JACINTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1814",
    DESCRICAO: "SANTO ANTONIO DO MONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1815",
    DESCRICAO: "SANTO ANTONIO DO RETIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1816",
    DESCRICAO: "SANTO ANTONIO DO RIO ABAIXO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1817",
    DESCRICAO: "SANTO HIPOLITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1818",
    DESCRICAO: "SANTOS DUMONT",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1819",
    DESCRICAO: "SAO BENTO ABADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1820",
    DESCRICAO: "SAO BRAS DO SUACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1821",
    DESCRICAO: "SAO DOMINGOS DAS DORES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1822",
    DESCRICAO: "SAO DOMINGOS DO PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1823",
    DESCRICAO: "SAO FELIX DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1824",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1825",
    DESCRICAO: "SAO FRANCISCO DE PAULA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1826",
    DESCRICAO: "SAO FRANCISCO DE SALES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1827",
    DESCRICAO: "SAO FRANCISCO DO GLORIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1828",
    DESCRICAO: "SAO GERALDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1829",
    DESCRICAO: "SAO GERALDO DA PIEDADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1830",
    DESCRICAO: "SAO GERALDO DO BAIXIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1831",
    DESCRICAO: "SAO GONCALO DO ABAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1832",
    DESCRICAO: "SAO GONCALO DO PARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1833",
    DESCRICAO: "SAO GONCALO DO RIO ABAIXO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1834",
    DESCRICAO: "SAO GONCALO DO SAPUCAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1835",
    DESCRICAO: "SAO GOTARDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1836",
    DESCRICAO: "SAO JOAO BATISTA DO GLORIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1837",
    DESCRICAO: "SAO JOAO DA LAGOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1838",
    DESCRICAO: "SAO JOAO DA MATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1839",
    DESCRICAO: "SAO JOAO DA PONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1840",
    DESCRICAO: "SAO JOAO DAS MISSOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1841",
    DESCRICAO: "SAO JOAO DEL REI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1842",
    DESCRICAO: "SAO JOAO DO MANHUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1843",
    DESCRICAO: "SAO JOAO DO MANTENINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1844",
    DESCRICAO: "SAO JOAO DO ORIENTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1845",
    DESCRICAO: "SAO JOAO DO PACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1846",
    DESCRICAO: "SAO JOAO DO PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1847",
    DESCRICAO: "SAO JOAO EVANGELISTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1848",
    DESCRICAO: "SAO JOAO NEPOMUCENO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1849",
    DESCRICAO: "SAO JOAQUIM DE BICAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1850",
    DESCRICAO: "SAO JOSE DA BARRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1851",
    DESCRICAO: "SAO JOSE DA LAPA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1852",
    DESCRICAO: "SAO JOSE DA SAFIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1853",
    DESCRICAO: "SAO JOSE DA VARGINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1854",
    DESCRICAO: "SAO JOSE DO ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1855",
    DESCRICAO: "SAO JOSE DO DIVINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1856",
    DESCRICAO: "SAO JOSE DO GOIABAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1857",
    DESCRICAO: "SAO JOSE DO JACURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1858",
    DESCRICAO: "SAO JOSE DO MANTIMENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1859",
    DESCRICAO: "SAO LOURENCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1860",
    DESCRICAO: "SAO MIGUEL DO ANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1861",
    DESCRICAO: "SAO PEDRO DA UNIAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1862",
    DESCRICAO: "SAO PEDRO DOS FERROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1863",
    DESCRICAO: "SAO PEDRO DO SUACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1864",
    DESCRICAO: "SAO ROMAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1865",
    DESCRICAO: "SAO ROQUE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1866",
    DESCRICAO: "SAO SEBASTIAO DA BELA VISTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1867",
    DESCRICAO: "SAO SEBASTIAO DA VARGEM ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1868",
    DESCRICAO: "SAO SEBASTIAO DO ANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1869",
    DESCRICAO: "SAO SEBASTIAO DO MARANHAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1870",
    DESCRICAO: "SAO SEBASTIAO DO OESTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1871",
    DESCRICAO: "SAO SEBASTIAO DO PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1872",
    DESCRICAO: "SAO SEBASTIAO DO RIO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1873",
    DESCRICAO: "SAO SEBASTIAO DO RIO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1874",
    DESCRICAO: "SAO TIAGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1875",
    DESCRICAO: "SAO TOMAS DE AQUINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1876",
    DESCRICAO: "SAO THOME DAS LETRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1877",
    DESCRICAO: "SAO VICENTE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1878",
    DESCRICAO: "SAPUCAI-MIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1879",
    DESCRICAO: "SARDOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1880",
    DESCRICAO: "SARZEDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1881",
    DESCRICAO: "SETUBINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1882",
    DESCRICAO: "SEM-PEIXE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1883",
    DESCRICAO: "SENADOR AMARAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1884",
    DESCRICAO: "SENADOR CORTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1885",
    DESCRICAO: "SENADOR FIRMINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1886",
    DESCRICAO: "SENADOR JOSE BENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1887",
    DESCRICAO: "SENADOR MODESTINO GONCALVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1888",
    DESCRICAO: "SENHORA DE OLIVEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1889",
    DESCRICAO: "SENHORA DO PORTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1890",
    DESCRICAO: "SENHORA DOS REMEDIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1891",
    DESCRICAO: "SERICITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1892",
    DESCRICAO: "SERITINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1893",
    DESCRICAO: "SERRA AZUL DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1894",
    DESCRICAO: "SERRA DA SAUDADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1895",
    DESCRICAO: "SERRA DOS AIMORES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1896",
    DESCRICAO: "SERRA DO SALITRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1897",
    DESCRICAO: "SERRANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1898",
    DESCRICAO: "SERRANOPOLIS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1899",
    DESCRICAO: "SERRANOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1900",
    DESCRICAO: "SERRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1901",
    DESCRICAO: "SETE LAGOAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1902",
    DESCRICAO: "SILVEIRANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1903",
    DESCRICAO: "SILVIANOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1904",
    DESCRICAO: "SIMAO PEREIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1905",
    DESCRICAO: "SIMONESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1906",
    DESCRICAO: "SOBRALIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1907",
    DESCRICAO: "SOLEDADE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1908",
    DESCRICAO: "TABULEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1909",
    DESCRICAO: "TAIOBEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1910",
    DESCRICAO: "TAPARUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1911",
    DESCRICAO: "TAPIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1912",
    DESCRICAO: "TAPIRAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1913",
    DESCRICAO: "TAQUARACU DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1914",
    DESCRICAO: "TARUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1915",
    DESCRICAO: "TEIXEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1916",
    DESCRICAO: "TEOFILO OTONI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1917",
    DESCRICAO: "TIMOTEO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1918",
    DESCRICAO: "TIRADENTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1919",
    DESCRICAO: "TIROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1920",
    DESCRICAO: "TOCANTINS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1921",
    DESCRICAO: "TOCOS DO MOJI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1922",
    DESCRICAO: "TOLEDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1923",
    DESCRICAO: "TOMBOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1924",
    DESCRICAO: "TRES CORACOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1925",
    DESCRICAO: "TRES MARIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1926",
    DESCRICAO: "TRES PONTAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1927",
    DESCRICAO: "TUMIRITINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1928",
    DESCRICAO: "TUPACIGUARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1929",
    DESCRICAO: "TURMALINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1930",
    DESCRICAO: "TURVOLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1931",
    DESCRICAO: "UBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1932",
    DESCRICAO: "UBAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1933",
    DESCRICAO: "UBAPORANGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1934",
    DESCRICAO: "UBERABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1935",
    DESCRICAO: "UBERLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1936",
    DESCRICAO: "UMBURATIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1937",
    DESCRICAO: "UNAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1938",
    DESCRICAO: "UNIAO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1939",
    DESCRICAO: "URUANA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1940",
    DESCRICAO: "URUCANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1941",
    DESCRICAO: "URUCUIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1942",
    DESCRICAO: "VARGEM ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1943",
    DESCRICAO: "VARGEM BONITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1944",
    DESCRICAO: "VARGEM GRANDE DO RIO PARDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1945",
    DESCRICAO: "VARGINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1946",
    DESCRICAO: "VARJAO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1947",
    DESCRICAO: "VARZEA DA PALMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1948",
    DESCRICAO: "VARZELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1949",
    DESCRICAO: "VAZANTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1950",
    DESCRICAO: "VERDELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1951",
    DESCRICAO: "VEREDINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1952",
    DESCRICAO: "VERISSIMO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1953",
    DESCRICAO: "VERMELHO NOVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1954",
    DESCRICAO: "VESPASIANO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1955",
    DESCRICAO: "VICOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1956",
    DESCRICAO: "VIEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1957",
    DESCRICAO: "MATHIAS LOBATO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1958",
    DESCRICAO: "VIRGEM DA LAPA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1959",
    DESCRICAO: "VIRGINIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1960",
    DESCRICAO: "VIRGINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1961",
    DESCRICAO: "VIRGOLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1962",
    DESCRICAO: "VISCONDE DO RIO BRANCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1963",
    DESCRICAO: "VOLTA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1964",
    DESCRICAO: "WENCESLAU BRAZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1965",
    DESCRICAO: "ACEGUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1966",
    DESCRICAO: "AGUA SANTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1967",
    DESCRICAO: "AGUDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1968",
    DESCRICAO: "AJURICABA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1969",
    DESCRICAO: "ALECRIM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1970",
    DESCRICAO: "ALEGRETE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1971",
    DESCRICAO: "ALEGRIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1972",
    DESCRICAO: "ALMIRANTE TAMANDARE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1973",
    DESCRICAO: "ALPESTRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1974",
    DESCRICAO: "ALTO ALEGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1975",
    DESCRICAO: "ALTO FELIZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1976",
    DESCRICAO: "ALVORADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1977",
    DESCRICAO: "AMARAL FERRADOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1978",
    DESCRICAO: "AMETISTA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1979",
    DESCRICAO: "ANDRE DA ROCHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1980",
    DESCRICAO: "ANTA GORDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1981",
    DESCRICAO: "ANTONIO PRADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1982",
    DESCRICAO: "ARAMBARE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1983",
    DESCRICAO: "ARARICA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1984",
    DESCRICAO: "ARATIBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1985",
    DESCRICAO: "ARROIO DO MEIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1986",
    DESCRICAO: "ARROIO DO SAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1987",
    DESCRICAO: "ARROIO DO PADRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1988",
    DESCRICAO: "ARROIO DOS RATOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1989",
    DESCRICAO: "ARROIO DO TIGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1990",
    DESCRICAO: "ARROIO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1991",
    DESCRICAO: "ARVOREZINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1992",
    DESCRICAO: "AUGUSTO PESTANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1993",
    DESCRICAO: "AUREA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1994",
    DESCRICAO: "BAGE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1995",
    DESCRICAO: "BALNEARIO PINHAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1996",
    DESCRICAO: "BARAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1997",
    DESCRICAO: "BARAO DE COTEGIPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1998",
    DESCRICAO: "BARAO DO TRIUNFO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1999",
    DESCRICAO: "BARRACAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2000",
    DESCRICAO: "BARRA DO GUARITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2001",
    DESCRICAO: "BARRA DO QUARAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2002",
    DESCRICAO: "BARRA DO RIBEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2003",
    DESCRICAO: "BARRA DO RIO AZUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2004",
    DESCRICAO: "BARRA FUNDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2005",
    DESCRICAO: "BARROS CASSAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2006",
    DESCRICAO: "BENJAMIN CONSTANT DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2007",
    DESCRICAO: "BENTO GONCALVES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2008",
    DESCRICAO: "BOA VISTA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2009",
    DESCRICAO: "BOA VISTA DO BURICA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2010",
    DESCRICAO: "BOA VISTA DO CADEADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2011",
    DESCRICAO: "BOA VISTA DO INCRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2012",
    DESCRICAO: "BOA VISTA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2013",
    DESCRICAO: "BOM JESUS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2014",
    DESCRICAO: "BOM PRINCIPIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2015",
    DESCRICAO: "BOM PROGRESSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2016",
    DESCRICAO: "BOM RETIRO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2017",
    DESCRICAO: "BOQUEIRAO DO LEAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2018",
    DESCRICAO: "BOSSOROCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2019",
    DESCRICAO: "BOZANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2020",
    DESCRICAO: "BRAGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2021",
    DESCRICAO: "BROCHIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2022",
    DESCRICAO: "BUTIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2023",
    DESCRICAO: "CACAPAVA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2024",
    DESCRICAO: "CACEQUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2025",
    DESCRICAO: "CACHOEIRA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2026",
    DESCRICAO: "CACHOEIRINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2027",
    DESCRICAO: "CACIQUE DOBLE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2028",
    DESCRICAO: "CAIBATE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2029",
    DESCRICAO: "CAICARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2030",
    DESCRICAO: "CAMAQUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2031",
    DESCRICAO: "CAMARGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2032",
    DESCRICAO: "CAMBARA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2033",
    DESCRICAO: "CAMPESTRE DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2034",
    DESCRICAO: "CAMPINA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2035",
    DESCRICAO: "CAMPINAS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2036",
    DESCRICAO: "CAMPO BOM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2037",
    DESCRICAO: "CAMPO NOVO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2038",
    DESCRICAO: "CAMPOS BORGES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2039",
    DESCRICAO: "CANDELARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2040",
    DESCRICAO: "CANDIDO GODOI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2041",
    DESCRICAO: "CANDIOTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2042",
    DESCRICAO: "CANELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2043",
    DESCRICAO: "CANGUCU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2044",
    DESCRICAO: "CANOAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2045",
    DESCRICAO: "CANUDOS DO VALE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2046",
    DESCRICAO: "CAPAO BONITO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2047",
    DESCRICAO: "CAPAO DA CANOA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2048",
    DESCRICAO: "CAPAO DO CIPO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2049",
    DESCRICAO: "CAPAO DO LEAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2050",
    DESCRICAO: "CAPIVARI DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2051",
    DESCRICAO: "CAPELA DE SANTANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2052",
    DESCRICAO: "CAPITAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2053",
    DESCRICAO: "CARAZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2054",
    DESCRICAO: "CARAA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2055",
    DESCRICAO: "CARLOS BARBOSA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2056",
    DESCRICAO: "CARLOS GOMES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2057",
    DESCRICAO: "CASCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2058",
    DESCRICAO: "CASEIROS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2059",
    DESCRICAO: "CATUIPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2060",
    DESCRICAO: "CAXIAS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2061",
    DESCRICAO: "CENTENARIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2062",
    DESCRICAO: "CERRITO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2063",
    DESCRICAO: "CERRO BRANCO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2064",
    DESCRICAO: "CERRO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2065",
    DESCRICAO: "CERRO GRANDE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2066",
    DESCRICAO: "CERRO LARGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2067",
    DESCRICAO: "CHAPADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2068",
    DESCRICAO: "CHARQUEADAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2069",
    DESCRICAO: "CHARRUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2070",
    DESCRICAO: "CHIAPETTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2071",
    DESCRICAO: "CHUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2072",
    DESCRICAO: "CHUVISCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2073",
    DESCRICAO: "CIDREIRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2074",
    DESCRICAO: "CIRIACO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2075",
    DESCRICAO: "COLINAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2076",
    DESCRICAO: "COLORADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2077",
    DESCRICAO: "CONDOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2078",
    DESCRICAO: "CONSTANTINA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2079",
    DESCRICAO: "COQUEIRO BAIXO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2080",
    DESCRICAO: "COQUEIROS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2081",
    DESCRICAO: "CORONEL BARROS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2082",
    DESCRICAO: "CORONEL BICACO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2083",
    DESCRICAO: "CORONEL PILAR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2084",
    DESCRICAO: "COTIPORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2085",
    DESCRICAO: "COXILHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2086",
    DESCRICAO: "CRISSIUMAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2087",
    DESCRICAO: "CRISTAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2088",
    DESCRICAO: "CRISTAL DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2089",
    DESCRICAO: "CRUZ ALTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2090",
    DESCRICAO: "CRUZALTENSE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2091",
    DESCRICAO: "CRUZEIRO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2092",
    DESCRICAO: "DAVID CANABARRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2093",
    DESCRICAO: "DERRUBADAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2094",
    DESCRICAO: "DEZESSEIS DE NOVEMBRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2095",
    DESCRICAO: "DILERMANDO DE AGUIAR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2096",
    DESCRICAO: "DOIS IRMAOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2097",
    DESCRICAO: "DOIS IRMAOS DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2098",
    DESCRICAO: "DOIS LAJEADOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2099",
    DESCRICAO: "DOM FELICIANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2100",
    DESCRICAO: "DOM PEDRO DE ALCANTARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2101",
    DESCRICAO: "DOM PEDRITO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2102",
    DESCRICAO: "DONA FRANCISCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2103",
    DESCRICAO: "DOUTOR MAURICIO CARDOSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2104",
    DESCRICAO: "DOUTOR RICARDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2105",
    DESCRICAO: "ELDORADO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2106",
    DESCRICAO: "ENCANTADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2107",
    DESCRICAO: "ENCRUZILHADA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2108",
    DESCRICAO: "ENGENHO VELHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2109",
    DESCRICAO: "ENTRE-IJUIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2110",
    DESCRICAO: "ENTRE RIOS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2111",
    DESCRICAO: "EREBANGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2112",
    DESCRICAO: "ERECHIM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2113",
    DESCRICAO: "ERNESTINA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2114",
    DESCRICAO: "HERVAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2115",
    DESCRICAO: "ERVAL GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2116",
    DESCRICAO: "ERVAL SECO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2117",
    DESCRICAO: "ESMERALDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2118",
    DESCRICAO: "ESPERANCA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2119",
    DESCRICAO: "ESPUMOSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2120",
    DESCRICAO: "ESTACAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2121",
    DESCRICAO: "ESTANCIA VELHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2122",
    DESCRICAO: "ESTEIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2123",
    DESCRICAO: "ESTRELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2124",
    DESCRICAO: "ESTRELA VELHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2125",
    DESCRICAO: "EUGENIO DE CASTRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2126",
    DESCRICAO: "FAGUNDES VARELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2127",
    DESCRICAO: "FARROUPILHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2128",
    DESCRICAO: "FAXINAL DO SOTURNO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2129",
    DESCRICAO: "FAXINALZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2130",
    DESCRICAO: "FAZENDA VILANOVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2131",
    DESCRICAO: "FELIZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2132",
    DESCRICAO: "FLORES DA CUNHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2133",
    DESCRICAO: "FLORIANO PEIXOTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2134",
    DESCRICAO: "FONTOURA XAVIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2135",
    DESCRICAO: "FORMIGUEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2136",
    DESCRICAO: "FORQUETINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2137",
    DESCRICAO: "FORTALEZA DOS VALOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2138",
    DESCRICAO: "FREDERICO WESTPHALEN",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2139",
    DESCRICAO: "GARIBALDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2140",
    DESCRICAO: "GARRUCHOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2141",
    DESCRICAO: "GAURAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2142",
    DESCRICAO: "GENERAL CAMARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2143",
    DESCRICAO: "GENTIL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2144",
    DESCRICAO: "GETULIO VARGAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2145",
    DESCRICAO: "GIRUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2146",
    DESCRICAO: "GLORINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2147",
    DESCRICAO: "GRAMADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2148",
    DESCRICAO: "GRAMADO DOS LOUREIROS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2149",
    DESCRICAO: "GRAMADO XAVIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2150",
    DESCRICAO: "GRAVATAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2151",
    DESCRICAO: "GUABIJU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2152",
    DESCRICAO: "GUAIBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2153",
    DESCRICAO: "GUAPORE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2154",
    DESCRICAO: "GUARANI DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2155",
    DESCRICAO: "HARMONIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2156",
    DESCRICAO: "HERVEIRAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2157",
    DESCRICAO: "HORIZONTINA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2158",
    DESCRICAO: "HULHA NEGRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2159",
    DESCRICAO: "HUMAITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2160",
    DESCRICAO: "IBARAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2161",
    DESCRICAO: "IBIACA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2162",
    DESCRICAO: "IBIRAIARAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2163",
    DESCRICAO: "IBIRAPUITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2164",
    DESCRICAO: "IBIRUBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2165",
    DESCRICAO: "IGREJINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2166",
    DESCRICAO: "IJUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2167",
    DESCRICAO: "ILOPOLIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2168",
    DESCRICAO: "IMBE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2169",
    DESCRICAO: "IMIGRANTE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2170",
    DESCRICAO: "INDEPENDENCIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2171",
    DESCRICAO: "INHACORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2172",
    DESCRICAO: "IPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2173",
    DESCRICAO: "IPIRANGA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2174",
    DESCRICAO: "IRAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2175",
    DESCRICAO: "ITAARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2176",
    DESCRICAO: "ITACURUBI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2177",
    DESCRICAO: "ITAPUCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2178",
    DESCRICAO: "ITAQUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2179",
    DESCRICAO: "ITATI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2180",
    DESCRICAO: "ITATIBA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2181",
    DESCRICAO: "IVORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2182",
    DESCRICAO: "IVOTI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2183",
    DESCRICAO: "JABOTICABA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2184",
    DESCRICAO: "JACUIZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2185",
    DESCRICAO: "JACUTINGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2186",
    DESCRICAO: "JAGUARAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2187",
    DESCRICAO: "JAGUARI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2188",
    DESCRICAO: "JAQUIRANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2189",
    DESCRICAO: "JARI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2190",
    DESCRICAO: "JOIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2191",
    DESCRICAO: "JULIO DE CASTILHOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2192",
    DESCRICAO: "LAGOA BONITA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2193",
    DESCRICAO: "LAGOAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2194",
    DESCRICAO: "LAGOA DOS TRES CANTOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2195",
    DESCRICAO: "LAGOA VERMELHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2196",
    DESCRICAO: "LAJEADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2197",
    DESCRICAO: "LAJEADO DO BUGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2198",
    DESCRICAO: "LAVRAS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2199",
    DESCRICAO: "LIBERATO SALZANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2200",
    DESCRICAO: "LINDOLFO COLLOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2201",
    DESCRICAO: "LINHA NOVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2202",
    DESCRICAO: "MACHADINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2203",
    DESCRICAO: "MACAMBARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2204",
    DESCRICAO: "MAMPITUBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2205",
    DESCRICAO: "MANOEL VIANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2206",
    DESCRICAO: "MAQUINE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2207",
    DESCRICAO: "MARATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2208",
    DESCRICAO: "MARAU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2209",
    DESCRICAO: "MARCELINO RAMOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2210",
    DESCRICAO: "MARIANA PIMENTEL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2211",
    DESCRICAO: "MARIANO MORO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2212",
    DESCRICAO: "MARQUES DE SOUZA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2213",
    DESCRICAO: "MATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2214",
    DESCRICAO: "MATO CASTELHANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2215",
    DESCRICAO: "MATO LEITAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2216",
    DESCRICAO: "MATO QUEIMADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2217",
    DESCRICAO: "MAXIMILIANO DE ALMEIDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2218",
    DESCRICAO: "MINAS DO LEAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2219",
    DESCRICAO: "MIRAGUAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2220",
    DESCRICAO: "MONTAURI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2221",
    DESCRICAO: "MONTE ALEGRE DOS CAMPOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2222",
    DESCRICAO: "MONTE BELO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2223",
    DESCRICAO: "MONTENEGRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2224",
    DESCRICAO: "MORMACO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2225",
    DESCRICAO: "MORRINHOS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2226",
    DESCRICAO: "MORRO REDONDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2227",
    DESCRICAO: "MORRO REUTER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2228",
    DESCRICAO: "MOSTARDAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2229",
    DESCRICAO: "MUCUM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2230",
    DESCRICAO: "MUITOS CAPOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2231",
    DESCRICAO: "MULITERNO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2232",
    DESCRICAO: "NAO-ME-TOQUE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2233",
    DESCRICAO: "NICOLAU VERGUEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2234",
    DESCRICAO: "NONOAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2235",
    DESCRICAO: "NOVA ALVORADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2236",
    DESCRICAO: "NOVA ARACA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2237",
    DESCRICAO: "NOVA BASSANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2238",
    DESCRICAO: "NOVA BOA VISTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2239",
    DESCRICAO: "NOVA BRESCIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2240",
    DESCRICAO: "NOVA CANDELARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2241",
    DESCRICAO: "NOVA ESPERANCA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2242",
    DESCRICAO: "NOVA HARTZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2243",
    DESCRICAO: "NOVA PADUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2244",
    DESCRICAO: "NOVA PALMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2245",
    DESCRICAO: "NOVA PETROPOLIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2246",
    DESCRICAO: "NOVA PRATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2247",
    DESCRICAO: "NOVA RAMADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2248",
    DESCRICAO: "NOVA ROMA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2249",
    DESCRICAO: "NOVA SANTA RITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2250",
    DESCRICAO: "NOVO CABRAIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2251",
    DESCRICAO: "NOVO HAMBURGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2252",
    DESCRICAO: "NOVO MACHADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2253",
    DESCRICAO: "NOVO TIRADENTES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2254",
    DESCRICAO: "NOVO XINGU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2255",
    DESCRICAO: "NOVO BARREIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2256",
    DESCRICAO: "OSORIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2257",
    DESCRICAO: "PAIM FILHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2258",
    DESCRICAO: "PALMARES DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2259",
    DESCRICAO: "PALMEIRA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2260",
    DESCRICAO: "PALMITINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2261",
    DESCRICAO: "PANAMBI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2262",
    DESCRICAO: "PANTANO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2263",
    DESCRICAO: "PARAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2264",
    DESCRICAO: "PARAISO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2265",
    DESCRICAO: "PARECI NOVO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2266",
    DESCRICAO: "PAROBE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2267",
    DESCRICAO: "PASSA SETE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2268",
    DESCRICAO: "PASSO DO SOBRADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2269",
    DESCRICAO: "PASSO FUNDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2270",
    DESCRICAO: "PAULO BENTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2271",
    DESCRICAO: "PAVERAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2272",
    DESCRICAO: "PEDRAS ALTAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2273",
    DESCRICAO: "PEDRO OSORIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2274",
    DESCRICAO: "PEJUCARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2275",
    DESCRICAO: "PELOTAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2276",
    DESCRICAO: "PICADA CAFE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2277",
    DESCRICAO: "PINHAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2278",
    DESCRICAO: "PINHAL DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2279",
    DESCRICAO: "PINHAL GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2280",
    DESCRICAO: "PINHEIRINHO DO VALE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2281",
    DESCRICAO: "PINHEIRO MACHADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2282",
    DESCRICAO: "PIRAPO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2283",
    DESCRICAO: "PIRATINI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2284",
    DESCRICAO: "PLANALTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2285",
    DESCRICAO: "POCO DAS ANTAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2286",
    DESCRICAO: "PONTAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2287",
    DESCRICAO: "PONTE PRETA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2288",
    DESCRICAO: "PORTAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2289",
    DESCRICAO: "PORTO ALEGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2290",
    DESCRICAO: "PORTO LUCENA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2291",
    DESCRICAO: "PORTO MAUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2292",
    DESCRICAO: "PORTO VERA CRUZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2293",
    DESCRICAO: "PORTO XAVIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2294",
    DESCRICAO: "POUSO NOVO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2295",
    DESCRICAO: "PRESIDENTE LUCENA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2296",
    DESCRICAO: "PROGRESSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2297",
    DESCRICAO: "PROTASIO ALVES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2298",
    DESCRICAO: "PUTINGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2299",
    DESCRICAO: "QUARAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2300",
    DESCRICAO: "QUATRO IRMAOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2301",
    DESCRICAO: "QUEVEDOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2302",
    DESCRICAO: "QUINZE DE NOVEMBRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2303",
    DESCRICAO: "REDENTORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2304",
    DESCRICAO: "RELVADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2305",
    DESCRICAO: "RESTINGA SECA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2306",
    DESCRICAO: "RIO DOS INDIOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2307",
    DESCRICAO: "RIO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2308",
    DESCRICAO: "RIO PARDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2309",
    DESCRICAO: "RIOZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2310",
    DESCRICAO: "ROCA SALES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2311",
    DESCRICAO: "RODEIO BONITO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2312",
    DESCRICAO: "ROLADOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2313",
    DESCRICAO: "ROLANTE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2314",
    DESCRICAO: "RONDA ALTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2315",
    DESCRICAO: "RONDINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2316",
    DESCRICAO: "ROQUE GONZALES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2317",
    DESCRICAO: "ROSARIO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2318",
    DESCRICAO: "SAGRADA FAMILIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2319",
    DESCRICAO: "SALDANHA MARINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2320",
    DESCRICAO: "SALTO DO JACUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2321",
    DESCRICAO: "SALVADOR DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2322",
    DESCRICAO: "SALVADOR DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2323",
    DESCRICAO: "SANANDUVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2324",
    DESCRICAO: "SANTA BARBARA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2325",
    DESCRICAO: "SANTA CECILIA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2326",
    DESCRICAO: "SANTA CLARA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2327",
    DESCRICAO: "SANTA CRUZ DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2328",
    DESCRICAO: "SANTA MARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2329",
    DESCRICAO: "SANTA MARIA DO HERVAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2330",
    DESCRICAO: "SANTA MARGARIDA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2331",
    DESCRICAO: "SANTANA DA BOA VISTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2332",
    DESCRICAO: "SANTANA DO LIVRAMENTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2333",
    DESCRICAO: "SANTA ROSA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2334",
    DESCRICAO: "SANTA TEREZA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2335",
    DESCRICAO: "SANTA VITORIA DO PALMAR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2336",
    DESCRICAO: "SANTIAGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2337",
    DESCRICAO: "SANTO ANGELO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2338",
    DESCRICAO: "SANTO ANTONIO DO PALMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2339",
    DESCRICAO: "SANTO ANTONIO DA PATRULHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2340",
    DESCRICAO: "SANTO ANTONIO DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2341",
    DESCRICAO: "SANTO ANTONIO DO PLANALTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2342",
    DESCRICAO: "SANTO AUGUSTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2343",
    DESCRICAO: "SANTO CRISTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2344",
    DESCRICAO: "SANTO EXPEDITO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2345",
    DESCRICAO: "SAO BORJA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2346",
    DESCRICAO: "SAO DOMINGOS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2347",
    DESCRICAO: "SAO FRANCISCO DE ASSIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2348",
    DESCRICAO: "SAO FRANCISCO DE PAULA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2349",
    DESCRICAO: "SAO GABRIEL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2350",
    DESCRICAO: "SAO JERONIMO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2351",
    DESCRICAO: "SAO JOAO DA URTIGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2352",
    DESCRICAO: "SAO JOAO DO POLESINE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2353",
    DESCRICAO: "SAO JORGE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2354",
    DESCRICAO: "SAO JOSE DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2355",
    DESCRICAO: "SAO JOSE DO HERVAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2356",
    DESCRICAO: "SAO JOSE DO HORTENCIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2357",
    DESCRICAO: "SAO JOSE DO INHACORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2358",
    DESCRICAO: "SAO JOSE DO NORTE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2359",
    DESCRICAO: "SAO JOSE DO OURO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2360",
    DESCRICAO: "SAO JOSE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2361",
    DESCRICAO: "SAO JOSE DOS AUSENTES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2362",
    DESCRICAO: "SAO LEOPOLDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2363",
    DESCRICAO: "SAO LOURENCO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2364",
    DESCRICAO: "SAO LUIZ GONZAGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2365",
    DESCRICAO: "SAO MARCOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2366",
    DESCRICAO: "SAO MARTINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2367",
    DESCRICAO: "SAO MARTINHO DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2368",
    DESCRICAO: "SAO MIGUEL DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2369",
    DESCRICAO: "SAO NICOLAU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2370",
    DESCRICAO: "SAO PAULO DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2371",
    DESCRICAO: "SAO PEDRO DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2372",
    DESCRICAO: "SAO PEDRO DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2373",
    DESCRICAO: "SAO PEDRO DO BUTIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2374",
    DESCRICAO: "SAO PEDRO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2375",
    DESCRICAO: "SAO SEBASTIAO DO CAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2376",
    DESCRICAO: "SAO SEPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2377",
    DESCRICAO: "SAO VALENTIM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2378",
    DESCRICAO: "SAO VALENTIM DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2379",
    DESCRICAO: "SAO VALERIO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2380",
    DESCRICAO: "SAO VENDELINO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2381",
    DESCRICAO: "SAO VICENTE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2382",
    DESCRICAO: "SAPIRANGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2383",
    DESCRICAO: "SAPUCAIA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2384",
    DESCRICAO: "SARANDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2385",
    DESCRICAO: "SEBERI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2386",
    DESCRICAO: "SEDE NOVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2387",
    DESCRICAO: "SEGREDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2388",
    DESCRICAO: "SELBACH",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2389",
    DESCRICAO: "SENADOR SALGADO FILHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2390",
    DESCRICAO: "SENTINELA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2391",
    DESCRICAO: "SERAFINA CORREA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2392",
    DESCRICAO: "SERIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2393",
    DESCRICAO: "SERTAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2394",
    DESCRICAO: "SERTAO SANTANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2395",
    DESCRICAO: "SETE DE SETEMBRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2396",
    DESCRICAO: "SEVERIANO DE ALMEIDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2397",
    DESCRICAO: "SILVEIRA MARTINS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2398",
    DESCRICAO: "SINIMBU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2399",
    DESCRICAO: "SOBRADINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2400",
    DESCRICAO: "SOLEDADE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2401",
    DESCRICAO: "TABAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2402",
    DESCRICAO: "TAPEJARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2403",
    DESCRICAO: "TAPERA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2404",
    DESCRICAO: "TAPES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2405",
    DESCRICAO: "TAQUARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2406",
    DESCRICAO: "TAQUARI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2407",
    DESCRICAO: "TAQUARUCU DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2408",
    DESCRICAO: "TAVARES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2409",
    DESCRICAO: "TENENTE PORTELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2410",
    DESCRICAO: "TERRA DE AREIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2411",
    DESCRICAO: "TEUTONIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2412",
    DESCRICAO: "TIO HUGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2413",
    DESCRICAO: "TIRADENTES DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2414",
    DESCRICAO: "TOROPI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2415",
    DESCRICAO: "TORRES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2416",
    DESCRICAO: "TRAMANDAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2417",
    DESCRICAO: "TRAVESSEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2418",
    DESCRICAO: "TRES ARROIOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2419",
    DESCRICAO: "TRES CACHOEIRAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2420",
    DESCRICAO: "TRES COROAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2421",
    DESCRICAO: "TRES DE MAIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2422",
    DESCRICAO: "TRES FORQUILHAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2423",
    DESCRICAO: "TRES PALMEIRAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2424",
    DESCRICAO: "TRES PASSOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2425",
    DESCRICAO: "TRINDADE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2426",
    DESCRICAO: "TRIUNFO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2427",
    DESCRICAO: "TUCUNDUVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2428",
    DESCRICAO: "TUNAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2429",
    DESCRICAO: "TUPANCI DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2430",
    DESCRICAO: "TUPANCIRETA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2431",
    DESCRICAO: "TUPANDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2432",
    DESCRICAO: "TUPARENDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2433",
    DESCRICAO: "TURUCU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2434",
    DESCRICAO: "UBIRETAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2435",
    DESCRICAO: "UNIAO DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2436",
    DESCRICAO: "UNISTALDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2437",
    DESCRICAO: "URUGUAIANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2438",
    DESCRICAO: "VACARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2439",
    DESCRICAO: "VALE VERDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2440",
    DESCRICAO: "VALE DO SOL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2441",
    DESCRICAO: "VALE REAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2442",
    DESCRICAO: "VANINI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2443",
    DESCRICAO: "VENANCIO AIRES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2444",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2445",
    DESCRICAO: "VERANOPOLIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2446",
    DESCRICAO: "VESPASIANO CORREA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2447",
    DESCRICAO: "VIADUTOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2448",
    DESCRICAO: "VIAMAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2449",
    DESCRICAO: "VICENTE DUTRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2450",
    DESCRICAO: "VICTOR GRAEFF",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2451",
    DESCRICAO: "VILA FLORES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2452",
    DESCRICAO: "VILA LANGARO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2453",
    DESCRICAO: "VILA MARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2454",
    DESCRICAO: "VILA NOVA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2455",
    DESCRICAO: "VISTA ALEGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2456",
    DESCRICAO: "VISTA ALEGRE DO PRATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2457",
    DESCRICAO: "VISTA GAUCHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2458",
    DESCRICAO: "VITORIA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2459",
    DESCRICAO: "WESTFALIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2460",
    DESCRICAO: "XANGRI-LA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2461",
    DESCRICAO: "ABDON BATISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2462",
    DESCRICAO: "ABELARDO LUZ",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2463",
    DESCRICAO: "AGROLANDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2464",
    DESCRICAO: "AGRONOMICA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2465",
    DESCRICAO: "AGUA DOCE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2466",
    DESCRICAO: "AGUAS DE CHAPECO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2467",
    DESCRICAO: "AGUAS FRIAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2468",
    DESCRICAO: "AGUAS MORNAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2469",
    DESCRICAO: "ALFREDO WAGNER",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2470",
    DESCRICAO: "ALTO BELA VISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2471",
    DESCRICAO: "ANCHIETA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2472",
    DESCRICAO: "ANGELINA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2473",
    DESCRICAO: "ANITA GARIBALDI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2474",
    DESCRICAO: "ANITAPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2475",
    DESCRICAO: "ANTONIO CARLOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2476",
    DESCRICAO: "APIUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2477",
    DESCRICAO: "ARABUTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2478",
    DESCRICAO: "ARAQUARI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2479",
    DESCRICAO: "ARARANGUA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2480",
    DESCRICAO: "ARMAZEM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2481",
    DESCRICAO: "ARROIO TRINTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2482",
    DESCRICAO: "ARVOREDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2483",
    DESCRICAO: "ASCURRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2484",
    DESCRICAO: "ATALANTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2485",
    DESCRICAO: "AURORA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2486",
    DESCRICAO: "BALNEARIO ARROIO DO SILVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2487",
    DESCRICAO: "BALNEARIO CAMBORIU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2488",
    DESCRICAO: "BALNEARIO BARRA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2489",
    DESCRICAO: "BALNEARIO GAIVOTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2490",
    DESCRICAO: "BANDEIRANTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2491",
    DESCRICAO: "BARRA BONITA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2492",
    DESCRICAO: "BARRA VELHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2493",
    DESCRICAO: "BELA VISTA DO TOLDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2494",
    DESCRICAO: "BELMONTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2495",
    DESCRICAO: "BENEDITO NOVO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2496",
    DESCRICAO: "BIGUACU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2497",
    DESCRICAO: "BLUMENAU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2498",
    DESCRICAO: "BOCAINA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2499",
    DESCRICAO: "BOMBINHAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2500",
    DESCRICAO: "BOM JARDIM DA SERRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2501",
    DESCRICAO: "BOM JESUS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2502",
    DESCRICAO: "BOM JESUS DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2503",
    DESCRICAO: "BOM RETIRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2504",
    DESCRICAO: "BOTUVERA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2505",
    DESCRICAO: "BRACO DO NORTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2506",
    DESCRICAO: "BRACO DO TROMBUDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2507",
    DESCRICAO: "BRUNOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2508",
    DESCRICAO: "BRUSQUE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2509",
    DESCRICAO: "CACADOR",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2510",
    DESCRICAO: "CAIBI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2511",
    DESCRICAO: "CALMON",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2512",
    DESCRICAO: "CAMBORIU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2513",
    DESCRICAO: "CAPAO ALTO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2514",
    DESCRICAO: "CAMPO ALEGRE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2515",
    DESCRICAO: "CAMPO BELO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2516",
    DESCRICAO: "CAMPO ERE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2517",
    DESCRICAO: "CAMPOS NOVOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2518",
    DESCRICAO: "CANELINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2519",
    DESCRICAO: "CANOINHAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2520",
    DESCRICAO: "CAPINZAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2521",
    DESCRICAO: "CAPIVARI DE BAIXO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2522",
    DESCRICAO: "CATANDUVAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2523",
    DESCRICAO: "CAXAMBU DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2524",
    DESCRICAO: "CELSO RAMOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2525",
    DESCRICAO: "CERRO NEGRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2526",
    DESCRICAO: "CHAPADAO DO LAGEADO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2527",
    DESCRICAO: "CHAPECO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2528",
    DESCRICAO: "COCAL DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2529",
    DESCRICAO: "CONCORDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2530",
    DESCRICAO: "CORDILHEIRA ALTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2531",
    DESCRICAO: "CORONEL FREITAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2532",
    DESCRICAO: "CORONEL MARTINS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2533",
    DESCRICAO: "CORUPA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2534",
    DESCRICAO: "CORREIA PINTO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2535",
    DESCRICAO: "CRICIUMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2536",
    DESCRICAO: "CUNHA PORA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2537",
    DESCRICAO: "CUNHATAI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2538",
    DESCRICAO: "CURITIBANOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2539",
    DESCRICAO: "DESCANSO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2540",
    DESCRICAO: "DIONISIO CERQUEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2541",
    DESCRICAO: "DONA EMMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2542",
    DESCRICAO: "DOUTOR PEDRINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2543",
    DESCRICAO: "ENTRE RIOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2544",
    DESCRICAO: "ERMO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2545",
    DESCRICAO: "ERVAL VELHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2546",
    DESCRICAO: "FAXINAL DOS GUEDES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2547",
    DESCRICAO: "FLOR DO SERTAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2548",
    DESCRICAO: "FLORIANOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2549",
    DESCRICAO: "FORMOSA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2550",
    DESCRICAO: "FORQUILHINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2551",
    DESCRICAO: "FRAIBURGO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2552",
    DESCRICAO: "FREI ROGERIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2553",
    DESCRICAO: "GALVAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2554",
    DESCRICAO: "GAROPABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2555",
    DESCRICAO: "GARUVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2556",
    DESCRICAO: "GASPAR",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2557",
    DESCRICAO: "GOVERNADOR CELSO RAMOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2558",
    DESCRICAO: "GRAO PARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2559",
    DESCRICAO: "GRAVATAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2560",
    DESCRICAO: "GUABIRUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2561",
    DESCRICAO: "GUARACIABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2562",
    DESCRICAO: "GUARAMIRIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2563",
    DESCRICAO: "GUARUJA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2564",
    DESCRICAO: "GUATAMBU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2565",
    DESCRICAO: "HERVAL DOESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2566",
    DESCRICAO: "IBIAM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2567",
    DESCRICAO: "IBICARE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2568",
    DESCRICAO: "IBIRAMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2569",
    DESCRICAO: "ICARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2570",
    DESCRICAO: "ILHOTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2571",
    DESCRICAO: "IMARUI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2572",
    DESCRICAO: "IMBITUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2573",
    DESCRICAO: "IMBUIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2574",
    DESCRICAO: "INDAIAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2575",
    DESCRICAO: "IOMERE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2576",
    DESCRICAO: "IPIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2577",
    DESCRICAO: "IPORA DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2578",
    DESCRICAO: "IPUACU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2579",
    DESCRICAO: "IPUMIRIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2580",
    DESCRICAO: "IRACEMINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2581",
    DESCRICAO: "IRANI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2582",
    DESCRICAO: "IRATI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2583",
    DESCRICAO: "IRINEOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2584",
    DESCRICAO: "ITA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2585",
    DESCRICAO: "ITAIOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2586",
    DESCRICAO: "ITAJAI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2587",
    DESCRICAO: "ITAPEMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2588",
    DESCRICAO: "ITAPIRANGA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2589",
    DESCRICAO: "ITAPOA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2590",
    DESCRICAO: "ITUPORANGA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2591",
    DESCRICAO: "JABORA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2592",
    DESCRICAO: "JACINTO MACHADO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2593",
    DESCRICAO: "JAGUARUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2594",
    DESCRICAO: "JARAGUA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2595",
    DESCRICAO: "JARDINOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2596",
    DESCRICAO: "JOACABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2597",
    DESCRICAO: "JOINVILLE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2598",
    DESCRICAO: "JOSE BOITEUX",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2599",
    DESCRICAO: "JUPIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2600",
    DESCRICAO: "LACERDOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2601",
    DESCRICAO: "LAGES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2602",
    DESCRICAO: "LAGUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2603",
    DESCRICAO: "LAJEADO GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2604",
    DESCRICAO: "LAURENTINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2605",
    DESCRICAO: "LAURO MULLER",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2606",
    DESCRICAO: "LEBON REGIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2607",
    DESCRICAO: "LEOBERTO LEAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2608",
    DESCRICAO: "LINDOIA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2609",
    DESCRICAO: "LONTRAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2610",
    DESCRICAO: "LUIZ ALVES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2611",
    DESCRICAO: "LUZERNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2612",
    DESCRICAO: "MACIEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2613",
    DESCRICAO: "MAFRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2614",
    DESCRICAO: "MAJOR GERCINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2615",
    DESCRICAO: "MAJOR VIEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2616",
    DESCRICAO: "MARACAJA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2617",
    DESCRICAO: "MARAVILHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2618",
    DESCRICAO: "MAREMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2619",
    DESCRICAO: "MASSARANDUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2620",
    DESCRICAO: "MATOS COSTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2621",
    DESCRICAO: "MELEIRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2622",
    DESCRICAO: "MIRIM DOCE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2623",
    DESCRICAO: "MODELO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2624",
    DESCRICAO: "MONDAI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2625",
    DESCRICAO: "MONTE CARLO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2626",
    DESCRICAO: "MONTE CASTELO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2627",
    DESCRICAO: "MORRO DA FUMACA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2628",
    DESCRICAO: "MORRO GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2629",
    DESCRICAO: "NAVEGANTES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2630",
    DESCRICAO: "NOVA ERECHIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2631",
    DESCRICAO: "NOVA ITABERABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2632",
    DESCRICAO: "NOVA TRENTO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2633",
    DESCRICAO: "NOVA VENEZA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2634",
    DESCRICAO: "NOVO HORIZONTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2635",
    DESCRICAO: "ORLEANS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2636",
    DESCRICAO: "OTACILIO COSTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2637",
    DESCRICAO: "OURO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2638",
    DESCRICAO: "OURO VERDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2639",
    DESCRICAO: "PAIAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2640",
    DESCRICAO: "PAINEL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2641",
    DESCRICAO: "PALHOCA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2642",
    DESCRICAO: "PALMA SOLA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2643",
    DESCRICAO: "PALMEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2644",
    DESCRICAO: "PALMITOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2645",
    DESCRICAO: "PAPANDUVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2646",
    DESCRICAO: "PARAISO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2647",
    DESCRICAO: "PASSO DE TORRES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2648",
    DESCRICAO: "PASSOS MAIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2649",
    DESCRICAO: "PAULO LOPES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2650",
    DESCRICAO: "PEDRAS GRANDES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2651",
    DESCRICAO: "PENHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2652",
    DESCRICAO: "PERITIBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2653",
    DESCRICAO: "PETROLANDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2654",
    DESCRICAO: "BALNEARIO PICARRAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2655",
    DESCRICAO: "PINHALZINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2656",
    DESCRICAO: "PINHEIRO PRETO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2657",
    DESCRICAO: "PIRATUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2658",
    DESCRICAO: "PLANALTO ALEGRE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2659",
    DESCRICAO: "POMERODE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2660",
    DESCRICAO: "PONTE ALTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2661",
    DESCRICAO: "PONTE ALTA DO NORTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2662",
    DESCRICAO: "PONTE SERRADA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2663",
    DESCRICAO: "PORTO BELO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2664",
    DESCRICAO: "PORTO UNIAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2665",
    DESCRICAO: "POUSO REDONDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2666",
    DESCRICAO: "PRAIA GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2667",
    DESCRICAO: "PRESIDENTE CASTELLO BRANCO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2668",
    DESCRICAO: "PRESIDENTE GETULIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2669",
    DESCRICAO: "PRESIDENTE NEREU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2670",
    DESCRICAO: "PRINCESA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2671",
    DESCRICAO: "QUILOMBO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2672",
    DESCRICAO: "RANCHO QUEIMADO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2673",
    DESCRICAO: "RIO DAS ANTAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2674",
    DESCRICAO: "RIO DO CAMPO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2675",
    DESCRICAO: "RIO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2676",
    DESCRICAO: "RIO DOS CEDROS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2677",
    DESCRICAO: "RIO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2678",
    DESCRICAO: "RIO FORTUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2679",
    DESCRICAO: "RIO NEGRINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2680",
    DESCRICAO: "RIO RUFINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2681",
    DESCRICAO: "RIQUEZA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2682",
    DESCRICAO: "RODEIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2683",
    DESCRICAO: "ROMELANDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2684",
    DESCRICAO: "SALETE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2685",
    DESCRICAO: "SALTINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2686",
    DESCRICAO: "SALTO VELOSO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2687",
    DESCRICAO: "SANGAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2688",
    DESCRICAO: "SANTA CECILIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2689",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2690",
    DESCRICAO: "SANTA ROSA DE LIMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2691",
    DESCRICAO: "SANTA ROSA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2692",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2693",
    DESCRICAO: "SANTA TEREZINHA DO PROGRESSO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2694",
    DESCRICAO: "SANTIAGO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2695",
    DESCRICAO: "SANTO AMARO DA IMPERATRIZ",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2696",
    DESCRICAO: "SAO BERNARDINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2697",
    DESCRICAO: "SAO BENTO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2698",
    DESCRICAO: "SAO BONIFACIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2699",
    DESCRICAO: "SAO CARLOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2700",
    DESCRICAO: "SAO CRISTOVAO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2701",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2702",
    DESCRICAO: "SAO FRANCISCO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2703",
    DESCRICAO: "SAO JOAO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2704",
    DESCRICAO: "SAO JOAO BATISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2705",
    DESCRICAO: "SAO JOAO DO ITAPERIU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2706",
    DESCRICAO: "SAO JOAO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2707",
    DESCRICAO: "SAO JOAQUIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2708",
    DESCRICAO: "SAO JOSE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2709",
    DESCRICAO: "SAO JOSE DO CEDRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2710",
    DESCRICAO: "SAO JOSE DO CERRITO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2711",
    DESCRICAO: "SAO LOURENCO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2712",
    DESCRICAO: "SAO LUDGERO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2713",
    DESCRICAO: "SAO MARTINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2714",
    DESCRICAO: "SAO MIGUEL DA BOA VISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2715",
    DESCRICAO: "SAO MIGUEL DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2716",
    DESCRICAO: "SAO PEDRO DE ALCANTARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2717",
    DESCRICAO: "SAUDADES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2718",
    DESCRICAO: "SCHROEDER",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2719",
    DESCRICAO: "SEARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2720",
    DESCRICAO: "SERRA ALTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2721",
    DESCRICAO: "SIDEROPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2722",
    DESCRICAO: "SOMBRIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2723",
    DESCRICAO: "SUL BRASIL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2724",
    DESCRICAO: "TAIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2725",
    DESCRICAO: "TANGARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2726",
    DESCRICAO: "TIGRINHOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2727",
    DESCRICAO: "TIJUCAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2728",
    DESCRICAO: "TIMBE DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2729",
    DESCRICAO: "TIMBO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2730",
    DESCRICAO: "TIMBO GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2731",
    DESCRICAO: "TRES BARRAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2732",
    DESCRICAO: "TREVISO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2733",
    DESCRICAO: "TREZE DE MAIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2734",
    DESCRICAO: "TREZE TILIAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2735",
    DESCRICAO: "TROMBUDO CENTRAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2736",
    DESCRICAO: "TUBARAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2737",
    DESCRICAO: "TUNAPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2738",
    DESCRICAO: "TURVO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2739",
    DESCRICAO: "UNIAO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2740",
    DESCRICAO: "URUBICI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2741",
    DESCRICAO: "URUPEMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2742",
    DESCRICAO: "URUSSANGA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2743",
    DESCRICAO: "VARGEAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2744",
    DESCRICAO: "VARGEM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2745",
    DESCRICAO: "VARGEM BONITA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2746",
    DESCRICAO: "VIDAL RAMOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2747",
    DESCRICAO: "VIDEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2748",
    DESCRICAO: "VITOR MEIRELES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2749",
    DESCRICAO: "WITMARSUM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2750",
    DESCRICAO: "XANXERE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2751",
    DESCRICAO: "XAVANTINA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2752",
    DESCRICAO: "XAXIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2753",
    DESCRICAO: "ZORTEA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2754",
    DESCRICAO: "ANGRA DOS REIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2755",
    DESCRICAO: "APERIBE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2756",
    DESCRICAO: "ARARUAMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2757",
    DESCRICAO: "AREAL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2758",
    DESCRICAO: "ARMACAO DOS BUZIOS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2759",
    DESCRICAO: "ARRAIAL DO CABO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2760",
    DESCRICAO: "BARRA DO PIRAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2761",
    DESCRICAO: "BARRA MANSA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2762",
    DESCRICAO: "BELFORD ROXO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2763",
    DESCRICAO: "BOM JARDIM",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2764",
    DESCRICAO: "BOM JESUS DO ITABAPOANA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2765",
    DESCRICAO: "CABO FRIO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2766",
    DESCRICAO: "CACHOEIRAS DE MACACU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2767",
    DESCRICAO: "CAMBUCI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2768",
    DESCRICAO: "CARAPEBUS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2769",
    DESCRICAO: "COMENDADOR LEVY GASPARIAN",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2770",
    DESCRICAO: "CAMPOS DOS GOYTACAZES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2771",
    DESCRICAO: "CANTAGALO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2772",
    DESCRICAO: "CARDOSO MOREIRA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2773",
    DESCRICAO: "CARMO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2774",
    DESCRICAO: "CASIMIRO DE ABREU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2775",
    DESCRICAO: "CONCEICAO DE MACABU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2776",
    DESCRICAO: "CORDEIRO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2777",
    DESCRICAO: "DUAS BARRAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2778",
    DESCRICAO: "DUQUE DE CAXIAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2779",
    DESCRICAO: "ENGENHEIRO PAULO DE FRONTIN",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2780",
    DESCRICAO: "GUAPIMIRIM",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2781",
    DESCRICAO: "IGUABA GRANDE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2782",
    DESCRICAO: "ITABORAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2783",
    DESCRICAO: "ITAGUAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2784",
    DESCRICAO: "ITALVA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2785",
    DESCRICAO: "ITAOCARA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2786",
    DESCRICAO: "ITAPERUNA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2787",
    DESCRICAO: "ITATIAIA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2788",
    DESCRICAO: "JAPERI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2789",
    DESCRICAO: "LAJE DO MURIAE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2790",
    DESCRICAO: "MACAE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2791",
    DESCRICAO: "MACUCO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2792",
    DESCRICAO: "MAGE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2793",
    DESCRICAO: "MANGARATIBA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2794",
    DESCRICAO: "MARICA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2795",
    DESCRICAO: "MENDES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2796",
    DESCRICAO: "MESQUITA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2797",
    DESCRICAO: "MIGUEL PEREIRA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2798",
    DESCRICAO: "MIRACEMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2799",
    DESCRICAO: "NATIVIDADE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2800",
    DESCRICAO: "NILOPOLIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2801",
    DESCRICAO: "NITEROI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2802",
    DESCRICAO: "NOVA FRIBURGO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2803",
    DESCRICAO: "NOVA IGUACU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2804",
    DESCRICAO: "PARACAMBI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2805",
    DESCRICAO: "PARAIBA DO SUL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2806",
    DESCRICAO: "PARATI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2807",
    DESCRICAO: "PATY DO ALFERES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2808",
    DESCRICAO: "PETROPOLIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2809",
    DESCRICAO: "PINHEIRAL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2810",
    DESCRICAO: "PIRAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2811",
    DESCRICAO: "PORCIUNCULA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2812",
    DESCRICAO: "PORTO REAL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2813",
    DESCRICAO: "QUATIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2814",
    DESCRICAO: "QUEIMADOS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2815",
    DESCRICAO: "QUISSAMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2816",
    DESCRICAO: "RESENDE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2817",
    DESCRICAO: "RIO BONITO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2818",
    DESCRICAO: "RIO CLARO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2819",
    DESCRICAO: "RIO DAS FLORES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2820",
    DESCRICAO: "RIO DAS OSTRAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2821",
    DESCRICAO: "RIO DE JANEIRO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2822",
    DESCRICAO: "SANTA MARIA MADALENA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2823",
    DESCRICAO: "SANTO ANTONIO DE PADUA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2824",
    DESCRICAO: "SAO FRANCISCO DE ITABAPOANA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2825",
    DESCRICAO: "SAO FIDELIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2826",
    DESCRICAO: "SAO GONCALO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2827",
    DESCRICAO: "SAO JOAO DA BARRA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2828",
    DESCRICAO: "SAO JOAO DE MERITI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2829",
    DESCRICAO: "SAO JOSE DE UBA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2830",
    DESCRICAO: "SAO JOSE DO VALE DO RIO PRETO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2831",
    DESCRICAO: "SAO PEDRO DA ALDEIA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2832",
    DESCRICAO: "SAO SEBASTIAO DO ALTO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2833",
    DESCRICAO: "SAPUCAIA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2834",
    DESCRICAO: "SAQUAREMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2835",
    DESCRICAO: "SEROPEDICA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2836",
    DESCRICAO: "SILVA JARDIM",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2837",
    DESCRICAO: "SUMIDOURO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2838",
    DESCRICAO: "TANGUA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2839",
    DESCRICAO: "TERESOPOLIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2840",
    DESCRICAO: "TRAJANO DE MORAIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2841",
    DESCRICAO: "TRES RIOS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2842",
    DESCRICAO: "VALENCA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2843",
    DESCRICAO: "VARRE-SAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2844",
    DESCRICAO: "VASSOURAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2845",
    DESCRICAO: "VOLTA REDONDA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2846",
    DESCRICAO: "AGUA BRANCA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2847",
    DESCRICAO: "ANADIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2848",
    DESCRICAO: "ARAPIRACA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2849",
    DESCRICAO: "ATALAIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2850",
    DESCRICAO: "BARRA DE SANTO ANTONIO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2851",
    DESCRICAO: "BARRA DE SAO MIGUEL",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2852",
    DESCRICAO: "BATALHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2853",
    DESCRICAO: "BELEM",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2854",
    DESCRICAO: "BELO MONTE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2855",
    DESCRICAO: "BOCA DA MATA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2856",
    DESCRICAO: "BRANQUINHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2857",
    DESCRICAO: "CACIMBINHAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2858",
    DESCRICAO: "CAJUEIRO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2859",
    DESCRICAO: "CAMPESTRE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2860",
    DESCRICAO: "CAMPO ALEGRE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2861",
    DESCRICAO: "CAMPO GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2862",
    DESCRICAO: "CANAPI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2863",
    DESCRICAO: "CAPELA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2864",
    DESCRICAO: "CARNEIROS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2865",
    DESCRICAO: "CHA PRETA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2866",
    DESCRICAO: "COITE DO NOIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2867",
    DESCRICAO: "COLONIA LEOPOLDINA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2868",
    DESCRICAO: "COQUEIRO SECO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2869",
    DESCRICAO: "CORURIPE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2870",
    DESCRICAO: "CRAIBAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2871",
    DESCRICAO: "DELMIRO GOUVEIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2872",
    DESCRICAO: "DOIS RIACHOS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2873",
    DESCRICAO: "ESTRELA DE ALAGOAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2874",
    DESCRICAO: "FEIRA GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2875",
    DESCRICAO: "FELIZ DESERTO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2876",
    DESCRICAO: "FLEXEIRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2877",
    DESCRICAO: "GIRAU DO PONCIANO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2878",
    DESCRICAO: "IBATEGUARA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2879",
    DESCRICAO: "IGACI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2880",
    DESCRICAO: "IGREJA NOVA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2881",
    DESCRICAO: "INHAPI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2882",
    DESCRICAO: "JACARE DOS HOMENS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2883",
    DESCRICAO: "JACUIPE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2884",
    DESCRICAO: "JAPARATINGA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2885",
    DESCRICAO: "JARAMATAIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2886",
    DESCRICAO: "JEQUIA DA PRAIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2887",
    DESCRICAO: "JOAQUIM GOMES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2888",
    DESCRICAO: "JUNDIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2889",
    DESCRICAO: "JUNQUEIRO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2890",
    DESCRICAO: "LAGOA DA CANOA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2891",
    DESCRICAO: "LIMOEIRO DE ANADIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2892",
    DESCRICAO: "MACEIO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2893",
    DESCRICAO: "MAJOR ISIDORO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2894",
    DESCRICAO: "MARAGOGI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2895",
    DESCRICAO: "MARAVILHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2896",
    DESCRICAO: "MARECHAL DEODORO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2897",
    DESCRICAO: "MARIBONDO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2898",
    DESCRICAO: "MAR VERMELHO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2899",
    DESCRICAO: "MATA GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2900",
    DESCRICAO: "MATRIZ DE CAMARAGIBE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2901",
    DESCRICAO: "MESSIAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2902",
    DESCRICAO: "MINADOR DO NEGRAO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2903",
    DESCRICAO: "MONTEIROPOLIS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2904",
    DESCRICAO: "MURICI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2905",
    DESCRICAO: "NOVO LINO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2906",
    DESCRICAO: "OLHO DAGUA DAS FLORES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2907",
    DESCRICAO: "OLHO DAGUA DO CASADO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2908",
    DESCRICAO: "OLHO DAGUA GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2909",
    DESCRICAO: "OLIVENCA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2910",
    DESCRICAO: "OURO BRANCO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2911",
    DESCRICAO: "PALESTINA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2912",
    DESCRICAO: "PALMEIRA DOS INDIOS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2913",
    DESCRICAO: "PAO DE ACUCAR",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2914",
    DESCRICAO: "PARICONHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2915",
    DESCRICAO: "PARIPUEIRA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2916",
    DESCRICAO: "PASSO DE CAMARAGIBE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2917",
    DESCRICAO: "PAULO JACINTO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2918",
    DESCRICAO: "PENEDO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2919",
    DESCRICAO: "PIACABUCU",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2920",
    DESCRICAO: "PILAR",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2921",
    DESCRICAO: "PINDOBA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2922",
    DESCRICAO: "PIRANHAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2923",
    DESCRICAO: "POCO DAS TRINCHEIRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2924",
    DESCRICAO: "PORTO CALVO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2925",
    DESCRICAO: "PORTO DE PEDRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2926",
    DESCRICAO: "PORTO REAL DO COLEGIO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2927",
    DESCRICAO: "QUEBRANGULO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2928",
    DESCRICAO: "RIO LARGO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2929",
    DESCRICAO: "ROTEIRO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2930",
    DESCRICAO: "SANTA LUZIA DO NORTE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2931",
    DESCRICAO: "SANTANA DO IPANEMA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2932",
    DESCRICAO: "SANTANA DO MUNDAU",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2933",
    DESCRICAO: "SAO BRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2934",
    DESCRICAO: "SAO JOSE DA LAJE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2935",
    DESCRICAO: "SAO JOSE DA TAPERA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2936",
    DESCRICAO: "SAO LUIS DO QUITUNDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2937",
    DESCRICAO: "SAO MIGUEL DOS CAMPOS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2938",
    DESCRICAO: "SAO MIGUEL DOS MILAGRES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2939",
    DESCRICAO: "SAO SEBASTIAO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2940",
    DESCRICAO: "SATUBA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2941",
    DESCRICAO: "SENADOR RUI PALMEIRA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2942",
    DESCRICAO: "TANQUE DARCA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2943",
    DESCRICAO: "TAQUARANA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2944",
    DESCRICAO: "TEOTONIO VILELA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2945",
    DESCRICAO: "TRAIPU",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2946",
    DESCRICAO: "UNIAO DOS PALMARES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2947",
    DESCRICAO: "VICOSA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2948",
    DESCRICAO: "ABAIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2949",
    DESCRICAO: "ABARE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2950",
    DESCRICAO: "ACAJUTIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2951",
    DESCRICAO: "ADUSTINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2952",
    DESCRICAO: "AGUA FRIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2953",
    DESCRICAO: "ERICO CARDOSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2954",
    DESCRICAO: "AIQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2955",
    DESCRICAO: "ALAGOINHAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2956",
    DESCRICAO: "ALCOBACA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2957",
    DESCRICAO: "ALMADINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2958",
    DESCRICAO: "AMARGOSA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2959",
    DESCRICAO: "AMELIA RODRIGUES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2960",
    DESCRICAO: "AMERICA DOURADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2961",
    DESCRICAO: "ANAGE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2962",
    DESCRICAO: "ANDARAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2963",
    DESCRICAO: "ANDORINHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2964",
    DESCRICAO: "ANGICAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2965",
    DESCRICAO: "ANGUERA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2966",
    DESCRICAO: "ANTAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2967",
    DESCRICAO: "ANTONIO CARDOSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2968",
    DESCRICAO: "ANTONIO GONCALVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2969",
    DESCRICAO: "APORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2970",
    DESCRICAO: "APUAREMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2971",
    DESCRICAO: "ARACATU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2972",
    DESCRICAO: "ARACAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2973",
    DESCRICAO: "ARACI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2974",
    DESCRICAO: "ARAMARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2975",
    DESCRICAO: "ARATACA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2976",
    DESCRICAO: "ARATUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2977",
    DESCRICAO: "AURELINO LEAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2978",
    DESCRICAO: "BAIANOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2979",
    DESCRICAO: "BAIXA GRANDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2980",
    DESCRICAO: "BANZAE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2981",
    DESCRICAO: "BARRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2982",
    DESCRICAO: "BARRA DA ESTIVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2983",
    DESCRICAO: "BARRA DO CHOCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2984",
    DESCRICAO: "BARRA DO MENDES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2985",
    DESCRICAO: "BARRA DO ROCHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2986",
    DESCRICAO: "BARREIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2987",
    DESCRICAO: "BARRO ALTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2988",
    DESCRICAO: "BARROCAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2989",
    DESCRICAO: "BARRO PRETO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2990",
    DESCRICAO: "BELMONTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2991",
    DESCRICAO: "BELO CAMPO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2992",
    DESCRICAO: "BIRITINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2993",
    DESCRICAO: "BOA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2994",
    DESCRICAO: "BOA VISTA DO TUPIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2995",
    DESCRICAO: "BOM JESUS DA LAPA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2996",
    DESCRICAO: "BOM JESUS DA SERRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2997",
    DESCRICAO: "BONINAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2998",
    DESCRICAO: "BONITO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2999",
    DESCRICAO: "BOQUIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3000",
    DESCRICAO: "BOTUPORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3001",
    DESCRICAO: "BREJOES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3002",
    DESCRICAO: "BREJOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3003",
    DESCRICAO: "BROTAS DE MACAUBAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3004",
    DESCRICAO: "BRUMADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3005",
    DESCRICAO: "BUERAREMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3006",
    DESCRICAO: "BURITIRAMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3007",
    DESCRICAO: "CAATIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3008",
    DESCRICAO: "CABACEIRAS DO PARAGUACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3009",
    DESCRICAO: "CACHOEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3010",
    DESCRICAO: "CACULE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3011",
    DESCRICAO: "CAEM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3012",
    DESCRICAO: "CAETANOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3013",
    DESCRICAO: "CAETITE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3014",
    DESCRICAO: "CAFARNAUM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3015",
    DESCRICAO: "CAIRU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3016",
    DESCRICAO: "CALDEIRAO GRANDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3017",
    DESCRICAO: "CAMACAN",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3018",
    DESCRICAO: "CAMACARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3019",
    DESCRICAO: "CAMAMU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3020",
    DESCRICAO: "CAMPO ALEGRE DE LOURDES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3021",
    DESCRICAO: "CAMPO FORMOSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3022",
    DESCRICAO: "CANAPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3023",
    DESCRICAO: "CANARANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3024",
    DESCRICAO: "CANAVIEIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3025",
    DESCRICAO: "CANDEAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3026",
    DESCRICAO: "CANDEIAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3027",
    DESCRICAO: "CANDIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3028",
    DESCRICAO: "CANDIDO SALES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3029",
    DESCRICAO: "CANSANCAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3030",
    DESCRICAO: "CANUDOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3031",
    DESCRICAO: "CAPELA DO ALTO ALEGRE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3032",
    DESCRICAO: "CAPIM GROSSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3033",
    DESCRICAO: "CARAIBAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3034",
    DESCRICAO: "CARAVELAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3035",
    DESCRICAO: "CARDEAL DA SILVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3036",
    DESCRICAO: "CARINHANHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3037",
    DESCRICAO: "CASA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3038",
    DESCRICAO: "CASTRO ALVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3039",
    DESCRICAO: "CATOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3040",
    DESCRICAO: "CATU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3041",
    DESCRICAO: "CATURAMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3042",
    DESCRICAO: "CENTRAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3043",
    DESCRICAO: "CHORROCHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3044",
    DESCRICAO: "CICERO DANTAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3045",
    DESCRICAO: "CIPO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3046",
    DESCRICAO: "COARACI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3047",
    DESCRICAO: "COCOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3048",
    DESCRICAO: "CONCEICAO DA FEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3049",
    DESCRICAO: "CONCEICAO DO ALMEIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3050",
    DESCRICAO: "CONCEICAO DO COITE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3051",
    DESCRICAO: "CONCEICAO DO JACUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3052",
    DESCRICAO: "CONDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3053",
    DESCRICAO: "CONDEUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3054",
    DESCRICAO: "CONTENDAS DO SINCORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3055",
    DESCRICAO: "CORACAO DE MARIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3056",
    DESCRICAO: "CORDEIROS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3057",
    DESCRICAO: "CORIBE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3058",
    DESCRICAO: "CORONEL JOAO SA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3059",
    DESCRICAO: "CORRENTINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3060",
    DESCRICAO: "COTEGIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3061",
    DESCRICAO: "CRAVOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3062",
    DESCRICAO: "CRISOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3063",
    DESCRICAO: "CRISTOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3064",
    DESCRICAO: "CRUZ DAS ALMAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3065",
    DESCRICAO: "CURACA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3066",
    DESCRICAO: "DARIO MEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3067",
    DESCRICAO: "DIAS DAVILA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3068",
    DESCRICAO: "DOM BASILIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3069",
    DESCRICAO: "DOM MACEDO COSTA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3070",
    DESCRICAO: "ELISIO MEDRADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3071",
    DESCRICAO: "ENCRUZILHADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3072",
    DESCRICAO: "ENTRE RIOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3073",
    DESCRICAO: "ESPLANADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3074",
    DESCRICAO: "EUCLIDES DA CUNHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3075",
    DESCRICAO: "EUNAPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3076",
    DESCRICAO: "FATIMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3077",
    DESCRICAO: "FEIRA DA MATA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3078",
    DESCRICAO: "FEIRA DE SANTANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3079",
    DESCRICAO: "FILADELFIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3080",
    DESCRICAO: "FIRMINO ALVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3081",
    DESCRICAO: "FLORESTA AZUL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3082",
    DESCRICAO: "FORMOSA DO RIO PRETO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3083",
    DESCRICAO: "GANDU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3084",
    DESCRICAO: "GAVIAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3085",
    DESCRICAO: "GENTIO DO OURO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3086",
    DESCRICAO: "GLORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3087",
    DESCRICAO: "GONGOGI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3088",
    DESCRICAO: "GOVERNADOR MANGABEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3089",
    DESCRICAO: "GUAJERU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3090",
    DESCRICAO: "GUANAMBI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3091",
    DESCRICAO: "GUARATINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3092",
    DESCRICAO: "HELIOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3093",
    DESCRICAO: "IACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3094",
    DESCRICAO: "IBIASSUCE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3095",
    DESCRICAO: "IBICARAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3096",
    DESCRICAO: "IBICOARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3097",
    DESCRICAO: "IBICUI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3098",
    DESCRICAO: "IBIPEBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3099",
    DESCRICAO: "IBIPITANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3100",
    DESCRICAO: "IBIQUERA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3101",
    DESCRICAO: "IBIRAPITANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3102",
    DESCRICAO: "IBIRAPUA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3103",
    DESCRICAO: "IBIRATAIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3104",
    DESCRICAO: "IBITIARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3105",
    DESCRICAO: "IBITITA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3106",
    DESCRICAO: "IBOTIRAMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3107",
    DESCRICAO: "ICHU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3108",
    DESCRICAO: "IGAPORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3109",
    DESCRICAO: "IGRAPIUNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3110",
    DESCRICAO: "IGUAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3111",
    DESCRICAO: "ILHEUS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3112",
    DESCRICAO: "INHAMBUPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3113",
    DESCRICAO: "IPECAETA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3114",
    DESCRICAO: "IPIAU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3115",
    DESCRICAO: "IPIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3116",
    DESCRICAO: "IPUPIARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3117",
    DESCRICAO: "IRAJUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3118",
    DESCRICAO: "IRAMAIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3119",
    DESCRICAO: "IRAQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3120",
    DESCRICAO: "IRARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3121",
    DESCRICAO: "IRECE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3122",
    DESCRICAO: "ITABELA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3123",
    DESCRICAO: "ITABERABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3124",
    DESCRICAO: "ITABUNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3125",
    DESCRICAO: "ITACARE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3126",
    DESCRICAO: "ITAETE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3127",
    DESCRICAO: "ITAGI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3128",
    DESCRICAO: "ITAGIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3129",
    DESCRICAO: "ITAGIMIRIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3130",
    DESCRICAO: "ITAGUACU DA BAHIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3131",
    DESCRICAO: "ITAJU DO COLONIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3132",
    DESCRICAO: "ITAJUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3133",
    DESCRICAO: "ITAMARAJU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3134",
    DESCRICAO: "ITAMARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3135",
    DESCRICAO: "ITAMBE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3136",
    DESCRICAO: "ITANAGRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3137",
    DESCRICAO: "ITANHEM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3138",
    DESCRICAO: "ITAPARICA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3139",
    DESCRICAO: "ITAPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3140",
    DESCRICAO: "ITAPEBI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3141",
    DESCRICAO: "ITAPETINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3142",
    DESCRICAO: "ITAPICURU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3143",
    DESCRICAO: "ITAPITANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3144",
    DESCRICAO: "ITAQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3145",
    DESCRICAO: "ITARANTIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3146",
    DESCRICAO: "ITATIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3147",
    DESCRICAO: "ITIRUCU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3148",
    DESCRICAO: "ITIUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3149",
    DESCRICAO: "ITORORO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3150",
    DESCRICAO: "ITUACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3151",
    DESCRICAO: "ITUBERA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3152",
    DESCRICAO: "IUIU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3153",
    DESCRICAO: "JABORANDI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3154",
    DESCRICAO: "JACARACI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3155",
    DESCRICAO: "JACOBINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3156",
    DESCRICAO: "JAGUAQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3157",
    DESCRICAO: "JAGUARARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3158",
    DESCRICAO: "JAGUARIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3159",
    DESCRICAO: "JANDAIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3160",
    DESCRICAO: "JEQUIE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3161",
    DESCRICAO: "JEREMOABO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3162",
    DESCRICAO: "JIQUIRICA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3163",
    DESCRICAO: "JITAUNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3164",
    DESCRICAO: "JOAO DOURADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3165",
    DESCRICAO: "JUAZEIRO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3166",
    DESCRICAO: "JUCURUCU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3167",
    DESCRICAO: "JUSSARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3168",
    DESCRICAO: "JUSSARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3169",
    DESCRICAO: "JUSSIAPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3170",
    DESCRICAO: "LAFAIETE COUTINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3171",
    DESCRICAO: "LAGOA REAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3172",
    DESCRICAO: "LAJE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3173",
    DESCRICAO: "LAJEDAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3174",
    DESCRICAO: "LAJEDINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3175",
    DESCRICAO: "LAJEDO DO TABOCAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3176",
    DESCRICAO: "LAMARAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3177",
    DESCRICAO: "LAPAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3178",
    DESCRICAO: "LAURO DE FREITAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3179",
    DESCRICAO: "LENCOIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3180",
    DESCRICAO: "LICINIO DE ALMEIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3181",
    DESCRICAO: "LIVRAMENTO DE NOSSA SENHORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3182",
    DESCRICAO: "LUIS EDUARDO MAGALHAES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3183",
    DESCRICAO: "MACAJUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3184",
    DESCRICAO: "MACARANI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3185",
    DESCRICAO: "MACAUBAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3186",
    DESCRICAO: "MACURURE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3187",
    DESCRICAO: "MADRE DE DEUS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3188",
    DESCRICAO: "MAETINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3189",
    DESCRICAO: "MAIQUINIQUE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3190",
    DESCRICAO: "MAIRI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3191",
    DESCRICAO: "MALHADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3192",
    DESCRICAO: "MALHADA DE PEDRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3193",
    DESCRICAO: "MANOEL VITORINO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3194",
    DESCRICAO: "MANSIDAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3195",
    DESCRICAO: "MARACAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3196",
    DESCRICAO: "MARAGOGIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3197",
    DESCRICAO: "MARAU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3198",
    DESCRICAO: "MARCIONILIO SOUZA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3199",
    DESCRICAO: "MASCOTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3200",
    DESCRICAO: "MATA DE SAO JOAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3201",
    DESCRICAO: "MATINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3202",
    DESCRICAO: "MEDEIROS NETO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3203",
    DESCRICAO: "MIGUEL CALMON",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3204",
    DESCRICAO: "MILAGRES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3205",
    DESCRICAO: "MIRANGABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3206",
    DESCRICAO: "MIRANTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3207",
    DESCRICAO: "MONTE SANTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3208",
    DESCRICAO: "MORPARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3209",
    DESCRICAO: "MORRO DO CHAPEU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3210",
    DESCRICAO: "MORTUGABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3211",
    DESCRICAO: "MUCUGE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3212",
    DESCRICAO: "MUCURI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3213",
    DESCRICAO: "MULUNGU DO MORRO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3214",
    DESCRICAO: "MUNDO NOVO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3215",
    DESCRICAO: "MUNIZ FERREIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3216",
    DESCRICAO: "MUQUEM DE SAO FRANCISCO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3217",
    DESCRICAO: "MURITIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3218",
    DESCRICAO: "MUTUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3219",
    DESCRICAO: "NAZARE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3220",
    DESCRICAO: "NILO PECANHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3221",
    DESCRICAO: "NORDESTINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3222",
    DESCRICAO: "NOVA CANAA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3223",
    DESCRICAO: "NOVA FATIMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3224",
    DESCRICAO: "NOVA IBIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3225",
    DESCRICAO: "NOVA ITARANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3226",
    DESCRICAO: "NOVA REDENCAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3227",
    DESCRICAO: "NOVA SOURE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3228",
    DESCRICAO: "NOVA VICOSA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3229",
    DESCRICAO: "NOVO HORIZONTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3230",
    DESCRICAO: "NOVO TRIUNFO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3231",
    DESCRICAO: "OLINDINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3232",
    DESCRICAO: "OLIVEIRA DOS BREJINHOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3233",
    DESCRICAO: "OURICANGAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3234",
    DESCRICAO: "OUROLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3235",
    DESCRICAO: "PALMAS DE MONTE ALTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3236",
    DESCRICAO: "PALMEIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3237",
    DESCRICAO: "PARAMIRIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3238",
    DESCRICAO: "PARATINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3239",
    DESCRICAO: "PARIPIRANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3240",
    DESCRICAO: "PAU BRASIL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3241",
    DESCRICAO: "PAULO AFONSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3242",
    DESCRICAO: "PE DE SERRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3243",
    DESCRICAO: "PEDRAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3244",
    DESCRICAO: "PEDRO ALEXANDRE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3245",
    DESCRICAO: "PIATA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3246",
    DESCRICAO: "PILAO ARCADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3247",
    DESCRICAO: "PINDAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3248",
    DESCRICAO: "PINDOBACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3249",
    DESCRICAO: "PINTADAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3250",
    DESCRICAO: "PIRAI DO NORTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3251",
    DESCRICAO: "PIRIPA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3252",
    DESCRICAO: "PIRITIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3253",
    DESCRICAO: "PLANALTINO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3254",
    DESCRICAO: "PLANALTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3255",
    DESCRICAO: "POCOES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3256",
    DESCRICAO: "POJUCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3257",
    DESCRICAO: "PONTO NOVO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3258",
    DESCRICAO: "PORTO SEGURO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3259",
    DESCRICAO: "POTIRAGUA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3260",
    DESCRICAO: "PRADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3261",
    DESCRICAO: "PRESIDENTE DUTRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3262",
    DESCRICAO: "PRESIDENTE JANIO QUADROS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3263",
    DESCRICAO: "PRESIDENTE TANCREDO NEVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3264",
    DESCRICAO: "QUEIMADAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3265",
    DESCRICAO: "QUIJINGUE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3266",
    DESCRICAO: "QUIXABEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3267",
    DESCRICAO: "RAFAEL JAMBEIRO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3268",
    DESCRICAO: "REMANSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3269",
    DESCRICAO: "RETIROLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3270",
    DESCRICAO: "RIACHAO DAS NEVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3271",
    DESCRICAO: "RIACHAO DO JACUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3272",
    DESCRICAO: "RIACHO DE SANTANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3273",
    DESCRICAO: "RIBEIRA DO AMPARO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3274",
    DESCRICAO: "RIBEIRA DO POMBAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3275",
    DESCRICAO: "RIBEIRAO DO LARGO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3276",
    DESCRICAO: "RIO DE CONTAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3277",
    DESCRICAO: "RIO DO ANTONIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3278",
    DESCRICAO: "RIO DO PIRES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3279",
    DESCRICAO: "RIO REAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3280",
    DESCRICAO: "RODELAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3281",
    DESCRICAO: "RUY BARBOSA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3282",
    DESCRICAO: "SALINAS DA MARGARIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3283",
    DESCRICAO: "SALVADOR",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3284",
    DESCRICAO: "SANTA BARBARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3285",
    DESCRICAO: "SANTA BRIGIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3286",
    DESCRICAO: "SANTA CRUZ CABRALIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3287",
    DESCRICAO: "SANTA CRUZ DA VITORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3288",
    DESCRICAO: "SANTA INES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3289",
    DESCRICAO: "SANTALUZ",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3290",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3291",
    DESCRICAO: "SANTA MARIA DA VITORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3292",
    DESCRICAO: "SANTANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3293",
    DESCRICAO: "SANTANOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3294",
    DESCRICAO: "SANTA RITA DE CASSIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3295",
    DESCRICAO: "SANTA TERESINHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3296",
    DESCRICAO: "SANTO AMARO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3297",
    DESCRICAO: "SANTO ANTONIO DE JESUS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3298",
    DESCRICAO: "SANTO ESTEVAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3299",
    DESCRICAO: "SAO DESIDERIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3300",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3301",
    DESCRICAO: "SAO FELIX",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3302",
    DESCRICAO: "SAO FELIX DO CORIBE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3303",
    DESCRICAO: "SAO FELIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3304",
    DESCRICAO: "SAO FRANCISCO DO CONDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3305",
    DESCRICAO: "SAO GABRIEL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3306",
    DESCRICAO: "SAO GONCALO DOS CAMPOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3307",
    DESCRICAO: "SAO JOSE DA VITORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3308",
    DESCRICAO: "SAO JOSE DO JACUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3309",
    DESCRICAO: "SAO MIGUEL DAS MATAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3310",
    DESCRICAO: "SAO SEBASTIAO DO PASSE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3311",
    DESCRICAO: "SAPEACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3312",
    DESCRICAO: "SATIRO DIAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3313",
    DESCRICAO: "SAUBARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3314",
    DESCRICAO: "SAUDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3315",
    DESCRICAO: "SEABRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3316",
    DESCRICAO: "SEBASTIAO LARANJEIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3317",
    DESCRICAO: "SENHOR DO BONFIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3318",
    DESCRICAO: "SERRA DO RAMALHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3319",
    DESCRICAO: "SENTO SE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3320",
    DESCRICAO: "SERRA DOURADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3321",
    DESCRICAO: "SERRA PRETA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3322",
    DESCRICAO: "SERRINHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3323",
    DESCRICAO: "SERROLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3324",
    DESCRICAO: "SIMOES FILHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3325",
    DESCRICAO: "SITIO DO MATO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3326",
    DESCRICAO: "SITIO DO QUINTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3327",
    DESCRICAO: "SOBRADINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3328",
    DESCRICAO: "SOUTO SOARES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3329",
    DESCRICAO: "TABOCAS DO BREJO VELHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3330",
    DESCRICAO: "TANHACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3331",
    DESCRICAO: "TANQUE NOVO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3332",
    DESCRICAO: "TANQUINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3333",
    DESCRICAO: "TAPEROA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3334",
    DESCRICAO: "TAPIRAMUTA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3335",
    DESCRICAO: "TEIXEIRA DE FREITAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3336",
    DESCRICAO: "TEODORO SAMPAIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3337",
    DESCRICAO: "TEOFILANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3338",
    DESCRICAO: "TEOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3339",
    DESCRICAO: "TERRA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3340",
    DESCRICAO: "TREMEDAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3341",
    DESCRICAO: "TUCANO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3342",
    DESCRICAO: "UAUA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3343",
    DESCRICAO: "UBAIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3344",
    DESCRICAO: "UBAITABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3345",
    DESCRICAO: "UBATA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3346",
    DESCRICAO: "UIBAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3347",
    DESCRICAO: "UMBURANAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3348",
    DESCRICAO: "UNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3349",
    DESCRICAO: "URANDI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3350",
    DESCRICAO: "URUCUCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3351",
    DESCRICAO: "UTINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3352",
    DESCRICAO: "VALENCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3353",
    DESCRICAO: "VALENTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3354",
    DESCRICAO: "VARZEA DA ROCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3355",
    DESCRICAO: "VARZEA DO POCO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3356",
    DESCRICAO: "VARZEA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3357",
    DESCRICAO: "VARZEDO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3358",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3359",
    DESCRICAO: "VEREDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3360",
    DESCRICAO: "VITORIA DA CONQUISTA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3361",
    DESCRICAO: "WAGNER",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3362",
    DESCRICAO: "WANDERLEY",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3363",
    DESCRICAO: "WENCESLAU GUIMARAES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3364",
    DESCRICAO: "XIQUE-XIQUE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3365",
    DESCRICAO: "AFONSO CLAUDIO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3366",
    DESCRICAO: "AGUIA BRANCA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3367",
    DESCRICAO: "AGUA DOCE DO NORTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3368",
    DESCRICAO: "ALEGRE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3369",
    DESCRICAO: "ALFREDO CHAVES",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3370",
    DESCRICAO: "ALTO RIO NOVO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3371",
    DESCRICAO: "ANCHIETA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3372",
    DESCRICAO: "APIACA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3373",
    DESCRICAO: "ARACRUZ",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3374",
    DESCRICAO: "ATILIO VIVACQUA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3375",
    DESCRICAO: "BAIXO GUANDU",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3376",
    DESCRICAO: "BARRA DE SAO FRANCISCO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3377",
    DESCRICAO: "BOA ESPERANCA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3378",
    DESCRICAO: "BOM JESUS DO NORTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3379",
    DESCRICAO: "BREJETUBA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3380",
    DESCRICAO: "CACHOEIRO DE ITAPEMIRIM",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3381",
    DESCRICAO: "CARIACICA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3382",
    DESCRICAO: "CASTELO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3383",
    DESCRICAO: "COLATINA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3384",
    DESCRICAO: "CONCEICAO DA BARRA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3385",
    DESCRICAO: "CONCEICAO DO CASTELO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3386",
    DESCRICAO: "DIVINO DE SAO LOURENCO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3387",
    DESCRICAO: "DOMINGOS MARTINS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3388",
    DESCRICAO: "DORES DO RIO PRETO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3389",
    DESCRICAO: "ECOPORANGA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3390",
    DESCRICAO: "FUNDAO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3391",
    DESCRICAO: "GOVERNADOR LINDENBERG",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3392",
    DESCRICAO: "GUACUI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3393",
    DESCRICAO: "GUARAPARI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3394",
    DESCRICAO: "IBATIBA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3395",
    DESCRICAO: "IBIRACU",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3396",
    DESCRICAO: "IBITIRAMA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3397",
    DESCRICAO: "ICONHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3398",
    DESCRICAO: "IRUPI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3399",
    DESCRICAO: "ITAGUACU",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3400",
    DESCRICAO: "ITAPEMIRIM",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3401",
    DESCRICAO: "ITARANA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3402",
    DESCRICAO: "IUNA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3403",
    DESCRICAO: "JAGUARE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3404",
    DESCRICAO: "JERONIMO MONTEIRO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3405",
    DESCRICAO: "JOAO NEIVA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3406",
    DESCRICAO: "LARANJA DA TERRA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3407",
    DESCRICAO: "LINHARES",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3408",
    DESCRICAO: "MANTENOPOLIS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3409",
    DESCRICAO: "MARATAIZES",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3410",
    DESCRICAO: "MARECHAL FLORIANO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3411",
    DESCRICAO: "MARILANDIA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3412",
    DESCRICAO: "MIMOSO DO SUL",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3413",
    DESCRICAO: "MONTANHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3414",
    DESCRICAO: "MUCURICI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3415",
    DESCRICAO: "MUNIZ FREIRE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3416",
    DESCRICAO: "MUQUI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3417",
    DESCRICAO: "NOVA VENECIA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3418",
    DESCRICAO: "PANCAS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3419",
    DESCRICAO: "PEDRO CANARIO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3420",
    DESCRICAO: "PINHEIROS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3421",
    DESCRICAO: "PIUMA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3422",
    DESCRICAO: "PONTO BELO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3423",
    DESCRICAO: "PRESIDENTE KENNEDY",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3424",
    DESCRICAO: "RIO BANANAL",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3425",
    DESCRICAO: "RIO NOVO DO SUL",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3426",
    DESCRICAO: "SANTA LEOPOLDINA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3427",
    DESCRICAO: "SANTA MARIA DE JETIBA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3428",
    DESCRICAO: "SANTA TERESA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3429",
    DESCRICAO: "SAO DOMINGOS DO NORTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3430",
    DESCRICAO: "SAO GABRIEL DA PALHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3431",
    DESCRICAO: "SAO JOSE DO CALCADO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3432",
    DESCRICAO: "SAO MATEUS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3433",
    DESCRICAO: "SAO ROQUE DO CANAA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3434",
    DESCRICAO: "SERRA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3435",
    DESCRICAO: "SOORETAMA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3436",
    DESCRICAO: "VARGEM ALTA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3437",
    DESCRICAO: "VENDA NOVA DO IMIGRANTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3438",
    DESCRICAO: "VIANA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3439",
    DESCRICAO: "VILA PAVAO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3440",
    DESCRICAO: "VILA VALERIO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3441",
    DESCRICAO: "VILA VELHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3442",
    DESCRICAO: "VITORIA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3443",
    DESCRICAO: "AGUA BRANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3444",
    DESCRICAO: "AGUIAR",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3445",
    DESCRICAO: "ALAGOA GRANDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3446",
    DESCRICAO: "ALAGOA NOVA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3447",
    DESCRICAO: "ALAGOINHA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3448",
    DESCRICAO: "ALCANTIL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3449",
    DESCRICAO: "ALGODAO DE JANDAIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3450",
    DESCRICAO: "ALHANDRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3451",
    DESCRICAO: "SAO JOAO DO RIO DO PEIXE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3452",
    DESCRICAO: "AMPARO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3453",
    DESCRICAO: "APARECIDA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3454",
    DESCRICAO: "ARACAGI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3455",
    DESCRICAO: "ARARA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3456",
    DESCRICAO: "ARARUNA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3457",
    DESCRICAO: "AREIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3458",
    DESCRICAO: "AREIA DE BARAUNAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3459",
    DESCRICAO: "AREIAL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3460",
    DESCRICAO: "AROEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3461",
    DESCRICAO: "ASSUNCAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3462",
    DESCRICAO: "BAIA DA TRAICAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3463",
    DESCRICAO: "BANANEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3464",
    DESCRICAO: "BARAUNA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3465",
    DESCRICAO: "BARRA DE SANTANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3466",
    DESCRICAO: "BARRA DE SANTA ROSA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3467",
    DESCRICAO: "BARRA DE SAO MIGUEL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3468",
    DESCRICAO: "BAYEUX",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3469",
    DESCRICAO: "BELEM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3470",
    DESCRICAO: "BELEM DO BREJO DO CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3471",
    DESCRICAO: "BERNARDINO BATISTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3472",
    DESCRICAO: "BOA VENTURA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3473",
    DESCRICAO: "BOA VISTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3474",
    DESCRICAO: "BOM JESUS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3475",
    DESCRICAO: "BOM SUCESSO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3476",
    DESCRICAO: "BONITO DE SANTA FE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3477",
    DESCRICAO: "BOQUEIRAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3478",
    DESCRICAO: "IGARACY",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3479",
    DESCRICAO: "BORBOREMA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3480",
    DESCRICAO: "BREJO DO CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3481",
    DESCRICAO: "BREJO DOS SANTOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3482",
    DESCRICAO: "CAAPORA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3483",
    DESCRICAO: "CABACEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3484",
    DESCRICAO: "CABEDELO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3485",
    DESCRICAO: "CACHOEIRA DOS INDIOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3486",
    DESCRICAO: "CACIMBA DE AREIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3487",
    DESCRICAO: "CACIMBA DE DENTRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3488",
    DESCRICAO: "CACIMBAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3489",
    DESCRICAO: "CAICARA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3490",
    DESCRICAO: "CAJAZEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3491",
    DESCRICAO: "CAJAZEIRINHAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3492",
    DESCRICAO: "CALDAS BRANDAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3493",
    DESCRICAO: "CAMALAU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3494",
    DESCRICAO: "CAMPINA GRANDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3495",
    DESCRICAO: "CAPIM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3496",
    DESCRICAO: "CARAUBAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3497",
    DESCRICAO: "CARRAPATEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3498",
    DESCRICAO: "CASSERENGUE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3499",
    DESCRICAO: "CATINGUEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3500",
    DESCRICAO: "CATOLE DO ROCHA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3501",
    DESCRICAO: "CATURITE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3502",
    DESCRICAO: "CONCEICAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3503",
    DESCRICAO: "CONDADO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3504",
    DESCRICAO: "CONDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3505",
    DESCRICAO: "CONGO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3506",
    DESCRICAO: "COREMAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3507",
    DESCRICAO: "COXIXOLA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3508",
    DESCRICAO: "CRUZ DO ESPIRITO SANTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3509",
    DESCRICAO: "CUBATI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3510",
    DESCRICAO: "CUITE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3511",
    DESCRICAO: "CUITEGI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3512",
    DESCRICAO: "CUITE DE MAMANGUAPE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3513",
    DESCRICAO: "CURRAL DE CIMA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3514",
    DESCRICAO: "CURRAL VELHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3515",
    DESCRICAO: "DAMIAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3516",
    DESCRICAO: "DESTERRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3517",
    DESCRICAO: "VISTA SERRANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3518",
    DESCRICAO: "DIAMANTE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3519",
    DESCRICAO: "DONA INES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3520",
    DESCRICAO: "DUAS ESTRADAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3521",
    DESCRICAO: "EMAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3522",
    DESCRICAO: "ESPERANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3523",
    DESCRICAO: "FAGUNDES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3524",
    DESCRICAO: "FREI MARTINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3525",
    DESCRICAO: "GADO BRAVO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3526",
    DESCRICAO: "GUARABIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3527",
    DESCRICAO: "GURINHEM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3528",
    DESCRICAO: "GURJAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3529",
    DESCRICAO: "IBIARA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3530",
    DESCRICAO: "IMACULADA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3531",
    DESCRICAO: "INGA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3532",
    DESCRICAO: "ITABAIANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3533",
    DESCRICAO: "ITAPORANGA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3534",
    DESCRICAO: "ITAPOROROCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3535",
    DESCRICAO: "ITATUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3536",
    DESCRICAO: "JACARAU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3537",
    DESCRICAO: "JERICO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3538",
    DESCRICAO: "JOAO PESSOA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3539",
    DESCRICAO: "JUAREZ TAVORA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3540",
    DESCRICAO: "JUAZEIRINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3541",
    DESCRICAO: "JUNCO DO SERIDO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3542",
    DESCRICAO: "JURIPIRANGA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3543",
    DESCRICAO: "JURU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3544",
    DESCRICAO: "LAGOA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3545",
    DESCRICAO: "LAGOA DE DENTRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3546",
    DESCRICAO: "LAGOA SECA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3547",
    DESCRICAO: "LASTRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3548",
    DESCRICAO: "LIVRAMENTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3549",
    DESCRICAO: "LOGRADOURO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3550",
    DESCRICAO: "LUCENA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3551",
    DESCRICAO: "MAE DAGUA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3552",
    DESCRICAO: "MALTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3553",
    DESCRICAO: "MAMANGUAPE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3554",
    DESCRICAO: "MANAIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3555",
    DESCRICAO: "MARCACAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3556",
    DESCRICAO: "MARI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3557",
    DESCRICAO: "MARIZOPOLIS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3558",
    DESCRICAO: "MASSARANDUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3559",
    DESCRICAO: "MATARACA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3560",
    DESCRICAO: "MATINHAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3561",
    DESCRICAO: "MATO GROSSO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3562",
    DESCRICAO: "MATUREIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3563",
    DESCRICAO: "MOGEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3564",
    DESCRICAO: "MONTADAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3565",
    DESCRICAO: "MONTE HOREBE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3566",
    DESCRICAO: "MONTEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3567",
    DESCRICAO: "MULUNGU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3568",
    DESCRICAO: "NATUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3569",
    DESCRICAO: "NAZAREZINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3570",
    DESCRICAO: "NOVA FLORESTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3571",
    DESCRICAO: "NOVA OLINDA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3572",
    DESCRICAO: "NOVA PALMEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3573",
    DESCRICAO: "OLHO DAGUA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3574",
    DESCRICAO: "OLIVEDOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3575",
    DESCRICAO: "OURO VELHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3576",
    DESCRICAO: "PARARI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3577",
    DESCRICAO: "PASSAGEM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3578",
    DESCRICAO: "PATOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3579",
    DESCRICAO: "PAULISTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3580",
    DESCRICAO: "PEDRA BRANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3581",
    DESCRICAO: "PEDRA LAVRADA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3582",
    DESCRICAO: "PEDRAS DE FOGO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3583",
    DESCRICAO: "PIANCO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3584",
    DESCRICAO: "PICUI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3585",
    DESCRICAO: "PILAR",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3586",
    DESCRICAO: "PILOES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3587",
    DESCRICAO: "PILOEZINHOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3588",
    DESCRICAO: "PIRPIRITUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3589",
    DESCRICAO: "PITIMBU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3590",
    DESCRICAO: "POCINHOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3591",
    DESCRICAO: "POCO DANTAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3592",
    DESCRICAO: "POCO DE JOSE DE MOURA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3593",
    DESCRICAO: "POMBAL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3594",
    DESCRICAO: "PRATA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3595",
    DESCRICAO: "PRINCESA ISABEL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3596",
    DESCRICAO: "PUXINANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3597",
    DESCRICAO: "QUEIMADAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3598",
    DESCRICAO: "QUIXABA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3599",
    DESCRICAO: "REMIGIO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3600",
    DESCRICAO: "PEDRO REGIS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3601",
    DESCRICAO: "RIACHAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3602",
    DESCRICAO: "RIACHAO DO BACAMARTE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3603",
    DESCRICAO: "RIACHAO DO POCO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3604",
    DESCRICAO: "RIACHO DE SANTO ANTONIO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3605",
    DESCRICAO: "RIACHO DOS CAVALOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3606",
    DESCRICAO: "RIO TINTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3607",
    DESCRICAO: "SALGADINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3608",
    DESCRICAO: "SALGADO DE SAO FELIX",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3609",
    DESCRICAO: "SANTA CECILIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3610",
    DESCRICAO: "SANTA CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3611",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3612",
    DESCRICAO: "SANTA INES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3613",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3614",
    DESCRICAO: "SANTANA DE MANGUEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3615",
    DESCRICAO: "SANTANA DOS GARROTES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3617",
    DESCRICAO: "SANTA RITA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3618",
    DESCRICAO: "SANTA TERESINHA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3619",
    DESCRICAO: "SANTO ANDRE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3620",
    DESCRICAO: "SAO BENTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3621",
    DESCRICAO: "SAO BENTINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3622",
    DESCRICAO: "SAO DOMINGOS DO CARIRI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3623",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3624",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3625",
    DESCRICAO: "SAO JOAO DO CARIRI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3626",
    DESCRICAO: "SAO JOAO DO TIGRE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3627",
    DESCRICAO: "SAO JOSE DA LAGOA TAPADA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3628",
    DESCRICAO: "SAO JOSE DE CAIANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3629",
    DESCRICAO: "SAO JOSE DE ESPINHARAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3630",
    DESCRICAO: "SAO JOSE DOS RAMOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3631",
    DESCRICAO: "SAO JOSE DE PIRANHAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3632",
    DESCRICAO: "SAO JOSE DE PRINCESA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3633",
    DESCRICAO: "SAO JOSE DO BONFIM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3634",
    DESCRICAO: "SAO JOSE DO BREJO DO CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3635",
    DESCRICAO: "SAO JOSE DO SABUGI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3636",
    DESCRICAO: "SAO JOSE DOS CORDEIROS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3637",
    DESCRICAO: "SAO MAMEDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3638",
    DESCRICAO: "SAO MIGUEL DE TAIPU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3639",
    DESCRICAO: "SAO SEBASTIAO DE LAGOA DE ROCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3640",
    DESCRICAO: "SAO SEBASTIAO DO UMBUZEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3641",
    DESCRICAO: "SAPE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3643",
    DESCRICAO: "SERRA BRANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3644",
    DESCRICAO: "SERRA DA RAIZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3645",
    DESCRICAO: "SERRA GRANDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3646",
    DESCRICAO: "SERRA REDONDA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3647",
    DESCRICAO: "SERRARIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3648",
    DESCRICAO: "SERTAOZINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3649",
    DESCRICAO: "SOBRADO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3650",
    DESCRICAO: "SOLANEA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3651",
    DESCRICAO: "SOLEDADE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3652",
    DESCRICAO: "SOSSEGO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3653",
    DESCRICAO: "SOUSA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3654",
    DESCRICAO: "SUME",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3656",
    DESCRICAO: "TAPEROA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3657",
    DESCRICAO: "TAVARES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3658",
    DESCRICAO: "TEIXEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3659",
    DESCRICAO: "TENORIO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3660",
    DESCRICAO: "TRIUNFO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3661",
    DESCRICAO: "UIRAUNA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3662",
    DESCRICAO: "UMBUZEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3663",
    DESCRICAO: "VARZEA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3664",
    DESCRICAO: "VIEIROPOLIS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3665",
    DESCRICAO: "ZABELE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3666",
    DESCRICAO: "ACAUA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3667",
    DESCRICAO: "AGRICOLANDIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3668",
    DESCRICAO: "AGUA BRANCA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3669",
    DESCRICAO: "ALAGOINHA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3670",
    DESCRICAO: "ALEGRETE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3671",
    DESCRICAO: "ALTO LONGA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3672",
    DESCRICAO: "ALTOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3673",
    DESCRICAO: "ALVORADA DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3674",
    DESCRICAO: "AMARANTE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3675",
    DESCRICAO: "ANGICAL DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3676",
    DESCRICAO: "ANISIO DE ABREU",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3677",
    DESCRICAO: "ANTONIO ALMEIDA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3678",
    DESCRICAO: "AROAZES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3679",
    DESCRICAO: "AROEIRAS DO ITAIM",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3680",
    DESCRICAO: "ARRAIAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3681",
    DESCRICAO: "ASSUNCAO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3682",
    DESCRICAO: "AVELINO LOPES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3683",
    DESCRICAO: "BAIXA GRANDE DO RIBEIRO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3684",
    DESCRICAO: "BARRA DALCANTARA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3685",
    DESCRICAO: "BARRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3686",
    DESCRICAO: "BARREIRAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3687",
    DESCRICAO: "BARRO DURO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3688",
    DESCRICAO: "BATALHA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3689",
    DESCRICAO: "BELA VISTA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3690",
    DESCRICAO: "BELEM DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3691",
    DESCRICAO: "BENEDITINOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3692",
    DESCRICAO: "BERTOLINIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3693",
    DESCRICAO: "BETANIA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3694",
    DESCRICAO: "BOA HORA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3695",
    DESCRICAO: "BOCAINA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3696",
    DESCRICAO: "BOM JESUS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3697",
    DESCRICAO: "BOM PRINCIPIO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3698",
    DESCRICAO: "BONFIM DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3699",
    DESCRICAO: "BOQUEIRAO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3700",
    DESCRICAO: "BRASILEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3701",
    DESCRICAO: "BREJO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3702",
    DESCRICAO: "BURITI DOS LOPES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3703",
    DESCRICAO: "BURITI DOS MONTES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3704",
    DESCRICAO: "CABECEIRAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3705",
    DESCRICAO: "CAJAZEIRAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3706",
    DESCRICAO: "CAJUEIRO DA PRAIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3707",
    DESCRICAO: "CALDEIRAO GRANDE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3708",
    DESCRICAO: "CAMPINAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3709",
    DESCRICAO: "CAMPO ALEGRE DO FIDALGO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3710",
    DESCRICAO: "CAMPO GRANDE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3711",
    DESCRICAO: "CAMPO LARGO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3712",
    DESCRICAO: "CAMPO MAIOR",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3713",
    DESCRICAO: "CANAVIEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3714",
    DESCRICAO: "CANTO DO BURITI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3715",
    DESCRICAO: "CAPITAO DE CAMPOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3716",
    DESCRICAO: "CAPITAO GERVASIO OLIVEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3717",
    DESCRICAO: "CARACOL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3718",
    DESCRICAO: "CARAUBAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3719",
    DESCRICAO: "CARIDADE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3720",
    DESCRICAO: "CASTELO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3721",
    DESCRICAO: "CAXINGO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3722",
    DESCRICAO: "COCAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3723",
    DESCRICAO: "COCAL DE TELHA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3724",
    DESCRICAO: "COCAL DOS ALVES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3725",
    DESCRICAO: "COIVARAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3726",
    DESCRICAO: "COLONIA DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3727",
    DESCRICAO: "COLONIA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3728",
    DESCRICAO: "CONCEICAO DO CANINDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3729",
    DESCRICAO: "CORONEL JOSE DIAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3730",
    DESCRICAO: "CORRENTE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3731",
    DESCRICAO: "CRISTALANDIA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3732",
    DESCRICAO: "CRISTINO CASTRO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3733",
    DESCRICAO: "CURIMATA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3734",
    DESCRICAO: "CURRAIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3735",
    DESCRICAO: "CURRALINHOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3736",
    DESCRICAO: "CURRAL NOVO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3737",
    DESCRICAO: "DEMERVAL LOBAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3738",
    DESCRICAO: "DIRCEU ARCOVERDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3739",
    DESCRICAO: "DOM EXPEDITO LOPES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3740",
    DESCRICAO: "DOMINGOS MOURAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3741",
    DESCRICAO: "DOM INOCENCIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3742",
    DESCRICAO: "ELESBAO VELOSO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3743",
    DESCRICAO: "ELISEU MARTINS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3744",
    DESCRICAO: "ESPERANTINA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3745",
    DESCRICAO: "FARTURA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3746",
    DESCRICAO: "FLORES DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3747",
    DESCRICAO: "FLORESTA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3748",
    DESCRICAO: "FLORIANO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3749",
    DESCRICAO: "FRANCINOPOLIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3750",
    DESCRICAO: "FRANCISCO AYRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3751",
    DESCRICAO: "FRANCISCO MACEDO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3752",
    DESCRICAO: "FRANCISCO SANTOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3753",
    DESCRICAO: "FRONTEIRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3754",
    DESCRICAO: "GEMINIANO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3755",
    DESCRICAO: "GILBUES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3756",
    DESCRICAO: "GUADALUPE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3757",
    DESCRICAO: "GUARIBAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3758",
    DESCRICAO: "HUGO NAPOLEAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3759",
    DESCRICAO: "ILHA GRANDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3760",
    DESCRICAO: "INHUMA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3761",
    DESCRICAO: "IPIRANGA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3762",
    DESCRICAO: "ISAIAS COELHO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3763",
    DESCRICAO: "ITAINOPOLIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3764",
    DESCRICAO: "ITAUEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3765",
    DESCRICAO: "JACOBINA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3766",
    DESCRICAO: "JAICOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3767",
    DESCRICAO: "JARDIM DO MULATO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3768",
    DESCRICAO: "JATOBA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3769",
    DESCRICAO: "JERUMENHA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3770",
    DESCRICAO: "JOAO COSTA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3771",
    DESCRICAO: "JOAQUIM PIRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3772",
    DESCRICAO: "JOCA MARQUES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3773",
    DESCRICAO: "JOSE DE FREITAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3774",
    DESCRICAO: "JUAZEIRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3775",
    DESCRICAO: "JULIO BORGES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3776",
    DESCRICAO: "JUREMA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3777",
    DESCRICAO: "LAGOINHA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3778",
    DESCRICAO: "LAGOA ALEGRE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3779",
    DESCRICAO: "LAGOA DO BARRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3780",
    DESCRICAO: "LAGOA DE SAO FRANCISCO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3781",
    DESCRICAO: "LAGOA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3782",
    DESCRICAO: "LAGOA DO SITIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3783",
    DESCRICAO: "LANDRI SALES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3784",
    DESCRICAO: "LUIS CORREIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3785",
    DESCRICAO: "LUZILANDIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3786",
    DESCRICAO: "MADEIRO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3787",
    DESCRICAO: "MANOEL EMIDIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3788",
    DESCRICAO: "MARCOLANDIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3789",
    DESCRICAO: "MARCOS PARENTE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3790",
    DESCRICAO: "MASSAPE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3791",
    DESCRICAO: "MATIAS OLIMPIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3792",
    DESCRICAO: "MIGUEL ALVES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3793",
    DESCRICAO: "MIGUEL LEAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3794",
    DESCRICAO: "MILTON BRANDAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3795",
    DESCRICAO: "MONSENHOR GIL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3796",
    DESCRICAO: "MONSENHOR HIPOLITO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3797",
    DESCRICAO: "MONTE ALEGRE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3798",
    DESCRICAO: "MORRO CABECA NO TEMPO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3799",
    DESCRICAO: "MORRO DO CHAPEU DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3800",
    DESCRICAO: "MURICI DOS PORTELAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3801",
    DESCRICAO: "NAZARE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3802",
    DESCRICAO: "NOSSA SENHORA DE NAZARE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3803",
    DESCRICAO: "NOSSA SENHORA DOS REMEDIOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3804",
    DESCRICAO: "NOVO ORIENTE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3805",
    DESCRICAO: "NOVO SANTO ANTONIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3806",
    DESCRICAO: "OEIRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3807",
    DESCRICAO: "OLHO DAGUA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3808",
    DESCRICAO: "PADRE MARCOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3809",
    DESCRICAO: "PAES LANDIM",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3810",
    DESCRICAO: "PAJEU DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3811",
    DESCRICAO: "PALMEIRA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3812",
    DESCRICAO: "PALMEIRAIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3813",
    DESCRICAO: "PAQUETA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3814",
    DESCRICAO: "PARNAGUA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3815",
    DESCRICAO: "PARNAIBA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3816",
    DESCRICAO: "PASSAGEM FRANCA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3817",
    DESCRICAO: "PATOS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3818",
    DESCRICAO: "PAU DARCO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3819",
    DESCRICAO: "PAULISTANA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3820",
    DESCRICAO: "PAVUSSU",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3821",
    DESCRICAO: "PEDRO II",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3822",
    DESCRICAO: "PEDRO LAURENTINO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3823",
    DESCRICAO: "NOVA SANTA RITA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3824",
    DESCRICAO: "PICOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3825",
    DESCRICAO: "PIMENTEIRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3826",
    DESCRICAO: "PIO IX",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3827",
    DESCRICAO: "PIRACURUCA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3828",
    DESCRICAO: "PIRIPIRI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3829",
    DESCRICAO: "PORTO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3830",
    DESCRICAO: "PORTO ALEGRE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3831",
    DESCRICAO: "PRATA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3832",
    DESCRICAO: "QUEIMADA NOVA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3833",
    DESCRICAO: "REDENCAO DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3834",
    DESCRICAO: "REGENERACAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3835",
    DESCRICAO: "RIACHO FRIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3836",
    DESCRICAO: "RIBEIRA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3837",
    DESCRICAO: "RIBEIRO GONCALVES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3838",
    DESCRICAO: "RIO GRANDE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3839",
    DESCRICAO: "SANTA CRUZ DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3840",
    DESCRICAO: "SANTA CRUZ DOS MILAGRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3841",
    DESCRICAO: "SANTA FILOMENA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3842",
    DESCRICAO: "SANTA LUZ",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3843",
    DESCRICAO: "SANTANA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3844",
    DESCRICAO: "SANTA ROSA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3845",
    DESCRICAO: "SANTO ANTONIO DE LISBOA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3846",
    DESCRICAO: "SANTO ANTONIO DOS MILAGRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3847",
    DESCRICAO: "SANTO INACIO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3848",
    DESCRICAO: "SAO BRAZ DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3849",
    DESCRICAO: "SAO FELIX DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3850",
    DESCRICAO: "SAO FRANCISCO DE ASSIS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3851",
    DESCRICAO: "SAO FRANCISCO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3852",
    DESCRICAO: "SAO GONCALO DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3853",
    DESCRICAO: "SAO GONCALO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3854",
    DESCRICAO: "SAO JOAO DA CANABRAVA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3855",
    DESCRICAO: "SAO JOAO DA FRONTEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3856",
    DESCRICAO: "SAO JOAO DA SERRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3857",
    DESCRICAO: "SAO JOAO DA VARJOTA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3858",
    DESCRICAO: "SAO JOAO DO ARRAIAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3859",
    DESCRICAO: "SAO JOAO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3860",
    DESCRICAO: "SAO JOSE DO DIVINO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3861",
    DESCRICAO: "SAO JOSE DO PEIXE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3862",
    DESCRICAO: "SAO JOSE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3863",
    DESCRICAO: "SAO JULIAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3864",
    DESCRICAO: "SAO LOURENCO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3865",
    DESCRICAO: "SAO LUIS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3866",
    DESCRICAO: "SAO MIGUEL DA BAIXA GRANDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3867",
    DESCRICAO: "SAO MIGUEL DO FIDALGO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3868",
    DESCRICAO: "SAO MIGUEL DO TAPUIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3869",
    DESCRICAO: "SAO PEDRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3870",
    DESCRICAO: "SAO RAIMUNDO NONATO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3871",
    DESCRICAO: "SEBASTIAO BARROS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3872",
    DESCRICAO: "SEBASTIAO LEAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3873",
    DESCRICAO: "SIGEFREDO PACHECO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3874",
    DESCRICAO: "SIMOES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3875",
    DESCRICAO: "SIMPLICIO MENDES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3876",
    DESCRICAO: "SOCORRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3877",
    DESCRICAO: "SUSSUAPARA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3878",
    DESCRICAO: "TAMBORIL DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3879",
    DESCRICAO: "TANQUE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3880",
    DESCRICAO: "TERESINA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3881",
    DESCRICAO: "UNIAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3882",
    DESCRICAO: "URUCUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3883",
    DESCRICAO: "VALENCA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3884",
    DESCRICAO: "VARZEA BRANCA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3885",
    DESCRICAO: "VARZEA GRANDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3886",
    DESCRICAO: "VERA MENDES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3887",
    DESCRICAO: "VILA NOVA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3888",
    DESCRICAO: "WALL FERRAZ",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3889",
    DESCRICAO: "ACARI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3890",
    DESCRICAO: "ACU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3891",
    DESCRICAO: "AFONSO BEZERRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3892",
    DESCRICAO: "AGUA NOVA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3893",
    DESCRICAO: "ALEXANDRIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3894",
    DESCRICAO: "ALMINO AFONSO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3895",
    DESCRICAO: "ALTO DO RODRIGUES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3896",
    DESCRICAO: "ANGICOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3897",
    DESCRICAO: "ANTONIO MARTINS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3898",
    DESCRICAO: "APODI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3899",
    DESCRICAO: "AREIA BRANCA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3900",
    DESCRICAO: "ARES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3901",
    DESCRICAO: "AUGUSTO SEVERO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3902",
    DESCRICAO: "BAIA FORMOSA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3903",
    DESCRICAO: "BARAUNA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3904",
    DESCRICAO: "BARCELONA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3905",
    DESCRICAO: "BENTO FERNANDES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3906",
    DESCRICAO: "BODO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3907",
    DESCRICAO: "BOM JESUS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3908",
    DESCRICAO: "BREJINHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3909",
    DESCRICAO: "CAICARA DO NORTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3910",
    DESCRICAO: "CAICARA DO RIO DO VENTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3911",
    DESCRICAO: "CAICO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3912",
    DESCRICAO: "CAMPO REDONDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3913",
    DESCRICAO: "CANGUARETAMA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3914",
    DESCRICAO: "CARAUBAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3915",
    DESCRICAO: "CARNAUBA DOS DANTAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3916",
    DESCRICAO: "CARNAUBAIS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3917",
    DESCRICAO: "CEARA-MIRIM",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3918",
    DESCRICAO: "CERRO CORA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3919",
    DESCRICAO: "CORONEL EZEQUIEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3920",
    DESCRICAO: "CORONEL JOAO PESSOA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3921",
    DESCRICAO: "CRUZETA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3922",
    DESCRICAO: "CURRAIS NOVOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3923",
    DESCRICAO: "DOUTOR SEVERIANO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3924",
    DESCRICAO: "PARNAMIRIM",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3925",
    DESCRICAO: "ENCANTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3926",
    DESCRICAO: "EQUADOR",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3927",
    DESCRICAO: "ESPIRITO SANTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3928",
    DESCRICAO: "EXTREMOZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3929",
    DESCRICAO: "FELIPE GUERRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3930",
    DESCRICAO: "FERNANDO PEDROZA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3931",
    DESCRICAO: "FLORANIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3932",
    DESCRICAO: "FRANCISCO DANTAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3933",
    DESCRICAO: "FRUTUOSO GOMES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3934",
    DESCRICAO: "GALINHOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3935",
    DESCRICAO: "GOIANINHA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3936",
    DESCRICAO: "GOVERNADOR DIX-SEPT ROSADO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3937",
    DESCRICAO: "GROSSOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3938",
    DESCRICAO: "GUAMARE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3939",
    DESCRICAO: "IELMO MARINHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3940",
    DESCRICAO: "IPANGUACU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3941",
    DESCRICAO: "IPUEIRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3942",
    DESCRICAO: "ITAJA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3943",
    DESCRICAO: "ITAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3944",
    DESCRICAO: "JACANA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3945",
    DESCRICAO: "JANDAIRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3946",
    DESCRICAO: "JANDUIS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3947",
    DESCRICAO: "JANUARIO CICCO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3948",
    DESCRICAO: "JAPI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3949",
    DESCRICAO: "JARDIM DE ANGICOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3950",
    DESCRICAO: "JARDIM DE PIRANHAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3951",
    DESCRICAO: "JARDIM DO SERIDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3952",
    DESCRICAO: "JOAO CAMARA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3953",
    DESCRICAO: "JOAO DIAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3954",
    DESCRICAO: "JOSE DA PENHA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3955",
    DESCRICAO: "JUCURUTU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3956",
    DESCRICAO: "JUNDIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3957",
    DESCRICAO: "LAGOA DANTA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3958",
    DESCRICAO: "LAGOA DE PEDRAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3959",
    DESCRICAO: "LAGOA DE VELHOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3960",
    DESCRICAO: "LAGOA NOVA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3961",
    DESCRICAO: "LAGOA SALGADA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3962",
    DESCRICAO: "LAJES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3963",
    DESCRICAO: "LAJES PINTADAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3964",
    DESCRICAO: "LUCRECIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3965",
    DESCRICAO: "LUIS GOMES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3966",
    DESCRICAO: "MACAIBA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3967",
    DESCRICAO: "MACAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3968",
    DESCRICAO: "MAJOR SALES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3969",
    DESCRICAO: "MARCELINO VIEIRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3970",
    DESCRICAO: "MARTINS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3971",
    DESCRICAO: "MAXARANGUAPE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3972",
    DESCRICAO: "MESSIAS TARGINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3973",
    DESCRICAO: "MONTANHAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3974",
    DESCRICAO: "MONTE ALEGRE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3975",
    DESCRICAO: "MONTE DAS GAMELEIRAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3976",
    DESCRICAO: "MOSSORO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3977",
    DESCRICAO: "NATAL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3978",
    DESCRICAO: "NISIA FLORESTA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3979",
    DESCRICAO: "NOVA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3980",
    DESCRICAO: "OLHO-DAGUA DO BORGES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3981",
    DESCRICAO: "OURO BRANCO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3982",
    DESCRICAO: "PARANA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3983",
    DESCRICAO: "PARAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3984",
    DESCRICAO: "PARAZINHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3985",
    DESCRICAO: "PARELHAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3986",
    DESCRICAO: "RIO DO FOGO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3987",
    DESCRICAO: "PASSA E FICA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3988",
    DESCRICAO: "PASSAGEM",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3989",
    DESCRICAO: "PATU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3990",
    DESCRICAO: "SANTA MARIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3991",
    DESCRICAO: "PAU DOS FERROS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3992",
    DESCRICAO: "PEDRA GRANDE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3993",
    DESCRICAO: "PEDRA PRETA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3994",
    DESCRICAO: "PEDRO AVELINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3995",
    DESCRICAO: "PEDRO VELHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3996",
    DESCRICAO: "PENDENCIAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3997",
    DESCRICAO: "PILOES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3998",
    DESCRICAO: "POCO BRANCO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3999",
    DESCRICAO: "PORTALEGRE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4000",
    DESCRICAO: "PORTO DO MANGUE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4001",
    DESCRICAO: "PRESIDENTE JUSCELINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4002",
    DESCRICAO: "PUREZA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4003",
    DESCRICAO: "RAFAEL FERNANDES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4004",
    DESCRICAO: "RAFAEL GODEIRO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4005",
    DESCRICAO: "RIACHO DA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4006",
    DESCRICAO: "RIACHO DE SANTANA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4007",
    DESCRICAO: "RIACHUELO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4008",
    DESCRICAO: "RODOLFO FERNANDES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4009",
    DESCRICAO: "TIBAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4010",
    DESCRICAO: "RUY BARBOSA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4011",
    DESCRICAO: "SANTA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4012",
    DESCRICAO: "SANTANA DO MATOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4013",
    DESCRICAO: "SANTANA DO SERIDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4014",
    DESCRICAO: "SANTO ANTONIO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4015",
    DESCRICAO: "SAO BENTO DO NORTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4016",
    DESCRICAO: "SAO BENTO DO TRAIRI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4017",
    DESCRICAO: "SAO FERNANDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4018",
    DESCRICAO: "SAO FRANCISCO DO OESTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4019",
    DESCRICAO: "SAO GONCALO DO AMARANTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4020",
    DESCRICAO: "SAO JOAO DO SABUGI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4021",
    DESCRICAO: "SAO JOSE DE MIPIBU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4022",
    DESCRICAO: "SAO JOSE DO CAMPESTRE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4023",
    DESCRICAO: "SAO JOSE DO SERIDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4024",
    DESCRICAO: "SAO MIGUEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4025",
    DESCRICAO: "SAO MIGUEL DO GOSTOSO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4026",
    DESCRICAO: "SAO PAULO DO POTENGI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4027",
    DESCRICAO: "SAO PEDRO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4028",
    DESCRICAO: "SAO RAFAEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4029",
    DESCRICAO: "SAO TOME",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4030",
    DESCRICAO: "SAO VICENTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4031",
    DESCRICAO: "SENADOR ELOI DE SOUZA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4032",
    DESCRICAO: "SENADOR GEORGINO AVELINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4033",
    DESCRICAO: "SERRA DE SAO BENTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4034",
    DESCRICAO: "SERRA DO MEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4035",
    DESCRICAO: "SERRA NEGRA DO NORTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4036",
    DESCRICAO: "SERRINHA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4037",
    DESCRICAO: "SERRINHA DOS PINTOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4038",
    DESCRICAO: "SEVERIANO MELO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4039",
    DESCRICAO: "SITIO NOVO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4040",
    DESCRICAO: "TABOLEIRO GRANDE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4041",
    DESCRICAO: "TAIPU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4042",
    DESCRICAO: "TANGARA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4043",
    DESCRICAO: "TENENTE ANANIAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4044",
    DESCRICAO: "TENENTE LAURENTINO CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4045",
    DESCRICAO: "TIBAU DO SUL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4046",
    DESCRICAO: "TIMBAUBA DOS BATISTAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4047",
    DESCRICAO: "TOUROS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4048",
    DESCRICAO: "TRIUNFO POTIGUAR",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4049",
    DESCRICAO: "UMARIZAL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4050",
    DESCRICAO: "UPANEMA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4051",
    DESCRICAO: "VARZEA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4052",
    DESCRICAO: "VENHA-VER",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4053",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4054",
    DESCRICAO: "VICOSA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4055",
    DESCRICAO: "VILA FLOR",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4056",
    DESCRICAO: "AMPARO DE SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4057",
    DESCRICAO: "AQUIDABA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4058",
    DESCRICAO: "ARACAJU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4059",
    DESCRICAO: "ARAUA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4060",
    DESCRICAO: "AREIA BRANCA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4061",
    DESCRICAO: "BARRA DOS COQUEIROS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4062",
    DESCRICAO: "BOQUIM",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4063",
    DESCRICAO: "BREJO GRANDE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4064",
    DESCRICAO: "CAMPO DO BRITO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4065",
    DESCRICAO: "CANHOBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4066",
    DESCRICAO: "CANINDE DE SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4067",
    DESCRICAO: "CAPELA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4068",
    DESCRICAO: "CARIRA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4069",
    DESCRICAO: "CARMOPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4070",
    DESCRICAO: "CEDRO DE SAO JOAO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4071",
    DESCRICAO: "CRISTINAPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4072",
    DESCRICAO: "CUMBE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4073",
    DESCRICAO: "DIVINA PASTORA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4074",
    DESCRICAO: "ESTANCIA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4075",
    DESCRICAO: "FEIRA NOVA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4076",
    DESCRICAO: "FREI PAULO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4077",
    DESCRICAO: "GARARU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4078",
    DESCRICAO: "GENERAL MAYNARD",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4079",
    DESCRICAO: "GRACHO CARDOSO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4080",
    DESCRICAO: "ILHA DAS FLORES",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4081",
    DESCRICAO: "INDIAROBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4082",
    DESCRICAO: "ITABAIANA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4083",
    DESCRICAO: "ITABAIANINHA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4084",
    DESCRICAO: "ITABI",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4085",
    DESCRICAO: "ITAPORANGA DAJUDA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4086",
    DESCRICAO: "JAPARATUBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4087",
    DESCRICAO: "JAPOATA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4088",
    DESCRICAO: "LAGARTO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4089",
    DESCRICAO: "LARANJEIRAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4090",
    DESCRICAO: "MACAMBIRA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4091",
    DESCRICAO: "MALHADA DOS BOIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4092",
    DESCRICAO: "MALHADOR",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4093",
    DESCRICAO: "MARUIM",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4094",
    DESCRICAO: "MOITA BONITA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4095",
    DESCRICAO: "MONTE ALEGRE DE SERGIPE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4096",
    DESCRICAO: "MURIBECA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4097",
    DESCRICAO: "NEOPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4098",
    DESCRICAO: "NOSSA SENHORA APARECIDA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4099",
    DESCRICAO: "NOSSA SENHORA DA GLORIA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4100",
    DESCRICAO: "NOSSA SENHORA DAS DORES",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4101",
    DESCRICAO: "NOSSA SENHORA DE LOURDES",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4102",
    DESCRICAO: "NOSSA SENHORA DO SOCORRO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4103",
    DESCRICAO: "PACATUBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4104",
    DESCRICAO: "PEDRA MOLE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4105",
    DESCRICAO: "PEDRINHAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4106",
    DESCRICAO: "PINHAO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4107",
    DESCRICAO: "PIRAMBU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4108",
    DESCRICAO: "POCO REDONDO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4109",
    DESCRICAO: "POCO VERDE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4110",
    DESCRICAO: "PORTO DA FOLHA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4111",
    DESCRICAO: "PROPRIA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4112",
    DESCRICAO: "RIACHAO DO DANTAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4113",
    DESCRICAO: "RIACHUELO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4114",
    DESCRICAO: "RIBEIROPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4115",
    DESCRICAO: "ROSARIO DO CATETE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4116",
    DESCRICAO: "SALGADO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4117",
    DESCRICAO: "SANTA LUZIA DO ITANHY",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4118",
    DESCRICAO: "SANTANA DO SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4119",
    DESCRICAO: "SANTA ROSA DE LIMA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4120",
    DESCRICAO: "SANTO AMARO DAS BROTAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4121",
    DESCRICAO: "SAO CRISTOVAO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4122",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4123",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4124",
    DESCRICAO: "SAO MIGUEL DO ALEIXO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4125",
    DESCRICAO: "SIMAO DIAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4126",
    DESCRICAO: "SIRIRI",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4127",
    DESCRICAO: "TELHA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4128",
    DESCRICAO: "TOBIAS BARRETO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4129",
    DESCRICAO: "TOMAR DO GERU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4130",
    DESCRICAO: "UMBAUBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4131",
    DESCRICAO: "ALVARAES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4132",
    DESCRICAO: "AMATURA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4133",
    DESCRICAO: "ANAMA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4134",
    DESCRICAO: "ANORI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4135",
    DESCRICAO: "APUI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4136",
    DESCRICAO: "ATALAIA DO NORTE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4137",
    DESCRICAO: "AUTAZES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4138",
    DESCRICAO: "BARCELOS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4139",
    DESCRICAO: "BARREIRINHA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4140",
    DESCRICAO: "BENJAMIN CONSTANT",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4141",
    DESCRICAO: "BERURI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4142",
    DESCRICAO: "BOA VISTA DO RAMOS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4143",
    DESCRICAO: "BOCA DO ACRE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4144",
    DESCRICAO: "BORBA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4145",
    DESCRICAO: "CAAPIRANGA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4146",
    DESCRICAO: "CANUTAMA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4147",
    DESCRICAO: "CARAUARI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4148",
    DESCRICAO: "CAREIRO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4149",
    DESCRICAO: "CAREIRO DA VARZEA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4150",
    DESCRICAO: "COARI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4151",
    DESCRICAO: "CODAJAS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4152",
    DESCRICAO: "EIRUNEPE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4153",
    DESCRICAO: "ENVIRA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4154",
    DESCRICAO: "FONTE BOA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4155",
    DESCRICAO: "GUAJARA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4156",
    DESCRICAO: "HUMAITA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4157",
    DESCRICAO: "IPIXUNA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4158",
    DESCRICAO: "IRANDUBA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4159",
    DESCRICAO: "ITACOATIARA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4160",
    DESCRICAO: "ITAMARATI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4161",
    DESCRICAO: "ITAPIRANGA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4162",
    DESCRICAO: "JAPURA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4163",
    DESCRICAO: "JURUA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4164",
    DESCRICAO: "JUTAI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4165",
    DESCRICAO: "LABREA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4166",
    DESCRICAO: "MANACAPURU",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4167",
    DESCRICAO: "MANAQUIRI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4168",
    DESCRICAO: "MANAUS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4169",
    DESCRICAO: "MANICORE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4170",
    DESCRICAO: "MARAA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4171",
    DESCRICAO: "MAUES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4172",
    DESCRICAO: "NHAMUNDA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4173",
    DESCRICAO: "NOVA OLINDA DO NORTE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4174",
    DESCRICAO: "NOVO AIRAO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4175",
    DESCRICAO: "NOVO ARIPUANA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4176",
    DESCRICAO: "PARINTINS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4177",
    DESCRICAO: "PAUINI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4178",
    DESCRICAO: "PRESIDENTE FIGUEIREDO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4179",
    DESCRICAO: "RIO PRETO DA EVA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4180",
    DESCRICAO: "SANTA ISABEL DO RIO NEGRO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4181",
    DESCRICAO: "SANTO ANTONIO DO ICA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4182",
    DESCRICAO: "SAO GABRIEL DA CACHOEIRA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4183",
    DESCRICAO: "SAO PAULO DE OLIVENCA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4184",
    DESCRICAO: "SAO SEBASTIAO DO UATUMA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4185",
    DESCRICAO: "SILVES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4186",
    DESCRICAO: "TABATINGA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4187",
    DESCRICAO: "TAPAUA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4188",
    DESCRICAO: "TEFE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4189",
    DESCRICAO: "TONANTINS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4190",
    DESCRICAO: "UARINI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4191",
    DESCRICAO: "URUCARA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4192",
    DESCRICAO: "URUCURITUBA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4193",
    DESCRICAO: "ABAIARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4194",
    DESCRICAO: "ACARAPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4195",
    DESCRICAO: "ACARAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4196",
    DESCRICAO: "ACOPIARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4197",
    DESCRICAO: "AIUABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4198",
    DESCRICAO: "ALCANTARAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4199",
    DESCRICAO: "ALTANEIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4200",
    DESCRICAO: "ALTO SANTO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4201",
    DESCRICAO: "AMONTADA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4202",
    DESCRICAO: "ANTONINA DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4203",
    DESCRICAO: "APUIARES",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4204",
    DESCRICAO: "AQUIRAZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4205",
    DESCRICAO: "ARACATI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4206",
    DESCRICAO: "ARACOIABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4207",
    DESCRICAO: "ARARENDA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4208",
    DESCRICAO: "ARARIPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4209",
    DESCRICAO: "ARATUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4210",
    DESCRICAO: "ARNEIROZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4211",
    DESCRICAO: "ASSARE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4212",
    DESCRICAO: "AURORA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4213",
    DESCRICAO: "BAIXIO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4214",
    DESCRICAO: "BANABUIU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4215",
    DESCRICAO: "BARBALHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4216",
    DESCRICAO: "BARREIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4217",
    DESCRICAO: "BARRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4218",
    DESCRICAO: "BARROQUINHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4219",
    DESCRICAO: "BATURITE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4220",
    DESCRICAO: "BEBERIBE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4221",
    DESCRICAO: "BELA CRUZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4222",
    DESCRICAO: "BOA VIAGEM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4223",
    DESCRICAO: "BREJO SANTO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4224",
    DESCRICAO: "CAMOCIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4225",
    DESCRICAO: "CAMPOS SALES",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4226",
    DESCRICAO: "CANINDE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4227",
    DESCRICAO: "CAPISTRANO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4228",
    DESCRICAO: "CARIDADE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4229",
    DESCRICAO: "CARIRE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4230",
    DESCRICAO: "CARIRIACU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4231",
    DESCRICAO: "CARIUS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4232",
    DESCRICAO: "CARNAUBAL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4233",
    DESCRICAO: "CASCAVEL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4234",
    DESCRICAO: "CATARINA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4235",
    DESCRICAO: "CATUNDA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4236",
    DESCRICAO: "CAUCAIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4237",
    DESCRICAO: "CEDRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4238",
    DESCRICAO: "CHAVAL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4239",
    DESCRICAO: "CHORO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4240",
    DESCRICAO: "CHOROZINHO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4241",
    DESCRICAO: "COREAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4242",
    DESCRICAO: "CRATEUS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4243",
    DESCRICAO: "CRATO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4244",
    DESCRICAO: "CROATA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4245",
    DESCRICAO: "CRUZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4246",
    DESCRICAO: "DEPUTADO IRAPUAN PINHEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4247",
    DESCRICAO: "ERERE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4248",
    DESCRICAO: "EUSEBIO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4249",
    DESCRICAO: "FARIAS BRITO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4250",
    DESCRICAO: "FORQUILHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4251",
    DESCRICAO: "FORTALEZA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4252",
    DESCRICAO: "FORTIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4253",
    DESCRICAO: "FRECHEIRINHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4254",
    DESCRICAO: "GENERAL SAMPAIO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4255",
    DESCRICAO: "GRACA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4256",
    DESCRICAO: "GRANJA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4257",
    DESCRICAO: "GRANJEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4258",
    DESCRICAO: "GROAIRAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4259",
    DESCRICAO: "GUAIUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4260",
    DESCRICAO: "GUARACIABA DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4261",
    DESCRICAO: "GUARAMIRANGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4262",
    DESCRICAO: "HIDROLANDIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4263",
    DESCRICAO: "HORIZONTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4264",
    DESCRICAO: "IBARETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4265",
    DESCRICAO: "IBIAPINA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4266",
    DESCRICAO: "IBICUITINGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4267",
    DESCRICAO: "ICAPUI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4268",
    DESCRICAO: "ICO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4269",
    DESCRICAO: "IGUATU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4270",
    DESCRICAO: "INDEPENDENCIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4271",
    DESCRICAO: "IPAPORANGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4272",
    DESCRICAO: "IPAUMIRIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4273",
    DESCRICAO: "IPU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4274",
    DESCRICAO: "IPUEIRAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4275",
    DESCRICAO: "IRACEMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4276",
    DESCRICAO: "IRAUCUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4277",
    DESCRICAO: "ITAICABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4278",
    DESCRICAO: "ITAITINGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4279",
    DESCRICAO: "ITAPAGE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4280",
    DESCRICAO: "ITAPIPOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4281",
    DESCRICAO: "ITAPIUNA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4282",
    DESCRICAO: "ITAREMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4283",
    DESCRICAO: "ITATIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4284",
    DESCRICAO: "JAGUARETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4285",
    DESCRICAO: "JAGUARIBARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4286",
    DESCRICAO: "JAGUARIBE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4287",
    DESCRICAO: "JAGUARUANA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4288",
    DESCRICAO: "JARDIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4289",
    DESCRICAO: "JATI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4290",
    DESCRICAO: "JIJOCA DE JERICOACOARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4291",
    DESCRICAO: "JUAZEIRO DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4292",
    DESCRICAO: "JUCAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4293",
    DESCRICAO: "LAVRAS DA MANGABEIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4294",
    DESCRICAO: "LIMOEIRO DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4295",
    DESCRICAO: "MADALENA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4296",
    DESCRICAO: "MARACANAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4297",
    DESCRICAO: "MARANGUAPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4298",
    DESCRICAO: "MARCO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4299",
    DESCRICAO: "MARTINOPOLE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4300",
    DESCRICAO: "MASSAPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4301",
    DESCRICAO: "MAURITI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4302",
    DESCRICAO: "MERUOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4303",
    DESCRICAO: "MILAGRES",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4304",
    DESCRICAO: "MILHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4305",
    DESCRICAO: "MIRAIMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4306",
    DESCRICAO: "MISSAO VELHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4307",
    DESCRICAO: "MOMBACA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4308",
    DESCRICAO: "MONSENHOR TABOSA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4309",
    DESCRICAO: "MORADA NOVA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4310",
    DESCRICAO: "MORAUJO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4311",
    DESCRICAO: "MORRINHOS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4312",
    DESCRICAO: "MUCAMBO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4313",
    DESCRICAO: "MULUNGU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4314",
    DESCRICAO: "NOVA OLINDA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4315",
    DESCRICAO: "NOVA RUSSAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4316",
    DESCRICAO: "NOVO ORIENTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4317",
    DESCRICAO: "OCARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4318",
    DESCRICAO: "OROS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4319",
    DESCRICAO: "PACAJUS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4320",
    DESCRICAO: "PACATUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4321",
    DESCRICAO: "PACOTI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4322",
    DESCRICAO: "PACUJA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4323",
    DESCRICAO: "PALHANO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4324",
    DESCRICAO: "PALMACIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4325",
    DESCRICAO: "PARACURU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4326",
    DESCRICAO: "PARAIPABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4327",
    DESCRICAO: "PARAMBU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4328",
    DESCRICAO: "PARAMOTI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4329",
    DESCRICAO: "PEDRA BRANCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4330",
    DESCRICAO: "PENAFORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4331",
    DESCRICAO: "PENTECOSTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4332",
    DESCRICAO: "PEREIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4333",
    DESCRICAO: "PINDORETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4334",
    DESCRICAO: "PIQUET CARNEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4335",
    DESCRICAO: "PIRES FERREIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4336",
    DESCRICAO: "PORANGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4337",
    DESCRICAO: "PORTEIRAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4338",
    DESCRICAO: "POTENGI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4339",
    DESCRICAO: "POTIRETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4340",
    DESCRICAO: "QUITERIANOPOLIS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4341",
    DESCRICAO: "QUIXADA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4342",
    DESCRICAO: "QUIXELO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4343",
    DESCRICAO: "QUIXERAMOBIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4344",
    DESCRICAO: "QUIXERE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4345",
    DESCRICAO: "REDENCAO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4346",
    DESCRICAO: "RERIUTABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4347",
    DESCRICAO: "RUSSAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4348",
    DESCRICAO: "SABOEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4349",
    DESCRICAO: "SALITRE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4350",
    DESCRICAO: "SANTANA DO ACARAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4351",
    DESCRICAO: "SANTANA DO CARIRI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4352",
    DESCRICAO: "SANTA QUITERIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4353",
    DESCRICAO: "SAO BENEDITO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4354",
    DESCRICAO: "SAO GONCALO DO AMARANTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4355",
    DESCRICAO: "SAO JOAO DO JAGUARIBE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4356",
    DESCRICAO: "SAO LUIS DO CURU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4357",
    DESCRICAO: "SENADOR POMPEU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4358",
    DESCRICAO: "SENADOR SA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4359",
    DESCRICAO: "SOBRAL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4360",
    DESCRICAO: "SOLONOPOLE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4361",
    DESCRICAO: "TABULEIRO DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4362",
    DESCRICAO: "TAMBORIL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4363",
    DESCRICAO: "TARRAFAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4364",
    DESCRICAO: "TAUA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4365",
    DESCRICAO: "TEJUCUOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4366",
    DESCRICAO: "TIANGUA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4367",
    DESCRICAO: "TRAIRI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4368",
    DESCRICAO: "TURURU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4369",
    DESCRICAO: "UBAJARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4370",
    DESCRICAO: "UMARI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4371",
    DESCRICAO: "UMIRIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4372",
    DESCRICAO: "URUBURETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4373",
    DESCRICAO: "URUOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4374",
    DESCRICAO: "VARJOTA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4375",
    DESCRICAO: "VARZEA ALEGRE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4376",
    DESCRICAO: "VICOSA DO CEARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4377",
    DESCRICAO: "ABAETETUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4378",
    DESCRICAO: "ABEL FIGUEIREDO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4379",
    DESCRICAO: "ACARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4380",
    DESCRICAO: "AFUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4381",
    DESCRICAO: "AGUA AZUL DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4382",
    DESCRICAO: "ALENQUER",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4383",
    DESCRICAO: "ALMEIRIM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4384",
    DESCRICAO: "ALTAMIRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4385",
    DESCRICAO: "ANAJAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4386",
    DESCRICAO: "ANANINDEUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4387",
    DESCRICAO: "ANAPU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4388",
    DESCRICAO: "AUGUSTO CORREA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4389",
    DESCRICAO: "AURORA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4390",
    DESCRICAO: "AVEIRO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4391",
    DESCRICAO: "BAGRE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4392",
    DESCRICAO: "BAIAO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4393",
    DESCRICAO: "BANNACH",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4394",
    DESCRICAO: "BARCARENA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4395",
    DESCRICAO: "BELEM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4396",
    DESCRICAO: "BELTERRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4397",
    DESCRICAO: "BENEVIDES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4398",
    DESCRICAO: "BOM JESUS DO TOCANTINS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4399",
    DESCRICAO: "BONITO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4400",
    DESCRICAO: "BRAGANCA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4401",
    DESCRICAO: "BRASIL NOVO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4402",
    DESCRICAO: "BREJO GRANDE DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4403",
    DESCRICAO: "BREU BRANCO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4404",
    DESCRICAO: "BREVES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4405",
    DESCRICAO: "BUJARU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4406",
    DESCRICAO: "CACHOEIRA DO PIRIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4407",
    DESCRICAO: "CACHOEIRA DO ARARI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4408",
    DESCRICAO: "CAMETA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4409",
    DESCRICAO: "CANAA DOS CARAJAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4410",
    DESCRICAO: "CAPANEMA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4411",
    DESCRICAO: "CAPITAO POCO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4412",
    DESCRICAO: "CASTANHAL",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4413",
    DESCRICAO: "CHAVES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4414",
    DESCRICAO: "COLARES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4415",
    DESCRICAO: "CONCEICAO DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4416",
    DESCRICAO: "CONCORDIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4417",
    DESCRICAO: "CUMARU DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4418",
    DESCRICAO: "CURIONOPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4419",
    DESCRICAO: "CURRALINHO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4420",
    DESCRICAO: "CURUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4421",
    DESCRICAO: "CURUCA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4422",
    DESCRICAO: "DOM ELISEU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4423",
    DESCRICAO: "ELDORADO DOS CARAJAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4424",
    DESCRICAO: "FARO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4425",
    DESCRICAO: "FLORESTA DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4426",
    DESCRICAO: "GARRAFAO DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4427",
    DESCRICAO: "GOIANESIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4428",
    DESCRICAO: "GURUPA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4429",
    DESCRICAO: "IGARAPE-ACU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4430",
    DESCRICAO: "IGARAPE-MIRI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4431",
    DESCRICAO: "INHANGAPI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4432",
    DESCRICAO: "IPIXUNA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4433",
    DESCRICAO: "IRITUIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4434",
    DESCRICAO: "ITAITUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4435",
    DESCRICAO: "ITUPIRANGA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4436",
    DESCRICAO: "JACAREACANGA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4437",
    DESCRICAO: "JACUNDA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4438",
    DESCRICAO: "JURUTI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4439",
    DESCRICAO: "LIMOEIRO DO AJURU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4440",
    DESCRICAO: "MAE DO RIO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4441",
    DESCRICAO: "MAGALHAES BARATA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4442",
    DESCRICAO: "MARABA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4443",
    DESCRICAO: "MARACANA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4444",
    DESCRICAO: "MARAPANIM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4445",
    DESCRICAO: "MARITUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4446",
    DESCRICAO: "MEDICILANDIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4447",
    DESCRICAO: "MELGACO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4448",
    DESCRICAO: "MOCAJUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4449",
    DESCRICAO: "MOJU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4450",
    DESCRICAO: "MONTE ALEGRE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4451",
    DESCRICAO: "MUANA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4452",
    DESCRICAO: "NOVA ESPERANCA DO PIRIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4453",
    DESCRICAO: "NOVA IPIXUNA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4454",
    DESCRICAO: "NOVA TIMBOTEUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4455",
    DESCRICAO: "NOVO PROGRESSO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4456",
    DESCRICAO: "NOVO REPARTIMENTO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4457",
    DESCRICAO: "OBIDOS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4458",
    DESCRICAO: "OEIRAS DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4459",
    DESCRICAO: "ORIXIMINA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4460",
    DESCRICAO: "OUREM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4461",
    DESCRICAO: "OURILANDIA DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4462",
    DESCRICAO: "PACAJA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4463",
    DESCRICAO: "PALESTINA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4464",
    DESCRICAO: "PARAGOMINAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4465",
    DESCRICAO: "PARAUAPEBAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4466",
    DESCRICAO: "PAU DARCO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4467",
    DESCRICAO: "PEIXE-BOI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4468",
    DESCRICAO: "PICARRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4469",
    DESCRICAO: "PLACAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4470",
    DESCRICAO: "PONTA DE PEDRAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4471",
    DESCRICAO: "PORTEL",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4472",
    DESCRICAO: "PORTO DE MOZ",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4473",
    DESCRICAO: "PRAINHA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4474",
    DESCRICAO: "PRIMAVERA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4475",
    DESCRICAO: "QUATIPURU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4476",
    DESCRICAO: "REDENCAO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4477",
    DESCRICAO: "RIO MARIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4478",
    DESCRICAO: "RONDON DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4479",
    DESCRICAO: "RUROPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4480",
    DESCRICAO: "SALINOPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4481",
    DESCRICAO: "SALVATERRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4482",
    DESCRICAO: "SANTA BARBARA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4483",
    DESCRICAO: "SANTA CRUZ DO ARARI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4484",
    DESCRICAO: "SANTA ISABEL DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4485",
    DESCRICAO: "SANTA LUZIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4486",
    DESCRICAO: "SANTA MARIA DAS BARREIRAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4487",
    DESCRICAO: "SANTA MARIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4488",
    DESCRICAO: "SANTANA DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4489",
    DESCRICAO: "SANTAREM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4490",
    DESCRICAO: "SANTAREM NOVO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4491",
    DESCRICAO: "SANTO ANTONIO DO TAUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4492",
    DESCRICAO: "SAO CAETANO DE ODIVELAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4493",
    DESCRICAO: "SAO DOMINGOS DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4494",
    DESCRICAO: "SAO DOMINGOS DO CAPIM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4495",
    DESCRICAO: "SAO FELIX DO XINGU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4496",
    DESCRICAO: "SAO FRANCISCO DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4497",
    DESCRICAO: "SAO GERALDO DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4498",
    DESCRICAO: "SAO JOAO DA PONTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4499",
    DESCRICAO: "SAO JOAO DE PIRABAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4500",
    DESCRICAO: "SAO JOAO DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4501",
    DESCRICAO: "SAO MIGUEL DO GUAMA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4502",
    DESCRICAO: "SAO SEBASTIAO DA BOA VISTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4503",
    DESCRICAO: "SAPUCAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4504",
    DESCRICAO: "SENADOR JOSE PORFIRIO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4505",
    DESCRICAO: "SOURE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4506",
    DESCRICAO: "TAILANDIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4507",
    DESCRICAO: "TERRA ALTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4508",
    DESCRICAO: "TERRA SANTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4509",
    DESCRICAO: "TOME-ACU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4510",
    DESCRICAO: "TRACUATEUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4511",
    DESCRICAO: "TRAIRAO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4512",
    DESCRICAO: "TUCUMA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4513",
    DESCRICAO: "TUCURUI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4514",
    DESCRICAO: "ULIANOPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4515",
    DESCRICAO: "URUARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4516",
    DESCRICAO: "VIGIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4517",
    DESCRICAO: "VISEU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4518",
    DESCRICAO: "VITORIA DO XINGU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4519",
    DESCRICAO: "XINGUARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4520",
    DESCRICAO: "ABREU E LIMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4521",
    DESCRICAO: "AFOGADOS DA INGAZEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4522",
    DESCRICAO: "AFRANIO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4523",
    DESCRICAO: "AGRESTINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4524",
    DESCRICAO: "AGUA PRETA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4525",
    DESCRICAO: "AGUAS BELAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4526",
    DESCRICAO: "ALAGOINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4527",
    DESCRICAO: "ALIANCA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4528",
    DESCRICAO: "ALTINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4529",
    DESCRICAO: "AMARAJI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4530",
    DESCRICAO: "ANGELIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4531",
    DESCRICAO: "ARACOIABA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4532",
    DESCRICAO: "ARARIPINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4533",
    DESCRICAO: "ARCOVERDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4534",
    DESCRICAO: "BARRA DE GUABIRABA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4535",
    DESCRICAO: "BARREIROS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4536",
    DESCRICAO: "BELEM DE MARIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4537",
    DESCRICAO: "BELEM DE SAO FRANCISCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4538",
    DESCRICAO: "BELO JARDIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4539",
    DESCRICAO: "BETANIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4540",
    DESCRICAO: "BEZERROS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4541",
    DESCRICAO: "BODOCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4542",
    DESCRICAO: "BOM CONSELHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4543",
    DESCRICAO: "BOM JARDIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4544",
    DESCRICAO: "BONITO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4545",
    DESCRICAO: "BREJAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4546",
    DESCRICAO: "BREJINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4547",
    DESCRICAO: "BREJO DA MADRE DE DEUS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4548",
    DESCRICAO: "BUENOS AIRES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4549",
    DESCRICAO: "BUIQUE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4550",
    DESCRICAO: "CABO DE SANTO AGOSTINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4551",
    DESCRICAO: "CABROBO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4552",
    DESCRICAO: "CACHOEIRINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4553",
    DESCRICAO: "CAETES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4554",
    DESCRICAO: "CALCADO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4555",
    DESCRICAO: "CALUMBI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4556",
    DESCRICAO: "CAMARAGIBE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4557",
    DESCRICAO: "CAMOCIM DE SAO FELIX",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4558",
    DESCRICAO: "CAMUTANGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4559",
    DESCRICAO: "CANHOTINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4560",
    DESCRICAO: "CAPOEIRAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4561",
    DESCRICAO: "CARNAIBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4562",
    DESCRICAO: "CARNAUBEIRA DA PENHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4563",
    DESCRICAO: "CARPINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4564",
    DESCRICAO: "CARUARU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4565",
    DESCRICAO: "CASINHAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4566",
    DESCRICAO: "CATENDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4567",
    DESCRICAO: "CEDRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4568",
    DESCRICAO: "CHA DE ALEGRIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4569",
    DESCRICAO: "CHA GRANDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4570",
    DESCRICAO: "CONDADO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4571",
    DESCRICAO: "CORRENTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4572",
    DESCRICAO: "CORTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4573",
    DESCRICAO: "CUMARU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4574",
    DESCRICAO: "CUPIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4575",
    DESCRICAO: "CUSTODIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4576",
    DESCRICAO: "DORMENTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4577",
    DESCRICAO: "ESCADA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4578",
    DESCRICAO: "EXU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4579",
    DESCRICAO: "FEIRA NOVA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4580",
    DESCRICAO: "FERNANDO DE NORONHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4581",
    DESCRICAO: "FERREIROS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4582",
    DESCRICAO: "FLORES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4583",
    DESCRICAO: "FLORESTA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4584",
    DESCRICAO: "FREI MIGUELINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4585",
    DESCRICAO: "GAMELEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4586",
    DESCRICAO: "GARANHUNS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4587",
    DESCRICAO: "GLORIA DO GOITA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4588",
    DESCRICAO: "GOIANA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4589",
    DESCRICAO: "GRANITO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4590",
    DESCRICAO: "GRAVATA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4591",
    DESCRICAO: "IATI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4592",
    DESCRICAO: "IBIMIRIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4593",
    DESCRICAO: "IBIRAJUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4594",
    DESCRICAO: "IGARASSU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4595",
    DESCRICAO: "IGUARACI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4596",
    DESCRICAO: "INAJA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4597",
    DESCRICAO: "INGAZEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4598",
    DESCRICAO: "IPOJUCA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4599",
    DESCRICAO: "IPUBI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4600",
    DESCRICAO: "ITACURUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4601",
    DESCRICAO: "ITAIBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4602",
    DESCRICAO: "ILHA DE ITAMARACA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4603",
    DESCRICAO: "ITAMBE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4604",
    DESCRICAO: "ITAPETIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4605",
    DESCRICAO: "ITAPISSUMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4606",
    DESCRICAO: "ITAQUITINGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4607",
    DESCRICAO: "JABOATAO DOS GUARARAPES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4608",
    DESCRICAO: "JAQUEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4609",
    DESCRICAO: "JATAUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4610",
    DESCRICAO: "JATOBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4611",
    DESCRICAO: "JOAO ALFREDO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4612",
    DESCRICAO: "JOAQUIM NABUCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4613",
    DESCRICAO: "JUCATI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4614",
    DESCRICAO: "JUPI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4615",
    DESCRICAO: "JUREMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4616",
    DESCRICAO: "LAGOA DO CARRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4617",
    DESCRICAO: "LAGOA DO ITAENGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4618",
    DESCRICAO: "LAGOA DO OURO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4619",
    DESCRICAO: "LAGOA DOS GATOS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4620",
    DESCRICAO: "LAGOA GRANDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4621",
    DESCRICAO: "LAJEDO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4622",
    DESCRICAO: "LIMOEIRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4623",
    DESCRICAO: "MACAPARANA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4624",
    DESCRICAO: "MACHADOS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4625",
    DESCRICAO: "MANARI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4626",
    DESCRICAO: "MARAIAL",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4627",
    DESCRICAO: "MIRANDIBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4628",
    DESCRICAO: "MORENO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4629",
    DESCRICAO: "NAZARE DA MATA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4630",
    DESCRICAO: "OLINDA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4631",
    DESCRICAO: "OROBO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4632",
    DESCRICAO: "OROCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4633",
    DESCRICAO: "OURICURI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4634",
    DESCRICAO: "PALMARES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4635",
    DESCRICAO: "PALMEIRINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4636",
    DESCRICAO: "PANELAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4637",
    DESCRICAO: "PARANATAMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4638",
    DESCRICAO: "PARNAMIRIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4639",
    DESCRICAO: "PASSIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4640",
    DESCRICAO: "PAUDALHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4641",
    DESCRICAO: "PAULISTA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4642",
    DESCRICAO: "PEDRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4643",
    DESCRICAO: "PESQUEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4644",
    DESCRICAO: "PETROLANDIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4645",
    DESCRICAO: "PETROLINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4646",
    DESCRICAO: "POCAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4647",
    DESCRICAO: "POMBOS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4648",
    DESCRICAO: "PRIMAVERA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4649",
    DESCRICAO: "QUIPAPA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4650",
    DESCRICAO: "QUIXABA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4651",
    DESCRICAO: "RECIFE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4652",
    DESCRICAO: "RIACHO DAS ALMAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4653",
    DESCRICAO: "RIBEIRAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4654",
    DESCRICAO: "RIO FORMOSO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4655",
    DESCRICAO: "SAIRE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4656",
    DESCRICAO: "SALGADINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4657",
    DESCRICAO: "SALGUEIRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4658",
    DESCRICAO: "SALOA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4659",
    DESCRICAO: "SANHARO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4660",
    DESCRICAO: "SANTA CRUZ",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4661",
    DESCRICAO: "SANTA CRUZ DA BAIXA VERDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4662",
    DESCRICAO: "SANTA CRUZ DO CAPIBARIBE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4663",
    DESCRICAO: "SANTA FILOMENA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4664",
    DESCRICAO: "SANTA MARIA DA BOA VISTA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4665",
    DESCRICAO: "SANTA MARIA DO CAMBUCA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4666",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4667",
    DESCRICAO: "SAO BENEDITO DO SUL",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4668",
    DESCRICAO: "SAO BENTO DO UNA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4669",
    DESCRICAO: "SAO CAITANO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4670",
    DESCRICAO: "SAO JOAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4671",
    DESCRICAO: "SAO JOAQUIM DO MONTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4672",
    DESCRICAO: "SAO JOSE DA COROA GRANDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4673",
    DESCRICAO: "SAO JOSE DO BELMONTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4674",
    DESCRICAO: "SAO JOSE DO EGITO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4675",
    DESCRICAO: "SAO LOURENCO DA MATA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4676",
    DESCRICAO: "SAO VICENTE FERRER",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4677",
    DESCRICAO: "SERRA TALHADA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4678",
    DESCRICAO: "SERRITA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4679",
    DESCRICAO: "SERTANIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4680",
    DESCRICAO: "SIRINHAEM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4681",
    DESCRICAO: "MOREILANDIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4682",
    DESCRICAO: "SOLIDAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4683",
    DESCRICAO: "SURUBIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4684",
    DESCRICAO: "TABIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4685",
    DESCRICAO: "TACAIMBO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4686",
    DESCRICAO: "TACARATU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4687",
    DESCRICAO: "TAMANDARE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4688",
    DESCRICAO: "TAQUARITINGA DO NORTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4689",
    DESCRICAO: "TEREZINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4690",
    DESCRICAO: "TERRA NOVA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4691",
    DESCRICAO: "TIMBAUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4692",
    DESCRICAO: "TORITAMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4693",
    DESCRICAO: "TRACUNHAEM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4694",
    DESCRICAO: "TRINDADE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4695",
    DESCRICAO: "TRIUNFO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4696",
    DESCRICAO: "TUPANATINGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4697",
    DESCRICAO: "TUPARETAMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4698",
    DESCRICAO: "VENTUROSA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4699",
    DESCRICAO: "VERDEJANTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4700",
    DESCRICAO: "VERTENTE DO LERIO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4701",
    DESCRICAO: "VERTENTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4702",
    DESCRICAO: "VICENCIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4703",
    DESCRICAO: "VITORIA DE SANTO ANTAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4704",
    DESCRICAO: "XEXEU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4705",
    DESCRICAO: "ABATIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4706",
    DESCRICAO: "ADRIANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4707",
    DESCRICAO: "AGUDOS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4708",
    DESCRICAO: "ALMIRANTE TAMANDARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4709",
    DESCRICAO: "ALTAMIRA DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4710",
    DESCRICAO: "ALTONIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4711",
    DESCRICAO: "ALTO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4712",
    DESCRICAO: "ALTO PIQUIRI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4713",
    DESCRICAO: "ALVORADA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4714",
    DESCRICAO: "AMAPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4715",
    DESCRICAO: "AMPERE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4716",
    DESCRICAO: "ANAHY",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4717",
    DESCRICAO: "ANDIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4718",
    DESCRICAO: "ANGULO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4719",
    DESCRICAO: "ANTONINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4720",
    DESCRICAO: "ANTONIO OLINTO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4721",
    DESCRICAO: "APUCARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4722",
    DESCRICAO: "ARAPONGAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4723",
    DESCRICAO: "ARAPOTI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4724",
    DESCRICAO: "ARAPUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4725",
    DESCRICAO: "ARARUNA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4726",
    DESCRICAO: "ARAUCARIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4727",
    DESCRICAO: "ARIRANHA DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4728",
    DESCRICAO: "ASSAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4729",
    DESCRICAO: "ASSIS CHATEAUBRIAND",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4730",
    DESCRICAO: "ASTORGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4731",
    DESCRICAO: "ATALAIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4732",
    DESCRICAO: "BALSA NOVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4733",
    DESCRICAO: "BANDEIRANTES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4734",
    DESCRICAO: "BARBOSA FERRAZ",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4735",
    DESCRICAO: "BARRACAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4736",
    DESCRICAO: "BARRA DO JACARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4737",
    DESCRICAO: "BELA VISTA DA CAROBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4738",
    DESCRICAO: "BELA VISTA DO PARAISO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4739",
    DESCRICAO: "BITURUNA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4740",
    DESCRICAO: "BOA ESPERANCA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4741",
    DESCRICAO: "BOA ESPERANCA DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4742",
    DESCRICAO: "BOA VENTURA DE SAO ROQUE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4743",
    DESCRICAO: "BOA VISTA DA APARECIDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4744",
    DESCRICAO: "BOCAIUVA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4745",
    DESCRICAO: "BOM JESUS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4746",
    DESCRICAO: "BOM SUCESSO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4747",
    DESCRICAO: "BOM SUCESSO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4748",
    DESCRICAO: "BORRAZOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4749",
    DESCRICAO: "BRAGANEY",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4750",
    DESCRICAO: "BRASILANDIA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4751",
    DESCRICAO: "CAFEARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4752",
    DESCRICAO: "CAFELANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4753",
    DESCRICAO: "CAFEZAL DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4754",
    DESCRICAO: "CALIFORNIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4755",
    DESCRICAO: "CAMBARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4756",
    DESCRICAO: "CAMBE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4757",
    DESCRICAO: "CAMBIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4758",
    DESCRICAO: "CAMPINA DA LAGOA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4759",
    DESCRICAO: "CAMPINA DO SIMAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4760",
    DESCRICAO: "CAMPINA GRANDE DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4761",
    DESCRICAO: "CAMPO BONITO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4762",
    DESCRICAO: "CAMPO DO TENENTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4763",
    DESCRICAO: "CAMPO LARGO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4764",
    DESCRICAO: "CAMPO MAGRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4765",
    DESCRICAO: "CAMPO MOURAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4766",
    DESCRICAO: "CANDIDO DE ABREU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4767",
    DESCRICAO: "CANDOI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4768",
    DESCRICAO: "CANTAGALO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4769",
    DESCRICAO: "CAPANEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4770",
    DESCRICAO: "CAPITAO LEONIDAS MARQUES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4771",
    DESCRICAO: "CARAMBEI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4772",
    DESCRICAO: "CARLOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4773",
    DESCRICAO: "CASCAVEL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4774",
    DESCRICAO: "CASTRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4775",
    DESCRICAO: "CATANDUVAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4776",
    DESCRICAO: "CENTENARIO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4777",
    DESCRICAO: "CERRO AZUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4778",
    DESCRICAO: "CEU AZUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4779",
    DESCRICAO: "CHOPINZINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4780",
    DESCRICAO: "CIANORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4781",
    DESCRICAO: "CIDADE GAUCHA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4782",
    DESCRICAO: "CLEVELANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4783",
    DESCRICAO: "COLOMBO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4784",
    DESCRICAO: "COLORADO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4785",
    DESCRICAO: "CONGONHINHAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4786",
    DESCRICAO: "CONSELHEIRO MAIRINCK",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4787",
    DESCRICAO: "CONTENDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4788",
    DESCRICAO: "CORBELIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4789",
    DESCRICAO: "CORNELIO PROCOPIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4790",
    DESCRICAO: "CORONEL DOMINGOS SOARES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4791",
    DESCRICAO: "CORONEL VIVIDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4792",
    DESCRICAO: "CORUMBATAI DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4793",
    DESCRICAO: "CRUZEIRO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4794",
    DESCRICAO: "CRUZEIRO DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4795",
    DESCRICAO: "CRUZEIRO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4796",
    DESCRICAO: "CRUZ MACHADO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4797",
    DESCRICAO: "CRUZMALTINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4798",
    DESCRICAO: "CURITIBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4799",
    DESCRICAO: "CURIUVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4800",
    DESCRICAO: "DIAMANTE DO NORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4801",
    DESCRICAO: "DIAMANTE DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4802",
    DESCRICAO: "DIAMANTE DOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4803",
    DESCRICAO: "DOIS VIZINHOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4804",
    DESCRICAO: "DOURADINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4805",
    DESCRICAO: "DOUTOR CAMARGO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4806",
    DESCRICAO: "ENEAS MARQUES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4807",
    DESCRICAO: "ENGENHEIRO BELTRAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4808",
    DESCRICAO: "ESPERANCA NOVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4809",
    DESCRICAO: "ENTRE RIOS DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4810",
    DESCRICAO: "ESPIGAO ALTO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4811",
    DESCRICAO: "FAROL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4812",
    DESCRICAO: "FAXINAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4813",
    DESCRICAO: "FAZENDA RIO GRANDE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4814",
    DESCRICAO: "FENIX",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4815",
    DESCRICAO: "FERNANDES PINHEIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4816",
    DESCRICAO: "FIGUEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4817",
    DESCRICAO: "FLORAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4818",
    DESCRICAO: "FLOR DA SERRA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4819",
    DESCRICAO: "FLORESTA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4820",
    DESCRICAO: "FLORESTOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4821",
    DESCRICAO: "FLORIDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4822",
    DESCRICAO: "FORMOSA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4823",
    DESCRICAO: "FOZ DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4824",
    DESCRICAO: "FRANCISCO ALVES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4825",
    DESCRICAO: "FRANCISCO BELTRAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4826",
    DESCRICAO: "FOZ DO JORDAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4827",
    DESCRICAO: "GENERAL CARNEIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4828",
    DESCRICAO: "GODOY MOREIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4829",
    DESCRICAO: "GOIOERE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4830",
    DESCRICAO: "GOIOXIM",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4831",
    DESCRICAO: "GRANDES RIOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4832",
    DESCRICAO: "GUAIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4833",
    DESCRICAO: "GUAIRACA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4834",
    DESCRICAO: "GUAMIRANGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4835",
    DESCRICAO: "GUAPIRAMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4836",
    DESCRICAO: "GUAPOREMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4837",
    DESCRICAO: "GUARACI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4838",
    DESCRICAO: "GUARANIACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4839",
    DESCRICAO: "GUARAPUAVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4840",
    DESCRICAO: "GUARAQUECABA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4841",
    DESCRICAO: "GUARATUBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4842",
    DESCRICAO: "HONORIO SERPA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4843",
    DESCRICAO: "IBAITI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4844",
    DESCRICAO: "IBEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4845",
    DESCRICAO: "IBIPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4846",
    DESCRICAO: "ICARAIMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4847",
    DESCRICAO: "IGUARACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4848",
    DESCRICAO: "IGUATU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4849",
    DESCRICAO: "IMBAU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4850",
    DESCRICAO: "IMBITUVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4851",
    DESCRICAO: "INACIO MARTINS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4852",
    DESCRICAO: "INAJA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4853",
    DESCRICAO: "INDIANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4854",
    DESCRICAO: "IPIRANGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4855",
    DESCRICAO: "IPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4856",
    DESCRICAO: "IRACEMA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4857",
    DESCRICAO: "IRATI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4858",
    DESCRICAO: "IRETAMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4859",
    DESCRICAO: "ITAGUAJE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4860",
    DESCRICAO: "ITAIPULANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4861",
    DESCRICAO: "ITAMBARACA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4862",
    DESCRICAO: "ITAMBE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4863",
    DESCRICAO: "ITAPEJARA DOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4864",
    DESCRICAO: "ITAPERUCU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4865",
    DESCRICAO: "ITAUNA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4866",
    DESCRICAO: "IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4867",
    DESCRICAO: "IVAIPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4868",
    DESCRICAO: "IVATE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4869",
    DESCRICAO: "IVATUBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4870",
    DESCRICAO: "JABOTI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4871",
    DESCRICAO: "JACAREZINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4872",
    DESCRICAO: "JAGUAPITA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4873",
    DESCRICAO: "JAGUARIAIVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4874",
    DESCRICAO: "JANDAIA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4875",
    DESCRICAO: "JANIOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4876",
    DESCRICAO: "JAPIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4877",
    DESCRICAO: "JAPURA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4878",
    DESCRICAO: "JARDIM ALEGRE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4879",
    DESCRICAO: "JARDIM OLINDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4880",
    DESCRICAO: "JATAIZINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4881",
    DESCRICAO: "JESUITAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4882",
    DESCRICAO: "JOAQUIM TAVORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4883",
    DESCRICAO: "JUNDIAI DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4884",
    DESCRICAO: "JURANDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4885",
    DESCRICAO: "JUSSARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4886",
    DESCRICAO: "KALORE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4887",
    DESCRICAO: "LAPA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4888",
    DESCRICAO: "LARANJAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4889",
    DESCRICAO: "LARANJEIRAS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4890",
    DESCRICAO: "LEOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4891",
    DESCRICAO: "LIDIANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4892",
    DESCRICAO: "LINDOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4893",
    DESCRICAO: "LOANDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4894",
    DESCRICAO: "LOBATO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4895",
    DESCRICAO: "LONDRINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4896",
    DESCRICAO: "LUIZIANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4897",
    DESCRICAO: "LUNARDELLI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4898",
    DESCRICAO: "LUPIONOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4899",
    DESCRICAO: "MALLET",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4900",
    DESCRICAO: "MAMBORE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4901",
    DESCRICAO: "MANDAGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4902",
    DESCRICAO: "MANDAGUARI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4903",
    DESCRICAO: "MANDIRITUBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4904",
    DESCRICAO: "MANFRINOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4905",
    DESCRICAO: "MANGUEIRINHA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4906",
    DESCRICAO: "MANOEL RIBAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4907",
    DESCRICAO: "MARECHAL CANDIDO RONDON",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4908",
    DESCRICAO: "MARIA HELENA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4909",
    DESCRICAO: "MARIALVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4910",
    DESCRICAO: "MARILANDIA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4911",
    DESCRICAO: "MARILENA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4912",
    DESCRICAO: "MARILUZ",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4913",
    DESCRICAO: "MARINGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4914",
    DESCRICAO: "MARIOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4915",
    DESCRICAO: "MARIPA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4916",
    DESCRICAO: "MARMELEIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4917",
    DESCRICAO: "MARQUINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4918",
    DESCRICAO: "MARUMBI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4919",
    DESCRICAO: "MATELANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4920",
    DESCRICAO: "MATINHOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4921",
    DESCRICAO: "MATO RICO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4922",
    DESCRICAO: "MAUA DA SERRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4923",
    DESCRICAO: "MEDIANEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4924",
    DESCRICAO: "MERCEDES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4925",
    DESCRICAO: "MIRADOR",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4926",
    DESCRICAO: "MIRASELVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4927",
    DESCRICAO: "MISSAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4928",
    DESCRICAO: "MOREIRA SALES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4929",
    DESCRICAO: "MORRETES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4930",
    DESCRICAO: "MUNHOZ DE MELO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4931",
    DESCRICAO: "NOSSA SENHORA DAS GRACAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4932",
    DESCRICAO: "NOVA ALIANCA DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4933",
    DESCRICAO: "NOVA AMERICA DA COLINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4934",
    DESCRICAO: "NOVA AURORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4935",
    DESCRICAO: "NOVA CANTU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4936",
    DESCRICAO: "NOVA ESPERANCA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4937",
    DESCRICAO: "NOVA ESPERANCA DO SUDOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4938",
    DESCRICAO: "NOVA FATIMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4939",
    DESCRICAO: "NOVA LARANJEIRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4940",
    DESCRICAO: "NOVA LONDRINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4941",
    DESCRICAO: "NOVA OLIMPIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4942",
    DESCRICAO: "NOVA SANTA BARBARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4943",
    DESCRICAO: "NOVA SANTA ROSA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4944",
    DESCRICAO: "NOVA PRATA DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4945",
    DESCRICAO: "NOVA TEBAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4946",
    DESCRICAO: "NOVO ITACOLOMI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4947",
    DESCRICAO: "ORTIGUEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4948",
    DESCRICAO: "OURIZONA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4949",
    DESCRICAO: "OURO VERDE DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4950",
    DESCRICAO: "PAICANDU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4951",
    DESCRICAO: "PALMAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4952",
    DESCRICAO: "PALMEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4953",
    DESCRICAO: "PALMITAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4954",
    DESCRICAO: "PALOTINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4955",
    DESCRICAO: "PARAISO DO NORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4956",
    DESCRICAO: "PARANACITY",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4957",
    DESCRICAO: "PARANAGUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4958",
    DESCRICAO: "PARANAPOEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4959",
    DESCRICAO: "PARANAVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4960",
    DESCRICAO: "PATO BRAGADO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4961",
    DESCRICAO: "PATO BRANCO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4962",
    DESCRICAO: "PAULA FREITAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4963",
    DESCRICAO: "PAULO FRONTIN",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4964",
    DESCRICAO: "PEABIRU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4965",
    DESCRICAO: "PEROBAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4966",
    DESCRICAO: "PEROLA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4967",
    DESCRICAO: "PEROLA DOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4968",
    DESCRICAO: "PIEN",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4969",
    DESCRICAO: "PINHAIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4970",
    DESCRICAO: "PINHALAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4971",
    DESCRICAO: "PINHAL DE SAO BENTO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4972",
    DESCRICAO: "PINHAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4973",
    DESCRICAO: "PIRAI DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4974",
    DESCRICAO: "PIRAQUARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4975",
    DESCRICAO: "PITANGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4976",
    DESCRICAO: "PITANGUEIRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4977",
    DESCRICAO: "PLANALTINA DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4978",
    DESCRICAO: "PLANALTO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4979",
    DESCRICAO: "PONTA GROSSA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4980",
    DESCRICAO: "PONTAL DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4981",
    DESCRICAO: "PORECATU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4982",
    DESCRICAO: "PORTO AMAZONAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4983",
    DESCRICAO: "PORTO BARREIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4984",
    DESCRICAO: "PORTO RICO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4985",
    DESCRICAO: "PORTO VITORIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4986",
    DESCRICAO: "PRADO FERREIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4987",
    DESCRICAO: "PRANCHITA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4988",
    DESCRICAO: "PRESIDENTE CASTELO BRANCO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4989",
    DESCRICAO: "PRIMEIRO DE MAIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4990",
    DESCRICAO: "PRUDENTOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4991",
    DESCRICAO: "QUARTO CENTENARIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4992",
    DESCRICAO: "QUATIGUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4993",
    DESCRICAO: "QUATRO BARRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4994",
    DESCRICAO: "QUATRO PONTES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4995",
    DESCRICAO: "QUEDAS DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4996",
    DESCRICAO: "QUERENCIA DO NORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4997",
    DESCRICAO: "QUINTA DO SOL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4998",
    DESCRICAO: "QUITANDINHA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4999",
    DESCRICAO: "RAMILANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5000",
    DESCRICAO: "RANCHO ALEGRE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5001",
    DESCRICAO: "RANCHO ALEGRE DOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5002",
    DESCRICAO: "REALEZA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5003",
    DESCRICAO: "REBOUCAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5004",
    DESCRICAO: "RENASCENCA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5005",
    DESCRICAO: "RESERVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5006",
    DESCRICAO: "RESERVA DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5007",
    DESCRICAO: "RIBEIRAO CLARO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5008",
    DESCRICAO: "RIBEIRAO DO PINHAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5009",
    DESCRICAO: "RIO AZUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5010",
    DESCRICAO: "RIO BOM",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5011",
    DESCRICAO: "RIO BONITO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5012",
    DESCRICAO: "RIO BRANCO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5013",
    DESCRICAO: "RIO BRANCO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5014",
    DESCRICAO: "RIO NEGRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5015",
    DESCRICAO: "ROLANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5016",
    DESCRICAO: "RONCADOR",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5017",
    DESCRICAO: "RONDON",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5018",
    DESCRICAO: "ROSARIO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5019",
    DESCRICAO: "SABAUDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5020",
    DESCRICAO: "SALGADO FILHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5021",
    DESCRICAO: "SALTO DO ITARARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5022",
    DESCRICAO: "SALTO DO LONTRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5023",
    DESCRICAO: "SANTA AMELIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5024",
    DESCRICAO: "SANTA CECILIA DO PAVAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5025",
    DESCRICAO: "SANTA CRUZ DE MONTE CASTELO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5026",
    DESCRICAO: "SANTA FE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5027",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5028",
    DESCRICAO: "SANTA INES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5029",
    DESCRICAO: "SANTA ISABEL DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5030",
    DESCRICAO: "SANTA IZABEL DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5031",
    DESCRICAO: "SANTA LUCIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5032",
    DESCRICAO: "SANTA MARIA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5033",
    DESCRICAO: "SANTA MARIANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5034",
    DESCRICAO: "SANTA MONICA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5035",
    DESCRICAO: "SANTANA DO ITARARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5036",
    DESCRICAO: "SANTA TEREZA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5037",
    DESCRICAO: "SANTA TEREZINHA DE ITAIPU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5038",
    DESCRICAO: "SANTO ANTONIO DA PLATINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5039",
    DESCRICAO: "SANTO ANTONIO DO CAIUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5040",
    DESCRICAO: "SANTO ANTONIO DO PARAISO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5041",
    DESCRICAO: "SANTO ANTONIO DO SUDOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5042",
    DESCRICAO: "SANTO INACIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5043",
    DESCRICAO: "SAO CARLOS DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5044",
    DESCRICAO: "SAO JERONIMO DA SERRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5045",
    DESCRICAO: "SAO JOAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5046",
    DESCRICAO: "SAO JOAO DO CAIUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5047",
    DESCRICAO: "SAO JOAO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5048",
    DESCRICAO: "SAO JOAO DO TRIUNFO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5049",
    DESCRICAO: "SAO JORGE DOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5050",
    DESCRICAO: "SAO JORGE DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5051",
    DESCRICAO: "SAO JORGE DO PATROCINIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5052",
    DESCRICAO: "SAO JOSE DA BOA VISTA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5053",
    DESCRICAO: "SAO JOSE DAS PALMEIRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5054",
    DESCRICAO: "SAO JOSE DOS PINHAIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5055",
    DESCRICAO: "SAO MANOEL DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5056",
    DESCRICAO: "SAO MATEUS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5057",
    DESCRICAO: "SAO MIGUEL DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5058",
    DESCRICAO: "SAO PEDRO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5059",
    DESCRICAO: "SAO PEDRO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5060",
    DESCRICAO: "SAO PEDRO DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5061",
    DESCRICAO: "SAO SEBASTIAO DA AMOREIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5062",
    DESCRICAO: "SAO TOME",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5063",
    DESCRICAO: "SAPOPEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5064",
    DESCRICAO: "SARANDI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5065",
    DESCRICAO: "SAUDADE DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5066",
    DESCRICAO: "SENGES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5067",
    DESCRICAO: "SERRANOPOLIS DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5068",
    DESCRICAO: "SERTANEJA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5069",
    DESCRICAO: "SERTANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5070",
    DESCRICAO: "SIQUEIRA CAMPOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5071",
    DESCRICAO: "SULINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5072",
    DESCRICAO: "TAMARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5073",
    DESCRICAO: "TAMBOARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5074",
    DESCRICAO: "TAPEJARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5075",
    DESCRICAO: "TAPIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5076",
    DESCRICAO: "TEIXEIRA SOARES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5077",
    DESCRICAO: "TELEMACO BORBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5078",
    DESCRICAO: "TERRA BOA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5079",
    DESCRICAO: "TERRA RICA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5080",
    DESCRICAO: "TERRA ROXA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5081",
    DESCRICAO: "TIBAGI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5082",
    DESCRICAO: "TIJUCAS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5083",
    DESCRICAO: "TOLEDO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5084",
    DESCRICAO: "TOMAZINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5085",
    DESCRICAO: "TRES BARRAS DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5086",
    DESCRICAO: "TUNAS DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5087",
    DESCRICAO: "TUNEIRAS DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5088",
    DESCRICAO: "TUPASSI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5089",
    DESCRICAO: "TURVO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5090",
    DESCRICAO: "UBIRATA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5091",
    DESCRICAO: "UMUARAMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5092",
    DESCRICAO: "UNIAO DA VITORIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5093",
    DESCRICAO: "UNIFLOR",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5094",
    DESCRICAO: "URAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5095",
    DESCRICAO: "WENCESLAU BRAZ",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5096",
    DESCRICAO: "VENTANIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5097",
    DESCRICAO: "VERA CRUZ DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5098",
    DESCRICAO: "VERE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5099",
    DESCRICAO: "ALTO PARAISO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5100",
    DESCRICAO: "DOUTOR ULYSSES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5101",
    DESCRICAO: "VIRMOND",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5102",
    DESCRICAO: "VITORINO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5103",
    DESCRICAO: "XAMBRE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5104",
    DESCRICAO: "ALTA FLORESTA DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5105",
    DESCRICAO: "ARIQUEMES",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5106",
    DESCRICAO: "CABIXI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5107",
    DESCRICAO: "CACOAL",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5108",
    DESCRICAO: "CEREJEIRAS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5109",
    DESCRICAO: "COLORADO DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5110",
    DESCRICAO: "CORUMBIARA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5111",
    DESCRICAO: "COSTA MARQUES",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5112",
    DESCRICAO: "ESPIGAO DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5113",
    DESCRICAO: "GUAJARA-MIRIM",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5114",
    DESCRICAO: "JARU",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5115",
    DESCRICAO: "JI-PARANA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5116",
    DESCRICAO: "MACHADINHO DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5117",
    DESCRICAO: "NOVA BRASILANDIA DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5118",
    DESCRICAO: "OURO PRETO DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5119",
    DESCRICAO: "PIMENTA BUENO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5120",
    DESCRICAO: "PORTO VELHO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5121",
    DESCRICAO: "PRESIDENTE MEDICI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5122",
    DESCRICAO: "RIO CRESPO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5123",
    DESCRICAO: "ROLIM DE MOURA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5124",
    DESCRICAO: "SANTA LUZIA DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5125",
    DESCRICAO: "VILHENA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5126",
    DESCRICAO: "SAO MIGUEL DO GUAPORE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5127",
    DESCRICAO: "NOVA MAMORE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5128",
    DESCRICAO: "ALVORADA DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5129",
    DESCRICAO: "ALTO ALEGRE DOS PARECIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5130",
    DESCRICAO: "ALTO PARAISO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5131",
    DESCRICAO: "BURITIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5132",
    DESCRICAO: "NOVO HORIZONTE DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5133",
    DESCRICAO: "CACAULANDIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5134",
    DESCRICAO: "CAMPO NOVO DE RONDONIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5135",
    DESCRICAO: "CANDEIAS DO JAMARI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5136",
    DESCRICAO: "CASTANHEIRAS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5137",
    DESCRICAO: "CHUPINGUAIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5138",
    DESCRICAO: "CUJUBIM",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5139",
    DESCRICAO: "GOVERNADOR JORGE TEIXEIRA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5140",
    DESCRICAO: "ITAPUA DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5141",
    DESCRICAO: "MINISTRO ANDREAZZA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5142",
    DESCRICAO: "MIRANTE DA SERRA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5143",
    DESCRICAO: "MONTE NEGRO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5144",
    DESCRICAO: "NOVA UNIAO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5145",
    DESCRICAO: "PARECIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5146",
    DESCRICAO: "PIMENTEIRAS DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5147",
    DESCRICAO: "PRIMAVERA DE RONDONIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5148",
    DESCRICAO: "SAO FELIPE DOESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5149",
    DESCRICAO: "SAO FRANCISCO DO GUAPORE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5150",
    DESCRICAO: "SERINGUEIRAS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5151",
    DESCRICAO: "TEIXEIROPOLIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5152",
    DESCRICAO: "THEOBROMA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5153",
    DESCRICAO: "URUPA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5154",
    DESCRICAO: "VALE DO ANARI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5155",
    DESCRICAO: "VALE DO PARAISO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5156",
    DESCRICAO: "ABREULANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5157",
    DESCRICAO: "AGUIARNOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5158",
    DESCRICAO: "ALIANCA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5159",
    DESCRICAO: "ALMAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5160",
    DESCRICAO: "ALVORADA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5161",
    DESCRICAO: "ANANAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5162",
    DESCRICAO: "ANGICO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5163",
    DESCRICAO: "APARECIDA DO RIO NEGRO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5164",
    DESCRICAO: "ARAGOMINAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5165",
    DESCRICAO: "ARAGUACEMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5166",
    DESCRICAO: "ARAGUACU",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5167",
    DESCRICAO: "ARAGUAINA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5168",
    DESCRICAO: "ARAGUANA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5169",
    DESCRICAO: "ARAGUATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5170",
    DESCRICAO: "ARAPOEMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5171",
    DESCRICAO: "ARRAIAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5172",
    DESCRICAO: "AUGUSTINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5173",
    DESCRICAO: "AURORA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5174",
    DESCRICAO: "AXIXA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5175",
    DESCRICAO: "BABACULANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5176",
    DESCRICAO: "BANDEIRANTES DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5177",
    DESCRICAO: "BARRA DO OURO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5178",
    DESCRICAO: "BARROLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5179",
    DESCRICAO: "BERNARDO SAYAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5180",
    DESCRICAO: "BOM JESUS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5181",
    DESCRICAO: "BRASILANDIA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5182",
    DESCRICAO: "BREJINHO DE NAZARE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5183",
    DESCRICAO: "BURITI DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5184",
    DESCRICAO: "CACHOEIRINHA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5185",
    DESCRICAO: "CAMPOS LINDOS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5186",
    DESCRICAO: "CARIRI DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5187",
    DESCRICAO: "CARMOLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5188",
    DESCRICAO: "CARRASCO BONITO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5189",
    DESCRICAO: "CASEARA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5190",
    DESCRICAO: "CENTENARIO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5191",
    DESCRICAO: "CHAPADA DE AREIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5192",
    DESCRICAO: "CHAPADA DA NATIVIDADE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5193",
    DESCRICAO: "COLINAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5194",
    DESCRICAO: "COMBINADO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5195",
    DESCRICAO: "CONCEICAO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5196",
    DESCRICAO: "COUTO DE MAGALHAES",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5197",
    DESCRICAO: "CRISTALANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5198",
    DESCRICAO: "CRIXAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5199",
    DESCRICAO: "DARCINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5200",
    DESCRICAO: "DIANOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5201",
    DESCRICAO: "DIVINOPOLIS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5202",
    DESCRICAO: "DOIS IRMAOS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5203",
    DESCRICAO: "DUERE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5204",
    DESCRICAO: "ESPERANTINA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5205",
    DESCRICAO: "FATIMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5206",
    DESCRICAO: "FIGUEIROPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5207",
    DESCRICAO: "FILADELFIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5208",
    DESCRICAO: "FORMOSO DO ARAGUAIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5209",
    DESCRICAO: "FORTALEZA DO TABOCAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5210",
    DESCRICAO: "GOIANORTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5211",
    DESCRICAO: "GOIATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5212",
    DESCRICAO: "GUARAI",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5213",
    DESCRICAO: "GURUPI",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5214",
    DESCRICAO: "IPUEIRAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5215",
    DESCRICAO: "ITACAJA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5216",
    DESCRICAO: "ITAGUATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5217",
    DESCRICAO: "ITAPIRATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5218",
    DESCRICAO: "ITAPORA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5219",
    DESCRICAO: "JAU DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5220",
    DESCRICAO: "JUARINA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5221",
    DESCRICAO: "LAGOA DA CONFUSAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5222",
    DESCRICAO: "LAGOA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5223",
    DESCRICAO: "LAJEADO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5224",
    DESCRICAO: "LAVANDEIRA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5225",
    DESCRICAO: "LIZARDA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5226",
    DESCRICAO: "LUZINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5227",
    DESCRICAO: "MARIANOPOLIS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5228",
    DESCRICAO: "MATEIROS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5229",
    DESCRICAO: "MAURILANDIA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5230",
    DESCRICAO: "MIRACEMA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5231",
    DESCRICAO: "MIRANORTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5232",
    DESCRICAO: "MONTE DO CARMO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5233",
    DESCRICAO: "MONTE SANTO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5234",
    DESCRICAO: "PALMEIRAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5235",
    DESCRICAO: "MURICILANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5236",
    DESCRICAO: "NATIVIDADE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5237",
    DESCRICAO: "NAZARE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5238",
    DESCRICAO: "NOVA OLINDA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5239",
    DESCRICAO: "NOVA ROSALANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5240",
    DESCRICAO: "NOVO ACORDO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5241",
    DESCRICAO: "NOVO ALEGRE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5242",
    DESCRICAO: "NOVO JARDIM",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5243",
    DESCRICAO: "OLIVEIRA DE FATIMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5244",
    DESCRICAO: "PALMEIRANTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5245",
    DESCRICAO: "PALMEIROPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5246",
    DESCRICAO: "PARAISO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5247",
    DESCRICAO: "PARANA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5248",
    DESCRICAO: "PAU DARCO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5249",
    DESCRICAO: "PEDRO AFONSO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5250",
    DESCRICAO: "PEIXE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5251",
    DESCRICAO: "PEQUIZEIRO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5252",
    DESCRICAO: "COLMEIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5253",
    DESCRICAO: "PINDORAMA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5254",
    DESCRICAO: "PIRAQUE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5255",
    DESCRICAO: "PIUM",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5256",
    DESCRICAO: "PONTE ALTA DO BOM JESUS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5257",
    DESCRICAO: "PONTE ALTA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5258",
    DESCRICAO: "PORTO ALEGRE DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5259",
    DESCRICAO: "PORTO NACIONAL",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5260",
    DESCRICAO: "PRAIA NORTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5261",
    DESCRICAO: "PRESIDENTE KENNEDY",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5262",
    DESCRICAO: "PUGMIL",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5263",
    DESCRICAO: "RECURSOLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5264",
    DESCRICAO: "RIACHINHO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5265",
    DESCRICAO: "RIO DA CONCEICAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5266",
    DESCRICAO: "RIO DOS BOIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5267",
    DESCRICAO: "RIO SONO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5268",
    DESCRICAO: "SAMPAIO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5269",
    DESCRICAO: "SANDOLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5270",
    DESCRICAO: "SANTA FE DO ARAGUAIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5271",
    DESCRICAO: "SANTA MARIA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5272",
    DESCRICAO: "SANTA RITA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5273",
    DESCRICAO: "SANTA ROSA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5274",
    DESCRICAO: "SANTA TEREZA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5275",
    DESCRICAO: "SANTA TEREZINHA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5276",
    DESCRICAO: "SAO BENTO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5277",
    DESCRICAO: "SAO FELIX DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5278",
    DESCRICAO: "SAO MIGUEL DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5279",
    DESCRICAO: "SAO SALVADOR DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5280",
    DESCRICAO: "SAO SEBASTIAO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5281",
    DESCRICAO: "SAO VALERIO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5282",
    DESCRICAO: "SILVANOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5283",
    DESCRICAO: "SITIO NOVO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5284",
    DESCRICAO: "SUCUPIRA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5285",
    DESCRICAO: "TAGUATINGA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5286",
    DESCRICAO: "TAIPAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5287",
    DESCRICAO: "TALISMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5288",
    DESCRICAO: "PALMAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5289",
    DESCRICAO: "TOCANTINIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5290",
    DESCRICAO: "TOCANTINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5291",
    DESCRICAO: "TUPIRAMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5292",
    DESCRICAO: "TUPIRATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5293",
    DESCRICAO: "WANDERLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5294",
    DESCRICAO: "XAMBIOA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5295",
    DESCRICAO: "ACAILANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5296",
    DESCRICAO: "AFONSO CUNHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5297",
    DESCRICAO: "AGUA DOCE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5298",
    DESCRICAO: "ALCANTARA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5299",
    DESCRICAO: "ALDEIAS ALTAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5300",
    DESCRICAO: "ALTAMIRA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5301",
    DESCRICAO: "ALTO ALEGRE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5302",
    DESCRICAO: "ALTO ALEGRE DO PINDARE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5303",
    DESCRICAO: "ALTO PARNAIBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5304",
    DESCRICAO: "AMAPA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5305",
    DESCRICAO: "AMARANTE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5306",
    DESCRICAO: "ANAJATUBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5307",
    DESCRICAO: "ANAPURUS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5308",
    DESCRICAO: "APICUM-ACU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5309",
    DESCRICAO: "ARAGUANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5310",
    DESCRICAO: "ARAIOSES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5311",
    DESCRICAO: "ARAME",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5312",
    DESCRICAO: "ARARI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5313",
    DESCRICAO: "AXIXA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5314",
    DESCRICAO: "BACABAL",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5315",
    DESCRICAO: "BACABEIRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5316",
    DESCRICAO: "BACURI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5317",
    DESCRICAO: "BACURITUBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5318",
    DESCRICAO: "BALSAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5319",
    DESCRICAO: "BARAO DE GRAJAU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5320",
    DESCRICAO: "BARRA DO CORDA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5321",
    DESCRICAO: "BARREIRINHAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5322",
    DESCRICAO: "BELAGUA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5323",
    DESCRICAO: "BELA VISTA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5324",
    DESCRICAO: "BENEDITO LEITE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5325",
    DESCRICAO: "BEQUIMAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5326",
    DESCRICAO: "BERNARDO DO MEARIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5327",
    DESCRICAO: "BOA VISTA DO GURUPI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5328",
    DESCRICAO: "BOM JARDIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5329",
    DESCRICAO: "BOM JESUS DAS SELVAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5330",
    DESCRICAO: "BOM LUGAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5331",
    DESCRICAO: "BREJO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5332",
    DESCRICAO: "BREJO DE AREIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5333",
    DESCRICAO: "BURITI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5334",
    DESCRICAO: "BURITI BRAVO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5335",
    DESCRICAO: "BURITICUPU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5336",
    DESCRICAO: "BURITIRANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5337",
    DESCRICAO: "CACHOEIRA GRANDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5338",
    DESCRICAO: "CAJAPIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5339",
    DESCRICAO: "CAJARI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5340",
    DESCRICAO: "CAMPESTRE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5341",
    DESCRICAO: "CANDIDO MENDES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5342",
    DESCRICAO: "CANTANHEDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5343",
    DESCRICAO: "CAPINZAL DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5344",
    DESCRICAO: "CAROLINA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5345",
    DESCRICAO: "CARUTAPERA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5346",
    DESCRICAO: "CAXIAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5347",
    DESCRICAO: "CEDRAL",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5348",
    DESCRICAO: "CENTRAL DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5349",
    DESCRICAO: "CENTRO DO GUILHERME",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5350",
    DESCRICAO: "CENTRO NOVO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5351",
    DESCRICAO: "CHAPADINHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5352",
    DESCRICAO: "CIDELANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5353",
    DESCRICAO: "CODO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5354",
    DESCRICAO: "COELHO NETO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5355",
    DESCRICAO: "COLINAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5356",
    DESCRICAO: "CONCEICAO DO LAGO-ACU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5357",
    DESCRICAO: "COROATA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5358",
    DESCRICAO: "CURURUPU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5359",
    DESCRICAO: "DAVINOPOLIS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5360",
    DESCRICAO: "DOM PEDRO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5361",
    DESCRICAO: "DUQUE BACELAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5362",
    DESCRICAO: "ESPERANTINOPOLIS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5363",
    DESCRICAO: "ESTREITO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5364",
    DESCRICAO: "FEIRA NOVA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5365",
    DESCRICAO: "FERNANDO FALCAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5366",
    DESCRICAO: "FORMOSA DA SERRA NEGRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5367",
    DESCRICAO: "FORTALEZA DOS NOGUEIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5368",
    DESCRICAO: "FORTUNA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5369",
    DESCRICAO: "GODOFREDO VIANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5370",
    DESCRICAO: "GONCALVES DIAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5371",
    DESCRICAO: "GOVERNADOR ARCHER",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5372",
    DESCRICAO: "GOVERNADOR EDISON LOBAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5373",
    DESCRICAO: "GOVERNADOR EUGENIO BARROS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5374",
    DESCRICAO: "GOVERNADOR LUIZ ROCHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5375",
    DESCRICAO: "GOVERNADOR NEWTON BELLO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5376",
    DESCRICAO: "GOVERNADOR NUNES FREIRE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5377",
    DESCRICAO: "GRACA ARANHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5378",
    DESCRICAO: "GRAJAU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5379",
    DESCRICAO: "GUIMARAES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5380",
    DESCRICAO: "HUMBERTO DE CAMPOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5381",
    DESCRICAO: "ICATU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5382",
    DESCRICAO: "IGARAPE DO MEIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5383",
    DESCRICAO: "IGARAPE GRANDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5384",
    DESCRICAO: "IMPERATRIZ",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5385",
    DESCRICAO: "ITAIPAVA DO GRAJAU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5386",
    DESCRICAO: "ITAPECURU MIRIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5387",
    DESCRICAO: "ITINGA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5388",
    DESCRICAO: "JATOBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5389",
    DESCRICAO: "JENIPAPO DOS VIEIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5390",
    DESCRICAO: "JOAO LISBOA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5391",
    DESCRICAO: "JOSELANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5392",
    DESCRICAO: "JUNCO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5393",
    DESCRICAO: "LAGO DA PEDRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5394",
    DESCRICAO: "LAGO DO JUNCO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5395",
    DESCRICAO: "LAGO VERDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5396",
    DESCRICAO: "LAGOA DO MATO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5397",
    DESCRICAO: "LAGO DOS RODRIGUES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5398",
    DESCRICAO: "LAGOA GRANDE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5399",
    DESCRICAO: "LAJEADO NOVO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5400",
    DESCRICAO: "LIMA CAMPOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5401",
    DESCRICAO: "LORETO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5402",
    DESCRICAO: "LUIS DOMINGUES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5403",
    DESCRICAO: "MAGALHAES DE ALMEIDA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5404",
    DESCRICAO: "MARACACUME",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5405",
    DESCRICAO: "MARAJA DO SENA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5406",
    DESCRICAO: "MARANHAOZINHO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5407",
    DESCRICAO: "MATA ROMA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5408",
    DESCRICAO: "MATINHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5409",
    DESCRICAO: "MATOES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5410",
    DESCRICAO: "MATOES DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5411",
    DESCRICAO: "MILAGRES DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5412",
    DESCRICAO: "MIRADOR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5413",
    DESCRICAO: "MIRANDA DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5414",
    DESCRICAO: "MIRINZAL",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5415",
    DESCRICAO: "MONCAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5416",
    DESCRICAO: "MONTES ALTOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5417",
    DESCRICAO: "MORROS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5418",
    DESCRICAO: "NINA RODRIGUES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5419",
    DESCRICAO: "NOVA COLINAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5420",
    DESCRICAO: "NOVA IORQUE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5421",
    DESCRICAO: "NOVA OLINDA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5422",
    DESCRICAO: "OLHO DAGUA DAS CUNHAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5423",
    DESCRICAO: "OLINDA NOVA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5424",
    DESCRICAO: "PACO DO LUMIAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5425",
    DESCRICAO: "PALMEIRANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5426",
    DESCRICAO: "PARAIBANO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5427",
    DESCRICAO: "PARNARAMA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5428",
    DESCRICAO: "PASSAGEM FRANCA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5429",
    DESCRICAO: "PASTOS BONS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5430",
    DESCRICAO: "PAULINO NEVES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5431",
    DESCRICAO: "PAULO RAMOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5432",
    DESCRICAO: "PEDREIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5433",
    DESCRICAO: "PEDRO DO ROSARIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5434",
    DESCRICAO: "PENALVA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5435",
    DESCRICAO: "PERI MIRIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5436",
    DESCRICAO: "PERITORO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5437",
    DESCRICAO: "PINDARE-MIRIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5438",
    DESCRICAO: "PINHEIRO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5439",
    DESCRICAO: "PIO XII",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5440",
    DESCRICAO: "PIRAPEMAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5441",
    DESCRICAO: "POCAO DE PEDRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5442",
    DESCRICAO: "PORTO FRANCO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5443",
    DESCRICAO: "PORTO RICO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5444",
    DESCRICAO: "PRESIDENTE DUTRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5445",
    DESCRICAO: "PRESIDENTE JUSCELINO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5446",
    DESCRICAO: "PRESIDENTE MEDICI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5447",
    DESCRICAO: "PRESIDENTE SARNEY",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5448",
    DESCRICAO: "PRESIDENTE VARGAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5449",
    DESCRICAO: "PRIMEIRA CRUZ",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5450",
    DESCRICAO: "RAPOSA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5451",
    DESCRICAO: "RIACHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5452",
    DESCRICAO: "RIBAMAR FIQUENE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5453",
    DESCRICAO: "ROSARIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5454",
    DESCRICAO: "SAMBAIBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5455",
    DESCRICAO: "SANTA FILOMENA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5456",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5457",
    DESCRICAO: "SANTA INES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5458",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5459",
    DESCRICAO: "SANTA LUZIA DO PARUA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5460",
    DESCRICAO: "SANTA QUITERIA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5461",
    DESCRICAO: "SANTA RITA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5462",
    DESCRICAO: "SANTANA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5463",
    DESCRICAO: "SANTO AMARO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5464",
    DESCRICAO: "SANTO ANTONIO DOS LOPES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5465",
    DESCRICAO: "SAO BENEDITO DO RIO PRETO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5466",
    DESCRICAO: "SAO BENTO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5467",
    DESCRICAO: "SAO BERNARDO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5468",
    DESCRICAO: "SAO DOMINGOS DO AZEITAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5469",
    DESCRICAO: "SAO DOMINGOS DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5470",
    DESCRICAO: "SAO FELIX DE BALSAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5471",
    DESCRICAO: "SAO FRANCISCO DO BREJAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5472",
    DESCRICAO: "SAO FRANCISCO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5473",
    DESCRICAO: "SAO JOAO BATISTA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5474",
    DESCRICAO: "SAO JOAO DO CARU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5475",
    DESCRICAO: "SAO JOAO DO PARAISO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5476",
    DESCRICAO: "SAO JOAO DO SOTER",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5477",
    DESCRICAO: "SAO JOAO DOS PATOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5478",
    DESCRICAO: "SAO JOSE DE RIBAMAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5479",
    DESCRICAO: "SAO JOSE DOS BASILIOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5480",
    DESCRICAO: "SAO LUIS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5481",
    DESCRICAO: "SAO LUIS GONZAGA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5482",
    DESCRICAO: "SAO MATEUS DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5483",
    DESCRICAO: "SAO PEDRO DA AGUA BRANCA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5484",
    DESCRICAO: "SAO PEDRO DOS CRENTES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5485",
    DESCRICAO: "SAO RAIMUNDO DAS MANGABEIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5486",
    DESCRICAO: "SAO RAIMUNDO DO DOCA BEZERRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5487",
    DESCRICAO: "SAO ROBERTO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5488",
    DESCRICAO: "SAO VICENTE FERRER",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5489",
    DESCRICAO: "SATUBINHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5490",
    DESCRICAO: "SENADOR ALEXANDRE COSTA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5491",
    DESCRICAO: "SENADOR LA ROCQUE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5492",
    DESCRICAO: "SERRANO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5493",
    DESCRICAO: "SITIO NOVO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5494",
    DESCRICAO: "SUCUPIRA DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5495",
    DESCRICAO: "SUCUPIRA DO RIACHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5496",
    DESCRICAO: "TASSO FRAGOSO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5497",
    DESCRICAO: "TIMBIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5498",
    DESCRICAO: "TIMON",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5499",
    DESCRICAO: "TRIZIDELA DO VALE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5500",
    DESCRICAO: "TUFILANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5501",
    DESCRICAO: "TUNTUM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5502",
    DESCRICAO: "TURIACU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5503",
    DESCRICAO: "TURILANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5504",
    DESCRICAO: "TUTOIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5505",
    DESCRICAO: "URBANO SANTOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5506",
    DESCRICAO: "VARGEM GRANDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5507",
    DESCRICAO: "VIANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5508",
    DESCRICAO: "VILA NOVA DOS MARTIRIOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5509",
    DESCRICAO: "VITORIA DO MEARIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5510",
    DESCRICAO: "VITORINO FREIRE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5511",
    DESCRICAO: "ZE DOCA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5512",
    DESCRICAO: "SERRA DO NAVIO",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5513",
    DESCRICAO: "AMAPA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5514",
    DESCRICAO: "PEDRA BRANCA DO AMAPARI",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5515",
    DESCRICAO: "CALCOENE",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5516",
    DESCRICAO: "CUTIAS",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5517",
    DESCRICAO: "FERREIRA GOMES",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5518",
    DESCRICAO: "ITAUBAL",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5519",
    DESCRICAO: "LARANJAL DO JARI",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5520",
    DESCRICAO: "MACAPA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5521",
    DESCRICAO: "MAZAGAO",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5522",
    DESCRICAO: "OIAPOQUE",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5523",
    DESCRICAO: "PORTO GRANDE",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5524",
    DESCRICAO: "PRACUUBA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5525",
    DESCRICAO: "SANTANA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5526",
    DESCRICAO: "TARTARUGALZINHO",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5527",
    DESCRICAO: "VITORIA DO JARI",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5528",
    DESCRICAO: "AMAJARI",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5529",
    DESCRICAO: "ALTO ALEGRE",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5530",
    DESCRICAO: "BOA VISTA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5531",
    DESCRICAO: "BONFIM",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5532",
    DESCRICAO: "CANTA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5533",
    DESCRICAO: "CARACARAI",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5534",
    DESCRICAO: "CAROEBE",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5535",
    DESCRICAO: "IRACEMA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5536",
    DESCRICAO: "MUCAJAI",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5537",
    DESCRICAO: "NORMANDIA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5538",
    DESCRICAO: "PACARAIMA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5539",
    DESCRICAO: "RORAINOPOLIS",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5540",
    DESCRICAO: "SAO JOAO DA BALIZA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5541",
    DESCRICAO: "SAO LUIZ",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5542",
    DESCRICAO: "UIRAMUTA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5543",
    DESCRICAO: "ACRELANDIA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5544",
    DESCRICAO: "ASSIS BRASIL",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5545",
    DESCRICAO: "BRASILEIA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5546",
    DESCRICAO: "BUJARI",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5547",
    DESCRICAO: "CAPIXABA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5548",
    DESCRICAO: "CRUZEIRO DO SUL",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5549",
    DESCRICAO: "EPITACIOLANDIA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5550",
    DESCRICAO: "FEIJO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5551",
    DESCRICAO: "JORDAO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5552",
    DESCRICAO: "MANCIO LIMA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5553",
    DESCRICAO: "MANOEL URBANO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5554",
    DESCRICAO: "MARECHAL THAUMATURGO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5555",
    DESCRICAO: "PLACIDO DE CASTRO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5556",
    DESCRICAO: "PORTO WALTER",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5557",
    DESCRICAO: "RIO BRANCO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5558",
    DESCRICAO: "RODRIGUES ALVES",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5559",
    DESCRICAO: "SANTA ROSA DO PURUS",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5560",
    DESCRICAO: "SENADOR GUIOMARD",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5561",
    DESCRICAO: "SENA MADUREIRA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5562",
    DESCRICAO: "TARAUACA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5563",
    DESCRICAO: "XAPURI",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5564",
    DESCRICAO: "PORTO ACRE",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5565",
    DESCRICAO: "PESCARIA BRAVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5566",
    DESCRICAO: "JOCA CLAUDINO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5567",
    DESCRICAO: "PARAISO DAS AGUAS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5568",
    DESCRICAO: "SAO VICENTE DO SERIDO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5569",
    DESCRICAO: "PINTO BANDEIRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5570",
    DESCRICAO: "BALNEARIO RINCAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5571",
    DESCRICAO: "MOJUI DOS CAMPOS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5572",
    DESCRICAO: "TACIMA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5573",
    DESCRICAO: "NAZARIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3188",
    DESCRICAO: "AFEGANE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3189",
    DESCRICAO: "ALBANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3190",
    DESCRICAO: "ALEMÃ",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3191",
    DESCRICAO: "ANDORRANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3192",
    DESCRICAO: "ANGOLANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3193",
    DESCRICAO: "ANGUILANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3194",
    DESCRICAO: "ANTIGUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3195",
    DESCRICAO: "ARABE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3196",
    DESCRICAO: "ARGELINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3197",
    DESCRICAO: "ARGENTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3198",
    DESCRICAO: "ARMENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3199",
    DESCRICAO: "ARUBANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3200",
    DESCRICAO: "AUSTRALIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3201",
    DESCRICAO: "AUSTRIACA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3202",
    DESCRICAO: "AZERBAIDJANO",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3203",
    DESCRICAO: "BAHAMENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3204",
    DESCRICAO: "BARBADIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3205",
    DESCRICAO: "BARENITA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3206",
    DESCRICAO: "BELGA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3207",
    DESCRICAO: "BELIZENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3208",
    DESCRICAO: "BENGALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3209",
    DESCRICAO: "BENINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3210",
    DESCRICAO: "BERMUDIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3211",
    DESCRICAO: "BIELORRUSSA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3212",
    DESCRICAO: "BIRMANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3213",
    DESCRICAO: "BOLIVIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3214",
    DESCRICAO: "BÓSNIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3215",
    DESCRICAO: "BOTSUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3216",
    DESCRICAO: "BRASILEIRA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3217",
    DESCRICAO: "BRITÂNICA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3218",
    DESCRICAO: "BRUNEIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3219",
    DESCRICAO: "BÚLGARA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3220",
    DESCRICAO: "BURQUINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3221",
    DESCRICAO: "BURUNDINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3222",
    DESCRICAO: "BUTANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3223",
    DESCRICAO: "CABO-VERDIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3224",
    DESCRICAO: "CAIMANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3225",
    DESCRICAO: "CAMARONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3226",
    DESCRICAO: "CAMBOJANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3227",
    DESCRICAO: "CANADENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3228",
    DESCRICAO: "CATARIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3229",
    DESCRICAO: "CAZAQUE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3230",
    DESCRICAO: "CENTRO-AFRICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3231",
    DESCRICAO: "CHADIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3232",
    DESCRICAO: "CHILENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3233",
    DESCRICAO: "CHINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3234",
    DESCRICAO: "CHIPREANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3235",
    DESCRICAO: "CINGALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3236",
    DESCRICAO: "COLOMBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3237",
    DESCRICAO: "COMORENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3238",
    DESCRICAO: "CONGOLESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3239",
    DESCRICAO: "COOKENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3240",
    DESCRICAO: "COSTARRIQUENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3241",
    DESCRICAO: "COVEITIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3242",
    DESCRICAO: "CROATA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3243",
    DESCRICAO: "CUBANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3244",
    DESCRICAO: "DINAMARQUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3245",
    DESCRICAO: "DJIBUTIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3246",
    DESCRICAO: "DOMINICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3247",
    DESCRICAO: "EGIPCIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3248",
    DESCRICAO: "EQUATORIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3249",
    DESCRICAO: "ERITREIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3250",
    DESCRICAO: "ESLOVACA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3251",
    DESCRICAO: "ESLOVENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3252",
    DESCRICAO: "ESPANHOLA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3253",
    DESCRICAO: "ESTONIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3254",
    DESCRICAO: "ETIOPE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3255",
    DESCRICAO: "FEROESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3256",
    DESCRICAO: "FIJIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3257",
    DESCRICAO: "FILIPINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3258",
    DESCRICAO: "FINLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3259",
    DESCRICAO: "FRANCESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3260",
    DESCRICAO: "GABONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3261",
    DESCRICAO: "GAMBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3262",
    DESCRICAO: "GANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3263",
    DESCRICAO: "GEORGIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3264",
    DESCRICAO: "GIBRALTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3265",
    DESCRICAO: "GRANADINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3266",
    DESCRICAO: "GREGA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3267",
    DESCRICAO: "GROENLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3268",
    DESCRICAO: "GUADALUPENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3269",
    DESCRICAO: "GUAMESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3270",
    DESCRICAO: "GUATEMALTECA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3271",
    DESCRICAO: "GUERNESIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3272",
    DESCRICAO: "GUIANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3273",
    DESCRICAO: "GUIANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3274",
    DESCRICAO: "GUINEANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3275",
    DESCRICAO: "GUINEENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3276",
    DESCRICAO: "GUINÉU-EQUATORIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3277",
    DESCRICAO: "HAITIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3278",
    DESCRICAO: "HOLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3279",
    DESCRICAO: "HONDURENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3280",
    DESCRICAO: "HÚNGARA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3281",
    DESCRICAO: "IEMENITA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3282",
    DESCRICAO: "INDIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3283",
    DESCRICAO: "INDONÉSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3284",
    DESCRICAO: "IRANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3285",
    DESCRICAO: "IRAQUIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3286",
    DESCRICAO: "IRLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3287",
    DESCRICAO: "ISLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3288",
    DESCRICAO: "ISRAELENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3289",
    DESCRICAO: "ITALIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3290",
    DESCRICAO: "JAMAICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3291",
    DESCRICAO: "JAPONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3292",
    DESCRICAO: "JERSIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3293",
    DESCRICAO: "JORDANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3294",
    DESCRICAO: "KIRIBATIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3295",
    DESCRICAO: "LAOSIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3296",
    DESCRICAO: "LEONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3297",
    DESCRICAO: "LESOTA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3298",
    DESCRICAO: "LETÃ",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3299",
    DESCRICAO: "LIBANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3300",
    DESCRICAO: "LIBERIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3301",
    DESCRICAO: "LÍBIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3302",
    DESCRICAO: "LIECHTENSTEINIENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3303",
    DESCRICAO: "LITUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3304",
    DESCRICAO: "LUXEMBURGUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3305",
    DESCRICAO: "MACEDÔNIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3306",
    DESCRICAO: "MAIOTENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3307",
    DESCRICAO: "MALAIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3308",
    DESCRICAO: "MALAUIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3309",
    DESCRICAO: "MALDÍVIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3310",
    DESCRICAO: "MALGAXE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3311",
    DESCRICAO: "MALINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3312",
    DESCRICAO: "MALTESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3313",
    DESCRICAO: "MALVINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3314",
    DESCRICAO: "MANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3315",
    DESCRICAO: "MARFINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3316",
    DESCRICAO: "MARROQUINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3317",
    DESCRICAO: "MARSHALLINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3318",
    DESCRICAO: "MARTINICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3319",
    DESCRICAO: "MAURICIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3320",
    DESCRICAO: "MAURITANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3321",
    DESCRICAO: "MEXICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3322",
    DESCRICAO: "MICRONÉSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3323",
    DESCRICAO: "MOÇAMBICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3324",
    DESCRICAO: "MOLDÁVIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3325",
    DESCRICAO: "MONEGASCA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3326",
    DESCRICAO: "MONGOL",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3327",
    DESCRICAO: "MONSERRATENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3328",
    DESCRICAO: "MONTENEGRINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3329",
    DESCRICAO: "NAMIBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3330",
    DESCRICAO: "NAURUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3331",
    DESCRICAO: "NEOCALEDÔNIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3332",
    DESCRICAO: "NEOZELANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3333",
    DESCRICAO: "NEPALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3334",
    DESCRICAO: "NICARAGUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3335",
    DESCRICAO: "NIGERIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3336",
    DESCRICAO: "NIGERINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3337",
    DESCRICAO: "NIUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3338",
    DESCRICAO: "NORFOLKIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3339",
    DESCRICAO: "NORTEAMERICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3340",
    DESCRICAO: "NORTE-COREANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3341",
    DESCRICAO: "NORTE-MARIANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3342",
    DESCRICAO: "NORUEGUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3343",
    DESCRICAO: "OMANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3344",
    DESCRICAO: "PALAUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3345",
    DESCRICAO: "PALESTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3346",
    DESCRICAO: "PANAMENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3347",
    DESCRICAO: "PAPUÁSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3348",
    DESCRICAO: "PAQUISTANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3349",
    DESCRICAO: "PARAGUAIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3350",
    DESCRICAO: "PERUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3351",
    DESCRICAO: "PITCAIRNENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3352",
    DESCRICAO: "POLINÉSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3353",
    DESCRICAO: "POLONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3354",
    DESCRICAO: "PORTORRIQUENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3355",
    DESCRICAO: "PORTUGUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3356",
    DESCRICAO: "QUENIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3357",
    DESCRICAO: "QUIRGUISTANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3358",
    DESCRICAO: "REUNIONENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3359",
    DESCRICAO: "ROMENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3360",
    DESCRICAO: "RUANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3361",
    DESCRICAO: "RUSSA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3362",
    DESCRICAO: "SAARIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3363",
    DESCRICAO: "SALOMÔNICA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3364",
    DESCRICAO: "SALVADORENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3365",
    DESCRICAO: "SAMARINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3366",
    DESCRICAO: "SAMOANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3367",
    DESCRICAO: "SANTA-HELENENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3368",
    DESCRICAO: "SANTA-LUCENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3369",
    DESCRICAO: "SÃO-CRISTOVENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3370",
    DESCRICAO: "SÃO-PEDRO-MIQUELONENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3371",
    DESCRICAO: "SÃO-TOMENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3372",
    DESCRICAO: "SÃO-VICENTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3373",
    DESCRICAO: "SEICHELENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3374",
    DESCRICAO: "SENEGALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3375",
    DESCRICAO: "SERVIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3376",
    DESCRICAO: "SINGAPURENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3377",
    DESCRICAO: "SIRIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3378",
    DESCRICAO: "SOMALI",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3379",
    DESCRICAO: "SUAZI",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3380",
    DESCRICAO: "SUDANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3381",
    DESCRICAO: "SUECA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3382",
    DESCRICAO: "SUIÇA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3383",
    DESCRICAO: "SUL-AFRICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3384",
    DESCRICAO: "SUL-COREANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3385",
    DESCRICAO: "SURINAMESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3386",
    DESCRICAO: "TADJIQUE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3387",
    DESCRICAO: "TAILANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3388",
    DESCRICAO: "TAIUANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3389",
    DESCRICAO: "TANZANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3390",
    DESCRICAO: "TCHECA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3391",
    DESCRICAO: "TIMORENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3392",
    DESCRICAO: "TOBAGUIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3393",
    DESCRICAO: "TOGOLESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3394",
    DESCRICAO: "TONGANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3395",
    DESCRICAO: "TOQUELAUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3396",
    DESCRICAO: "TUNISIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3397",
    DESCRICAO: "TURCA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3398",
    DESCRICAO: "TURCO-CAIQUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3399",
    DESCRICAO: "TURCOMANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3400",
    DESCRICAO: "TUVALUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3401",
    DESCRICAO: "UCRANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3402",
    DESCRICAO: "UGANDENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3403",
    DESCRICAO: "URUGUAIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3404",
    DESCRICAO: "UZBEQUE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3405",
    DESCRICAO: "VANUATENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3406",
    DESCRICAO: "VATICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3407",
    DESCRICAO: "VENEZUELANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3408",
    DESCRICAO: "VIETNAMINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3409",
    DESCRICAO: "VIRGINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3410",
    DESCRICAO: "ZAMBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3411",
    DESCRICAO: "ZIMBABUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "5024",
    DESCRICAO: "SUDANESA DO SUL",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4138",
    DESCRICAO: "Empres. fat/ano acima R$200MM",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4139",
    DESCRICAO: "Empres. fat/ano entre R$50 e R$200MM",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4140",
    DESCRICAO: "Empresário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4141",
    DESCRICAO: "Empres. fat/ano entre R$1 e R$10 MM",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4142",
    DESCRICAO: "Micro Empresário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4143",
    DESCRICAO: "Dir. e Dirig Geral de Emp Púb/Púb-Priv",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4144",
    DESCRICAO: "Diretor de empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4145",
    DESCRICAO: "Sup e Ger de Emp Púb/Púb-Priv",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4146",
    DESCRICAO: "Superintendente de empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4147",
    DESCRICAO: "Superv. e Coord de Emp. Púb/Púb-Priv",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4148",
    DESCRICAO: "Supervisor em empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4149",
    DESCRICAO: "Vereador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4150",
    DESCRICAO: "Prefeito",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4151",
    DESCRICAO: "Promotor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4152",
    DESCRICAO: "Funcionário do alto escalão do poder executivo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4153",
    DESCRICAO: "Engenheiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4154",
    DESCRICAO: "Pesquisador das Ciências Exatas, Humanas e Biológicas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4155",
    DESCRICAO: "Médico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4156",
    DESCRICAO: "Odontologista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4157",
    DESCRICAO: "Enfermeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4158",
    DESCRICAO: "Farmacêutico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4159",
    DESCRICAO: "Veterinário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4160",
    DESCRICAO: "Estilista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4161",
    DESCRICAO: "Cartógrafo e Agrimensor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4162",
    DESCRICAO: "Prof, Instr e Pedag Nível Sup",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4163",
    DESCRICAO: "Professor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4164",
    DESCRICAO: "Vendedor especializado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4165",
    DESCRICAO: "Comerciante",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4166",
    DESCRICAO: "Caixa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4167",
    DESCRICAO: "Assistente de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4168",
    DESCRICAO: "Assistente de nível técnico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4169",
    DESCRICAO: "Tecnólogo de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4170",
    DESCRICAO: "Tecnólogo de nível médio",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4171",
    DESCRICAO: "Advogado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4172",
    DESCRICAO: "Economista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4173",
    DESCRICAO: "Líder religioso",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4174",
    DESCRICAO: "Locutor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4175",
    DESCRICAO: "Jornalista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4176",
    DESCRICAO: "Tradutor e linguista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4177",
    DESCRICAO: "Profissionais das Artes",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4178",
    DESCRICAO: "Membro das forças armadas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4179",
    DESCRICAO: "Pç Forças Armadas, Polic e Bomb",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4180",
    DESCRICAO: "Agente da polícia civil ou federal",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4181",
    DESCRICAO: "Avaliador de bens e valores",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4182",
    DESCRICAO: "Agente financeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4183",
    DESCRICAO: "Piloto",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4184",
    DESCRICAO: "Atleta, esportista, treinador e arbitro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4185",
    DESCRICAO: "Treinadores e Arbitros Ativ Esp",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4186",
    DESCRICAO: "Fotógrafo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4187",
    DESCRICAO: "Chefe de cozinha",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4188",
    DESCRICAO: "Fazendeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4189",
    DESCRICAO: "Profissional qualificado do agronegócio",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4190",
    DESCRICAO: "Lavrador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4191",
    DESCRICAO: "Esteticista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4192",
    DESCRICAO: "Profissional  de manutenção , limpeza ou segurança",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4193",
    DESCRICAO: "Profissional de arte, publicidade ou moda",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4194",
    DESCRICAO: "Motorista especializado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4195",
    DESCRICAO: "Auxiliar administrativo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4196",
    DESCRICAO: "Funcionário de lotérica",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4197",
    DESCRICAO: "Operador de máquinas e equipamentos",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4198",
    DESCRICAO: "Cobrador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4199",
    DESCRICAO: "Agente de viagem",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4200",
    DESCRICAO: "Telefonista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4201",
    DESCRICAO: "Encarregado geral",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4202",
    DESCRICAO: "Operário constr,mecân,elétr",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4203",
    DESCRICAO: "Mecânico de motores, máquinas e equipamentos",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4204",
    DESCRICAO: "Funcionário dos correios",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4205",
    DESCRICAO: "Operador de telemarketing",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4206",
    DESCRICAO: "Doméstico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4207",
    DESCRICAO: "Faxineiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4208",
    DESCRICAO: "Servente ou ajudante construção civil",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4209",
    DESCRICAO: "Repositor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4210",
    DESCRICAO: "Demais ocupações não qualificadas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4233",
    DESCRICAO: "Tratador de animais",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4234",
    DESCRICAO: "Empacotador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4235",
    DESCRICAO: "Carregador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4236",
    DESCRICAO: "Jardineiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4237",
    DESCRICAO: "Recepcionista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4238",
    DESCRICAO: "Atendente",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4239",
    DESCRICAO: "Balconista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4240",
    DESCRICAO: "Vendedor de lojas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4241",
    DESCRICAO: "Aeroviário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4242",
    DESCRICAO: "Entregadores e operadores de logística",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4243",
    DESCRICAO: "Analista de nível técnico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4244",
    DESCRICAO: "Especialista de nível técnico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4245",
    DESCRICAO: "Instrutor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4246",
    DESCRICAO: "Pedagogo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4247",
    DESCRICAO: "Nutricionista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4248",
    DESCRICAO: "Fisioterapeuta",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4249",
    DESCRICAO: "Profissional qualificado do ramo estética",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4250",
    DESCRICAO: "Profissional qualificado do ramo alimentar",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4251",
    DESCRICAO: "Confeiteiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4252",
    DESCRICAO: "Assistente de cozinha",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4253",
    DESCRICAO: "Técnico e profissional qualificado da saúde",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4254",
    DESCRICAO: "Instrumentador cirúrgico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4255",
    DESCRICAO: "Protético",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4256",
    DESCRICAO: "Radiologista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4257",
    DESCRICAO: "Policial",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4258",
    DESCRICAO: "Bombeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4259",
    DESCRICAO: "Investigador da polícia civil ou federal",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4260",
    DESCRICAO: "Analista de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4261",
    DESCRICAO: "Especialista de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4262",
    DESCRICAO: "Delegado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4263",
    DESCRICAO: "Psicólogo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4264",
    DESCRICAO: "Fonoaudiólogo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4265",
    DESCRICAO: "Zootecnista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4266",
    DESCRICAO: "Agrônomo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4267",
    DESCRICAO: "Arquiteto",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4268",
    DESCRICAO: "Jurista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4269",
    DESCRICAO: "Corretor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4270",
    DESCRICAO: "Aeronauta",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4271",
    DESCRICAO: "Escritor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4272",
    DESCRICAO: "Desenhista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4273",
    DESCRICAO: "Decorador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4274",
    DESCRICAO: "Designer",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4275",
    DESCRICAO: "Produtor Rural",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4276",
    DESCRICAO: "Pecuarista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4277",
    DESCRICAO: "Administrador de empresas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4278",
    DESCRICAO: "Gerente geral de empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4279",
    DESCRICAO: "Deputado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4280",
    DESCRICAO: "Senador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4281",
    DESCRICAO: "Presidente",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4282",
    DESCRICAO: "Governador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4283",
    DESCRICAO: "Funcionário do alto escalão do poder legislativo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4284",
    DESCRICAO: "Funcionário do alto escalão do poder judiciário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4285",
    DESCRICAO: "Juiz",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4286",
    DESCRICAO: "Desembargador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4999",
    DESCRICAO: "Estudante",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "5020",
    DESCRICAO: "Aposentado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "5021",
    DESCRICAO: "Bancário",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "825",
    DESCRICAO: "PASSAPORTE",
    SIGLA: "PASS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "826",
    DESCRICAO: "ASSOCIAÇÃO BRASILEIRA DE EX-CONGRESSISTAS",
    SIGLA: "ABEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "827",
    DESCRICAO: "ASSEMBLEIA LEGISLATIVA",
    SIGLA: "ALEG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "828",
    DESCRICAO: "CAMARA MUNICIPAL DO RIO DE JANEIRO",
    SIGLA: "CMRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "829",
    DESCRICAO: "CARTORIO REG CIVIL PESSOAS NATURAIS",
    SIGLA: "CCPN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "830",
    DESCRICAO: "COMISSÃO NACIONAL DE ENERGIA NUCLEAR",
    SIGLA: "CNEN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "831",
    DESCRICAO: "CONF NACIONAL DIRIGENTES LOGISTAS",
    SIGLA: "CNDL",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "832",
    DESCRICAO: "CONFEDERAÇÃO NACIONAL DAS PROFISSÕES LIBERAIS",
    SIGLA: "CPL",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "833",
    DESCRICAO: "CONSELHO DOS DETETIVES DO BRASIL",
    SIGLA: "CDEB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "834",
    DESCRICAO: "CONSELHO FEDERAL DOS DETETIVES PROFISSIONAIS DO BRASIL",
    SIGLA: "CFDP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "835",
    DESCRICAO: "CONSELHO FEDERAL DE MEDICINA VETERINÁRIA",
    SIGLA: "CFMV",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "836",
    DESCRICAO: "CONSELHO FEDERAL DE PSICOLOGIA",
    SIGLA: "CFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "837",
    DESCRICAO: "CONSELHO FEDERAL DE BIOLOGIA",
    SIGLA: "CFB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "838",
    DESCRICAO: "CONSELHO FEDERAL DE ENFERMAGEM",
    SIGLA: "CFE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "839",
    DESCRICAO: "CONSELHO NAC DE ESTATISTICA",
    SIGLA: "CNE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "840",
    DESCRICAO: "CONSELHO REG DA BAHIA",
    SIGLA: "CRBA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "841",
    DESCRICAO: "CONSELHO REGIONAL DE ADMINISTRAÇÃO",
    SIGLA: "CRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "842",
    DESCRICAO: "CONSELHO REGIONAL DE ASSISTENTES SOCIAIS",
    SIGLA: "CRAS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "843",
    DESCRICAO: "CONSELHO REGIONAL DE BIBLIOTECONOMIA",
    SIGLA: "CRB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "844",
    DESCRICAO: "CONSELHO REGIONAL DE BIOLOGIA",
    SIGLA: "CRBio",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "845",
    DESCRICAO: "CONSELHO REGIONAL DE BIOMEDICINA",
    SIGLA: "CRBM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "846",
    DESCRICAO: "CONSELHO REGIONAL DE CONTABILIDADE",
    SIGLA: "CRC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "847",
    DESCRICAO: "CONSELHO REGIONAL DE CORRETORES DE IMÓVEIS",
    SIGLA: "CRCI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "848",
    DESCRICAO: "CONSELHO REGIONAL DE ECONOMIA",
    SIGLA: "CRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "849",
    DESCRICAO: "CONSELHO REGIONAL DE EDUCAÇÃO FÍSICA",
    SIGLA: "CREF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "850",
    DESCRICAO: "CONSELHO REGIONAL DE ENFERMAGEM",
    SIGLA: "EF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "851",
    DESCRICAO: "CONSELHO REG DE ENFERMARIA",
    SIGLA: "CREN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "852",
    DESCRICAO: "CONSELHO REGIONAL DE ENGENHARIA E AGRONOMIA",
    SIGLA: "CREA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "853",
    DESCRICAO: "CONSELHO REGIONAL DE ESTATÍSTICA",
    SIGLA: "CONRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "854",
    DESCRICAO: "CONSELHO REGIONAL DE FARMÁCIA",
    SIGLA: "CRF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "855",
    DESCRICAO: "CONSELHO REGIONAL DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
    SIGLA: "CRFT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "856",
    DESCRICAO: "CONSELHO REGIONAL DE FONOAUDIOLOGIA",
    SIGLA: "CRFA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "857",
    DESCRICAO: "CONSELHO REG DE GEOLOGIA",
    SIGLA: "CREG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "858",
    DESCRICAO: "CONSELHO REGIONAL DE MEDICINA VETERINÁRIA",
    SIGLA: "CRMV",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "859",
    DESCRICAO: "CONSELHO REGIONAL DE MEDICINA",
    SIGLA: "CRM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "860",
    DESCRICAO: "CONSELHO REGIONAL DE MUSEOLOGIA",
    SIGLA: "CRMU",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "861",
    DESCRICAO: "CONSELHO REGIONAL DE NUTRICIONISTAS",
    SIGLA: "CRN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "862",
    DESCRICAO: "CONSELHO REGIONAL DE ODONTOLOGIA",
    SIGLA: "CRO",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "863",
    DESCRICAO: "CONSELHO REGIONAL DE PSICOLOGIA",
    SIGLA: "CRP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "864",
    DESCRICAO: "CONSELHO REGIONAL DE QUÍMICA",
    SIGLA: "CRQ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "865",
    DESCRICAO: "CONSELHO REGIONAL DE REPRESENTANTES COMERCIAIS",
    SIGLA: "CRRC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "866",
    DESCRICAO: "CONSELHO REGIONAL DE SERVICO SOCIAL",
    SIGLA: "CRSS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "867",
    DESCRICAO: "CONSELHO REGIONAL DE TÉCNICOS EM RADIOLOGIA",
    SIGLA: "CRTR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "868",
    DESCRICAO: "CONSELHO REGIONAL DE PROFISSIONAIS DE RELAÇÕES PÚBLICAS",
    SIGLA: "CRRP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "869",
    DESCRICAO: "CONSELHO REG DOS TEC ADMINISTRACAO",
    SIGLA: "CRTA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "870",
    DESCRICAO: "CONSELHO REG FEDERAL PUBLICA",
    SIGLA: "CRFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "871",
    DESCRICAO: "CONSELHO REG MEDICINA ESTETICA",
    SIGLA: "CRME",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "872",
    DESCRICAO: "CORPO DE BOMBEIROS MILITAR",
    SIGLA: "CBM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "873",
    DESCRICAO: "CORREGEDORIA GERAL DA JUSTICA",
    SIGLA: "CGJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "874",
    DESCRICAO: "DEFENSORIA PUBLICA GERAL DO ESTADO",
    SIGLA: "DPGE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "875",
    DESCRICAO: "DEPARTAMENTO ADMINISTRATIVO",
    SIGLA: "DRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "876",
    DESCRICAO: "DEPARTAMENTO DE INVESTIGACOES",
    SIGLA: "DI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "877",
    DESCRICAO: "DEPARTAMENTO DE POLICIA CIVIL",
    SIGLA: "DPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "878",
    DESCRICAO: "DEPARTAMENTO DE POLICIA FEDERAL",
    SIGLA: "DPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "879",
    DESCRICAO: "DEPARTAMENTO DE POLICIA TECNICO CIENTIFICA",
    SIGLA: "DPTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "880",
    DESCRICAO: "DEPARTAMENTO DE TITULOS FEDERAIS",
    SIGLA: "DTF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "881",
    DESCRICAO: "DEPARTAMENTO DE TRANSITO",
    SIGLA: "DTRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "882",
    DESCRICAO: "DEPARTAMENTO DE CORREIOS E TELÉGRAFOS",
    SIGLA: "DCT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "883",
    DESCRICAO: "DEPARTAMENTO ESTADUAL DE SEG PUBLICA",
    SIGLA: "DESP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "884",
    DESCRICAO: "DEPARTAMENTO ESTRANGEIRO LEGISLATIVO",
    SIGLA: "DELE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "885",
    DESCRICAO: "DEPARTAMENTO FEDERAL DE SEG PUBLICA",
    SIGLA: "DFSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "886",
    DESCRICAO: "DEPARTAMENTO NACIONAL DE ESTRADA DE RODAGEM",
    SIGLA: "DNER",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "887",
    DESCRICAO: "DEPARTAMENTO ESTADUAL DE TRANSITO",
    SIGLA: "DETRAN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "888",
    DESCRICAO: "DIRETORIA GERAL DA POLICIA CIVIL",
    SIGLA: "DGPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "889",
    DESCRICAO: "ESTADO BRASILEIRO",
    SIGLA: "EB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "890",
    DESCRICAO: "FEDERACAO NACIONAL DOS JORNALISTAS",
    SIGLA: "FNJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "891",
    DESCRICAO: "FORCA AEREA BRASILEIRA",
    SIGLA: "FAB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "892",
    DESCRICAO: "FORCAS ARMADAS",
    SIGLA: "DPMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "893",
    DESCRICAO: "GERENCIA EST DE JUSTICA",
    SIGLA: "GEJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "894",
    DESCRICAO: "INST DE CRIMINOLOGIA",
    SIGLA: "IC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "895",
    DESCRICAO: "INST DE IDENT DR AROLDO MENDES DE PAIVA",
    SIGLA: "IAMP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "896",
    DESCRICAO: "INST DE IDENT EST PARANA",
    SIGLA: "IIEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "897",
    DESCRICAO: "INST DE IDENT TECNICA POLICIAL",
    SIGLA: "IITP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "898",
    DESCRICAO: "INSTITUTO FÉLIX PACHECO",
    SIGLA: "IFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "899",
    DESCRICAO: "INST NACIONAL DE IDENTIFICACAO",
    SIGLA: "INI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "900",
    DESCRICAO: "INSTITUTO PEREIRA FAUSTINO",
    SIGLA: "IPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "901",
    DESCRICAO: "INST POLICIA TECNICA",
    SIGLA: "IPT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "902",
    DESCRICAO: "INSTITUTO DE IDENTIFICAÇÃO TAVARES BURIL",
    SIGLA: "ITB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "903",
    DESCRICAO: "INSTITUTO DE ENGENHARIA",
    SIGLA: "IEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "904",
    DESCRICAO: "INSTITUTO DE IDENTIFICACAO DO PARANA",
    SIGLA: "IIPR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "906",
    DESCRICAO: "INSTITUTO GERAL DE PERICIAS",
    SIGLA: "IGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "907",
    DESCRICAO: "INSTITUTO INSPECIONAL PARTIDO",
    SIGLA: "IIP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "908",
    DESCRICAO: "INSTITUTO PEREIRA FAUSTINO R",
    SIGLA: "IPFR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "909",
    DESCRICAO: "INSTITUTO TECNICO CIENTIFICO DE POLICIA",
    SIGLA: "ITEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "910",
    DESCRICAO: "MINISTERIO DA AERONAUTICA",
    SIGLA: "MAE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "911",
    DESCRICAO: "MINISTERIO DA AERONAUTICA REPUBLICA",
    SIGLA: "MARJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "912",
    DESCRICAO: "MINISTERIO DA ASSOCIACAO FEDERATIVA",
    SIGLA: "MAF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "913",
    DESCRICAO: "MINISTERIO DA CIENCIA E TECNOLOGIA",
    SIGLA: "MCT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "914",
    DESCRICAO: "MINISTERIO DA CULTURA",
    SIGLA: "MCU",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "915",
    DESCRICAO: "MINISTERIO DA DEFESA",
    SIGLA: "MDEF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "916",
    DESCRICAO: "MINISTERIO DA ECONOMIA FAZENDA PLANEJAMENTO",
    SIGLA: "MEFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "917",
    DESCRICAO: "MINISTERIO DA EDUCACAO E CULTURA",
    SIGLA: "MEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "918",
    DESCRICAO: "MINISTERIO DA EDUCACAO E DO DESPORTO",
    SIGLA: "MED",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "919",
    DESCRICAO: "MINISTERIO DA EDUCACAO UNIVERSIDADE FEDERAL",
    SIGLA: "MEUF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "920",
    DESCRICAO: "MINISTERIO DA FAZENDA",
    SIGLA: "MFAZ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "921",
    DESCRICAO: "MINISTERIO DA INDUSTRIA MARITIMA",
    SIGLA: "MIM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "922",
    DESCRICAO: "MINISTERIO DA JUSTICA",
    SIGLA: "MJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "923",
    DESCRICAO: "MINISTERIO DA MARINHA",
    SIGLA: "MMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "924",
    DESCRICAO: "MINISTERIO DA MARINHA DEFESA",
    SIGLA: "MMD",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "925",
    DESCRICAO: "MINISTERIO DA MARINHA RIO DE JANEIRO",
    SIGLA: "MMRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "926",
    DESCRICAO: "MINISTERIO DA SAUDE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "927",
    DESCRICAO: "MINISTERIO DAS COMUNICACOES",
    SIGLA: "MC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "928",
    DESCRICAO: "MINISTERIO DAS RELACOES EXTERIORES",
    SIGLA: "MRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "929",
    DESCRICAO: "MINISTERIO DO EXERCITO",
    SIGLA: "MEX",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "930",
    DESCRICAO: "MINISTERIO DO TRABALHO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "931",
    DESCRICAO: "MINISTERIO DOS TRANSPORTES",
    SIGLA: "MTRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "932",
    DESCRICAO: "MINISTERIO FEDERAL",
    SIGLA: "MF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "933",
    DESCRICAO: "MINISTERIO MARINHA",
    SIGLA: "MMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "934",
    DESCRICAO: "MINISTERIO PUBLICO DISTRITO FED TERRITORIOS",
    SIGLA: "MPDF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "935",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DE MATO GROSSO",
    SIGLA: "MPMT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "936",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DE MINISTERIOAS GERAIS",
    SIGLA: "MPMG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "937",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DE SAO PAULO",
    SIGLA: "MPES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "938",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DO PARANA",
    SIGLA: "MPPR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "939",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DO RIO DE JANEIRO",
    SIGLA: "MPRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "940",
    DESCRICAO: "MINISTERIO PUBLICO DO TRABALHO",
    SIGLA: "MPT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "941",
    DESCRICAO: "MINISTERIO PUBLICO FEDERAL",
    SIGLA: "MPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "942",
    DESCRICAO: "MINISTERIO PUBLICO MILITAR",
    SIGLA: "MPM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "943",
    DESCRICAO: "MINISTERIO SERVICO SOCIAL",
    SIGLA: "MSS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "944",
    DESCRICAO: "MINISTERIO SOCIAL SAUDE",
    SIGLA: "MSS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "945",
    DESCRICAO: "ORDEM DOS ADVOGADOS DO BRASIL",
    SIGLA: "OAB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "946",
    DESCRICAO: "ORDEM DOS MUSICOS DO BRASIL",
    SIGLA: "OMB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "947",
    DESCRICAO: "PENITENCIARIA INDL DE GUARAPUAVA",
    SIGLA: "PIG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "948",
    DESCRICAO: "PODER JUDICIARIO",
    SIGLA: "PJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "949",
    DESCRICAO: "PODER JUDICIARIO DO EST DE GOIAS",
    SIGLA: "PJEG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "950",
    DESCRICAO: "POLICIA CIVIL INST DE IDENT",
    SIGLA: "PCII",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "951",
    DESCRICAO: "POLICIA DO DISTRITO FED INST DE IDENT",
    SIGLA: "PDFI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "952",
    DESCRICAO: "POLICIA FEDERAL",
    SIGLA: "PFED",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "953",
    DESCRICAO: "POLICIA FERROVIARIA FEDERAL",
    SIGLA: "PFF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "954",
    DESCRICAO: "POLICIA MILITAR",
    SIGLA: "PM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "955",
    DESCRICAO: "POLICIA MILITAR DO RJ",
    SIGLA: "PMRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "956",
    DESCRICAO: "PORT MINISTERIO FEDERAL",
    SIGLA: "PMAF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "957",
    DESCRICAO: "PREFEITURA MUNICIPAL DE PETROPOLIS",
    SIGLA: "PMP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "958",
    DESCRICAO: "PROCURADORIA GERAL DE JUSTICA",
    SIGLA: "PGJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "959",
    DESCRICAO: "PROCURADORIA GERAL DO ESTADO",
    SIGLA: "PGE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "960",
    DESCRICAO: "REDE FERROVIARIA FEDERAL",
    SIGLA: "RFF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "961",
    DESCRICAO: "REGISTRO GERAL",
    SIGLA: "RG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "962",
    DESCRICAO: "REGISTRO GERAL PACHECO",
    SIGLA: "RGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "965",
    DESCRICAO: "SECRETARIA DA CULTURA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "966",
    DESCRICAO: "SECRETARIA DA JUSTICA E SEGURANÇA",
    SIGLA: "SJS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "967",
    DESCRICAO: "SECRETARIA DA RECEITA FEDERAL",
    SIGLA: "SRF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "968",
    DESCRICAO: "SECRETARIA DE ADMIN DEPTO GERAL DE PESSOAL",
    SIGLA: "SAGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "969",
    DESCRICAO: "SECRETARIA DE ESTADO DA DEFESA SOCIAL",
    SIGLA: "SEDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "970",
    DESCRICAO: "SECRETARIA DE EST DA FAZENDA CULTURA",
    SIGLA: "SEF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "971",
    DESCRICAO: "SECRETARIA DE ESTADO DA JUSTICA",
    SIGLA: "SEJU",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "972",
    DESCRICAO: "SECRETARIA DE EST DA POLICIA CIVIL",
    SIGLA: "SEPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "973",
    DESCRICAO: "SECRETARIA DE EST DA SEGURANCA",
    SIGLA: "SES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "974",
    DESCRICAO: "SECRETARIA DE EST DE ADMINISTRACAO",
    SIGLA: "SEA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "975",
    DESCRICAO: "SECRETARIA DE ESTADO DE EDUCAÇÃO E CULTURA",
    SIGLA: "SEEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "976",
    DESCRICAO: "SECRETARIA DE EST DE JUSTICA E INTERIOR",
    SIGLA: "SEJI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "977",
    DESCRICAO: "SECRETARIA DE EST DE OBRAS E MEIO AMBIENTE",
    SIGLA: "SOMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "978",
    DESCRICAO: "SECRETARIA DE EST E JUSTICA DA SEG PUBLICA",
    SIGLA: "SJSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "979",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA",
    SIGLA: "SESP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "980",
    DESCRICAO: "SECRETARIA DE POLICIA E SEG PUBLICA",
    SIGLA: "SPSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "981",
    DESCRICAO: "SECRETARIA DE SEG INFORMACOES",
    SIGLA: "SSI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "982",
    DESCRICAO: "SECRETARIA DE SEGURANÇA PÚBLICA",
    SIGLA: "SSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "983",
    DESCRICAO: "SECRETARIA DA SEGURANÇA PUBLICA E DEFESA DA CIDADANIA",
    SIGLA: "SPDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "984",
    DESCRICAO: "SECRETARIA DE SEGURANCA",
    SIGLA: "SDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "985",
    DESCRICAO: "SECRETARIA DE SEGURANCA PUBLICA - CE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "986",
    DESCRICAO: "SECRETARIA DE SEGURANCA PUBLICA DE SP",
    SIGLA: "SSSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "987",
    DESCRICAO: "SECRETARIA DO INTERIOR DA JUSTICA",
    SIGLA: "SIJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "988",
    DESCRICAO: "SECRETARIA ESPECIAL DE CIENCIA E TECNOLOGIA",
    SIGLA: "SECT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "989",
    DESCRICAO: "SECRETARIA ESTADUAL DE IDENTIDADE",
    SIGLA: "SEID",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "990",
    DESCRICAO: "SECRETARIA ESTADUAL PUBLICA",
    SIGLA: "SEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "991",
    DESCRICAO: "SECRETARIA JUSTICA TRABALHO CIDADANIA",
    SIGLA: "SJTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "992",
    DESCRICAO: "SECRETARIA PUBLICA DO ESPIRITO SANTO",
    SIGLA: "SPES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "993",
    DESCRICAO: "SECRETARIA PUBLICA DO SUL",
    SIGLA: "SPS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "994",
    DESCRICAO: "SECRETARIA SEG PUBLICA PR",
    SIGLA: "SSPR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "995",
    DESCRICAO: "SECRETARIA SEGURANCA DO RIO DE JANEIRO",
    SIGLA: "SSRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "996",
    DESCRICAO: "SEG PUBL CIDADANIA INST IDENT",
    SIGLA: "SPCI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "997",
    DESCRICAO: "SENADO FEDERAL",
    SIGLA: "SF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "998",
    DESCRICAO: "SERV ESTRANG DEPTO POL AEREA FRONT PF",
    SIGLA: "SEPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "999",
    DESCRICAO: "SERVICO DE IDENT E CRIMINALISTA",
    SIGLA: "SIC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1000",
    DESCRICAO: "SIND DETETIVES PARTIC PROF ESPIRITO SANT",
    SIGLA: "SDES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1001",
    DESCRICAO: "SINDICATO DOS JORNALISTAS PROFISSIONAIS",
    SIGLA: "SJP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1002",
    DESCRICAO: "SUPERINTENDÊNCIA DE ADMINISTRAÇÃO DE PESSOAL",
    SIGLA: "SAP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1003",
    DESCRICAO: "SUPERINT GERAL DE POLICIA JUDICIARIA",
    SIGLA: "SGPJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1004",
    DESCRICAO: "SUPERINTENDÊNCIA DE POLÍCIA CIVIL",
    SIGLA: "SPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1005",
    DESCRICAO: "SUPERINTENDÊNCIA POLÍCIA TÉCNICA CIENTÍFICA",
    SIGLA: "SPTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1006",
    DESCRICAO: "SUPERIOR TRIBUNAL MILITAR",
    SIGLA: "STM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1007",
    DESCRICAO: "TRIBUNAL DE CONTAS",
    SIGLA: "TC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1008",
    DESCRICAO: "TRIBUNAL DE JUSTICA",
    SIGLA: "TJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1009",
    DESCRICAO: "TRIBUNAL ELEITORAL",
    SIGLA: "TE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1010",
    DESCRICAO: "TRIBUNAL REGIONAL DO TRABALHO",
    SIGLA: "TRT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1011",
    DESCRICAO: "TRIBUNAL REGIONAL ELEITORAL",
    SIGLA: "TRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1012",
    DESCRICAO: "TRIBUNAL REGIONAL FEDERAL",
    SIGLA: "TRF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1013",
    DESCRICAO: "TRIBUNAL SUPERIOR DO TRABALHO",
    SIGLA: "TST",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1014",
    DESCRICAO: "SUPERINTENDENCIA GERAL DE POLICIA CIVIL",
    SIGLA: "SGPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1015",
    DESCRICAO: "SECRETARIA DE ESTADO DA CASA CIVIL",
    SIGLA: "SECC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1016",
    DESCRICAO: "SECRETARIA DO ESTADO DA JUSTICA DE SAO PAULO",
    SIGLA: "SEJSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1017",
    DESCRICAO: "SECRETARIA DE ESTADO DE SAÚDE E DEFESA CIVIL",
    SIGLA: "SESDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1018",
    DESCRICAO: "SECRETARIA DE JUSTICA",
    SIGLA: "SJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1019",
    DESCRICAO: "INSTITUTO DE IDENTIFICACAO RICARDO GUMBLETON DAUNT",
    SIGLA: "IIRGD",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1020",
    DESCRICAO: "MINISTERIO DA GUERRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1021",
    DESCRICAO: "COORDENACAO GERAL DE POLICIA DE IMIGRACAO",
    SIGLA: "CGPI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1022",
    DESCRICAO: "DELEGACIA GERAL DE POLICIA",
    SIGLA: "DGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1023",
    DESCRICAO: "DELEGACIA DE POLÍCIA MARÍTIMA, AÉREA E DE FRONTEIRAS",
    SIGLA: "DPMAF/DPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1024",
    DESCRICAO: "SECRETARIA DE SEGURANCA - DEPTO. POLICIA TECNICA",
    SIGLA: "SSDPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1025",
    DESCRICAO: "COORDENACAO DE IMIGRACAO",
    SIGLA: "CIMCRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1026",
    DESCRICAO: "POLICIA CIVIL",
    SIGLA: "PC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1027",
    DESCRICAO: "POLÍCIA CIVIL DO ESTADO DE MINAS GERAIS",
    SIGLA: "PCMG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1028",
    DESCRICAO: "POLÍCIA TÉCNICO CIENTÍFICA",
    SIGLA: "PTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1029",
    DESCRICAO: "SECRETARIA COORDENADORA DE JUSTIÇA E DEFESA SOCIAL",
    SIGLA: "SEDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1030",
    DESCRICAO: "SECRETARIA DE DEFESA SOCIAL",
    SIGLA: "SDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1031",
    DESCRICAO: "SECRETARIA DA SEGURANÇA E DA DEFESA SOCIAL",
    SIGLA: "SSDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1032",
    DESCRICAO: "SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL",
    SIGLA: "SSPDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1033",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA CIDADÃ",
    SIGLA: "SESEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1034",
    DESCRICAO: "SECRETARIA DE ESTADO DE JUSTIÇA E SEGURANÇA PÚBLICA",
    SIGLA: "SEJUSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1035",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL",
    SIGLA: "SESED",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1036",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DEFESA DO CIDADÃO",
    SIGLA: "SSPDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1037",
    DESCRICAO: "GERÊNCIA DE ESTADO DE JUSTIÇA, SEGURANÇA PÚBLICA E CIDADANIA",
    SIGLA: "GEJUSPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1038",
    DESCRICAO: "CONSELHO DE ARQUITETURA E URBANISMO DO BRASIL",
    SIGLA: "CAU/BR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1039",
    DESCRICAO: "DEPARTAMENTO NACIONAL DE TRÂNSITO",
    SIGLA: "DENATRAN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1040",
    DESCRICAO: "GABINETE MILITAR",
    SIGLA: "GM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1041",
    DESCRICAO: "GERÊNCIA DE ESTADO DA SEGURANÇA PÚBLICA",
    SIGLA: "GESEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1042",
    DESCRICAO: "SECRETARIA DA JUSTIÇA E DA SEGURANÇA",
    SIGLA: "SJSEG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1043",
    DESCRICAO: "SECRETARIA DA SEGURANÇA, DEFESA E CIDADANIA",
    SIGLA: "SESDEC/RO",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1044",
    DESCRICAO:
      "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E ADMINISTRAÇÃO PENITENCIÁRIA",
    SIGLA: "SESPAP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1045",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA, DEFESA E CIDADANIA",
    SIGLA: "SESEGDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1046",
    DESCRICAO: "SECRETARIA DE JUSTIÇA E DEFESA SOCIAL",
    SIGLA: "SJDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1047",
    DESCRICAO: "Conselho Federal de Farmácia",
    SIGLA: "CFF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1048",
    DESCRICAO: "CONSELHO FEDERAL DOS DESPACHANTES DOCUMENTALISTAS DO BRASIL",
    SIGLA: "CFDD/BR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1049",
    DESCRICAO: "INSTITUTO DE ADMINISTRAÇÃO PENITENCIÁRIA",
    SIGLA: "IAPEN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1050",
    DESCRICAO: "SECRETARIA DE TRIBUTAÇÃO",
    SIGLA: "SET",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1051",
    DESCRICAO: "SECRETARIA DE JUSTIÇA E SEGURANÇA PÚBLICA",
    SIGLA: "SJSEGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1052",
    DESCRICAO: "SECRETARIA DE ESTADO DA CASA CIVIL E DESENVOLVIMENTO ECONÔMICO",
    SIGLA: "SECC/DE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1053",
    DESCRICAO: "AGENCIA NACIONAL DE AVIAÇÃO CIVIL",
    SIGLA: "ANAC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1054",
    DESCRICAO: "DIRETORIA DE IDENTIFICAÇÃO CIVIL",
    SIGLA: "DETRAN/RJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1055",
    DESCRICAO: "SECRETARIA DE SEGURANÇA PÚBLICA POLÍCIA CIVIL",
    SIGLA: "SSPPC/GO",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1056",
    DESCRICAO: "Divisão de Segurança e Guarda",
    SIGLA: "DSG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1057",
    DESCRICAO: "Secretaria de Justiça Interior e Segurança",
    SIGLA: "SJIS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1058",
    DESCRICAO: "Departamento de Polícia Técnica",
    SIGLA: "DPT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1059",
    DESCRICAO: "SSP - Polícia Civil",
    SIGLA: "SSPPC",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "1",
    DESCRICAO: "AFEGANISTAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "2",
    DESCRICAO: "AFRICA DO SUL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "3",
    DESCRICAO: "ALBANIA, REPUBLICA  DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "4",
    DESCRICAO: "ALEMANHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "5",
    DESCRICAO: "ANDORRA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "6",
    DESCRICAO: "ANGOLA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "7",
    DESCRICAO: "ANGUILLA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "8",
    DESCRICAO: "ANTIGUA E BARBUDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "9",
    DESCRICAO: "ANTILHAS HOLANDESAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "10",
    DESCRICAO: "ARABIA SAUDITA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "11",
    DESCRICAO: "ARGELIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "12",
    DESCRICAO: "ARGENTINA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "13",
    DESCRICAO: "ARMENIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "14",
    DESCRICAO: "ARUBA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "15",
    DESCRICAO: "AUSTRALIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "16",
    DESCRICAO: "AUSTRIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "17",
    DESCRICAO: "AZERBAIJAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "18",
    DESCRICAO: "BAHAMAS, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "19",
    DESCRICAO: "BAHREIN, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "20",
    DESCRICAO: "BANGLADESH",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "21",
    DESCRICAO: "BARBADOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "22",
    DESCRICAO: "BELARUS, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "23",
    DESCRICAO: "BELGICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "24",
    DESCRICAO: "BELIZE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "25",
    DESCRICAO: "BENIN",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "26",
    DESCRICAO: "BERMUDAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "27",
    DESCRICAO: "BOLIVIA, ESTADO PLURINACIONAL DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "28",
    DESCRICAO: "BOSNIA-HERZEGOVINA (REPUBLICA DA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "29",
    DESCRICAO: "BOTSUANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "30",
    DESCRICAO: "BRASIL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "31",
    DESCRICAO: "BRUNEI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "32",
    DESCRICAO: "BULGARIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "33",
    DESCRICAO: "BURKINA FASO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "34",
    DESCRICAO: "BURUNDI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "35",
    DESCRICAO: "BUTAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "36",
    DESCRICAO: "CABO VERDE, REPUBLICA DE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "37",
    DESCRICAO: "CAMAROES",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "38",
    DESCRICAO: "CAMBOJA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "39",
    DESCRICAO: "CANADA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "40",
    DESCRICAO: "CANARIAS, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "41",
    DESCRICAO: "CATAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "42",
    DESCRICAO: "CAYMAN, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "43",
    DESCRICAO: "CAZAQUISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "44",
    DESCRICAO: "CHADE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "45",
    DESCRICAO: "CHILE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "46",
    DESCRICAO: "CHINA, REPUBLICA POPULAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "47",
    DESCRICAO: "CHIPRE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "48",
    DESCRICAO: "CHRISTMAS,ILHA (NAVIDAD)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "49",
    DESCRICAO: "CINGAPURA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "50",
    DESCRICAO: "COCOS(KEELING),ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "51",
    DESCRICAO: "COLOMBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "52",
    DESCRICAO: "COMORES, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "53",
    DESCRICAO: "CONGO, REPUBLICA DEMOCRATICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "54",
    DESCRICAO: "CONGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "55",
    DESCRICAO: "COOK, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "56",
    DESCRICAO: "COREIA (DO NORTE), REP.POP.DEMOCRATICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "57",
    DESCRICAO: "COREIA (DO SUL), REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "58",
    DESCRICAO: "COSTA DO MARFIM",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "59",
    DESCRICAO: "COSTA RICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "60",
    DESCRICAO: "COVEITE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "61",
    DESCRICAO: "CROACIA (REPUBLICA DA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "62",
    DESCRICAO: "CUBA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "63",
    DESCRICAO: "DINAMARCA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "64",
    DESCRICAO: "DJIBUTI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "65",
    DESCRICAO: "DOMINICA,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "66",
    DESCRICAO: "EGITO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "67",
    DESCRICAO: "EL SALVADOR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "68",
    DESCRICAO: "EMIRADOS ARABES UNIDOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "69",
    DESCRICAO: "EQUADOR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "70",
    DESCRICAO: "ERITREIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "71",
    DESCRICAO: "ESLOVACA, REPUBLICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "72",
    DESCRICAO: "ESLOVENIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "73",
    DESCRICAO: "ESPANHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "74",
    DESCRICAO: "ESTADOS UNIDOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "75",
    DESCRICAO: "ESTONIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "76",
    DESCRICAO: "ETIOPIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "77",
    DESCRICAO: "FALKLAND (ILHAS MALVINAS)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "78",
    DESCRICAO: "FEROE, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "79",
    DESCRICAO: "FIJI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "80",
    DESCRICAO: "FILIPINAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "81",
    DESCRICAO: "FINLANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "82",
    DESCRICAO: "FORMOSA (TAIWAN)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "83",
    DESCRICAO: "FRANCA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "84",
    DESCRICAO: "GABAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "85",
    DESCRICAO: "GAMBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "86",
    DESCRICAO: "GANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "87",
    DESCRICAO: "GEORGIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "88",
    DESCRICAO: "GIBRALTAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "89",
    DESCRICAO: "GRANADA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "90",
    DESCRICAO: "GRECIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "91",
    DESCRICAO: "GROENLANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "92",
    DESCRICAO: "GUADALUPE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "93",
    DESCRICAO: "GUAM",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "94",
    DESCRICAO: "GUATEMALA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "95",
    DESCRICAO: "GUERNSEY, ILHA DO CANAL (INCLUI ALDERNEY E SARK)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "96",
    DESCRICAO: "GUIANA FRANCESA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "97",
    DESCRICAO: "GUIANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "98",
    DESCRICAO: "GUINE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "99",
    DESCRICAO: "GUINE-BISSAU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "100",
    DESCRICAO: "GUINE-EQUATORIAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "101",
    DESCRICAO: "HAITI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "102",
    DESCRICAO: "HONDURAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "103",
    DESCRICAO: "HONG KONG",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "104",
    DESCRICAO: "HUNGRIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "105",
    DESCRICAO: "IEMEN",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "106",
    DESCRICAO: "INDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "107",
    DESCRICAO: "INDONESIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "108",
    DESCRICAO: "IRA, REPUBLICA ISLAMICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "109",
    DESCRICAO: "IRAQUE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "110",
    DESCRICAO: "IRLANDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "111",
    DESCRICAO: "ISLANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "112",
    DESCRICAO: "ISRAEL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "113",
    DESCRICAO: "ITALIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "114",
    DESCRICAO: "JAMAICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "115",
    DESCRICAO: "JAPAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "116",
    DESCRICAO: "JERSEY, ILHA DO CANAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "117",
    DESCRICAO: "JOHNSTON, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "118",
    DESCRICAO: "JORDANIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "119",
    DESCRICAO: "KIRIBATI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "120",
    DESCRICAO: "LAOS, REP.POP.DEMOCR.DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "121",
    DESCRICAO: "LEBUAN,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "122",
    DESCRICAO: "LESOTO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "123",
    DESCRICAO: "LETONIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "124",
    DESCRICAO: "LIBANO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "125",
    DESCRICAO: "LIBERIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "126",
    DESCRICAO: "LIBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "127",
    DESCRICAO: "LIECHTENSTEIN",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "128",
    DESCRICAO: "LITUANIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "129",
    DESCRICAO: "LUXEMBURGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "130",
    DESCRICAO: "MACAU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "131",
    DESCRICAO: "MACEDONIA, ANT.REP.IUGOSLAVA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "132",
    DESCRICAO: "MADAGASCAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "133",
    DESCRICAO: "MADEIRA, ILHA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "134",
    DESCRICAO: "MALASIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "135",
    DESCRICAO: "MALAVI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "136",
    DESCRICAO: "MALDIVAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "137",
    DESCRICAO: "MALI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "138",
    DESCRICAO: "MALTA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "139",
    DESCRICAO: "MAN, ILHA DE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "140",
    DESCRICAO: "MARIANAS DO NORTE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "141",
    DESCRICAO: "MARROCOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "142",
    DESCRICAO: "MARSHALL,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "143",
    DESCRICAO: "MARTINICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "144",
    DESCRICAO: "MAURICIO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "145",
    DESCRICAO: "MAURITANIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "146",
    DESCRICAO: "MAYOTTE (ILHAS FRANCESAS)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "147",
    DESCRICAO: "MEXICO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "148",
    DESCRICAO: "MIANMAR (BIRMANIA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "149",
    DESCRICAO: "MICRONESIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "150",
    DESCRICAO: "MIDWAY, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "151",
    DESCRICAO: "MOCAMBIQUE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "152",
    DESCRICAO: "MOLDAVIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "153",
    DESCRICAO: "MONACO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "154",
    DESCRICAO: "MONGOLIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "155",
    DESCRICAO: "MONTENEGRO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "156",
    DESCRICAO: "MONTSERRAT,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "157",
    DESCRICAO: "NAMIBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "158",
    DESCRICAO: "NAURU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "159",
    DESCRICAO: "NEPAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "160",
    DESCRICAO: "NICARAGUA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "161",
    DESCRICAO: "NIGER",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "162",
    DESCRICAO: "NIGERIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "163",
    DESCRICAO: "NIUE,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "164",
    DESCRICAO: "NORFOLK,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "165",
    DESCRICAO: "NORUEGA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "166",
    DESCRICAO: "NOVA CALEDONIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "167",
    DESCRICAO: "NOVA ZELANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "168",
    DESCRICAO: "OMA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "169",
    DESCRICAO: "PACIFICO,ILHAS DO (POSSESSAO DOS EUA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "170",
    DESCRICAO: "PAISES BAIXOS (HOLANDA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "171",
    DESCRICAO: "PALAU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "172",
    DESCRICAO: "PANAMA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "173",
    DESCRICAO: "PAPUA NOVA GUINE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "174",
    DESCRICAO: "PAQUISTAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "175",
    DESCRICAO: "PARAGUAI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "176",
    DESCRICAO: "PERU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "177",
    DESCRICAO: "PITCAIRN,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "178",
    DESCRICAO: "POLINESIA FRANCESA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "179",
    DESCRICAO: "POLONIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "180",
    DESCRICAO: "PORTO RICO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "181",
    DESCRICAO: "PORTUGAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "182",
    DESCRICAO: "QUENIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "183",
    DESCRICAO: "QUIRGUIZ, REPUBLICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "184",
    DESCRICAO: "REINO UNIDO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "185",
    DESCRICAO: "REPUBLICA CENTRO-AFRICANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "186",
    DESCRICAO: "REPUBLICA DOMINICANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "187",
    DESCRICAO: "REUNIAO, ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "188",
    DESCRICAO: "ROMENIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "189",
    DESCRICAO: "RUANDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "190",
    DESCRICAO: "RUSSIA, FEDERACAO DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "191",
    DESCRICAO: "SAARA OCIDENTAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "192",
    DESCRICAO: "SALOMAO, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "193",
    DESCRICAO: "SAMOA AMERICANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "194",
    DESCRICAO: "SAMOA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "195",
    DESCRICAO: "SAN MARINO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "196",
    DESCRICAO: "SANTA HELENA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "197",
    DESCRICAO: "SANTA LUCIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "198",
    DESCRICAO: "SAO CRISTOVAO E NEVES,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "199",
    DESCRICAO: "SAO PEDRO E MIQUELON",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "200",
    DESCRICAO: "SAO TOME E PRINCIPE, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "201",
    DESCRICAO: "SAO VICENTE E GRANADINAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "202",
    DESCRICAO: "SENEGAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "203",
    DESCRICAO: "SERRA LEOA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "204",
    DESCRICAO: "SERVIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "205",
    DESCRICAO: "SEYCHELLES",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "206",
    DESCRICAO: "SIRIA, REPUBLICA ARABE DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "207",
    DESCRICAO: "SOMALIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "208",
    DESCRICAO: "SRI LANKA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "209",
    DESCRICAO: "SUAZILANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "210",
    DESCRICAO: "SUDAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "211",
    DESCRICAO: "SUECIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "212",
    DESCRICAO: "SUICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "213",
    DESCRICAO: "SURINAME",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "214",
    DESCRICAO: "TADJIQUISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "215",
    DESCRICAO: "TAILANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "216",
    DESCRICAO: "TANZANIA, REP.UNIDA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "217",
    DESCRICAO: "TCHECA, REPUBLICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "218",
    DESCRICAO: "TERRITORIO BRIT.OC.INDICO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "219",
    DESCRICAO: "TIMOR LESTE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "220",
    DESCRICAO: "TOGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "221",
    DESCRICAO: "TONGA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "222",
    DESCRICAO: "TOQUELAU,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "223",
    DESCRICAO: "TRINIDAD E TOBAGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "224",
    DESCRICAO: "TUNISIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "225",
    DESCRICAO: "TURCAS E CAICOS,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "226",
    DESCRICAO: "TURCOMENISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "227",
    DESCRICAO: "TURQUIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "228",
    DESCRICAO: "TUVALU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "229",
    DESCRICAO: "UCRANIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "230",
    DESCRICAO: "UGANDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "231",
    DESCRICAO: "URUGUAI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "232",
    DESCRICAO: "UZBEQUISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "233",
    DESCRICAO: "VANUATU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "234",
    DESCRICAO: "VATICANO, EST.DA CIDADE DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "235",
    DESCRICAO: "VENEZUELA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "236",
    DESCRICAO: "VIETNA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "237",
    DESCRICAO: "VIRGENS,ILHAS (BRITANICAS)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "238",
    DESCRICAO: "VIRGENS,ILHAS (E.U.A.)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "239",
    DESCRICAO: "WAKE, ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "240",
    DESCRICAO: "ZAMBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "241",
    DESCRICAO: "ZIMBABUE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "242",
    DESCRICAO: "NÃO INFORMADO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "243",
    DESCRICAO: "SUDAO DO SUL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3496",
    DESCRICAO: "ANUAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3497",
    DESCRICAO: "MENSAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3498",
    DESCRICAO: "QUINZENAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3499",
    DESCRICAO: "SEMANAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3500",
    DESCRICAO: "SEMESTRAL",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "142",
    DESCRICAO: "Diretor geral de empresa e organizações ",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "143",
    DESCRICAO: "Atividade agropecuária, rural ou florestal",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "144",
    DESCRICAO: "Bancário",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "145",
    DESCRICAO: "Economista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "146",
    DESCRICAO: "Gerente administrativo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "147",
    DESCRICAO: "Engenheiro",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "148",
    DESCRICAO: "Advogado",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "149",
    DESCRICAO: "Empregado doméstico nos serviços gerais",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "150",
    DESCRICAO: "Não Informado",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "151",
    DESCRICAO: "Analista de sistemas",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "152",
    DESCRICAO: "Médico",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "153",
    DESCRICAO: "Membros das forças armadas e policiais",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "154",
    DESCRICAO: "Zootecnista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "155",
    DESCRICAO: "Artista, produtor ou trabalhador de entretenimento ",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "156",
    DESCRICAO: "Professor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "157",
    DESCRICAO: "Vendedor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "158",
    DESCRICAO: "Psicólogo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "159",
    DESCRICAO: "Dentista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "160",
    DESCRICAO: "Representante Comercial",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "161",
    DESCRICAO: "Enfermeiro",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "162",
    DESCRICAO: "Pedagogo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "163",
    DESCRICAO: "Fonoaudiólogo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "164",
    DESCRICAO: "Acadêmico",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "165",
    DESCRICAO: "Dirigente político ou síndical",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "166",
    DESCRICAO: "Corretor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "167",
    DESCRICAO: "Jornalista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "168",
    DESCRICAO: "Designer",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "169",
    DESCRICAO: "Técnico especialista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "170",
    DESCRICAO: "Agente de saúde",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "171",
    DESCRICAO: "Biomédico",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "172",
    DESCRICAO: "Servidor Público",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "173",
    DESCRICAO: "Auditor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "174",
    DESCRICAO: "Piloto",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "175",
    DESCRICAO: "Líder religioso",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "176",
    DESCRICAO: "Assistente social",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "177",
    DESCRICAO: "Motorista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "178",
    DESCRICAO: "Escrivão",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "179",
    DESCRICAO: "Veterinário",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "180",
    DESCRICAO: "Técnólogo especialista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "181",
    DESCRICAO: "Gerente de hotelaria",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "182",
    DESCRICAO: "Bibliotecário",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "183",
    DESCRICAO: "Operador de Máquinas",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "184",
    DESCRICAO: "Escritor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "185",
    DESCRICAO: "Prof Esporte",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "186",
    DESCRICAO: "Comissário de bordo, Guia de turismo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "187",
    DESCRICAO: "Outras ocupações não classificadas",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "188",
    DESCRICAO: "Serviços Gerais",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "189",
    DESCRICAO: "Trab ind beneficiamento e construção civil",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "190",
    DESCRICAO: "Trab serv de embelezamento",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "191",
    DESCRICAO: "Trab serv Proteção/Seguranc (Exceto Militar)",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "3111",
    DESCRICAO: "MASCULINO",
  },
  {
    DOMINIO: "SEXO",
    CODIGO: "3112",
    DESCRICAO: "FEMININO",
  },
  {
    DOMINIO: "SEXO",
    CODIGO: "5018",
    DESCRICAO: "NÃO IDENTIFICADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "447",
    DESCRICAO: "CANCELADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "448",
    DESCRICAO: "PENDENTE DE REGULARIZACAO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "449",
    DESCRICAO: "REGULAR",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4214",
    DESCRICAO: "INCOMPLETO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4215",
    DESCRICAO: "INCORRETO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4216",
    DESCRICAO: "INDETERMINADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4217",
    DESCRICAO: "INEXISTENTE",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4218",
    DESCRICAO: "NULO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4219",
    DESCRICAO: "SUSPENSO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4982",
    DESCRICAO: "CANCELADO POR ENCERRAMENTO DE ESPOLIO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4983",
    DESCRICAO: "CANCELADO POR MULTIPLICIDADE",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4984",
    DESCRICAO: "CANCELADO DE OFICIO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4985",
    DESCRICAO: "CANCELADO POR OBITO SEM ESPOLIO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "5001",
    DESCRICAO: "CPF NULO",
  },
  {
    DOMINIO: "SITUACAO_ESCOLARIDADE",
    CODIGO: "3081",
    DESCRICAO: "COMPLETO",
  },
  {
    DOMINIO: "SITUACAO_ESCOLARIDADE",
    CODIGO: "3082",
    DESCRICAO: "CURSANDO",
  },
  {
    DOMINIO: "SITUACAO_ESCOLARIDADE",
    CODIGO: "3083",
    DESCRICAO: "INCOMPLETO",
  },
  {
    DOMINIO: "TIPO_ATIVIDADE",
    CODIGO: "8",
    DESCRICAO: "PRIVADO - PESSOAS FISICAS",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3072",
    DESCRICAO: "PESSOAL",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3073",
    DESCRICAO: "COMERCIAL",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3074",
    DESCRICAO: "OUTROS",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3495",
    DESCRICAO: "DERIVATIVO",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "4988",
    DESCRICAO: "FUNCIONARIO",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "5012",
    DESCRICAO: "CONTA SALARIO",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "5015",
    DESCRICAO: "RISCO SACADO",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "565",
    DESCRICAO: "RESIDENCIAL",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "566",
    DESCRICAO: "COMERCIAL",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "3134",
    DESCRICAO: "OUTROS",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "5014",
    DESCRICAO: "CONTA SALARIO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "584",
    DESCRICAO: "ALUGUEL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "585",
    DESCRICAO: "APLICAÇÃO FINANCEIRA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "586",
    DESCRICAO: "APOSENTADORIA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "587",
    DESCRICAO: "DIVIDENDOS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "588",
    DESCRICAO: "OUTRAS RENDAS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "589",
    DESCRICAO: "PENSÃO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "590",
    DESCRICAO: "PRINCIPAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3113",
    DESCRICAO: "GRATIFICAÇÃO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3114",
    DESCRICAO: "PRÓ-LABORE",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3115",
    DESCRICAO: "COMISSÃO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3116",
    DESCRICAO: "HONORÁRIOS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3117",
    DESCRICAO: "RENDA RURAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3144",
    DESCRICAO: "REMUNERAÇÃO MENSAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3181",
    DESCRICAO: "CÔNJUGE",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3493",
    DESCRICAO: "BÔNUS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3494",
    DESCRICAO: "MESADA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4807",
    DESCRICAO: "LIMITE CARTÃO DE CRÉDITO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4808",
    DESCRICAO: "LIMITE CHEQUE ESPECIAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4819",
    DESCRICAO: "SALÁRIO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4820",
    DESCRICAO: "PRESUMIDA",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "1",
    DESCRICAO: "Comprovada",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "2",
    DESCRICAO: "Declarada",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "3",
    DESCRICAO: "Presumida pelo gerente",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "4",
    DESCRICAO: "Presumida por investimentos",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "5",
    DESCRICAO: "Presumida por modelo",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "6",
    DESCRICAO: "Não informada",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "7",
    DESCRICAO: "Presumida por crédito",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "8",
    DESCRICAO: "Renda BACEN",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3412",
    DESCRICAO: "AEROPORTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3413",
    DESCRICAO: "ALAMEDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3414",
    DESCRICAO: "ÁREA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3415",
    DESCRICAO: "ASA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3416",
    DESCRICAO: "AVENIDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3417",
    DESCRICAO: "CAMPO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3418",
    DESCRICAO: "CHÁCARA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3419",
    DESCRICAO: "COLÔNIA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3420",
    DESCRICAO: "CONDOMÍNIO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3421",
    DESCRICAO: "CONJUNTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3422",
    DESCRICAO: "DISTRITO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3423",
    DESCRICAO: "ESPLANADA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3424",
    DESCRICAO: "ESTAÇÃO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3425",
    DESCRICAO: "ESTRADA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3426",
    DESCRICAO: "FAVELA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3427",
    DESCRICAO: "FAZENDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3428",
    DESCRICAO: "FEIRA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3429",
    DESCRICAO: "JARDIM",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3430",
    DESCRICAO: "LADEIRA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3431",
    DESCRICAO: "LAGO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3432",
    DESCRICAO: "LAGOA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3433",
    DESCRICAO: "LARGO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3434",
    DESCRICAO: "LOTEAMENTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3435",
    DESCRICAO: "MORRO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3436",
    DESCRICAO: "NÚCLEO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3437",
    DESCRICAO: "PARQUE",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3438",
    DESCRICAO: "PASSARELA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3439",
    DESCRICAO: "PÁTIO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3440",
    DESCRICAO: "PRAÇA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3441",
    DESCRICAO: "QUADRA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3442",
    DESCRICAO: "RECANTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3443",
    DESCRICAO: "RESIDENCIAL",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3444",
    DESCRICAO: "RODOVIA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3445",
    DESCRICAO: "RUA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3446",
    DESCRICAO: "SERVIDÃO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3447",
    DESCRICAO: "SETOR",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3448",
    DESCRICAO: "SÍTIO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3449",
    DESCRICAO: "TRAVESSA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3450",
    DESCRICAO: "TRECHO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3451",
    DESCRICAO: "TREVO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3452",
    DESCRICAO: "VALE",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3453",
    DESCRICAO: "VEREDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3454",
    DESCRICAO: "VIA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3455",
    DESCRICAO: "VIADUTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3456",
    DESCRICAO: "VIELA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3457",
    DESCRICAO: "VILA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3501",
    DESCRICAO: "10ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3502",
    DESCRICAO: "10ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3503",
    DESCRICAO: "11ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3504",
    DESCRICAO: "11ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3505",
    DESCRICAO: "12ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3506",
    DESCRICAO: "12ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3507",
    DESCRICAO: "13ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3508",
    DESCRICAO: "14ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3509",
    DESCRICAO: "15ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3510",
    DESCRICAO: "16ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3511",
    DESCRICAO: "17ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3512",
    DESCRICAO: "18ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3513",
    DESCRICAO: "19ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3514",
    DESCRICAO: "1ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3515",
    DESCRICAO: "1ª Ladeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3516",
    DESCRICAO: "1ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3517",
    DESCRICAO: "1ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3518",
    DESCRICAO: "1ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3519",
    DESCRICAO: "1ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3520",
    DESCRICAO: "1ª Travessa da Rodovia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3521",
    DESCRICAO: "1ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3522",
    DESCRICAO: "1º Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3523",
    DESCRICAO: "1º Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3524",
    DESCRICAO: "20ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3525",
    DESCRICAO: "21ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3526",
    DESCRICAO: "22ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3527",
    DESCRICAO: "2ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3528",
    DESCRICAO: "2ª Ladeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3529",
    DESCRICAO: "2ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3530",
    DESCRICAO: "2ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3531",
    DESCRICAO: "2ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3532",
    DESCRICAO: "2ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3533",
    DESCRICAO: "2ª Travessa da Rodovia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3534",
    DESCRICAO: "2ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3535",
    DESCRICAO: "2º Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3536",
    DESCRICAO: "2º Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3537",
    DESCRICAO: "3ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3538",
    DESCRICAO: "3ª Ladeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3539",
    DESCRICAO: "3ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3540",
    DESCRICAO: "3ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3541",
    DESCRICAO: "3ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3542",
    DESCRICAO: "3ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3543",
    DESCRICAO: "3ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3544",
    DESCRICAO: "3º Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3545",
    DESCRICAO: "3º Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3546",
    DESCRICAO: "4ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3547",
    DESCRICAO: "4ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3548",
    DESCRICAO: "4ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3549",
    DESCRICAO: "4ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3550",
    DESCRICAO: "4ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3551",
    DESCRICAO: "4ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3552",
    DESCRICAO: "5ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3553",
    DESCRICAO: "5ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3554",
    DESCRICAO: "5ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3555",
    DESCRICAO: "5ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3556",
    DESCRICAO: "5ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3557",
    DESCRICAO: "6ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3558",
    DESCRICAO: "6ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3559",
    DESCRICAO: "6ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3560",
    DESCRICAO: "6ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3561",
    DESCRICAO: "7ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3562",
    DESCRICAO: "7ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3563",
    DESCRICAO: "8ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3564",
    DESCRICAO: "9ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3565",
    DESCRICAO: "9ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3566",
    DESCRICAO: "Acampamento",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3567",
    DESCRICAO: "Acesso",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3568",
    DESCRICAO: "Acesso Estadual",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3569",
    DESCRICAO: "Acesso Local",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3570",
    DESCRICAO: "Adro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3571",
    DESCRICAO: "Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3572",
    DESCRICAO: "Anel Viário",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3573",
    DESCRICAO: "Antiga Estação",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3574",
    DESCRICAO: "Antiga Estrada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3575",
    DESCRICAO: "Área Especial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3576",
    DESCRICAO: "Área Verde",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3577",
    DESCRICAO: "Artéria",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3578",
    DESCRICAO: "Atalho",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3579",
    DESCRICAO: "Avenida Contorno",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3580",
    DESCRICAO: "Avenida Marginal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3581",
    DESCRICAO: "Avenida Marginal Direita",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3582",
    DESCRICAO: "Avenida Marginal Esquerda",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3583",
    DESCRICAO: "Avenida Marginal Norte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3584",
    DESCRICAO: "Avenida Perimetral",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3585",
    DESCRICAO: "Baixa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3586",
    DESCRICAO: "Balão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3587",
    DESCRICAO: "Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3588",
    DESCRICAO: "Beco 1",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3589",
    DESCRICAO: "Beco 2",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3590",
    DESCRICAO: "Beco 3",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3591",
    DESCRICAO: "Belvedere",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3592",
    DESCRICAO: "Bloco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3593",
    DESCRICAO: "Blocos",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3594",
    DESCRICAO: "Bosque",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3595",
    DESCRICAO: "Boulevard",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3596",
    DESCRICAO: "Bulevar",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3597",
    DESCRICAO: "Buraco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3598",
    DESCRICAO: "Cais",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3599",
    DESCRICAO: "Calçada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3600",
    DESCRICAO: "Calçadão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3601",
    DESCRICAO: "Caminho",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3602",
    DESCRICAO: "Caminho de Servidão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3603",
    DESCRICAO: "Campus",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3604",
    DESCRICAO: "Canal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3605",
    DESCRICAO: "Ciclovia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3606",
    DESCRICAO: "Circular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3607",
    DESCRICAO: "Colina",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3608",
    DESCRICAO: "Complexo Viário",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3609",
    DESCRICAO: "Comunidade",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3610",
    DESCRICAO: "Condomínio Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3611",
    DESCRICAO: "Conjunto Habitacional",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3612",
    DESCRICAO: "Conjunto Mutirão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3613",
    DESCRICAO: "Conjunto Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3614",
    DESCRICAO: "Contorno",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3615",
    DESCRICAO: "Corredor",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3616",
    DESCRICAO: "Córrego",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3617",
    DESCRICAO: "Descida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3618",
    DESCRICAO: "Desvio",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3619",
    DESCRICAO: "Eixo",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3620",
    DESCRICAO: "Eixo Industrial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3621",
    DESCRICAO: "Eixo Principal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3622",
    DESCRICAO: "Elevada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3623",
    DESCRICAO: "Entrada Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3624",
    DESCRICAO: "Entre Quadra",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3625",
    DESCRICAO: "Escada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3626",
    DESCRICAO: "Escada de Pedra",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3627",
    DESCRICAO: "Escadaria",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3628",
    DESCRICAO: "Estacionamento",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3629",
    DESCRICAO: "Estádio",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3630",
    DESCRICAO: "Estrada Antiga",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3631",
    DESCRICAO: "Estrada de Ferro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3632",
    DESCRICAO: "Estrada de Ligação",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3633",
    DESCRICAO: "Estrada de Servidão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3634",
    DESCRICAO: "Estrada Estadual",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3635",
    DESCRICAO: "Estrada Intermunicipal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3636",
    DESCRICAO: "Estrada Municipal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3637",
    DESCRICAO: "Estrada Nova",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3638",
    DESCRICAO: "Estrada Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3639",
    DESCRICAO: "Estrada Velha",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3640",
    DESCRICAO: "Estrada Vicinal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3641",
    DESCRICAO: "Fonte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3642",
    DESCRICAO: "Forte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3643",
    DESCRICAO: "Galeria",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3644",
    DESCRICAO: "Gleba",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3645",
    DESCRICAO: "Granja",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3646",
    DESCRICAO: "Ilha",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3647",
    DESCRICAO: "Jardim Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3648",
    DESCRICAO: "Jardinete",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3649",
    DESCRICAO: "Margem",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3650",
    DESCRICAO: "Marginal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3651",
    DESCRICAO: "Mercado",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3652",
    DESCRICAO: "Módulo",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3653",
    DESCRICAO: "Monte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3654",
    DESCRICAO: "Nova Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3655",
    DESCRICAO: "Núcleo Habitacional",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3656",
    DESCRICAO: "Núcleo Rural",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3657",
    DESCRICAO: "Outeiro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3658",
    DESCRICAO: "Parada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3659",
    DESCRICAO: "Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3660",
    DESCRICAO: "Parque Municipal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3661",
    DESCRICAO: "Parque Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3662",
    DESCRICAO: "Passagem",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3663",
    DESCRICAO: "Passagem de Pedestres",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3664",
    DESCRICAO: "Passagem Subterrânea",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3665",
    DESCRICAO: "Passeio",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3666",
    DESCRICAO: "Passeio Público",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3667",
    DESCRICAO: "Ponta",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3668",
    DESCRICAO: "Ponte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3669",
    DESCRICAO: "Porto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3670",
    DESCRICAO: "Praça de Esportes",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3671",
    DESCRICAO: "Praia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3672",
    DESCRICAO: "Prolongamento",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3673",
    DESCRICAO: "Quinta",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3674",
    DESCRICAO: "Ramal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3675",
    DESCRICAO: "Rampa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3676",
    DESCRICAO: "Reta",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3677",
    DESCRICAO: "Retiro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3678",
    DESCRICAO: "Retorno",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3679",
    DESCRICAO: "Rodo Anel",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3680",
    DESCRICAO: "Rotatória",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3681",
    DESCRICAO: "Rótula",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3682",
    DESCRICAO: "Rua de Ligação",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3683",
    DESCRICAO: "Rua de Pedestre",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3684",
    DESCRICAO: "Rua Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3685",
    DESCRICAO: "Rua Principal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3686",
    DESCRICAO: "Rua Projetada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3687",
    DESCRICAO: "Rua Velha",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3688",
    DESCRICAO: "Rua Vicinal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3689",
    DESCRICAO: "Ruela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3690",
    DESCRICAO: "Servidão de Passagem",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3691",
    DESCRICAO: "Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3692",
    DESCRICAO: "Terceira Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3693",
    DESCRICAO: "Terminal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3694",
    DESCRICAO: "Travessa Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3695",
    DESCRICAO: "Trincheira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3696",
    DESCRICAO: "Túnel",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3697",
    DESCRICAO: "Unidade",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3698",
    DESCRICAO: "Vala",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3699",
    DESCRICAO: "Variante",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3700",
    DESCRICAO: "Variante da Estrada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3701",
    DESCRICAO: "Via Coletora",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3702",
    DESCRICAO: "Via Costeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3703",
    DESCRICAO: "Via de Acesso",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3704",
    DESCRICAO: "Via de Pedestre",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3705",
    DESCRICAO: "Via de Pedestres",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3706",
    DESCRICAO: "Via Expressa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3707",
    DESCRICAO: "Via Lateral",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3708",
    DESCRICAO: "Via Litoranea",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3709",
    DESCRICAO: "Via Local",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3710",
    DESCRICAO: "Via Marginal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3711",
    DESCRICAO: "Via Pedestre",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3712",
    DESCRICAO: "Via Principal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3713",
    DESCRICAO: "Zigue-Zague",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4289",
    DESCRICAO: "SHA",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "952",
    DESCRICAO: "CELULAR",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "953",
    DESCRICAO: "COMERCIAL",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "954",
    DESCRICAO: "RESIDENCIAL",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "4225",
    DESCRICAO: "ADICIONAL",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "4989",
    DESCRICAO: "FUNCIONARIO",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "5013",
    DESCRICAO: "CONTA SALARIO",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "5016",
    DESCRICAO: "RISCO SACADO",
  },
  {
    DOMINIO: "PEP",
    CODIGO: "2",
    DESCRICAO: "Sim",
    SIGLA: "SIM",
  },
  {
    DOMINIO: "PEP",
    CODIGO: "1",
    DESCRICAO: "Não",
    SIGLA: "NAO",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "1",
    DESCRICAO: "Comunhão Parcial de Bens",
    SIGLA: "Comunhao Parcial de Bens",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "2",
    DESCRICAO: "Comunhão Total de Bens",
    SIGLA: "Comunhao Total de Bens",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "3",
    DESCRICAO: "Separação Total de Bens",
    SIGLA: "Separacao Total de Bens",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "4",
    DESCRICAO: "Participação Final nos Aquestos",
    SIGLA: "Participacao Final nos Aquestos",
  },
];

let dominioshmo = [
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "87",
    DESCRICAO: "ENSINO FUNDAMENTAL COMPLETO",
  },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "88",
    DESCRICAO: "ENSINO MEDIO (2.ºGRAU)",
  },
  { DOMINIO: "ESCOLARIDADE", CODIGO: "89", DESCRICAO: "EDUCACAO SUPERIOR" },
  { DOMINIO: "ESCOLARIDADE", CODIGO: "90", DESCRICAO: "MESTRADO" },
  { DOMINIO: "ESCOLARIDADE", CODIGO: "91", DESCRICAO: "DOUTORADO" },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4555",
    DESCRICAO: "ENSINO SUPERIOR COM PÓS GRADUAÇÃO",
  },
  { DOMINIO: "ESCOLARIDADE", CODIGO: "4556", DESCRICAO: "NÃO ALFABETIZADO" },
  { DOMINIO: "ESCOLARIDADE", CODIGO: "4557", DESCRICAO: "ALFABETIZADO" },
  {
    DOMINIO: "ESCOLARIDADE",
    CODIGO: "4558",
    DESCRICAO: "ENSINO FUNDAMENTAL ATE 5º ANO",
  },
  { DOMINIO: "ESCOLARIDADE", CODIGO: "5042", DESCRICAO: "NÃO IDENTIFICADO" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "93", DESCRICAO: "COMPANHEIRO(A)" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "94", DESCRICAO: "DIVORCIADO(A)" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "95", DESCRICAO: "SEPARADO(A)" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "96", DESCRICAO: "SOLTEIRO(A)" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "97", DESCRICAO: "UNIAO ESTAVEL" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "98", DESCRICAO: "VIUVO(A)" },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3068",
    DESCRICAO: "CASADO(A) - COMUNHÃO PARCIAL DE BENS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3069",
    DESCRICAO: "CASADO(A) - COMUNHÃO UNIVERSAL DE BENS",
  },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "3071",
    DESCRICAO: "CASADO(A) - SEPARACAO DE BENS",
  },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "3182", DESCRICAO: "CASADO(A)" },
  { DOMINIO: "ESTADO_CIVIL", CODIGO: "3183", DESCRICAO: "OUTROS" },
  {
    DOMINIO: "ESTADO_CIVIL",
    CODIGO: "4711",
    DESCRICAO: "CASADO(A) COM PARTICIPAÇÃO FINAL DOS AQUESTOS",
  },
  { DOMINIO: "IR_IOF", CODIGO: "6", DESCRICAO: "Isento", SIGLA: "IR" },
  { DOMINIO: "IR_IOF", CODIGO: "7", DESCRICAO: "Não isento", SIGLA: "IR" },
  { DOMINIO: "IR_IOF", CODIGO: "8", DESCRICAO: "Isento", SIGLA: "IOF" },
  { DOMINIO: "IR_IOF", CODIGO: "9", DESCRICAO: "Não isento", SIGLA: "IOF" },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1",
    DESCRICAO: "ABADIA DE GOIAS",
    SIGLA: "GO",
  },
  { DOMINIO: "MUNICIPIO", CODIGO: "2", DESCRICAO: "ABADIANIA", SIGLA: "GO" },
  { DOMINIO: "MUNICIPIO", CODIGO: "3", DESCRICAO: "ACREUNA", SIGLA: "GO" },
  { DOMINIO: "MUNICIPIO", CODIGO: "4", DESCRICAO: "ADELANDIA", SIGLA: "GO" },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5",
    DESCRICAO: "AGUA FRIA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "6",
    DESCRICAO: "AGUA LIMPA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "7",
    DESCRICAO: "AGUAS LINDAS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "8",
    DESCRICAO: "ALEXANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "9",
    DESCRICAO: "ALOANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "10",
    DESCRICAO: "ALTO HORIZONTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "11",
    DESCRICAO: "ALTO PARAISO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "12",
    DESCRICAO: "ALVORADA DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "13",
    DESCRICAO: "AMARALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "14",
    DESCRICAO: "AMERICANO DO BRASIL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "15",
    DESCRICAO: "AMORINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "16",
    DESCRICAO: "ANAPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "17",
    DESCRICAO: "ANHANGUERA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "18",
    DESCRICAO: "ANICUNS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "19",
    DESCRICAO: "APARECIDA DE GOIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "20",
    DESCRICAO: "APARECIDA DO RIO DOCE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "21",
    DESCRICAO: "APORE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "22",
    DESCRICAO: "ARACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "23",
    DESCRICAO: "ARAGARCAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "24",
    DESCRICAO: "ARAGOIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "25",
    DESCRICAO: "ARAGUAPAZ",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "26",
    DESCRICAO: "ARENOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "27",
    DESCRICAO: "ARUANA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "28",
    DESCRICAO: "AURILANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "29",
    DESCRICAO: "AVELINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "30",
    DESCRICAO: "BALIZA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "31",
    DESCRICAO: "BARRO ALTO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "32",
    DESCRICAO: "BELA VISTA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "33",
    DESCRICAO: "BOM JARDIM DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "34",
    DESCRICAO: "BOM JESUS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "35",
    DESCRICAO: "BONFINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "36",
    DESCRICAO: "BONOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "37",
    DESCRICAO: "BRAZABRANTES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "38",
    DESCRICAO: "BRITANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "39",
    DESCRICAO: "BURITI ALEGRE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "40",
    DESCRICAO: "BURITI DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "41",
    DESCRICAO: "BURITINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "42",
    DESCRICAO: "CABECEIRAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "43",
    DESCRICAO: "CACHOEIRA ALTA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "44",
    DESCRICAO: "CACHOEIRA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "45",
    DESCRICAO: "CACHOEIRA DOURADA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "46",
    DESCRICAO: "CACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "47",
    DESCRICAO: "CAIAPONIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "48",
    DESCRICAO: "CALDAS NOVAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "49",
    DESCRICAO: "CALDAZINHA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "50",
    DESCRICAO: "CAMPESTRE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "51",
    DESCRICAO: "CAMPINACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "52",
    DESCRICAO: "CAMPINORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "53",
    DESCRICAO: "CAMPO ALEGRE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "54",
    DESCRICAO: "CAMPO LIMPO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "55",
    DESCRICAO: "CAMPOS BELOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "56",
    DESCRICAO: "CAMPOS VERDES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "57",
    DESCRICAO: "CARMO DO RIO VERDE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "58",
    DESCRICAO: "CASTELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "59",
    DESCRICAO: "CATALAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "60",
    DESCRICAO: "CATURAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "61",
    DESCRICAO: "CAVALCANTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "62",
    DESCRICAO: "CERES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "63",
    DESCRICAO: "CEZARINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "64",
    DESCRICAO: "CHAPADAO DO CEU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "65",
    DESCRICAO: "CIDADE OCIDENTAL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "66",
    DESCRICAO: "COCALZINHO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "67",
    DESCRICAO: "COLINAS DO SUL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "68",
    DESCRICAO: "CORREGO DO OURO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "69",
    DESCRICAO: "CORUMBA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "70",
    DESCRICAO: "CORUMBAIBA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "71",
    DESCRICAO: "CRISTALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "72",
    DESCRICAO: "CRISTIANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "73",
    DESCRICAO: "CRIXAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "74",
    DESCRICAO: "CROMINIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "75",
    DESCRICAO: "CUMARI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "76",
    DESCRICAO: "DAMIANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "77",
    DESCRICAO: "DAMOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "78",
    DESCRICAO: "DAVINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "79",
    DESCRICAO: "DIORAMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "80",
    DESCRICAO: "DOVERLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "81",
    DESCRICAO: "EDEALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "82",
    DESCRICAO: "EDEIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "83",
    DESCRICAO: "ESTRELA DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "84",
    DESCRICAO: "FAINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "85",
    DESCRICAO: "FAZENDA NOVA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "86",
    DESCRICAO: "FIRMINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "87",
    DESCRICAO: "FLORES DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "88",
    DESCRICAO: "FORMOSA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "89",
    DESCRICAO: "FORMOSO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "90",
    DESCRICAO: "GAMELEIRA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "91",
    DESCRICAO: "DIVINOPOLIS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "92",
    DESCRICAO: "GOIANAPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "93",
    DESCRICAO: "GOIANDIRA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "94",
    DESCRICAO: "GOIANESIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "95",
    DESCRICAO: "GOIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "96",
    DESCRICAO: "GOIANIRA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "97",
    DESCRICAO: "GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "98",
    DESCRICAO: "GOIATUBA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "99",
    DESCRICAO: "GOUVELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "100",
    DESCRICAO: "GUAPO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "101",
    DESCRICAO: "GUARAITA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "102",
    DESCRICAO: "GUARANI DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "103",
    DESCRICAO: "GUARINOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "104",
    DESCRICAO: "HEITORAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "105",
    DESCRICAO: "HIDROLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "106",
    DESCRICAO: "HIDROLINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "107",
    DESCRICAO: "IACIARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "108",
    DESCRICAO: "INACIOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "109",
    DESCRICAO: "INDIARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "110",
    DESCRICAO: "INHUMAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "111",
    DESCRICAO: "IPAMERI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "112",
    DESCRICAO: "IPIRANGA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "113",
    DESCRICAO: "IPORA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "114",
    DESCRICAO: "ISRAELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "115",
    DESCRICAO: "ITABERAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "116",
    DESCRICAO: "ITAGUARI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "117",
    DESCRICAO: "ITAGUARU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "118",
    DESCRICAO: "ITAJA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "119",
    DESCRICAO: "ITAPACI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "120",
    DESCRICAO: "ITAPIRAPUA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "121",
    DESCRICAO: "ITAPURANGA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "122",
    DESCRICAO: "ITARUMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "123",
    DESCRICAO: "ITAUCU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "124",
    DESCRICAO: "ITUMBIARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "125",
    DESCRICAO: "IVOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "126",
    DESCRICAO: "JANDAIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "127",
    DESCRICAO: "JARAGUA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "128",
    DESCRICAO: "JATAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "129",
    DESCRICAO: "JAUPACI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "130",
    DESCRICAO: "JESUPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "131",
    DESCRICAO: "JOVIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "132",
    DESCRICAO: "JUSSARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "133",
    DESCRICAO: "LAGOA SANTA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "134",
    DESCRICAO: "LEOPOLDO DE BULHOES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "135",
    DESCRICAO: "LUZIANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "136",
    DESCRICAO: "MAIRIPOTABA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "137",
    DESCRICAO: "MAMBAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "138",
    DESCRICAO: "MARA ROSA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "139",
    DESCRICAO: "MARZAGAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "140",
    DESCRICAO: "MATRINCHA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "141",
    DESCRICAO: "MAURILANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "142",
    DESCRICAO: "MIMOSO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "143",
    DESCRICAO: "MINACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "144",
    DESCRICAO: "MINEIROS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "145",
    DESCRICAO: "MOIPORA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "146",
    DESCRICAO: "MONTE ALEGRE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "147",
    DESCRICAO: "MONTES CLAROS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "148",
    DESCRICAO: "MONTIVIDIU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "149",
    DESCRICAO: "MONTIVIDIU DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "150",
    DESCRICAO: "MORRINHOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "151",
    DESCRICAO: "MORRO AGUDO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "152",
    DESCRICAO: "MOSSAMEDES",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "153",
    DESCRICAO: "MOZARLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "154",
    DESCRICAO: "MUNDO NOVO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "155",
    DESCRICAO: "MUTUNOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "156",
    DESCRICAO: "NAZARIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "157",
    DESCRICAO: "NEROPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "158",
    DESCRICAO: "NIQUELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "159",
    DESCRICAO: "NOVA AMERICA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "160",
    DESCRICAO: "NOVA AURORA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "161",
    DESCRICAO: "NOVA CRIXAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "162",
    DESCRICAO: "NOVA GLORIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "163",
    DESCRICAO: "NOVA IGUACU DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "164",
    DESCRICAO: "NOVA ROMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "165",
    DESCRICAO: "NOVA VENEZA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "166",
    DESCRICAO: "NOVO BRASIL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "167",
    DESCRICAO: "NOVO GAMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "168",
    DESCRICAO: "NOVO PLANALTO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "169",
    DESCRICAO: "ORIZONA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "170",
    DESCRICAO: "OURO VERDE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "171",
    DESCRICAO: "OUVIDOR",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "172",
    DESCRICAO: "PADRE BERNARDO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "173",
    DESCRICAO: "PALESTINA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "174",
    DESCRICAO: "PALMEIRAS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "175",
    DESCRICAO: "PALMELO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "176",
    DESCRICAO: "PALMINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "177",
    DESCRICAO: "PANAMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "178",
    DESCRICAO: "PARANAIGUARA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "179",
    DESCRICAO: "PARAUNA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "180",
    DESCRICAO: "PEROLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "181",
    DESCRICAO: "PETROLINA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "182",
    DESCRICAO: "PILAR DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "183",
    DESCRICAO: "PIRACANJUBA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "184",
    DESCRICAO: "PIRANHAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "185",
    DESCRICAO: "PIRENOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "186",
    DESCRICAO: "PIRES DO RIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "187",
    DESCRICAO: "PLANALTINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "188",
    DESCRICAO: "PONTALINA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "189",
    DESCRICAO: "PORANGATU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "190",
    DESCRICAO: "PORTEIRAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "191",
    DESCRICAO: "PORTELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "192",
    DESCRICAO: "POSSE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "193",
    DESCRICAO: "PROFESSOR JAMIL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "194",
    DESCRICAO: "QUIRINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "195",
    DESCRICAO: "RIALMA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "196",
    DESCRICAO: "RIANAPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "197",
    DESCRICAO: "RIO QUENTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "198",
    DESCRICAO: "RIO VERDE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "199",
    DESCRICAO: "RUBIATABA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "200",
    DESCRICAO: "SANCLERLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "201",
    DESCRICAO: "SANTA BARBARA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "202",
    DESCRICAO: "SANTA CRUZ DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "203",
    DESCRICAO: "SANTA FE DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "204",
    DESCRICAO: "SANTA HELENA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "205",
    DESCRICAO: "SANTA ISABEL",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "206",
    DESCRICAO: "SANTA RITA DO ARAGUAIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "207",
    DESCRICAO: "SANTA RITA DO NOVO DESTINO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "208",
    DESCRICAO: "SANTA ROSA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "209",
    DESCRICAO: "SANTA TEREZA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "210",
    DESCRICAO: "SANTA TEREZINHA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "211",
    DESCRICAO: "SANTO ANTONIO DA BARRA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "212",
    DESCRICAO: "SANTO ANTONIO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "213",
    DESCRICAO: "SANTO ANTONIO DO DESCOBERTO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "214",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "215",
    DESCRICAO: "SAO FRANCISCO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "216",
    DESCRICAO: "SAO JOAO D ALIANCA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "217",
    DESCRICAO: "SAO JOAO DA PARAUNA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "218",
    DESCRICAO: "SAO LUIS DE MONTES BELOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "219",
    DESCRICAO: "SAO LUIZ DO NORTE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "220",
    DESCRICAO: "SAO MIGUEL DO ARAGUAIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "221",
    DESCRICAO: "SAO MIGUEL DO PASSA QUATRO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "222",
    DESCRICAO: "SAO PATRICIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "223",
    DESCRICAO: "SAO SIMAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "224",
    DESCRICAO: "SENADOR CANEDO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "225",
    DESCRICAO: "SERRANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "226",
    DESCRICAO: "SILVANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "227",
    DESCRICAO: "SIMOLANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "228",
    DESCRICAO: "SITIO D ABADIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "229",
    DESCRICAO: "TAQUARAL DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "230",
    DESCRICAO: "TERESINA DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "231",
    DESCRICAO: "TEREZOPOLIS DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "232",
    DESCRICAO: "TRES RANCHOS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "233",
    DESCRICAO: "TRINDADE",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "234",
    DESCRICAO: "TROMBAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "235",
    DESCRICAO: "TURVANIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "236",
    DESCRICAO: "TURVELANDIA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "237",
    DESCRICAO: "UIRAPURU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "238",
    DESCRICAO: "URUACU",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "239",
    DESCRICAO: "URUANA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "240",
    DESCRICAO: "URUTAI",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "241",
    DESCRICAO: "VALPARAISO DE GOIAS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "242",
    DESCRICAO: "VARJAO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "243",
    DESCRICAO: "VIANOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "244",
    DESCRICAO: "VICENTINOPOLIS",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "245",
    DESCRICAO: "VILA BOA",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "246",
    DESCRICAO: "VILA PROPICIO",
    SIGLA: "GO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "247",
    DESCRICAO: "ACORIZAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "248",
    DESCRICAO: "AGUA BOA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "249",
    DESCRICAO: "ALTA FLORESTA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "250",
    DESCRICAO: "ALTO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "251",
    DESCRICAO: "ALTO BOA VISTA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "252",
    DESCRICAO: "ALTO GARCAS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "253",
    DESCRICAO: "ALTO PARAGUAI",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "254",
    DESCRICAO: "ALTO TAQUARI",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "255",
    DESCRICAO: "APIACAS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "256",
    DESCRICAO: "ARAGUAIANA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "257",
    DESCRICAO: "ARAGUAINHA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "258",
    DESCRICAO: "ARAPUTANGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "259",
    DESCRICAO: "ARENAPOLIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "260",
    DESCRICAO: "ARIPUANA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "261",
    DESCRICAO: "BARAO DE MELGACO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "262",
    DESCRICAO: "BARRA DO BUGRES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "263",
    DESCRICAO: "BARRA DO GARCAS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "264",
    DESCRICAO: "BOM JESUS DO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "265",
    DESCRICAO: "BRASNORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "266",
    DESCRICAO: "CACERES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "267",
    DESCRICAO: "CAMPINAPOLIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "268",
    DESCRICAO: "CAMPO NOVO DO PARECIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "269",
    DESCRICAO: "CAMPO VERDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "270",
    DESCRICAO: "CAMPOS DE JULIO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "271",
    DESCRICAO: "CANABRAVA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "272",
    DESCRICAO: "CANARANA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "273",
    DESCRICAO: "CARLINDA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "274",
    DESCRICAO: "CASTANHEIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "275",
    DESCRICAO: "CHAPADA DOS GUIMARAES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "276",
    DESCRICAO: "CLAUDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "277",
    DESCRICAO: "COCALINHO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "278",
    DESCRICAO: "COLIDER",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "279",
    DESCRICAO: "COLNIZA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "280",
    DESCRICAO: "COMODORO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "281",
    DESCRICAO: "CONFRESA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "282",
    DESCRICAO: "CONQUISTA D OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "283",
    DESCRICAO: "COTRIGUACU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "284",
    DESCRICAO: "CUIABA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "285",
    DESCRICAO: "CURVELANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "286",
    DESCRICAO: "DENISE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "287",
    DESCRICAO: "DIAMANTINO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "288",
    DESCRICAO: "DOM AQUINO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "289",
    DESCRICAO: "FELIZ NATAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "290",
    DESCRICAO: "FIGUEIROPOLIS D OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "291",
    DESCRICAO: "GAUCHA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "292",
    DESCRICAO: "GENERAL CARNEIRO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "293",
    DESCRICAO: "GLORIA D OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "294",
    DESCRICAO: "GUARANTA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "295",
    DESCRICAO: "GUIRATINGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "296",
    DESCRICAO: "INDIAVAI",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "297",
    DESCRICAO: "IPIRANGA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "298",
    DESCRICAO: "ITANHANGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "299",
    DESCRICAO: "ITAUBA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "300",
    DESCRICAO: "ITIQUIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "301",
    DESCRICAO: "JACIARA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "302",
    DESCRICAO: "JANGADA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "303",
    DESCRICAO: "JAURU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "304",
    DESCRICAO: "JUARA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "305",
    DESCRICAO: "JUINA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "306",
    DESCRICAO: "JURUENA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "307",
    DESCRICAO: "JUSCIMEIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "308",
    DESCRICAO: "LAMBARI D OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "309",
    DESCRICAO: "LUCAS DO RIO VERDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "310",
    DESCRICAO: "LUCIARA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "311",
    DESCRICAO: "VILA BELA DA SANTISSIMA TRINDADE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "312",
    DESCRICAO: "MARCELANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "313",
    DESCRICAO: "MATUPA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "314",
    DESCRICAO: "MIRASSOL D OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "315",
    DESCRICAO: "NOBRES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "316",
    DESCRICAO: "NORTELANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "317",
    DESCRICAO: "NOSSA SENHORA DO LIVRAMENTO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "318",
    DESCRICAO: "NOVA BANDEIRANTES",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "319",
    DESCRICAO: "NOVA NAZARE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "320",
    DESCRICAO: "NOVA LACERDA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "321",
    DESCRICAO: "NOVA SANTA HELENA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "322",
    DESCRICAO: "NOVA BRASILANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "323",
    DESCRICAO: "NOVA CANAA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "324",
    DESCRICAO: "NOVA MUTUM",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "325",
    DESCRICAO: "NOVA OLIMPIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "326",
    DESCRICAO: "NOVA UBIRATA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "327",
    DESCRICAO: "NOVA XAVANTINA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "328",
    DESCRICAO: "NOVO MUNDO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "329",
    DESCRICAO: "NOVO HORIZONTE DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "330",
    DESCRICAO: "NOVO SAO JOAQUIM",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "331",
    DESCRICAO: "PARANAITA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "332",
    DESCRICAO: "PARANATINGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "333",
    DESCRICAO: "NOVO SANTO ANTONIO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "334",
    DESCRICAO: "PEDRA PRETA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "335",
    DESCRICAO: "PEIXOTO DE AZEVEDO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "336",
    DESCRICAO: "PLANALTO DA SERRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "337",
    DESCRICAO: "POCONE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "338",
    DESCRICAO: "PONTAL DO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "339",
    DESCRICAO: "PONTE BRANCA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "340",
    DESCRICAO: "PONTES E LACERDA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "341",
    DESCRICAO: "PORTO ALEGRE DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "342",
    DESCRICAO: "PORTO DOS GAUCHOS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "343",
    DESCRICAO: "PORTO ESPERIDIAO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "344",
    DESCRICAO: "PORTO ESTRELA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "345",
    DESCRICAO: "POXOREU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "346",
    DESCRICAO: "PRIMAVERA DO LESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "347",
    DESCRICAO: "QUERENCIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "348",
    DESCRICAO: "SAO JOSE DOS QUATRO MARCOS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "349",
    DESCRICAO: "RESERVA DO CABACAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "350",
    DESCRICAO: "RIBEIRAO CASCALHEIRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "351",
    DESCRICAO: "RIBEIRAOZINHO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "352",
    DESCRICAO: "RIO BRANCO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "353",
    DESCRICAO: "SANTA CARMEM",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "354",
    DESCRICAO: "SANTO AFONSO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "355",
    DESCRICAO: "SAO JOSE DO POVO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "356",
    DESCRICAO: "SAO JOSE DO RIO CLARO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "357",
    DESCRICAO: "SAO JOSE DO XINGU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "358",
    DESCRICAO: "SAO PEDRO DA CIPA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "359",
    DESCRICAO: "RONDOLANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "360",
    DESCRICAO: "RONDONOPOLIS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "361",
    DESCRICAO: "ROSARIO OESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "362",
    DESCRICAO: "SANTA CRUZ DO XINGU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "363",
    DESCRICAO: "SALTO DO CEU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "364",
    DESCRICAO: "SANTA RITA DO TRIVELATO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "365",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "366",
    DESCRICAO: "SANTO ANTONIO DO LESTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "367",
    DESCRICAO: "SANTO ANTONIO DO LEVERGER",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "368",
    DESCRICAO: "SAO FELIX DO ARAGUAIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "369",
    DESCRICAO: "SAPEZAL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "370",
    DESCRICAO: "SERRA NOVA DOURADA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "371",
    DESCRICAO: "SINOP",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "372",
    DESCRICAO: "SORRISO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "373",
    DESCRICAO: "TABAPORA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "374",
    DESCRICAO: "TANGARA DA SERRA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "375",
    DESCRICAO: "TAPURAH",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "376",
    DESCRICAO: "TERRA NOVA DO NORTE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "377",
    DESCRICAO: "TESOURO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "378",
    DESCRICAO: "TORIXOREU",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "379",
    DESCRICAO: "UNIAO DO SUL",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "380",
    DESCRICAO: "VALE DE SAO DOMINGOS",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "381",
    DESCRICAO: "VARZEA GRANDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "382",
    DESCRICAO: "VERA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "383",
    DESCRICAO: "VILA RICA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "384",
    DESCRICAO: "NOVA GUARITA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "385",
    DESCRICAO: "NOVA MARILANDIA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "386",
    DESCRICAO: "NOVA MARINGA",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "387",
    DESCRICAO: "NOVA MONTE VERDE",
    SIGLA: "MT",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "388",
    DESCRICAO: "BRASILIA",
    SIGLA: "DF",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "389",
    DESCRICAO: "AGUA CLARA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "390",
    DESCRICAO: "ALCINOPOLIS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "391",
    DESCRICAO: "AMAMBAI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "392",
    DESCRICAO: "ANASTACIO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "393",
    DESCRICAO: "ANAURILANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "394",
    DESCRICAO: "ANGELICA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "395",
    DESCRICAO: "ANTONIO JOAO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "396",
    DESCRICAO: "APARECIDA DO TABOADO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "397",
    DESCRICAO: "AQUIDAUANA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "398",
    DESCRICAO: "ARAL MOREIRA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "399",
    DESCRICAO: "BANDEIRANTES",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "400",
    DESCRICAO: "BATAGUASSU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "401",
    DESCRICAO: "BATAYPORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "402",
    DESCRICAO: "BELA VISTA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "403",
    DESCRICAO: "BODOQUENA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "404",
    DESCRICAO: "BONITO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "405",
    DESCRICAO: "BRASILANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "406",
    DESCRICAO: "CAARAPO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "407",
    DESCRICAO: "CAMAPUA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "408",
    DESCRICAO: "CAMPO GRANDE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "409",
    DESCRICAO: "CARACOL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "410",
    DESCRICAO: "CASSILANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "411",
    DESCRICAO: "CHAPADAO DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "412",
    DESCRICAO: "CORGUINHO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "413",
    DESCRICAO: "CORONEL SAPUCAIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "414",
    DESCRICAO: "CORUMBA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "415",
    DESCRICAO: "COSTA RICA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "416",
    DESCRICAO: "COXIM",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "417",
    DESCRICAO: "DEODAPOLIS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "418",
    DESCRICAO: "DOIS IRMAOS DO BURITI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "419",
    DESCRICAO: "DOURADINA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "420",
    DESCRICAO: "DOURADOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "421",
    DESCRICAO: "ELDORADO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "422",
    DESCRICAO: "FATIMA DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "423",
    DESCRICAO: "FIGUEIRAO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "424",
    DESCRICAO: "GLORIA DE DOURADOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "425",
    DESCRICAO: "GUIA LOPES DA LAGUNA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "426",
    DESCRICAO: "IGUATEMI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "427",
    DESCRICAO: "INOCENCIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "428",
    DESCRICAO: "ITAPORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "429",
    DESCRICAO: "ITAQUIRAI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "430",
    DESCRICAO: "IVINHEMA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "431",
    DESCRICAO: "JAPORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "432",
    DESCRICAO: "JARAGUARI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "433",
    DESCRICAO: "JARDIM",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "434",
    DESCRICAO: "JATEI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "435",
    DESCRICAO: "JUTI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "436",
    DESCRICAO: "LADARIO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "437",
    DESCRICAO: "LAGUNA CARAPA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "438",
    DESCRICAO: "MARACAJU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "439",
    DESCRICAO: "MIRANDA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "440",
    DESCRICAO: "MUNDO NOVO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "441",
    DESCRICAO: "NAVIRAI",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "442",
    DESCRICAO: "NIOAQUE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "443",
    DESCRICAO: "NOVA ALVORADA DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "444",
    DESCRICAO: "NOVA ANDRADINA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "445",
    DESCRICAO: "NOVO HORIZONTE DO SUL",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "446",
    DESCRICAO: "PARANAIBA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "447",
    DESCRICAO: "PARANHOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "448",
    DESCRICAO: "PEDRO GOMES",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "449",
    DESCRICAO: "PONTA PORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "450",
    DESCRICAO: "PORTO MURTINHO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "451",
    DESCRICAO: "RIBAS DO RIO PARDO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "452",
    DESCRICAO: "RIO BRILHANTE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "453",
    DESCRICAO: "RIO NEGRO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "454",
    DESCRICAO: "RIO VERDE DE MATO GROSSO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "455",
    DESCRICAO: "ROCHEDO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "456",
    DESCRICAO: "SANTA RITA DO PARDO",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "457",
    DESCRICAO: "SAO GABRIEL DO OESTE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "458",
    DESCRICAO: "SETE QUEDAS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "459",
    DESCRICAO: "SELVIRIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "460",
    DESCRICAO: "SIDROLANDIA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "461",
    DESCRICAO: "SONORA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "462",
    DESCRICAO: "TACURU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "463",
    DESCRICAO: "TAQUARUSSU",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "464",
    DESCRICAO: "TERENOS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "465",
    DESCRICAO: "TRES LAGOAS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "466",
    DESCRICAO: "VICENTINA",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "467",
    DESCRICAO: "ADAMANTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "468",
    DESCRICAO: "ADOLFO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "469",
    DESCRICAO: "AGUAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "470",
    DESCRICAO: "AGUAS DA PRATA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "471",
    DESCRICAO: "AGUAS DE LINDOIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "472",
    DESCRICAO: "AGUAS DE SANTA BARBARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "473",
    DESCRICAO: "AGUAS DE SAO PEDRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "474",
    DESCRICAO: "AGUDOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "475",
    DESCRICAO: "ALAMBARI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "476",
    DESCRICAO: "ALFREDO MARCONDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "477",
    DESCRICAO: "ALTAIR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "478",
    DESCRICAO: "ALTINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "479",
    DESCRICAO: "ALTO ALEGRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "480",
    DESCRICAO: "ALUMINIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "481",
    DESCRICAO: "ALVARES FLORENCE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "482",
    DESCRICAO: "ALVARES MACHADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "483",
    DESCRICAO: "ALVARO DE CARVALHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "484",
    DESCRICAO: "ALVINLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "485",
    DESCRICAO: "AMERICANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "486",
    DESCRICAO: "AMERICO BRASILIENSE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "487",
    DESCRICAO: "AMERICO DE CAMPOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "488",
    DESCRICAO: "AMPARO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "489",
    DESCRICAO: "ANALANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "490",
    DESCRICAO: "ANDRADINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "491",
    DESCRICAO: "ANGATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "492",
    DESCRICAO: "ANHEMBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "493",
    DESCRICAO: "ANHUMAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "494",
    DESCRICAO: "APARECIDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "495",
    DESCRICAO: "APARECIDA D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "496",
    DESCRICAO: "APIAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "497",
    DESCRICAO: "ARACARIGUAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "498",
    DESCRICAO: "ARACATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "499",
    DESCRICAO: "ARACOIABA DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "500",
    DESCRICAO: "ARAMINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "501",
    DESCRICAO: "ARANDU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "502",
    DESCRICAO: "ARAPEI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "503",
    DESCRICAO: "ARARAQUARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "504",
    DESCRICAO: "ARARAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "505",
    DESCRICAO: "ARCO-IRIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "506",
    DESCRICAO: "AREALVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "507",
    DESCRICAO: "AREIAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "508",
    DESCRICAO: "AREIOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "509",
    DESCRICAO: "ARIRANHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "510",
    DESCRICAO: "ARTUR NOGUEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "511",
    DESCRICAO: "ARUJA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "512",
    DESCRICAO: "ASPASIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "513",
    DESCRICAO: "ASSIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "514",
    DESCRICAO: "ATIBAIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "515",
    DESCRICAO: "AURIFLAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "516",
    DESCRICAO: "AVAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "517",
    DESCRICAO: "AVANHANDAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "518",
    DESCRICAO: "AVARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "519",
    DESCRICAO: "BADY BASSITT",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "520",
    DESCRICAO: "BALBINOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "521",
    DESCRICAO: "BALSAMO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "522",
    DESCRICAO: "BANANAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "523",
    DESCRICAO: "BARAO DE ANTONINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "524",
    DESCRICAO: "BARBOSA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "525",
    DESCRICAO: "BARIRI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "526",
    DESCRICAO: "BARRA BONITA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "527",
    DESCRICAO: "BARRA DO CHAPEU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "528",
    DESCRICAO: "BARRA DO TURVO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "529",
    DESCRICAO: "BARRETOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "530",
    DESCRICAO: "BARRINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "531",
    DESCRICAO: "BARUERI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "532",
    DESCRICAO: "BASTOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "533",
    DESCRICAO: "BATATAIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "534",
    DESCRICAO: "BAURU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "535",
    DESCRICAO: "BEBEDOURO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "536",
    DESCRICAO: "BENTO DE ABREU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "537",
    DESCRICAO: "BERNARDINO DE CAMPOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "538",
    DESCRICAO: "BERTIOGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "539",
    DESCRICAO: "BILAC",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "540",
    DESCRICAO: "BIRIGUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "541",
    DESCRICAO: "BIRITIBA-MIRIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "542",
    DESCRICAO: "BOA ESPERANCA DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "543",
    DESCRICAO: "BOCAINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "544",
    DESCRICAO: "BOFETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "545",
    DESCRICAO: "BOITUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "546",
    DESCRICAO: "BOM JESUS DOS PERDOES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "547",
    DESCRICAO: "BOM SUCESSO DE ITARARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "548",
    DESCRICAO: "BORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "549",
    DESCRICAO: "BORACEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "550",
    DESCRICAO: "BORBOREMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "551",
    DESCRICAO: "BOREBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "552",
    DESCRICAO: "BOTUCATU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "553",
    DESCRICAO: "BRAGANCA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "554",
    DESCRICAO: "BRAUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "555",
    DESCRICAO: "BREJO ALEGRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "556",
    DESCRICAO: "BRODOWSKI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "557",
    DESCRICAO: "BROTAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "558",
    DESCRICAO: "BURI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "559",
    DESCRICAO: "BURITAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "560",
    DESCRICAO: "BURITIZAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "561",
    DESCRICAO: "CABRALIA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "562",
    DESCRICAO: "CABREUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "563",
    DESCRICAO: "CACAPAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "564",
    DESCRICAO: "CACHOEIRA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "565",
    DESCRICAO: "CACONDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "566",
    DESCRICAO: "CAFELANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "567",
    DESCRICAO: "CAIABU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "568",
    DESCRICAO: "CAIEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "569",
    DESCRICAO: "CAIUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "570",
    DESCRICAO: "CAJAMAR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "571",
    DESCRICAO: "CAJATI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "572",
    DESCRICAO: "CAJOBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "573",
    DESCRICAO: "CAJURU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "574",
    DESCRICAO: "CAMPINA DO MONTE ALEGRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "575",
    DESCRICAO: "CAMPINAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "576",
    DESCRICAO: "CAMPO LIMPO PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "577",
    DESCRICAO: "CAMPOS DO JORDAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "578",
    DESCRICAO: "CAMPOS NOVOS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "579",
    DESCRICAO: "CANANEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "580",
    DESCRICAO: "CANAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "581",
    DESCRICAO: "CANDIDO MOTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "582",
    DESCRICAO: "CANDIDO RODRIGUES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "583",
    DESCRICAO: "CANITAR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "584",
    DESCRICAO: "CAPAO BONITO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "585",
    DESCRICAO: "CAPELA DO ALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "586",
    DESCRICAO: "CAPIVARI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "587",
    DESCRICAO: "CARAGUATATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "588",
    DESCRICAO: "CARAPICUIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "589",
    DESCRICAO: "CARDOSO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "590",
    DESCRICAO: "CASA BRANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "591",
    DESCRICAO: "CASSIA DOS COQUEIROS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "592",
    DESCRICAO: "CASTILHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "593",
    DESCRICAO: "CATANDUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "594",
    DESCRICAO: "CATIGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "595",
    DESCRICAO: "CEDRAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "596",
    DESCRICAO: "CERQUEIRA CESAR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "597",
    DESCRICAO: "CERQUILHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "598",
    DESCRICAO: "CESARIO LANGE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "599",
    DESCRICAO: "CHARQUEADA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "600",
    DESCRICAO: "CLEMENTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "601",
    DESCRICAO: "COLINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "602",
    DESCRICAO: "COLOMBIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "603",
    DESCRICAO: "CONCHAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "604",
    DESCRICAO: "CONCHAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "605",
    DESCRICAO: "CORDEIROPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "606",
    DESCRICAO: "COROADOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "607",
    DESCRICAO: "CORONEL MACEDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "608",
    DESCRICAO: "CORUMBATAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "609",
    DESCRICAO: "COSMOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "610",
    DESCRICAO: "COSMORAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "611",
    DESCRICAO: "COTIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "612",
    DESCRICAO: "CRAVINHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "613",
    DESCRICAO: "CRISTAIS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "614",
    DESCRICAO: "CRUZALIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "615",
    DESCRICAO: "CRUZEIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "616",
    DESCRICAO: "CUBATAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "617",
    DESCRICAO: "CUNHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "618",
    DESCRICAO: "DESCALVADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "619",
    DESCRICAO: "DIADEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "620",
    DESCRICAO: "DIRCE REIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "621",
    DESCRICAO: "DIVINOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "622",
    DESCRICAO: "DOBRADA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "623",
    DESCRICAO: "DOIS CORREGOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "624",
    DESCRICAO: "DOLCINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "625",
    DESCRICAO: "DOURADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "626",
    DESCRICAO: "DRACENA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "627",
    DESCRICAO: "DUARTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "628",
    DESCRICAO: "DUMONT",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "629",
    DESCRICAO: "ECHAPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "630",
    DESCRICAO: "ELDORADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "631",
    DESCRICAO: "ELIAS FAUSTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "632",
    DESCRICAO: "ELISIARIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "633",
    DESCRICAO: "EMBAUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "634",
    DESCRICAO: "EMBU DAS ARTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "635",
    DESCRICAO: "EMBU-GUACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "636",
    DESCRICAO: "EMILIANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "637",
    DESCRICAO: "ENGENHEIRO COELHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "638",
    DESCRICAO: "ESPIRITO SANTO DO PINHAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "639",
    DESCRICAO: "ESPIRITO SANTO DO TURVO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "640",
    DESCRICAO: "ESTRELA D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "641",
    DESCRICAO: "ESTRELA DO NORTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "642",
    DESCRICAO: "EUCLIDES DA CUNHA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "643",
    DESCRICAO: "FARTURA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "644",
    DESCRICAO: "FERNANDOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "645",
    DESCRICAO: "FERNANDO PRESTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "646",
    DESCRICAO: "FERNAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "647",
    DESCRICAO: "FERRAZ DE VASCONCELOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "648",
    DESCRICAO: "FLORA RICA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "649",
    DESCRICAO: "FLOREAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "650",
    DESCRICAO: "FLORIDA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "651",
    DESCRICAO: "FLORINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "652",
    DESCRICAO: "FRANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "653",
    DESCRICAO: "FRANCISCO MORATO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "654",
    DESCRICAO: "FRANCO DA ROCHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "655",
    DESCRICAO: "GABRIEL MONTEIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "656",
    DESCRICAO: "GALIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "657",
    DESCRICAO: "GARCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "658",
    DESCRICAO: "GASTAO VIDIGAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "659",
    DESCRICAO: "GAVIAO PEIXOTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "660",
    DESCRICAO: "GENERAL SALGADO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "661",
    DESCRICAO: "GETULINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "662",
    DESCRICAO: "GLICERIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "663",
    DESCRICAO: "GUAICARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "664",
    DESCRICAO: "GUAIMBE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "665",
    DESCRICAO: "GUAIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "666",
    DESCRICAO: "GUAPIACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "667",
    DESCRICAO: "GUAPIARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "668",
    DESCRICAO: "GUARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "669",
    DESCRICAO: "GUARACAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "670",
    DESCRICAO: "GUARACI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "671",
    DESCRICAO: "GUARANI D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "672",
    DESCRICAO: "GUARANTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "673",
    DESCRICAO: "GUARARAPES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "674",
    DESCRICAO: "GUARAREMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "675",
    DESCRICAO: "GUARATINGUETA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "676",
    DESCRICAO: "GUAREI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "677",
    DESCRICAO: "GUARIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "678",
    DESCRICAO: "GUARUJA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "679",
    DESCRICAO: "GUARULHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "680",
    DESCRICAO: "GUATAPARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "681",
    DESCRICAO: "GUZOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "682",
    DESCRICAO: "HERCULANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "683",
    DESCRICAO: "HOLAMBRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "684",
    DESCRICAO: "HORTOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "685",
    DESCRICAO: "IACANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "686",
    DESCRICAO: "IACRI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "687",
    DESCRICAO: "IARAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "688",
    DESCRICAO: "IBATE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "689",
    DESCRICAO: "IBIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "690",
    DESCRICAO: "IBIRAREMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "691",
    DESCRICAO: "IBITINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "692",
    DESCRICAO: "IBIUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "693",
    DESCRICAO: "ICEM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "694",
    DESCRICAO: "IEPE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "695",
    DESCRICAO: "IGARACU DO TIETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "696",
    DESCRICAO: "IGARAPAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "697",
    DESCRICAO: "IGARATA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "698",
    DESCRICAO: "IGUAPE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "699",
    DESCRICAO: "ILHABELA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "700",
    DESCRICAO: "ILHA COMPRIDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "701",
    DESCRICAO: "ILHA SOLTEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "702",
    DESCRICAO: "INDAIATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "703",
    DESCRICAO: "INDIANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "704",
    DESCRICAO: "INDIAPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "705",
    DESCRICAO: "INUBIA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "706",
    DESCRICAO: "IPAUSSU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "707",
    DESCRICAO: "IPERO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "708",
    DESCRICAO: "IPEUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "709",
    DESCRICAO: "IPIGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "710",
    DESCRICAO: "IPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "711",
    DESCRICAO: "IPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "712",
    DESCRICAO: "IRACEMAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "713",
    DESCRICAO: "IRAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "714",
    DESCRICAO: "IRAPURU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "715",
    DESCRICAO: "ITABERA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "716",
    DESCRICAO: "ITAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "717",
    DESCRICAO: "ITAJOBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "718",
    DESCRICAO: "ITAJU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "719",
    DESCRICAO: "ITANHAEM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "720",
    DESCRICAO: "ITAOCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "721",
    DESCRICAO: "ITAPECERICA DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "722",
    DESCRICAO: "ITAPETININGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "723",
    DESCRICAO: "ITAPEVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "724",
    DESCRICAO: "ITAPEVI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "725",
    DESCRICAO: "ITAPIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "726",
    DESCRICAO: "ITAPIRAPUA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "727",
    DESCRICAO: "ITAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "728",
    DESCRICAO: "ITAPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "729",
    DESCRICAO: "ITAPUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "730",
    DESCRICAO: "ITAPURA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "731",
    DESCRICAO: "ITAQUAQUECETUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "732",
    DESCRICAO: "ITARARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "733",
    DESCRICAO: "ITARIRI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "734",
    DESCRICAO: "ITATIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "735",
    DESCRICAO: "ITATINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "736",
    DESCRICAO: "ITIRAPINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "737",
    DESCRICAO: "ITIRAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "738",
    DESCRICAO: "ITOBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "739",
    DESCRICAO: "ITU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "740",
    DESCRICAO: "ITUPEVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "741",
    DESCRICAO: "ITUVERAVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "742",
    DESCRICAO: "JABORANDI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "743",
    DESCRICAO: "JABOTICABAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "744",
    DESCRICAO: "JACAREI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "745",
    DESCRICAO: "JACI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "746",
    DESCRICAO: "JACUPIRANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "747",
    DESCRICAO: "JAGUARIUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "748",
    DESCRICAO: "JALES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "749",
    DESCRICAO: "JAMBEIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "750",
    DESCRICAO: "JANDIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "751",
    DESCRICAO: "JARDINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "752",
    DESCRICAO: "JARINU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "753",
    DESCRICAO: "JAU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "754",
    DESCRICAO: "JERIQUARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "755",
    DESCRICAO: "JOANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "756",
    DESCRICAO: "JOAO RAMALHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "757",
    DESCRICAO: "JOSE BONIFACIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "758",
    DESCRICAO: "JULIO MESQUITA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "759",
    DESCRICAO: "JUMIRIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "760",
    DESCRICAO: "JUNDIAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "761",
    DESCRICAO: "JUNQUEIROPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "762",
    DESCRICAO: "JUQUIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "763",
    DESCRICAO: "JUQUITIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "764",
    DESCRICAO: "LAGOINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "765",
    DESCRICAO: "LARANJAL PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "766",
    DESCRICAO: "LAVINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "767",
    DESCRICAO: "LAVRINHAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "768",
    DESCRICAO: "LEME",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "769",
    DESCRICAO: "LENCOIS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "770",
    DESCRICAO: "LIMEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "771",
    DESCRICAO: "LINDOIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "772",
    DESCRICAO: "LINS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "773",
    DESCRICAO: "LORENA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "774",
    DESCRICAO: "LOURDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "775",
    DESCRICAO: "LOUVEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "776",
    DESCRICAO: "LUCELIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "777",
    DESCRICAO: "LUCIANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "778",
    DESCRICAO: "LUIS ANTONIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "779",
    DESCRICAO: "LUIZIANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "780",
    DESCRICAO: "LUPERCIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "781",
    DESCRICAO: "LUTECIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "782",
    DESCRICAO: "MACATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "783",
    DESCRICAO: "MACAUBAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "784",
    DESCRICAO: "MACEDONIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "785",
    DESCRICAO: "MAGDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "786",
    DESCRICAO: "MAIRINQUE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "787",
    DESCRICAO: "MAIRIPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "788",
    DESCRICAO: "MANDURI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "789",
    DESCRICAO: "MARABA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "790",
    DESCRICAO: "MARACAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "791",
    DESCRICAO: "MARAPOAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "792",
    DESCRICAO: "MARIAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "793",
    DESCRICAO: "MARILIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "794",
    DESCRICAO: "MARINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "795",
    DESCRICAO: "MARTINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "796",
    DESCRICAO: "MATAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "797",
    DESCRICAO: "MAUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "798",
    DESCRICAO: "MENDONCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "799",
    DESCRICAO: "MERIDIANO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "800",
    DESCRICAO: "MESOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "801",
    DESCRICAO: "MIGUELOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "802",
    DESCRICAO: "MINEIROS DO TIETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "803",
    DESCRICAO: "MIRACATU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "804",
    DESCRICAO: "MIRA ESTRELA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "805",
    DESCRICAO: "MIRANDOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "806",
    DESCRICAO: "MIRANTE DO PARANAPANEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "807",
    DESCRICAO: "MIRASSOL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "808",
    DESCRICAO: "MIRASSOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "809",
    DESCRICAO: "MOCOCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "810",
    DESCRICAO: "MOGI DAS CRUZES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "811",
    DESCRICAO: "MOGI GUACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "812",
    DESCRICAO: "MOGI MIRIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "813",
    DESCRICAO: "MOMBUCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "814",
    DESCRICAO: "MONCOES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "815",
    DESCRICAO: "MONGAGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "816",
    DESCRICAO: "MONTE ALEGRE DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "817",
    DESCRICAO: "MONTE ALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "818",
    DESCRICAO: "MONTE APRAZIVEL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "819",
    DESCRICAO: "MONTE AZUL PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "820",
    DESCRICAO: "MONTE CASTELO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "821",
    DESCRICAO: "MONTEIRO LOBATO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "822",
    DESCRICAO: "MONTE MOR",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "823",
    DESCRICAO: "MORRO AGUDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "824",
    DESCRICAO: "MORUNGABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "825",
    DESCRICAO: "MOTUCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "826",
    DESCRICAO: "MURUTINGA DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "827",
    DESCRICAO: "NANTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "828",
    DESCRICAO: "NARANDIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "829",
    DESCRICAO: "NATIVIDADE DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "830",
    DESCRICAO: "NAZARE PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "831",
    DESCRICAO: "NEVES PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "832",
    DESCRICAO: "NHANDEARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "833",
    DESCRICAO: "NIPOA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "834",
    DESCRICAO: "NOVA ALIANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "835",
    DESCRICAO: "NOVA CAMPINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "836",
    DESCRICAO: "NOVA CANAA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "837",
    DESCRICAO: "NOVA CASTILHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "838",
    DESCRICAO: "NOVA EUROPA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "839",
    DESCRICAO: "NOVA GRANADA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "840",
    DESCRICAO: "NOVA GUATAPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "841",
    DESCRICAO: "NOVA INDEPENDENCIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "842",
    DESCRICAO: "NOVAIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "843",
    DESCRICAO: "NOVA LUZITANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "844",
    DESCRICAO: "NOVA ODESSA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "845",
    DESCRICAO: "NOVO HORIZONTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "846",
    DESCRICAO: "NUPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "847",
    DESCRICAO: "OCAUCU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "848",
    DESCRICAO: "OLEO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "849",
    DESCRICAO: "OLIMPIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "850",
    DESCRICAO: "ONDA VERDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "851",
    DESCRICAO: "ORIENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "852",
    DESCRICAO: "ORINDIUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "853",
    DESCRICAO: "ORLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "854",
    DESCRICAO: "OSASCO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "855",
    DESCRICAO: "OSCAR BRESSANE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "856",
    DESCRICAO: "OSVALDO CRUZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "857",
    DESCRICAO: "OURINHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "858",
    DESCRICAO: "OUROESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "859",
    DESCRICAO: "OURO VERDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "860",
    DESCRICAO: "PACAEMBU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "861",
    DESCRICAO: "PALESTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "862",
    DESCRICAO: "PALMARES PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "863",
    DESCRICAO: "PALMEIRA D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "864",
    DESCRICAO: "PALMITAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "865",
    DESCRICAO: "PANORAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "866",
    DESCRICAO: "PARAGUACU PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "867",
    DESCRICAO: "PARAIBUNA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "868",
    DESCRICAO: "PARAISO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "869",
    DESCRICAO: "PARANAPANEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "870",
    DESCRICAO: "PARANAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "871",
    DESCRICAO: "PARAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "872",
    DESCRICAO: "PARDINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "873",
    DESCRICAO: "PARIQUERA-ACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "874",
    DESCRICAO: "PARISI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "875",
    DESCRICAO: "PATROCINIO PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "876",
    DESCRICAO: "PAULICEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "877",
    DESCRICAO: "PAULINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "878",
    DESCRICAO: "PAULISTANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "879",
    DESCRICAO: "PAULO DE FARIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "880",
    DESCRICAO: "PEDERNEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "881",
    DESCRICAO: "PEDRA BELA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "882",
    DESCRICAO: "PEDRANOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "883",
    DESCRICAO: "PEDREGULHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "884",
    DESCRICAO: "PEDREIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "885",
    DESCRICAO: "PEDRINHAS PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "886",
    DESCRICAO: "PEDRO DE TOLEDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "887",
    DESCRICAO: "PENAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "888",
    DESCRICAO: "PEREIRA BARRETO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "889",
    DESCRICAO: "PEREIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "890",
    DESCRICAO: "PERUIBE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "891",
    DESCRICAO: "PIACATU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "892",
    DESCRICAO: "PIEDADE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "893",
    DESCRICAO: "PILAR DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "894",
    DESCRICAO: "PINDAMONHANGABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "895",
    DESCRICAO: "PINDORAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "896",
    DESCRICAO: "PINHALZINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "897",
    DESCRICAO: "PIQUEROBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "898",
    DESCRICAO: "PIQUETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "899",
    DESCRICAO: "PIRACAIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "900",
    DESCRICAO: "PIRACICABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "901",
    DESCRICAO: "PIRAJU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "902",
    DESCRICAO: "PIRAJUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "903",
    DESCRICAO: "PIRANGI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "904",
    DESCRICAO: "PIRAPORA DO BOM JESUS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "905",
    DESCRICAO: "PIRAPOZINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "906",
    DESCRICAO: "PIRASSUNUNGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "907",
    DESCRICAO: "PIRATININGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "908",
    DESCRICAO: "PITANGUEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "909",
    DESCRICAO: "PLANALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "910",
    DESCRICAO: "PLATINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "911",
    DESCRICAO: "POA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "912",
    DESCRICAO: "POLONI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "913",
    DESCRICAO: "POMPEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "914",
    DESCRICAO: "PONGAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "915",
    DESCRICAO: "PONTAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "916",
    DESCRICAO: "PONTALINDA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "917",
    DESCRICAO: "PONTES GESTAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "918",
    DESCRICAO: "POPULINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "919",
    DESCRICAO: "PORANGABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "920",
    DESCRICAO: "PORTO FELIZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "921",
    DESCRICAO: "PORTO FERREIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "922",
    DESCRICAO: "POTIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "923",
    DESCRICAO: "POTIRENDABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "924",
    DESCRICAO: "PRACINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "925",
    DESCRICAO: "PRADOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "926",
    DESCRICAO: "PRAIA GRANDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "927",
    DESCRICAO: "PRATANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "928",
    DESCRICAO: "PRESIDENTE ALVES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "929",
    DESCRICAO: "PRESIDENTE BERNARDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "930",
    DESCRICAO: "PRESIDENTE EPITACIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "931",
    DESCRICAO: "PRESIDENTE PRUDENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "932",
    DESCRICAO: "PRESIDENTE VENCESLAU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "933",
    DESCRICAO: "PROMISSAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "934",
    DESCRICAO: "QUADRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "935",
    DESCRICAO: "QUATA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "936",
    DESCRICAO: "QUEIROZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "937",
    DESCRICAO: "QUELUZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "938",
    DESCRICAO: "QUINTANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "939",
    DESCRICAO: "RAFARD",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "940",
    DESCRICAO: "RANCHARIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "941",
    DESCRICAO: "REDENCAO DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "942",
    DESCRICAO: "REGENTE FEIJO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "943",
    DESCRICAO: "REGINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "944",
    DESCRICAO: "REGISTRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "945",
    DESCRICAO: "RESTINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "946",
    DESCRICAO: "RIBEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "947",
    DESCRICAO: "RIBEIRAO BONITO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "948",
    DESCRICAO: "RIBEIRAO BRANCO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "949",
    DESCRICAO: "RIBEIRAO CORRENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "950",
    DESCRICAO: "RIBEIRAO DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "951",
    DESCRICAO: "RIBEIRAO DOS INDIOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "952",
    DESCRICAO: "RIBEIRAO GRANDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "953",
    DESCRICAO: "RIBEIRAO PIRES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "954",
    DESCRICAO: "RIBEIRAO PRETO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "955",
    DESCRICAO: "RIVERSUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "956",
    DESCRICAO: "RIFAINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "957",
    DESCRICAO: "RINCAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "958",
    DESCRICAO: "RINOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "959",
    DESCRICAO: "RIO CLARO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "960",
    DESCRICAO: "RIO DAS PEDRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "961",
    DESCRICAO: "RIO GRANDE DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "962",
    DESCRICAO: "RIOLANDIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "963",
    DESCRICAO: "ROSANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "964",
    DESCRICAO: "ROSEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "965",
    DESCRICAO: "RUBIACEA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "966",
    DESCRICAO: "RUBINEIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "967",
    DESCRICAO: "SABINO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "968",
    DESCRICAO: "SAGRES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "969",
    DESCRICAO: "SALES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "970",
    DESCRICAO: "SALES OLIVEIRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "971",
    DESCRICAO: "SALESOPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "972",
    DESCRICAO: "SALMOURAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "973",
    DESCRICAO: "SALTINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "974",
    DESCRICAO: "SALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "975",
    DESCRICAO: "SALTO DE PIRAPORA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "976",
    DESCRICAO: "SALTO GRANDE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "977",
    DESCRICAO: "SANDOVALINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "978",
    DESCRICAO: "SANTA ADELIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "979",
    DESCRICAO: "SANTA ALBERTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "980",
    DESCRICAO: "SANTA BARBARA D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "981",
    DESCRICAO: "SANTA BRANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "982",
    DESCRICAO: "SANTA CLARA D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "983",
    DESCRICAO: "SANTA CRUZ DA CONCEICAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "984",
    DESCRICAO: "SANTA CRUZ DA ESPERANCA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "985",
    DESCRICAO: "SANTA CRUZ DAS PALMEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "986",
    DESCRICAO: "SANTA CRUZ DO RIO PARDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "987",
    DESCRICAO: "SANTA ERNESTINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "988",
    DESCRICAO: "SANTA FE DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "989",
    DESCRICAO: "SANTA GERTRUDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "990",
    DESCRICAO: "SANTA ISABEL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "991",
    DESCRICAO: "SANTA LUCIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "992",
    DESCRICAO: "SANTA MARIA DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "993",
    DESCRICAO: "SANTA MERCEDES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "994",
    DESCRICAO: "SANTANA DA PONTE PENSA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "995",
    DESCRICAO: "SANTANA DE PARNAIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "996",
    DESCRICAO: "SANTA RITA D OESTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "997",
    DESCRICAO: "SANTA RITA DO PASSA QUATRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "998",
    DESCRICAO: "SANTA ROSA DE VITERBO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "999",
    DESCRICAO: "SANTA SALETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1000",
    DESCRICAO: "SANTO ANASTACIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1001",
    DESCRICAO: "SANTO ANDRE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1002",
    DESCRICAO: "SANTO ANTONIO DA ALEGRIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1003",
    DESCRICAO: "SANTO ANTONIO DE POSSE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1004",
    DESCRICAO: "SANTO ANTONIO DO ARACANGUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1005",
    DESCRICAO: "SANTO ANTONIO DO JARDIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1006",
    DESCRICAO: "SANTO ANTONIO DO PINHAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1007",
    DESCRICAO: "SANTO EXPEDITO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1008",
    DESCRICAO: "SANTOPOLIS DO AGUAPEI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1009",
    DESCRICAO: "SANTOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1010",
    DESCRICAO: "SAO BENTO DO SAPUCAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1011",
    DESCRICAO: "SAO BERNARDO DO CAMPO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1012",
    DESCRICAO: "SAO CAETANO DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1013",
    DESCRICAO: "SAO CARLOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1014",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1015",
    DESCRICAO: "SAO JOAO DA BOA VISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1016",
    DESCRICAO: "SAO JOAO DAS DUAS PONTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1017",
    DESCRICAO: "SAO JOAO DE IRACEMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1018",
    DESCRICAO: "SAO JOAO DO PAU D ALHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1019",
    DESCRICAO: "SAO JOAQUIM DA BARRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1020",
    DESCRICAO: "SAO JOSE DA BELA VISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1021",
    DESCRICAO: "SAO JOSE DO BARREIRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1022",
    DESCRICAO: "SAO JOSE DO RIO PARDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1023",
    DESCRICAO: "SAO JOSE DO RIO PRETO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1024",
    DESCRICAO: "SAO JOSE DOS CAMPOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1025",
    DESCRICAO: "SAO LOURENCO DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1026",
    DESCRICAO: "SAO LUIZ DO PARAITINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1027",
    DESCRICAO: "SAO MANUEL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1028",
    DESCRICAO: "SAO MIGUEL ARCANJO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1029",
    DESCRICAO: "SAO PAULO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1030",
    DESCRICAO: "SAO PEDRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1031",
    DESCRICAO: "SAO PEDRO DO TURVO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1032",
    DESCRICAO: "SAO ROQUE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1033",
    DESCRICAO: "SAO SEBASTIAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1034",
    DESCRICAO: "SAO SEBASTIAO DA GRAMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1035",
    DESCRICAO: "SAO SIMAO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1036",
    DESCRICAO: "SAO VICENTE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1037",
    DESCRICAO: "SARAPUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1038",
    DESCRICAO: "SARUTAIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1039",
    DESCRICAO: "SEBASTIANOPOLIS DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1040",
    DESCRICAO: "SERRA AZUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1041",
    DESCRICAO: "SERRANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1042",
    DESCRICAO: "SERRA NEGRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1043",
    DESCRICAO: "SERTAOZINHO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1044",
    DESCRICAO: "SETE BARRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1045",
    DESCRICAO: "SEVERINIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1046",
    DESCRICAO: "SILVEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1047",
    DESCRICAO: "SOCORRO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1048",
    DESCRICAO: "SOROCABA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1049",
    DESCRICAO: "SUD MENNUCCI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1050",
    DESCRICAO: "SUMARE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1051",
    DESCRICAO: "SUZANO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1052",
    DESCRICAO: "SUZANAPOLIS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1053",
    DESCRICAO: "TABAPUA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1054",
    DESCRICAO: "TABATINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1055",
    DESCRICAO: "TABOAO DA SERRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1056",
    DESCRICAO: "TACIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1057",
    DESCRICAO: "TAGUAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1058",
    DESCRICAO: "TAIACU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1059",
    DESCRICAO: "TAIUVA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1060",
    DESCRICAO: "TAMBAU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1061",
    DESCRICAO: "TANABI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1062",
    DESCRICAO: "TAPIRAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1063",
    DESCRICAO: "TAPIRATIBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1064",
    DESCRICAO: "TAQUARAL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1065",
    DESCRICAO: "TAQUARITINGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1066",
    DESCRICAO: "TAQUARITUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1067",
    DESCRICAO: "TAQUARIVAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1068",
    DESCRICAO: "TARABAI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1069",
    DESCRICAO: "TARUMA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1070",
    DESCRICAO: "TATUI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1071",
    DESCRICAO: "TAUBATE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1072",
    DESCRICAO: "TEJUPA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1073",
    DESCRICAO: "TEODORO SAMPAIO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1074",
    DESCRICAO: "TERRA ROXA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1075",
    DESCRICAO: "TIETE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1076",
    DESCRICAO: "TIMBURI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1077",
    DESCRICAO: "TORRE DE PEDRA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1078",
    DESCRICAO: "TORRINHA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1079",
    DESCRICAO: "TRABIJU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1080",
    DESCRICAO: "TREMEMBE",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1081",
    DESCRICAO: "TRES FRONTEIRAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1082",
    DESCRICAO: "TUIUTI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1083",
    DESCRICAO: "TUPA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1084",
    DESCRICAO: "TUPI PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1085",
    DESCRICAO: "TURIUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1086",
    DESCRICAO: "TURMALINA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1087",
    DESCRICAO: "UBARANA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1088",
    DESCRICAO: "UBATUBA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1089",
    DESCRICAO: "UBIRAJARA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1090",
    DESCRICAO: "UCHOA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1091",
    DESCRICAO: "UNIAO PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1092",
    DESCRICAO: "URANIA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1093",
    DESCRICAO: "URU",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1094",
    DESCRICAO: "URUPES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1095",
    DESCRICAO: "VALENTIM GENTIL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1096",
    DESCRICAO: "VALINHOS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1097",
    DESCRICAO: "VALPARAISO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1098",
    DESCRICAO: "VARGEM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1099",
    DESCRICAO: "VARGEM GRANDE DO SUL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1100",
    DESCRICAO: "VARGEM GRANDE PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1101",
    DESCRICAO: "VARZEA PAULISTA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1102",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1103",
    DESCRICAO: "VINHEDO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1104",
    DESCRICAO: "VIRADOURO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1105",
    DESCRICAO: "VISTA ALEGRE DO ALTO",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1106",
    DESCRICAO: "VITORIA BRASIL",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1107",
    DESCRICAO: "VOTORANTIM",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1108",
    DESCRICAO: "VOTUPORANGA",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1109",
    DESCRICAO: "ZACARIAS",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1110",
    DESCRICAO: "CHAVANTES",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1111",
    DESCRICAO: "ESTIVA GERBI",
    SIGLA: "SP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1112",
    DESCRICAO: "ABADIA DOS DOURADOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1113",
    DESCRICAO: "ABAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1114",
    DESCRICAO: "ABRE CAMPO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1115",
    DESCRICAO: "ACAIACA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1116",
    DESCRICAO: "ACUCENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1117",
    DESCRICAO: "AGUA BOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1118",
    DESCRICAO: "AGUA COMPRIDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1119",
    DESCRICAO: "AGUANIL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1120",
    DESCRICAO: "AGUAS FORMOSAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1121",
    DESCRICAO: "AGUAS VERMELHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1122",
    DESCRICAO: "AIMORES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1123",
    DESCRICAO: "AIURUOCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1124",
    DESCRICAO: "ALAGOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1125",
    DESCRICAO: "ALBERTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1126",
    DESCRICAO: "ALEM PARAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1127",
    DESCRICAO: "ALFENAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1128",
    DESCRICAO: "ALFREDO VASCONCELOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1129",
    DESCRICAO: "ALMENARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1130",
    DESCRICAO: "ALPERCATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1131",
    DESCRICAO: "ALPINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1132",
    DESCRICAO: "ALTEROSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1133",
    DESCRICAO: "ALTO CAPARAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1134",
    DESCRICAO: "ALTO RIO DOCE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1135",
    DESCRICAO: "ALVARENGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1136",
    DESCRICAO: "ALVINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1137",
    DESCRICAO: "ALVORADA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1138",
    DESCRICAO: "AMPARO DA SERRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1139",
    DESCRICAO: "ANDRADAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1140",
    DESCRICAO: "CACHOEIRA DE PAJEU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1141",
    DESCRICAO: "ANDRELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1142",
    DESCRICAO: "ANGELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1143",
    DESCRICAO: "ANTONIO CARLOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1144",
    DESCRICAO: "ANTONIO DIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1145",
    DESCRICAO: "ANTONIO PRADO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1146",
    DESCRICAO: "ARACAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1147",
    DESCRICAO: "ARACITABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1148",
    DESCRICAO: "ARACUAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1149",
    DESCRICAO: "ARAGUARI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1150",
    DESCRICAO: "ARANTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1151",
    DESCRICAO: "ARAPONGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1152",
    DESCRICAO: "ARAPORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1153",
    DESCRICAO: "ARAPUA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1154",
    DESCRICAO: "ARAUJOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1155",
    DESCRICAO: "ARAXA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1156",
    DESCRICAO: "ARCEBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1157",
    DESCRICAO: "ARCOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1158",
    DESCRICAO: "AREADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1159",
    DESCRICAO: "ARGIRITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1160",
    DESCRICAO: "ARICANDUVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1161",
    DESCRICAO: "ARINOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1162",
    DESCRICAO: "ASTOLFO DUTRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1163",
    DESCRICAO: "ATALEIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1164",
    DESCRICAO: "AUGUSTO DE LIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1165",
    DESCRICAO: "BAEPENDI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1166",
    DESCRICAO: "BALDIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1167",
    DESCRICAO: "BAMBUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1168",
    DESCRICAO: "BANDEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1169",
    DESCRICAO: "BANDEIRA DO SUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1170",
    DESCRICAO: "BARAO DE COCAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1171",
    DESCRICAO: "BARAO DE MONTE ALTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1172",
    DESCRICAO: "BARBACENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1173",
    DESCRICAO: "BARRA LONGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1174",
    DESCRICAO: "BARROSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1175",
    DESCRICAO: "BELA VISTA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1176",
    DESCRICAO: "BELMIRO BRAGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1177",
    DESCRICAO: "BELO HORIZONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1178",
    DESCRICAO: "BELO ORIENTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1179",
    DESCRICAO: "BELO VALE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1180",
    DESCRICAO: "BERILO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1181",
    DESCRICAO: "BERTOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1182",
    DESCRICAO: "BERIZAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1183",
    DESCRICAO: "BETIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1184",
    DESCRICAO: "BIAS FORTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1185",
    DESCRICAO: "BICAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1186",
    DESCRICAO: "BIQUINHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1187",
    DESCRICAO: "BOA ESPERANCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1188",
    DESCRICAO: "BOCAINA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1189",
    DESCRICAO: "BOCAIUVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1190",
    DESCRICAO: "BOM DESPACHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1191",
    DESCRICAO: "BOM JARDIM DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1192",
    DESCRICAO: "BOM JESUS DA PENHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1193",
    DESCRICAO: "BOM JESUS DO AMPARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1194",
    DESCRICAO: "BOM JESUS DO GALHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1195",
    DESCRICAO: "BOM REPOUSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1196",
    DESCRICAO: "BOM SUCESSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1197",
    DESCRICAO: "BONFIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1198",
    DESCRICAO: "BONFINOPOLIS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1199",
    DESCRICAO: "BONITO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1200",
    DESCRICAO: "BORDA DA MATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1201",
    DESCRICAO: "BOTELHOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1202",
    DESCRICAO: "BOTUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1203",
    DESCRICAO: "BRASILANDIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1204",
    DESCRICAO: "BRASILIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1205",
    DESCRICAO: "BRAS PIRES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1206",
    DESCRICAO: "BRAUNAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1207",
    DESCRICAO: "BRASOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1208",
    DESCRICAO: "BRUMADINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1209",
    DESCRICAO: "BUENO BRANDAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1210",
    DESCRICAO: "BUENOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1211",
    DESCRICAO: "BUGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1212",
    DESCRICAO: "BURITIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1213",
    DESCRICAO: "BURITIZEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1214",
    DESCRICAO: "CABECEIRA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1215",
    DESCRICAO: "CABO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1216",
    DESCRICAO: "CACHOEIRA DA PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1217",
    DESCRICAO: "CACHOEIRA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1218",
    DESCRICAO: "CACHOEIRA DOURADA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1219",
    DESCRICAO: "CAETANOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1220",
    DESCRICAO: "CAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1221",
    DESCRICAO: "CAIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1222",
    DESCRICAO: "CAJURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1223",
    DESCRICAO: "CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1224",
    DESCRICAO: "CAMACHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1225",
    DESCRICAO: "CAMANDUCAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1226",
    DESCRICAO: "CAMBUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1227",
    DESCRICAO: "CAMBUQUIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1228",
    DESCRICAO: "CAMPANARIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1229",
    DESCRICAO: "CAMPANHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1230",
    DESCRICAO: "CAMPESTRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1231",
    DESCRICAO: "CAMPINA VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1232",
    DESCRICAO: "CAMPO AZUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1233",
    DESCRICAO: "CAMPO BELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1234",
    DESCRICAO: "CAMPO DO MEIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1235",
    DESCRICAO: "CAMPO FLORIDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1236",
    DESCRICAO: "CAMPOS ALTOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1237",
    DESCRICAO: "CAMPOS GERAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1238",
    DESCRICAO: "CANAA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1239",
    DESCRICAO: "CANAPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1240",
    DESCRICAO: "CANA VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1241",
    DESCRICAO: "CANDEIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1242",
    DESCRICAO: "CANTAGALO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1243",
    DESCRICAO: "CAPARAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1244",
    DESCRICAO: "CAPELA NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1245",
    DESCRICAO: "CAPELINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1246",
    DESCRICAO: "CAPETINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1247",
    DESCRICAO: "CAPIM BRANCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1248",
    DESCRICAO: "CAPINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1249",
    DESCRICAO: "CAPITAO ANDRADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1250",
    DESCRICAO: "CAPITAO ENEAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1251",
    DESCRICAO: "CAPITOLIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1252",
    DESCRICAO: "CAPUTIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1253",
    DESCRICAO: "CARAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1254",
    DESCRICAO: "CARANAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1255",
    DESCRICAO: "CARANDAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1256",
    DESCRICAO: "CARANGOLA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1257",
    DESCRICAO: "CARATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1258",
    DESCRICAO: "CARBONITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1259",
    DESCRICAO: "CAREACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1260",
    DESCRICAO: "CARLOS CHAGAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1261",
    DESCRICAO: "CARMESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1262",
    DESCRICAO: "CARMO DA CACHOEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1263",
    DESCRICAO: "CARMO DA MATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1264",
    DESCRICAO: "CARMO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1265",
    DESCRICAO: "CARMO DO CAJURU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1266",
    DESCRICAO: "CARMO DO PARANAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1267",
    DESCRICAO: "CARMO DO RIO CLARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1268",
    DESCRICAO: "CARMOPOLIS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1269",
    DESCRICAO: "CARNEIRINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1270",
    DESCRICAO: "CARRANCAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1271",
    DESCRICAO: "CARVALHOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1272",
    DESCRICAO: "CARVALHOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1273",
    DESCRICAO: "CASA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1274",
    DESCRICAO: "CASCALHO RICO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1275",
    DESCRICAO: "CASSIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1276",
    DESCRICAO: "CONCEICAO DA BARRA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1277",
    DESCRICAO: "CATAGUASES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1278",
    DESCRICAO: "CATAS ALTAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1279",
    DESCRICAO: "CATAS ALTAS DA NORUEGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1280",
    DESCRICAO: "CATUJI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1281",
    DESCRICAO: "CATUTI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1282",
    DESCRICAO: "CAXAMBU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1283",
    DESCRICAO: "CEDRO DO ABAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1284",
    DESCRICAO: "CENTRAL DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1285",
    DESCRICAO: "CENTRALINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1286",
    DESCRICAO: "CHACARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1287",
    DESCRICAO: "CHALE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1288",
    DESCRICAO: "CHAPADA DO NORTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1289",
    DESCRICAO: "CHAPADA GAUCHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1290",
    DESCRICAO: "CHIADOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1291",
    DESCRICAO: "CIPOTANEA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1292",
    DESCRICAO: "CLARAVAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1293",
    DESCRICAO: "CLARO DOS POCOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1294",
    DESCRICAO: "CLAUDIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1295",
    DESCRICAO: "COIMBRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1296",
    DESCRICAO: "COLUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1297",
    DESCRICAO: "COMENDADOR GOMES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1298",
    DESCRICAO: "COMERCINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1299",
    DESCRICAO: "CONCEICAO DA APARECIDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1300",
    DESCRICAO: "CONCEICAO DAS PEDRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1301",
    DESCRICAO: "CONCEICAO DAS ALAGOAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1302",
    DESCRICAO: "CONCEICAO DE IPANEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1303",
    DESCRICAO: "CONCEICAO DO MATO DENTRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1304",
    DESCRICAO: "CONCEICAO DO PARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1305",
    DESCRICAO: "CONCEICAO DO RIO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1306",
    DESCRICAO: "CONCEICAO DOS OUROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1307",
    DESCRICAO: "CONEGO MARINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1308",
    DESCRICAO: "CONFINS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1309",
    DESCRICAO: "CONGONHAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1310",
    DESCRICAO: "CONGONHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1311",
    DESCRICAO: "CONGONHAS DO NORTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1312",
    DESCRICAO: "CONQUISTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1313",
    DESCRICAO: "CONSELHEIRO LAFAIETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1314",
    DESCRICAO: "CONSELHEIRO PENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1315",
    DESCRICAO: "CONSOLACAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1316",
    DESCRICAO: "CONTAGEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1317",
    DESCRICAO: "COQUEIRAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1318",
    DESCRICAO: "CORACAO DE JESUS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1319",
    DESCRICAO: "CORDISBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1320",
    DESCRICAO: "CORDISLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1321",
    DESCRICAO: "CORINTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1322",
    DESCRICAO: "COROACI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1323",
    DESCRICAO: "COROMANDEL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1324",
    DESCRICAO: "CORONEL FABRICIANO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1325",
    DESCRICAO: "CORONEL MURTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1326",
    DESCRICAO: "CORONEL PACHECO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1327",
    DESCRICAO: "CORONEL XAVIER CHAVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1328",
    DESCRICAO: "CORREGO DANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1329",
    DESCRICAO: "CORREGO DO BOM JESUS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1330",
    DESCRICAO: "CORREGO FUNDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1331",
    DESCRICAO: "CORREGO NOVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1332",
    DESCRICAO: "COUTO DE MAGALHAES DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1333",
    DESCRICAO: "CRISOLITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1334",
    DESCRICAO: "CRISTAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1335",
    DESCRICAO: "CRISTALIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1336",
    DESCRICAO: "CRISTIANO OTONI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1337",
    DESCRICAO: "CRISTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1338",
    DESCRICAO: "CRUCILANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1339",
    DESCRICAO: "CRUZEIRO DA FORTALEZA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1340",
    DESCRICAO: "CRUZILIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1341",
    DESCRICAO: "CUPARAQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1342",
    DESCRICAO: "CURRAL DE DENTRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1343",
    DESCRICAO: "CURVELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1344",
    DESCRICAO: "DATAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1345",
    DESCRICAO: "DELFIM MOREIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1346",
    DESCRICAO: "DELFINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1347",
    DESCRICAO: "DELTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1348",
    DESCRICAO: "DESCOBERTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1349",
    DESCRICAO: "DESTERRO DE ENTRE RIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1350",
    DESCRICAO: "DESTERRO DO MELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1351",
    DESCRICAO: "DIAMANTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1352",
    DESCRICAO: "DIOGO DE VASCONCELOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1353",
    DESCRICAO: "DIONISIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1354",
    DESCRICAO: "DIVINESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1355",
    DESCRICAO: "DIVINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1356",
    DESCRICAO: "DIVINO DAS LARANJEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1357",
    DESCRICAO: "DIVINOLANDIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1358",
    DESCRICAO: "DIVINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1359",
    DESCRICAO: "DIVISA ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1360",
    DESCRICAO: "DIVISA NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1361",
    DESCRICAO: "DIVISOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1362",
    DESCRICAO: "DOM BOSCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1363",
    DESCRICAO: "DOM CAVATI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1364",
    DESCRICAO: "DOM JOAQUIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1365",
    DESCRICAO: "DOM SILVERIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1366",
    DESCRICAO: "DOM VICOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1367",
    DESCRICAO: "DONA EUZEBIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1368",
    DESCRICAO: "DORES DE CAMPOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1369",
    DESCRICAO: "DORES DE GUANHAES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1370",
    DESCRICAO: "DORES DO INDAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1371",
    DESCRICAO: "DORES DO TURVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1372",
    DESCRICAO: "DORESOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1373",
    DESCRICAO: "DOURADOQUARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1374",
    DESCRICAO: "DURANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1375",
    DESCRICAO: "ELOI MENDES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1376",
    DESCRICAO: "ENGENHEIRO CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1377",
    DESCRICAO: "ENGENHEIRO NAVARRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1378",
    DESCRICAO: "ENTRE FOLHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1379",
    DESCRICAO: "ENTRE RIOS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1380",
    DESCRICAO: "ERVALIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1381",
    DESCRICAO: "ESMERALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1382",
    DESCRICAO: "ESPERA FELIZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1383",
    DESCRICAO: "ESPINOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1384",
    DESCRICAO: "ESPIRITO SANTO DO DOURADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1385",
    DESCRICAO: "ESTIVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1386",
    DESCRICAO: "ESTRELA DALVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1387",
    DESCRICAO: "ESTRELA DO INDAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1388",
    DESCRICAO: "ESTRELA DO SUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1389",
    DESCRICAO: "EUGENOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1390",
    DESCRICAO: "EWBANK DA CAMARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1391",
    DESCRICAO: "EXTREMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1392",
    DESCRICAO: "FAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1393",
    DESCRICAO: "FARIA LEMOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1394",
    DESCRICAO: "FELICIO DOS SANTOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1395",
    DESCRICAO: "SAO GONCALO DO RIO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1396",
    DESCRICAO: "FELISBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1397",
    DESCRICAO: "FELIXLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1398",
    DESCRICAO: "FERNANDES TOURINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1399",
    DESCRICAO: "FERROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1400",
    DESCRICAO: "FERVEDOURO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1401",
    DESCRICAO: "FLORESTAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1402",
    DESCRICAO: "FORMIGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1403",
    DESCRICAO: "FORMOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1404",
    DESCRICAO: "FORTALEZA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1405",
    DESCRICAO: "FORTUNA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1406",
    DESCRICAO: "FRANCISCO BADARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1407",
    DESCRICAO: "FRANCISCO DUMONT",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1408",
    DESCRICAO: "FRANCISCO SA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1409",
    DESCRICAO: "FRANCISCOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1410",
    DESCRICAO: "FREI GASPAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1411",
    DESCRICAO: "FREI INOCENCIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1412",
    DESCRICAO: "FREI LAGONEGRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1413",
    DESCRICAO: "FRONTEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1414",
    DESCRICAO: "FRONTEIRA DOS VALES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1415",
    DESCRICAO: "FRUTA DE LEITE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1416",
    DESCRICAO: "FRUTAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1417",
    DESCRICAO: "FUNILANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1418",
    DESCRICAO: "GALILEIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1419",
    DESCRICAO: "GAMELEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1420",
    DESCRICAO: "GLAUCILANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1421",
    DESCRICAO: "GOIABEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1422",
    DESCRICAO: "GOIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1423",
    DESCRICAO: "GONCALVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1424",
    DESCRICAO: "GONZAGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1425",
    DESCRICAO: "GOUVEIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1426",
    DESCRICAO: "GOVERNADOR VALADARES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1427",
    DESCRICAO: "GRAO MOGOL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1428",
    DESCRICAO: "GRUPIARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1429",
    DESCRICAO: "GUANHAES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1430",
    DESCRICAO: "GUAPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1431",
    DESCRICAO: "GUARACIABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1432",
    DESCRICAO: "GUARACIAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1433",
    DESCRICAO: "GUARANESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1434",
    DESCRICAO: "GUARANI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1435",
    DESCRICAO: "GUARARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1436",
    DESCRICAO: "GUARDA-MOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1437",
    DESCRICAO: "GUAXUPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1438",
    DESCRICAO: "GUIDOVAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1439",
    DESCRICAO: "GUIMARANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1440",
    DESCRICAO: "GUIRICEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1441",
    DESCRICAO: "GURINHATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1442",
    DESCRICAO: "HELIODORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1443",
    DESCRICAO: "IAPU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1444",
    DESCRICAO: "IBERTIOGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1445",
    DESCRICAO: "IBIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1446",
    DESCRICAO: "IBIAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1447",
    DESCRICAO: "IBIRACATU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1448",
    DESCRICAO: "IBIRACI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1449",
    DESCRICAO: "IBIRITE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1450",
    DESCRICAO: "IBITIURA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1451",
    DESCRICAO: "IBITURUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1452",
    DESCRICAO: "ICARAI DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1453",
    DESCRICAO: "IGARAPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1454",
    DESCRICAO: "IGARATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1455",
    DESCRICAO: "IGUATAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1456",
    DESCRICAO: "IJACI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1457",
    DESCRICAO: "ILICINEA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1458",
    DESCRICAO: "IMBE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1459",
    DESCRICAO: "INCONFIDENTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1460",
    DESCRICAO: "INDAIABIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1461",
    DESCRICAO: "INDIANOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1462",
    DESCRICAO: "INGAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1463",
    DESCRICAO: "INHAPIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1464",
    DESCRICAO: "INHAUMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1465",
    DESCRICAO: "INIMUTABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1466",
    DESCRICAO: "IPABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1467",
    DESCRICAO: "IPANEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1468",
    DESCRICAO: "IPATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1469",
    DESCRICAO: "IPIACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1470",
    DESCRICAO: "IPUIUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1471",
    DESCRICAO: "IRAI DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1472",
    DESCRICAO: "ITABIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1473",
    DESCRICAO: "ITABIRINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1474",
    DESCRICAO: "ITABIRITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1475",
    DESCRICAO: "ITACAMBIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1476",
    DESCRICAO: "ITACARAMBI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1477",
    DESCRICAO: "ITAGUARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1478",
    DESCRICAO: "ITAIPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1479",
    DESCRICAO: "ITAJUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1480",
    DESCRICAO: "ITAMARANDIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1481",
    DESCRICAO: "ITAMARATI DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1482",
    DESCRICAO: "ITAMBACURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1483",
    DESCRICAO: "ITAMBE DO MATO DENTRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1484",
    DESCRICAO: "ITAMOGI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1485",
    DESCRICAO: "ITAMONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1486",
    DESCRICAO: "ITANHANDU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1487",
    DESCRICAO: "ITANHOMI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1488",
    DESCRICAO: "ITAOBIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1489",
    DESCRICAO: "ITAPAGIPE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1490",
    DESCRICAO: "ITAPECERICA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1491",
    DESCRICAO: "ITAPEVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1492",
    DESCRICAO: "ITATIAIUCU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1493",
    DESCRICAO: "ITAU DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1494",
    DESCRICAO: "ITAUNA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1495",
    DESCRICAO: "ITAVERAVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1496",
    DESCRICAO: "ITINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1497",
    DESCRICAO: "ITUETA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1498",
    DESCRICAO: "ITUIUTABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1499",
    DESCRICAO: "ITUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1500",
    DESCRICAO: "ITURAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1501",
    DESCRICAO: "ITUTINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1502",
    DESCRICAO: "JABOTICATUBAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1503",
    DESCRICAO: "JACINTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1504",
    DESCRICAO: "JACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1505",
    DESCRICAO: "JACUTINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1506",
    DESCRICAO: "JAGUARACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1507",
    DESCRICAO: "JAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1508",
    DESCRICAO: "JAMPRUCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1509",
    DESCRICAO: "JANAUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1510",
    DESCRICAO: "JANUARIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1511",
    DESCRICAO: "JAPARAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1512",
    DESCRICAO: "JAPONVAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1513",
    DESCRICAO: "JECEABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1514",
    DESCRICAO: "JENIPAPO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1515",
    DESCRICAO: "JEQUERI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1516",
    DESCRICAO: "JEQUITAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1517",
    DESCRICAO: "JEQUITIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1518",
    DESCRICAO: "JEQUITINHONHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1519",
    DESCRICAO: "JESUANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1520",
    DESCRICAO: "JOAIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1521",
    DESCRICAO: "JOANESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1522",
    DESCRICAO: "JOAO MONLEVADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1523",
    DESCRICAO: "JOAO PINHEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1524",
    DESCRICAO: "JOAQUIM FELICIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1525",
    DESCRICAO: "JORDANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1526",
    DESCRICAO: "JOSE GONCALVES DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1527",
    DESCRICAO: "JOSE RAYDAN",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1528",
    DESCRICAO: "JOSENOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1529",
    DESCRICAO: "NOVA UNIAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1530",
    DESCRICAO: "JUATUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1531",
    DESCRICAO: "JUIZ DE FORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1532",
    DESCRICAO: "JURAMENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1533",
    DESCRICAO: "JURUAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1534",
    DESCRICAO: "JUVENILIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1535",
    DESCRICAO: "LADAINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1536",
    DESCRICAO: "LAGAMAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1537",
    DESCRICAO: "LAGOA DA PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1538",
    DESCRICAO: "LAGOA DOS PATOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1539",
    DESCRICAO: "LAGOA DOURADA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1540",
    DESCRICAO: "LAGOA FORMOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1541",
    DESCRICAO: "LAGOA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1542",
    DESCRICAO: "LAGOA SANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1543",
    DESCRICAO: "LAJINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1544",
    DESCRICAO: "LAMBARI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1545",
    DESCRICAO: "LAMIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1546",
    DESCRICAO: "LARANJAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1547",
    DESCRICAO: "LASSANCE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1548",
    DESCRICAO: "LAVRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1549",
    DESCRICAO: "LEANDRO FERREIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1550",
    DESCRICAO: "LEME DO PRADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1551",
    DESCRICAO: "LEOPOLDINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1552",
    DESCRICAO: "LIBERDADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1553",
    DESCRICAO: "LIMA DUARTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1554",
    DESCRICAO: "LIMEIRA DO OESTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1555",
    DESCRICAO: "LONTRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1556",
    DESCRICAO: "LUISBURGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1557",
    DESCRICAO: "LUISLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1558",
    DESCRICAO: "LUMINARIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1559",
    DESCRICAO: "LUZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1560",
    DESCRICAO: "MACHACALIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1561",
    DESCRICAO: "MACHADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1562",
    DESCRICAO: "MADRE DE DEUS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1563",
    DESCRICAO: "MALACACHETA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1564",
    DESCRICAO: "MAMONAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1565",
    DESCRICAO: "MANGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1566",
    DESCRICAO: "MANHUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1567",
    DESCRICAO: "MANHUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1568",
    DESCRICAO: "MANTENA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1569",
    DESCRICAO: "MARAVILHAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1570",
    DESCRICAO: "MAR DE ESPANHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1571",
    DESCRICAO: "MARIA DA FE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1572",
    DESCRICAO: "MARIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1573",
    DESCRICAO: "MARILAC",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1574",
    DESCRICAO: "MARIO CAMPOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1575",
    DESCRICAO: "MARIPA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1576",
    DESCRICAO: "MARLIERIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1577",
    DESCRICAO: "MARMELOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1578",
    DESCRICAO: "MARTINHO CAMPOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1579",
    DESCRICAO: "MARTINS SOARES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1580",
    DESCRICAO: "MATA VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1581",
    DESCRICAO: "MATERLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1582",
    DESCRICAO: "MATEUS LEME",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1583",
    DESCRICAO: "MATIAS BARBOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1584",
    DESCRICAO: "MATIAS CARDOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1585",
    DESCRICAO: "MATIPO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1586",
    DESCRICAO: "MATO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1587",
    DESCRICAO: "MATOZINHOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1588",
    DESCRICAO: "MATUTINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1589",
    DESCRICAO: "MEDEIROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1590",
    DESCRICAO: "MEDINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1591",
    DESCRICAO: "MENDES PIMENTEL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1592",
    DESCRICAO: "MERCES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1593",
    DESCRICAO: "MESQUITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1594",
    DESCRICAO: "MINAS NOVAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1595",
    DESCRICAO: "MINDURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1596",
    DESCRICAO: "MIRABELA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1597",
    DESCRICAO: "MIRADOURO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1598",
    DESCRICAO: "MIRAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1599",
    DESCRICAO: "MIRAVANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1600",
    DESCRICAO: "MOEDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1601",
    DESCRICAO: "MOEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1602",
    DESCRICAO: "MONJOLOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1603",
    DESCRICAO: "MONSENHOR PAULO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1604",
    DESCRICAO: "MONTALVANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1605",
    DESCRICAO: "MONTE ALEGRE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1606",
    DESCRICAO: "MONTE AZUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1607",
    DESCRICAO: "MONTE BELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1608",
    DESCRICAO: "MONTE CARMELO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1609",
    DESCRICAO: "MONTE FORMOSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1610",
    DESCRICAO: "MONTE SANTO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1611",
    DESCRICAO: "MONTES CLAROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1612",
    DESCRICAO: "MONTE SIAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1613",
    DESCRICAO: "MONTEZUMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1614",
    DESCRICAO: "MORADA NOVA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1615",
    DESCRICAO: "MORRO DA GARCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1616",
    DESCRICAO: "MORRO DO PILAR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1617",
    DESCRICAO: "MUNHOZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1618",
    DESCRICAO: "MURIAE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1619",
    DESCRICAO: "MUTUM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1620",
    DESCRICAO: "MUZAMBINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1621",
    DESCRICAO: "NACIP RAYDAN",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1622",
    DESCRICAO: "NANUQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1623",
    DESCRICAO: "NAQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1624",
    DESCRICAO: "NATALANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1625",
    DESCRICAO: "NATERCIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1626",
    DESCRICAO: "NAZARENO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1627",
    DESCRICAO: "NEPOMUCENO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1628",
    DESCRICAO: "NINHEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1629",
    DESCRICAO: "NOVA BELEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1630",
    DESCRICAO: "NOVA ERA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1631",
    DESCRICAO: "NOVA LIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1632",
    DESCRICAO: "NOVA MODICA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1633",
    DESCRICAO: "NOVA PONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1634",
    DESCRICAO: "NOVA PORTEIRINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1635",
    DESCRICAO: "NOVA RESENDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1636",
    DESCRICAO: "NOVA SERRANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1637",
    DESCRICAO: "NOVO CRUZEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1638",
    DESCRICAO: "NOVO ORIENTE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1639",
    DESCRICAO: "NOVORIZONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1640",
    DESCRICAO: "OLARIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1641",
    DESCRICAO: "OLHOS D AGUA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1642",
    DESCRICAO: "OLIMPIO NORONHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1643",
    DESCRICAO: "OLIVEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1644",
    DESCRICAO: "OLIVEIRA FORTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1645",
    DESCRICAO: "ONCA DE PITANGUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1646",
    DESCRICAO: "ORATORIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1647",
    DESCRICAO: "ORIZANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1648",
    DESCRICAO: "OURO BRANCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1649",
    DESCRICAO: "OURO FINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1650",
    DESCRICAO: "OURO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1651",
    DESCRICAO: "OURO VERDE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1652",
    DESCRICAO: "PADRE CARVALHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1653",
    DESCRICAO: "PADRE PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1654",
    DESCRICAO: "PAINEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1655",
    DESCRICAO: "PAINS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1656",
    DESCRICAO: "PAI PEDRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1657",
    DESCRICAO: "PAIVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1658",
    DESCRICAO: "PALMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1659",
    DESCRICAO: "PALMOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1660",
    DESCRICAO: "PAPAGAIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1661",
    DESCRICAO: "PARACATU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1662",
    DESCRICAO: "PARA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1663",
    DESCRICAO: "PARAGUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1664",
    DESCRICAO: "PARAISOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1665",
    DESCRICAO: "PARAOPEBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1666",
    DESCRICAO: "PASSABEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1667",
    DESCRICAO: "PASSA QUATRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1668",
    DESCRICAO: "PASSA TEMPO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1669",
    DESCRICAO: "PASSA VINTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1670",
    DESCRICAO: "PASSOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1671",
    DESCRICAO: "PATIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1672",
    DESCRICAO: "PATOS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1673",
    DESCRICAO: "PATROCINIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1674",
    DESCRICAO: "PATROCINIO DO MURIAE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1675",
    DESCRICAO: "PAULA CANDIDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1676",
    DESCRICAO: "PAULISTAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1677",
    DESCRICAO: "PAVAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1678",
    DESCRICAO: "PECANHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1679",
    DESCRICAO: "PEDRA AZUL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1680",
    DESCRICAO: "PEDRA BONITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1681",
    DESCRICAO: "PEDRA DO ANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1682",
    DESCRICAO: "PEDRA DO INDAIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1683",
    DESCRICAO: "PEDRA DOURADA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1684",
    DESCRICAO: "PEDRALVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1685",
    DESCRICAO: "PEDRAS DE MARIA DA CRUZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1686",
    DESCRICAO: "PEDRINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1687",
    DESCRICAO: "PEDRO LEOPOLDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1688",
    DESCRICAO: "PEDRO TEIXEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1689",
    DESCRICAO: "PEQUERI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1690",
    DESCRICAO: "PEQUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1691",
    DESCRICAO: "PERDIGAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1692",
    DESCRICAO: "PERDIZES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1693",
    DESCRICAO: "PERDOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1694",
    DESCRICAO: "PERIQUITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1695",
    DESCRICAO: "PESCADOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1696",
    DESCRICAO: "PIAU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1697",
    DESCRICAO: "PIEDADE DE CARATINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1698",
    DESCRICAO: "PIEDADE DE PONTE NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1699",
    DESCRICAO: "PIEDADE DO RIO GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1700",
    DESCRICAO: "PIEDADE DOS GERAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1701",
    DESCRICAO: "PIMENTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1702",
    DESCRICAO: "PINGO-D AGUA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1703",
    DESCRICAO: "PINTOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1704",
    DESCRICAO: "PIRACEMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1705",
    DESCRICAO: "PIRAJUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1706",
    DESCRICAO: "PIRANGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1707",
    DESCRICAO: "PIRANGUCU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1708",
    DESCRICAO: "PIRANGUINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1709",
    DESCRICAO: "PIRAPETINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1710",
    DESCRICAO: "PIRAPORA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1711",
    DESCRICAO: "PIRAUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1712",
    DESCRICAO: "PITANGUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1713",
    DESCRICAO: "PIUMHI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1714",
    DESCRICAO: "PLANURA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1715",
    DESCRICAO: "POCO FUNDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1716",
    DESCRICAO: "POCOS DE CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1717",
    DESCRICAO: "POCRANE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1718",
    DESCRICAO: "POMPEU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1719",
    DESCRICAO: "PONTE NOVA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1720",
    DESCRICAO: "PONTO CHIQUE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1721",
    DESCRICAO: "PONTO DOS VOLANTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1722",
    DESCRICAO: "PORTEIRINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1723",
    DESCRICAO: "PORTO FIRME",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1724",
    DESCRICAO: "POTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1725",
    DESCRICAO: "POUSO ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1726",
    DESCRICAO: "POUSO ALTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1727",
    DESCRICAO: "PRADOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1728",
    DESCRICAO: "PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1729",
    DESCRICAO: "PRATAPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1730",
    DESCRICAO: "PRATINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1731",
    DESCRICAO: "PRESIDENTE BERNARDES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1732",
    DESCRICAO: "PRESIDENTE JUSCELINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1733",
    DESCRICAO: "PRESIDENTE KUBITSCHEK",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1734",
    DESCRICAO: "PRESIDENTE OLEGARIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1735",
    DESCRICAO: "ALTO JEQUITIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1736",
    DESCRICAO: "PRUDENTE DE MORAIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1737",
    DESCRICAO: "QUARTEL GERAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1738",
    DESCRICAO: "QUELUZITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1739",
    DESCRICAO: "RAPOSOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1740",
    DESCRICAO: "RAUL SOARES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1741",
    DESCRICAO: "RECREIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1742",
    DESCRICAO: "REDUTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1743",
    DESCRICAO: "RESENDE COSTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1744",
    DESCRICAO: "RESPLENDOR",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1745",
    DESCRICAO: "RESSAQUINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1746",
    DESCRICAO: "RIACHINHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1747",
    DESCRICAO: "RIACHO DOS MACHADOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1748",
    DESCRICAO: "RIBEIRAO DAS NEVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1749",
    DESCRICAO: "RIBEIRAO VERMELHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1750",
    DESCRICAO: "RIO ACIMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1751",
    DESCRICAO: "RIO CASCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1752",
    DESCRICAO: "RIO DOCE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1753",
    DESCRICAO: "RIO DO PRADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1754",
    DESCRICAO: "RIO ESPERA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1755",
    DESCRICAO: "RIO MANSO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1756",
    DESCRICAO: "RIO NOVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1757",
    DESCRICAO: "RIO PARANAIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1758",
    DESCRICAO: "RIO PARDO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1759",
    DESCRICAO: "RIO PIRACICABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1760",
    DESCRICAO: "RIO POMBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1761",
    DESCRICAO: "RIO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1762",
    DESCRICAO: "RIO VERMELHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1763",
    DESCRICAO: "RITAPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1764",
    DESCRICAO: "ROCHEDO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1765",
    DESCRICAO: "RODEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1766",
    DESCRICAO: "ROMARIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1767",
    DESCRICAO: "ROSARIO DA LIMEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1768",
    DESCRICAO: "RUBELITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1769",
    DESCRICAO: "RUBIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1770",
    DESCRICAO: "SABARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1771",
    DESCRICAO: "SABINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1772",
    DESCRICAO: "SACRAMENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1773",
    DESCRICAO: "SALINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1774",
    DESCRICAO: "SALTO DA DIVISA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1775",
    DESCRICAO: "SANTA BARBARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1776",
    DESCRICAO: "SANTA BARBARA DO LESTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1777",
    DESCRICAO: "SANTA BARBARA DO MONTE VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1778",
    DESCRICAO: "SANTA BARBARA DO TUGURIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1779",
    DESCRICAO: "SANTA CRUZ DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1780",
    DESCRICAO: "SANTA CRUZ DE SALINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1781",
    DESCRICAO: "SANTA CRUZ DO ESCALVADO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1782",
    DESCRICAO: "SANTA EFIGENIA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1783",
    DESCRICAO: "SANTA FE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1784",
    DESCRICAO: "SANTA HELENA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1785",
    DESCRICAO: "SANTA JULIANA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1786",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1787",
    DESCRICAO: "SANTA MARGARIDA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1788",
    DESCRICAO: "SANTA MARIA DE ITABIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1789",
    DESCRICAO: "SANTA MARIA DO SALTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1790",
    DESCRICAO: "SANTA MARIA DO SUACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1791",
    DESCRICAO: "SANTANA DA VARGEM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1792",
    DESCRICAO: "SANTANA DE CATAGUASES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1793",
    DESCRICAO: "SANTANA DE PIRAPAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1794",
    DESCRICAO: "SANTANA DO DESERTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1795",
    DESCRICAO: "SANTANA DO GARAMBEU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1796",
    DESCRICAO: "SANTANA DO JACARE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1797",
    DESCRICAO: "SANTANA DO MANHUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1798",
    DESCRICAO: "SANTANA DO PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1799",
    DESCRICAO: "SANTANA DO RIACHO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1800",
    DESCRICAO: "SANTANA DOS MONTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1801",
    DESCRICAO: "SANTA RITA DE CALDAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1802",
    DESCRICAO: "SANTA RITA DE JACUTINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1803",
    DESCRICAO: "SANTA RITA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1804",
    DESCRICAO: "SANTA RITA DO IBITIPOCA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1805",
    DESCRICAO: "SANTA RITA DO ITUETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1806",
    DESCRICAO: "SANTA RITA DO SAPUCAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1807",
    DESCRICAO: "SANTA ROSA DA SERRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1808",
    DESCRICAO: "SANTA VITORIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1809",
    DESCRICAO: "SANTO ANTONIO DO AMPARO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1810",
    DESCRICAO: "SANTO ANTONIO DO AVENTUREIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1811",
    DESCRICAO: "SANTO ANTONIO DO GRAMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1812",
    DESCRICAO: "SANTO ANTONIO DO ITAMBE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1813",
    DESCRICAO: "SANTO ANTONIO DO JACINTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1814",
    DESCRICAO: "SANTO ANTONIO DO MONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1815",
    DESCRICAO: "SANTO ANTONIO DO RETIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1816",
    DESCRICAO: "SANTO ANTONIO DO RIO ABAIXO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1817",
    DESCRICAO: "SANTO HIPOLITO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1818",
    DESCRICAO: "SANTOS DUMONT",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1819",
    DESCRICAO: "SAO BENTO ABADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1820",
    DESCRICAO: "SAO BRAS DO SUACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1821",
    DESCRICAO: "SAO DOMINGOS DAS DORES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1822",
    DESCRICAO: "SAO DOMINGOS DO PRATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1823",
    DESCRICAO: "SAO FELIX DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1824",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1825",
    DESCRICAO: "SAO FRANCISCO DE PAULA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1826",
    DESCRICAO: "SAO FRANCISCO DE SALES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1827",
    DESCRICAO: "SAO FRANCISCO DO GLORIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1828",
    DESCRICAO: "SAO GERALDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1829",
    DESCRICAO: "SAO GERALDO DA PIEDADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1830",
    DESCRICAO: "SAO GERALDO DO BAIXIO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1831",
    DESCRICAO: "SAO GONCALO DO ABAETE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1832",
    DESCRICAO: "SAO GONCALO DO PARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1833",
    DESCRICAO: "SAO GONCALO DO RIO ABAIXO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1834",
    DESCRICAO: "SAO GONCALO DO SAPUCAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1835",
    DESCRICAO: "SAO GOTARDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1836",
    DESCRICAO: "SAO JOAO BATISTA DO GLORIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1837",
    DESCRICAO: "SAO JOAO DA LAGOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1838",
    DESCRICAO: "SAO JOAO DA MATA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1839",
    DESCRICAO: "SAO JOAO DA PONTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1840",
    DESCRICAO: "SAO JOAO DAS MISSOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1841",
    DESCRICAO: "SAO JOAO DEL REI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1842",
    DESCRICAO: "SAO JOAO DO MANHUACU",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1843",
    DESCRICAO: "SAO JOAO DO MANTENINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1844",
    DESCRICAO: "SAO JOAO DO ORIENTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1845",
    DESCRICAO: "SAO JOAO DO PACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1846",
    DESCRICAO: "SAO JOAO DO PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1847",
    DESCRICAO: "SAO JOAO EVANGELISTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1848",
    DESCRICAO: "SAO JOAO NEPOMUCENO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1849",
    DESCRICAO: "SAO JOAQUIM DE BICAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1850",
    DESCRICAO: "SAO JOSE DA BARRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1851",
    DESCRICAO: "SAO JOSE DA LAPA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1852",
    DESCRICAO: "SAO JOSE DA SAFIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1853",
    DESCRICAO: "SAO JOSE DA VARGINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1854",
    DESCRICAO: "SAO JOSE DO ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1855",
    DESCRICAO: "SAO JOSE DO DIVINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1856",
    DESCRICAO: "SAO JOSE DO GOIABAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1857",
    DESCRICAO: "SAO JOSE DO JACURI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1858",
    DESCRICAO: "SAO JOSE DO MANTIMENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1859",
    DESCRICAO: "SAO LOURENCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1860",
    DESCRICAO: "SAO MIGUEL DO ANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1861",
    DESCRICAO: "SAO PEDRO DA UNIAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1862",
    DESCRICAO: "SAO PEDRO DOS FERROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1863",
    DESCRICAO: "SAO PEDRO DO SUACUI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1864",
    DESCRICAO: "SAO ROMAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1865",
    DESCRICAO: "SAO ROQUE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1866",
    DESCRICAO: "SAO SEBASTIAO DA BELA VISTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1867",
    DESCRICAO: "SAO SEBASTIAO DA VARGEM ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1868",
    DESCRICAO: "SAO SEBASTIAO DO ANTA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1869",
    DESCRICAO: "SAO SEBASTIAO DO MARANHAO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1870",
    DESCRICAO: "SAO SEBASTIAO DO OESTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1871",
    DESCRICAO: "SAO SEBASTIAO DO PARAISO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1872",
    DESCRICAO: "SAO SEBASTIAO DO RIO PRETO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1873",
    DESCRICAO: "SAO SEBASTIAO DO RIO VERDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1874",
    DESCRICAO: "SAO TIAGO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1875",
    DESCRICAO: "SAO TOMAS DE AQUINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1876",
    DESCRICAO: "SAO THOME DAS LETRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1877",
    DESCRICAO: "SAO VICENTE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1878",
    DESCRICAO: "SAPUCAI-MIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1879",
    DESCRICAO: "SARDOA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1880",
    DESCRICAO: "SARZEDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1881",
    DESCRICAO: "SETUBINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1882",
    DESCRICAO: "SEM PEIXE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1883",
    DESCRICAO: "SENADOR AMARAL",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1884",
    DESCRICAO: "SENADOR CORTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1885",
    DESCRICAO: "SENADOR FIRMINO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1886",
    DESCRICAO: "SENADOR JOSE BENTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1887",
    DESCRICAO: "SENADOR MODESTINO GONCALVES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1888",
    DESCRICAO: "SENHORA DE OLIVEIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1889",
    DESCRICAO: "SENHORA DO PORTO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1890",
    DESCRICAO: "SENHORA DOS REMEDIOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1891",
    DESCRICAO: "SERICITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1892",
    DESCRICAO: "SERITINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1893",
    DESCRICAO: "SERRA AZUL DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1894",
    DESCRICAO: "SERRA DA SAUDADE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1895",
    DESCRICAO: "SERRA DOS AIMORES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1896",
    DESCRICAO: "SERRA DO SALITRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1897",
    DESCRICAO: "SERRANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1898",
    DESCRICAO: "SERRANOPOLIS DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1899",
    DESCRICAO: "SERRANOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1900",
    DESCRICAO: "SERRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1901",
    DESCRICAO: "SETE LAGOAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1902",
    DESCRICAO: "SILVEIRANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1903",
    DESCRICAO: "SILVIANOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1904",
    DESCRICAO: "SIMAO PEREIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1905",
    DESCRICAO: "SIMONESIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1906",
    DESCRICAO: "SOBRALIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1907",
    DESCRICAO: "SOLEDADE DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1908",
    DESCRICAO: "TABULEIRO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1909",
    DESCRICAO: "TAIOBEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1910",
    DESCRICAO: "TAPARUBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1911",
    DESCRICAO: "TAPIRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1912",
    DESCRICAO: "TAPIRAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1913",
    DESCRICAO: "TAQUARACU DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1914",
    DESCRICAO: "TARUMIRIM",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1915",
    DESCRICAO: "TEIXEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1916",
    DESCRICAO: "TEOFILO OTONI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1917",
    DESCRICAO: "TIMOTEO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1918",
    DESCRICAO: "TIRADENTES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1919",
    DESCRICAO: "TIROS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1920",
    DESCRICAO: "TOCANTINS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1921",
    DESCRICAO: "TOCOS DO MOJI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1922",
    DESCRICAO: "TOLEDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1923",
    DESCRICAO: "TOMBOS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1924",
    DESCRICAO: "TRES CORACOES",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1925",
    DESCRICAO: "TRES MARIAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1926",
    DESCRICAO: "TRES PONTAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1927",
    DESCRICAO: "TUMIRITINGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1928",
    DESCRICAO: "TUPACIGUARA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1929",
    DESCRICAO: "TURMALINA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1930",
    DESCRICAO: "TURVOLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1931",
    DESCRICAO: "UBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1932",
    DESCRICAO: "UBAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1933",
    DESCRICAO: "UBAPORANGA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1934",
    DESCRICAO: "UBERABA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1935",
    DESCRICAO: "UBERLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1936",
    DESCRICAO: "UMBURATIBA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1937",
    DESCRICAO: "UNAI",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1938",
    DESCRICAO: "UNIAO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1939",
    DESCRICAO: "URUANA DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1940",
    DESCRICAO: "URUCANIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1941",
    DESCRICAO: "URUCUIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1942",
    DESCRICAO: "VARGEM ALEGRE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1943",
    DESCRICAO: "VARGEM BONITA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1944",
    DESCRICAO: "VARGEM GRANDE DO RIO PARDO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1945",
    DESCRICAO: "VARGINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1946",
    DESCRICAO: "VARJAO DE MINAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1947",
    DESCRICAO: "VARZEA DA PALMA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1948",
    DESCRICAO: "VARZELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1949",
    DESCRICAO: "VAZANTE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1950",
    DESCRICAO: "VERDELANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1951",
    DESCRICAO: "VEREDINHA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1952",
    DESCRICAO: "VERISSIMO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1953",
    DESCRICAO: "VERMELHO NOVO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1954",
    DESCRICAO: "VESPASIANO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1955",
    DESCRICAO: "VICOSA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1956",
    DESCRICAO: "VIEIRAS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1957",
    DESCRICAO: "MATHIAS LOBATO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1958",
    DESCRICAO: "VIRGEM DA LAPA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1959",
    DESCRICAO: "VIRGINIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1960",
    DESCRICAO: "VIRGINOPOLIS",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1961",
    DESCRICAO: "VIRGOLANDIA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1962",
    DESCRICAO: "VISCONDE DO RIO BRANCO",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1963",
    DESCRICAO: "VOLTA GRANDE",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1964",
    DESCRICAO: "WENCESLAU BRAZ",
    SIGLA: "MG",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1965",
    DESCRICAO: "ACEGUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1966",
    DESCRICAO: "AGUA SANTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1967",
    DESCRICAO: "AGUDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1968",
    DESCRICAO: "AJURICABA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1969",
    DESCRICAO: "ALECRIM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1970",
    DESCRICAO: "ALEGRETE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1971",
    DESCRICAO: "ALEGRIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1972",
    DESCRICAO: "ALMIRANTE TAMANDARE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1973",
    DESCRICAO: "ALPESTRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1974",
    DESCRICAO: "ALTO ALEGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1975",
    DESCRICAO: "ALTO FELIZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1976",
    DESCRICAO: "ALVORADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1977",
    DESCRICAO: "AMARAL FERRADOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1978",
    DESCRICAO: "AMETISTA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1979",
    DESCRICAO: "ANDRE DA ROCHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1980",
    DESCRICAO: "ANTA GORDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1981",
    DESCRICAO: "ANTONIO PRADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1982",
    DESCRICAO: "ARAMBARE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1983",
    DESCRICAO: "ARARICA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1984",
    DESCRICAO: "ARATIBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1985",
    DESCRICAO: "ARROIO DO MEIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1986",
    DESCRICAO: "ARROIO DO SAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1987",
    DESCRICAO: "ARROIO DO PADRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1988",
    DESCRICAO: "ARROIO DOS RATOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1989",
    DESCRICAO: "ARROIO DO TIGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1990",
    DESCRICAO: "ARROIO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1991",
    DESCRICAO: "ARVOREZINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1992",
    DESCRICAO: "AUGUSTO PESTANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1993",
    DESCRICAO: "AUREA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1994",
    DESCRICAO: "BAGE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1995",
    DESCRICAO: "BALNEARIO PINHAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1996",
    DESCRICAO: "BARAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1997",
    DESCRICAO: "BARAO DE COTEGIPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1998",
    DESCRICAO: "BARAO DO TRIUNFO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "1999",
    DESCRICAO: "BARRACAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2000",
    DESCRICAO: "BARRA DO GUARITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2001",
    DESCRICAO: "BARRA DO QUARAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2002",
    DESCRICAO: "BARRA DO RIBEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2003",
    DESCRICAO: "BARRA DO RIO AZUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2004",
    DESCRICAO: "BARRA FUNDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2005",
    DESCRICAO: "BARROS CASSAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2006",
    DESCRICAO: "BENJAMIN CONSTANT DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2007",
    DESCRICAO: "BENTO GONCALVES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2008",
    DESCRICAO: "BOA VISTA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2009",
    DESCRICAO: "BOA VISTA DO BURICA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2010",
    DESCRICAO: "BOA VISTA DO CADEADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2011",
    DESCRICAO: "BOA VISTA DO INCRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2012",
    DESCRICAO: "BOA VISTA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2013",
    DESCRICAO: "BOM JESUS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2014",
    DESCRICAO: "BOM PRINCIPIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2015",
    DESCRICAO: "BOM PROGRESSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2016",
    DESCRICAO: "BOM RETIRO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2017",
    DESCRICAO: "BOQUEIRAO DO LEAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2018",
    DESCRICAO: "BOSSOROCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2019",
    DESCRICAO: "BOZANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2020",
    DESCRICAO: "BRAGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2021",
    DESCRICAO: "BROCHIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2022",
    DESCRICAO: "BUTIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2023",
    DESCRICAO: "CACAPAVA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2024",
    DESCRICAO: "CACEQUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2025",
    DESCRICAO: "CACHOEIRA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2026",
    DESCRICAO: "CACHOEIRINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2027",
    DESCRICAO: "CACIQUE DOBLE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2028",
    DESCRICAO: "CAIBATE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2029",
    DESCRICAO: "CAICARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2030",
    DESCRICAO: "CAMAQUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2031",
    DESCRICAO: "CAMARGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2032",
    DESCRICAO: "CAMBARA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2033",
    DESCRICAO: "CAMPESTRE DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2034",
    DESCRICAO: "CAMPINA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2035",
    DESCRICAO: "CAMPINAS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2036",
    DESCRICAO: "CAMPO BOM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2037",
    DESCRICAO: "CAMPO NOVO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2038",
    DESCRICAO: "CAMPOS BORGES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2039",
    DESCRICAO: "CANDELARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2040",
    DESCRICAO: "CANDIDO GODOI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2041",
    DESCRICAO: "CANDIOTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2042",
    DESCRICAO: "CANELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2043",
    DESCRICAO: "CANGUCU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2044",
    DESCRICAO: "CANOAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2045",
    DESCRICAO: "CANUDOS DO VALE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2046",
    DESCRICAO: "CAPAO BONITO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2047",
    DESCRICAO: "CAPAO DA CANOA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2048",
    DESCRICAO: "CAPAO DO CIPO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2049",
    DESCRICAO: "CAPAO DO LEAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2050",
    DESCRICAO: "CAPIVARI DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2051",
    DESCRICAO: "CAPELA DE SANTANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2052",
    DESCRICAO: "CAPITAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2053",
    DESCRICAO: "CARAZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2054",
    DESCRICAO: "CARAA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2055",
    DESCRICAO: "CARLOS BARBOSA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2056",
    DESCRICAO: "CARLOS GOMES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2057",
    DESCRICAO: "CASCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2058",
    DESCRICAO: "CASEIROS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2059",
    DESCRICAO: "CATUIPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2060",
    DESCRICAO: "CAXIAS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2061",
    DESCRICAO: "CENTENARIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2062",
    DESCRICAO: "CERRITO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2063",
    DESCRICAO: "CERRO BRANCO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2064",
    DESCRICAO: "CERRO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2065",
    DESCRICAO: "CERRO GRANDE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2066",
    DESCRICAO: "CERRO LARGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2067",
    DESCRICAO: "CHAPADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2068",
    DESCRICAO: "CHARQUEADAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2069",
    DESCRICAO: "CHARRUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2070",
    DESCRICAO: "CHIAPETTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2071",
    DESCRICAO: "CHUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2072",
    DESCRICAO: "CHUVISCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2073",
    DESCRICAO: "CIDREIRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2074",
    DESCRICAO: "CIRIACO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2075",
    DESCRICAO: "COLINAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2076",
    DESCRICAO: "COLORADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2077",
    DESCRICAO: "CONDOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2078",
    DESCRICAO: "CONSTANTINA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2079",
    DESCRICAO: "COQUEIRO BAIXO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2080",
    DESCRICAO: "COQUEIROS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2081",
    DESCRICAO: "CORONEL BARROS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2082",
    DESCRICAO: "CORONEL BICACO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2083",
    DESCRICAO: "CORONEL PILAR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2084",
    DESCRICAO: "COTIPORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2085",
    DESCRICAO: "COXILHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2086",
    DESCRICAO: "CRISSIUMAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2087",
    DESCRICAO: "CRISTAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2088",
    DESCRICAO: "CRISTAL DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2089",
    DESCRICAO: "CRUZ ALTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2090",
    DESCRICAO: "CRUZALTENSE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2091",
    DESCRICAO: "CRUZEIRO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2092",
    DESCRICAO: "DAVID CANABARRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2093",
    DESCRICAO: "DERRUBADAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2094",
    DESCRICAO: "DEZESSEIS DE NOVEMBRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2095",
    DESCRICAO: "DILERMANDO DE AGUIAR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2096",
    DESCRICAO: "DOIS IRMAOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2097",
    DESCRICAO: "DOIS IRMAOS DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2098",
    DESCRICAO: "DOIS LAJEADOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2099",
    DESCRICAO: "DOM FELICIANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2100",
    DESCRICAO: "DOM PEDRO DE ALCANTARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2101",
    DESCRICAO: "DOM PEDRITO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2102",
    DESCRICAO: "DONA FRANCISCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2103",
    DESCRICAO: "DOUTOR MAURICIO CARDOSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2104",
    DESCRICAO: "DOUTOR RICARDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2105",
    DESCRICAO: "ELDORADO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2106",
    DESCRICAO: "ENCANTADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2107",
    DESCRICAO: "ENCRUZILHADA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2108",
    DESCRICAO: "ENGENHO VELHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2109",
    DESCRICAO: "ENTRE-IJUIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2110",
    DESCRICAO: "ENTRE RIOS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2111",
    DESCRICAO: "EREBANGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2112",
    DESCRICAO: "ERECHIM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2113",
    DESCRICAO: "ERNESTINA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2114",
    DESCRICAO: "HERVAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2115",
    DESCRICAO: "ERVAL GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2116",
    DESCRICAO: "ERVAL SECO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2117",
    DESCRICAO: "ESMERALDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2118",
    DESCRICAO: "ESPERANCA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2119",
    DESCRICAO: "ESPUMOSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2120",
    DESCRICAO: "ESTACAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2121",
    DESCRICAO: "ESTANCIA VELHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2122",
    DESCRICAO: "ESTEIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2123",
    DESCRICAO: "ESTRELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2124",
    DESCRICAO: "ESTRELA VELHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2125",
    DESCRICAO: "EUGENIO DE CASTRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2126",
    DESCRICAO: "FAGUNDES VARELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2127",
    DESCRICAO: "FARROUPILHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2128",
    DESCRICAO: "FAXINAL DO SOTURNO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2129",
    DESCRICAO: "FAXINALZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2130",
    DESCRICAO: "FAZENDA VILANOVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2131",
    DESCRICAO: "FELIZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2132",
    DESCRICAO: "FLORES DA CUNHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2133",
    DESCRICAO: "FLORIANO PEIXOTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2134",
    DESCRICAO: "FONTOURA XAVIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2135",
    DESCRICAO: "FORMIGUEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2136",
    DESCRICAO: "FORQUETINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2137",
    DESCRICAO: "FORTALEZA DOS VALOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2138",
    DESCRICAO: "FREDERICO WESTPHALEN",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2139",
    DESCRICAO: "GARIBALDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2140",
    DESCRICAO: "GARRUCHOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2141",
    DESCRICAO: "GAURAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2142",
    DESCRICAO: "GENERAL CAMARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2143",
    DESCRICAO: "GENTIL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2144",
    DESCRICAO: "GETULIO VARGAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2145",
    DESCRICAO: "GIRUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2146",
    DESCRICAO: "GLORINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2147",
    DESCRICAO: "GRAMADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2148",
    DESCRICAO: "GRAMADO DOS LOUREIROS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2149",
    DESCRICAO: "GRAMADO XAVIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2150",
    DESCRICAO: "GRAVATAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2151",
    DESCRICAO: "GUABIJU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2152",
    DESCRICAO: "GUAIBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2153",
    DESCRICAO: "GUAPORE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2154",
    DESCRICAO: "GUARANI DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2155",
    DESCRICAO: "HARMONIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2156",
    DESCRICAO: "HERVEIRAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2157",
    DESCRICAO: "HORIZONTINA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2158",
    DESCRICAO: "HULHA NEGRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2159",
    DESCRICAO: "HUMAITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2160",
    DESCRICAO: "IBARAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2161",
    DESCRICAO: "IBIACA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2162",
    DESCRICAO: "IBIRAIARAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2163",
    DESCRICAO: "IBIRAPUITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2164",
    DESCRICAO: "IBIRUBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2165",
    DESCRICAO: "IGREJINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2166",
    DESCRICAO: "IJUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2167",
    DESCRICAO: "ILOPOLIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2168",
    DESCRICAO: "IMBE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2169",
    DESCRICAO: "IMIGRANTE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2170",
    DESCRICAO: "INDEPENDENCIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2171",
    DESCRICAO: "INHACORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2172",
    DESCRICAO: "IPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2173",
    DESCRICAO: "IPIRANGA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2174",
    DESCRICAO: "IRAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2175",
    DESCRICAO: "ITAARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2176",
    DESCRICAO: "ITACURUBI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2177",
    DESCRICAO: "ITAPUCA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2178",
    DESCRICAO: "ITAQUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2179",
    DESCRICAO: "ITATI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2180",
    DESCRICAO: "ITATIBA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2181",
    DESCRICAO: "IVORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2182",
    DESCRICAO: "IVOTI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2183",
    DESCRICAO: "JABOTICABA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2184",
    DESCRICAO: "JACUIZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2185",
    DESCRICAO: "JACUTINGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2186",
    DESCRICAO: "JAGUARAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2187",
    DESCRICAO: "JAGUARI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2188",
    DESCRICAO: "JAQUIRANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2189",
    DESCRICAO: "JARI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2190",
    DESCRICAO: "JOIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2191",
    DESCRICAO: "JULIO DE CASTILHOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2192",
    DESCRICAO: "LAGOA BONITA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2193",
    DESCRICAO: "LAGOAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2194",
    DESCRICAO: "LAGOA DOS TRES CANTOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2195",
    DESCRICAO: "LAGOA VERMELHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2196",
    DESCRICAO: "LAJEADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2197",
    DESCRICAO: "LAJEADO DO BUGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2198",
    DESCRICAO: "LAVRAS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2199",
    DESCRICAO: "LIBERATO SALZANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2200",
    DESCRICAO: "LINDOLFO COLLOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2201",
    DESCRICAO: "LINHA NOVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2202",
    DESCRICAO: "MACHADINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2203",
    DESCRICAO: "MACAMBARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2204",
    DESCRICAO: "MAMPITUBA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2205",
    DESCRICAO: "MANOEL VIANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2206",
    DESCRICAO: "MAQUINE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2207",
    DESCRICAO: "MARATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2208",
    DESCRICAO: "MARAU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2209",
    DESCRICAO: "MARCELINO RAMOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2210",
    DESCRICAO: "MARIANA PIMENTEL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2211",
    DESCRICAO: "MARIANO MORO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2212",
    DESCRICAO: "MARQUES DE SOUZA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2213",
    DESCRICAO: "MATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2214",
    DESCRICAO: "MATO CASTELHANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2215",
    DESCRICAO: "MATO LEITAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2216",
    DESCRICAO: "MATO QUEIMADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2217",
    DESCRICAO: "MAXIMILIANO DE ALMEIDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2218",
    DESCRICAO: "MINAS DO LEAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2219",
    DESCRICAO: "MIRAGUAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2220",
    DESCRICAO: "MONTAURI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2221",
    DESCRICAO: "MONTE ALEGRE DOS CAMPOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2222",
    DESCRICAO: "MONTE BELO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2223",
    DESCRICAO: "MONTENEGRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2224",
    DESCRICAO: "MORMACO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2225",
    DESCRICAO: "MORRINHOS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2226",
    DESCRICAO: "MORRO REDONDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2227",
    DESCRICAO: "MORRO REUTER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2228",
    DESCRICAO: "MOSTARDAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2229",
    DESCRICAO: "MUCUM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2230",
    DESCRICAO: "MUITOS CAPOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2231",
    DESCRICAO: "MULITERNO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2232",
    DESCRICAO: "NAO-ME-TOQUE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2233",
    DESCRICAO: "NICOLAU VERGUEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2234",
    DESCRICAO: "NONOAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2235",
    DESCRICAO: "NOVA ALVORADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2236",
    DESCRICAO: "NOVA ARACA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2237",
    DESCRICAO: "NOVA BASSANO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2238",
    DESCRICAO: "NOVA BOA VISTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2239",
    DESCRICAO: "NOVA BRESCIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2240",
    DESCRICAO: "NOVA CANDELARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2241",
    DESCRICAO: "NOVA ESPERANCA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2242",
    DESCRICAO: "NOVA HARTZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2243",
    DESCRICAO: "NOVA PADUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2244",
    DESCRICAO: "NOVA PALMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2245",
    DESCRICAO: "NOVA PETROPOLIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2246",
    DESCRICAO: "NOVA PRATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2247",
    DESCRICAO: "NOVA RAMADA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2248",
    DESCRICAO: "NOVA ROMA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2249",
    DESCRICAO: "NOVA SANTA RITA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2250",
    DESCRICAO: "NOVO CABRAIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2251",
    DESCRICAO: "NOVO HAMBURGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2252",
    DESCRICAO: "NOVO MACHADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2253",
    DESCRICAO: "NOVO TIRADENTES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2254",
    DESCRICAO: "NOVO XINGU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2255",
    DESCRICAO: "NOVO BARREIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2256",
    DESCRICAO: "OSORIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2257",
    DESCRICAO: "PAIM FILHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2258",
    DESCRICAO: "PALMARES DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2259",
    DESCRICAO: "PALMEIRA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2260",
    DESCRICAO: "PALMITINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2261",
    DESCRICAO: "PANAMBI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2262",
    DESCRICAO: "PANTANO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2263",
    DESCRICAO: "PARAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2264",
    DESCRICAO: "PARAISO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2265",
    DESCRICAO: "PARECI NOVO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2266",
    DESCRICAO: "PAROBE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2267",
    DESCRICAO: "PASSA SETE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2268",
    DESCRICAO: "PASSO DO SOBRADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2269",
    DESCRICAO: "PASSO FUNDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2270",
    DESCRICAO: "PAULO BENTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2271",
    DESCRICAO: "PAVERAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2272",
    DESCRICAO: "PEDRAS ALTAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2273",
    DESCRICAO: "PEDRO OSORIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2274",
    DESCRICAO: "PEJUCARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2275",
    DESCRICAO: "PELOTAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2276",
    DESCRICAO: "PICADA CAFE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2277",
    DESCRICAO: "PINHAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2278",
    DESCRICAO: "PINHAL DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2279",
    DESCRICAO: "PINHAL GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2280",
    DESCRICAO: "PINHEIRINHO DO VALE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2281",
    DESCRICAO: "PINHEIRO MACHADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2282",
    DESCRICAO: "PIRAPO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2283",
    DESCRICAO: "PIRATINI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2284",
    DESCRICAO: "PLANALTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2285",
    DESCRICAO: "POCO DAS ANTAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2286",
    DESCRICAO: "PONTAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2287",
    DESCRICAO: "PONTE PRETA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2288",
    DESCRICAO: "PORTAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2289",
    DESCRICAO: "PORTO ALEGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2290",
    DESCRICAO: "PORTO LUCENA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2291",
    DESCRICAO: "PORTO MAUA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2292",
    DESCRICAO: "PORTO VERA CRUZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2293",
    DESCRICAO: "PORTO XAVIER",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2294",
    DESCRICAO: "POUSO NOVO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2295",
    DESCRICAO: "PRESIDENTE LUCENA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2296",
    DESCRICAO: "PROGRESSO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2297",
    DESCRICAO: "PROTASIO ALVES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2298",
    DESCRICAO: "PUTINGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2299",
    DESCRICAO: "QUARAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2300",
    DESCRICAO: "QUATRO IRMAOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2301",
    DESCRICAO: "QUEVEDOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2302",
    DESCRICAO: "QUINZE DE NOVEMBRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2303",
    DESCRICAO: "REDENTORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2304",
    DESCRICAO: "RELVADO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2305",
    DESCRICAO: "RESTINGA SECA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2306",
    DESCRICAO: "RIO DOS INDIOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2307",
    DESCRICAO: "RIO GRANDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2308",
    DESCRICAO: "RIO PARDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2309",
    DESCRICAO: "RIOZINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2310",
    DESCRICAO: "ROCA SALES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2311",
    DESCRICAO: "RODEIO BONITO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2312",
    DESCRICAO: "ROLADOR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2313",
    DESCRICAO: "ROLANTE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2314",
    DESCRICAO: "RONDA ALTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2315",
    DESCRICAO: "RONDINHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2316",
    DESCRICAO: "ROQUE GONZALES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2317",
    DESCRICAO: "ROSARIO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2318",
    DESCRICAO: "SAGRADA FAMILIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2319",
    DESCRICAO: "SALDANHA MARINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2320",
    DESCRICAO: "SALTO DO JACUI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2321",
    DESCRICAO: "SALVADOR DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2322",
    DESCRICAO: "SALVADOR DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2323",
    DESCRICAO: "SANANDUVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2324",
    DESCRICAO: "SANTA BARBARA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2325",
    DESCRICAO: "SANTA CECILIA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2326",
    DESCRICAO: "SANTA CLARA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2327",
    DESCRICAO: "SANTA CRUZ DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2328",
    DESCRICAO: "SANTA MARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2329",
    DESCRICAO: "SANTA MARIA DO HERVAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2330",
    DESCRICAO: "SANTA MARGARIDA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2331",
    DESCRICAO: "SANTANA DA BOA VISTA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2332",
    DESCRICAO: "SANTANA DO LIVRAMENTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2333",
    DESCRICAO: "SANTA ROSA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2334",
    DESCRICAO: "SANTA TEREZA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2335",
    DESCRICAO: "SANTA VITORIA DO PALMAR",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2336",
    DESCRICAO: "SANTIAGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2337",
    DESCRICAO: "SANTO ANGELO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2338",
    DESCRICAO: "SANTO ANTONIO DO PALMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2339",
    DESCRICAO: "SANTO ANTONIO DA PATRULHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2340",
    DESCRICAO: "SANTO ANTONIO DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2341",
    DESCRICAO: "SANTO ANTONIO DO PLANALTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2342",
    DESCRICAO: "SANTO AUGUSTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2343",
    DESCRICAO: "SANTO CRISTO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2344",
    DESCRICAO: "SANTO EXPEDITO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2345",
    DESCRICAO: "SAO BORJA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2346",
    DESCRICAO: "SAO DOMINGOS DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2347",
    DESCRICAO: "SAO FRANCISCO DE ASSIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2348",
    DESCRICAO: "SAO FRANCISCO DE PAULA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2349",
    DESCRICAO: "SAO GABRIEL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2350",
    DESCRICAO: "SAO JERONIMO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2351",
    DESCRICAO: "SAO JOAO DA URTIGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2352",
    DESCRICAO: "SAO JOAO DO POLESINE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2353",
    DESCRICAO: "SAO JORGE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2354",
    DESCRICAO: "SAO JOSE DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2355",
    DESCRICAO: "SAO JOSE DO HERVAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2356",
    DESCRICAO: "SAO JOSE DO HORTENCIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2357",
    DESCRICAO: "SAO JOSE DO INHACORA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2358",
    DESCRICAO: "SAO JOSE DO NORTE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2359",
    DESCRICAO: "SAO JOSE DO OURO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2360",
    DESCRICAO: "SAO JOSE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2361",
    DESCRICAO: "SAO JOSE DOS AUSENTES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2362",
    DESCRICAO: "SAO LEOPOLDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2363",
    DESCRICAO: "SAO LOURENCO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2364",
    DESCRICAO: "SAO LUIZ GONZAGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2365",
    DESCRICAO: "SAO MARCOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2366",
    DESCRICAO: "SAO MARTINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2367",
    DESCRICAO: "SAO MARTINHO DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2368",
    DESCRICAO: "SAO MIGUEL DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2369",
    DESCRICAO: "SAO NICOLAU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2370",
    DESCRICAO: "SAO PAULO DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2371",
    DESCRICAO: "SAO PEDRO DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2372",
    DESCRICAO: "SAO PEDRO DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2373",
    DESCRICAO: "SAO PEDRO DO BUTIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2374",
    DESCRICAO: "SAO PEDRO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2375",
    DESCRICAO: "SAO SEBASTIAO DO CAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2376",
    DESCRICAO: "SAO SEPE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2377",
    DESCRICAO: "SAO VALENTIM",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2378",
    DESCRICAO: "SAO VALENTIM DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2379",
    DESCRICAO: "SAO VALERIO DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2380",
    DESCRICAO: "SAO VENDELINO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2381",
    DESCRICAO: "SAO VICENTE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2382",
    DESCRICAO: "SAPIRANGA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2383",
    DESCRICAO: "SAPUCAIA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2384",
    DESCRICAO: "SARANDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2385",
    DESCRICAO: "SEBERI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2386",
    DESCRICAO: "SEDE NOVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2387",
    DESCRICAO: "SEGREDO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2388",
    DESCRICAO: "SELBACH",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2389",
    DESCRICAO: "SENADOR SALGADO FILHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2390",
    DESCRICAO: "SENTINELA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2391",
    DESCRICAO: "SERAFINA CORREA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2392",
    DESCRICAO: "SERIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2393",
    DESCRICAO: "SERTAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2394",
    DESCRICAO: "SERTAO SANTANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2395",
    DESCRICAO: "SETE DE SETEMBRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2396",
    DESCRICAO: "SEVERIANO DE ALMEIDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2397",
    DESCRICAO: "SILVEIRA MARTINS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2398",
    DESCRICAO: "SINIMBU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2399",
    DESCRICAO: "SOBRADINHO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2400",
    DESCRICAO: "SOLEDADE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2401",
    DESCRICAO: "TABAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2402",
    DESCRICAO: "TAPEJARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2403",
    DESCRICAO: "TAPERA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2404",
    DESCRICAO: "TAPES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2405",
    DESCRICAO: "TAQUARA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2406",
    DESCRICAO: "TAQUARI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2407",
    DESCRICAO: "TAQUARUCU DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2408",
    DESCRICAO: "TAVARES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2409",
    DESCRICAO: "TENENTE PORTELA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2410",
    DESCRICAO: "TERRA DE AREIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2411",
    DESCRICAO: "TEUTONIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2412",
    DESCRICAO: "TIO HUGO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2413",
    DESCRICAO: "TIRADENTES DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2414",
    DESCRICAO: "TOROPI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2415",
    DESCRICAO: "TORRES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2416",
    DESCRICAO: "TRAMANDAI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2417",
    DESCRICAO: "TRAVESSEIRO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2418",
    DESCRICAO: "TRES ARROIOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2419",
    DESCRICAO: "TRES CACHOEIRAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2420",
    DESCRICAO: "TRES COROAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2421",
    DESCRICAO: "TRES DE MAIO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2422",
    DESCRICAO: "TRES FORQUILHAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2423",
    DESCRICAO: "TRES PALMEIRAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2424",
    DESCRICAO: "TRES PASSOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2425",
    DESCRICAO: "TRINDADE DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2426",
    DESCRICAO: "TRIUNFO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2427",
    DESCRICAO: "TUCUNDUVA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2428",
    DESCRICAO: "TUNAS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2429",
    DESCRICAO: "TUPANCI DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2430",
    DESCRICAO: "TUPANCIRETA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2431",
    DESCRICAO: "TUPANDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2432",
    DESCRICAO: "TUPARENDI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2433",
    DESCRICAO: "TURUCU",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2434",
    DESCRICAO: "UBIRETAMA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2435",
    DESCRICAO: "UNIAO DA SERRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2436",
    DESCRICAO: "UNISTALDA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2437",
    DESCRICAO: "URUGUAIANA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2438",
    DESCRICAO: "VACARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2439",
    DESCRICAO: "VALE VERDE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2440",
    DESCRICAO: "VALE DO SOL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2441",
    DESCRICAO: "VALE REAL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2442",
    DESCRICAO: "VANINI",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2443",
    DESCRICAO: "VENANCIO AIRES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2444",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2445",
    DESCRICAO: "VERANOPOLIS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2446",
    DESCRICAO: "VESPASIANO CORREA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2447",
    DESCRICAO: "VIADUTOS",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2448",
    DESCRICAO: "VIAMAO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2449",
    DESCRICAO: "VICENTE DUTRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2450",
    DESCRICAO: "VICTOR GRAEFF",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2451",
    DESCRICAO: "VILA FLORES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2452",
    DESCRICAO: "VILA LANGARO",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2453",
    DESCRICAO: "VILA MARIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2454",
    DESCRICAO: "VILA NOVA DO SUL",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2455",
    DESCRICAO: "VISTA ALEGRE",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2456",
    DESCRICAO: "VISTA ALEGRE DO PRATA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2457",
    DESCRICAO: "VISTA GAUCHA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2458",
    DESCRICAO: "VITORIA DAS MISSOES",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2459",
    DESCRICAO: "WESTFALIA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2460",
    DESCRICAO: "XANGRI-LA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2461",
    DESCRICAO: "ABDON BATISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2462",
    DESCRICAO: "ABELARDO LUZ",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2463",
    DESCRICAO: "AGROLANDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2464",
    DESCRICAO: "AGRONOMICA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2465",
    DESCRICAO: "AGUA DOCE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2466",
    DESCRICAO: "AGUAS DE CHAPECO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2467",
    DESCRICAO: "AGUAS FRIAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2468",
    DESCRICAO: "AGUAS MORNAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2469",
    DESCRICAO: "ALFREDO WAGNER",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2470",
    DESCRICAO: "ALTO BELA VISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2471",
    DESCRICAO: "ANCHIETA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2472",
    DESCRICAO: "ANGELINA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2473",
    DESCRICAO: "ANITA GARIBALDI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2474",
    DESCRICAO: "ANITAPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2475",
    DESCRICAO: "ANTONIO CARLOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2476",
    DESCRICAO: "APIUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2477",
    DESCRICAO: "ARABUTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2478",
    DESCRICAO: "ARAQUARI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2479",
    DESCRICAO: "ARARANGUA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2480",
    DESCRICAO: "ARMAZEM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2481",
    DESCRICAO: "ARROIO TRINTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2482",
    DESCRICAO: "ARVOREDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2483",
    DESCRICAO: "ASCURRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2484",
    DESCRICAO: "ATALANTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2485",
    DESCRICAO: "AURORA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2486",
    DESCRICAO: "BALNEARIO ARROIO DO SILVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2487",
    DESCRICAO: "BALNEARIO CAMBORIU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2488",
    DESCRICAO: "BALNEARIO BARRA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2489",
    DESCRICAO: "BALNEARIO GAIVOTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2490",
    DESCRICAO: "BANDEIRANTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2491",
    DESCRICAO: "BARRA BONITA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2492",
    DESCRICAO: "BARRA VELHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2493",
    DESCRICAO: "BELA VISTA DO TOLDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2494",
    DESCRICAO: "BELMONTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2495",
    DESCRICAO: "BENEDITO NOVO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2496",
    DESCRICAO: "BIGUACU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2497",
    DESCRICAO: "BLUMENAU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2498",
    DESCRICAO: "BOCAINA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2499",
    DESCRICAO: "BOMBINHAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2500",
    DESCRICAO: "BOM JARDIM DA SERRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2501",
    DESCRICAO: "BOM JESUS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2502",
    DESCRICAO: "BOM JESUS DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2503",
    DESCRICAO: "BOM RETIRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2504",
    DESCRICAO: "BOTUVERA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2505",
    DESCRICAO: "BRACO DO NORTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2506",
    DESCRICAO: "BRACO DO TROMBUDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2507",
    DESCRICAO: "BRUNOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2508",
    DESCRICAO: "BRUSQUE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2509",
    DESCRICAO: "CACADOR",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2510",
    DESCRICAO: "CAIBI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2511",
    DESCRICAO: "CALMON",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2512",
    DESCRICAO: "CAMBORIU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2513",
    DESCRICAO: "CAPAO ALTO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2514",
    DESCRICAO: "CAMPO ALEGRE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2515",
    DESCRICAO: "CAMPO BELO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2516",
    DESCRICAO: "CAMPO ERE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2517",
    DESCRICAO: "CAMPOS NOVOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2518",
    DESCRICAO: "CANELINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2519",
    DESCRICAO: "CANOINHAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2520",
    DESCRICAO: "CAPINZAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2521",
    DESCRICAO: "CAPIVARI DE BAIXO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2522",
    DESCRICAO: "CATANDUVAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2523",
    DESCRICAO: "CAXAMBU DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2524",
    DESCRICAO: "CELSO RAMOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2525",
    DESCRICAO: "CERRO NEGRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2526",
    DESCRICAO: "CHAPADAO DO LAGEADO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2527",
    DESCRICAO: "CHAPECO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2528",
    DESCRICAO: "COCAL DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2529",
    DESCRICAO: "CONCORDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2530",
    DESCRICAO: "CORDILHEIRA ALTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2531",
    DESCRICAO: "CORONEL FREITAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2532",
    DESCRICAO: "CORONEL MARTINS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2533",
    DESCRICAO: "CORUPA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2534",
    DESCRICAO: "CORREIA PINTO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2535",
    DESCRICAO: "CRICIUMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2536",
    DESCRICAO: "CUNHA PORA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2537",
    DESCRICAO: "CUNHATAI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2538",
    DESCRICAO: "CURITIBANOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2539",
    DESCRICAO: "DESCANSO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2540",
    DESCRICAO: "DIONISIO CERQUEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2541",
    DESCRICAO: "DONA EMMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2542",
    DESCRICAO: "DOUTOR PEDRINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2543",
    DESCRICAO: "ENTRE RIOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2544",
    DESCRICAO: "ERMO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2545",
    DESCRICAO: "ERVAL VELHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2546",
    DESCRICAO: "FAXINAL DOS GUEDES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2547",
    DESCRICAO: "FLOR DO SERTAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2548",
    DESCRICAO: "FLORIANOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2549",
    DESCRICAO: "FORMOSA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2550",
    DESCRICAO: "FORQUILHINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2551",
    DESCRICAO: "FRAIBURGO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2552",
    DESCRICAO: "FREI ROGERIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2553",
    DESCRICAO: "GALVAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2554",
    DESCRICAO: "GAROPABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2555",
    DESCRICAO: "GARUVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2556",
    DESCRICAO: "GASPAR",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2557",
    DESCRICAO: "GOVERNADOR CELSO RAMOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2558",
    DESCRICAO: "GRAO PARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2559",
    DESCRICAO: "GRAVATAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2560",
    DESCRICAO: "GUABIRUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2561",
    DESCRICAO: "GUARACIABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2562",
    DESCRICAO: "GUARAMIRIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2563",
    DESCRICAO: "GUARUJA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2564",
    DESCRICAO: "GUATAMBU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2565",
    DESCRICAO: "HERVAL D OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2566",
    DESCRICAO: "IBIAM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2567",
    DESCRICAO: "IBICARE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2568",
    DESCRICAO: "IBIRAMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2569",
    DESCRICAO: "ICARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2570",
    DESCRICAO: "ILHOTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2571",
    DESCRICAO: "IMARUI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2572",
    DESCRICAO: "IMBITUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2573",
    DESCRICAO: "IMBUIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2574",
    DESCRICAO: "INDAIAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2575",
    DESCRICAO: "IOMERE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2576",
    DESCRICAO: "IPIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2577",
    DESCRICAO: "IPORA DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2578",
    DESCRICAO: "IPUACU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2579",
    DESCRICAO: "IPUMIRIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2580",
    DESCRICAO: "IRACEMINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2581",
    DESCRICAO: "IRANI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2582",
    DESCRICAO: "IRATI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2583",
    DESCRICAO: "IRINEOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2584",
    DESCRICAO: "ITA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2585",
    DESCRICAO: "ITAIOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2586",
    DESCRICAO: "ITAJAI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2587",
    DESCRICAO: "ITAPEMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2588",
    DESCRICAO: "ITAPIRANGA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2589",
    DESCRICAO: "ITAPOA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2590",
    DESCRICAO: "ITUPORANGA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2591",
    DESCRICAO: "JABORA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2592",
    DESCRICAO: "JACINTO MACHADO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2593",
    DESCRICAO: "JAGUARUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2594",
    DESCRICAO: "JARAGUA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2595",
    DESCRICAO: "JARDINOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2596",
    DESCRICAO: "JOACABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2597",
    DESCRICAO: "JOINVILLE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2598",
    DESCRICAO: "JOSE BOITEUX",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2599",
    DESCRICAO: "JUPIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2600",
    DESCRICAO: "LACERDOPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2601",
    DESCRICAO: "LAGES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2602",
    DESCRICAO: "LAGUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2603",
    DESCRICAO: "LAJEADO GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2604",
    DESCRICAO: "LAURENTINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2605",
    DESCRICAO: "LAURO MULLER",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2606",
    DESCRICAO: "LEBON REGIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2607",
    DESCRICAO: "LEOBERTO LEAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2608",
    DESCRICAO: "LINDOIA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2609",
    DESCRICAO: "LONTRAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2610",
    DESCRICAO: "LUIZ ALVES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2611",
    DESCRICAO: "LUZERNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2612",
    DESCRICAO: "MACIEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2613",
    DESCRICAO: "MAFRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2614",
    DESCRICAO: "MAJOR GERCINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2615",
    DESCRICAO: "MAJOR VIEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2616",
    DESCRICAO: "MARACAJA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2617",
    DESCRICAO: "MARAVILHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2618",
    DESCRICAO: "MAREMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2619",
    DESCRICAO: "MASSARANDUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2620",
    DESCRICAO: "MATOS COSTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2621",
    DESCRICAO: "MELEIRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2622",
    DESCRICAO: "MIRIM DOCE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2623",
    DESCRICAO: "MODELO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2624",
    DESCRICAO: "MONDAI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2625",
    DESCRICAO: "MONTE CARLO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2626",
    DESCRICAO: "MONTE CASTELO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2627",
    DESCRICAO: "MORRO DA FUMACA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2628",
    DESCRICAO: "MORRO GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2629",
    DESCRICAO: "NAVEGANTES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2630",
    DESCRICAO: "NOVA ERECHIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2631",
    DESCRICAO: "NOVA ITABERABA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2632",
    DESCRICAO: "NOVA TRENTO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2633",
    DESCRICAO: "NOVA VENEZA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2634",
    DESCRICAO: "NOVO HORIZONTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2635",
    DESCRICAO: "ORLEANS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2636",
    DESCRICAO: "OTACILIO COSTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2637",
    DESCRICAO: "OURO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2638",
    DESCRICAO: "OURO VERDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2639",
    DESCRICAO: "PAIAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2640",
    DESCRICAO: "PAINEL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2641",
    DESCRICAO: "PALHOCA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2642",
    DESCRICAO: "PALMA SOLA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2643",
    DESCRICAO: "PALMEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2644",
    DESCRICAO: "PALMITOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2645",
    DESCRICAO: "PAPANDUVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2646",
    DESCRICAO: "PARAISO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2647",
    DESCRICAO: "PASSO DE TORRES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2648",
    DESCRICAO: "PASSOS MAIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2649",
    DESCRICAO: "PAULO LOPES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2650",
    DESCRICAO: "PEDRAS GRANDES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2651",
    DESCRICAO: "PENHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2652",
    DESCRICAO: "PERITIBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2653",
    DESCRICAO: "PETROLANDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2654",
    DESCRICAO: "BALNEARIO PICARRAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2655",
    DESCRICAO: "PINHALZINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2656",
    DESCRICAO: "PINHEIRO PRETO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2657",
    DESCRICAO: "PIRATUBA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2658",
    DESCRICAO: "PLANALTO ALEGRE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2659",
    DESCRICAO: "POMERODE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2660",
    DESCRICAO: "PONTE ALTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2661",
    DESCRICAO: "PONTE ALTA DO NORTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2662",
    DESCRICAO: "PONTE SERRADA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2663",
    DESCRICAO: "PORTO BELO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2664",
    DESCRICAO: "PORTO UNIAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2665",
    DESCRICAO: "POUSO REDONDO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2666",
    DESCRICAO: "PRAIA GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2667",
    DESCRICAO: "PRESIDENTE CASTELO BRANCO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2668",
    DESCRICAO: "PRESIDENTE GETULIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2669",
    DESCRICAO: "PRESIDENTE NEREU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2670",
    DESCRICAO: "PRINCESA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2671",
    DESCRICAO: "QUILOMBO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2672",
    DESCRICAO: "RANCHO QUEIMADO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2673",
    DESCRICAO: "RIO DAS ANTAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2674",
    DESCRICAO: "RIO DO CAMPO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2675",
    DESCRICAO: "RIO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2676",
    DESCRICAO: "RIO DOS CEDROS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2677",
    DESCRICAO: "RIO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2678",
    DESCRICAO: "RIO FORTUNA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2679",
    DESCRICAO: "RIO NEGRINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2680",
    DESCRICAO: "RIO RUFINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2681",
    DESCRICAO: "RIQUEZA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2682",
    DESCRICAO: "RODEIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2683",
    DESCRICAO: "ROMELANDIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2684",
    DESCRICAO: "SALETE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2685",
    DESCRICAO: "SALTINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2686",
    DESCRICAO: "SALTO VELOSO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2687",
    DESCRICAO: "SANGAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2688",
    DESCRICAO: "SANTA CECILIA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2689",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2690",
    DESCRICAO: "SANTA ROSA DE LIMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2691",
    DESCRICAO: "SANTA ROSA DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2692",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2693",
    DESCRICAO: "SANTA TEREZINHA DO PROGRESSO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2694",
    DESCRICAO: "SANTIAGO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2695",
    DESCRICAO: "SANTO AMARO DA IMPERATRIZ",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2696",
    DESCRICAO: "SAO BERNARDINO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2697",
    DESCRICAO: "SAO BENTO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2698",
    DESCRICAO: "SAO BONIFACIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2699",
    DESCRICAO: "SAO CARLOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2700",
    DESCRICAO: "SAO CRISTOVAO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2701",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2702",
    DESCRICAO: "SAO FRANCISCO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2703",
    DESCRICAO: "SAO JOAO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2704",
    DESCRICAO: "SAO JOAO BATISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2705",
    DESCRICAO: "SAO JOAO DO ITAPERIU",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2706",
    DESCRICAO: "SAO JOAO DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2707",
    DESCRICAO: "SAO JOAQUIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2708",
    DESCRICAO: "SAO JOSE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2709",
    DESCRICAO: "SAO JOSE DO CEDRO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2710",
    DESCRICAO: "SAO JOSE DO CERRITO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2711",
    DESCRICAO: "SAO LOURENCO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2712",
    DESCRICAO: "SAO LUDGERO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2713",
    DESCRICAO: "SAO MARTINHO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2714",
    DESCRICAO: "SAO MIGUEL DA BOA VISTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2715",
    DESCRICAO: "SAO MIGUEL DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2716",
    DESCRICAO: "SAO PEDRO DE ALCANTARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2717",
    DESCRICAO: "SAUDADES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2718",
    DESCRICAO: "SCHROEDER",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2719",
    DESCRICAO: "SEARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2720",
    DESCRICAO: "SERRA ALTA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2721",
    DESCRICAO: "SIDEROPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2722",
    DESCRICAO: "SOMBRIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2723",
    DESCRICAO: "SUL BRASIL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2724",
    DESCRICAO: "TAIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2725",
    DESCRICAO: "TANGARA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2726",
    DESCRICAO: "TIGRINHOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2727",
    DESCRICAO: "TIJUCAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2728",
    DESCRICAO: "TIMBE DO SUL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2729",
    DESCRICAO: "TIMBO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2730",
    DESCRICAO: "TIMBO GRANDE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2731",
    DESCRICAO: "TRES BARRAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2732",
    DESCRICAO: "TREVISO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2733",
    DESCRICAO: "TREZE DE MAIO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2734",
    DESCRICAO: "TREZE TILIAS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2735",
    DESCRICAO: "TROMBUDO CENTRAL",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2736",
    DESCRICAO: "TUBARAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2737",
    DESCRICAO: "TUNAPOLIS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2738",
    DESCRICAO: "TURVO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2739",
    DESCRICAO: "UNIAO DO OESTE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2740",
    DESCRICAO: "URUBICI",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2741",
    DESCRICAO: "URUPEMA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2742",
    DESCRICAO: "URUSSANGA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2743",
    DESCRICAO: "VARGEAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2744",
    DESCRICAO: "VARGEM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2745",
    DESCRICAO: "VARGEM BONITA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2746",
    DESCRICAO: "VIDAL RAMOS",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2747",
    DESCRICAO: "VIDEIRA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2748",
    DESCRICAO: "VITOR MEIRELES",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2749",
    DESCRICAO: "WITMARSUM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2750",
    DESCRICAO: "XANXERE",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2751",
    DESCRICAO: "XAVANTINA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2752",
    DESCRICAO: "XAXIM",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2753",
    DESCRICAO: "ZORTEA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2754",
    DESCRICAO: "ANGRA DOS REIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2755",
    DESCRICAO: "APERIBE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2756",
    DESCRICAO: "ARARUAMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2757",
    DESCRICAO: "AREAL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2758",
    DESCRICAO: "ARMACAO DOS BUZIOS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2759",
    DESCRICAO: "ARRAIAL DO CABO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2760",
    DESCRICAO: "BARRA DO PIRAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2761",
    DESCRICAO: "BARRA MANSA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2762",
    DESCRICAO: "BELFORD ROXO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2763",
    DESCRICAO: "BOM JARDIM",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2764",
    DESCRICAO: "BOM JESUS DO ITABAPOANA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2765",
    DESCRICAO: "CABO FRIO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2766",
    DESCRICAO: "CACHOEIRAS DE MACACU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2767",
    DESCRICAO: "CAMBUCI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2768",
    DESCRICAO: "CARAPEBUS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2769",
    DESCRICAO: "COMENDADOR LEVY GASPARIAN",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2770",
    DESCRICAO: "CAMPOS DOS GOYTACAZES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2771",
    DESCRICAO: "CANTAGALO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2772",
    DESCRICAO: "CARDOSO MOREIRA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2773",
    DESCRICAO: "CARMO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2774",
    DESCRICAO: "CASIMIRO DE ABREU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2775",
    DESCRICAO: "CONCEICAO DE MACABU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2776",
    DESCRICAO: "CORDEIRO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2777",
    DESCRICAO: "DUAS BARRAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2778",
    DESCRICAO: "DUQUE DE CAXIAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2779",
    DESCRICAO: "ENGENHEIRO PAULO DE FRONTIN",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2780",
    DESCRICAO: "GUAPIMIRIM",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2781",
    DESCRICAO: "IGUABA GRANDE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2782",
    DESCRICAO: "ITABORAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2783",
    DESCRICAO: "ITAGUAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2784",
    DESCRICAO: "ITALVA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2785",
    DESCRICAO: "ITAOCARA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2786",
    DESCRICAO: "ITAPERUNA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2787",
    DESCRICAO: "ITATIAIA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2788",
    DESCRICAO: "JAPERI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2789",
    DESCRICAO: "LAJE DO MURIAE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2790",
    DESCRICAO: "MACAE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2791",
    DESCRICAO: "MACUCO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2792",
    DESCRICAO: "MAGE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2793",
    DESCRICAO: "MANGARATIBA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2794",
    DESCRICAO: "MARICA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2795",
    DESCRICAO: "MENDES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2796",
    DESCRICAO: "MESQUITA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2797",
    DESCRICAO: "MIGUEL PEREIRA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2798",
    DESCRICAO: "MIRACEMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2799",
    DESCRICAO: "NATIVIDADE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2800",
    DESCRICAO: "NILOPOLIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2801",
    DESCRICAO: "NITEROI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2802",
    DESCRICAO: "NOVA FRIBURGO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2803",
    DESCRICAO: "NOVA IGUACU",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2804",
    DESCRICAO: "PARACAMBI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2805",
    DESCRICAO: "PARAIBA DO SUL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2806",
    DESCRICAO: "PARATI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2807",
    DESCRICAO: "PATY DO ALFERES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2808",
    DESCRICAO: "PETROPOLIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2809",
    DESCRICAO: "PINHEIRAL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2810",
    DESCRICAO: "PIRAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2811",
    DESCRICAO: "PORCIUNCULA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2812",
    DESCRICAO: "PORTO REAL",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2813",
    DESCRICAO: "QUATIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2814",
    DESCRICAO: "QUEIMADOS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2815",
    DESCRICAO: "QUISSAMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2816",
    DESCRICAO: "RESENDE",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2817",
    DESCRICAO: "RIO BONITO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2818",
    DESCRICAO: "RIO CLARO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2819",
    DESCRICAO: "RIO DAS FLORES",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2820",
    DESCRICAO: "RIO DAS OSTRAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2821",
    DESCRICAO: "RIO DE JANEIRO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2822",
    DESCRICAO: "SANTA MARIA MADALENA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2823",
    DESCRICAO: "SANTO ANTONIO DE PADUA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2824",
    DESCRICAO: "SAO FRANCISCO DE ITABAPOANA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2825",
    DESCRICAO: "SAO FIDELIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2826",
    DESCRICAO: "SAO GONCALO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2827",
    DESCRICAO: "SAO JOAO DA BARRA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2828",
    DESCRICAO: "SAO JOAO DE MERITI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2829",
    DESCRICAO: "SAO JOSE DE UBA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2830",
    DESCRICAO: "SAO JOSE DO VALE DO RIO PRETO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2831",
    DESCRICAO: "SAO PEDRO DA ALDEIA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2832",
    DESCRICAO: "SAO SEBASTIAO DO ALTO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2833",
    DESCRICAO: "SAPUCAIA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2834",
    DESCRICAO: "SAQUAREMA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2835",
    DESCRICAO: "SEROPEDICA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2836",
    DESCRICAO: "SILVA JARDIM",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2837",
    DESCRICAO: "SUMIDOURO",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2838",
    DESCRICAO: "TANGUA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2839",
    DESCRICAO: "TERESOPOLIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2840",
    DESCRICAO: "TRAJANO DE MORAIS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2841",
    DESCRICAO: "TRES RIOS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2842",
    DESCRICAO: "VALENCA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2843",
    DESCRICAO: "VARRE-SAI",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2844",
    DESCRICAO: "VASSOURAS",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2845",
    DESCRICAO: "VOLTA REDONDA",
    SIGLA: "RJ",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2846",
    DESCRICAO: "AGUA BRANCA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2847",
    DESCRICAO: "ANADIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2848",
    DESCRICAO: "ARAPIRACA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2849",
    DESCRICAO: "ATALAIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2850",
    DESCRICAO: "BARRA DE SANTO ANTONIO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2851",
    DESCRICAO: "BARRA DE SAO MIGUEL",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2852",
    DESCRICAO: "BATALHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2853",
    DESCRICAO: "BELEM",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2854",
    DESCRICAO: "BELO MONTE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2855",
    DESCRICAO: "BOCA DA MATA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2856",
    DESCRICAO: "BRANQUINHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2857",
    DESCRICAO: "CACIMBINHAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2858",
    DESCRICAO: "CAJUEIRO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2859",
    DESCRICAO: "CAMPESTRE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2860",
    DESCRICAO: "CAMPO ALEGRE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2861",
    DESCRICAO: "CAMPO GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2862",
    DESCRICAO: "CANAPI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2863",
    DESCRICAO: "CAPELA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2864",
    DESCRICAO: "CARNEIROS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2865",
    DESCRICAO: "CHA PRETA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2866",
    DESCRICAO: "COITE DO NOIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2867",
    DESCRICAO: "COLONIA LEOPOLDINA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2868",
    DESCRICAO: "COQUEIRO SECO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2869",
    DESCRICAO: "CORURIPE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2870",
    DESCRICAO: "CRAIBAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2871",
    DESCRICAO: "DELMIRO GOUVEIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2872",
    DESCRICAO: "DOIS RIACHOS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2873",
    DESCRICAO: "ESTRELA DE ALAGOAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2874",
    DESCRICAO: "FEIRA GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2875",
    DESCRICAO: "FELIZ DESERTO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2876",
    DESCRICAO: "FLEXEIRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2877",
    DESCRICAO: "GIRAU DO PONCIANO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2878",
    DESCRICAO: "IBATEGUARA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2879",
    DESCRICAO: "IGACI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2880",
    DESCRICAO: "IGREJA NOVA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2881",
    DESCRICAO: "INHAPI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2882",
    DESCRICAO: "JACARE DOS HOMENS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2883",
    DESCRICAO: "JACUIPE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2884",
    DESCRICAO: "JAPARATINGA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2885",
    DESCRICAO: "JARAMATAIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2886",
    DESCRICAO: "JEQUIA DA PRAIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2887",
    DESCRICAO: "JOAQUIM GOMES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2888",
    DESCRICAO: "JUNDIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2889",
    DESCRICAO: "JUNQUEIRO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2890",
    DESCRICAO: "LAGOA DA CANOA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2891",
    DESCRICAO: "LIMOEIRO DE ANADIA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2892",
    DESCRICAO: "MACEIO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2893",
    DESCRICAO: "MAJOR ISIDORO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2894",
    DESCRICAO: "MARAGOGI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2895",
    DESCRICAO: "MARAVILHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2896",
    DESCRICAO: "MARECHAL DEODORO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2897",
    DESCRICAO: "MARIBONDO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2898",
    DESCRICAO: "MAR VERMELHO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2899",
    DESCRICAO: "MATA GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2900",
    DESCRICAO: "MATRIZ DE CAMARAGIBE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2901",
    DESCRICAO: "MESSIAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2902",
    DESCRICAO: "MINADOR DO NEGRAO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2903",
    DESCRICAO: "MONTEIROPOLIS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2904",
    DESCRICAO: "MURICI",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2905",
    DESCRICAO: "NOVO LINO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2906",
    DESCRICAO: "OLHO D AGUA DAS FLORES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2907",
    DESCRICAO: "OLHO D AGUA DO CASADO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2908",
    DESCRICAO: "OLHO D AGUA GRANDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2909",
    DESCRICAO: "OLIVENCA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2910",
    DESCRICAO: "OURO BRANCO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2911",
    DESCRICAO: "PALESTINA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2912",
    DESCRICAO: "PALMEIRA DOS INDIOS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2913",
    DESCRICAO: "PAO DE ACUCAR",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2914",
    DESCRICAO: "PARICONHA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2915",
    DESCRICAO: "PARIPUEIRA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2916",
    DESCRICAO: "PASSO DE CAMARAGIBE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2917",
    DESCRICAO: "PAULO JACINTO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2918",
    DESCRICAO: "PENEDO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2919",
    DESCRICAO: "PIACABUCU",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2920",
    DESCRICAO: "PILAR",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2921",
    DESCRICAO: "PINDOBA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2922",
    DESCRICAO: "PIRANHAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2923",
    DESCRICAO: "POCO DAS TRINCHEIRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2924",
    DESCRICAO: "PORTO CALVO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2925",
    DESCRICAO: "PORTO DE PEDRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2926",
    DESCRICAO: "PORTO REAL DO COLEGIO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2927",
    DESCRICAO: "QUEBRANGULO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2928",
    DESCRICAO: "RIO LARGO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2929",
    DESCRICAO: "ROTEIRO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2930",
    DESCRICAO: "SANTA LUZIA DO NORTE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2931",
    DESCRICAO: "SANTANA DO IPANEMA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2932",
    DESCRICAO: "SANTANA DO MUNDAU",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2933",
    DESCRICAO: "SAO BRAS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2934",
    DESCRICAO: "SAO JOSE DA LAJE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2935",
    DESCRICAO: "SAO JOSE DA TAPERA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2936",
    DESCRICAO: "SAO LUIS DO QUITUNDE",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2937",
    DESCRICAO: "SAO MIGUEL DOS CAMPOS",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2938",
    DESCRICAO: "SAO MIGUEL DOS MILAGRES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2939",
    DESCRICAO: "SAO SEBASTIAO",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2940",
    DESCRICAO: "SATUBA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2941",
    DESCRICAO: "SENADOR RUI PALMEIRA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2942",
    DESCRICAO: "TANQUE D ARCA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2943",
    DESCRICAO: "TAQUARANA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2944",
    DESCRICAO: "TEOTONIO VILELA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2945",
    DESCRICAO: "TRAIPU",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2946",
    DESCRICAO: "UNIAO DOS PALMARES",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2947",
    DESCRICAO: "VICOSA",
    SIGLA: "AL",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2948",
    DESCRICAO: "ABAIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2949",
    DESCRICAO: "ABARE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2950",
    DESCRICAO: "ACAJUTIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2951",
    DESCRICAO: "ADUSTINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2952",
    DESCRICAO: "AGUA FRIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2953",
    DESCRICAO: "ERICO CARDOSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2954",
    DESCRICAO: "AIQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2955",
    DESCRICAO: "ALAGOINHAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2956",
    DESCRICAO: "ALCOBACA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2957",
    DESCRICAO: "ALMADINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2958",
    DESCRICAO: "AMARGOSA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2959",
    DESCRICAO: "AMELIA RODRIGUES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2960",
    DESCRICAO: "AMERICA DOURADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2961",
    DESCRICAO: "ANAGE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2962",
    DESCRICAO: "ANDARAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2963",
    DESCRICAO: "ANDORINHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2964",
    DESCRICAO: "ANGICAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2965",
    DESCRICAO: "ANGUERA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2966",
    DESCRICAO: "ANTAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2967",
    DESCRICAO: "ANTONIO CARDOSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2968",
    DESCRICAO: "ANTONIO GONCALVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2969",
    DESCRICAO: "APORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2970",
    DESCRICAO: "APUAREMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2971",
    DESCRICAO: "ARACATU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2972",
    DESCRICAO: "ARACAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2973",
    DESCRICAO: "ARACI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2974",
    DESCRICAO: "ARAMARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2975",
    DESCRICAO: "ARATACA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2976",
    DESCRICAO: "ARATUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2977",
    DESCRICAO: "AURELINO LEAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2978",
    DESCRICAO: "BAIANOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2979",
    DESCRICAO: "BAIXA GRANDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2980",
    DESCRICAO: "BANZAE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2981",
    DESCRICAO: "BARRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2982",
    DESCRICAO: "BARRA DA ESTIVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2983",
    DESCRICAO: "BARRA DO CHOCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2984",
    DESCRICAO: "BARRA DO MENDES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2985",
    DESCRICAO: "BARRA DO ROCHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2986",
    DESCRICAO: "BARREIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2987",
    DESCRICAO: "BARRO ALTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2988",
    DESCRICAO: "BARROCAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2989",
    DESCRICAO: "BARRO PRETO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2990",
    DESCRICAO: "BELMONTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2991",
    DESCRICAO: "BELO CAMPO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2992",
    DESCRICAO: "BIRITINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2993",
    DESCRICAO: "BOA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2994",
    DESCRICAO: "BOA VISTA DO TUPIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2995",
    DESCRICAO: "BOM JESUS DA LAPA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2996",
    DESCRICAO: "BOM JESUS DA SERRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2997",
    DESCRICAO: "BONINAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2998",
    DESCRICAO: "BONITO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "2999",
    DESCRICAO: "BOQUIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3000",
    DESCRICAO: "BOTUPORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3001",
    DESCRICAO: "BREJOES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3002",
    DESCRICAO: "BREJOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3003",
    DESCRICAO: "BROTAS DE MACAUBAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3004",
    DESCRICAO: "BRUMADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3005",
    DESCRICAO: "BUERAREMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3006",
    DESCRICAO: "BURITIRAMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3007",
    DESCRICAO: "CAATIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3008",
    DESCRICAO: "CABACEIRAS DO PARAGUACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3009",
    DESCRICAO: "CACHOEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3010",
    DESCRICAO: "CACULE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3011",
    DESCRICAO: "CAEM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3012",
    DESCRICAO: "CAETANOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3013",
    DESCRICAO: "CAETITE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3014",
    DESCRICAO: "CAFARNAUM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3015",
    DESCRICAO: "CAIRU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3016",
    DESCRICAO: "CALDEIRAO GRANDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3017",
    DESCRICAO: "CAMACAN",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3018",
    DESCRICAO: "CAMACARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3019",
    DESCRICAO: "CAMAMU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3020",
    DESCRICAO: "CAMPO ALEGRE DE LOURDES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3021",
    DESCRICAO: "CAMPO FORMOSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3022",
    DESCRICAO: "CANAPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3023",
    DESCRICAO: "CANARANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3024",
    DESCRICAO: "CANAVIEIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3025",
    DESCRICAO: "CANDEAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3026",
    DESCRICAO: "CANDEIAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3027",
    DESCRICAO: "CANDIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3028",
    DESCRICAO: "CANDIDO SALES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3029",
    DESCRICAO: "CANSANCAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3030",
    DESCRICAO: "CANUDOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3031",
    DESCRICAO: "CAPELA DO ALTO ALEGRE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3032",
    DESCRICAO: "CAPIM GROSSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3033",
    DESCRICAO: "CARAIBAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3034",
    DESCRICAO: "CARAVELAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3035",
    DESCRICAO: "CARDEAL DA SILVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3036",
    DESCRICAO: "CARINHANHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3037",
    DESCRICAO: "CASA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3038",
    DESCRICAO: "CASTRO ALVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3039",
    DESCRICAO: "CATOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3040",
    DESCRICAO: "CATU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3041",
    DESCRICAO: "CATURAMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3042",
    DESCRICAO: "CENTRAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3043",
    DESCRICAO: "CHORROCHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3044",
    DESCRICAO: "CICERO DANTAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3045",
    DESCRICAO: "CIPO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3046",
    DESCRICAO: "COARACI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3047",
    DESCRICAO: "COCOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3048",
    DESCRICAO: "CONCEICAO DA FEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3049",
    DESCRICAO: "CONCEICAO DO ALMEIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3050",
    DESCRICAO: "CONCEICAO DO COITE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3051",
    DESCRICAO: "CONCEICAO DO JACUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3052",
    DESCRICAO: "CONDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3053",
    DESCRICAO: "CONDEUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3054",
    DESCRICAO: "CONTENDAS DO SINCORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3055",
    DESCRICAO: "CORACAO DE MARIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3056",
    DESCRICAO: "CORDEIROS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3057",
    DESCRICAO: "CORIBE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3058",
    DESCRICAO: "CORONEL JOAO SA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3059",
    DESCRICAO: "CORRENTINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3060",
    DESCRICAO: "COTEGIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3061",
    DESCRICAO: "CRAVOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3062",
    DESCRICAO: "CRISOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3063",
    DESCRICAO: "CRISTOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3064",
    DESCRICAO: "CRUZ DAS ALMAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3065",
    DESCRICAO: "CURACA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3066",
    DESCRICAO: "DARIO MEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3067",
    DESCRICAO: "DIAS D AVILA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3068",
    DESCRICAO: "DOM BASILIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3069",
    DESCRICAO: "DOM MACEDO COSTA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3070",
    DESCRICAO: "ELISIO MEDRADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3071",
    DESCRICAO: "ENCRUZILHADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3072",
    DESCRICAO: "ENTRE RIOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3073",
    DESCRICAO: "ESPLANADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3074",
    DESCRICAO: "EUCLIDES DA CUNHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3075",
    DESCRICAO: "EUNAPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3076",
    DESCRICAO: "FATIMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3077",
    DESCRICAO: "FEIRA DA MATA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3078",
    DESCRICAO: "FEIRA DE SANTANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3079",
    DESCRICAO: "FILADELFIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3080",
    DESCRICAO: "FIRMINO ALVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3081",
    DESCRICAO: "FLORESTA AZUL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3082",
    DESCRICAO: "FORMOSA DO RIO PRETO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3083",
    DESCRICAO: "GANDU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3084",
    DESCRICAO: "GAVIAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3085",
    DESCRICAO: "GENTIO DO OURO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3086",
    DESCRICAO: "GLORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3087",
    DESCRICAO: "GONGOGI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3088",
    DESCRICAO: "GOVERNADOR MANGABEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3089",
    DESCRICAO: "GUAJERU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3090",
    DESCRICAO: "GUANAMBI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3091",
    DESCRICAO: "GUARATINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3092",
    DESCRICAO: "HELIOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3093",
    DESCRICAO: "IACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3094",
    DESCRICAO: "IBIASSUCE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3095",
    DESCRICAO: "IBICARAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3096",
    DESCRICAO: "IBICOARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3097",
    DESCRICAO: "IBICUI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3098",
    DESCRICAO: "IBIPEBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3099",
    DESCRICAO: "IBIPITANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3100",
    DESCRICAO: "IBIQUERA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3101",
    DESCRICAO: "IBIRAPITANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3102",
    DESCRICAO: "IBIRAPUA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3103",
    DESCRICAO: "IBIRATAIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3104",
    DESCRICAO: "IBITIARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3105",
    DESCRICAO: "IBITITA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3106",
    DESCRICAO: "IBOTIRAMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3107",
    DESCRICAO: "ICHU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3108",
    DESCRICAO: "IGAPORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3109",
    DESCRICAO: "IGRAPIUNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3110",
    DESCRICAO: "IGUAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3111",
    DESCRICAO: "ILHEUS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3112",
    DESCRICAO: "INHAMBUPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3113",
    DESCRICAO: "IPECAETA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3114",
    DESCRICAO: "IPIAU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3115",
    DESCRICAO: "IPIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3116",
    DESCRICAO: "IPUPIARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3117",
    DESCRICAO: "IRAJUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3118",
    DESCRICAO: "IRAMAIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3119",
    DESCRICAO: "IRAQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3120",
    DESCRICAO: "IRARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3121",
    DESCRICAO: "IRECE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3122",
    DESCRICAO: "ITABELA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3123",
    DESCRICAO: "ITABERABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3124",
    DESCRICAO: "ITABUNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3125",
    DESCRICAO: "ITACARE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3126",
    DESCRICAO: "ITAETE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3127",
    DESCRICAO: "ITAGI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3128",
    DESCRICAO: "ITAGIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3129",
    DESCRICAO: "ITAGIMIRIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3130",
    DESCRICAO: "ITAGUACU DA BAHIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3131",
    DESCRICAO: "ITAJU DO COLONIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3132",
    DESCRICAO: "ITAJUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3133",
    DESCRICAO: "ITAMARAJU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3134",
    DESCRICAO: "ITAMARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3135",
    DESCRICAO: "ITAMBE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3136",
    DESCRICAO: "ITANAGRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3137",
    DESCRICAO: "ITANHEM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3138",
    DESCRICAO: "ITAPARICA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3139",
    DESCRICAO: "ITAPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3140",
    DESCRICAO: "ITAPEBI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3141",
    DESCRICAO: "ITAPETINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3142",
    DESCRICAO: "ITAPICURU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3143",
    DESCRICAO: "ITAPITANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3144",
    DESCRICAO: "ITAQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3145",
    DESCRICAO: "ITARANTIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3146",
    DESCRICAO: "ITATIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3147",
    DESCRICAO: "ITIRUCU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3148",
    DESCRICAO: "ITIUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3149",
    DESCRICAO: "ITORORO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3150",
    DESCRICAO: "ITUACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3151",
    DESCRICAO: "ITUBERA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3152",
    DESCRICAO: "IUIU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3153",
    DESCRICAO: "JABORANDI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3154",
    DESCRICAO: "JACARACI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3155",
    DESCRICAO: "JACOBINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3156",
    DESCRICAO: "JAGUAQUARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3157",
    DESCRICAO: "JAGUARARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3158",
    DESCRICAO: "JAGUARIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3159",
    DESCRICAO: "JANDAIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3160",
    DESCRICAO: "JEQUIE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3161",
    DESCRICAO: "JEREMOABO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3162",
    DESCRICAO: "JEQUIRICA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3163",
    DESCRICAO: "JITAUNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3164",
    DESCRICAO: "JOAO DOURADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3165",
    DESCRICAO: "JUAZEIRO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3166",
    DESCRICAO: "JUCURUCU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3167",
    DESCRICAO: "JUSSARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3168",
    DESCRICAO: "JUSSARI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3169",
    DESCRICAO: "JUSSIAPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3170",
    DESCRICAO: "LAFAIETE COUTINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3171",
    DESCRICAO: "LAGOA REAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3172",
    DESCRICAO: "LAJE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3173",
    DESCRICAO: "LAJEDAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3174",
    DESCRICAO: "LAJEDINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3175",
    DESCRICAO: "LAJEDO DO TABOCAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3176",
    DESCRICAO: "LAMARAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3177",
    DESCRICAO: "LAPAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3178",
    DESCRICAO: "LAURO DE FREITAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3179",
    DESCRICAO: "LENCOIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3180",
    DESCRICAO: "LICINIO DE ALMEIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3181",
    DESCRICAO: "LIVRAMENTO DE NOSSA SENHORA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3182",
    DESCRICAO: "LUIS EDUARDO MAGALHAES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3183",
    DESCRICAO: "MACAJUBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3184",
    DESCRICAO: "MACARANI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3185",
    DESCRICAO: "MACAUBAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3186",
    DESCRICAO: "MACURURE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3187",
    DESCRICAO: "MADRE DE DEUS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3188",
    DESCRICAO: "MAETINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3189",
    DESCRICAO: "MAIQUINIQUE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3190",
    DESCRICAO: "MAIRI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3191",
    DESCRICAO: "MALHADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3192",
    DESCRICAO: "MALHADA DE PEDRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3193",
    DESCRICAO: "MANOEL VITORINO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3194",
    DESCRICAO: "MANSIDAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3195",
    DESCRICAO: "MARACAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3196",
    DESCRICAO: "MARAGOGIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3197",
    DESCRICAO: "MARAU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3198",
    DESCRICAO: "MARCIONILIO SOUZA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3199",
    DESCRICAO: "MASCOTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3200",
    DESCRICAO: "MATA DE SAO JOAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3201",
    DESCRICAO: "MATINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3202",
    DESCRICAO: "MEDEIROS NETO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3203",
    DESCRICAO: "MIGUEL CALMON",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3204",
    DESCRICAO: "MILAGRES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3205",
    DESCRICAO: "MIRANGABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3206",
    DESCRICAO: "MIRANTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3207",
    DESCRICAO: "MONTE SANTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3208",
    DESCRICAO: "MORPARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3209",
    DESCRICAO: "MORRO DO CHAPEU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3210",
    DESCRICAO: "MORTUGABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3211",
    DESCRICAO: "MUCUGE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3212",
    DESCRICAO: "MUCURI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3213",
    DESCRICAO: "MULUNGU DO MORRO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3214",
    DESCRICAO: "MUNDO NOVO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3215",
    DESCRICAO: "MUNIZ FERREIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3216",
    DESCRICAO: "MUQUEM DE SAO FRANCISCO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3217",
    DESCRICAO: "MURITIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3218",
    DESCRICAO: "MUTUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3219",
    DESCRICAO: "NAZARE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3220",
    DESCRICAO: "NILO PECANHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3221",
    DESCRICAO: "NORDESTINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3222",
    DESCRICAO: "NOVA CANAA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3223",
    DESCRICAO: "NOVA FATIMA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3224",
    DESCRICAO: "NOVA IBIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3225",
    DESCRICAO: "NOVA ITARANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3226",
    DESCRICAO: "NOVA REDENCAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3227",
    DESCRICAO: "NOVA SOURE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3228",
    DESCRICAO: "NOVA VICOSA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3229",
    DESCRICAO: "NOVO HORIZONTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3230",
    DESCRICAO: "NOVO TRIUNFO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3231",
    DESCRICAO: "OLINDINA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3232",
    DESCRICAO: "OLIVEIRA DOS BREJINHOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3233",
    DESCRICAO: "OURICANGAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3234",
    DESCRICAO: "OUROLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3235",
    DESCRICAO: "PALMAS DE MONTE ALTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3236",
    DESCRICAO: "PALMEIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3237",
    DESCRICAO: "PARAMIRIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3238",
    DESCRICAO: "PARATINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3239",
    DESCRICAO: "PARIPIRANGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3240",
    DESCRICAO: "PAU BRASIL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3241",
    DESCRICAO: "PAULO AFONSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3242",
    DESCRICAO: "PE DE SERRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3243",
    DESCRICAO: "PEDRAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3244",
    DESCRICAO: "PEDRO ALEXANDRE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3245",
    DESCRICAO: "PIATA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3246",
    DESCRICAO: "PILAO ARCADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3247",
    DESCRICAO: "PINDAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3248",
    DESCRICAO: "PINDOBACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3249",
    DESCRICAO: "PINTADAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3250",
    DESCRICAO: "PIRAI DO NORTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3251",
    DESCRICAO: "PIRIPA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3252",
    DESCRICAO: "PIRITIBA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3253",
    DESCRICAO: "PLANALTINO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3254",
    DESCRICAO: "PLANALTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3255",
    DESCRICAO: "POCOES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3256",
    DESCRICAO: "POJUCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3257",
    DESCRICAO: "PONTO NOVO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3258",
    DESCRICAO: "PORTO SEGURO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3259",
    DESCRICAO: "POTIRAGUA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3260",
    DESCRICAO: "PRADO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3261",
    DESCRICAO: "PRESIDENTE DUTRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3262",
    DESCRICAO: "PRESIDENTE JANIO QUADROS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3263",
    DESCRICAO: "PRESIDENTE TANCREDO NEVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3264",
    DESCRICAO: "QUEIMADAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3265",
    DESCRICAO: "QUIJINGUE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3266",
    DESCRICAO: "QUIXABEIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3267",
    DESCRICAO: "RAFAEL JAMBEIRO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3268",
    DESCRICAO: "REMANSO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3269",
    DESCRICAO: "RETIROLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3270",
    DESCRICAO: "RIACHAO DAS NEVES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3271",
    DESCRICAO: "RIACHAO DO JACUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3272",
    DESCRICAO: "RIACHO DE SANTANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3273",
    DESCRICAO: "RIBEIRA DO AMPARO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3274",
    DESCRICAO: "RIBEIRA DO POMBAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3275",
    DESCRICAO: "RIBEIRAO DO LARGO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3276",
    DESCRICAO: "RIO DE CONTAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3277",
    DESCRICAO: "RIO DO ANTONIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3278",
    DESCRICAO: "RIO DO PIRES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3279",
    DESCRICAO: "RIO REAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3280",
    DESCRICAO: "RODELAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3281",
    DESCRICAO: "RUY BARBOSA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3282",
    DESCRICAO: "SALINAS DA MARGARIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3283",
    DESCRICAO: "SALVADOR",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3284",
    DESCRICAO: "SANTA BARBARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3285",
    DESCRICAO: "SANTA BRIGIDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3286",
    DESCRICAO: "SANTA CRUZ CABRALIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3287",
    DESCRICAO: "SANTA CRUZ DA VITORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3288",
    DESCRICAO: "SANTA INES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3289",
    DESCRICAO: "SANTALUZ",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3290",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3291",
    DESCRICAO: "SANTA MARIA DA VITORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3292",
    DESCRICAO: "SANTANA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3293",
    DESCRICAO: "SANTANOPOLIS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3294",
    DESCRICAO: "SANTA RITA DE CASSIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3295",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3296",
    DESCRICAO: "SANTO AMARO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3297",
    DESCRICAO: "SANTO ANTONIO DE JESUS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3298",
    DESCRICAO: "SANTO ESTEVAO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3299",
    DESCRICAO: "SAO DESIDERIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3300",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3301",
    DESCRICAO: "SAO FELIX",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3302",
    DESCRICAO: "SAO FELIX DO CORIBE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3303",
    DESCRICAO: "SAO FELIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3304",
    DESCRICAO: "SAO FRANCISCO DO CONDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3305",
    DESCRICAO: "SAO GABRIEL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3306",
    DESCRICAO: "SAO GONCALO DOS CAMPOS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3307",
    DESCRICAO: "SAO JOSE DA VITORIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3308",
    DESCRICAO: "SAO JOSE DO JACUIPE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3309",
    DESCRICAO: "SAO MIGUEL DAS MATAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3310",
    DESCRICAO: "SAO SEBASTIAO DO PASSE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3311",
    DESCRICAO: "SAPEACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3312",
    DESCRICAO: "SATIRO DIAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3313",
    DESCRICAO: "SAUBARA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3314",
    DESCRICAO: "SAUDE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3315",
    DESCRICAO: "SEABRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3316",
    DESCRICAO: "SEBASTIAO LARANJEIRAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3317",
    DESCRICAO: "SENHOR DO BONFIM",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3318",
    DESCRICAO: "SERRA DO RAMALHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3319",
    DESCRICAO: "SENTO SE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3320",
    DESCRICAO: "SERRA DOURADA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3321",
    DESCRICAO: "SERRA PRETA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3322",
    DESCRICAO: "SERRINHA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3323",
    DESCRICAO: "SERROLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3324",
    DESCRICAO: "SIMOES FILHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3325",
    DESCRICAO: "SITIO DO MATO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3326",
    DESCRICAO: "SITIO DO QUINTO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3327",
    DESCRICAO: "SOBRADINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3328",
    DESCRICAO: "SOUTO SOARES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3329",
    DESCRICAO: "TABOCAS DO BREJO VELHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3330",
    DESCRICAO: "TANHACU",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3331",
    DESCRICAO: "TANQUE NOVO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3332",
    DESCRICAO: "TANQUINHO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3333",
    DESCRICAO: "TAPEROA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3334",
    DESCRICAO: "TAPIRAMUTA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3335",
    DESCRICAO: "TEIXEIRA DE FREITAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3336",
    DESCRICAO: "TEODORO SAMPAIO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3337",
    DESCRICAO: "TEOFILANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3338",
    DESCRICAO: "TEOLANDIA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3339",
    DESCRICAO: "TERRA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3340",
    DESCRICAO: "TREMEDAL",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3341",
    DESCRICAO: "TUCANO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3342",
    DESCRICAO: "UAUA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3343",
    DESCRICAO: "UBAIRA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3344",
    DESCRICAO: "UBAITABA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3345",
    DESCRICAO: "UBATA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3346",
    DESCRICAO: "UIBAI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3347",
    DESCRICAO: "UMBURANAS",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3348",
    DESCRICAO: "UNA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3349",
    DESCRICAO: "URANDI",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3350",
    DESCRICAO: "URUCUCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3351",
    DESCRICAO: "UTINGA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3352",
    DESCRICAO: "VALENCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3353",
    DESCRICAO: "VALENTE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3354",
    DESCRICAO: "VARZEA DA ROCA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3355",
    DESCRICAO: "VARZEA DO POCO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3356",
    DESCRICAO: "VARZEA NOVA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3357",
    DESCRICAO: "VARZEDO",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3358",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3359",
    DESCRICAO: "VEREDA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3360",
    DESCRICAO: "VITORIA DA CONQUISTA",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3361",
    DESCRICAO: "WAGNER",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3362",
    DESCRICAO: "WANDERLEY",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3363",
    DESCRICAO: "WENCESLAU GUIMARAES",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3364",
    DESCRICAO: "XIQUE-XIQUE",
    SIGLA: "BA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3365",
    DESCRICAO: "AFONSO CLAUDIO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3366",
    DESCRICAO: "AGUIA BRANCA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3367",
    DESCRICAO: "AGUA DOCE DO NORTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3368",
    DESCRICAO: "ALEGRE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3369",
    DESCRICAO: "ALFREDO CHAVES",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3370",
    DESCRICAO: "ALTO RIO NOVO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3371",
    DESCRICAO: "ANCHIETA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3372",
    DESCRICAO: "APIACA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3373",
    DESCRICAO: "ARACRUZ",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3374",
    DESCRICAO: "ATILIO VIVACQUA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3375",
    DESCRICAO: "BAIXO GUANDU",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3376",
    DESCRICAO: "BARRA DE SAO FRANCISCO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3377",
    DESCRICAO: "BOA ESPERANCA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3378",
    DESCRICAO: "BOM JESUS DO NORTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3379",
    DESCRICAO: "BREJETUBA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3380",
    DESCRICAO: "CACHOEIRO DE ITAPEMIRIM",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3381",
    DESCRICAO: "CARIACICA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3382",
    DESCRICAO: "CASTELO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3383",
    DESCRICAO: "COLATINA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3384",
    DESCRICAO: "CONCEICAO DA BARRA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3385",
    DESCRICAO: "CONCEICAO DO CASTELO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3386",
    DESCRICAO: "DIVINO DE SAO LOURENCO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3387",
    DESCRICAO: "DOMINGOS MARTINS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3388",
    DESCRICAO: "DORES DO RIO PRETO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3389",
    DESCRICAO: "ECOPORANGA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3390",
    DESCRICAO: "FUNDAO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3391",
    DESCRICAO: "GOVERNADOR LINDENBERG",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3392",
    DESCRICAO: "GUACUI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3393",
    DESCRICAO: "GUARAPARI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3394",
    DESCRICAO: "IBATIBA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3395",
    DESCRICAO: "IBIRACU",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3396",
    DESCRICAO: "IBITIRAMA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3397",
    DESCRICAO: "ICONHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3398",
    DESCRICAO: "IRUPI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3399",
    DESCRICAO: "ITAGUACU",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3400",
    DESCRICAO: "ITAPEMIRIM",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3401",
    DESCRICAO: "ITARANA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3402",
    DESCRICAO: "IUNA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3403",
    DESCRICAO: "JAGUARE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3404",
    DESCRICAO: "JERONIMO MONTEIRO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3405",
    DESCRICAO: "JOAO NEIVA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3406",
    DESCRICAO: "LARANJA DA TERRA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3407",
    DESCRICAO: "LINHARES",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3408",
    DESCRICAO: "MANTENOPOLIS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3409",
    DESCRICAO: "MARATAIZES",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3410",
    DESCRICAO: "MARECHAL FLORIANO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3411",
    DESCRICAO: "MARILANDIA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3412",
    DESCRICAO: "MIMOSO DO SUL",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3413",
    DESCRICAO: "MONTANHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3414",
    DESCRICAO: "MUCURICI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3415",
    DESCRICAO: "MUNIZ FREIRE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3416",
    DESCRICAO: "MUQUI",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3417",
    DESCRICAO: "NOVA VENECIA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3418",
    DESCRICAO: "PANCAS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3419",
    DESCRICAO: "PEDRO CANARIO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3420",
    DESCRICAO: "PINHEIROS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3421",
    DESCRICAO: "PIUMA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3422",
    DESCRICAO: "PONTO BELO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3423",
    DESCRICAO: "PRESIDENTE KENNEDY",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3424",
    DESCRICAO: "RIO BANANAL",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3425",
    DESCRICAO: "RIO NOVO DO SUL",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3426",
    DESCRICAO: "SANTA LEOPOLDINA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3427",
    DESCRICAO: "SANTA MARIA DE JETIBA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3428",
    DESCRICAO: "SANTA TERESA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3429",
    DESCRICAO: "SAO DOMINGOS DO NORTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3430",
    DESCRICAO: "SAO GABRIEL DA PALHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3431",
    DESCRICAO: "SAO JOSE DO CALCADO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3432",
    DESCRICAO: "SAO MATEUS",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3433",
    DESCRICAO: "SAO ROQUE DO CANAA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3434",
    DESCRICAO: "SERRA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3435",
    DESCRICAO: "SOORETAMA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3436",
    DESCRICAO: "VARGEM ALTA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3437",
    DESCRICAO: "VENDA NOVA DO IMIGRANTE",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3438",
    DESCRICAO: "VIANA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3439",
    DESCRICAO: "VILA PAVAO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3440",
    DESCRICAO: "VILA VALERIO",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3441",
    DESCRICAO: "VILA VELHA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3442",
    DESCRICAO: "VITORIA",
    SIGLA: "ES",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3443",
    DESCRICAO: "AGUA BRANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3444",
    DESCRICAO: "AGUIAR",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3445",
    DESCRICAO: "ALAGOA GRANDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3446",
    DESCRICAO: "ALAGOA NOVA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3447",
    DESCRICAO: "ALAGOINHA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3448",
    DESCRICAO: "ALCANTIL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3449",
    DESCRICAO: "ALGODAO DE JANDAIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3450",
    DESCRICAO: "ALHANDRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3451",
    DESCRICAO: "SAO JOAO DO RIO DO PEIXE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3452",
    DESCRICAO: "AMPARO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3453",
    DESCRICAO: "APARECIDA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3454",
    DESCRICAO: "ARACAGI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3455",
    DESCRICAO: "ARARA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3456",
    DESCRICAO: "ARARUNA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3457",
    DESCRICAO: "AREIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3458",
    DESCRICAO: "AREIA DE BARAUNAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3459",
    DESCRICAO: "AREIAL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3460",
    DESCRICAO: "AROEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3461",
    DESCRICAO: "ASSUNCAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3462",
    DESCRICAO: "BAIA DA TRAICAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3463",
    DESCRICAO: "BANANEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3464",
    DESCRICAO: "BARAUNA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3465",
    DESCRICAO: "BARRA DE SANTANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3466",
    DESCRICAO: "BARRA DE SANTA ROSA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3467",
    DESCRICAO: "BARRA DE SAO MIGUEL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3468",
    DESCRICAO: "BAYEUX",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3469",
    DESCRICAO: "BELEM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3470",
    DESCRICAO: "BELEM DO BREJO DO CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3471",
    DESCRICAO: "BERNARDINO BATISTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3472",
    DESCRICAO: "BOA VENTURA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3473",
    DESCRICAO: "BOA VISTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3474",
    DESCRICAO: "BOM JESUS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3475",
    DESCRICAO: "BOM SUCESSO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3476",
    DESCRICAO: "BONITO DE SANTA FE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3477",
    DESCRICAO: "BOQUEIRAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3478",
    DESCRICAO: "IGARACY",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3479",
    DESCRICAO: "BORBOREMA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3480",
    DESCRICAO: "BREJO DO CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3481",
    DESCRICAO: "BREJO DOS SANTOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3482",
    DESCRICAO: "CAAPORA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3483",
    DESCRICAO: "CABACEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3484",
    DESCRICAO: "CABEDELO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3485",
    DESCRICAO: "CACHOEIRA DOS INDIOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3486",
    DESCRICAO: "CACIMBA DE AREIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3487",
    DESCRICAO: "CACIMBA DE DENTRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3488",
    DESCRICAO: "CACIMBAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3489",
    DESCRICAO: "CAICARA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3490",
    DESCRICAO: "CAJAZEIRAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3491",
    DESCRICAO: "CAJAZEIRINHAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3492",
    DESCRICAO: "CALDAS BRANDAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3493",
    DESCRICAO: "CAMALAU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3494",
    DESCRICAO: "CAMPINA GRANDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3495",
    DESCRICAO: "CAPIM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3496",
    DESCRICAO: "CARAUBAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3497",
    DESCRICAO: "CARRAPATEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3498",
    DESCRICAO: "CASSERENGUE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3499",
    DESCRICAO: "CATINGUEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3500",
    DESCRICAO: "CATOLE DO ROCHA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3501",
    DESCRICAO: "CATURITE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3502",
    DESCRICAO: "CONCEICAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3503",
    DESCRICAO: "CONDADO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3504",
    DESCRICAO: "CONDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3505",
    DESCRICAO: "CONGO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3506",
    DESCRICAO: "COREMAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3507",
    DESCRICAO: "COXIXOLA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3508",
    DESCRICAO: "CRUZ DO ESPIRITO SANTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3509",
    DESCRICAO: "CUBATI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3510",
    DESCRICAO: "CUITE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3511",
    DESCRICAO: "CUITEGI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3512",
    DESCRICAO: "CUITE DE MAMANGUAPE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3513",
    DESCRICAO: "CURRAL DE CIMA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3514",
    DESCRICAO: "CURRAL VELHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3515",
    DESCRICAO: "DAMIAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3516",
    DESCRICAO: "DESTERRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3517",
    DESCRICAO: "VISTA SERRANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3518",
    DESCRICAO: "DIAMANTE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3519",
    DESCRICAO: "DONA INES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3520",
    DESCRICAO: "DUAS ESTRADAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3521",
    DESCRICAO: "EMAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3522",
    DESCRICAO: "ESPERANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3523",
    DESCRICAO: "FAGUNDES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3524",
    DESCRICAO: "FREI MARTINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3525",
    DESCRICAO: "GADO BRAVO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3526",
    DESCRICAO: "GUARABIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3527",
    DESCRICAO: "GURINHEM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3528",
    DESCRICAO: "GURJAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3529",
    DESCRICAO: "IBIARA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3530",
    DESCRICAO: "IMACULADA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3531",
    DESCRICAO: "INGA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3532",
    DESCRICAO: "ITABAIANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3533",
    DESCRICAO: "ITAPORANGA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3534",
    DESCRICAO: "ITAPOROROCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3535",
    DESCRICAO: "ITATUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3536",
    DESCRICAO: "JACARAU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3537",
    DESCRICAO: "JERICO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3538",
    DESCRICAO: "JOAO PESSOA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3539",
    DESCRICAO: "JUAREZ TAVORA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3540",
    DESCRICAO: "JUAZEIRINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3541",
    DESCRICAO: "JUNCO DO SERIDO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3542",
    DESCRICAO: "JURIPIRANGA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3543",
    DESCRICAO: "JURU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3544",
    DESCRICAO: "LAGOA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3545",
    DESCRICAO: "LAGOA DE DENTRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3546",
    DESCRICAO: "LAGOA SECA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3547",
    DESCRICAO: "LASTRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3548",
    DESCRICAO: "LIVRAMENTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3549",
    DESCRICAO: "LOGRADOURO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3550",
    DESCRICAO: "LUCENA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3551",
    DESCRICAO: "MAE D AGUA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3552",
    DESCRICAO: "MALTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3553",
    DESCRICAO: "MAMANGUAPE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3554",
    DESCRICAO: "MANAIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3555",
    DESCRICAO: "MARCACAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3556",
    DESCRICAO: "MARI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3557",
    DESCRICAO: "MARIZOPOLIS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3558",
    DESCRICAO: "MASSARANDUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3559",
    DESCRICAO: "MATARACA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3560",
    DESCRICAO: "MATINHAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3561",
    DESCRICAO: "MATO GROSSO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3562",
    DESCRICAO: "MATUREIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3563",
    DESCRICAO: "MOGEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3564",
    DESCRICAO: "MONTADAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3565",
    DESCRICAO: "MONTE HOREBE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3566",
    DESCRICAO: "MONTEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3567",
    DESCRICAO: "MULUNGU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3568",
    DESCRICAO: "NATUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3569",
    DESCRICAO: "NAZAREZINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3570",
    DESCRICAO: "NOVA FLORESTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3571",
    DESCRICAO: "NOVA OLINDA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3572",
    DESCRICAO: "NOVA PALMEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3573",
    DESCRICAO: "OLHO D AGUA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3574",
    DESCRICAO: "OLIVEDOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3575",
    DESCRICAO: "OURO VELHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3576",
    DESCRICAO: "PARARI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3577",
    DESCRICAO: "PASSAGEM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3578",
    DESCRICAO: "PATOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3579",
    DESCRICAO: "PAULISTA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3580",
    DESCRICAO: "PEDRA BRANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3581",
    DESCRICAO: "PEDRA LAVRADA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3582",
    DESCRICAO: "PEDRAS DE FOGO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3583",
    DESCRICAO: "PIANCO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3584",
    DESCRICAO: "PICUI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3585",
    DESCRICAO: "PILAR",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3586",
    DESCRICAO: "PILOES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3587",
    DESCRICAO: "PILOEZINHOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3588",
    DESCRICAO: "PIRPIRITUBA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3589",
    DESCRICAO: "PITIMBU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3590",
    DESCRICAO: "POCINHOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3591",
    DESCRICAO: "POCO DANTAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3592",
    DESCRICAO: "POCO DE JOSE DE MOURA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3593",
    DESCRICAO: "POMBAL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3594",
    DESCRICAO: "PRATA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3595",
    DESCRICAO: "PRINCESA ISABEL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3596",
    DESCRICAO: "PUXINANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3597",
    DESCRICAO: "QUEIMADAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3598",
    DESCRICAO: "QUIXABA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3599",
    DESCRICAO: "REMIGIO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3600",
    DESCRICAO: "PEDRO REGIS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3601",
    DESCRICAO: "RIACHAO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3602",
    DESCRICAO: "RIACHAO DO BACAMARTE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3603",
    DESCRICAO: "RIACHAO DO POCO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3604",
    DESCRICAO: "RIACHO DE SANTO ANTONIO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3605",
    DESCRICAO: "RIACHO DOS CAVALOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3606",
    DESCRICAO: "RIO TINTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3607",
    DESCRICAO: "SALGADINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3608",
    DESCRICAO: "SALGADO DE SAO FELIX",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3609",
    DESCRICAO: "SANTA CECILIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3610",
    DESCRICAO: "SANTA CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3611",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3612",
    DESCRICAO: "SANTA INES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3613",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3614",
    DESCRICAO: "SANTANA DE MANGUEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3615",
    DESCRICAO: "SANTANA DOS GARROTES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3617",
    DESCRICAO: "SANTA RITA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3618",
    DESCRICAO: "SANTA TERESINHA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3619",
    DESCRICAO: "SANTO ANDRE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3620",
    DESCRICAO: "SAO BENTO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3621",
    DESCRICAO: "SAO BENTINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3622",
    DESCRICAO: "SAO DOMINGOS DO CARIRI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3623",
    DESCRICAO: "SAO DOMINGOS DE POMBAL",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3624",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3625",
    DESCRICAO: "SAO JOAO DO CARIRI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3626",
    DESCRICAO: "SAO JOAO DO TIGRE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3627",
    DESCRICAO: "SAO JOSE DA LAGOA TAPADA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3628",
    DESCRICAO: "SAO JOSE DE CAIANA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3629",
    DESCRICAO: "SAO JOSE DE ESPINHARAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3630",
    DESCRICAO: "SAO JOSE DOS RAMOS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3631",
    DESCRICAO: "SAO JOSE DE PIRANHAS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3632",
    DESCRICAO: "SAO JOSE DE PRINCESA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3633",
    DESCRICAO: "SAO JOSE DO BONFIM",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3634",
    DESCRICAO: "SAO JOSE DO BREJO DO CRUZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3635",
    DESCRICAO: "SAO JOSE DO SABUGI",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3636",
    DESCRICAO: "SAO JOSE DOS CORDEIROS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3637",
    DESCRICAO: "SAO MAMEDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3638",
    DESCRICAO: "SAO MIGUEL DE TAIPU",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3639",
    DESCRICAO: "SAO SEBASTIAO DE LAGOA DE ROCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3640",
    DESCRICAO: "SAO SEBASTIAO DO UMBUZEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3641",
    DESCRICAO: "SAPE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3643",
    DESCRICAO: "SERRA BRANCA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3644",
    DESCRICAO: "SERRA DA RAIZ",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3645",
    DESCRICAO: "SERRA GRANDE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3646",
    DESCRICAO: "SERRA REDONDA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3647",
    DESCRICAO: "SERRARIA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3648",
    DESCRICAO: "SERTAOZINHO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3649",
    DESCRICAO: "SOBRADO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3650",
    DESCRICAO: "SOLANEA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3651",
    DESCRICAO: "SOLEDADE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3652",
    DESCRICAO: "SOSSEGO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3653",
    DESCRICAO: "SOUSA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3654",
    DESCRICAO: "SUME",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3656",
    DESCRICAO: "TAPEROA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3657",
    DESCRICAO: "TAVARES",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3658",
    DESCRICAO: "TEIXEIRA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3659",
    DESCRICAO: "TENORIO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3660",
    DESCRICAO: "TRIUNFO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3661",
    DESCRICAO: "UIRAUNA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3662",
    DESCRICAO: "UMBUZEIRO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3663",
    DESCRICAO: "VARZEA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3664",
    DESCRICAO: "VIEIROPOLIS",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3665",
    DESCRICAO: "ZABELE",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3666",
    DESCRICAO: "ACAUA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3667",
    DESCRICAO: "AGRICOLANDIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3668",
    DESCRICAO: "AGUA BRANCA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3669",
    DESCRICAO: "ALAGOINHA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3670",
    DESCRICAO: "ALEGRETE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3671",
    DESCRICAO: "ALTO LONGA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3672",
    DESCRICAO: "ALTOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3673",
    DESCRICAO: "ALVORADA DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3674",
    DESCRICAO: "AMARANTE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3675",
    DESCRICAO: "ANGICAL DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3676",
    DESCRICAO: "ANISIO DE ABREU",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3677",
    DESCRICAO: "ANTONIO ALMEIDA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3678",
    DESCRICAO: "AROAZES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3679",
    DESCRICAO: "AROEIRAS DO ITAIM",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3680",
    DESCRICAO: "ARRAIAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3681",
    DESCRICAO: "ASSUNCAO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3682",
    DESCRICAO: "AVELINO LOPES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3683",
    DESCRICAO: "BAIXA GRANDE DO RIBEIRO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3684",
    DESCRICAO: "BARRA D ALCANTARA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3685",
    DESCRICAO: "BARRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3686",
    DESCRICAO: "BARREIRAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3687",
    DESCRICAO: "BARRO DURO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3688",
    DESCRICAO: "BATALHA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3689",
    DESCRICAO: "BELA VISTA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3690",
    DESCRICAO: "BELEM DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3691",
    DESCRICAO: "BENEDITINOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3692",
    DESCRICAO: "BERTOLINIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3693",
    DESCRICAO: "BETANIA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3694",
    DESCRICAO: "BOA HORA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3695",
    DESCRICAO: "BOCAINA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3696",
    DESCRICAO: "BOM JESUS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3697",
    DESCRICAO: "BOM PRINCIPIO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3698",
    DESCRICAO: "BONFIM DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3699",
    DESCRICAO: "BOQUEIRAO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3700",
    DESCRICAO: "BRASILEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3701",
    DESCRICAO: "BREJO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3702",
    DESCRICAO: "BURITI DOS LOPES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3703",
    DESCRICAO: "BURITI DOS MONTES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3704",
    DESCRICAO: "CABECEIRAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3705",
    DESCRICAO: "CAJAZEIRAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3706",
    DESCRICAO: "CAJUEIRO DA PRAIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3707",
    DESCRICAO: "CALDEIRAO GRANDE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3708",
    DESCRICAO: "CAMPINAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3709",
    DESCRICAO: "CAMPO ALEGRE DO FIDALGO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3710",
    DESCRICAO: "CAMPO GRANDE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3711",
    DESCRICAO: "CAMPO LARGO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3712",
    DESCRICAO: "CAMPO MAIOR",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3713",
    DESCRICAO: "CANAVIEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3714",
    DESCRICAO: "CANTO DO BURITI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3715",
    DESCRICAO: "CAPITAO DE CAMPOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3716",
    DESCRICAO: "CAPITAO GERVASIO OLIVEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3717",
    DESCRICAO: "CARACOL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3718",
    DESCRICAO: "CARAUBAS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3719",
    DESCRICAO: "CARIDADE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3720",
    DESCRICAO: "CASTELO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3721",
    DESCRICAO: "CAXINGO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3722",
    DESCRICAO: "COCAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3723",
    DESCRICAO: "COCAL DE TELHA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3724",
    DESCRICAO: "COCAL DOS ALVES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3725",
    DESCRICAO: "COIVARAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3726",
    DESCRICAO: "COLONIA DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3727",
    DESCRICAO: "COLONIA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3728",
    DESCRICAO: "CONCEICAO DO CANINDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3729",
    DESCRICAO: "CORONEL JOSE DIAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3730",
    DESCRICAO: "CORRENTE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3731",
    DESCRICAO: "CRISTALANDIA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3732",
    DESCRICAO: "CRISTINO CASTRO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3733",
    DESCRICAO: "CURIMATA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3734",
    DESCRICAO: "CURRAIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3735",
    DESCRICAO: "CURRALINHOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3736",
    DESCRICAO: "CURRAL NOVO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3737",
    DESCRICAO: "DEMERVAL LOBAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3738",
    DESCRICAO: "DIRCEU ARCOVERDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3739",
    DESCRICAO: "DOM EXPEDITO LOPES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3740",
    DESCRICAO: "DOMINGOS MOURAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3741",
    DESCRICAO: "DOM INOCENCIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3742",
    DESCRICAO: "ELESBAO VELOSO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3743",
    DESCRICAO: "ELISEU MARTINS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3744",
    DESCRICAO: "ESPERANTINA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3745",
    DESCRICAO: "FARTURA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3746",
    DESCRICAO: "FLORES DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3747",
    DESCRICAO: "FLORESTA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3748",
    DESCRICAO: "FLORIANO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3749",
    DESCRICAO: "FRANCINOPOLIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3750",
    DESCRICAO: "FRANCISCO AYRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3751",
    DESCRICAO: "FRANCISCO MACEDO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3752",
    DESCRICAO: "FRANCISCO SANTOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3753",
    DESCRICAO: "FRONTEIRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3754",
    DESCRICAO: "GEMINIANO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3755",
    DESCRICAO: "GILBUES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3756",
    DESCRICAO: "GUADALUPE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3757",
    DESCRICAO: "GUARIBAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3758",
    DESCRICAO: "HUGO NAPOLEAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3759",
    DESCRICAO: "ILHA GRANDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3760",
    DESCRICAO: "INHUMA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3761",
    DESCRICAO: "IPIRANGA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3762",
    DESCRICAO: "ISAIAS COELHO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3763",
    DESCRICAO: "ITAINOPOLIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3764",
    DESCRICAO: "ITAUEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3765",
    DESCRICAO: "JACOBINA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3766",
    DESCRICAO: "JAICOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3767",
    DESCRICAO: "JARDIM DO MULATO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3768",
    DESCRICAO: "JATOBA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3769",
    DESCRICAO: "JERUMENHA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3770",
    DESCRICAO: "JOAO COSTA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3771",
    DESCRICAO: "JOAQUIM PIRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3772",
    DESCRICAO: "JOCA MARQUES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3773",
    DESCRICAO: "JOSE DE FREITAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3774",
    DESCRICAO: "JUAZEIRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3775",
    DESCRICAO: "JULIO BORGES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3776",
    DESCRICAO: "JUREMA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3777",
    DESCRICAO: "LAGOINHA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3778",
    DESCRICAO: "LAGOA ALEGRE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3779",
    DESCRICAO: "LAGOA DO BARRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3780",
    DESCRICAO: "LAGOA DE SAO FRANCISCO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3781",
    DESCRICAO: "LAGOA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3782",
    DESCRICAO: "LAGOA DO SITIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3783",
    DESCRICAO: "LANDRI SALES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3784",
    DESCRICAO: "LUIS CORREIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3785",
    DESCRICAO: "LUZILANDIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3786",
    DESCRICAO: "MADEIRO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3787",
    DESCRICAO: "MANOEL EMIDIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3788",
    DESCRICAO: "MARCOLANDIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3789",
    DESCRICAO: "MARCOS PARENTE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3790",
    DESCRICAO: "MASSAPE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3791",
    DESCRICAO: "MATIAS OLIMPIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3792",
    DESCRICAO: "MIGUEL ALVES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3793",
    DESCRICAO: "MIGUEL LEAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3794",
    DESCRICAO: "MILTON BRANDAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3795",
    DESCRICAO: "MONSENHOR GIL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3796",
    DESCRICAO: "MONSENHOR HIPOLITO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3797",
    DESCRICAO: "MONTE ALEGRE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3798",
    DESCRICAO: "MORRO CABECA NO TEMPO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3799",
    DESCRICAO: "MORRO DO CHAPEU DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3800",
    DESCRICAO: "MURICI DOS PORTELAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3801",
    DESCRICAO: "NAZARE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3802",
    DESCRICAO: "NOSSA SENHORA DE NAZARE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3803",
    DESCRICAO: "NOSSA SENHORA DOS REMEDIOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3804",
    DESCRICAO: "NOVO ORIENTE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3805",
    DESCRICAO: "NOVO SANTO ANTONIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3806",
    DESCRICAO: "OEIRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3807",
    DESCRICAO: "OLHO D AGUA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3808",
    DESCRICAO: "PADRE MARCOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3809",
    DESCRICAO: "PAES LANDIM",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3810",
    DESCRICAO: "PAJEU DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3811",
    DESCRICAO: "PALMEIRA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3812",
    DESCRICAO: "PALMEIRAIS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3813",
    DESCRICAO: "PAQUETA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3814",
    DESCRICAO: "PARNAGUA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3815",
    DESCRICAO: "PARNAIBA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3816",
    DESCRICAO: "PASSAGEM FRANCA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3817",
    DESCRICAO: "PATOS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3818",
    DESCRICAO: "PAU D ARCO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3819",
    DESCRICAO: "PAULISTANA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3820",
    DESCRICAO: "PAVUSSU",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3821",
    DESCRICAO: "PEDRO II",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3822",
    DESCRICAO: "PEDRO LAURENTINO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3823",
    DESCRICAO: "NOVA SANTA RITA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3824",
    DESCRICAO: "PICOS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3825",
    DESCRICAO: "PIMENTEIRAS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3826",
    DESCRICAO: "PIO IX",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3827",
    DESCRICAO: "PIRACURUCA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3828",
    DESCRICAO: "PIRIPIRI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3829",
    DESCRICAO: "PORTO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3830",
    DESCRICAO: "PORTO ALEGRE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3831",
    DESCRICAO: "PRATA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3832",
    DESCRICAO: "QUEIMADA NOVA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3833",
    DESCRICAO: "REDENCAO DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3834",
    DESCRICAO: "REGENERACAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3835",
    DESCRICAO: "RIACHO FRIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3836",
    DESCRICAO: "RIBEIRA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3837",
    DESCRICAO: "RIBEIRO GONCALVES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3838",
    DESCRICAO: "RIO GRANDE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3839",
    DESCRICAO: "SANTA CRUZ DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3840",
    DESCRICAO: "SANTA CRUZ DOS MILAGRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3841",
    DESCRICAO: "SANTA FILOMENA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3842",
    DESCRICAO: "SANTA LUZ",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3843",
    DESCRICAO: "SANTANA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3844",
    DESCRICAO: "SANTA ROSA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3845",
    DESCRICAO: "SANTO ANTONIO DE LISBOA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3846",
    DESCRICAO: "SANTO ANTONIO DOS MILAGRES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3847",
    DESCRICAO: "SANTO INACIO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3848",
    DESCRICAO: "SAO BRAZ DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3849",
    DESCRICAO: "SAO FELIX DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3850",
    DESCRICAO: "SAO FRANCISCO DE ASSIS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3851",
    DESCRICAO: "SAO FRANCISCO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3852",
    DESCRICAO: "SAO GONCALO DO GURGUEIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3853",
    DESCRICAO: "SAO GONCALO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3854",
    DESCRICAO: "SAO JOAO DA CANABRAVA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3855",
    DESCRICAO: "SAO JOAO DA FRONTEIRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3856",
    DESCRICAO: "SAO JOAO DA SERRA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3857",
    DESCRICAO: "SAO JOAO DA VARJOTA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3858",
    DESCRICAO: "SAO JOAO DO ARRAIAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3859",
    DESCRICAO: "SAO JOAO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3860",
    DESCRICAO: "SAO JOSE DO DIVINO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3861",
    DESCRICAO: "SAO JOSE DO PEIXE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3862",
    DESCRICAO: "SAO JOSE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3863",
    DESCRICAO: "SAO JULIAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3864",
    DESCRICAO: "SAO LOURENCO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3865",
    DESCRICAO: "SAO LUIS DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3866",
    DESCRICAO: "SAO MIGUEL DA BAIXA GRANDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3867",
    DESCRICAO: "SAO MIGUEL DO FIDALGO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3868",
    DESCRICAO: "SAO MIGUEL DO TAPUIO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3869",
    DESCRICAO: "SAO PEDRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3870",
    DESCRICAO: "SAO RAIMUNDO NONATO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3871",
    DESCRICAO: "SEBASTIAO BARROS",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3872",
    DESCRICAO: "SEBASTIAO LEAL",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3873",
    DESCRICAO: "SIGEFREDO PACHECO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3874",
    DESCRICAO: "SIMOES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3875",
    DESCRICAO: "SIMPLICIO MENDES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3876",
    DESCRICAO: "SOCORRO DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3877",
    DESCRICAO: "SUSSUAPARA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3878",
    DESCRICAO: "TAMBORIL DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3879",
    DESCRICAO: "TANQUE DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3880",
    DESCRICAO: "TERESINA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3881",
    DESCRICAO: "UNIAO",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3882",
    DESCRICAO: "URUCUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3883",
    DESCRICAO: "VALENCA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3884",
    DESCRICAO: "VARZEA BRANCA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3885",
    DESCRICAO: "VARZEA GRANDE",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3886",
    DESCRICAO: "VERA MENDES",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3887",
    DESCRICAO: "VILA NOVA DO PIAUI",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3888",
    DESCRICAO: "WALL FERRAZ",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3889",
    DESCRICAO: "ACARI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3890",
    DESCRICAO: "ACU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3891",
    DESCRICAO: "AFONSO BEZERRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3892",
    DESCRICAO: "AGUA NOVA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3893",
    DESCRICAO: "ALEXANDRIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3894",
    DESCRICAO: "ALMINO AFONSO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3895",
    DESCRICAO: "ALTO DO RODRIGUES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3896",
    DESCRICAO: "ANGICOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3897",
    DESCRICAO: "ANTONIO MARTINS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3898",
    DESCRICAO: "APODI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3899",
    DESCRICAO: "AREIA BRANCA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3900",
    DESCRICAO: "AREZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3901",
    DESCRICAO: "AUGUSTO SEVERO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3902",
    DESCRICAO: "BAIA FORMOSA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3903",
    DESCRICAO: "BARAUNA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3904",
    DESCRICAO: "BARCELONA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3905",
    DESCRICAO: "BENTO FERNANDES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3906",
    DESCRICAO: "BODO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3907",
    DESCRICAO: "BOM JESUS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3908",
    DESCRICAO: "BREJINHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3909",
    DESCRICAO: "CAICARA DO NORTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3910",
    DESCRICAO: "CAICARA DO RIO DO VENTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3911",
    DESCRICAO: "CAICO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3912",
    DESCRICAO: "CAMPO REDONDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3913",
    DESCRICAO: "CANGUARETAMA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3914",
    DESCRICAO: "CARAUBAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3915",
    DESCRICAO: "CARNAUBA DOS DANTAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3916",
    DESCRICAO: "CARNAUBAIS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3917",
    DESCRICAO: "CEARA-MIRIM",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3918",
    DESCRICAO: "CERRO CORA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3919",
    DESCRICAO: "CORONEL EZEQUIEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3920",
    DESCRICAO: "CORONEL JOAO PESSOA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3921",
    DESCRICAO: "CRUZETA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3922",
    DESCRICAO: "CURRAIS NOVOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3923",
    DESCRICAO: "DOUTOR SEVERIANO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3924",
    DESCRICAO: "PARNAMIRIM",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3925",
    DESCRICAO: "ENCANTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3926",
    DESCRICAO: "EQUADOR",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3927",
    DESCRICAO: "ESPIRITO SANTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3928",
    DESCRICAO: "EXTREMOZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3929",
    DESCRICAO: "FELIPE GUERRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3930",
    DESCRICAO: "FERNANDO PEDROZA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3931",
    DESCRICAO: "FLORANIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3932",
    DESCRICAO: "FRANCISCO DANTAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3933",
    DESCRICAO: "FRUTUOSO GOMES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3934",
    DESCRICAO: "GALINHOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3935",
    DESCRICAO: "GOIANINHA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3936",
    DESCRICAO: "GOVERNADOR DIX-SEPT ROSADO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3937",
    DESCRICAO: "GROSSOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3938",
    DESCRICAO: "GUAMARE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3939",
    DESCRICAO: "IELMO MARINHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3940",
    DESCRICAO: "IPANGUACU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3941",
    DESCRICAO: "IPUEIRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3942",
    DESCRICAO: "ITAJA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3943",
    DESCRICAO: "ITAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3944",
    DESCRICAO: "JACANA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3945",
    DESCRICAO: "JANDAIRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3946",
    DESCRICAO: "JANDUIS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3947",
    DESCRICAO: "BOA SAUDE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3948",
    DESCRICAO: "JAPI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3949",
    DESCRICAO: "JARDIM DE ANGICOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3950",
    DESCRICAO: "JARDIM DE PIRANHAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3951",
    DESCRICAO: "JARDIM DO SERIDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3952",
    DESCRICAO: "JOAO CAMARA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3953",
    DESCRICAO: "JOAO DIAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3954",
    DESCRICAO: "JOSE DA PENHA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3955",
    DESCRICAO: "JUCURUTU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3956",
    DESCRICAO: "JUNDIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3957",
    DESCRICAO: "LAGOA D ANTA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3958",
    DESCRICAO: "LAGOA DE PEDRAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3959",
    DESCRICAO: "LAGOA DE VELHOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3960",
    DESCRICAO: "LAGOA NOVA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3961",
    DESCRICAO: "LAGOA SALGADA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3962",
    DESCRICAO: "LAJES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3963",
    DESCRICAO: "LAJES PINTADAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3964",
    DESCRICAO: "LUCRECIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3965",
    DESCRICAO: "LUIS GOMES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3966",
    DESCRICAO: "MACAIBA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3967",
    DESCRICAO: "MACAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3968",
    DESCRICAO: "MAJOR SALES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3969",
    DESCRICAO: "MARCELINO VIEIRA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3970",
    DESCRICAO: "MARTINS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3971",
    DESCRICAO: "MAXARANGUAPE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3972",
    DESCRICAO: "MESSIAS TARGINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3973",
    DESCRICAO: "MONTANHAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3974",
    DESCRICAO: "MONTE ALEGRE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3975",
    DESCRICAO: "MONTE DAS GAMELEIRAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3976",
    DESCRICAO: "MOSSORO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3977",
    DESCRICAO: "NATAL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3978",
    DESCRICAO: "NISIA FLORESTA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3979",
    DESCRICAO: "NOVA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3980",
    DESCRICAO: "OLHO-D AGUA DO BORGES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3981",
    DESCRICAO: "OURO BRANCO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3982",
    DESCRICAO: "PARANA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3983",
    DESCRICAO: "PARAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3984",
    DESCRICAO: "PARAZINHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3985",
    DESCRICAO: "PARELHAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3986",
    DESCRICAO: "RIO DO FOGO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3987",
    DESCRICAO: "PASSA E FICA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3988",
    DESCRICAO: "PASSAGEM",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3989",
    DESCRICAO: "PATU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3990",
    DESCRICAO: "SANTA MARIA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3991",
    DESCRICAO: "PAU DOS FERROS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3992",
    DESCRICAO: "PEDRA GRANDE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3993",
    DESCRICAO: "PEDRA PRETA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3994",
    DESCRICAO: "PEDRO AVELINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3995",
    DESCRICAO: "PEDRO VELHO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3996",
    DESCRICAO: "PENDENCIAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3997",
    DESCRICAO: "PILOES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3998",
    DESCRICAO: "POCO BRANCO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "3999",
    DESCRICAO: "PORTALEGRE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4000",
    DESCRICAO: "PORTO DO MANGUE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4001",
    DESCRICAO: "SERRA CAIADA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4002",
    DESCRICAO: "PUREZA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4003",
    DESCRICAO: "RAFAEL FERNANDES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4004",
    DESCRICAO: "RAFAEL GODEIRO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4005",
    DESCRICAO: "RIACHO DA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4006",
    DESCRICAO: "RIACHO DE SANTANA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4007",
    DESCRICAO: "RIACHUELO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4008",
    DESCRICAO: "RODOLFO FERNANDES",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4009",
    DESCRICAO: "TIBAU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4010",
    DESCRICAO: "RUY BARBOSA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4011",
    DESCRICAO: "SANTA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4012",
    DESCRICAO: "SANTANA DO MATOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4013",
    DESCRICAO: "SANTANA DO SERIDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4014",
    DESCRICAO: "SANTO ANTONIO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4015",
    DESCRICAO: "SAO BENTO DO NORTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4016",
    DESCRICAO: "SAO BENTO DO TRAIRI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4017",
    DESCRICAO: "SAO FERNANDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4018",
    DESCRICAO: "SAO FRANCISCO DO OESTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4019",
    DESCRICAO: "SAO GONCALO DO AMARANTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4020",
    DESCRICAO: "SAO JOAO DO SABUGI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4021",
    DESCRICAO: "SAO JOSE DE MIPIBU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4022",
    DESCRICAO: "SAO JOSE DO CAMPESTRE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4023",
    DESCRICAO: "SAO JOSE DO SERIDO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4024",
    DESCRICAO: "SAO MIGUEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4025",
    DESCRICAO: "SAO MIGUEL DO GOSTOSO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4026",
    DESCRICAO: "SAO PAULO DO POTENGI",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4027",
    DESCRICAO: "SAO PEDRO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4028",
    DESCRICAO: "SAO RAFAEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4029",
    DESCRICAO: "SAO TOME",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4030",
    DESCRICAO: "SAO VICENTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4031",
    DESCRICAO: "SENADOR ELOI DE SOUZA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4032",
    DESCRICAO: "SENADOR GEORGINO AVELINO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4033",
    DESCRICAO: "SERRA DE SAO BENTO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4034",
    DESCRICAO: "SERRA DO MEL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4035",
    DESCRICAO: "SERRA NEGRA DO NORTE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4036",
    DESCRICAO: "SERRINHA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4037",
    DESCRICAO: "SERRINHA DOS PINTOS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4038",
    DESCRICAO: "SEVERIANO MELO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4039",
    DESCRICAO: "SITIO NOVO",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4040",
    DESCRICAO: "TABOLEIRO GRANDE",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4041",
    DESCRICAO: "TAIPU",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4042",
    DESCRICAO: "TANGARA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4043",
    DESCRICAO: "TENENTE ANANIAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4044",
    DESCRICAO: "TENENTE LAURENTINO CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4045",
    DESCRICAO: "TIBAU DO SUL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4046",
    DESCRICAO: "TIMBAUBA DOS BATISTAS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4047",
    DESCRICAO: "TOUROS",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4048",
    DESCRICAO: "TRIUNFO POTIGUAR",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4049",
    DESCRICAO: "UMARIZAL",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4050",
    DESCRICAO: "UPANEMA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4051",
    DESCRICAO: "VARZEA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4052",
    DESCRICAO: "VENHA-VER",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4053",
    DESCRICAO: "VERA CRUZ",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4054",
    DESCRICAO: "VICOSA",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4055",
    DESCRICAO: "VILA FLOR",
    SIGLA: "RN",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4056",
    DESCRICAO: "AMPARO DE SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4057",
    DESCRICAO: "AQUIDABA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4058",
    DESCRICAO: "ARACAJU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4059",
    DESCRICAO: "ARAUA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4060",
    DESCRICAO: "AREIA BRANCA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4061",
    DESCRICAO: "BARRA DOS COQUEIROS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4062",
    DESCRICAO: "BOQUIM",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4063",
    DESCRICAO: "BREJO GRANDE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4064",
    DESCRICAO: "CAMPO DO BRITO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4065",
    DESCRICAO: "CANHOBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4066",
    DESCRICAO: "CANINDE DE SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4067",
    DESCRICAO: "CAPELA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4068",
    DESCRICAO: "CARIRA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4069",
    DESCRICAO: "CARMOPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4070",
    DESCRICAO: "CEDRO DE SAO JOAO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4071",
    DESCRICAO: "CRISTINAPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4072",
    DESCRICAO: "CUMBE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4073",
    DESCRICAO: "DIVINA PASTORA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4074",
    DESCRICAO: "ESTANCIA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4075",
    DESCRICAO: "FEIRA NOVA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4076",
    DESCRICAO: "FREI PAULO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4077",
    DESCRICAO: "GARARU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4078",
    DESCRICAO: "GENERAL MAYNARD",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4079",
    DESCRICAO: "GRACCHO CARDOSO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4080",
    DESCRICAO: "ILHA DAS FLORES",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4081",
    DESCRICAO: "INDIAROBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4082",
    DESCRICAO: "ITABAIANA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4083",
    DESCRICAO: "ITABAIANINHA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4084",
    DESCRICAO: "ITABI",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4085",
    DESCRICAO: "ITAPORANGA D AJUDA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4086",
    DESCRICAO: "JAPARATUBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4087",
    DESCRICAO: "JAPOATA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4088",
    DESCRICAO: "LAGARTO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4089",
    DESCRICAO: "LARANJEIRAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4090",
    DESCRICAO: "MACAMBIRA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4091",
    DESCRICAO: "MALHADA DOS BOIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4092",
    DESCRICAO: "MALHADOR",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4093",
    DESCRICAO: "MARUIM",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4094",
    DESCRICAO: "MOITA BONITA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4095",
    DESCRICAO: "MONTE ALEGRE DE SERGIPE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4096",
    DESCRICAO: "MURIBECA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4097",
    DESCRICAO: "NEOPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4098",
    DESCRICAO: "NOSSA SENHORA APARECIDA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4099",
    DESCRICAO: "NOSSA SENHORA DA GLORIA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4100",
    DESCRICAO: "NOSSA SENHORA DAS DORES",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4101",
    DESCRICAO: "NOSSA SENHORA DE LOURDES",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4102",
    DESCRICAO: "NOSSA SENHORA DO SOCORRO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4103",
    DESCRICAO: "PACATUBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4104",
    DESCRICAO: "PEDRA MOLE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4105",
    DESCRICAO: "PEDRINHAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4106",
    DESCRICAO: "PINHAO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4107",
    DESCRICAO: "PIRAMBU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4108",
    DESCRICAO: "POCO REDONDO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4109",
    DESCRICAO: "POCO VERDE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4110",
    DESCRICAO: "PORTO DA FOLHA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4111",
    DESCRICAO: "PROPRIA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4112",
    DESCRICAO: "RIACHAO DO DANTAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4113",
    DESCRICAO: "RIACHUELO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4114",
    DESCRICAO: "RIBEIROPOLIS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4115",
    DESCRICAO: "ROSARIO DO CATETE",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4116",
    DESCRICAO: "SALGADO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4117",
    DESCRICAO: "SANTA LUZIA DO ITANHY",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4118",
    DESCRICAO: "SANTANA DO SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4119",
    DESCRICAO: "SANTA ROSA DE LIMA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4120",
    DESCRICAO: "SANTO AMARO DAS BROTAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4121",
    DESCRICAO: "SAO CRISTOVAO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4122",
    DESCRICAO: "SAO DOMINGOS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4123",
    DESCRICAO: "SAO FRANCISCO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4124",
    DESCRICAO: "SAO MIGUEL DO ALEIXO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4125",
    DESCRICAO: "SIMAO DIAS",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4126",
    DESCRICAO: "SIRIRI",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4127",
    DESCRICAO: "TELHA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4128",
    DESCRICAO: "TOBIAS BARRETO",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4129",
    DESCRICAO: "TOMAR DO GERU",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4130",
    DESCRICAO: "UMBAUBA",
    SIGLA: "SE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4131",
    DESCRICAO: "ALVARAES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4132",
    DESCRICAO: "AMATURA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4133",
    DESCRICAO: "ANAMA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4134",
    DESCRICAO: "ANORI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4135",
    DESCRICAO: "APUI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4136",
    DESCRICAO: "ATALAIA DO NORTE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4137",
    DESCRICAO: "AUTAZES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4138",
    DESCRICAO: "BARCELOS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4139",
    DESCRICAO: "BARREIRINHA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4140",
    DESCRICAO: "BENJAMIN CONSTANT",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4141",
    DESCRICAO: "BERURI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4142",
    DESCRICAO: "BOA VISTA DO RAMOS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4143",
    DESCRICAO: "BOCA DO ACRE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4144",
    DESCRICAO: "BORBA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4145",
    DESCRICAO: "CAAPIRANGA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4146",
    DESCRICAO: "CANUTAMA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4147",
    DESCRICAO: "CARAUARI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4148",
    DESCRICAO: "CAREIRO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4149",
    DESCRICAO: "CAREIRO DA VARZEA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4150",
    DESCRICAO: "COARI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4151",
    DESCRICAO: "CODAJAS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4152",
    DESCRICAO: "EIRUNEPE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4153",
    DESCRICAO: "ENVIRA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4154",
    DESCRICAO: "FONTE BOA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4155",
    DESCRICAO: "GUAJARA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4156",
    DESCRICAO: "HUMAITA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4157",
    DESCRICAO: "IPIXUNA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4158",
    DESCRICAO: "IRANDUBA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4159",
    DESCRICAO: "ITACOATIARA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4160",
    DESCRICAO: "ITAMARATI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4161",
    DESCRICAO: "ITAPIRANGA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4162",
    DESCRICAO: "JAPURA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4163",
    DESCRICAO: "JURUA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4164",
    DESCRICAO: "JUTAI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4165",
    DESCRICAO: "LABREA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4166",
    DESCRICAO: "MANACAPURU",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4167",
    DESCRICAO: "MANAQUIRI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4168",
    DESCRICAO: "MANAUS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4169",
    DESCRICAO: "MANICORE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4170",
    DESCRICAO: "MARAA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4171",
    DESCRICAO: "MAUES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4172",
    DESCRICAO: "NHAMUNDA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4173",
    DESCRICAO: "NOVA OLINDA DO NORTE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4174",
    DESCRICAO: "NOVO AIRAO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4175",
    DESCRICAO: "NOVO ARIPUANA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4176",
    DESCRICAO: "PARINTINS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4177",
    DESCRICAO: "PAUINI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4178",
    DESCRICAO: "PRESIDENTE FIGUEIREDO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4179",
    DESCRICAO: "RIO PRETO DA EVA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4180",
    DESCRICAO: "SANTA ISABEL DO RIO NEGRO",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4181",
    DESCRICAO: "SANTO ANTONIO DO ICA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4182",
    DESCRICAO: "SAO GABRIEL DA CACHOEIRA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4183",
    DESCRICAO: "SAO PAULO DE OLIVENCA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4184",
    DESCRICAO: "SAO SEBASTIAO DO UATUMA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4185",
    DESCRICAO: "SILVES",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4186",
    DESCRICAO: "TABATINGA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4187",
    DESCRICAO: "TAPAUA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4188",
    DESCRICAO: "TEFE",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4189",
    DESCRICAO: "TONANTINS",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4190",
    DESCRICAO: "UARINI",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4191",
    DESCRICAO: "URUCARA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4192",
    DESCRICAO: "URUCURITUBA",
    SIGLA: "AM",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4193",
    DESCRICAO: "ABAIARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4194",
    DESCRICAO: "ACARAPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4195",
    DESCRICAO: "ACARAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4196",
    DESCRICAO: "ACOPIARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4197",
    DESCRICAO: "AIUABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4198",
    DESCRICAO: "ALCANTARAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4199",
    DESCRICAO: "ALTANEIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4200",
    DESCRICAO: "ALTO SANTO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4201",
    DESCRICAO: "AMONTADA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4202",
    DESCRICAO: "ANTONINA DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4203",
    DESCRICAO: "APUIARES",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4204",
    DESCRICAO: "AQUIRAZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4205",
    DESCRICAO: "ARACATI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4206",
    DESCRICAO: "ARACOIABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4207",
    DESCRICAO: "ARARENDA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4208",
    DESCRICAO: "ARARIPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4209",
    DESCRICAO: "ARATUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4210",
    DESCRICAO: "ARNEIROZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4211",
    DESCRICAO: "ASSARE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4212",
    DESCRICAO: "AURORA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4213",
    DESCRICAO: "BAIXIO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4214",
    DESCRICAO: "BANABUIU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4215",
    DESCRICAO: "BARBALHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4216",
    DESCRICAO: "BARREIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4217",
    DESCRICAO: "BARRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4218",
    DESCRICAO: "BARROQUINHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4219",
    DESCRICAO: "BATURITE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4220",
    DESCRICAO: "BEBERIBE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4221",
    DESCRICAO: "BELA CRUZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4222",
    DESCRICAO: "BOA VIAGEM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4223",
    DESCRICAO: "BREJO SANTO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4224",
    DESCRICAO: "CAMOCIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4225",
    DESCRICAO: "CAMPOS SALES",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4226",
    DESCRICAO: "CANINDE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4227",
    DESCRICAO: "CAPISTRANO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4228",
    DESCRICAO: "CARIDADE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4229",
    DESCRICAO: "CARIRE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4230",
    DESCRICAO: "CARIRIACU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4231",
    DESCRICAO: "CARIUS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4232",
    DESCRICAO: "CARNAUBAL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4233",
    DESCRICAO: "CASCAVEL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4234",
    DESCRICAO: "CATARINA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4235",
    DESCRICAO: "CATUNDA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4236",
    DESCRICAO: "CAUCAIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4237",
    DESCRICAO: "CEDRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4238",
    DESCRICAO: "CHAVAL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4239",
    DESCRICAO: "CHORO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4240",
    DESCRICAO: "CHOROZINHO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4241",
    DESCRICAO: "COREAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4242",
    DESCRICAO: "CRATEUS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4243",
    DESCRICAO: "CRATO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4244",
    DESCRICAO: "CROATA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4245",
    DESCRICAO: "CRUZ",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4246",
    DESCRICAO: "DEPUTADO IRAPUAN PINHEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4247",
    DESCRICAO: "ERERE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4248",
    DESCRICAO: "EUSEBIO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4249",
    DESCRICAO: "FARIAS BRITO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4250",
    DESCRICAO: "FORQUILHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4251",
    DESCRICAO: "FORTALEZA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4252",
    DESCRICAO: "FORTIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4253",
    DESCRICAO: "FRECHEIRINHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4254",
    DESCRICAO: "GENERAL SAMPAIO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4255",
    DESCRICAO: "GRACA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4256",
    DESCRICAO: "GRANJA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4257",
    DESCRICAO: "GRANJEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4258",
    DESCRICAO: "GROAIRAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4259",
    DESCRICAO: "GUAIUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4260",
    DESCRICAO: "GUARACIABA DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4261",
    DESCRICAO: "GUARAMIRANGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4262",
    DESCRICAO: "HIDROLANDIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4263",
    DESCRICAO: "HORIZONTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4264",
    DESCRICAO: "IBARETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4265",
    DESCRICAO: "IBIAPINA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4266",
    DESCRICAO: "IBICUITINGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4267",
    DESCRICAO: "ICAPUI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4268",
    DESCRICAO: "ICO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4269",
    DESCRICAO: "IGUATU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4270",
    DESCRICAO: "INDEPENDENCIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4271",
    DESCRICAO: "IPAPORANGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4272",
    DESCRICAO: "IPAUMIRIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4273",
    DESCRICAO: "IPU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4274",
    DESCRICAO: "IPUEIRAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4275",
    DESCRICAO: "IRACEMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4276",
    DESCRICAO: "IRAUCUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4277",
    DESCRICAO: "ITAICABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4278",
    DESCRICAO: "ITAITINGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4279",
    DESCRICAO: "ITAPAJE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4280",
    DESCRICAO: "ITAPIPOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4281",
    DESCRICAO: "ITAPIUNA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4282",
    DESCRICAO: "ITAREMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4283",
    DESCRICAO: "ITATIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4284",
    DESCRICAO: "JAGUARETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4285",
    DESCRICAO: "JAGUARIBARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4286",
    DESCRICAO: "JAGUARIBE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4287",
    DESCRICAO: "JAGUARUANA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4288",
    DESCRICAO: "JARDIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4289",
    DESCRICAO: "JATI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4290",
    DESCRICAO: "JIJOCA DE JERICOACOARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4291",
    DESCRICAO: "JUAZEIRO DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4292",
    DESCRICAO: "JUCAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4293",
    DESCRICAO: "LAVRAS DA MANGABEIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4294",
    DESCRICAO: "LIMOEIRO DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4295",
    DESCRICAO: "MADALENA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4296",
    DESCRICAO: "MARACANAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4297",
    DESCRICAO: "MARANGUAPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4298",
    DESCRICAO: "MARCO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4299",
    DESCRICAO: "MARTINOPOLE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4300",
    DESCRICAO: "MASSAPE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4301",
    DESCRICAO: "MAURITI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4302",
    DESCRICAO: "MERUOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4303",
    DESCRICAO: "MILAGRES",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4304",
    DESCRICAO: "MILHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4305",
    DESCRICAO: "MIRAIMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4306",
    DESCRICAO: "MISSAO VELHA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4307",
    DESCRICAO: "MOMBACA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4308",
    DESCRICAO: "MONSENHOR TABOSA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4309",
    DESCRICAO: "MORADA NOVA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4310",
    DESCRICAO: "MORAUJO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4311",
    DESCRICAO: "MORRINHOS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4312",
    DESCRICAO: "MUCAMBO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4313",
    DESCRICAO: "MULUNGU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4314",
    DESCRICAO: "NOVA OLINDA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4315",
    DESCRICAO: "NOVA RUSSAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4316",
    DESCRICAO: "NOVO ORIENTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4317",
    DESCRICAO: "OCARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4318",
    DESCRICAO: "OROS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4319",
    DESCRICAO: "PACAJUS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4320",
    DESCRICAO: "PACATUBA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4321",
    DESCRICAO: "PACOTI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4322",
    DESCRICAO: "PACUJA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4323",
    DESCRICAO: "PALHANO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4324",
    DESCRICAO: "PALMACIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4325",
    DESCRICAO: "PARACURU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4326",
    DESCRICAO: "PARAIPABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4327",
    DESCRICAO: "PARAMBU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4328",
    DESCRICAO: "PARAMOTI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4329",
    DESCRICAO: "PEDRA BRANCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4330",
    DESCRICAO: "PENAFORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4331",
    DESCRICAO: "PENTECOSTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4332",
    DESCRICAO: "PEREIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4333",
    DESCRICAO: "PINDORETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4334",
    DESCRICAO: "PIQUET CARNEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4335",
    DESCRICAO: "PIRES FERREIRA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4336",
    DESCRICAO: "PORANGA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4337",
    DESCRICAO: "PORTEIRAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4338",
    DESCRICAO: "POTENGI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4339",
    DESCRICAO: "POTIRETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4340",
    DESCRICAO: "QUITERIANOPOLIS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4341",
    DESCRICAO: "QUIXADA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4342",
    DESCRICAO: "QUIXELO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4343",
    DESCRICAO: "QUIXERAMOBIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4344",
    DESCRICAO: "QUIXERE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4345",
    DESCRICAO: "REDENCAO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4346",
    DESCRICAO: "RERIUTABA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4347",
    DESCRICAO: "RUSSAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4348",
    DESCRICAO: "SABOEIRO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4349",
    DESCRICAO: "SALITRE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4350",
    DESCRICAO: "SANTANA DO ACARAU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4351",
    DESCRICAO: "SANTANA DO CARIRI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4352",
    DESCRICAO: "SANTA QUITERIA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4353",
    DESCRICAO: "SAO BENEDITO",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4354",
    DESCRICAO: "SAO GONCALO DO AMARANTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4355",
    DESCRICAO: "SAO JOAO DO JAGUARIBE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4356",
    DESCRICAO: "SAO LUIS DO CURU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4357",
    DESCRICAO: "SENADOR POMPEU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4358",
    DESCRICAO: "SENADOR SA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4359",
    DESCRICAO: "SOBRAL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4360",
    DESCRICAO: "SOLONOPOLE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4361",
    DESCRICAO: "TABULEIRO DO NORTE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4362",
    DESCRICAO: "TAMBORIL",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4363",
    DESCRICAO: "TARRAFAS",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4364",
    DESCRICAO: "TAUA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4365",
    DESCRICAO: "TEJUCUOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4366",
    DESCRICAO: "TIANGUA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4367",
    DESCRICAO: "TRAIRI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4368",
    DESCRICAO: "TURURU",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4369",
    DESCRICAO: "UBAJARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4370",
    DESCRICAO: "UMARI",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4371",
    DESCRICAO: "UMIRIM",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4372",
    DESCRICAO: "URUBURETAMA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4373",
    DESCRICAO: "URUOCA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4374",
    DESCRICAO: "VARJOTA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4375",
    DESCRICAO: "VARZEA ALEGRE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4376",
    DESCRICAO: "VICOSA DO CEARA",
    SIGLA: "CE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4377",
    DESCRICAO: "ABAETETUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4378",
    DESCRICAO: "ABEL FIGUEIREDO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4379",
    DESCRICAO: "ACARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4380",
    DESCRICAO: "AFUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4381",
    DESCRICAO: "AGUA AZUL DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4382",
    DESCRICAO: "ALENQUER",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4383",
    DESCRICAO: "ALMEIRIM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4384",
    DESCRICAO: "ALTAMIRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4385",
    DESCRICAO: "ANAJAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4386",
    DESCRICAO: "ANANINDEUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4387",
    DESCRICAO: "ANAPU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4388",
    DESCRICAO: "AUGUSTO CORREA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4389",
    DESCRICAO: "AURORA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4390",
    DESCRICAO: "AVEIRO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4391",
    DESCRICAO: "BAGRE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4392",
    DESCRICAO: "BAIAO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4393",
    DESCRICAO: "BANNACH",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4394",
    DESCRICAO: "BARCARENA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4395",
    DESCRICAO: "BELEM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4396",
    DESCRICAO: "BELTERRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4397",
    DESCRICAO: "BENEVIDES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4398",
    DESCRICAO: "BOM JESUS DO TOCANTINS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4399",
    DESCRICAO: "BONITO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4400",
    DESCRICAO: "BRAGANCA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4401",
    DESCRICAO: "BRASIL NOVO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4402",
    DESCRICAO: "BREJO GRANDE DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4403",
    DESCRICAO: "BREU BRANCO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4404",
    DESCRICAO: "BREVES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4405",
    DESCRICAO: "BUJARU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4406",
    DESCRICAO: "CACHOEIRA DO PIRIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4407",
    DESCRICAO: "CACHOEIRA DO ARARI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4408",
    DESCRICAO: "CAMETA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4409",
    DESCRICAO: "CANAA DOS CARAJAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4410",
    DESCRICAO: "CAPANEMA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4411",
    DESCRICAO: "CAPITAO POCO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4412",
    DESCRICAO: "CASTANHAL",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4413",
    DESCRICAO: "CHAVES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4414",
    DESCRICAO: "COLARES",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4415",
    DESCRICAO: "CONCEICAO DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4416",
    DESCRICAO: "CONCORDIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4417",
    DESCRICAO: "CUMARU DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4418",
    DESCRICAO: "CURIONOPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4419",
    DESCRICAO: "CURRALINHO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4420",
    DESCRICAO: "CURUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4421",
    DESCRICAO: "CURUCA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4422",
    DESCRICAO: "DOM ELISEU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4423",
    DESCRICAO: "ELDORADO DOS CARAJAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4424",
    DESCRICAO: "FARO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4425",
    DESCRICAO: "FLORESTA DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4426",
    DESCRICAO: "GARRAFAO DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4427",
    DESCRICAO: "GOIANESIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4428",
    DESCRICAO: "GURUPA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4429",
    DESCRICAO: "IGARAPE-ACU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4430",
    DESCRICAO: "IGARAPE-MIRI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4431",
    DESCRICAO: "INHANGAPI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4432",
    DESCRICAO: "IPIXUNA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4433",
    DESCRICAO: "IRITUIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4434",
    DESCRICAO: "ITAITUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4435",
    DESCRICAO: "ITUPIRANGA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4436",
    DESCRICAO: "JACAREACANGA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4437",
    DESCRICAO: "JACUNDA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4438",
    DESCRICAO: "JURUTI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4439",
    DESCRICAO: "LIMOEIRO DO AJURU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4440",
    DESCRICAO: "MAE DO RIO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4441",
    DESCRICAO: "MAGALHAES BARATA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4442",
    DESCRICAO: "MARABA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4443",
    DESCRICAO: "MARACANA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4444",
    DESCRICAO: "MARAPANIM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4445",
    DESCRICAO: "MARITUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4446",
    DESCRICAO: "MEDICILANDIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4447",
    DESCRICAO: "MELGACO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4448",
    DESCRICAO: "MOCAJUBA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4449",
    DESCRICAO: "MOJU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4450",
    DESCRICAO: "MONTE ALEGRE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4451",
    DESCRICAO: "MUANA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4452",
    DESCRICAO: "NOVA ESPERANCA DO PIRIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4453",
    DESCRICAO: "NOVA IPIXUNA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4454",
    DESCRICAO: "NOVA TIMBOTEUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4455",
    DESCRICAO: "NOVO PROGRESSO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4456",
    DESCRICAO: "NOVO REPARTIMENTO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4457",
    DESCRICAO: "OBIDOS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4458",
    DESCRICAO: "OEIRAS DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4459",
    DESCRICAO: "ORIXIMINA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4460",
    DESCRICAO: "OUREM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4461",
    DESCRICAO: "OURILANDIA DO NORTE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4462",
    DESCRICAO: "PACAJA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4463",
    DESCRICAO: "PALESTINA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4464",
    DESCRICAO: "PARAGOMINAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4465",
    DESCRICAO: "PARAUAPEBAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4466",
    DESCRICAO: "PAU D ARCO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4467",
    DESCRICAO: "PEIXE-BOI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4468",
    DESCRICAO: "PICARRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4469",
    DESCRICAO: "PLACAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4470",
    DESCRICAO: "PONTA DE PEDRAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4471",
    DESCRICAO: "PORTEL",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4472",
    DESCRICAO: "PORTO DE MOZ",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4473",
    DESCRICAO: "PRAINHA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4474",
    DESCRICAO: "PRIMAVERA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4475",
    DESCRICAO: "QUATIPURU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4476",
    DESCRICAO: "REDENCAO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4477",
    DESCRICAO: "RIO MARIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4478",
    DESCRICAO: "RONDON DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4479",
    DESCRICAO: "RUROPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4480",
    DESCRICAO: "SALINOPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4481",
    DESCRICAO: "SALVATERRA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4482",
    DESCRICAO: "SANTA BARBARA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4483",
    DESCRICAO: "SANTA CRUZ DO ARARI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4484",
    DESCRICAO: "SANTA ISABEL DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4485",
    DESCRICAO: "SANTA LUZIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4486",
    DESCRICAO: "SANTA MARIA DAS BARREIRAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4487",
    DESCRICAO: "SANTA MARIA DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4488",
    DESCRICAO: "SANTANA DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4489",
    DESCRICAO: "SANTAREM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4490",
    DESCRICAO: "SANTAREM NOVO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4491",
    DESCRICAO: "SANTO ANTONIO DO TAUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4492",
    DESCRICAO: "SAO CAETANO DE ODIVELAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4493",
    DESCRICAO: "SAO DOMINGOS DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4494",
    DESCRICAO: "SAO DOMINGOS DO CAPIM",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4495",
    DESCRICAO: "SAO FELIX DO XINGU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4496",
    DESCRICAO: "SAO FRANCISCO DO PARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4497",
    DESCRICAO: "SAO GERALDO DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4498",
    DESCRICAO: "SAO JOAO DA PONTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4499",
    DESCRICAO: "SAO JOAO DE PIRABAS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4500",
    DESCRICAO: "SAO JOAO DO ARAGUAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4501",
    DESCRICAO: "SAO MIGUEL DO GUAMA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4502",
    DESCRICAO: "SAO SEBASTIAO DA BOA VISTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4503",
    DESCRICAO: "SAPUCAIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4504",
    DESCRICAO: "SENADOR JOSE PORFIRIO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4505",
    DESCRICAO: "SOURE",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4506",
    DESCRICAO: "TAILANDIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4507",
    DESCRICAO: "TERRA ALTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4508",
    DESCRICAO: "TERRA SANTA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4509",
    DESCRICAO: "TOME-ACU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4510",
    DESCRICAO: "TRACUATEUA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4511",
    DESCRICAO: "TRAIRAO",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4512",
    DESCRICAO: "TUCUMA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4513",
    DESCRICAO: "TUCURUI",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4514",
    DESCRICAO: "ULIANOPOLIS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4515",
    DESCRICAO: "URUARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4516",
    DESCRICAO: "VIGIA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4517",
    DESCRICAO: "VISEU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4518",
    DESCRICAO: "VITORIA DO XINGU",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4519",
    DESCRICAO: "XINGUARA",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4520",
    DESCRICAO: "ABREU E LIMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4521",
    DESCRICAO: "AFOGADOS DA INGAZEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4522",
    DESCRICAO: "AFRANIO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4523",
    DESCRICAO: "AGRESTINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4524",
    DESCRICAO: "AGUA PRETA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4525",
    DESCRICAO: "AGUAS BELAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4526",
    DESCRICAO: "ALAGOINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4527",
    DESCRICAO: "ALIANCA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4528",
    DESCRICAO: "ALTINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4529",
    DESCRICAO: "AMARAJI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4530",
    DESCRICAO: "ANGELIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4531",
    DESCRICAO: "ARACOIABA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4532",
    DESCRICAO: "ARARIPINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4533",
    DESCRICAO: "ARCOVERDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4534",
    DESCRICAO: "BARRA DE GUABIRABA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4535",
    DESCRICAO: "BARREIROS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4536",
    DESCRICAO: "BELEM DE MARIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4537",
    DESCRICAO: "BELEM DO SAO FRANCISCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4538",
    DESCRICAO: "BELO JARDIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4539",
    DESCRICAO: "BETANIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4540",
    DESCRICAO: "BEZERROS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4541",
    DESCRICAO: "BODOCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4542",
    DESCRICAO: "BOM CONSELHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4543",
    DESCRICAO: "BOM JARDIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4544",
    DESCRICAO: "BONITO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4545",
    DESCRICAO: "BREJAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4546",
    DESCRICAO: "BREJINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4547",
    DESCRICAO: "BREJO DA MADRE DE DEUS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4548",
    DESCRICAO: "BUENOS AIRES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4549",
    DESCRICAO: "BUIQUE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4550",
    DESCRICAO: "CABO DE SANTO AGOSTINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4551",
    DESCRICAO: "CABROBO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4552",
    DESCRICAO: "CACHOEIRINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4553",
    DESCRICAO: "CAETES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4554",
    DESCRICAO: "CALCADO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4555",
    DESCRICAO: "CALUMBI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4556",
    DESCRICAO: "CAMARAGIBE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4557",
    DESCRICAO: "CAMOCIM DE SAO FELIX",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4558",
    DESCRICAO: "CAMUTANGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4559",
    DESCRICAO: "CANHOTINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4560",
    DESCRICAO: "CAPOEIRAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4561",
    DESCRICAO: "CARNAIBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4562",
    DESCRICAO: "CARNAUBEIRA DA PENHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4563",
    DESCRICAO: "CARPINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4564",
    DESCRICAO: "CARUARU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4565",
    DESCRICAO: "CASINHAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4566",
    DESCRICAO: "CATENDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4567",
    DESCRICAO: "CEDRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4568",
    DESCRICAO: "CHA DE ALEGRIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4569",
    DESCRICAO: "CHA GRANDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4570",
    DESCRICAO: "CONDADO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4571",
    DESCRICAO: "CORRENTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4572",
    DESCRICAO: "CORTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4573",
    DESCRICAO: "CUMARU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4574",
    DESCRICAO: "CUPIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4575",
    DESCRICAO: "CUSTODIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4576",
    DESCRICAO: "DORMENTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4577",
    DESCRICAO: "ESCADA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4578",
    DESCRICAO: "EXU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4579",
    DESCRICAO: "FEIRA NOVA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4580",
    DESCRICAO: "FERNANDO DE NORONHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4581",
    DESCRICAO: "FERREIROS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4582",
    DESCRICAO: "FLORES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4583",
    DESCRICAO: "FLORESTA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4584",
    DESCRICAO: "FREI MIGUELINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4585",
    DESCRICAO: "GAMELEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4586",
    DESCRICAO: "GARANHUNS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4587",
    DESCRICAO: "GLORIA DO GOITA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4588",
    DESCRICAO: "GOIANA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4589",
    DESCRICAO: "GRANITO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4590",
    DESCRICAO: "GRAVATA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4591",
    DESCRICAO: "IATI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4592",
    DESCRICAO: "IBIMIRIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4593",
    DESCRICAO: "IBIRAJUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4594",
    DESCRICAO: "IGARASSU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4595",
    DESCRICAO: "IGUARACI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4596",
    DESCRICAO: "INAJA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4597",
    DESCRICAO: "INGAZEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4598",
    DESCRICAO: "IPOJUCA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4599",
    DESCRICAO: "IPUBI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4600",
    DESCRICAO: "ITACURUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4601",
    DESCRICAO: "ITAIBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4602",
    DESCRICAO: "ILHA DE ITAMARACA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4603",
    DESCRICAO: "ITAMBE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4604",
    DESCRICAO: "ITAPETIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4605",
    DESCRICAO: "ITAPISSUMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4606",
    DESCRICAO: "ITAQUITINGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4607",
    DESCRICAO: "JABOATAO DOS GUARARAPES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4608",
    DESCRICAO: "JAQUEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4609",
    DESCRICAO: "JATAUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4610",
    DESCRICAO: "JATOBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4611",
    DESCRICAO: "JOAO ALFREDO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4612",
    DESCRICAO: "JOAQUIM NABUCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4613",
    DESCRICAO: "JUCATI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4614",
    DESCRICAO: "JUPI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4615",
    DESCRICAO: "JUREMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4616",
    DESCRICAO: "LAGOA DO CARRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4617",
    DESCRICAO: "LAGOA DO ITAENGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4618",
    DESCRICAO: "LAGOA DO OURO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4619",
    DESCRICAO: "LAGOA DOS GATOS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4620",
    DESCRICAO: "LAGOA GRANDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4621",
    DESCRICAO: "LAJEDO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4622",
    DESCRICAO: "LIMOEIRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4623",
    DESCRICAO: "MACAPARANA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4624",
    DESCRICAO: "MACHADOS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4625",
    DESCRICAO: "MANARI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4626",
    DESCRICAO: "MARAIAL",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4627",
    DESCRICAO: "MIRANDIBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4628",
    DESCRICAO: "MORENO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4629",
    DESCRICAO: "NAZARE DA MATA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4630",
    DESCRICAO: "OLINDA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4631",
    DESCRICAO: "OROBO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4632",
    DESCRICAO: "OROCO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4633",
    DESCRICAO: "OURICURI",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4634",
    DESCRICAO: "PALMARES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4635",
    DESCRICAO: "PALMEIRINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4636",
    DESCRICAO: "PANELAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4637",
    DESCRICAO: "PARANATAMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4638",
    DESCRICAO: "PARNAMIRIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4639",
    DESCRICAO: "PASSIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4640",
    DESCRICAO: "PAUDALHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4641",
    DESCRICAO: "PAULISTA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4642",
    DESCRICAO: "PEDRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4643",
    DESCRICAO: "PESQUEIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4644",
    DESCRICAO: "PETROLANDIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4645",
    DESCRICAO: "PETROLINA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4646",
    DESCRICAO: "POCAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4647",
    DESCRICAO: "POMBOS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4648",
    DESCRICAO: "PRIMAVERA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4649",
    DESCRICAO: "QUIPAPA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4650",
    DESCRICAO: "QUIXABA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4651",
    DESCRICAO: "RECIFE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4652",
    DESCRICAO: "RIACHO DAS ALMAS",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4653",
    DESCRICAO: "RIBEIRAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4654",
    DESCRICAO: "RIO FORMOSO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4655",
    DESCRICAO: "SAIRE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4656",
    DESCRICAO: "SALGADINHO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4657",
    DESCRICAO: "SALGUEIRO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4658",
    DESCRICAO: "SALOA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4659",
    DESCRICAO: "SANHARO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4660",
    DESCRICAO: "SANTA CRUZ",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4661",
    DESCRICAO: "SANTA CRUZ DA BAIXA VERDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4662",
    DESCRICAO: "SANTA CRUZ DO CAPIBARIBE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4663",
    DESCRICAO: "SANTA FILOMENA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4664",
    DESCRICAO: "SANTA MARIA DA BOA VISTA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4665",
    DESCRICAO: "SANTA MARIA DO CAMBUCA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4666",
    DESCRICAO: "SANTA TEREZINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4667",
    DESCRICAO: "SAO BENEDITO DO SUL",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4668",
    DESCRICAO: "SAO BENTO DO UNA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4669",
    DESCRICAO: "SAO CAITANO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4670",
    DESCRICAO: "SAO JOAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4671",
    DESCRICAO: "SAO JOAQUIM DO MONTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4672",
    DESCRICAO: "SAO JOSE DA COROA GRANDE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4673",
    DESCRICAO: "SAO JOSE DO BELMONTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4674",
    DESCRICAO: "SAO JOSE DO EGITO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4675",
    DESCRICAO: "SAO LOURENCO DA MATA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4676",
    DESCRICAO: "SAO VICENTE FERRER",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4677",
    DESCRICAO: "SERRA TALHADA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4678",
    DESCRICAO: "SERRITA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4679",
    DESCRICAO: "SERTANIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4680",
    DESCRICAO: "SIRINHAEM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4681",
    DESCRICAO: "MOREILANDIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4682",
    DESCRICAO: "SOLIDAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4683",
    DESCRICAO: "SURUBIM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4684",
    DESCRICAO: "TABIRA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4685",
    DESCRICAO: "TACAIMBO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4686",
    DESCRICAO: "TACARATU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4687",
    DESCRICAO: "TAMANDARE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4688",
    DESCRICAO: "TAQUARITINGA DO NORTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4689",
    DESCRICAO: "TEREZINHA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4690",
    DESCRICAO: "TERRA NOVA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4691",
    DESCRICAO: "TIMBAUBA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4692",
    DESCRICAO: "TORITAMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4693",
    DESCRICAO: "TRACUNHAEM",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4694",
    DESCRICAO: "TRINDADE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4695",
    DESCRICAO: "TRIUNFO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4696",
    DESCRICAO: "TUPANATINGA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4697",
    DESCRICAO: "TUPARETAMA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4698",
    DESCRICAO: "VENTUROSA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4699",
    DESCRICAO: "VERDEJANTE",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4700",
    DESCRICAO: "VERTENTE DO LERIO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4701",
    DESCRICAO: "VERTENTES",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4702",
    DESCRICAO: "VICENCIA",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4703",
    DESCRICAO: "VITORIA DE SANTO ANTAO",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4704",
    DESCRICAO: "XEXEU",
    SIGLA: "PE",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4705",
    DESCRICAO: "ABATIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4706",
    DESCRICAO: "ADRIANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4707",
    DESCRICAO: "AGUDOS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4708",
    DESCRICAO: "ALMIRANTE TAMANDARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4709",
    DESCRICAO: "ALTAMIRA DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4710",
    DESCRICAO: "ALTONIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4711",
    DESCRICAO: "ALTO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4712",
    DESCRICAO: "ALTO PIQUIRI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4713",
    DESCRICAO: "ALVORADA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4714",
    DESCRICAO: "AMAPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4715",
    DESCRICAO: "AMPERE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4716",
    DESCRICAO: "ANAHY",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4717",
    DESCRICAO: "ANDIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4718",
    DESCRICAO: "ANGULO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4719",
    DESCRICAO: "ANTONINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4720",
    DESCRICAO: "ANTONIO OLINTO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4721",
    DESCRICAO: "APUCARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4722",
    DESCRICAO: "ARAPONGAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4723",
    DESCRICAO: "ARAPOTI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4724",
    DESCRICAO: "ARAPUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4725",
    DESCRICAO: "ARARUNA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4726",
    DESCRICAO: "ARAUCARIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4727",
    DESCRICAO: "ARIRANHA DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4728",
    DESCRICAO: "ASSAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4729",
    DESCRICAO: "ASSIS CHATEAUBRIAND",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4730",
    DESCRICAO: "ASTORGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4731",
    DESCRICAO: "ATALAIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4732",
    DESCRICAO: "BALSA NOVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4733",
    DESCRICAO: "BANDEIRANTES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4734",
    DESCRICAO: "BARBOSA FERRAZ",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4735",
    DESCRICAO: "BARRACAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4736",
    DESCRICAO: "BARRA DO JACARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4737",
    DESCRICAO: "BELA VISTA DA CAROBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4738",
    DESCRICAO: "BELA VISTA DO PARAISO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4739",
    DESCRICAO: "BITURUNA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4740",
    DESCRICAO: "BOA ESPERANCA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4741",
    DESCRICAO: "BOA ESPERANCA DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4742",
    DESCRICAO: "BOA VENTURA DE SAO ROQUE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4743",
    DESCRICAO: "BOA VISTA DA APARECIDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4744",
    DESCRICAO: "BOCAIUVA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4745",
    DESCRICAO: "BOM JESUS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4746",
    DESCRICAO: "BOM SUCESSO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4747",
    DESCRICAO: "BOM SUCESSO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4748",
    DESCRICAO: "BORRAZOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4749",
    DESCRICAO: "BRAGANEY",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4750",
    DESCRICAO: "BRASILANDIA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4751",
    DESCRICAO: "CAFEARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4752",
    DESCRICAO: "CAFELANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4753",
    DESCRICAO: "CAFEZAL DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4754",
    DESCRICAO: "CALIFORNIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4755",
    DESCRICAO: "CAMBARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4756",
    DESCRICAO: "CAMBE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4757",
    DESCRICAO: "CAMBIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4758",
    DESCRICAO: "CAMPINA DA LAGOA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4759",
    DESCRICAO: "CAMPINA DO SIMAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4760",
    DESCRICAO: "CAMPINA GRANDE DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4761",
    DESCRICAO: "CAMPO BONITO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4762",
    DESCRICAO: "CAMPO DO TENENTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4763",
    DESCRICAO: "CAMPO LARGO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4764",
    DESCRICAO: "CAMPO MAGRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4765",
    DESCRICAO: "CAMPO MOURAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4766",
    DESCRICAO: "CANDIDO DE ABREU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4767",
    DESCRICAO: "CANDOI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4768",
    DESCRICAO: "CANTAGALO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4769",
    DESCRICAO: "CAPANEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4770",
    DESCRICAO: "CAPITAO LEONIDAS MARQUES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4771",
    DESCRICAO: "CARAMBEI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4772",
    DESCRICAO: "CARLOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4773",
    DESCRICAO: "CASCAVEL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4774",
    DESCRICAO: "CASTRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4775",
    DESCRICAO: "CATANDUVAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4776",
    DESCRICAO: "CENTENARIO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4777",
    DESCRICAO: "CERRO AZUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4778",
    DESCRICAO: "CEU AZUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4779",
    DESCRICAO: "CHOPINZINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4780",
    DESCRICAO: "CIANORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4781",
    DESCRICAO: "CIDADE GAUCHA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4782",
    DESCRICAO: "CLEVELANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4783",
    DESCRICAO: "COLOMBO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4784",
    DESCRICAO: "COLORADO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4785",
    DESCRICAO: "CONGONHINHAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4786",
    DESCRICAO: "CONSELHEIRO MAIRINCK",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4787",
    DESCRICAO: "CONTENDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4788",
    DESCRICAO: "CORBELIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4789",
    DESCRICAO: "CORNELIO PROCOPIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4790",
    DESCRICAO: "CORONEL DOMINGOS SOARES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4791",
    DESCRICAO: "CORONEL VIVIDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4792",
    DESCRICAO: "CORUMBATAI DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4793",
    DESCRICAO: "CRUZEIRO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4794",
    DESCRICAO: "CRUZEIRO DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4795",
    DESCRICAO: "CRUZEIRO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4796",
    DESCRICAO: "CRUZ MACHADO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4797",
    DESCRICAO: "CRUZMALTINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4798",
    DESCRICAO: "CURITIBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4799",
    DESCRICAO: "CURIUVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4800",
    DESCRICAO: "DIAMANTE DO NORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4801",
    DESCRICAO: "DIAMANTE DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4802",
    DESCRICAO: "DIAMANTE D OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4803",
    DESCRICAO: "DOIS VIZINHOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4804",
    DESCRICAO: "DOURADINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4805",
    DESCRICAO: "DOUTOR CAMARGO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4806",
    DESCRICAO: "ENEAS MARQUES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4807",
    DESCRICAO: "ENGENHEIRO BELTRAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4808",
    DESCRICAO: "ESPERANCA NOVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4809",
    DESCRICAO: "ENTRE RIOS DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4810",
    DESCRICAO: "ESPIGAO ALTO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4811",
    DESCRICAO: "FAROL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4812",
    DESCRICAO: "FAXINAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4813",
    DESCRICAO: "FAZENDA RIO GRANDE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4814",
    DESCRICAO: "FENIX",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4815",
    DESCRICAO: "FERNANDES PINHEIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4816",
    DESCRICAO: "FIGUEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4817",
    DESCRICAO: "FLORAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4818",
    DESCRICAO: "FLOR DA SERRA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4819",
    DESCRICAO: "FLORESTA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4820",
    DESCRICAO: "FLORESTOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4821",
    DESCRICAO: "FLORIDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4822",
    DESCRICAO: "FORMOSA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4823",
    DESCRICAO: "FOZ DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4824",
    DESCRICAO: "FRANCISCO ALVES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4825",
    DESCRICAO: "FRANCISCO BELTRAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4826",
    DESCRICAO: "FOZ DO JORDAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4827",
    DESCRICAO: "GENERAL CARNEIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4828",
    DESCRICAO: "GODOY MOREIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4829",
    DESCRICAO: "GOIOERE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4830",
    DESCRICAO: "GOIOXIM",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4831",
    DESCRICAO: "GRANDES RIOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4832",
    DESCRICAO: "GUAIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4833",
    DESCRICAO: "GUAIRACA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4834",
    DESCRICAO: "GUAMIRANGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4835",
    DESCRICAO: "GUAPIRAMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4836",
    DESCRICAO: "GUAPOREMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4837",
    DESCRICAO: "GUARACI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4838",
    DESCRICAO: "GUARANIACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4839",
    DESCRICAO: "GUARAPUAVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4840",
    DESCRICAO: "GUARAQUECABA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4841",
    DESCRICAO: "GUARATUBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4842",
    DESCRICAO: "HONORIO SERPA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4843",
    DESCRICAO: "IBAITI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4844",
    DESCRICAO: "IBEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4845",
    DESCRICAO: "IBIPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4846",
    DESCRICAO: "ICARAIMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4847",
    DESCRICAO: "IGUARACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4848",
    DESCRICAO: "IGUATU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4849",
    DESCRICAO: "IMBAU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4850",
    DESCRICAO: "IMBITUVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4851",
    DESCRICAO: "INACIO MARTINS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4852",
    DESCRICAO: "INAJA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4853",
    DESCRICAO: "INDIANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4854",
    DESCRICAO: "IPIRANGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4855",
    DESCRICAO: "IPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4856",
    DESCRICAO: "IRACEMA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4857",
    DESCRICAO: "IRATI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4858",
    DESCRICAO: "IRETAMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4859",
    DESCRICAO: "ITAGUAJE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4860",
    DESCRICAO: "ITAIPULANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4861",
    DESCRICAO: "ITAMBARACA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4862",
    DESCRICAO: "ITAMBE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4863",
    DESCRICAO: "ITAPEJARA D OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4864",
    DESCRICAO: "ITAPERUCU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4865",
    DESCRICAO: "ITAUNA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4866",
    DESCRICAO: "IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4867",
    DESCRICAO: "IVAIPORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4868",
    DESCRICAO: "IVATE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4869",
    DESCRICAO: "IVATUBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4870",
    DESCRICAO: "JABOTI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4871",
    DESCRICAO: "JACAREZINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4872",
    DESCRICAO: "JAGUAPITA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4873",
    DESCRICAO: "JAGUARIAIVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4874",
    DESCRICAO: "JANDAIA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4875",
    DESCRICAO: "JANIOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4876",
    DESCRICAO: "JAPIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4877",
    DESCRICAO: "JAPURA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4878",
    DESCRICAO: "JARDIM ALEGRE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4879",
    DESCRICAO: "JARDIM OLINDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4880",
    DESCRICAO: "JATAIZINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4881",
    DESCRICAO: "JESUITAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4882",
    DESCRICAO: "JOAQUIM TAVORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4883",
    DESCRICAO: "JUNDIAI DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4884",
    DESCRICAO: "JURANDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4885",
    DESCRICAO: "JUSSARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4886",
    DESCRICAO: "KALORE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4887",
    DESCRICAO: "LAPA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4888",
    DESCRICAO: "LARANJAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4889",
    DESCRICAO: "LARANJEIRAS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4890",
    DESCRICAO: "LEOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4891",
    DESCRICAO: "LIDIANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4892",
    DESCRICAO: "LINDOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4893",
    DESCRICAO: "LOANDA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4894",
    DESCRICAO: "LOBATO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4895",
    DESCRICAO: "LONDRINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4896",
    DESCRICAO: "LUIZIANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4897",
    DESCRICAO: "LUNARDELLI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4898",
    DESCRICAO: "LUPIONOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4899",
    DESCRICAO: "MALLET",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4900",
    DESCRICAO: "MAMBORE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4901",
    DESCRICAO: "MANDAGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4902",
    DESCRICAO: "MANDAGUARI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4903",
    DESCRICAO: "MANDIRITUBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4904",
    DESCRICAO: "MANFRINOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4905",
    DESCRICAO: "MANGUEIRINHA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4906",
    DESCRICAO: "MANOEL RIBAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4907",
    DESCRICAO: "MARECHAL CANDIDO RONDON",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4908",
    DESCRICAO: "MARIA HELENA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4909",
    DESCRICAO: "MARIALVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4910",
    DESCRICAO: "MARILANDIA DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4911",
    DESCRICAO: "MARILENA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4912",
    DESCRICAO: "MARILUZ",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4913",
    DESCRICAO: "MARINGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4914",
    DESCRICAO: "MARIOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4915",
    DESCRICAO: "MARIPA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4916",
    DESCRICAO: "MARMELEIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4917",
    DESCRICAO: "MARQUINHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4918",
    DESCRICAO: "MARUMBI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4919",
    DESCRICAO: "MATELANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4920",
    DESCRICAO: "MATINHOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4921",
    DESCRICAO: "MATO RICO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4922",
    DESCRICAO: "MAUA DA SERRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4923",
    DESCRICAO: "MEDIANEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4924",
    DESCRICAO: "MERCEDES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4925",
    DESCRICAO: "MIRADOR",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4926",
    DESCRICAO: "MIRASELVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4927",
    DESCRICAO: "MISSAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4928",
    DESCRICAO: "MOREIRA SALES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4929",
    DESCRICAO: "MORRETES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4930",
    DESCRICAO: "MUNHOZ DE MELO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4931",
    DESCRICAO: "NOSSA SENHORA DAS GRACAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4932",
    DESCRICAO: "NOVA ALIANCA DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4933",
    DESCRICAO: "NOVA AMERICA DA COLINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4934",
    DESCRICAO: "NOVA AURORA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4935",
    DESCRICAO: "NOVA CANTU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4936",
    DESCRICAO: "NOVA ESPERANCA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4937",
    DESCRICAO: "NOVA ESPERANCA DO SUDOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4938",
    DESCRICAO: "NOVA FATIMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4939",
    DESCRICAO: "NOVA LARANJEIRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4940",
    DESCRICAO: "NOVA LONDRINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4941",
    DESCRICAO: "NOVA OLIMPIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4942",
    DESCRICAO: "NOVA SANTA BARBARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4943",
    DESCRICAO: "NOVA SANTA ROSA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4944",
    DESCRICAO: "NOVA PRATA DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4945",
    DESCRICAO: "NOVA TEBAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4946",
    DESCRICAO: "NOVO ITACOLOMI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4947",
    DESCRICAO: "ORTIGUEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4948",
    DESCRICAO: "OURIZONA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4949",
    DESCRICAO: "OURO VERDE DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4950",
    DESCRICAO: "PAICANDU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4951",
    DESCRICAO: "PALMAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4952",
    DESCRICAO: "PALMEIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4953",
    DESCRICAO: "PALMITAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4954",
    DESCRICAO: "PALOTINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4955",
    DESCRICAO: "PARAISO DO NORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4956",
    DESCRICAO: "PARANACITY",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4957",
    DESCRICAO: "PARANAGUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4958",
    DESCRICAO: "PARANAPOEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4959",
    DESCRICAO: "PARANAVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4960",
    DESCRICAO: "PATO BRAGADO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4961",
    DESCRICAO: "PATO BRANCO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4962",
    DESCRICAO: "PAULA FREITAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4963",
    DESCRICAO: "PAULO FRONTIN",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4964",
    DESCRICAO: "PEABIRU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4965",
    DESCRICAO: "PEROBAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4966",
    DESCRICAO: "PEROLA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4967",
    DESCRICAO: "PEROLA D OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4968",
    DESCRICAO: "PIEN",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4969",
    DESCRICAO: "PINHAIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4970",
    DESCRICAO: "PINHALAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4971",
    DESCRICAO: "PINHAL DE SAO BENTO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4972",
    DESCRICAO: "PINHAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4973",
    DESCRICAO: "PIRAI DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4974",
    DESCRICAO: "PIRAQUARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4975",
    DESCRICAO: "PITANGA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4976",
    DESCRICAO: "PITANGUEIRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4977",
    DESCRICAO: "PLANALTINA DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4978",
    DESCRICAO: "PLANALTO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4979",
    DESCRICAO: "PONTA GROSSA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4980",
    DESCRICAO: "PONTAL DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4981",
    DESCRICAO: "PORECATU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4982",
    DESCRICAO: "PORTO AMAZONAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4983",
    DESCRICAO: "PORTO BARREIRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4984",
    DESCRICAO: "PORTO RICO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4985",
    DESCRICAO: "PORTO VITORIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4986",
    DESCRICAO: "PRADO FERREIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4987",
    DESCRICAO: "PRANCHITA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4988",
    DESCRICAO: "PRESIDENTE CASTELO BRANCO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4989",
    DESCRICAO: "PRIMEIRO DE MAIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4990",
    DESCRICAO: "PRUDENTOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4991",
    DESCRICAO: "QUARTO CENTENARIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4992",
    DESCRICAO: "QUATIGUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4993",
    DESCRICAO: "QUATRO BARRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4994",
    DESCRICAO: "QUATRO PONTES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4995",
    DESCRICAO: "QUEDAS DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4996",
    DESCRICAO: "QUERENCIA DO NORTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4997",
    DESCRICAO: "QUINTA DO SOL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4998",
    DESCRICAO: "QUITANDINHA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "4999",
    DESCRICAO: "RAMILANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5000",
    DESCRICAO: "RANCHO ALEGRE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5001",
    DESCRICAO: "RANCHO ALEGRE D OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5002",
    DESCRICAO: "REALEZA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5003",
    DESCRICAO: "REBOUCAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5004",
    DESCRICAO: "RENASCENCA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5005",
    DESCRICAO: "RESERVA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5006",
    DESCRICAO: "RESERVA DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5007",
    DESCRICAO: "RIBEIRAO CLARO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5008",
    DESCRICAO: "RIBEIRAO DO PINHAL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5009",
    DESCRICAO: "RIO AZUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5010",
    DESCRICAO: "RIO BOM",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5011",
    DESCRICAO: "RIO BONITO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5012",
    DESCRICAO: "RIO BRANCO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5013",
    DESCRICAO: "RIO BRANCO DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5014",
    DESCRICAO: "RIO NEGRO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5015",
    DESCRICAO: "ROLANDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5016",
    DESCRICAO: "RONCADOR",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5017",
    DESCRICAO: "RONDON",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5018",
    DESCRICAO: "ROSARIO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5019",
    DESCRICAO: "SABAUDIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5020",
    DESCRICAO: "SALGADO FILHO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5021",
    DESCRICAO: "SALTO DO ITARARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5022",
    DESCRICAO: "SALTO DO LONTRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5023",
    DESCRICAO: "SANTA AMELIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5024",
    DESCRICAO: "SANTA CECILIA DO PAVAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5025",
    DESCRICAO: "SANTA CRUZ DE MONTE CASTELO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5026",
    DESCRICAO: "SANTA FE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5027",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5028",
    DESCRICAO: "SANTA INES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5029",
    DESCRICAO: "SANTA ISABEL DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5030",
    DESCRICAO: "SANTA IZABEL DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5031",
    DESCRICAO: "SANTA LUCIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5032",
    DESCRICAO: "SANTA MARIA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5033",
    DESCRICAO: "SANTA MARIANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5034",
    DESCRICAO: "SANTA MONICA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5035",
    DESCRICAO: "SANTANA DO ITARARE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5036",
    DESCRICAO: "SANTA TEREZA DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5037",
    DESCRICAO: "SANTA TEREZINHA DE ITAIPU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5038",
    DESCRICAO: "SANTO ANTONIO DA PLATINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5039",
    DESCRICAO: "SANTO ANTONIO DO CAIUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5040",
    DESCRICAO: "SANTO ANTONIO DO PARAISO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5041",
    DESCRICAO: "SANTO ANTONIO DO SUDOESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5042",
    DESCRICAO: "SANTO INACIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5043",
    DESCRICAO: "SAO CARLOS DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5044",
    DESCRICAO: "SAO JERONIMO DA SERRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5045",
    DESCRICAO: "SAO JOAO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5046",
    DESCRICAO: "SAO JOAO DO CAIUA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5047",
    DESCRICAO: "SAO JOAO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5048",
    DESCRICAO: "SAO JOAO DO TRIUNFO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5049",
    DESCRICAO: "SAO JORGE D OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5050",
    DESCRICAO: "SAO JORGE DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5051",
    DESCRICAO: "SAO JORGE DO PATROCINIO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5052",
    DESCRICAO: "SAO JOSE DA BOA VISTA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5053",
    DESCRICAO: "SAO JOSE DAS PALMEIRAS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5054",
    DESCRICAO: "SAO JOSE DOS PINHAIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5055",
    DESCRICAO: "SAO MANOEL DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5056",
    DESCRICAO: "SAO MATEUS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5057",
    DESCRICAO: "SAO MIGUEL DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5058",
    DESCRICAO: "SAO PEDRO DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5059",
    DESCRICAO: "SAO PEDRO DO IVAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5060",
    DESCRICAO: "SAO PEDRO DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5061",
    DESCRICAO: "SAO SEBASTIAO DA AMOREIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5062",
    DESCRICAO: "SAO TOME",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5063",
    DESCRICAO: "SAPOPEMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5064",
    DESCRICAO: "SARANDI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5065",
    DESCRICAO: "SAUDADE DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5066",
    DESCRICAO: "SENGES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5067",
    DESCRICAO: "SERRANOPOLIS DO IGUACU",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5068",
    DESCRICAO: "SERTANEJA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5069",
    DESCRICAO: "SERTANOPOLIS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5070",
    DESCRICAO: "SIQUEIRA CAMPOS",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5071",
    DESCRICAO: "SULINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5072",
    DESCRICAO: "TAMARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5073",
    DESCRICAO: "TAMBOARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5074",
    DESCRICAO: "TAPEJARA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5075",
    DESCRICAO: "TAPIRA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5076",
    DESCRICAO: "TEIXEIRA SOARES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5077",
    DESCRICAO: "TELEMACO BORBA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5078",
    DESCRICAO: "TERRA BOA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5079",
    DESCRICAO: "TERRA RICA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5080",
    DESCRICAO: "TERRA ROXA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5081",
    DESCRICAO: "TIBAGI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5082",
    DESCRICAO: "TIJUCAS DO SUL",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5083",
    DESCRICAO: "TOLEDO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5084",
    DESCRICAO: "TOMAZINA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5085",
    DESCRICAO: "TRES BARRAS DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5086",
    DESCRICAO: "TUNAS DO PARANA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5087",
    DESCRICAO: "TUNEIRAS DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5088",
    DESCRICAO: "TUPASSI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5089",
    DESCRICAO: "TURVO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5090",
    DESCRICAO: "UBIRATA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5091",
    DESCRICAO: "UMUARAMA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5092",
    DESCRICAO: "UNIAO DA VITORIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5093",
    DESCRICAO: "UNIFLOR",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5094",
    DESCRICAO: "URAI",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5095",
    DESCRICAO: "WENCESLAU BRAZ",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5096",
    DESCRICAO: "VENTANIA",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5097",
    DESCRICAO: "VERA CRUZ DO OESTE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5098",
    DESCRICAO: "VERE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5099",
    DESCRICAO: "ALTO PARAISO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5100",
    DESCRICAO: "DOUTOR ULYSSES",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5101",
    DESCRICAO: "VIRMOND",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5102",
    DESCRICAO: "VITORINO",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5103",
    DESCRICAO: "XAMBRE",
    SIGLA: "PR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5104",
    DESCRICAO: "ALTA FLORESTA D OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5105",
    DESCRICAO: "ARIQUEMES",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5106",
    DESCRICAO: "CABIXI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5107",
    DESCRICAO: "CACOAL",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5108",
    DESCRICAO: "CEREJEIRAS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5109",
    DESCRICAO: "COLORADO DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5110",
    DESCRICAO: "CORUMBIARA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5111",
    DESCRICAO: "COSTA MARQUES",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5112",
    DESCRICAO: "ESPIGAO DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5113",
    DESCRICAO: "GUAJARA-MIRIM",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5114",
    DESCRICAO: "JARU",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5115",
    DESCRICAO: "JI-PARANA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5116",
    DESCRICAO: "MACHADINHO D OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5117",
    DESCRICAO: "NOVA BRASILANDIA D OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5118",
    DESCRICAO: "OURO PRETO DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5119",
    DESCRICAO: "PIMENTA BUENO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5120",
    DESCRICAO: "PORTO VELHO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5121",
    DESCRICAO: "PRESIDENTE MEDICI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5122",
    DESCRICAO: "RIO CRESPO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5123",
    DESCRICAO: "ROLIM DE MOURA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5124",
    DESCRICAO: "SANTA LUZIA D OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5125",
    DESCRICAO: "VILHENA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5126",
    DESCRICAO: "SAO MIGUEL DO GUAPORE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5127",
    DESCRICAO: "NOVA MAMORE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5128",
    DESCRICAO: "ALVORADA D OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5129",
    DESCRICAO: "ALTO ALEGRE DOS PARECIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5130",
    DESCRICAO: "ALTO PARAISO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5131",
    DESCRICAO: "BURITIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5132",
    DESCRICAO: "NOVO HORIZONTE DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5133",
    DESCRICAO: "CACAULANDIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5134",
    DESCRICAO: "CAMPO NOVO DE RONDONIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5135",
    DESCRICAO: "CANDEIAS DO JAMARI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5136",
    DESCRICAO: "CASTANHEIRAS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5137",
    DESCRICAO: "CHUPINGUAIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5138",
    DESCRICAO: "CUJUBIM",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5139",
    DESCRICAO: "GOVERNADOR JORGE TEIXEIRA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5140",
    DESCRICAO: "ITAPUA DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5141",
    DESCRICAO: "MINISTRO ANDREAZZA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5142",
    DESCRICAO: "MIRANTE DA SERRA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5143",
    DESCRICAO: "MONTE NEGRO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5144",
    DESCRICAO: "NOVA UNIAO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5145",
    DESCRICAO: "PARECIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5146",
    DESCRICAO: "PIMENTEIRAS DO OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5147",
    DESCRICAO: "PRIMAVERA DE RONDONIA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5148",
    DESCRICAO: "SAO FELIPE D OESTE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5149",
    DESCRICAO: "SAO FRANCISCO DO GUAPORE",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5150",
    DESCRICAO: "SERINGUEIRAS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5151",
    DESCRICAO: "TEIXEIROPOLIS",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5152",
    DESCRICAO: "THEOBROMA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5153",
    DESCRICAO: "URUPA",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5154",
    DESCRICAO: "VALE DO ANARI",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5155",
    DESCRICAO: "VALE DO PARAISO",
    SIGLA: "RO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5156",
    DESCRICAO: "ABREULANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5157",
    DESCRICAO: "AGUIARNOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5158",
    DESCRICAO: "ALIANCA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5159",
    DESCRICAO: "ALMAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5160",
    DESCRICAO: "ALVORADA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5161",
    DESCRICAO: "ANANAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5162",
    DESCRICAO: "ANGICO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5163",
    DESCRICAO: "APARECIDA DO RIO NEGRO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5164",
    DESCRICAO: "ARAGOMINAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5165",
    DESCRICAO: "ARAGUACEMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5166",
    DESCRICAO: "ARAGUACU",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5167",
    DESCRICAO: "ARAGUAINA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5168",
    DESCRICAO: "ARAGUANA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5169",
    DESCRICAO: "ARAGUATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5170",
    DESCRICAO: "ARAPOEMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5171",
    DESCRICAO: "ARRAIAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5172",
    DESCRICAO: "AUGUSTINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5173",
    DESCRICAO: "AURORA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5174",
    DESCRICAO: "AXIXA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5175",
    DESCRICAO: "BABACULANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5176",
    DESCRICAO: "BANDEIRANTES DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5177",
    DESCRICAO: "BARRA DO OURO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5178",
    DESCRICAO: "BARROLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5179",
    DESCRICAO: "BERNARDO SAYAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5180",
    DESCRICAO: "BOM JESUS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5181",
    DESCRICAO: "BRASILANDIA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5182",
    DESCRICAO: "BREJINHO DE NAZARE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5183",
    DESCRICAO: "BURITI DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5184",
    DESCRICAO: "CACHOEIRINHA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5185",
    DESCRICAO: "CAMPOS LINDOS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5186",
    DESCRICAO: "CARIRI DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5187",
    DESCRICAO: "CARMOLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5188",
    DESCRICAO: "CARRASCO BONITO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5189",
    DESCRICAO: "CASEARA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5190",
    DESCRICAO: "CENTENARIO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5191",
    DESCRICAO: "CHAPADA DE AREIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5192",
    DESCRICAO: "CHAPADA DA NATIVIDADE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5193",
    DESCRICAO: "COLINAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5194",
    DESCRICAO: "COMBINADO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5195",
    DESCRICAO: "CONCEICAO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5196",
    DESCRICAO: "COUTO DE MAGALHAES",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5197",
    DESCRICAO: "CRISTALANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5198",
    DESCRICAO: "CRIXAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5199",
    DESCRICAO: "DARCINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5200",
    DESCRICAO: "DIANOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5201",
    DESCRICAO: "DIVINOPOLIS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5202",
    DESCRICAO: "DOIS IRMAOS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5203",
    DESCRICAO: "DUERE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5204",
    DESCRICAO: "ESPERANTINA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5205",
    DESCRICAO: "FATIMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5206",
    DESCRICAO: "FIGUEIROPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5207",
    DESCRICAO: "FILADELFIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5208",
    DESCRICAO: "FORMOSO DO ARAGUAIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5209",
    DESCRICAO: "FORTALEZA DO TABOCAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5210",
    DESCRICAO: "GOIANORTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5211",
    DESCRICAO: "GOIATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5212",
    DESCRICAO: "GUARAI",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5213",
    DESCRICAO: "GURUPI",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5214",
    DESCRICAO: "IPUEIRAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5215",
    DESCRICAO: "ITACAJA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5216",
    DESCRICAO: "ITAGUATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5217",
    DESCRICAO: "ITAPIRATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5218",
    DESCRICAO: "ITAPORA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5219",
    DESCRICAO: "JAU DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5220",
    DESCRICAO: "JUARINA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5221",
    DESCRICAO: "LAGOA DA CONFUSAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5222",
    DESCRICAO: "LAGOA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5223",
    DESCRICAO: "LAJEADO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5224",
    DESCRICAO: "LAVANDEIRA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5225",
    DESCRICAO: "LIZARDA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5226",
    DESCRICAO: "LUZINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5227",
    DESCRICAO: "MARIANOPOLIS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5228",
    DESCRICAO: "MATEIROS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5229",
    DESCRICAO: "MAURILANDIA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5230",
    DESCRICAO: "MIRACEMA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5231",
    DESCRICAO: "MIRANORTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5232",
    DESCRICAO: "MONTE DO CARMO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5233",
    DESCRICAO: "MONTE SANTO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5234",
    DESCRICAO: "PALMEIRAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5235",
    DESCRICAO: "MURICILANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5236",
    DESCRICAO: "NATIVIDADE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5237",
    DESCRICAO: "NAZARE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5238",
    DESCRICAO: "NOVA OLINDA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5239",
    DESCRICAO: "NOVA ROSALANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5240",
    DESCRICAO: "NOVO ACORDO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5241",
    DESCRICAO: "NOVO ALEGRE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5242",
    DESCRICAO: "NOVO JARDIM",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5243",
    DESCRICAO: "OLIVEIRA DE FATIMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5244",
    DESCRICAO: "PALMEIRANTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5245",
    DESCRICAO: "PALMEIROPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5246",
    DESCRICAO: "PARAISO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5247",
    DESCRICAO: "PARANA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5248",
    DESCRICAO: "PAU D ARCO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5249",
    DESCRICAO: "PEDRO AFONSO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5250",
    DESCRICAO: "PEIXE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5251",
    DESCRICAO: "PEQUIZEIRO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5252",
    DESCRICAO: "COLMEIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5253",
    DESCRICAO: "PINDORAMA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5254",
    DESCRICAO: "PIRAQUE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5255",
    DESCRICAO: "PIUM",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5256",
    DESCRICAO: "PONTE ALTA DO BOM JESUS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5257",
    DESCRICAO: "PONTE ALTA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5258",
    DESCRICAO: "PORTO ALEGRE DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5259",
    DESCRICAO: "PORTO NACIONAL",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5260",
    DESCRICAO: "PRAIA NORTE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5261",
    DESCRICAO: "PRESIDENTE KENNEDY",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5262",
    DESCRICAO: "PUGMIL",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5263",
    DESCRICAO: "RECURSOLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5264",
    DESCRICAO: "RIACHINHO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5265",
    DESCRICAO: "RIO DA CONCEICAO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5266",
    DESCRICAO: "RIO DOS BOIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5267",
    DESCRICAO: "RIO SONO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5268",
    DESCRICAO: "SAMPAIO",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5269",
    DESCRICAO: "SANDOLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5270",
    DESCRICAO: "SANTA FE DO ARAGUAIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5271",
    DESCRICAO: "SANTA MARIA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5272",
    DESCRICAO: "SANTA RITA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5273",
    DESCRICAO: "SANTA ROSA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5274",
    DESCRICAO: "SANTA TEREZA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5275",
    DESCRICAO: "SANTA TEREZINHA DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5276",
    DESCRICAO: "SAO BENTO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5277",
    DESCRICAO: "SAO FELIX DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5278",
    DESCRICAO: "SAO MIGUEL DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5279",
    DESCRICAO: "SAO SALVADOR DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5280",
    DESCRICAO: "SAO SEBASTIAO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5281",
    DESCRICAO: "SAO VALERIO DA NATIVIDADE",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5282",
    DESCRICAO: "SILVANOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5283",
    DESCRICAO: "SITIO NOVO DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5284",
    DESCRICAO: "SUCUPIRA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5285",
    DESCRICAO: "TAGUATINGA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5286",
    DESCRICAO: "TAIPAS DO TOCANTINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5287",
    DESCRICAO: "TALISMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5288",
    DESCRICAO: "PALMAS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5289",
    DESCRICAO: "TOCANTINIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5290",
    DESCRICAO: "TOCANTINOPOLIS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5291",
    DESCRICAO: "TUPIRAMA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5292",
    DESCRICAO: "TUPIRATINS",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5293",
    DESCRICAO: "WANDERLANDIA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5294",
    DESCRICAO: "XAMBIOA",
    SIGLA: "TO",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5295",
    DESCRICAO: "ACAILANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5296",
    DESCRICAO: "AFONSO CUNHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5297",
    DESCRICAO: "AGUA DOCE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5298",
    DESCRICAO: "ALCANTARA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5299",
    DESCRICAO: "ALDEIAS ALTAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5300",
    DESCRICAO: "ALTAMIRA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5301",
    DESCRICAO: "ALTO ALEGRE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5302",
    DESCRICAO: "ALTO ALEGRE DO PINDARE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5303",
    DESCRICAO: "ALTO PARNAIBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5304",
    DESCRICAO: "AMAPA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5305",
    DESCRICAO: "AMARANTE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5306",
    DESCRICAO: "ANAJATUBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5307",
    DESCRICAO: "ANAPURUS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5308",
    DESCRICAO: "APICUM-ACU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5309",
    DESCRICAO: "ARAGUANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5310",
    DESCRICAO: "ARAIOSES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5311",
    DESCRICAO: "ARAME",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5312",
    DESCRICAO: "ARARI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5313",
    DESCRICAO: "AXIXA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5314",
    DESCRICAO: "BACABAL",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5315",
    DESCRICAO: "BACABEIRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5316",
    DESCRICAO: "BACURI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5317",
    DESCRICAO: "BACURITUBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5318",
    DESCRICAO: "BALSAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5319",
    DESCRICAO: "BARAO DE GRAJAU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5320",
    DESCRICAO: "BARRA DO CORDA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5321",
    DESCRICAO: "BARREIRINHAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5322",
    DESCRICAO: "BELAGUA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5323",
    DESCRICAO: "BELA VISTA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5324",
    DESCRICAO: "BENEDITO LEITE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5325",
    DESCRICAO: "BEQUIMAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5326",
    DESCRICAO: "BERNARDO DO MEARIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5327",
    DESCRICAO: "BOA VISTA DO GURUPI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5328",
    DESCRICAO: "BOM JARDIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5329",
    DESCRICAO: "BOM JESUS DAS SELVAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5330",
    DESCRICAO: "BOM LUGAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5331",
    DESCRICAO: "BREJO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5332",
    DESCRICAO: "BREJO DE AREIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5333",
    DESCRICAO: "BURITI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5334",
    DESCRICAO: "BURITI BRAVO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5335",
    DESCRICAO: "BURITICUPU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5336",
    DESCRICAO: "BURITIRANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5337",
    DESCRICAO: "CACHOEIRA GRANDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5338",
    DESCRICAO: "CAJAPIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5339",
    DESCRICAO: "CAJARI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5340",
    DESCRICAO: "CAMPESTRE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5341",
    DESCRICAO: "CANDIDO MENDES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5342",
    DESCRICAO: "CANTANHEDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5343",
    DESCRICAO: "CAPINZAL DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5344",
    DESCRICAO: "CAROLINA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5345",
    DESCRICAO: "CARUTAPERA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5346",
    DESCRICAO: "CAXIAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5347",
    DESCRICAO: "CEDRAL",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5348",
    DESCRICAO: "CENTRAL DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5349",
    DESCRICAO: "CENTRO DO GUILHERME",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5350",
    DESCRICAO: "CENTRO NOVO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5351",
    DESCRICAO: "CHAPADINHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5352",
    DESCRICAO: "CIDELANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5353",
    DESCRICAO: "CODO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5354",
    DESCRICAO: "COELHO NETO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5355",
    DESCRICAO: "COLINAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5356",
    DESCRICAO: "CONCEICAO DO LAGO-ACU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5357",
    DESCRICAO: "COROATA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5358",
    DESCRICAO: "CURURUPU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5359",
    DESCRICAO: "DAVINOPOLIS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5360",
    DESCRICAO: "DOM PEDRO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5361",
    DESCRICAO: "DUQUE BACELAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5362",
    DESCRICAO: "ESPERANTINOPOLIS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5363",
    DESCRICAO: "ESTREITO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5364",
    DESCRICAO: "FEIRA NOVA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5365",
    DESCRICAO: "FERNANDO FALCAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5366",
    DESCRICAO: "FORMOSA DA SERRA NEGRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5367",
    DESCRICAO: "FORTALEZA DOS NOGUEIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5368",
    DESCRICAO: "FORTUNA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5369",
    DESCRICAO: "GODOFREDO VIANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5370",
    DESCRICAO: "GONCALVES DIAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5371",
    DESCRICAO: "GOVERNADOR ARCHER",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5372",
    DESCRICAO: "GOVERNADOR EDSON LOBAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5373",
    DESCRICAO: "GOVERNADOR EUGENIO BARROS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5374",
    DESCRICAO: "GOVERNADOR LUIZ ROCHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5375",
    DESCRICAO: "GOVERNADOR NEWTON BELLO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5376",
    DESCRICAO: "GOVERNADOR NUNES FREIRE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5377",
    DESCRICAO: "GRACA ARANHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5378",
    DESCRICAO: "GRAJAU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5379",
    DESCRICAO: "GUIMARAES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5380",
    DESCRICAO: "HUMBERTO DE CAMPOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5381",
    DESCRICAO: "ICATU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5382",
    DESCRICAO: "IGARAPE DO MEIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5383",
    DESCRICAO: "IGARAPE GRANDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5384",
    DESCRICAO: "IMPERATRIZ",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5385",
    DESCRICAO: "ITAIPAVA DO GRAJAU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5386",
    DESCRICAO: "ITAPECURU MIRIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5387",
    DESCRICAO: "ITINGA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5388",
    DESCRICAO: "JATOBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5389",
    DESCRICAO: "JENIPAPO DOS VIEIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5390",
    DESCRICAO: "JOAO LISBOA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5391",
    DESCRICAO: "JOSELANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5392",
    DESCRICAO: "JUNCO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5393",
    DESCRICAO: "LAGO DA PEDRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5394",
    DESCRICAO: "LAGO DO JUNCO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5395",
    DESCRICAO: "LAGO VERDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5396",
    DESCRICAO: "LAGOA DO MATO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5397",
    DESCRICAO: "LAGO DOS RODRIGUES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5398",
    DESCRICAO: "LAGOA GRANDE DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5399",
    DESCRICAO: "LAJEADO NOVO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5400",
    DESCRICAO: "LIMA CAMPOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5401",
    DESCRICAO: "LORETO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5402",
    DESCRICAO: "LUIS DOMINGUES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5403",
    DESCRICAO: "MAGALHAES DE ALMEIDA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5404",
    DESCRICAO: "MARACACUME",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5405",
    DESCRICAO: "MARAJA DO SENA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5406",
    DESCRICAO: "MARANHAOZINHO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5407",
    DESCRICAO: "MATA ROMA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5408",
    DESCRICAO: "MATINHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5409",
    DESCRICAO: "MATOES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5410",
    DESCRICAO: "MATOES DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5411",
    DESCRICAO: "MILAGRES DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5412",
    DESCRICAO: "MIRADOR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5413",
    DESCRICAO: "MIRANDA DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5414",
    DESCRICAO: "MIRINZAL",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5415",
    DESCRICAO: "MONCAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5416",
    DESCRICAO: "MONTES ALTOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5417",
    DESCRICAO: "MORROS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5418",
    DESCRICAO: "NINA RODRIGUES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5419",
    DESCRICAO: "NOVA COLINAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5420",
    DESCRICAO: "NOVA IORQUE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5421",
    DESCRICAO: "NOVA OLINDA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5422",
    DESCRICAO: "OLHO D AGUA DAS CUNHAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5423",
    DESCRICAO: "OLINDA NOVA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5424",
    DESCRICAO: "PACO DO LUMIAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5425",
    DESCRICAO: "PALMEIRANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5426",
    DESCRICAO: "PARAIBANO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5427",
    DESCRICAO: "PARNARAMA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5428",
    DESCRICAO: "PASSAGEM FRANCA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5429",
    DESCRICAO: "PASTOS BONS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5430",
    DESCRICAO: "PAULINO NEVES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5431",
    DESCRICAO: "PAULO RAMOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5432",
    DESCRICAO: "PEDREIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5433",
    DESCRICAO: "PEDRO DO ROSARIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5434",
    DESCRICAO: "PENALVA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5435",
    DESCRICAO: "PERI MIRIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5436",
    DESCRICAO: "PERITORO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5437",
    DESCRICAO: "PINDARE MIRIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5438",
    DESCRICAO: "PINHEIRO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5439",
    DESCRICAO: "PIO XII",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5440",
    DESCRICAO: "PIRAPEMAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5441",
    DESCRICAO: "POCAO DE PEDRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5442",
    DESCRICAO: "PORTO FRANCO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5443",
    DESCRICAO: "PORTO RICO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5444",
    DESCRICAO: "PRESIDENTE DUTRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5445",
    DESCRICAO: "PRESIDENTE JUSCELINO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5446",
    DESCRICAO: "PRESIDENTE MEDICI",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5447",
    DESCRICAO: "PRESIDENTE SARNEY",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5448",
    DESCRICAO: "PRESIDENTE VARGAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5449",
    DESCRICAO: "PRIMEIRA CRUZ",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5450",
    DESCRICAO: "RAPOSA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5451",
    DESCRICAO: "RIACHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5452",
    DESCRICAO: "RIBAMAR FIQUENE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5453",
    DESCRICAO: "ROSARIO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5454",
    DESCRICAO: "SAMBAIBA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5455",
    DESCRICAO: "SANTA FILOMENA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5456",
    DESCRICAO: "SANTA HELENA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5457",
    DESCRICAO: "SANTA INES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5458",
    DESCRICAO: "SANTA LUZIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5459",
    DESCRICAO: "SANTA LUZIA DO PARUA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5460",
    DESCRICAO: "SANTA QUITERIA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5461",
    DESCRICAO: "SANTA RITA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5462",
    DESCRICAO: "SANTANA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5463",
    DESCRICAO: "SANTO AMARO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5464",
    DESCRICAO: "SANTO ANTONIO DOS LOPES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5465",
    DESCRICAO: "SAO BENEDITO DO RIO PRETO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5466",
    DESCRICAO: "SAO BENTO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5467",
    DESCRICAO: "SAO BERNARDO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5468",
    DESCRICAO: "SAO DOMINGOS DO AZEITAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5469",
    DESCRICAO: "SAO DOMINGOS DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5470",
    DESCRICAO: "SAO FELIX DE BALSAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5471",
    DESCRICAO: "SAO FRANCISCO DO BREJAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5472",
    DESCRICAO: "SAO FRANCISCO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5473",
    DESCRICAO: "SAO JOAO BATISTA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5474",
    DESCRICAO: "SAO JOAO DO CARU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5475",
    DESCRICAO: "SAO JOAO DO PARAISO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5476",
    DESCRICAO: "SAO JOAO DO SOTER",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5477",
    DESCRICAO: "SAO JOAO DOS PATOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5478",
    DESCRICAO: "SAO JOSE DE RIBAMAR",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5479",
    DESCRICAO: "SAO JOSE DOS BASILIOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5480",
    DESCRICAO: "SAO LUIS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5481",
    DESCRICAO: "SAO LUIS GONZAGA DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5482",
    DESCRICAO: "SAO MATEUS DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5483",
    DESCRICAO: "SAO PEDRO DA AGUA BRANCA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5484",
    DESCRICAO: "SAO PEDRO DOS CRENTES",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5485",
    DESCRICAO: "SAO RAIMUNDO DAS MANGABEIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5486",
    DESCRICAO: "SAO RAIMUNDO DO DOCA BEZERRA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5487",
    DESCRICAO: "SAO ROBERTO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5488",
    DESCRICAO: "SAO VICENTE FERRER",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5489",
    DESCRICAO: "SATUBINHA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5490",
    DESCRICAO: "SENADOR ALEXANDRE COSTA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5491",
    DESCRICAO: "SENADOR LA ROQUE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5492",
    DESCRICAO: "SERRANO DO MARANHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5493",
    DESCRICAO: "SITIO NOVO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5494",
    DESCRICAO: "SUCUPIRA DO NORTE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5495",
    DESCRICAO: "SUCUPIRA DO RIACHAO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5496",
    DESCRICAO: "TASSO FRAGOSO",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5497",
    DESCRICAO: "TIMBIRAS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5498",
    DESCRICAO: "TIMON",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5499",
    DESCRICAO: "TRIZIDELA DO VALE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5500",
    DESCRICAO: "TUFILANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5501",
    DESCRICAO: "TUNTUM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5502",
    DESCRICAO: "TURIACU",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5503",
    DESCRICAO: "TURILANDIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5504",
    DESCRICAO: "TUTOIA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5505",
    DESCRICAO: "URBANO SANTOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5506",
    DESCRICAO: "VARGEM GRANDE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5507",
    DESCRICAO: "VIANA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5508",
    DESCRICAO: "VILA NOVA DOS MARTIRIOS",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5509",
    DESCRICAO: "VITORIA DO MEARIM",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5510",
    DESCRICAO: "VITORINO FREIRE",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5511",
    DESCRICAO: "ZE DOCA",
    SIGLA: "MA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5512",
    DESCRICAO: "SERRA DO NAVIO",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5513",
    DESCRICAO: "AMAPA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5514",
    DESCRICAO: "PEDRA BRANCA DO AMAPARI",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5515",
    DESCRICAO: "CALCOENE",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5516",
    DESCRICAO: "CUTIAS",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5517",
    DESCRICAO: "FERREIRA GOMES",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5518",
    DESCRICAO: "ITAUBAL",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5519",
    DESCRICAO: "LARANJAL DO JARI",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5520",
    DESCRICAO: "MACAPA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5521",
    DESCRICAO: "MAZAGAO",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5522",
    DESCRICAO: "OIAPOQUE",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5523",
    DESCRICAO: "PORTO GRANDE",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5524",
    DESCRICAO: "PRACUUBA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5525",
    DESCRICAO: "SANTANA",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5526",
    DESCRICAO: "TARTARUGALZINHO",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5527",
    DESCRICAO: "VITORIA DO JARI",
    SIGLA: "AP",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5528",
    DESCRICAO: "AMAJARI",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5529",
    DESCRICAO: "ALTO ALEGRE",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5530",
    DESCRICAO: "BOA VISTA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5531",
    DESCRICAO: "BONFIM",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5532",
    DESCRICAO: "CANTA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5533",
    DESCRICAO: "CARACARAI",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5534",
    DESCRICAO: "CAROEBE",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5535",
    DESCRICAO: "IRACEMA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5536",
    DESCRICAO: "MUCAJAI",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5537",
    DESCRICAO: "NORMANDIA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5538",
    DESCRICAO: "PACARAIMA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5539",
    DESCRICAO: "RORAINOPOLIS",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5540",
    DESCRICAO: "SAO JOAO DA BALIZA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5541",
    DESCRICAO: "SAO LUIZ",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5542",
    DESCRICAO: "UIRAMUTA",
    SIGLA: "RR",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5543",
    DESCRICAO: "ACRELANDIA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5544",
    DESCRICAO: "ASSIS BRASIL",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5545",
    DESCRICAO: "BRASILEIA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5546",
    DESCRICAO: "BUJARI",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5547",
    DESCRICAO: "CAPIXABA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5548",
    DESCRICAO: "CRUZEIRO DO SUL",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5549",
    DESCRICAO: "EPITACIOLANDIA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5550",
    DESCRICAO: "FEIJO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5551",
    DESCRICAO: "JORDAO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5552",
    DESCRICAO: "MANCIO LIMA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5553",
    DESCRICAO: "MANOEL URBANO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5554",
    DESCRICAO: "MARECHAL THAUMATURGO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5555",
    DESCRICAO: "PLACIDO DE CASTRO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5556",
    DESCRICAO: "PORTO WALTER",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5557",
    DESCRICAO: "RIO BRANCO",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5558",
    DESCRICAO: "RODRIGUES ALVES",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5559",
    DESCRICAO: "SANTA ROSA DO PURUS",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5560",
    DESCRICAO: "SENADOR GUIOMARD",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5561",
    DESCRICAO: "SENA MADUREIRA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5562",
    DESCRICAO: "TARAUACA",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5563",
    DESCRICAO: "XAPURI",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5564",
    DESCRICAO: "PORTO ACRE",
    SIGLA: "AC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5566",
    DESCRICAO: "SEVILHA",
    SIGLA: "EX",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5567",
    DESCRICAO: "BARCELONA",
    SIGLA: "EX",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5568",
    DESCRICAO: "PESCARIA BRAVA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5569",
    DESCRICAO: "JOCA CLAUDINO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5570",
    DESCRICAO: "PARAISO DAS AGUAS",
    SIGLA: "MS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5571",
    DESCRICAO: "SERIDO",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5572",
    DESCRICAO: "PINTO BANDEIRA",
    SIGLA: "RS",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5573",
    DESCRICAO: "MOJUI DOS CAMPOS",
    SIGLA: "PA",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5574",
    DESCRICAO: "TACIMA",
    SIGLA: "PB",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5575",
    DESCRICAO: "NAZARIA",
    SIGLA: "PI",
  },
  {
    DOMINIO: "MUNICIPIO",
    CODIGO: "5576",
    DESCRICAO: "BALNEARIO RINCAO",
    SIGLA: "SC",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3188",
    DESCRICAO: "AFEGANE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3189",
    DESCRICAO: "ALBANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3190",
    DESCRICAO: "ALEMÃ",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3191",
    DESCRICAO: "ANDORRANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3192",
    DESCRICAO: "ANGOLANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3193",
    DESCRICAO: "ANGUILANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3194",
    DESCRICAO: "ANTIGUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3195",
    DESCRICAO: "ARABE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3196",
    DESCRICAO: "ARGELINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3197",
    DESCRICAO: "ARGENTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3198",
    DESCRICAO: "ARMENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3199",
    DESCRICAO: "ARUBANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3200",
    DESCRICAO: "AUSTRALIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3201",
    DESCRICAO: "AUSTRIACA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3202",
    DESCRICAO: "AZERBAIDJANO",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3203",
    DESCRICAO: "BAHAMENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3204",
    DESCRICAO: "BARBADIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3205",
    DESCRICAO: "BARENITA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3206",
    DESCRICAO: "BELGA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3207",
    DESCRICAO: "BELIZENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3208",
    DESCRICAO: "BENGALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3209",
    DESCRICAO: "BENINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3210",
    DESCRICAO: "BERMUDIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3211",
    DESCRICAO: "BIELORRUSSA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3212",
    DESCRICAO: "BIRMANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3213",
    DESCRICAO: "BOLIVIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3214",
    DESCRICAO: "BÓSNIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3215",
    DESCRICAO: "BOTSUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3216",
    DESCRICAO: "BRASILEIRA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3217",
    DESCRICAO: "BRITÂNICA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3218",
    DESCRICAO: "BRUNEIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3219",
    DESCRICAO: "BÚLGARA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3220",
    DESCRICAO: "BURQUINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3221",
    DESCRICAO: "BURUNDINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3222",
    DESCRICAO: "BUTANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3223",
    DESCRICAO: "CABO-VERDIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3224",
    DESCRICAO: "CAIMANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3225",
    DESCRICAO: "CAMARONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3226",
    DESCRICAO: "CAMBOJANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3227",
    DESCRICAO: "CANADENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3228",
    DESCRICAO: "CATARIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3229",
    DESCRICAO: "CAZAQUE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3230",
    DESCRICAO: "CENTRO-AFRICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3231",
    DESCRICAO: "CHADIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3232",
    DESCRICAO: "CHILENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3233",
    DESCRICAO: "CHINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3234",
    DESCRICAO: "CHIPREANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3235",
    DESCRICAO: "CINGALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3236",
    DESCRICAO: "COLOMBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3237",
    DESCRICAO: "COMORENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3238",
    DESCRICAO: "CONGOLESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3239",
    DESCRICAO: "COOKENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3240",
    DESCRICAO: "COSTARRIQUENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3241",
    DESCRICAO: "COVEITIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3242",
    DESCRICAO: "CROATA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3243",
    DESCRICAO: "CUBANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3244",
    DESCRICAO: "DINAMARQUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3245",
    DESCRICAO: "DJIBUTIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3246",
    DESCRICAO: "DOMINICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3247",
    DESCRICAO: "EGIPCIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3248",
    DESCRICAO: "EQUATORIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3249",
    DESCRICAO: "ERITREIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3250",
    DESCRICAO: "ESLOVACA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3251",
    DESCRICAO: "ESLOVENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3252",
    DESCRICAO: "ESPANHOLA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3253",
    DESCRICAO: "ESTONIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3254",
    DESCRICAO: "ETIOPE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3255",
    DESCRICAO: "FEROESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3256",
    DESCRICAO: "FIJIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3257",
    DESCRICAO: "FILIPINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3258",
    DESCRICAO: "FINLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3259",
    DESCRICAO: "FRANCESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3260",
    DESCRICAO: "GABONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3261",
    DESCRICAO: "GAMBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3262",
    DESCRICAO: "GANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3263",
    DESCRICAO: "GEORGIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3264",
    DESCRICAO: "GIBRALTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3265",
    DESCRICAO: "GRANADINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3266",
    DESCRICAO: "GREGA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3267",
    DESCRICAO: "GROENLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3268",
    DESCRICAO: "GUADALUPENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3269",
    DESCRICAO: "GUAMESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3270",
    DESCRICAO: "GUATEMALTECA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3271",
    DESCRICAO: "GUERNESIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3272",
    DESCRICAO: "GUIANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3273",
    DESCRICAO: "GUIANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3274",
    DESCRICAO: "GUINEANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3275",
    DESCRICAO: "GUINEENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3276",
    DESCRICAO: "GUINÉU-EQUATORIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3277",
    DESCRICAO: "HAITIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3278",
    DESCRICAO: "HOLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3279",
    DESCRICAO: "HONDURENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3280",
    DESCRICAO: "HÚNGARA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3281",
    DESCRICAO: "IEMENITA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3282",
    DESCRICAO: "INDIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3283",
    DESCRICAO: "INDONÉSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3284",
    DESCRICAO: "IRANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3285",
    DESCRICAO: "IRAQUIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3286",
    DESCRICAO: "IRLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3287",
    DESCRICAO: "ISLANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3288",
    DESCRICAO: "ISRAELENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3289",
    DESCRICAO: "ITALIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3290",
    DESCRICAO: "JAMAICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3291",
    DESCRICAO: "JAPONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3292",
    DESCRICAO: "JERSIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3293",
    DESCRICAO: "JORDANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3294",
    DESCRICAO: "KIRIBATIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3295",
    DESCRICAO: "LAOSIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3296",
    DESCRICAO: "LEONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3297",
    DESCRICAO: "LESOTA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3298",
    DESCRICAO: "LETÃ",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3299",
    DESCRICAO: "LIBANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3300",
    DESCRICAO: "LIBERIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3301",
    DESCRICAO: "LÍBIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3302",
    DESCRICAO: "LIECHTENSTEINIENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3303",
    DESCRICAO: "LITUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3304",
    DESCRICAO: "LUXEMBURGUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3305",
    DESCRICAO: "MACEDÔNIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3306",
    DESCRICAO: "MAIOTENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3307",
    DESCRICAO: "MALAIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3308",
    DESCRICAO: "MALAUIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3309",
    DESCRICAO: "MALDÍVIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3310",
    DESCRICAO: "MALGAXE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3311",
    DESCRICAO: "MALINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3312",
    DESCRICAO: "MALTESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3313",
    DESCRICAO: "MALVINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3314",
    DESCRICAO: "MANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3315",
    DESCRICAO: "MARFINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3316",
    DESCRICAO: "MARROQUINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3317",
    DESCRICAO: "MARSHALLINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3318",
    DESCRICAO: "MARTINICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3319",
    DESCRICAO: "MAURICIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3320",
    DESCRICAO: "MAURITANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3321",
    DESCRICAO: "MEXICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3322",
    DESCRICAO: "MICRONÉSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3323",
    DESCRICAO: "MOÇAMBICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3324",
    DESCRICAO: "MOLDÁVIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3325",
    DESCRICAO: "MONEGASCA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3326",
    DESCRICAO: "MONGOL",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3327",
    DESCRICAO: "MONSERRATENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3328",
    DESCRICAO: "MONTENEGRINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3329",
    DESCRICAO: "NAMIBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3330",
    DESCRICAO: "NAURUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3331",
    DESCRICAO: "NEOCALEDÔNIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3332",
    DESCRICAO: "NEOZELANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3333",
    DESCRICAO: "NEPALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3334",
    DESCRICAO: "NICARAGUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3335",
    DESCRICAO: "NIGERIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3336",
    DESCRICAO: "NIGERINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3337",
    DESCRICAO: "NIUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3338",
    DESCRICAO: "NORFOLKIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3339",
    DESCRICAO: "NORTEAMERICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3340",
    DESCRICAO: "NORTE-COREANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3341",
    DESCRICAO: "NORTE-MARIANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3342",
    DESCRICAO: "NORUEGUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3343",
    DESCRICAO: "OMANENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3344",
    DESCRICAO: "PALAUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3345",
    DESCRICAO: "PALESTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3346",
    DESCRICAO: "PANAMENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3347",
    DESCRICAO: "PAPUÁSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3348",
    DESCRICAO: "PAQUISTANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3349",
    DESCRICAO: "PARAGUAIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3350",
    DESCRICAO: "PERUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3351",
    DESCRICAO: "PITCAIRNENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3352",
    DESCRICAO: "POLINÉSIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3353",
    DESCRICAO: "POLONESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3354",
    DESCRICAO: "PORTORRIQUENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3355",
    DESCRICAO: "PORTUGUESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3356",
    DESCRICAO: "QUENIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3357",
    DESCRICAO: "QUIRGUISTANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3358",
    DESCRICAO: "REUNIONENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3359",
    DESCRICAO: "ROMENA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3360",
    DESCRICAO: "RUANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3361",
    DESCRICAO: "RUSSA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3362",
    DESCRICAO: "SAARIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3363",
    DESCRICAO: "SALOMÔNICA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3364",
    DESCRICAO: "SALVADORENHA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3365",
    DESCRICAO: "SAMARINESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3366",
    DESCRICAO: "SAMOANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3367",
    DESCRICAO: "SANTA-HELENENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3368",
    DESCRICAO: "SANTA-LUCENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3369",
    DESCRICAO: "SÃO-CRISTOVENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3370",
    DESCRICAO: "SÃO-PEDRO-MIQUELONENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3371",
    DESCRICAO: "SÃO-TOMENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3372",
    DESCRICAO: "SÃO-VICENTINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3373",
    DESCRICAO: "SEICHELENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3374",
    DESCRICAO: "SENEGALESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3375",
    DESCRICAO: "SERVIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3376",
    DESCRICAO: "SINGAPURENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3377",
    DESCRICAO: "SIRIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3378",
    DESCRICAO: "SOMALI",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3379",
    DESCRICAO: "SUAZI",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3380",
    DESCRICAO: "SUDANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3381",
    DESCRICAO: "SUECA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3382",
    DESCRICAO: "SUIÇA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3383",
    DESCRICAO: "SUL-AFRICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3384",
    DESCRICAO: "SUL-COREANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3385",
    DESCRICAO: "SURINAMESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3386",
    DESCRICAO: "TADJIQUE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3387",
    DESCRICAO: "TAILANDESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3388",
    DESCRICAO: "TAIUANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3389",
    DESCRICAO: "TANZANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3390",
    DESCRICAO: "TCHECA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3391",
    DESCRICAO: "TIMORENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3392",
    DESCRICAO: "TOBAGUIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3393",
    DESCRICAO: "TOGOLESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3394",
    DESCRICAO: "TONGANESA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3395",
    DESCRICAO: "TOQUELAUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3396",
    DESCRICAO: "TUNISIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3397",
    DESCRICAO: "TURCA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3398",
    DESCRICAO: "TURCO-CAIQUENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3399",
    DESCRICAO: "TURCOMANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3400",
    DESCRICAO: "TUVALUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3401",
    DESCRICAO: "UCRANIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3402",
    DESCRICAO: "UGANDENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3403",
    DESCRICAO: "URUGUAIA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3404",
    DESCRICAO: "UZBEQUE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3405",
    DESCRICAO: "VANUATENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3406",
    DESCRICAO: "VATICANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3407",
    DESCRICAO: "VENEZUELANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3408",
    DESCRICAO: "VIETNAMINA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3409",
    DESCRICAO: "VIRGINENSE",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3410",
    DESCRICAO: "ZAMBIANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "3411",
    DESCRICAO: "ZIMBABUANA",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "5046",
    DESCRICAO: "SUDANESA DO SUL",
  },
  {
    DOMINIO: "NACIONALIDADE",
    CODIGO: "5047",
    DESCRICAO: "SUDANESA DO SUL",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4140",
    DESCRICAO: "Empresário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4142",
    DESCRICAO: "Micro Empresário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4144",
    DESCRICAO: "Diretor de empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4146",
    DESCRICAO: "Superintendente de empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4148",
    DESCRICAO: "Supervisor em empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4149",
    DESCRICAO: "Vereador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4150",
    DESCRICAO: "Prefeito",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4151",
    DESCRICAO: "Promotor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4152",
    DESCRICAO: "Funcionário do alto escalão do poder executivo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4153",
    DESCRICAO: "Engenheiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4154",
    DESCRICAO: "Pesquisador das Ciências Exatas, Humanas e Biológicas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4155",
    DESCRICAO: "Médico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4156",
    DESCRICAO: "Odontologista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4157",
    DESCRICAO: "Enfermeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4158",
    DESCRICAO: "Farmacêutico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4159",
    DESCRICAO: "Veterinário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4160",
    DESCRICAO: "Estilista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4161",
    DESCRICAO: "Cartógrafo e Agrimensor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4163",
    DESCRICAO: "Professor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4164",
    DESCRICAO: "Vendedor especializado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4165",
    DESCRICAO: "Comerciante",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4166",
    DESCRICAO: "Caixa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4167",
    DESCRICAO: "Assistente de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4168",
    DESCRICAO: "Assistente de nível técnico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4169",
    DESCRICAO: "Tecnólogo de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4170",
    DESCRICAO: "Tecnólogo de nível médio",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4171",
    DESCRICAO: "Advogado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4172",
    DESCRICAO: "Economista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4173",
    DESCRICAO: "Líder religioso",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4174",
    DESCRICAO: "Locutor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4175",
    DESCRICAO: "Jornalista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4176",
    DESCRICAO: "Tradutor e linguista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4178",
    DESCRICAO: "Membro das forças armadas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4180",
    DESCRICAO: "Agente da polícia civil ou federal",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4181",
    DESCRICAO: "Avaliador de bens e valores",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4182",
    DESCRICAO: "Agente financeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4183",
    DESCRICAO: "Piloto",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4184",
    DESCRICAO: "Atleta, esportista, treinador e arbitro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4186",
    DESCRICAO: "Fotógrafo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4187",
    DESCRICAO: "Chefe de cozinha",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4188",
    DESCRICAO: "Fazendeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4189",
    DESCRICAO: "Profissional qualificado do agronegócio",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4190",
    DESCRICAO: "Lavrador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4191",
    DESCRICAO: "Esteticista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4192",
    DESCRICAO: "Profissional  de manutenção , limpeza ou segurança",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4193",
    DESCRICAO: "Profissional de arte, publicidade ou moda",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4194",
    DESCRICAO: "Motorista especializado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4195",
    DESCRICAO: "Auxiliar administrativo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4196",
    DESCRICAO: "Funcionário de lotérica",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4197",
    DESCRICAO: "Operador de máquinas e equipamentos",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4198",
    DESCRICAO: "Cobrador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4199",
    DESCRICAO: "Agente de viagem",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4200",
    DESCRICAO: "Telefonista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4201",
    DESCRICAO: "Encarregado geral",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4203",
    DESCRICAO: "Mecânico de motores, máquinas e equipamentos",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4204",
    DESCRICAO: "Funcionário dos correios",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4205",
    DESCRICAO: "Operador de telemarketing",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4206",
    DESCRICAO: "Doméstico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4207",
    DESCRICAO: "Faxineiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4208",
    DESCRICAO: "Servente ou ajudante construção civil",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4209",
    DESCRICAO: "Repositor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4210",
    DESCRICAO: "Demais ocupações não qualificadas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4735",
    DESCRICAO: "Administrador de empresas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4736",
    DESCRICAO: "Aeronauta",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4737",
    DESCRICAO: "Aeroviário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4738",
    DESCRICAO: "Agronomo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4739",
    DESCRICAO: "Analista de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4740",
    DESCRICAO: "Analista de nível técnico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4741",
    DESCRICAO: "Arquiteto",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4742",
    DESCRICAO: "Assitente de cozinha",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4743",
    DESCRICAO: "Atendente",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4744",
    DESCRICAO: "Balconista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4745",
    DESCRICAO: "Bombeiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4746",
    DESCRICAO: "Carregador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4747",
    DESCRICAO: "Confeiteiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4748",
    DESCRICAO: "Corretor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4749",
    DESCRICAO: "Decorador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4750",
    DESCRICAO: "Delegado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4751",
    DESCRICAO: "Deputado",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4753",
    DESCRICAO: "Desenhista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4754",
    DESCRICAO: "Designer",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4755",
    DESCRICAO: "Empacotador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4756",
    DESCRICAO: "Entregadores e operadores de logística",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4757",
    DESCRICAO: "Escritor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4758",
    DESCRICAO: "Especialista de nível superior",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4759",
    DESCRICAO: "Especialista de nível técnico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4760",
    DESCRICAO: "Fisioterapeuta",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4761",
    DESCRICAO: "Fonoaudiológo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4762",
    DESCRICAO: "Funcionário do alto escalão do poder judiciário",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4763",
    DESCRICAO: "Funcionário do alto escalão do poder legislativo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4764",
    DESCRICAO: "Gerente geral de empresa",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4765",
    DESCRICAO: "Governador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4766",
    DESCRICAO: "Instrumentador cirurgico",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4767",
    DESCRICAO: "Instrutor",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4768",
    DESCRICAO: "Investigador da polícia civil ou federal",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4769",
    DESCRICAO: "Jardineiro",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4770",
    DESCRICAO: "Juiz",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4771",
    DESCRICAO: "Jurista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4772",
    DESCRICAO: "Nutricionista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4773",
    DESCRICAO: "Pecuarista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4774",
    DESCRICAO: "Pedagogo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4775",
    DESCRICAO: "Policial",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4776",
    DESCRICAO: "Presidente",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4777",
    DESCRICAO: "Produtor Rural",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4778",
    DESCRICAO: "Profissional qualificado do ramo alimentar",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4779",
    DESCRICAO: "Profissional qualificado do ramo estética",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4780",
    DESCRICAO: "Protético",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4781",
    DESCRICAO: "Psicologo",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4782",
    DESCRICAO: "Radiologista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4783",
    DESCRICAO: "Recepcionista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4784",
    DESCRICAO: "Senador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4785",
    DESCRICAO: "Técnico e profissional qualificado da saúde",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4787",
    DESCRICAO: "Vendedor de lojas",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4788",
    DESCRICAO: "Zootecnista",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4790",
    DESCRICAO: "Tratador de animais",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4791",
    DESCRICAO: "Desembargador",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4792",
    DESCRICAO: "MVTESTE",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4793",
    DESCRICAO: "Empres. fat/ano acima R$200MM",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4794",
    DESCRICAO: "Empres. fat/ano entre R$50 e R$200MM",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4795",
    DESCRICAO: "Empres. fat/ano entre R$1 e R$10 MM",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4796",
    DESCRICAO: "Dir. e Dirig Geral de Emp Púb/Púb-Priv",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4797",
    DESCRICAO: "Sup e Ger de Emp Púb/Púb-Priv",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4798",
    DESCRICAO: "Superv. e Coord de Emp. Púb/Púb-Priv",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4799",
    DESCRICAO: "Prof, Instr e Pedag Nível Sup",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4800",
    DESCRICAO: "Profissionais das Artes",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4801",
    DESCRICAO: "Pç Forças Armadas, Polic e Bomb",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4802",
    DESCRICAO: "Treinadores e Arbitros Ativ Esp",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "4803",
    DESCRICAO: "Operário constr,mecân,elétr",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "5021",
    DESCRICAO: "ESTUDANTE",
  },
  {
    DOMINIO: "OCUPACAO",
    CODIGO: "5043",
    DESCRICAO: "Aposentado",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "825",
    DESCRICAO: "PASSAPORTE",
    SIGLA: "PASS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "826",
    DESCRICAO: "ASSOCIAÇÃO BRASILEIRA DE EX-CONGRESSISTAS",
    SIGLA: "ABEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "827",
    DESCRICAO: "ASSEMBLEIA LEGISLATIVA",
    SIGLA: "ALEG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "828",
    DESCRICAO: "CAMARA MUNICIPAL DO RIO DE JANEIRO",
    SIGLA: "CMRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "829",
    DESCRICAO: "CARTORIO REG CIVIL PESSOAS NATURAIS",
    SIGLA: "CCPN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "830",
    DESCRICAO: "COMISSAO NACIONAL DE ENERGIA NUCLEAR",
    SIGLA: "CNEN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "831",
    DESCRICAO: "CONF NACIONAL DIRIGENTES LOGISTAS",
    SIGLA: "CNDL",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "832",
    DESCRICAO: "Confederação Nacional das Profissões Liberais",
    SIGLA: "CPL",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "833",
    DESCRICAO: "CONSELHO DOS DETETIVES DO BRASIL",
    SIGLA: "CDEB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "834",
    DESCRICAO: "Conselho Federal dos Detetives Profissionais do Brasil",
    SIGLA: "CFDP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "835",
    DESCRICAO: "Conselho Federal de Medicina Veterinária",
    SIGLA: "CFMV",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "836",
    DESCRICAO: "Conselho Federal de Psicologia",
    SIGLA: "CFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "837",
    DESCRICAO: "CONSELHO FEDERAL DE BIOLOGIA",
    SIGLA: "CFB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "838",
    DESCRICAO: "CONSELHO FEDERAL DE ENFERMAGEM",
    SIGLA: "CFE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "839",
    DESCRICAO: "CONSELHO NAC DE ESTATISTICA",
    SIGLA: "CNE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "840",
    DESCRICAO: "CONSELHO REG DA BAHIA",
    SIGLA: "CRBA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "841",
    DESCRICAO: "Conselho Regional de Administração",
    SIGLA: "CRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "842",
    DESCRICAO: "Conselho Regional de Assistentes Sociais",
    SIGLA: "CRAS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "843",
    DESCRICAO: "Conselho Regional de Biblioteconomia",
    SIGLA: "CRB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "844",
    DESCRICAO: "Conselho Regional de Biologia",
    SIGLA: "CRBio",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "845",
    DESCRICAO: "Conselho Regional de Biomedicina",
    SIGLA: "CRBM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "846",
    DESCRICAO: "Conselho Regional de Contabilidade",
    SIGLA: "CRC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "847",
    DESCRICAO: "Conselho Regional de Corretores de Imóveis",
    SIGLA: "CRCI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "848",
    DESCRICAO: "Conselho Regional de Economia",
    SIGLA: "CRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "849",
    DESCRICAO: "Conselho Regional de Educação Física",
    SIGLA: "CREF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "850",
    DESCRICAO: "Conselho Regional de Enfermagem",
    SIGLA: "EF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "851",
    DESCRICAO: "CONSELHO REG DE ENFERMARIA",
    SIGLA: "CREN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "852",
    DESCRICAO: "Conselho Regional de Engenharia Arquitetura e Agronomia",
    SIGLA: "CREA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "853",
    DESCRICAO: "Conselho Regional de Estatística",
    SIGLA: "CONRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "854",
    DESCRICAO: "Conselho Regional de Farmácia",
    SIGLA: "CRF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "855",
    DESCRICAO: "Conselho Regional de Fisioterapia e Terapia Ocupacional",
    SIGLA: "CRFT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "856",
    DESCRICAO: "Conselho Regional de Fonoaudiologia",
    SIGLA: "CRFA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "857",
    DESCRICAO: "CONSELHO REG DE GEOLOGIA",
    SIGLA: "CREG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "858",
    DESCRICAO: "Conselho Regional de Medicina Veterinária",
    SIGLA: "CRMV",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "859",
    DESCRICAO: "Conselho Regional de Medicina",
    SIGLA: "CRM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "860",
    DESCRICAO: "Conselho Regional de Museologia",
    SIGLA: "CRMU",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "861",
    DESCRICAO: "CONSELHO REGIONAL DE NUTRICIONISTAS",
    SIGLA: "CRN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "862",
    DESCRICAO: "Conselho Regional de Odontologia",
    SIGLA: "CRO",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "863",
    DESCRICAO: "Conselho Regional de Psicologia",
    SIGLA: "CRP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "864",
    DESCRICAO: "Conselho Regional de Química",
    SIGLA: "CRQ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "865",
    DESCRICAO: "Conselho Regional de Representantes Comerciais",
    SIGLA: "CRRC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "866",
    DESCRICAO: "CONSELHO REGIONAL DE SERVICO SOCIAL",
    SIGLA: "CRSS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "867",
    DESCRICAO: "Conselho Regional de Técnicos em Radiologia",
    SIGLA: "CRTR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "868",
    DESCRICAO: "Conselho Regional de Profissionais de Relações Públicas",
    SIGLA: "CRRP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "869",
    DESCRICAO: "CONSELHO REG DOS TEC ADMINISTRACAO",
    SIGLA: "CRTA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "870",
    DESCRICAO: "CONSELHO REG FEDERAL PUBLICA",
    SIGLA: "CRFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "871",
    DESCRICAO: "CONSELHO REG MEDICINA ESTETICA",
    SIGLA: "CRME",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "872",
    DESCRICAO: "Corpo de Bombeiros Militar",
    SIGLA: "CBM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "873",
    DESCRICAO: "CORREGEDORIA GERAL DA JUSTICA",
    SIGLA: "CGJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "874",
    DESCRICAO: "DEFENSORIA PUBLICA GERAL DO ESTADO",
    SIGLA: "DPGE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "875",
    DESCRICAO: "DEPARTAMENTO ADMINISTRATIVO",
    SIGLA: "DRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "876",
    DESCRICAO: "DEPARTAMENTO DE INVESTIGACOES",
    SIGLA: "DI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "877",
    DESCRICAO: "DEPARTAMENTO DE POLICIA CIVIL",
    SIGLA: "DPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "878",
    DESCRICAO: "DEPARTAMENTO DE POLICIA FEDERAL",
    SIGLA: "DPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "879",
    DESCRICAO: "DEPARTAMENTO DE POLICIA TECNICO CIENTIFICA",
    SIGLA: "DPTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "880",
    DESCRICAO: "DEPARTAMENTO DE TITULOS FEDERAIS",
    SIGLA: "DTF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "881",
    DESCRICAO: "DEPARTAMENTO DE TRANSITO",
    SIGLA: "DTRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "882",
    DESCRICAO: "Departamento de Correios e Telégrafos",
    SIGLA: "DCT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "883",
    DESCRICAO: "DEPARTAMENTO ESTADUAL DE SEG PUBLICA",
    SIGLA: "DESP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "884",
    DESCRICAO: "DEPARTAMENTO ESTRANGEIRO LEGISLATIVO",
    SIGLA: "DELE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "885",
    DESCRICAO: "DEPARTAMENTO FEDERAL DE SEG PUBLICA",
    SIGLA: "DFSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "886",
    DESCRICAO: "Departamento Nacional de Estrada de Rodagem",
    SIGLA: "DNER",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "887",
    DESCRICAO: "DEPARTAMENTO ESTADUAL DE TRANSITO",
    SIGLA: "DETRAN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "888",
    DESCRICAO: "DIRETORIA GERAL DA POLICIA CIVIL",
    SIGLA: "DGPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "889",
    DESCRICAO: "ESTADO BRASILEIRO",
    SIGLA: "EB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "890",
    DESCRICAO: "FEDERACAO NACIONAL DOS JORNALISTAS",
    SIGLA: "FNJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "891",
    DESCRICAO: "FORCA AEREA BRASILEIRA",
    SIGLA: "FAB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "892",
    DESCRICAO: "FORCAS ARMADAS",
    SIGLA: "DPMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "893",
    DESCRICAO: "GERENCIA EST DE JUSTICA",
    SIGLA: "GEJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "894",
    DESCRICAO: "INST DE CRIMINOLOGIA",
    SIGLA: "IC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "895",
    DESCRICAO: "INST DE IDENT DR AROLDO MENDES DE PAIVA",
    SIGLA: "IAMP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "896",
    DESCRICAO: "INST DE IDENT EST PARANA",
    SIGLA: "IIEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "897",
    DESCRICAO: "INST DE IDENT TECNICA POLICIAL",
    SIGLA: "IITP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "898",
    DESCRICAO: "INST FELIX PACHECO",
    SIGLA: "IFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "899",
    DESCRICAO: "INST NACIONAL DE IDENTIFICACAO",
    SIGLA: "INI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "900",
    DESCRICAO: "Instituto Pereira Faustino",
    SIGLA: "IPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "901",
    DESCRICAO: "INST POLICIA TECNICA",
    SIGLA: "IPT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "902",
    DESCRICAO: "Instituto de Identificação Tavares Buril",
    SIGLA: "ITB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "903",
    DESCRICAO: "INSTITUTO DE ENGENHARIA",
    SIGLA: "IEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "904",
    DESCRICAO: "INSTITUTO DE IDENTIFICACAO DO PARANA",
    SIGLA: "IIPR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "905",
    DESCRICAO: "INSTITUTO FELIX PACHECO",
    SIGLA: "FIP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "906",
    DESCRICAO: "INSTITUTO GERAL DE PERICIAS",
    SIGLA: "IGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "907",
    DESCRICAO: "INSTITUTO INSPECIONAL PARTIDO",
    SIGLA: "IIP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "908",
    DESCRICAO: "INSTITUTO PEREIRA FAUSTINO R",
    SIGLA: "IPFR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "909",
    DESCRICAO: "INSTITUTO TECNICO CIENTIFICO DE POLICIA",
    SIGLA: "ITEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "910",
    DESCRICAO: "MINISTERIO DA AERONAUTICA",
    SIGLA: "MAE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "911",
    DESCRICAO: "MINISTERIO DA AERONAUTICA REPUBLICA",
    SIGLA: "MARJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "912",
    DESCRICAO: "MINISTERIO DA ASSOCIACAO FEDERATIVA",
    SIGLA: "MAF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "913",
    DESCRICAO: "MINISTERIO DA CIENCIA E TECNOLOGIA",
    SIGLA: "MCT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "914",
    DESCRICAO: "MINISTERIO DA CULTURA",
    SIGLA: "MCU",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "915",
    DESCRICAO: "MINISTERIO DA DEFESA",
    SIGLA: "MDEF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "916",
    DESCRICAO: "MINISTERIO DA ECONOMIA FAZENDA PLANEJAMENTO",
    SIGLA: "MEFP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "917",
    DESCRICAO: "MINISTERIO DA EDUCACAO E CULTURA",
    SIGLA: "MEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "918",
    DESCRICAO: "MINISTERIO DA EDUCACAO E DO DESPORTO",
    SIGLA: "MED",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "919",
    DESCRICAO: "MINISTERIO DA EDUCACAO UNIVERSIDADE FEDERAL",
    SIGLA: "MEUF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "920",
    DESCRICAO: "MINISTERIO DA FAZENDA",
    SIGLA: "MFAZ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "921",
    DESCRICAO: "MINISTERIO DA INDUSTRIA MARITIMA",
    SIGLA: "MIM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "922",
    DESCRICAO: "MINISTERIO DA JUSTICA",
    SIGLA: "MJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "923",
    DESCRICAO: "MINISTERIO DA MARINHA",
    SIGLA: "MMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "924",
    DESCRICAO: "MINISTERIO DA MARINHA DEFESA",
    SIGLA: "MMD",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "925",
    DESCRICAO: "MINISTERIO DA MARINHA RIO DE JANEIRO",
    SIGLA: "MMRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "926",
    DESCRICAO: "MINISTERIO DA SAUDE",
    SIGLA: "MS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "927",
    DESCRICAO: "MINISTERIO DAS COMUNICACOES",
    SIGLA: "MC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "928",
    DESCRICAO: "MINISTERIO DAS RELACOES EXTERIORES",
    SIGLA: "MRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "929",
    DESCRICAO: "MINISTERIO DO EXERCITO",
    SIGLA: "MEX",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "930",
    DESCRICAO: "MINISTERIO DO TRABALHO",
    SIGLA: "MT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "931",
    DESCRICAO: "MINISTERIO DOS TRANSPORTES",
    SIGLA: "MTRA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "932",
    DESCRICAO: "MINISTERIO FEDERAL",
    SIGLA: "MF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "933",
    DESCRICAO: "MINISTERIO MARINHA",
    SIGLA: "MMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "934",
    DESCRICAO: "MINISTERIO PUBLICO DISTRITO FED TERRITORIOS",
    SIGLA: "MPDF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "935",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DE MATO GROSSO",
    SIGLA: "MPMT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "936",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DE MINISTERIOAS GERAIS",
    SIGLA: "MPMG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "937",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DE SAO PAULO",
    SIGLA: "MPES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "938",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DO PARANA",
    SIGLA: "MPPR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "939",
    DESCRICAO: "MINISTERIO PUBLICO DO EST DO RIO DE JANEIRO",
    SIGLA: "MPRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "940",
    DESCRICAO: "MINISTERIO PUBLICO DO TRABALHO",
    SIGLA: "MPT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "941",
    DESCRICAO: "MINISTERIO PUBLICO FEDERAL",
    SIGLA: "MPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "942",
    DESCRICAO: "MINISTERIO PUBLICO MILITAR",
    SIGLA: "MPM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "943",
    DESCRICAO: "MINISTERIO SERVICO SOCIAL",
    SIGLA: "MSS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "944",
    DESCRICAO: "MINISTERIO SOCIAL SAUDE",
    SIGLA: "MSS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "945",
    DESCRICAO: "ORDEM DOS ADVOGADOS DO BRASIL",
    SIGLA: "OAB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "946",
    DESCRICAO: "ORDEM DOS MUSICOS DO BRASIL",
    SIGLA: "OMB",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "947",
    DESCRICAO: "PENITENCIARIA INDL DE GUARAPUAVA",
    SIGLA: "PIG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "948",
    DESCRICAO: "PODER JUDICIARIO",
    SIGLA: "PJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "949",
    DESCRICAO: "PODER JUDICIARIO DO EST DE GOIAS",
    SIGLA: "PJEG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "950",
    DESCRICAO: "POLICIA CIVIL INST DE IDENT",
    SIGLA: "PCII",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "951",
    DESCRICAO: "POLICIA DO DISTRITO FED INST DE IDENT",
    SIGLA: "PDFI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "952",
    DESCRICAO: "POLICIA FEDERAL",
    SIGLA: "PFED",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "953",
    DESCRICAO: "POLICIA FERROVIARIA FEDERAL",
    SIGLA: "PFF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "954",
    DESCRICAO: "POLICIA MILITAR",
    SIGLA: "PM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "955",
    DESCRICAO: "POLICIA MILITAR DO RJ",
    SIGLA: "PMRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "956",
    DESCRICAO: "PORT MINISTERIO FEDERAL",
    SIGLA: "PMAF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "957",
    DESCRICAO: "PREFEITURA MUNICIPAL DE PETROPOLIS",
    SIGLA: "PMP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "958",
    DESCRICAO: "PROCURADORIA GERAL DE JUSTICA",
    SIGLA: "PGJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "959",
    DESCRICAO: "PROCURADORIA GERAL DO ESTADO",
    SIGLA: "PGE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "960",
    DESCRICAO: "REDE FERROVIARIA FEDERAL",
    SIGLA: "RFF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "961",
    DESCRICAO: "REGISTRO GERAL",
    SIGLA: "RG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "962",
    DESCRICAO: "REGISTRO GERAL PACHECO",
    SIGLA: "RGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "965",
    DESCRICAO: "SECRETARIA DA CULTURA",
    SIGLA: "SC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "966",
    DESCRICAO: "SECRETARIA DA JUSTICA E SEGURANÇA",
    SIGLA: "SJS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "967",
    DESCRICAO: "SECRETARIA DA RECEITA FEDERAL",
    SIGLA: "SRF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "968",
    DESCRICAO: "SECRETARIA DE ADMIN DEPTO GERAL DE PESSOAL",
    SIGLA: "SAGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "969",
    DESCRICAO: "SECRETARIA DE ESTADO DA DEFESA SOCIAL",
    SIGLA: "SEDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "970",
    DESCRICAO: "SECRETARIA DE EST DA FAZENDA CULTURA",
    SIGLA: "SEF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "971",
    DESCRICAO: "SECRETARIA DE ESTADO DA JUSTICA",
    SIGLA: "SEJU",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "972",
    DESCRICAO: "SECRETARIA DE EST DA POLICIA CIVIL",
    SIGLA: "SEPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "973",
    DESCRICAO: "SECRETARIA DE EST DA SEGURANCA",
    SIGLA: "SES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "974",
    DESCRICAO: "SECRETARIA DE EST DE ADMINISTRACAO",
    SIGLA: "SEA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "975",
    DESCRICAO: "Secretaria de Estado de Educação e Cultura",
    SIGLA: "SEEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "976",
    DESCRICAO: "SECRETARIA DE EST DE JUSTICA E INTERIOR",
    SIGLA: "SEJI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "977",
    DESCRICAO: "SECRETARIA DE EST DE OBRAS E MEIO AMBIENTE",
    SIGLA: "SOMA",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "978",
    DESCRICAO: "SECRETARIA DE EST E JUSTICA DA SEG PUBLICA",
    SIGLA: "SJSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "979",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA",
    SIGLA: "SESP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "980",
    DESCRICAO: "SECRETARIA DE POLICIA E SEG PUBLICA",
    SIGLA: "SPSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "981",
    DESCRICAO: "SECRETARIA DE SEG INFORMACOES",
    SIGLA: "SSI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "982",
    DESCRICAO: "Secretaria de Segurança Pública",
    SIGLA: "SSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "983",
    DESCRICAO: "SECRETARIA DA SEGURANÇA PUBLICA E DEFESA DA CIDADANIA",
    SIGLA: "SPDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "984",
    DESCRICAO: "SECRETARIA DE SEGURANCA",
    SIGLA: "SDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "985",
    DESCRICAO: "SECRETARIA DE SEGURANCA PUBLICA - CE",
    SIGLA: "CE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "986",
    DESCRICAO: "SECRETARIA DE SEGURANCA PUBLICA DE SP",
    SIGLA: "SSSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "987",
    DESCRICAO: "SECRETARIA DO INTERIOR DA JUSTICA",
    SIGLA: "SIJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "988",
    DESCRICAO: "SECRETARIA ESPECIAL DE CIENCIA E TECNOLOGIA",
    SIGLA: "SECT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "989",
    DESCRICAO: "SECRETARIA ESTADUAL DE IDENTIDADE",
    SIGLA: "SEID",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "990",
    DESCRICAO: "SECRETARIA ESTADUAL PUBLICA",
    SIGLA: "SEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "991",
    DESCRICAO: "SECRETARIA JUSTICA TRABALHO CIDADANIA",
    SIGLA: "SJTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "992",
    DESCRICAO: "SECRETARIA PUBLICA DO ESPIRITO SANTO",
    SIGLA: "SPES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "993",
    DESCRICAO: "SECRETARIA PUBLICA DO SUL",
    SIGLA: "SPS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "994",
    DESCRICAO: "SECRETARIA SEG PUBLICA PR",
    SIGLA: "SSPR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "995",
    DESCRICAO: "SECRETARIA SEGURANCA DO RIO DE JANEIRO",
    SIGLA: "SSRJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "996",
    DESCRICAO: "SEG PUBL CIDADANIA INST IDENT",
    SIGLA: "SPCI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "997",
    DESCRICAO: "SENADO FEDERAL",
    SIGLA: "SF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "998",
    DESCRICAO: "SERV ESTRANG DEPTO POL AEREA FRONT PF",
    SIGLA: "SEPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "999",
    DESCRICAO: "SERVICO DE IDENT E CRIMINALISTA",
    SIGLA: "SIC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1000",
    DESCRICAO: "SIND DETETIVES PARTIC PROF ESPIRITO SANT",
    SIGLA: "SDES",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1001",
    DESCRICAO: "Sindicato dos Jornalistas Profissionais",
    SIGLA: "SJP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1002",
    DESCRICAO: "Superintendência de Administração de Pessoal",
    SIGLA: "SAP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1003",
    DESCRICAO: "SUPERINT GERAL DE POLICIA JUDICIARIA",
    SIGLA: "SGPJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1004",
    DESCRICAO: "Superintendência de Polícia Civil",
    SIGLA: "SPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1005",
    DESCRICAO: "Superintendência Polícia Técnica Científica",
    SIGLA: "SPTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1006",
    DESCRICAO: "SUPERIOR TRIBUNAL MILITAR",
    SIGLA: "STM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1007",
    DESCRICAO: "TRIBUNAL DE CONTAS",
    SIGLA: "TC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1008",
    DESCRICAO: "TRIBUNAL DE JUSTICA",
    SIGLA: "TJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1009",
    DESCRICAO: "TRIBUNAL ELEITORAL",
    SIGLA: "TE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1010",
    DESCRICAO: "TRIBUNAL REGIONAL DO TRABALHO",
    SIGLA: "TRT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1011",
    DESCRICAO: "TRIBUNAL REGIONAL ELEITORAL",
    SIGLA: "TRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1012",
    DESCRICAO: "TRIBUNAL REGIONAL FEDERAL",
    SIGLA: "TRF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1013",
    DESCRICAO: "TRIBUNAL SUPERIOR DO TRABALHO",
    SIGLA: "TST",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1014",
    DESCRICAO: "SUPERINTENDENCIA GERAL DE POLICIA CIVIL",
    SIGLA: "SGPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1015",
    DESCRICAO: "SECRETARIA DE ESTADO DA CASA CIVIL",
    SIGLA: "SECC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1016",
    DESCRICAO: "SECRETARIA DO ESTADO DA JUSTICA DE SAO PAULO",
    SIGLA: "SEJSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1017",
    DESCRICAO: "SECRETARIA DE ESTADO DE SAÚDE E DEFESA CIVIL",
    SIGLA: "SESDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1018",
    DESCRICAO: "SECRETARIA DE JUSTICA",
    SIGLA: "SJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1019",
    DESCRICAO: "INSTITUTO DE IDENTIFICACAO RICARDO GUMBLETON DAUNT",
    SIGLA: "IIRGD",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1020",
    DESCRICAO: "MINISTERIO DA GUERRA",
    SIGLA: "MG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1021",
    DESCRICAO: "COORDENACAO GERAL DE POLICIA DE IMIGRACAO",
    SIGLA: "CGPI",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1022",
    DESCRICAO: "DELEGACIA GERAL DE POLICIA",
    SIGLA: "DGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1023",
    DESCRICAO: "Delegacia de Polícia Marítima, Aérea e de Fronteiras",
    SIGLA: "DPMAF/DPF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1024",
    DESCRICAO: "SECRETARIA DE SEGURANCA - DEPTO. POLICIA TECNICA",
    SIGLA: "SSDPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1025",
    DESCRICAO: "COORDENACAO DE IMIGRACAO",
    SIGLA: "CIMCRE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1026",
    DESCRICAO: "POLICIA CIVIL",
    SIGLA: "PC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1027",
    DESCRICAO: "POLÍCIA CIVIL DO ESTADO DE MINAS GERAIS",
    SIGLA: "PCMG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1028",
    DESCRICAO: "POLÍCIA TÉCNICO CIENTÍFICA",
    SIGLA: "PTC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1029",
    DESCRICAO: "SECRETARIA COORDENADORA DE JUSTIÇA E DEFESA SOCIAL",
    SIGLA: "SEDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1030",
    DESCRICAO: "SECRETARIA DE DEFESA SOCIAL",
    SIGLA: "SDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1031",
    DESCRICAO: "SECRETARIA DA SEGURANÇA E DA DEFESA SOCIAL",
    SIGLA: "SSDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1032",
    DESCRICAO: "SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL",
    SIGLA: "SSPDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1033",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA CIDADÃ",
    SIGLA: "SESEC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1034",
    DESCRICAO: "SECRETARIA DE ESTADO DE JUSTIÇA E SEGURANÇA PÚBLICA",
    SIGLA: "SEJUSP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1035",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL",
    SIGLA: "SESED",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1036",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DEFESA DO CIDADÃO",
    SIGLA: "SSPDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1037",
    DESCRICAO: "GERÊNCIA DE ESTADO DE JUSTIÇA, SEGURANÇA PÚBLICA E CIDADANIA",
    SIGLA: "GEJUSPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1038",
    DESCRICAO: "CONSELHO DE ARQUITETURA E URBANISMO DO BRASIL",
    SIGLA: "CAU/BR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1039",
    DESCRICAO: "DEPARTAMENTO NACIONAL DE TRÂNSITO",
    SIGLA: "DENATRAN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1040",
    DESCRICAO: "GABINETE MILITAR",
    SIGLA: "GM",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1041",
    DESCRICAO: "GERÊNCIA DE ESTADO DA SEGURANÇA PÚBLICA",
    SIGLA: "GESEP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1042",
    DESCRICAO: "SECRETARIA DA JUSTIÇA E DA SEGURANÇA",
    SIGLA: "SJSEG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1043",
    DESCRICAO: "SECRETARIA DA SEGURANÇA, DEFESA E CIDADANIA",
    SIGLA: "SESDEC/RO",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1044",
    DESCRICAO:
      "SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E ADMINISTRAÇÃO PENITENCIÁRIA",
    SIGLA: "SESPAP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1045",
    DESCRICAO: "SECRETARIA DE ESTADO DA SEGURANÇA, DEFESA E CIDADANIA",
    SIGLA: "SESEGDC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1046",
    DESCRICAO: "SECRETARIA DE JUSTIÇA E DEFESA SOCIAL",
    SIGLA: "SJDS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1047",
    DESCRICAO: "Conselho Federal de Farmácia",
    SIGLA: "CFF",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1048",
    DESCRICAO: "CONSELHO FEDERAL DOS DESPACHANTES DOCUMENTALISTAS DO BRASIL",
    SIGLA: "CFDD/BR",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1049",
    DESCRICAO: "INSTITUTO DE ADMINISTRAÇÃO PENITENCIÁRIA",
    SIGLA: "IAPEN",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1050",
    DESCRICAO: "SECRETARIA DE TRIBUTAÇÃO",
    SIGLA: "SET",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1051",
    DESCRICAO: "SECRETARIA DE JUSTIÇA E SEGURANÇA PÚBLICA",
    SIGLA: "SJSEGP",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1052",
    DESCRICAO: "SECRETARIA DE ESTADO DA CASA CIVIL E DESENVOLVIMENTO ECONÔMICO",
    SIGLA: "SECC/DE",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1053",
    DESCRICAO: "AGENCIA NACIONAL DE AVIAÇÃO CIVIL",
    SIGLA: "ANAC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1055",
    DESCRICAO: "DIRETORIA DE IDENTIFICAÇÃO CIVIL",
    SIGLA: "DETRAN/RJ",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1056",
    DESCRICAO: "SECRETARIA DE SEGURANÇA PÚBLICA POLÍCIA CIVIL",
    SIGLA: "SSPPC/GO",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1057",
    DESCRICAO: "Divisão de Segurança e Guarda",
    SIGLA: "DSG",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1059",
    DESCRICAO: "Secretaria de Justiça Interior e Segurança",
    SIGLA: "SJIS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1061",
    DESCRICAO: "Departamento de Polícia Técnica",
    SIGLA: "DPT",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1062",
    DESCRICAO: "SSP - Polícia Civil",
    SIGLA: "SSPPC",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1069",
    DESCRICAO: "Secretaria de Justiça.",
    SIGLA: "SECJUS",
  },
  {
    DOMINIO: "ORGAO_EMISSOR",
    CODIGO: "1071",
    DESCRICAO: "Secretaria da Segurança Pública e Defesa da Cidadania.",
    SIGLA: "SSPDDC",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "1",
    DESCRICAO: "AFEGANISTAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "2",
    DESCRICAO: "AFRICA DO SUL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "3",
    DESCRICAO: "ALBANIA, REPUBLICA  DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "4",
    DESCRICAO: "ALEMANHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "5",
    DESCRICAO: "ANDORRA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "6",
    DESCRICAO: "ANGOLA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "7",
    DESCRICAO: "ANGUILLA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "8",
    DESCRICAO: "ANTIGUA E BARBUDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "9",
    DESCRICAO: "ANTILHAS HOLANDESAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "10",
    DESCRICAO: "ARABIA SAUDITA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "11",
    DESCRICAO: "ARGELIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "12",
    DESCRICAO: "ARGENTINA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "13",
    DESCRICAO: "ARMENIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "14",
    DESCRICAO: "ARUBA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "15",
    DESCRICAO: "AUSTRALIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "16",
    DESCRICAO: "AUSTRIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "17",
    DESCRICAO: "AZERBAIJAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "18",
    DESCRICAO: "BAHAMAS, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "19",
    DESCRICAO: "BAHREIN, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "20",
    DESCRICAO: "BANGLADESH",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "21",
    DESCRICAO: "BARBADOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "22",
    DESCRICAO: "BELARUS, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "23",
    DESCRICAO: "BELGICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "24",
    DESCRICAO: "BELIZE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "25",
    DESCRICAO: "BENIN",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "26",
    DESCRICAO: "BERMUDAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "27",
    DESCRICAO: "BOLIVIA, ESTADO PLURINACIONAL DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "28",
    DESCRICAO: "BOSNIA-HERZEGOVINA (REPUBLICA DA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "29",
    DESCRICAO: "BOTSUANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "29",
    DESCRICAO: "BRASIL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "31",
    DESCRICAO: "BRUNEI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "32",
    DESCRICAO: "BULGARIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "33",
    DESCRICAO: "BURKINA FASO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "34",
    DESCRICAO: "BURUNDI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "35",
    DESCRICAO: "BUTAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "36",
    DESCRICAO: "CABO VERDE, REPUBLICA DE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "37",
    DESCRICAO: "CAMAROES",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "38",
    DESCRICAO: "CAMBOJA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "39",
    DESCRICAO: "CANADA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "40",
    DESCRICAO: "CANARIAS, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "41",
    DESCRICAO: "CATAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "42",
    DESCRICAO: "CAYMAN, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "43",
    DESCRICAO: "CAZAQUISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "44",
    DESCRICAO: "CHADE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "45",
    DESCRICAO: "CHILE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "46",
    DESCRICAO: "CHINA, REPUBLICA POPULAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "47",
    DESCRICAO: "CHIPRE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "48",
    DESCRICAO: "CHRISTMAS,ILHA (NAVIDAD)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "49",
    DESCRICAO: "CINGAPURA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "50",
    DESCRICAO: "COCOS(KEELING),ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "51",
    DESCRICAO: "COLOMBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "52",
    DESCRICAO: "COMORES, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "53",
    DESCRICAO: "CONGO, REPUBLICA DEMOCRATICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "54",
    DESCRICAO: "CONGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "55",
    DESCRICAO: "COOK, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "56",
    DESCRICAO: "COREIA (DO NORTE), REP.POP.DEMOCRATICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "57",
    DESCRICAO: "COREIA (DO SUL), REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "58",
    DESCRICAO: "COSTA DO MARFIM",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "59",
    DESCRICAO: "COSTA RICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "60",
    DESCRICAO: "COVEITE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "61",
    DESCRICAO: "CROACIA (REPUBLICA DA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "62",
    DESCRICAO: "CUBA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "63",
    DESCRICAO: "DINAMARCA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "64",
    DESCRICAO: "DJIBUTI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "65",
    DESCRICAO: "DOMINICA,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "66",
    DESCRICAO: "EGITO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "67",
    DESCRICAO: "EL SALVADOR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "68",
    DESCRICAO: "EMIRADOS ARABES UNIDOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "69",
    DESCRICAO: "EQUADOR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "70",
    DESCRICAO: "ERITREIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "71",
    DESCRICAO: "ESLOVACA, REPUBLICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "72",
    DESCRICAO: "ESLOVENIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "73",
    DESCRICAO: "ESPANHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "74",
    DESCRICAO: "ESTADOS UNIDOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "75",
    DESCRICAO: "ESTONIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "76",
    DESCRICAO: "ETIOPIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "77",
    DESCRICAO: "FALKLAND (ILHAS MALVINAS)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "78",
    DESCRICAO: "FEROE, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "79",
    DESCRICAO: "FIJI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "80",
    DESCRICAO: "FILIPINAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "81",
    DESCRICAO: "FINLANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "82",
    DESCRICAO: "FORMOSA (TAIWAN)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "83",
    DESCRICAO: "FRANCA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "84",
    DESCRICAO: "GABAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "85",
    DESCRICAO: "GAMBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "86",
    DESCRICAO: "GANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "87",
    DESCRICAO: "GEORGIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "88",
    DESCRICAO: "GIBRALTAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "89",
    DESCRICAO: "GRANADA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "90",
    DESCRICAO: "GRECIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "91",
    DESCRICAO: "GROENLANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "92",
    DESCRICAO: "GUADALUPE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "93",
    DESCRICAO: "GUAM",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "94",
    DESCRICAO: "GUATEMALA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "95",
    DESCRICAO: "GUERNSEY, ILHA DO CANAL (INCLUI ALDERNEY E SARK)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "96",
    DESCRICAO: "GUIANA FRANCESA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "97",
    DESCRICAO: "GUIANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "98",
    DESCRICAO: "GUINE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "99",
    DESCRICAO: "GUINE-BISSAU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "100",
    DESCRICAO: "GUINE-EQUATORIAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "101",
    DESCRICAO: "HAITI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "102",
    DESCRICAO: "HONDURAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "103",
    DESCRICAO: "HONG KONG",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "104",
    DESCRICAO: "HUNGRIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "105",
    DESCRICAO: "IEMEN",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "106",
    DESCRICAO: "INDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "107",
    DESCRICAO: "INDONESIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "108",
    DESCRICAO: "IRA, REPUBLICA ISLAMICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "109",
    DESCRICAO: "IRAQUE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "110",
    DESCRICAO: "IRLANDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "111",
    DESCRICAO: "ISLANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "112",
    DESCRICAO: "ISRAEL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "113",
    DESCRICAO: "ITALIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "114",
    DESCRICAO: "JAMAICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "115",
    DESCRICAO: "JAPAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "116",
    DESCRICAO: "JERSEY, ILHA DO CANAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "117",
    DESCRICAO: "JOHNSTON, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "118",
    DESCRICAO: "JORDANIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "119",
    DESCRICAO: "KIRIBATI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "120",
    DESCRICAO: "LAOS, REP.POP.DEMOCR.DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "121",
    DESCRICAO: "LEBUAN,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "122",
    DESCRICAO: "LESOTO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "123",
    DESCRICAO: "LETONIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "124",
    DESCRICAO: "LIBANO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "125",
    DESCRICAO: "LIBERIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "126",
    DESCRICAO: "LIBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "127",
    DESCRICAO: "LIECHTENSTEIN",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "128",
    DESCRICAO: "LITUANIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "129",
    DESCRICAO: "LUXEMBURGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "130",
    DESCRICAO: "MACAU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "131",
    DESCRICAO: "MACEDONIA, ANT.REP.IUGOSLAVA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "132",
    DESCRICAO: "MADAGASCAR",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "133",
    DESCRICAO: "MADEIRA, ILHA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "134",
    DESCRICAO: "MALASIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "135",
    DESCRICAO: "MALAVI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "136",
    DESCRICAO: "MALDIVAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "137",
    DESCRICAO: "MALI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "138",
    DESCRICAO: "MALTA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "139",
    DESCRICAO: "MAN, ILHA DE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "140",
    DESCRICAO: "MARIANAS DO NORTE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "141",
    DESCRICAO: "MARROCOS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "142",
    DESCRICAO: "MARSHALL,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "143",
    DESCRICAO: "MARTINICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "144",
    DESCRICAO: "MAURICIO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "145",
    DESCRICAO: "MAURITANIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "146",
    DESCRICAO: "MAYOTTE (ILHAS FRANCESAS)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "147",
    DESCRICAO: "MEXICO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "148",
    DESCRICAO: "MIANMAR (BIRMANIA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "149",
    DESCRICAO: "MICRONESIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "150",
    DESCRICAO: "MIDWAY, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "151",
    DESCRICAO: "MOCAMBIQUE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "152",
    DESCRICAO: "MOLDAVIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "153",
    DESCRICAO: "MONACO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "154",
    DESCRICAO: "MONGOLIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "155",
    DESCRICAO: "MONTENEGRO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "156",
    DESCRICAO: "MONTSERRAT,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "157",
    DESCRICAO: "NAMIBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "158",
    DESCRICAO: "NAURU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "159",
    DESCRICAO: "NEPAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "160",
    DESCRICAO: "NICARAGUA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "161",
    DESCRICAO: "NIGER",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "162",
    DESCRICAO: "NIGERIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "163",
    DESCRICAO: "NIUE,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "164",
    DESCRICAO: "NORFOLK,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "165",
    DESCRICAO: "NORUEGA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "166",
    DESCRICAO: "NOVA CALEDONIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "167",
    DESCRICAO: "NOVA ZELANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "168",
    DESCRICAO: "OMA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "169",
    DESCRICAO: "PACIFICO,ILHAS DO (POSSESSAO DOS EUA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "170",
    DESCRICAO: "PAISES BAIXOS (HOLANDA)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "171",
    DESCRICAO: "PALAU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "172",
    DESCRICAO: "PANAMA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "173",
    DESCRICAO: "PAPUA NOVA GUINE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "174",
    DESCRICAO: "PAQUISTAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "175",
    DESCRICAO: "PARAGUAI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "176",
    DESCRICAO: "PERU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "177",
    DESCRICAO: "PITCAIRN,ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "178",
    DESCRICAO: "POLINESIA FRANCESA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "179",
    DESCRICAO: "POLONIA, REPUBLICA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "180",
    DESCRICAO: "PORTO RICO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "181",
    DESCRICAO: "PORTUGAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "182",
    DESCRICAO: "QUENIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "183",
    DESCRICAO: "QUIRGUIZ, REPUBLICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "184",
    DESCRICAO: "REINO UNIDO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "185",
    DESCRICAO: "REPUBLICA CENTRO-AFRICANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "186",
    DESCRICAO: "REPUBLICA DOMINICANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "187",
    DESCRICAO: "REUNIAO, ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "188",
    DESCRICAO: "ROMENIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "189",
    DESCRICAO: "RUANDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "190",
    DESCRICAO: "RUSSIA, FEDERACAO DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "191",
    DESCRICAO: "SAARA OCIDENTAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "192",
    DESCRICAO: "SALOMAO, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "193",
    DESCRICAO: "SAMOA AMERICANA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "194",
    DESCRICAO: "SAMOA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "195",
    DESCRICAO: "SAN MARINO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "196",
    DESCRICAO: "SANTA HELENA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "197",
    DESCRICAO: "SANTA LUCIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "198",
    DESCRICAO: "SAO CRISTOVAO E NEVES,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "199",
    DESCRICAO: "SAO PEDRO E MIQUELON",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "200",
    DESCRICAO: "SAO TOME E PRINCIPE, ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "201",
    DESCRICAO: "SAO VICENTE E GRANADINAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "202",
    DESCRICAO: "SENEGAL",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "203",
    DESCRICAO: "SERRA LEOA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "204",
    DESCRICAO: "SERVIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "205",
    DESCRICAO: "SEYCHELLES",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "206",
    DESCRICAO: "SIRIA, REPUBLICA ARABE DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "207",
    DESCRICAO: "SOMALIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "208",
    DESCRICAO: "SRI LANKA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "209",
    DESCRICAO: "SUAZILANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "210",
    DESCRICAO: "SUDAO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "211",
    DESCRICAO: "SUECIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "212",
    DESCRICAO: "SUICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "213",
    DESCRICAO: "SURINAME",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "214",
    DESCRICAO: "TADJIQUISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "215",
    DESCRICAO: "TAILANDIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "216",
    DESCRICAO: "TANZANIA, REP.UNIDA DA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "217",
    DESCRICAO: "TCHECA, REPUBLICA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "218",
    DESCRICAO: "TERRITORIO BRIT.OC.INDICO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "219",
    DESCRICAO: "TIMOR LESTE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "220",
    DESCRICAO: "TOGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "221",
    DESCRICAO: "TONGA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "222",
    DESCRICAO: "TOQUELAU,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "223",
    DESCRICAO: "TRINIDAD E TOBAGO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "224",
    DESCRICAO: "TUNISIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "225",
    DESCRICAO: "TURCAS E CAICOS,ILHAS",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "226",
    DESCRICAO: "TURCOMENISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "227",
    DESCRICAO: "TURQUIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "228",
    DESCRICAO: "TUVALU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "229",
    DESCRICAO: "UCRANIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "230",
    DESCRICAO: "UGANDA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "231",
    DESCRICAO: "URUGUAI",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "232",
    DESCRICAO: "UZBEQUISTAO, REPUBLICA DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "233",
    DESCRICAO: "VANUATU",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "234",
    DESCRICAO: "VATICANO, EST.DA CIDADE DO",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "235",
    DESCRICAO: "VENEZUELA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "236",
    DESCRICAO: "VIETNA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "237",
    DESCRICAO: "VIRGENS,ILHAS (BRITANICAS)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "238",
    DESCRICAO: "VIRGENS,ILHAS (E.U.A.)",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "239",
    DESCRICAO: "WAKE, ILHA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "240",
    DESCRICAO: "ZAMBIA",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "241",
    DESCRICAO: "ZIMBABUE",
  },
  {
    DOMINIO: "PAIS",
    CODIGO: "242",
    DESCRICAO: "NÃO INFORMADO",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3496",
    DESCRICAO: "ANUAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3497",
    DESCRICAO: "MENSAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3498",
    DESCRICAO: "QUINZENAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3499",
    DESCRICAO: "SEMANAL",
  },
  {
    DOMINIO: "PERIODICIDADE_RENDA",
    CODIGO: "3500",
    DESCRICAO: "SEMESTRAL",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "172",
    DESCRICAO: "Servidor Público",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "142",
    DESCRICAO: "Diretor geral de empresa e organizações",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "143",
    DESCRICAO: "Atividade agropecuária, rural ou florestal",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "144",
    DESCRICAO: "Bancário",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "145",
    DESCRICAO: "Economista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "146",
    DESCRICAO: "Gerente administrativo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "147",
    DESCRICAO: "Engenheiro",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "148",
    DESCRICAO: "Advogado",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "149",
    DESCRICAO: "Empregado doméstico nos serviços gerais",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "150",
    DESCRICAO: "Não Informado",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "151",
    DESCRICAO: "Analista de sistemas",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "152",
    DESCRICAO: "Médico",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "153",
    DESCRICAO: "Membros das forças armadas e policiais",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "154",
    DESCRICAO: "Zootecnista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "155",
    DESCRICAO: "Artista, produtor ou trabalhador de entretenimento",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "156",
    DESCRICAO: "Professor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "157",
    DESCRICAO: "Vendedor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "158",
    DESCRICAO: "Psicólogo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "159",
    DESCRICAO: "Dentista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "160",
    DESCRICAO: "Representante Comercial",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "161",
    DESCRICAO: "Enfermeiro",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "162",
    DESCRICAO: "Pedagogo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "163",
    DESCRICAO: "Fonoaudiólogo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "164",
    DESCRICAO: "Acadêmico",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "165",
    DESCRICAO: "Dirigente político ou síndical",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "166",
    DESCRICAO: "Corretor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "167",
    DESCRICAO: "Jornalista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "168",
    DESCRICAO: "Designer",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "169",
    DESCRICAO: "Técnico especialista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "170",
    DESCRICAO: "Agente de saúde",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "171",
    DESCRICAO: "Biomédico",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "173",
    DESCRICAO: "Auditor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "174",
    DESCRICAO: "Piloto",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "175",
    DESCRICAO: "Líder religioso",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "176",
    DESCRICAO: "Assistente social",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "177",
    DESCRICAO: "Motorista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "178",
    DESCRICAO: "Escrivão",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "179",
    DESCRICAO: "Veterinário",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "180",
    DESCRICAO: "Técnólogo especialista",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "181",
    DESCRICAO: "Gerente de hotelaria",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "182",
    DESCRICAO: "Bibliotecário",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "183",
    DESCRICAO: "Operador de Máquinas",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "184",
    DESCRICAO: "Escritor",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "185",
    DESCRICAO: "Prof Esporte",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "186",
    DESCRICAO: "Comissário de bordo, Guia de turismo",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "187",
    DESCRICAO: "Outras ocupações não classificadas",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "188",
    DESCRICAO: "Serviços Gerais",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "189",
    DESCRICAO: "Trab ind beneficiamento e construção civil",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "190",
    DESCRICAO: "Trab serv de embelezamento",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "191",
    DESCRICAO: "Trab serv Proteção/Seguranc (Exceto Militar)",
  },
  {
    DOMINIO: "PROFISSAO",
    CODIGO: "192",
    DESCRICAO: "Não identificado",
  },
  {
    DOMINIO: "SEXO",
    CODIGO: "3111",
    DESCRICAO: "MASCULINO",
  },
  {
    DOMINIO: "SEXO",
    CODIGO: "3112",
    DESCRICAO: "FEMININO",
  },
  {
    DOMINIO: "SEXO",
    CODIGO: "5041",
    DESCRICAO: "NÃO IDENTIFICADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "449",
    DESCRICAO: "REGULAR",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "448",
    DESCRICAO: "PENDENTE DE REGULARIZACAO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "447",
    DESCRICAO: "CANCELADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4712",
    DESCRICAO: "INCOMPLETO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4713",
    DESCRICAO: "INCORRETO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4714",
    DESCRICAO: "INDETERMINADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4715",
    DESCRICAO: "INEXISTENTE",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4716",
    DESCRICAO: "NULO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4717",
    DESCRICAO: "SUSPENSO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4995",
    DESCRICAO: "CANCELADO POR ENCERRAMENTO DE ESPOLIO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4996",
    DESCRICAO: "CANCELADO POR MULTIPLICIDADE",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4997",
    DESCRICAO: "CANCELADO DE OFICIO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "4998",
    DESCRICAO: "CANCELADO POR OBITO SEM ESPOLIO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "5024",
    DESCRICAO: "CPF CANCELADO",
  },
  {
    DOMINIO: "SITUACAO_CPF",
    CODIGO: "5025",
    DESCRICAO: "CPF NULO",
  },
  {
    DOMINIO: "SITUACAO_ESCOLARIDADE",
    CODIGO: "3081",
    DESCRICAO: "COMPLETO",
  },
  {
    DOMINIO: "SITUACAO_ESCOLARIDADE",
    CODIGO: "3082",
    DESCRICAO: "CURSANDO",
  },
  {
    DOMINIO: "SITUACAO_ESCOLARIDADE",
    CODIGO: "3083",
    DESCRICAO: "INCOMPLETO",
  },
  {
    DOMINIO: "TIPO_ATIVIDADE",
    CODIGO: "8",
    DESCRICAO: "PRIVADO - PESSOAS FISICAS",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3072",
    DESCRICAO: "PESSOAL",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3073",
    DESCRICAO: "COMERCIAL",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3074",
    DESCRICAO: "OUTROS",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "3495",
    DESCRICAO: "DERIVATIVO",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "5005",
    DESCRICAO: "FUNCIONARIO",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "5033",
    DESCRICAO: "CONTA SALARIO",
  },
  {
    DOMINIO: "TIPO_EMAIL",
    CODIGO: "5036",
    DESCRICAO: "RISCO SACADO",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "565",
    DESCRICAO: "RESIDENCIAL",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "566",
    DESCRICAO: "COMERCIAL",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "3134",
    DESCRICAO: "OUTROS",
  },
  {
    DOMINIO: "TIPO_ENDERECO",
    CODIGO: "5035",
    DESCRICAO: "CONTA SALARIO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "584",
    DESCRICAO: "ALUGUEL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "585",
    DESCRICAO: "APLICAÇÃO FINANCEIRA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "586",
    DESCRICAO: "APOSENTADORIA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "587",
    DESCRICAO: "DIVIDENDOS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "588",
    DESCRICAO: "OUTRAS RENDAS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "589",
    DESCRICAO: "PENSÃO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "590",
    DESCRICAO: "PRINCIPAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3113",
    DESCRICAO: "GRATIFICAÇÃO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3114",
    DESCRICAO: "PRÓ-LABORE",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3115",
    DESCRICAO: "COMISSÃO",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3116",
    DESCRICAO: "HONORÁRIOS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3117",
    DESCRICAO: "RENDA RURAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3144",
    DESCRICAO: "REMUNERAÇÃO MENSAL",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3181",
    DESCRICAO: "CÔNJUGE",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3493",
    DESCRICAO: "BÔNUS",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "3494",
    DESCRICAO: "MESADA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4807",
    DESCRICAO: "Limite Cartão de Crédito",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4808",
    DESCRICAO: "Limite Cheque Especial",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4988",
    DESCRICAO: "PRESUMIDA",
  },
  {
    DOMINIO: "TIPO_FONTE_RENDA",
    CODIGO: "4994",
    DESCRICAO: "SALÁRIO",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "1",
    DESCRICAO: "Comprovada",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "2",
    DESCRICAO: "Declarada",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "3",
    DESCRICAO: "Presumida pelo gerente",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "4",
    DESCRICAO: "Presumida por investimentos",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "5",
    DESCRICAO: "Presumida por modelo",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "6",
    DESCRICAO: "Não informada",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "7",
    DESCRICAO: "Presumida por crédito",
  },
  {
    DOMINIO: "TIPO_INFORMACAO_RENDA",
    CODIGO: "8",
    DESCRICAO: "Renda BACEN",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3412",
    DESCRICAO: "AEROPORTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3413",
    DESCRICAO: "ALAMEDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3414",
    DESCRICAO: "ÁREA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3415",
    DESCRICAO: "ASA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3416",
    DESCRICAO: "AVENIDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3417",
    DESCRICAO: "CAMPO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3418",
    DESCRICAO: "CHÁCARA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3419",
    DESCRICAO: "COLÔNIA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3420",
    DESCRICAO: "CONDOMÍNIO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3421",
    DESCRICAO: "CONJUNTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3422",
    DESCRICAO: "DISTRITO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3423",
    DESCRICAO: "ESPLANADA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3424",
    DESCRICAO: "ESTAÇÃO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3425",
    DESCRICAO: "ESTRADA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3426",
    DESCRICAO: "FAVELA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3427",
    DESCRICAO: "FAZENDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3428",
    DESCRICAO: "FEIRA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3429",
    DESCRICAO: "JARDIM",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3430",
    DESCRICAO: "LADEIRA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3431",
    DESCRICAO: "LAGO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3432",
    DESCRICAO: "LAGOA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3433",
    DESCRICAO: "LARGO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3434",
    DESCRICAO: "LOTEAMENTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3435",
    DESCRICAO: "MORRO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3436",
    DESCRICAO: "NÚCLEO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3437",
    DESCRICAO: "PARQUE",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3438",
    DESCRICAO: "PASSARELA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3439",
    DESCRICAO: "PÁTIO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3440",
    DESCRICAO: "PRAÇA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3441",
    DESCRICAO: "QUADRA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3442",
    DESCRICAO: "RECANTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3443",
    DESCRICAO: "RESIDENCIAL",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3444",
    DESCRICAO: "RODOVIA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3445",
    DESCRICAO: "RUA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3446",
    DESCRICAO: "SERVIDÃO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3447",
    DESCRICAO: "SETOR",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3448",
    DESCRICAO: "SÍTIO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3449",
    DESCRICAO: "TRAVESSA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3450",
    DESCRICAO: "TRECHO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3451",
    DESCRICAO: "TREVO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3452",
    DESCRICAO: "VALE",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3453",
    DESCRICAO: "VEREDA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3454",
    DESCRICAO: "VIA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3455",
    DESCRICAO: "VIADUTO",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3456",
    DESCRICAO: "VIELA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3457",
    DESCRICAO: "VILA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3712",
    DESCRICAO: "Via Principal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3713",
    DESCRICAO: "Zigue-Zague",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3728",
    DESCRICAO: "Analista",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3927",
    DESCRICAO: "10ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3928",
    DESCRICAO: "10ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3929",
    DESCRICAO: "11ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3930",
    DESCRICAO: "11ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3931",
    DESCRICAO: "12ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3932",
    DESCRICAO: "12ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3933",
    DESCRICAO: "13ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3934",
    DESCRICAO: "14ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3935",
    DESCRICAO: "15ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3936",
    DESCRICAO: "16ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3937",
    DESCRICAO: "17ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3938",
    DESCRICAO: "18ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3939",
    DESCRICAO: "19ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3940",
    DESCRICAO: "1ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3941",
    DESCRICAO: "1ª Ladeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3942",
    DESCRICAO: "1ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3943",
    DESCRICAO: "1ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3944",
    DESCRICAO: "1ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3945",
    DESCRICAO: "1ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3946",
    DESCRICAO: "1ª Travessa da Rodovia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3947",
    DESCRICAO: "1ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3948",
    DESCRICAO: "1º Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3949",
    DESCRICAO: "1º Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3950",
    DESCRICAO: "20ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3951",
    DESCRICAO: "21ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3952",
    DESCRICAO: "22ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3953",
    DESCRICAO: "2ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3954",
    DESCRICAO: "2ª Ladeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3955",
    DESCRICAO: "2ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3956",
    DESCRICAO: "2ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3957",
    DESCRICAO: "2ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3958",
    DESCRICAO: "2ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3959",
    DESCRICAO: "2ª Travessa da Rodovia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3960",
    DESCRICAO: "2ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3961",
    DESCRICAO: "2º Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3962",
    DESCRICAO: "2º Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3963",
    DESCRICAO: "3ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3964",
    DESCRICAO: "3ª Ladeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3965",
    DESCRICAO: "3ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3966",
    DESCRICAO: "3ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3967",
    DESCRICAO: "3ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3968",
    DESCRICAO: "3ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3969",
    DESCRICAO: "3ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3970",
    DESCRICAO: "3º Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3971",
    DESCRICAO: "3º Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3972",
    DESCRICAO: "4ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3973",
    DESCRICAO: "4ª Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3974",
    DESCRICAO: "4ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3975",
    DESCRICAO: "4ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3976",
    DESCRICAO: "4ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3977",
    DESCRICAO: "4ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3978",
    DESCRICAO: "5ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3979",
    DESCRICAO: "5ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3980",
    DESCRICAO: "5ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3981",
    DESCRICAO: "5ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3982",
    DESCRICAO: "5ª Vila",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3983",
    DESCRICAO: "6ª Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3984",
    DESCRICAO: "6ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3985",
    DESCRICAO: "6ª Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3986",
    DESCRICAO: "6ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3987",
    DESCRICAO: "7ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3988",
    DESCRICAO: "7ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3989",
    DESCRICAO: "8ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3990",
    DESCRICAO: "9ª Rua",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3991",
    DESCRICAO: "9ª Travessa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3992",
    DESCRICAO: "Acampamento",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3993",
    DESCRICAO: "Acesso",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3994",
    DESCRICAO: "Acesso Estadual",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3995",
    DESCRICAO: "Acesso Local",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3996",
    DESCRICAO: "Adro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3997",
    DESCRICAO: "Alto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3998",
    DESCRICAO: "Anel Viário",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "3999",
    DESCRICAO: "Antiga Estação",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4000",
    DESCRICAO: "Antiga Estrada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4001",
    DESCRICAO: "Área Especial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4002",
    DESCRICAO: "Área Verde",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4003",
    DESCRICAO: "Artéria",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4004",
    DESCRICAO: "Atalho",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4005",
    DESCRICAO: "Avenida Contorno",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4006",
    DESCRICAO: "Avenida Marginal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4007",
    DESCRICAO: "Avenida Marginal Direita",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4008",
    DESCRICAO: "Avenida Marginal Esquerda",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4009",
    DESCRICAO: "Avenida Marginal Norte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4010",
    DESCRICAO: "Avenida Perimetral",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4011",
    DESCRICAO: "Baixa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4012",
    DESCRICAO: "Balão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4013",
    DESCRICAO: "Beco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4014",
    DESCRICAO: "Beco 1",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4015",
    DESCRICAO: "Beco 2",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4016",
    DESCRICAO: "Beco 3",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4017",
    DESCRICAO: "Belvedere",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4018",
    DESCRICAO: "Bloco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4019",
    DESCRICAO: "Blocos",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4020",
    DESCRICAO: "Bosque",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4021",
    DESCRICAO: "Boulevard",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4022",
    DESCRICAO: "Bulevar",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4023",
    DESCRICAO: "Buraco",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4024",
    DESCRICAO: "Cais",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4025",
    DESCRICAO: "Calçada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4026",
    DESCRICAO: "Calçadão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4027",
    DESCRICAO: "Caminho",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4028",
    DESCRICAO: "Caminho de Servidão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4029",
    DESCRICAO: "Campus",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4030",
    DESCRICAO: "Canal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4031",
    DESCRICAO: "Ciclovia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4032",
    DESCRICAO: "Circular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4033",
    DESCRICAO: "Colina",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4034",
    DESCRICAO: "Complexo Viário",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4035",
    DESCRICAO: "Comunidade",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4036",
    DESCRICAO: "Condomínio Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4037",
    DESCRICAO: "Conjunto Habitacional",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4038",
    DESCRICAO: "Conjunto Mutirão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4039",
    DESCRICAO: "Conjunto Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4040",
    DESCRICAO: "Contorno",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4041",
    DESCRICAO: "Corredor",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4042",
    DESCRICAO: "Córrego",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4043",
    DESCRICAO: "Descida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4044",
    DESCRICAO: "Desvio",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4045",
    DESCRICAO: "Eixo",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4046",
    DESCRICAO: "Eixo Industrial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4047",
    DESCRICAO: "Eixo Principal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4048",
    DESCRICAO: "Elevada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4049",
    DESCRICAO: "Entrada Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4050",
    DESCRICAO: "Entre Quadra",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4051",
    DESCRICAO: "Escada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4052",
    DESCRICAO: "Escada de Pedra",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4053",
    DESCRICAO: "Escadaria",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4054",
    DESCRICAO: "Estacionamento",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4055",
    DESCRICAO: "Estádio",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4056",
    DESCRICAO: "Estrada Antiga",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4057",
    DESCRICAO: "Estrada de Ferro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4058",
    DESCRICAO: "Estrada de Ligação",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4059",
    DESCRICAO: "Estrada de Servidão",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4060",
    DESCRICAO: "Estrada Estadual",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4061",
    DESCRICAO: "Estrada Intermunicipal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4062",
    DESCRICAO: "Estrada Municipal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4063",
    DESCRICAO: "Estrada Nova",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4064",
    DESCRICAO: "Estrada Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4065",
    DESCRICAO: "Estrada Velha",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4066",
    DESCRICAO: "Estrada Vicinal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4067",
    DESCRICAO: "Fonte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4068",
    DESCRICAO: "Forte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4069",
    DESCRICAO: "Galeria",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4070",
    DESCRICAO: "Gleba",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4071",
    DESCRICAO: "Granja",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4072",
    DESCRICAO: "Ilha",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4073",
    DESCRICAO: "Jardim Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4074",
    DESCRICAO: "Jardinete",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4075",
    DESCRICAO: "Margem",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4076",
    DESCRICAO: "Marginal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4077",
    DESCRICAO: "Mercado",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4078",
    DESCRICAO: "Módulo",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4079",
    DESCRICAO: "Monte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4080",
    DESCRICAO: "Nova Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4081",
    DESCRICAO: "Núcleo Habitacional",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4082",
    DESCRICAO: "Núcleo Rural",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4083",
    DESCRICAO: "Outeiro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4084",
    DESCRICAO: "Parada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4085",
    DESCRICAO: "Paralela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4086",
    DESCRICAO: "Parque Municipal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4087",
    DESCRICAO: "Parque Residencial",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4088",
    DESCRICAO: "Passagem",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4089",
    DESCRICAO: "Passagem de Pedestres",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4090",
    DESCRICAO: "Passagem Subterrânea",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4091",
    DESCRICAO: "Passeio",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4092",
    DESCRICAO: "Passeio Público",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4093",
    DESCRICAO: "Ponta",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4094",
    DESCRICAO: "Ponte",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4095",
    DESCRICAO: "Porto",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4096",
    DESCRICAO: "Praça de Esportes",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4097",
    DESCRICAO: "Praia",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4098",
    DESCRICAO: "Prolongamento",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4099",
    DESCRICAO: "Quinta",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4100",
    DESCRICAO: "Ramal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4101",
    DESCRICAO: "Rampa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4102",
    DESCRICAO: "Reta",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4103",
    DESCRICAO: "Retiro",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4104",
    DESCRICAO: "Retorno",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4105",
    DESCRICAO: "Rodo Anel",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4106",
    DESCRICAO: "Rotatória",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4107",
    DESCRICAO: "Rótula",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4108",
    DESCRICAO: "Rua de Ligação",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4109",
    DESCRICAO: "Rua de Pedestre",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4110",
    DESCRICAO: "Rua Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4111",
    DESCRICAO: "Rua Principal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4112",
    DESCRICAO: "Rua Projetada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4113",
    DESCRICAO: "Rua Velha",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4114",
    DESCRICAO: "Rua Vicinal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4115",
    DESCRICAO: "Ruela",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4116",
    DESCRICAO: "Servidão de Passagem",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4117",
    DESCRICAO: "Subida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4118",
    DESCRICAO: "Terceira Avenida",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4119",
    DESCRICAO: "Terminal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4120",
    DESCRICAO: "Travessa Particular",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4121",
    DESCRICAO: "Trincheira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4122",
    DESCRICAO: "Túnel",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4123",
    DESCRICAO: "Unidade",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4124",
    DESCRICAO: "Vala",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4125",
    DESCRICAO: "Variante",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4126",
    DESCRICAO: "Variante da Estrada",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4127",
    DESCRICAO: "Via Coletora",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4128",
    DESCRICAO: "Via Costeira",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4129",
    DESCRICAO: "Via de Acesso",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4130",
    DESCRICAO: "Via de Pedestre",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4131",
    DESCRICAO: "Via de Pedestres",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4132",
    DESCRICAO: "Via Expressa",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4133",
    DESCRICAO: "Via Lateral",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4134",
    DESCRICAO: "Via Litoranea",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4135",
    DESCRICAO: "Via Local",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4136",
    DESCRICAO: "Via Marginal",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4137",
    DESCRICAO: "Via Pedestre",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "4805",
    DESCRICAO: "SHA",
  },
  {
    DOMINIO: "TIPO_LOGRADOURO",
    CODIGO: "5045",
    DESCRICAO: "1º Beco",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "952",
    DESCRICAO: "CELULAR",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "953",
    DESCRICAO: "COMERCIAL",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "954",
    DESCRICAO: "RESIDENCIAL",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "4723",
    DESCRICAO: "ADICIONAL",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "5006", 
    DESCRICAO: "FUNCIONARIO",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "5034",
    DESCRICAO: "CONTA SALARIO",
  },
  {
    DOMINIO: "TIPO_TELEFONE",
    CODIGO: "5037",
    DESCRICAO: "RISCO SACADO",
  },
  {
    DOMINIO: "PEP",
    CODIGO: "2",
    DESCRICAO: "Sim",
    SIGLA: "SIM",
  },
  {
    DOMINIO: "PEP",
    CODIGO: "1",
    DESCRICAO: "Não",
    SIGLA: "NAO",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "1",
    DESCRICAO: "Comunhão Parcial de Bens",
    SIGLA: "Comunhao Parcial de Bens",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "2",
    DESCRICAO: "Comunhão Total de Bens",
    SIGLA: "Comunhao Total de Bens",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "3",
    DESCRICAO: "Separação Total de Bens",
    SIGLA: "Separacao Total de Bens",
  },
  {
    DOMINIO: "CONJUGE_REGIME",
    CODIGO: "4",
    DESCRICAO: "Participação Final nos Aquestos",
    SIGLA: "Participacao Final nos Aquestos",
  },
];

export const obterDominio = (dominio) => {
  let result = dominios.filter((e) => e.DOMINIO == dominio);
  // console.log(result)
  return result;
};
