import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
           <React.Fragment>
               <footer className="footer">
                    ©  {new Date().getFullYear() - 1} -  {new Date().getFullYear()}<span className="text-muted d-none d-sm-inline-block float-right">RBCB</span>
                </footer>
           </React.Fragment>
        );
    }
}

export default Footer;
  





