import React from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import style from './dashboard.module.css'

export default function HorizontalBarInfo({ title, data }) {
    const YAxisRightTick = (props) => {
        const { x, y, stroke, payload } = props

        function formatNumberWithMi(number) {
            if (Math.abs(number) >= 1e2) {
                // return (number / 1e6).toFixed(3) + ' Mi'
                return (number / 1e6).toFixed(2) + ' Mi'
            } else {
                return number.toString()
            }
        }

        return (
            <g transform={`translate(${x + 10},${y - 12})`} >
                <rect x={-10} y={5} width="59" height="16" fill="#D9D9D9" rx="5" ry="5"></rect>
                <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="center"
                    fill="#000"
                    fontSize={10}
                    fontWeight={600}
                    alignmentBaseline="auto"
                >
                    {formatNumberWithMi(payload.value)}
                    {/* <p style={{ textAlign: 'right' }}> {formatNumberWithMi(payload.value)}</p> */}
                </text>
            </g>
        )
    }

    function CustomYAxisTick({ x, y, payload }) {
        return (
            <g transform={`translate(${x},${y})`} xlinkTitle="asdads">
                <text
                    width={10}
                    x={0}
                    y={0}
                    dy={0}
                    dx={-20}
                    textAnchor="middle"
                    fontSize={10}
                    fill="#fff"
                >
                    <title>{payload.value}</title>

                    <tspan x={0} y={7} dy={-4} dx={-30}>
                        {payload.value.substring(0, 10)}
                    </tspan>
                    {/* {yAxisLabelArr.length > 1 && (
                        <tspan x={0} y={0} dy={10} dx={-20}>
                            {yAxisLabelArr[1]}
                        </tspan>
                    )} */}
                </text>
            </g>
        )
    }

    return (
        <div className={style.shadowbox}
            style={{
                //minWidth: '345px',
                width: "100%",
                height: '465px',
                borderRadius: '20px',
                backgroundColor: '#17A2B8',
                padding: '10px 0px 0px',

            }}
        >
            <div className={style.graphTitle}>
                <p style={{ fontSize: "18px", color: "white", textAlign: "center", fontWeight: "600", textTransform: "capitalize" }}>{title}</p>
                {/* <h5 className="text-white text-center h5">{title}</h5> */}
                {title === 'Produção por Convênio' && (
                    <h6 className={style.subtitle}>10 Maiores</h6>
                )}
            </div>
            <ResponsiveContainer width="100%" height="100%" >
                <BarChart
                    width={100}
                    height={250}
                    data={data}
                    layout="vertical"
                    margin={{ left: 10, right: 20, top: 0, bottom: 40 }}
                    barSize={15}


                >
                    <XAxis hide axisLine={false} type="number" isAnimationActive={false} />
                    <YAxis
                        yAxisId={0}
                        dataKey='categoria'
                        type="category"
                        axisLine={false}
                        tickLine={false}
                        tick={<CustomYAxisTick />}
                        isAnimationActive={false}
                        fontSize={9}
                    />
                    <YAxis
                        orientation="right"
                        yAxisId={1}
                        dataKey="total_liquido"
                        type="category"
                        axisLine={false}
                        isAnimationActive={false}
                        tickLine={false}
                        tickFormatter={(value) => value.toLocaleString()}
                        tick={<YAxisRightTick />}
                    />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Bar
                        dataKey="total_liquido"
                        fill="#3E6C84"
                        type="number"


                        isAnimationActive={false}
                        radius={[0, 10, 10, 0]}

                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
