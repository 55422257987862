import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "./../../../layout";
import {
  mCPF,
  getCookie,
  mDate,
  mTel,
  formatFloat,
  formatCurrency,
  Aguarde
} from "../../../../helpers/general";
import {
  validateCPF,
  validateDate,
  validateEmail,
} from "../../../../helpers/validation";
import {
  sendData,
  sendMultiPartData,
  setSession,
} from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import {
  setProposta,
  setClienteDados,
  setSimulacao,
  setAlterarProposta,
} from "../../../../store/actions/dados";
import { swalError, confirmSwal } from "../../../swal";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import Simulacao from "./selSimulacao";
import * as moment from "moment";

const dataIni = { cpf: "", nome: "", ddn: "", telefone: "" };
function CreditoPessoal({ ...props }) {
  const [warning, setWarning] = useState({});
  const [consultando, setConsultando] = useState(false);
  const [dados, setDados] = useState(dataIni);
  const [simulacoes, setSimulacoes] = useState([]);
  const [show, setShow] = useState(false);
  const [simulacao, setSimulacao] = useState({});
  const [gravando, setGravando] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    console.log("CreditoPessoal.index....(useEffect)");
    if (props.alterarSimulacao) Simular();
  }, []);

  const validarDados = () => {
    let result = true;
    let _warning = {};

    if (!dados.cpf || !validateCPF(dados.cpf)) {
      _warning = { ..._warning, cpf: 1 };
      result = false;
    }

    if (!dados.nome) {
      _warning = { ..._warning, nome: 1 };
      result = false;
    }

    if (!dados.ddn || !validateDate(dados.ddn)) {
      _warning = { ..._warning, ddn: 1 };
      result = false;
    }

    console.log("CreditoPessoa.validarDados", _warning);
    setWarning(_warning);
    return result;
  };

  const Simular = (cpf) => {
    console.log("CreditoPessoal.Simular(1)");
    if (!props.alterarSimulacao && !validarDados()) return;

    console.log("CreditoPessoal.Simular(2)");
    setConsultando(true);
    setSimulacoes([]);
    let _dados = {
      cpf: props.alterarSimulacao ? props.detalhes.cpf : dados.cpf,
      nome: props.alterarSimulacao ? props.detalhes.nome : dados.nome,
      ddn: props.alterarSimulacao ? props.detalhes.ddn : dados.ddn,
      // valorOperacao: props.alterarSimulacao ? props.detalhes.valor_total : 0,
      valorParcela: props.alterarSimulacao ? props.detalhes.valor_parcela : 0,
      prazo: props.alterarSimulacao ? props.detalhes.prazo : 0,
    };
    _dados["ddn"] = _dados.ddn?.split("/").reverse().join("-");
    _dados["cpf"] = _dados.cpf?.replace(/\D/g, "");
    console.log("CreditoPessoal.Simular", _dados);

    sendData({
      uri: uriList("simulacaoOriginal"),
      content: _dados,
      signal: null,
      method: "POST",
    })
      .then(async (res) => {
        setConsultando(false);
        console.log("CreditoPessoal.Simular - res: ", res);
        if (res.status && res.status > 200) {
          let erro =
            (res.errors && res.errors[0]?.message) ||
            "Erro ao fazer simulação!";

          console.log(erro + ": ", res.message);

          swalError({ title: erro, text: res.data || res.message });
          // throw new Error();
        } else if (res.data) {
          let _simulacoes = res.data.simulations;
          console.log(_simulacoes);
          _simulacoes.sort(function (a, b) {
            console.log(`a: ${a.operationPeriod} b: ${b.operationPeriod}`);
            if (a.operationPeriod > b.operationPeriod) {
              return -1;
            }
            if (a.operationPeriod < b.operationPeriod) {
              return 1;
            }
            // a must be equal to b
            return 0;
          });

          console.log(res.data);
          setConsultando(false);
          setSimulacoes(_simulacoes);
          setData(res.data);
          // setSimulacoes(res.data.suggestedSimulations);
          console.log("CreditoPessoal.Simulacoes(teste Diogo): ", _simulacoes);
          if (_simulacoes?.length == 1 && props.alterarSimulacao) {
            await handleSimulation(_simulacoes[0]);
          } else setShow(true);
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: JSON.stringify(e.message) });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  };

  // const Aguarde = (props) => {
  //   return <Aguarde />;
  //   // return (
  //   //   <Modal
  //   //     size={"md"}
  //   //     scrollable
  //   //     show={true}
  //   //     animation={false}
  //   //     backdrop="static"
  //   //     centered
  //   //   >
  //   //     <Modal.Body
  //   //       className="maxWidth750"
  //   //       style={{
  //   //         width: "100%",
  //   //         justifyContent: "center",
  //   //         textAlign: "center",
  //   //       }}
  //   //     >
  //   //       <Spinner animation="border" />
  //   //       {"  "}
  //   //       {props.legenda || "Aguarde enquanto o processo é finalizado..."}
  //   //     </Modal.Body>
  //   //   </Modal>
  //   // );
  // };

  const carregarDados = async (cpf) => {
    console.log("CreditoPessoa.carregarDados", cpf);
    if (cpf)
      await sendData({
        uri: uriList("pessoa"),
        content: { cpf },
        signal: null,
        method: "POST",
      })
        .then(async (res) => {
          console.log("CreditoPessoal - carregarDados - res: ", res);
          setDados({
            ...dados,
            cpf,
            nome: res.data.nome,
            ddn: moment(res.data.ddn).format("DD/MM/YYYY"),
          });
        })
        .catch((e) => {
          console.log("CreditoPessoal - carregarDados - error: ", e);
        });
  };

  const handleCpf = (e) => {
    setWarning({ ...warning, cpf: 0 });
    let nums = /\d/;
    setDados({});
    let cpf = e.currentTarget.value;
    let valid = true;
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setDados({ ...dados, cpf });
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (!valid) setWarning({ ...warning, cpf: 1 });
        else carregarDados(cpf);
      }

      return false;
    } else if (!e.currentTarget.value) setDados({ ...dados, cpf: "" });

    return false;
  };

  const handleChange = (e) => {
    setWarning({ ...warning, [e.target.name]: 0 });
    let target = e.currentTarget;
    let name = target.name;
    let value = target.value;
    if (name === "ddn") setDados({ ...dados, [name]: mDate(value) });
    else setDados({ ...dados, [name]: value });
  };

  const handleBlur = (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let valor = target.value.toUpperCase();
    setWarning({ ...warning, [name]: 0 });
    handleChange(e);

    if (name === "ddn" && valor) {
      if (!validateDate(valor)) setWarning({ ...warning, ["ddn"]: 1 });
    } else if (name === "cpf" && valor) {
      if (!validateCPF(valor)) setWarning({ ...warning, ["cpf"]: 1 });
    }
  };

  const handleSimulation = async (_simulacao) => {
    setShow(false);
    // console.log("index.handleSimulation: ",)
    // setGravando(true);
    if (_simulacao) setSimulacao(_simulacao);
    else _simulacao = simulacao;
    let simulation = {
      status: "Simulacao",
      valor_total: formatFloat(_simulacao.fundedValue),
      valor_principal: formatFloat(_simulacao.requestedValue),
      valor_liquido: formatFloat(_simulacao.requestedValue),
      prazo: _simulacao.operationPeriod,
      valor_parcelas: formatFloat(_simulacao.trancheValue),
      iof: formatFloat(_simulacao.iofTotalValue),
      total_geral: formatFloat(_simulacao.fundedValue),
      taxa_juros_mensal: formatFloat(_simulacao.monthInterestRate),
      taxa_juros_anual: formatFloat(_simulacao.yearInterestRate),
      taxa_efetiva: formatFloat(_simulacao.monthlyCet),
      custo_efetivom: formatFloat(_simulacao.monthlyCet),
      custo_efetivoa: formatFloat(_simulacao.yearlyCet),
      custo_efetivo: formatFloat(_simulacao.yearlyCet),
      operacao: "CONTRATO NOVO",
      ultimo_vencimento: null,
      primeiro_vencimento: _simulacao.firstTrancheDueDate,
      valor_juros: 0,
      tipo_simulacao: "valor",
      tipo: "valor",
      convenio: data.nome_convenio,
      tabela_id: data.tabela_id,
      convenio_id: data.convenio_id,
      tipo_convenio: data.tipo_convenio,
      document: data.document,
      fullName: data.fullName,
      birthDate: data.birthDate,
      ..._simulacao,
    };
    if (props.alterarSimulacao) {
      await props.alterarSimulacao(simulation);
    } else {
      setSession({ obj: simulation, key: "status" });
      setSession({ obj: simulation, key: "result" });
      console.log("CreditoPessoal.index.handleSimulation: ", simulation);
    }
    if (props.toggleStep) props.toggleStep(1, _simulacao, simulation);
  };

  return (
    <>
      {show && (
        <Simulacao
          simulacoes={simulacoes}
          data={data}
          dados={dados}
          onCloseEv={() => setShow(false)}
          handle={handleSimulation}
        />
      )}
      {!props.alterarSimulacao && (
        <fieldset className="col-12" style={{ height: "100%" }}>
          <h4
            className="display-4 text-center font-weight-bold"
            style={{ marginBottom: "10px", color: "#3E6C84" }}
          >
            Crédito Pessoal
          </h4>

          <div className="form ml-1" style={{ overflow: "hidden" }}>
            <div className="row flex ">
              <div className="form-group col-md-4">
                <InputLabelAlert
                  type="tel"
                  label="CPF"
                  id="cpf"
                  value={dados.cpf}
                  handle={handleCpf}
                  autoComplete="off"
                  onBlur={handleBlur}
                  length="14"
                  focus={true}
                  onContextMenu={(e) => e.preventDefault()}
                />

                <InputAlert display={warning.cpf} messages={"CPF inválido."} />
              </div>
              <div className="form-group col-md-8">
                <InputLabelAlert
                  type="text"
                  label="Nome"
                  id="nome"
                  value={dados.nome}
                  handle={handleChange}
                  autoComplete="off"
                  onContextMenu={(e) => e.preventDefault()}
                />
                <InputAlert
                  display={warning.nome}
                  messages={"Campo inválido."}
                />
              </div>
              <div className="form-group col-md-3">
                <InputLabelAlert
                  type="tel"
                  label="Data de Nascimento"
                  id="ddn"
                  value={dados.ddn}
                  onBlur={handleBlur}
                  handle={handleChange}
                  warning={warning.ddn}
                  length="10"
                  focus={false}
                />
                <InputAlert
                  display={warning.ddn}
                  messages={"Campo inválido."}
                />
              </div>
            </div>

            <div className="row mt-5 d-flex ml-1 justify-content-center">
              <button
                style={{ backgroundColor: "#3E6C84", width: "30%" }}
                onClick={Simular}
                className="btn btn-md btn-info font-weight-bold mr-2"
              >
                Simular
              </button>
              <button
                style={{ backgroundColor: "#3E6C84", width: "30%" }}
                onClick={props.reRender}
                className="btn btn-md btn-info font-weight-bold mr-2"
              >
                Limpar
              </button>
            </div>
          </div>
        </fieldset>
      )}
      {consultando && <Aguarde />}
      {gravando && <Aguarde legenda={"Aguarde, gravando proposta..."} />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    setAlterarProposta(alterar, proposta) {
      dispatch(setAlterarProposta(alterar, proposta));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(CreditoPessoal);
