import React from "react";

import { InputAlert, InputLabelAlert } from "../../layout";

const Form = ({ ...props }) => (
  <>
    <div className="row">
      <div className="form-group col-md-5"> 
        <InputLabelAlert
          type="text"
          label="Nome do Pai"
          id="nome_pai"
          length="150"
          warning={props.warning.nome_pai}
          value={props.data.nome_pai}
          handle={props.handle}
          focus={true}
        />
      </div>

      <div className="form-group col-md-4">
        <label htmlFor="tipo_renda" className="no-margin">
          Tipo de Renda
        </label>
        <select
          id="tipo_renda"
          name="tipo_renda"
          value={props.data.tipo_renda}
          onChange={props.handle}
          style={{
            borderColor: props.warning.tipo_renda ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.tipo_renda &&
            props.tipo_renda.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>

      <div className="form-group col-md-3">
        <label htmlFor="ocupacao" className="no-margin">
          Ocupação
        </label>
        <select
          id="ocupacao"
          name="ocupacao"
          value={props.data.ocupacao}
          onChange={props.handle}
          style={{
            borderColor: props.warning.ocupacao ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.ocupacao &&
            props.ocupacao.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-5">
        <InputLabelAlert
          type="text"
          label="Nome do Cônjuge"
          id="conjuge_nome"
          length="150"
          warning={props.warning.conjuge_nome}
          value={props.data.conjuge_nome}
          handle={props.handle}
          focus={false}
        />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="profissao" className="no-margin">
          Profissão
        </label>
        <select
          id="profissao"
          name="profissao"
          value={props.data.profissao}
          onChange={props.handle}
          style={{
            borderColor: props.warning.profissao ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.profissao &&
            props.profissao.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="escola" className="no-margin">
          Situação Escolar
        </label>
        <select
          id="escola"
          name="escola"
          value={props.data.escola}
          onChange={props.handle}
          style={{
            borderColor: props.warning.escola ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.situacaoEscolaridade &&
            props.situacaoEscolaridade.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="escolaridade" className="no-margin">
          Escolaridade
        </label>
        <select
          id="escolaridade"
          name="escolaridade"
          value={props.data.escolaridade}
          onChange={props.handle}
          style={{
            borderColor: props.warning.escolaridade ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.escolaridade &&
            props.escolaridade.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="atividade" className="no-margin">
          Atividade
        </label>
        <select
          id="atividade"
          name="atividade"
          value={props.data.atividade}
          onChange={props.handle}
          style={{
            borderColor: props.warning.atividade ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.atividade &&
            props.atividade.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="tax_ir" className="no-margin">
          Taxa IR
        </label>
        <select
          id="tax_ir"
          name="tax_ir"
          value={props.data.tax_ir}
          onChange={props.handle}
          style={{
            borderColor: props.warning.tax_ir ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.tax_ir &&
            props.tax_ir.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Patrimônio"
          id="patrimonio"
          cifrao={true}
          value={props.data.patrimonio}
          step="0.010"
          handle={props.handle}
          warning={props.warning.patrimonio}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="pep" className="no-margin">
          Politicamente Exposto
        </label>
        <select
          id="pep"
          name="pep"
          value={props.data.pep}
          onChange={props.handle}
          style={{
            borderColor: props.warning.pep ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.pep &&
            props.pep.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.SIGLA}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="situacao_documento" className="no-margin">
          Situação Documento
        </label>
        <select
          id="situacao_documento"
          name="situacao_documento"
          value={props.data.situacao_documento}
          onChange={props.handle}
          style={{
            borderColor: props.warning.situacao_documento ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.situacao_documento &&
            props.situacao_documento.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="tel"
          label="Data da Situação"
          field={props.data.data_situacao}
          id="data_situacao"
          value={props.data.data_situacao}
          handle={props.handleDate}
          warning={props.warning.data_situacao}
          msgErro={"Data inválida!"}
          length="10"
          focus={false}
          onContextMenu={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <label htmlFor="permitir_bacen" className="no-margin">
          Permitir Consulta Bacen
        </label>
        <select
          id="permitir_bacen"
          name="permitir_bacen"
          value={props.data.permitir_bacen}
          onChange={props.handle}
          style={{
            borderColor: props.warning.permitir_bacen ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.permitir_bacen &&
            props.permitir_bacen.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="permitir_pcam" className="no-margin">
          Permitir Câmbio de Dados
        </label>
        <select
          id="permitir_pcam"
          name="permitir_pcam"
          value={props.data.permitir_pcam}
          onChange={props.handle}
          style={{
            borderColor: props.warning.permitir_pcam ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props.permitir_pcam &&
            props.permitir_pcam.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>
  </>
);

export default Form;
