import React, { useState, useEffect } from "react";

import { sendData, getData, setSession } from "../helpers/auth";
import { base64URLToGuid } from "../helpers/Uteis";
import { width } from "../helpers/general";
import { uriList } from "../helpers/config";
import Status from './status'
import Address from "../views/simulator/address";
import Contact from "../views/simulator/contact";
import Complement from "../views/simulator/complement";
import Bank from "../views/simulator/bank";
import Documents from "../views/simulator/documents";
import SimulatorLayout from "../components/layout";
import Login from "./loginClient";

import { connect } from "react-redux";
import { setProposta, setClienteDados } from "../store/actions/dados";
import LeilaoInssWelcome from "../views/leilaoInssWelcome";
import StatusResumo from "../views/statusResumo";

const _stepIni = { cur: -1, maxStep: 8, }
function PreencherDados({ ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(false);
  const [proposta, setProposta] = useState(false);
  const [step, setStep] = useState(_stepIni);

  useEffect(() => {
    console.log("preencherDados.useEffect");
    const _temp = props?.match?.params?.proposta
    let _proposta = base64URLToGuid(_temp)
    console.log("preencherDados.useEffect: ", { _temp, _proposta });

    //-- permitir informar a chave da proposta
    if (_temp.length > 30) {
      _proposta = props?.match?.params?.proposta
      console.log("preencherDados.useEffect(Maior que 30caracteres): ", _proposta);
    }

    console.log("preencherDados.useEffect(setProposta): ", _proposta);
    setProposta(_proposta);

    carregarDados(_proposta);
  }, []);

  const carregarDados = async (_proposta) => {
    setStep({ ...step, cur: -1 });
    console.log("[preencherDados.carregarDados(proposta)]: ", { proposta, _proposta });
    await sendData({ uri: uriList('acompanharProposta'), content: { uuid: _proposta || proposta }, method: 'POST' })
      .then(async (res) => {
        // res["multSelect"] = prop.multSelect
        console.log("portabilidades - handleClickTr(z): ", res);
        props.alterarProposta(res);
        setData(res);

        //-- Carregando dados do cliente
        await getData({ uri: uriList("pessoas") + "/" + res.cliente_id }).then(
          (res) => {
            console.log("preencherDados.carregarDados(Dados do cliente): ", res)
            props.setClienteDados(res);
          }
        );

        // setStep({ ...step, cur: 7 });

        if (res.posicao?.toString()?.toLowerCase() !== "simulacao")
          setStep({ ...step, cur: 10 });
        else
          setStep({ ...step, cur: 0 });

        setLoaded(true);
      })
      .catch(e => {
        console.log("portabilidades - handleClickTr(Error): ", e);
        setLoaded(true);
      });
  }

  const toggleStep = (n) => {
    console.log("preencherDados - toggleStep(0): ", n);
    if (!n || n === undefined) n = 1;
    let _next = step.cur + n;
    console.log("preencherDados - toggleStep(1)");
    if (_next >= 0 && _next <= step?.maxStep) {
      console.log("preencherDados - toggleStep(1.1): ", { _next, step });
      setStep({ ...step, cur: _next });
      console.log("preencherDados - toggleStep(2)");
      if (_next == step.maxStep) {
        setTimeout(() => {
          setStep({ ...step, cur: 10 });
        }, 10000);
      }
    }
  };


  const RenderScreen = ({ ...props }) => {

    return (
      {
        0: <LeilaoInssWelcome toggleStep={toggleStep} preencherDados={true} leilaoInss={true} />,

        1: <StatusResumo toggleStep={toggleStep} preencherDados={true} leilaoInss={true} />,

        2: <Login toggleStep={toggleStep} />,

        3: (
          <Documents
            title="Documentos"
            toggleStep={toggleStep}
            admin={false}
            temp={props?.detalhes}
            preencherDados={width() == "mobile"}
            leilaoInss={true}
          />
        ),

        4: <Address title="Endereço" toggleStep={toggleStep} admin={true} preencherDados={width() == "mobile"} leilaoInss={true} />,

        5: <Contact title="Contato" toggleStep={toggleStep} admin={true} preencherDados={width() == "mobile"} leilaoInss={true} />,

        6: <Bank title="Dados Bancários" toggleStep={toggleStep} admin={true} preencherDados={width() == "mobile"} leilaoInss={true} />,

        7: (
          <Complement title="Complemento" toggleStep={toggleStep} admin={true} preencherDados={width() == "mobile"} leilaoInss={true} />
        ),


        8: (
          //Exibir dados da proposta
          <>
            < div className="fundoInss" ></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <SimulatorLayout admin={true} leilaoInss={true}>
                {
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    backgroundColor: '#f0f0f0', // fundo cinza claro                    
                    overflowY: width() === "mobile" ? 'auto' : 'visible',
                    minHeight: width() === "mobile" ? '700px' : 'none',
                    maxHeight: width() === "mobile" ? '700px' : 'none',
                    height: '100vh', // Garantir que ocupe a altura total da viewport para centralização vertical
                  }}>
                    <h1>Sua proposta foi enviada e está em análise, em alguns instantes estará disponível para assinatura!</h1>
                  </div>
                }
              </SimulatorLayout>
            </div>
          </>
        ),
        10:
          <>
            < div className="fundoInss" ></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <SimulatorLayout admin={true} leilaoInss={true}>
                <div style={{
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  // textAlign: 'center',
                  backgroundColor: '#f0f0f0', // fundo cinza claro
                  // color: '#007bff', // cor da fonte azulada
                  overflowY: width() === "mobile" ? 'auto' : 'visible',
                  minHeight: width() === "mobile" ? '700px' : 'none',
                  maxHeight: width() === "mobile" ? '700px' : 'none',
                  marginTop: '15px', marginBottom: '15px', width: "100%",
                  height: width() === "mobile" ? '100vh' : "auto",
                }}>

                  <Status
                    preencherCli={true}
                    portabilidade={data.assinando_port}
                    data={data}
                    internalUser={false}
                    leilaoInss={true}
                  />
                </div>
              </SimulatorLayout>
            </div>
          </>,

      }[props.step] || (
        <>
          < div className="fundoInss" ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <SimulatorLayout admin={true} leilaoInss={true}>
              {
                (!loaded &&
                  <div
                    style={{
                      overflowY: width() == "mobile" ? 'auto' : 'visible',
                      minHeight: width() == "mobile" ? '700px' : 'none',
                      maxHeight: width() == "mobile" ? '700px' : 'none',
                    }}><h1 className=" col-12 text-center" style={{ color: "black", }}>Aguarde, carregando...</h1></div>) ||
                (loaded &&
                  <div
                    style={{
                      overflowY: width() == "mobile" ? 'auto' : 'visible',
                      minHeight: width() == "mobile" ? '700px' : 'none',
                      maxHeight: width() == "mobile" ? '700px' : 'none',
                    }}><h1 className=" col-12 text-center" style={{ color: "black", }}>Erro, proposta não encontrada!</h1></div>)
              }
            </SimulatorLayout>
          </div>
        </>
      )
    );
  };

  return (
    <>
      {step.cur >= step.maxStep ? (
        <RenderScreen step={step.cur} />
      ) : (
        <>
          < div className="fundoInss"  ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <SimulatorLayout admin={true} leilaoInss={true} preencherDados={true}>
              {([0].indexOf(step.cur) < 0) &&
                <div style={{ padding: "0px", }}>
                  {data &&
                    <h5
                      className=" col-12 text-center" style={{ color: "black", }}
                    >
                      <p style={{ fontSize: '13px', marginLeft: "15px", textDecoration: 'underline', fontFamily: 'Roboto' }}>{props?.detalhes?.cliente + ' - ' + props?.detalhes?.cpf}</p>
                    </h5>
                  }
                </div>}

              <RenderScreen step={step.cur} />
            </SimulatorLayout>
          </div>
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(PreencherDados);
