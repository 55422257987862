import React, { useState } from "react";
import { Button } from 'reactstrap';

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'react-bootstrap'
import Swal from './../../../components/swal'
import { sendMultiPartData, sendData, getData } from "../../../helpers/auth";
import { uriList } from '../../../helpers/config';

import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";

let permissoes = [
    {
        value: "todos", 
        nome: "Todos"
    },
    {
        value: "operador",
        nome: "Operador"
    },
    {
        value: "agente",
        nome: "Agente"
    },
    {
        value: "administrador",
        nome: "Administrador"
    },
]

const swalOptions = {
    title: 'Deseja realmente excluir o registro selecionado?',
    icon: 'warning',
    buttons: {
        cancel: {
            text: 'Não',
            value: null,
            visible: true,
            className: "",
            closeModal: true
        },
        confirm: {
            text: 'Sim',
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true
        }
    }
}


const Form = ({ ...props }) => {
    let {
        filterOptions,
        handleOption,
        emails,
        actualPage,
        handlePage,
        setClickedTr,
        sortArrObj,
        clickedTr,
        callback,
        setEmails
    } = props;

    const [show, setShow] = useState(false)
    const [id, setId] = useState(0)
    const [nome, setNome] = useState("")
    const [assunto, setAssunto] = useState("")
    const [corpo, setCorpo] = useState("")
    const [exibir_rodape, setExibir_Rodape] = useState(1)
    const [permissao, setPermissao] = useState("todos")
    const [warning, setWarning] = useState({
        nome: 0,
        assunto: 0,
        corpo: 0,
    })
    const [method, setMethod] = useState('POST')
    const [gravando, setGravando] = useState(false)    

    const handleClose = () => setShow(false);
    const handleEdit = async (id) => {
        getData({ uri: uriList("emails") + '/' + id })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();

                setNome(res.nome)
                setAssunto(res.assunto)
                setCorpo(res.corpo)
                setExibir_Rodape(res.exibir_rodape)
                setPermissao(res.permissao)
                setId(id)
                setMethod('PUT')
                setShow(true)
            })
            .catch((e) => {
                return false;
            });
    }

    const handleDelete = async (isConfirm, swal, id) => {
        if (isConfirm) {
            await sendData({ uri: uriList('emails') + '/' + id, content: {}, method: 'DELETE' })
                .then(res => {
                    if (res.status && parseInt(res.status) > 200) throw new Error();
                    // alert('Email excluído com sucesso!')
                    callback()
                })
                .catch(e => {
                    alert('Erro ao excluir email.')
                });
        }
    }

    const validar = () => {
        let _warning = {}
        if (!nome)
            _warning["nome"] = 1;

        if (!assunto)
            _warning["assunto"] = 1;

        if (!corpo)
            _warning["corpo"] = 1;

        setWarning(_warning)

        return (!_warning.nome && !_warning.corpo && !_warning.assunto)
    }

    const handlePost = async () => {
        setGravando(true)
        let dObj = new FormData();
        if (!validar())
            return

        dObj.append('nome', nome.toUpperCase());
        dObj.append('assunto', assunto.toUpperCase());
        dObj.append('corpo', corpo);
        dObj.append('exibir_rodape', exibir_rodape);
        dObj.append('permissao', permissao);

        await sendMultiPartData({ uri: uriList('emails') + ((method === "PUT") ? '/' + id : ""), content: dObj, method })
            .then(res => {
                if (res.status && parseInt(res.status) > 200) throw new Error(res.message[0].message);
                // alert('Email gravado com sucesso!')
                callback()
                setShow(false)
                setGravando(false)
            })
            .catch(e => {
                setGravando(false)
                alert(e)
            });
    }

    const handleNew = () => {
        setNome('')
        setNome('')
        setAssunto('')
        setCorpo('')
        setExibir_Rodape(0)
        setPermissao('todos')
        setMethod('POST')
        setId(0)
        setShow(true)
    }

    function Cadastrar() {
        return (
            <>

            </>)
    }

    return (
        <>
            <Modal size='lg' show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span>Cadastro de Emails</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="maxWidth750">
                    <div>
                        <InputLabelAlert
                            type='text'
                            label="Descrição"
                            id='nome'
                            length='150'
                            warning={warning.nome}
                            // minLength={5}
                            value={nome}
                            handle={e => { setNome(e.target.value); setWarning({ ...warning, nome: 0 }) }}
                            focus={true} />
                        <InputLabelAlert
                            type='text'
                            label="Assunto"
                            id='assunto'
                            length='150'
                            warning={warning.assunto}
                            // minLength={5}
                            value={assunto}
                            handle={e => { setAssunto(e.target.value); setWarning({ ...warning, assunto: 0 }) }}
                            focus={false} />
                        <label className="m-0 font-90">Corpo do Email</label>
                        <textarea
                            type="textArea"
                            style={{ bordercolor: warning.corpo ? 'red' : '' }}
                            rows="15"
                            className="form-control"
                            value={corpo}
                            warning={warning.corpo}
                            onChange={e => { setCorpo(e.target.value); setWarning({ ...warning, corpo: 0 }) }}
                        />
                        <label className="m-0 font-90">Permissões</label>
                        <select className="form-control" defaultValue={"todos"} onChange={e => setPermissao(e.target.value)}>
                            {permissoes.map((item, key) => {
                                return (
                                    <option key={key} value={item.value}>{item.nome}</option>
                                )
                            })

                            }

                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="success" disabled={gravando} onClick={handlePost}>
                        Gravar
    </Button>
                    <Button color="secondary" onClick={handleClose}>
                        Fechar
    </Button>
                </Modal.Footer>
            </Modal>
            <div className="col-md-4 py-1 px-0">
                <button
                    onClick={() => handleNew()}
                    className="btn btn-sm text-light"
                    title="Novo Email"
                    style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
                >
                    <i className="mdi mdi-plus-box" />
                </button>
                <button
                    onClick={() => window.print()}
                    className="btn btn-sm text-light"
                    title="Imprimir"
                    style={{ backgroundColor: "#00bdff" }}
                >
                    <i className="mdi mdi-printer" />
                </button>
            </div>

            <div className="row py-2">
                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Email</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="nome"
                    />
                </div>
            </div>

            {/* <div className='table-responsive'> */}
            <table className="table table-bordered table-striped">
                <thead>
                    <tr
                        title="Clique para ordenar"
                        style={{ backgroundColor: "#f2f2f2" }}
                    >
                        <th
                            scope="col"
                            className="font-80">#</th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj('nome', 'string')}
                        >
                            Email
                            </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj('assunto', 'string')}
                        >
                            Assunto
                            </th>
                    </tr>
                </thead>
                <tbody>

                    {emails.length > 0 ? (

                        emails.map(
                            (email, i) =>

                                i >= actualPage * 10 &&
                                i < (actualPage + 1) * 10 && (
                                    <tr key={i}
                                        className={`${
                                            clickedTr ===
                                                email.id
                                                ? "bg-secondary text-light"
                                                : ""
                                            }`}

                                        onClick={() => {
                                            setClickedTr(
                                                email.id
                                            );
                                        }}

                                        onContextMenu={(e) => {
                                            e.preventDefault();
                                            setClickedTr(
                                                email.id
                                            );
                                        }}

                                        onDoubleClick={e => handleEdit(email.id)
                                        }
                                    >
                                        <td>{i + 1}</td>
                                        <td>
                                            {email.nome}
                                        </td>
                                        <td >{email.assunto}</td>
                                        <td className="text-center">
                                            <button className="btn btn-sm btn-warning" title="Altera um registro" onClick={e => handleEdit(email.id)}><i className="mdi mdi-file-edit-outline"></i></button>
                                            <Swal options={swalOptions} id={email.id} title="Exclusão de Registro" callback={handleDelete} className="btn btn-sm btn-danger" style={{ marginLeft: "2px", cursor: "pointer" }} >
                                                <i className="mdi mdi-delete"></i>
                                            </Swal>
                                        </td>

                                    </tr>
                                )

                        )

                    ) : (
                            <tr>
                                <td>Sem dados</td>
                            </tr>
                        )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2" className='text-left'>
                            Total de Emails: {emails && emails.length}
                        </td>

                        <td colSpan="7"></td>
                    </tr>
                </tfoot>
            </table>
            {/* </div> */}
            <Pagination
                detailed={emails}
                actual={actualPage}
                changePage={handlePage}
            />
        </>
    );
};

export default Form;
