import React, { useState, useEffect } from "react";

import { post } from "axios";
import { connect } from "react-redux";

import Form from "../../components/form/simulator/result.js";
import {
  getSession,
  sendData,
  setSession,
  // sendMultiPartData,
} from "../../helpers/auth";
import { Buttons } from "../../components/layout";
import { uriList, enviroment, TOKEN_KEY, TOKEN_CAPTCHA } from "../../helpers/config";
import { getItemCrypt } from '../../helpers/auth'
import {
  getCookie,
  // width,
  formatFloat,
  Aguarde,
  exportAsImage,
} from "../../helpers/general";
import { desativarSistema } from "../../helpers/Uteis";
import { setProposta } from "../../store/actions/dados";
import { swalError } from "../../components/swal";
// import Geolocalizacao from "../../helpers/geolocalizacao.js";

function Result({ ...props }) {
  const [details, setDetails] = useState(0);
  const [result, setResult] = useState(
    props.result || getSession({ key: "resultado" })
  );
  const [userData, setUserData] = useState(
    props.data || getSession({ key: "status" }) || props.detalhes
  );
  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState({});
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [msgErro, setMsgErro] = useState("");
  const [msgObs, setMsgObs] = useState("");
  const [cnsMargem, setCnsMargem] = useState(false);
  const [getLocation, setGetLocation] = useState(true); //-- Mudar para false e habilitar exibição do Componente de Geolocalização

  useEffect(() => {
    console.log("result - userData: ", userData);
    console.log("result - result: ", result);
    // console.log("ContratosRefin: ", props.contratosRefin)
    // console.log("User: ", props.user)
    console.log("Dados dos Contratos: ", props.clienteDados);
    if (props.refinPort) submitRefinPort(false);
    else if (result) {
      console.log("result - useEffect(result): ", result);
      setObj({
        convenio_id: result.convenio_id || userData.convenio_id,
        tipo: result.tipo,
        valor: result.valor,
        prazo: result.prazo,
        operacao: result.operacao || "CONTRATO NOVO",
        gravar: !props.naoGravar ? "true" : "false",
        ip: getCookie({ label: "ip" }),
        proposta_uuid: props.proposta,
        cpf: result.cpf,
        contrato: result.contrato,
        contratos: result.contratos,
        tabela_id: result.tabela_id || userData.tabela_id,
        dadosRefin: result.dadosRefin,
        dadosPort: result.dadosPort,
      });
    }
    // eslint-disable-next-line
  }, []);

  const submitRefinPort = async (gravar) => {
    console.log("submitRefinPort - Inicio");
    console.log("result.submitRefinPort - Desativar Sistema?")
    //-- Diogo 16.05.2023 - Saindo da gravação se o sistema estiver desativado
    if (desativarSistema() && ["OPERADOR", 'ADMINISTRADOR'].indexOf(props?.user?.tipo) < 0) {
      swalError({ title: `Sistema indisponível para gravação de dados!` })
      return;
    }

    if (gravar) {
      let intervalid = 0;
      async function consultarMargem() {
        setMsgObs("Consultando liberação da margem...");
        setMsgErro("");
        setCnsMargem(true);
        await sendData({
          uri: uriList("consultarAverbacaoPortabilidade"),
          content: { uuid: props.proposta },
          method: "POST",
        })
          .then(async (res) => {
            console.log("submitRefinPort - consulta realizada: ", res.message);
            clearInterval(intervalid);
            setMsgObs(null);
            // console.log("Margem livre? ", res.message)
            if (res.message.toLowerCase() == "contrato ativo") {
              // console.log("submitRefinPort - Gravando CCB do Refin da Port")
              setSending(true);

              //-- Atribuindo proposta
              // content["refinPort"] = userData;
              userData["uuid"] = props.proposta;
              userData["forca"] = true;

              console.log(
                "submitRefinPort - Gravando CCB do Refin da Port: ",
                userData
              );

              //-- Enviando proposta de refin da port para assinatura
              await sendData({
                uri: uriList("assinarRefinPort"),
                content: userData,
                method: "POST",
              })
                .then((res) => {
                  if (res.status && parseInt(res.status) > 200)
                    throw new Error();
                  props.toggleStep(1);
                })
                .catch((error) => {
                  setSending(false);
                  setError(true);
                  setMsgErro("Erro ao enviar proposta para assinatura!");
                });
            } else {
              setMsgObs("");
              setMsgErro("!!!!! Margem não está liberada !!!!!");
            }
          })
          .catch((error) => {
            setMsgObs(null);
            setSending(false);
            setError(true);
            setMsgErro("Erro ao consultar liberação de Margem");
          });
      }

      consultarMargem();
    } else {
      console.log("submitRefinPort - Margem livre...: ", gravar);

      setLoading(true);
      let [latitude, longitude] = getCookie({ label: "geolocation" })
        ? getCookie({ label: "geolocation" }).split(",")
        : ["", ""];
      let content = {
        prazo: props.detalhes.prazo,
        tabela_id: props.detalhes.tabela_id,
        convenio_id: props.detalhes.convenio_id,
        valor: formatFloat(props.detalhes.valor_parcelas),
        tipo: "parcela",
        operacao: "REFINANCIAMENTO",
        contrato: props.detalhes.cedula_port || props.detalhes.cedula_numero,
        cpf: props.detalhes.cpf,
        ip: getCookie({ label: "ip" }),
        latitude,
        longitude,
        proposta_uuid: "",
        refinPort: true,
        agente_id:
          ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0
            ? props.user?.pessoa_id
            : null,
        operador_id: props.user?.pessoa_id,
      };
      console.log("Dados de Refin: ", content);
      sendData({
        uri: uriList("simulador"),
        content,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) {
            let erro = JSON.parse(res.erro || res.message);
            let msgErro = String(erro.msg || erro);
            console.log("Erro ao efetuar consulta: ", msgErro);
            setError(true);
            setMsgErro("Erro ao fazer simulação");
          }
          setUserData(res);
          setResult(content);
          setLoading(false);
          console.log("UserData (DadosRefin): ", res);
          // props.alterarProposta(res);
        })
        .catch((e) => {
          console.log(".....Erro ao simular.....");
          setError(true);
          setLoading(false);
          setMsgErro("Erro ao fazer simulação");
          // setSending(false)
        });
    }
  };

  const acompanharProposta = async (dados) => {
    sendData({
      uri: uriList("acompanharProposta"),
      content: { uuid: dados.uuid || dados.proposta_uuid },
      method: "POST",
    })
      .then((res) => {
        // let _dados = { ...result, ...userData, ...res }
        let _dados = res
        props.alterarProposta(_dados);
        console.log("result.acompanharProposta(Alterar Proposta): ", _dados);
      })
      .catch((e) => { });
  };

  const submitCP = async (dados) => {
    let _dados = { simulacao: userData, gravar: true };

    //-- Diogo 16.05.2023 - Saindo da gravação se o sistema estiver desativado
    console.log("result.submitCP - Desativar Sistema?")
    if (desativarSistema() && ["OPERADOR", 'ADMINISTRADOR'].indexOf(props?.user?.tipo) < 0) {
      swalError({ title: `Sistema indisponível para gravação de dados!` })
      return;
    }

    if (props.preencherCli) props.toggleStep();
    else {
      if (props.user) {
        _dados["user"] = props.user;
        _dados["operador_id"] = props.user?.pessoa_id;
        if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0)
          _dados["agente_id"] = props.user?.pessoa_id;
      }

      console.log("Result.submitCP", _dados);
      sendData({
        uri: uriList("simulacaoOriginal"),
        content: _dados,
        signal: null,
        method: "POST",
      })
        .then(async (res) => {
          // setGravando(false);
          console.log("CreditoPessoal.handleSimulation - res: ", res);
          if (
            (res.status && res.status > 200) ||
            (res._status && res._status > 200)
          ) {
            let erro = "Erro ao fazer simulação";
            console.log(erro + ": ", res.message);

            setError(true);
            setMsgErro(erro);
          } else {
            console.log(
              "CreditoPessoa.handleSimulation - res.data: ",
              res.data
            );
            setSession({ obj: res.data, key: "status" });
            await acompanharProposta(res.data);
            if (props.toggleStep) props.toggleStep(1, res.data);
          }
        })
        .catch((e) => {
          // setGravando(false);
          setError(true);
          setMsgErro(JSON.stringify(e.message));

          console.log("Erro de Simulação\n: ", e);
        });
    }
  };

  const gravarScreenShot = async (screenShot, uuid) => {
    console.log("result.gravarScreenShot(1): ", screenShot);
    // console.log("result.gravarScreenShot(1): ", screenShot);
    let dObj = new FormData();
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];

    dObj.append("tipo", "simulacao");
    dObj.append("proposta_uuid", uuid);
    dObj.append("file", screenShot);
    dObj.append("ip", getCookie({ label: "ip" }));
    dObj.append("geolocation", JSON.stringify({ latitude, longitude }));
    console.log("result.gravarScreenShot(2)");

    const url = uriList("documentos");
    const config = {
      headers: {
        Accept: "*",
        key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
        "content-type": "multipart/form-data",
        "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
        "captcha": getItemCrypt(TOKEN_CAPTCHA),
      },
    };

    console.log("result.gravarScreenShot(3)");
    post(url, dObj, config)
      .then((response) => {
        console.log("Result.gravarScreenShot(ok)");
      })
      .catch((err) => {
        console.log("Result.gravarScreenShot(error)");
      });
    console.log("result.gravarScreenShot(4)");
  };

  const submit = async (callback) => {
    //-- Saindo da execução caso seja geração de refin de portabilidade
    if (props.gerarRefinPort) {
      props.toggleStep(1);
      return;
    }

    console.log("result.submit(portabilidade?): ", props.dadosPort)

    //-- Diogo 16.05.2023 - Saindo da gravação se o sistema estiver desativado
    //-- PS: A Pedido do Alexandre a portabilidade não e "travada"
    if (!props.dadosPort && desativarSistema() && ["OPERADOR", 'ADMINISTRADOR'].indexOf(props?.user?.tipo) < 0) {
      swalError({ title: `Sistema indisponível para gravação de dados!` })
      return;
    }

    setSending(true);
    setLoading(true);
    console.log("Submit - Result: (1)");
    let screenShot;
    screenShot = await exportAsImage(
      "simulation-result",
      "simulationResult.jpg",
      props?.user?.name
    );
    // exportAsImage("simulation-financedata", "simulationResult.jpg");
    console.log("Submit - Result: (obj)", obj);
    if (props.preencherCli) {
      props.toggleStep();
    } else {
      if (props.user) {
        obj["user"] = props.user;
        obj["operador_id"] = props.user?.pessoa_id;
        if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0)
          obj["agente_id"] = props.user?.pessoa_id;
      }

      sendData({
        uri: uriList("simulador"),
        content: obj,
        signal: null,
        method: "POST",
      })
        .then(async (res) => {
          console.log("Submit - Result: (res)", res);
          let _uuid = res.uuid || res.proposta_uuid
          if (!_uuid && obj.gravar) {
            setError(true);
            setLoading(false);
            setSending(false);
            setMsgErro("Não foi possivel gravar a simulação(proposta)");
            if (callback) callback(false);
          } else {
            if (res.status && res.status > 200) throw new Error();
            setSession({ obj: res, key: "status" });

            setLoading(false);
            setSending(false);

            //-- Gravando tela de simulação
            if (_uuid && obj.gravar) {
              if (screenShot)
                await gravarScreenShot(screenShot, _uuid);

              await acompanharProposta(res);
              props.toggleStep(1);
            }
          }
        })
        .catch((e) => {
          console.log(".....Erro ao simular.....");
          setError(true);
          setLoading(false);
          setSending(false);
          setMsgErro("Erro ao fazer simulação");
          if (enviroment === "dev") {
            setSession({ obj: obj, key: "status" });
            props.toggleStep(1);
          }

          //-- Caso ocorra erro no envio habilita botão
          if (callback) callback(false);
        });
    }
  };

  function goBack() {
    setSending(false);
    setLoading(false);
    setError(false);
    if (!props.preencherCli && !props.gerarRefinPort) props.toggleStep(-1, obj);

    if (props.goBack) props.goBack();
  }

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>

      {(loading || sending) && (
        <Aguarde
          legenda={
            sending
              ? "Aguarde, enviando proposta..."
              : "Aguarde, simulando proposta..."
          }
        />
      )}
      {/* {sending && <Aguarde legenda={"Aguarde, enviando proposta..."} />} */}

      {msgObs && (
        <div
          className="font-wight-bold"
          style={{ textAlign: "center", width: "100%", color: "$primary" }}
        >
          <br />
          <h2 style={{ opacity: "60%" }}>{msgObs}</h2>
        </div>
      )}
      {!msgObs && (error || cnsMargem) && (
        <div
          className="font-wight-bold"
          style={{ textAlign: "center", width: "100%", color: "red" }}
        >
          <br />
          <h2 style={{ opacity: "60%" }}>{msgErro}</h2>
        </div>
      )}
      {/* {!getLocation && <Geolocalizacao onCloseEv={() => setGetLocation(true)} />} */}
      {(getLocation || props.gerarRefinPort) && (
        <>
          <Form
            result={userData}
            userData={userData}
            setDetails={setDetails}
            details={details}
            goBack={goBack}
            admin={props.admin}
            clearError={() => setError(false)}
            portabilidade={props.portabilidade}
          />

          <Buttons
            first={props.goBack ? "Voltar" : "Nova Simulação"}
            second="Avançar"
            goBack={goBack}
            disabledSecond={loading || sending || error || props.disabledSecond}
            submit={() =>
              props.refinPort
                ? submitRefinPort(true)
                : userData.tipo_convenio?.toLowerCase() == "original"
                  ? submitCP()
                  : submit()
            }
          />
        </>
      )}
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
    detalhes: state.dados.detalhes,
    preencherCli: state.dados.preencherCli,
    contratosRefin: state.dados.contratosRefin,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novoProposta) {
      dispatch(setProposta(novoProposta));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Result);
