import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/complement_sicoob";

import { Buttons } from "../../components/layout";
import { cidades } from "../../helpers/cidades";
import { mDate, getCookie, mTel, mCPF } from "../../helpers/general";
import { sendData, setSession } from "../../helpers/auth";
import {
  validateMonthDate,
  validateDate,
  validateCPF,
} from "../../helpers/validation";
import { obterDominio } from "../../helpers/original";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";
import { setTDoc } from "../../store/actions/dados";

import { connect } from "react-redux";

function Complement({ ...props }) {
  const [escolaridade, setEscolaridade] = useState(
    obterDominio("ESCOLARIDADE")
  );
  const [profissao, setProfissao] = useState(obterDominio("PROFISSAO"));
  const [conjuge_regime, setConjugeRegime] = useState(
    obterDominio("CONJUGE_REGIME")
  );

  const [data, setData] = useState({
    apelido: "",
    escolaridade: escolaridade[0].CODIGO,
    profissao: profissao[0].CODIGO,
    conjuge_nome: "",
    conjuge_cpf: "",
    conjuge_regime: conjuge_regime[0].SIGLA,
    referencia1_nome: "",
    referencia1_telefone: "",
    referencia2_nome: "",
    referencia2_telefone: "",
    dependentes: 0,
    telefone_rh: "",
    data_admissao: null,
    telefone_comercial: "",
    id: props.cliente,
    ip: getCookie({ label: "ip" }),
    proposta_uuid: props.proposta,
  });

  const [showAlert, setAlert] = useState({
    t_doc: 0,
    num_doc: 0,
    orgao: 0,
    uf_exp: 0,
    data_exp: 0,
  });

  const [alternate, setAlternate] = useState({
    data_exp: 0,
  });

  const [alternateMessage, setAlternateMessage] = useState({
    data_exp: "",
  });

  function handleChange(e, innerData) {
    let target = e.currentTarget;
    let value = innerData || target.value;
    setAlert({ ...showAlert, [target.name]: 0 });
    if (
      [
        "referencia1_telefone",
        "referencia2_telefone",
        "telefone_rh",
        "telefone_comercial",
      ].indexOf(target.name) >= 0
    ) {
      let tel = mTel(target.value);
      setData({ ...data, [target.name]: tel });
    } else if (target.name === "conjuge_cpf") {
      let cpf = mCPF(e.currentTarget.value);
      if (cpf.length <= 14) {
        setData({ ...data, [target.name]: cpf });
        setAlert({
          ...showAlert,
          [target.name]: cpf.length === 14 && !validateCPF(cpf) ? 1 : 0,
        });
      }
    } else if (target.name === "dependentes") {
      setData({ ...data, [target.name]: value.replace(/\D/g, "") });
    } else if (
      data[target.name] &&
      data[target.name].length === 0 &&
      target.value === ""
    ) {
      setAlert({ ...showAlert, [target.name]: 1 });
    } else {
      setData({ ...data, [target.name]: value });
    }
  }

  function handleDate(e) {
    let nums = /[0-9]/;
    let target = e.currentTarget;
    let ddn = mDate(target.value);

    handleChange(e, ddn);
    setAlternate(0);

    if (nums.test(target.value)) {
      // console.log("Validate - date", target);

      if (ddn.length === 10 && !validateDate(ddn))
        setAlert({ ...showAlert, [target.name]: 1 });

      return false;
    }

    return false;
  }

  async function submit(callback) {
    if (true) {
      let newData = Object.assign(data);

      for (let n in newData) {
        if (newData[n] && typeof newData[n] === "string")
          newData[n] = newData[n].toUpperCase();
      }

      await sendData({
        uri: uriList("pessoas"),
        content: { ...newData, proposta_uuid: props.proposta, },
        method: "POST",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setSession({
            obj: newData,
            key: "complemento",
          });

          props.toggleStep(1);
          props.alterarTDoc(data.t_doc);
          // console.log("Alterar Tipo Doc: ", data.t_doc)
        })
        .catch((e) => {
          if (callback) callback(false);

          alert("Erro ao gravar dados.");
        });

      for (let d in data) props.clienteDados[d] = data[d];

      return false;
    } else if (callback) callback(false);
  }

  useEffect(() => {
    console.log("Complemento Sicoob - UseEffect: ", props.clienteDados);
    for (let d in data)
      if (props.clienteDados[d])
        if (d == "data_admissao") {
          let date = new Date(props.clienteDados[d]);
          if (!isNaN(date)) date = date.toLocaleDateString();
          else date = mDate(props.clienteDados[d]);

          setData((previous) => ({ ...previous, [d]: date }));
        } else
          setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));
  }, []);

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>
      <div className="form">
        <Form
          data={data}
          handle={handleChange}
          warning={showAlert}
          handleDate={handleDate}
          alternate={alternate}
          alternateMessage={alternateMessage}
          escolaridade={escolaridade}
          profissao={profissao}
          conjuge_regime={conjuge_regime}
        />
      </div>

      <Buttons
        goBack={() => goBack(data, "complemento", props.toggleStep)}
        submit={submit}
      />
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarTDoc(t_doc) {
      dispatch(setTDoc(t_doc));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Complement);
