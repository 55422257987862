import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/complement_original";

import { Buttons } from "../../components/layout";
import { mDate, getCookie, formatCurrency } from "../../helpers/general";
import { sendData, setSession } from "../../helpers/auth";
import { obterDominio } from "../../helpers/original";
import { validateMonthDate, validateDate } from "../../helpers/validation";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";
import { setTDoc } from "../../store/actions/dados";

import { connect } from "react-redux";

function Complement({ ...props }) {
  const [tipo_renda, setTipoRenda] = useState(obterDominio("TIPO_FONTE_RENDA"));
  const [ocupacao, setOcupacao] = useState(obterDominio("OCUPACAO"));
  const [profissao, setProfissao] = useState(obterDominio("PROFISSAO"));
  const [escolaridade, setEscolaridade] = useState(
    obterDominio("ESCOLARIDADE")
  );
  const [atividade, setAtividade] = useState(obterDominio("TIPO_ATIVIDADE"));
  const [situacaoEscolaridade, setSituacaoEscolaridade] = useState(
    obterDominio("SITUACAO_ESCOLARIDADE")
  );
  const [tax_ir, setTaxIR] = useState(obterDominio("IR_IOF"));
  const [pep, setPep] = useState(obterDominio("PEP"));
  const [situacao_documento, setSituacaoDocumento] = useState(
    obterDominio("SITUACAO_CPF")
  );
  const [permitir_bacen, setPermitirBacen] = useState(obterDominio("PEP"));
  const [permitir_pcam, setPermitirPcam] = useState(obterDominio("PEP"));

  const [data, setData] = useState({
    nome_pai: "",
    tipo_renda: tipo_renda[0].CODIGO,
    ocupacao: ocupacao[0].CODIGO,
    profissao: profissao[0].CODIGO,
    escolaridade: escolaridade[0].CODIGO,
    atividade: atividade[0].CODIGO,
    tax_ir: tax_ir[0].CODIGO,
    pep: pep[0].SIGLA,
    situacao_documento: situacao_documento[0].CODIGO,
    permitir_bacen: permitir_bacen[0].CODIGO,
    permitir_pcam: permitir_pcam[0].CODIGO,
    conjuge_nome: "",
    escola: "",
    patrimonio: 0,
    data_situacao: null,
    id: props.cliente,
    ip: getCookie({ label: "ip" }),
    proposta_uuid: props.proposta,
  });

  const [showAlert, setAlert] = useState({});

  const [alternate, setAlternate] = useState({
    data_exp: 0,
  });

  const [alternateMessage, setAlternateMessage] = useState({
    data_exp: "",
  });

  function handleChange(e, innerData) {
    let target = e.currentTarget;
    let value = innerData || target.value;

    setData({ ...data, [target.name]: value });

    if (target.name == "patrimonio") {
      setData({ ...data, patrimonio: formatCurrency(value) });
    } else if (
      data[target.name] &&
      data[target.name].length === 0 &&
      target.value === ""
    ) {
      setAlert({ ...showAlert, [target.name]: 1 });
    } else {
      clearAlert(e);
    }
  }

  function handleDate(e) {
    let nums = /[0-9]/;
    let target = e.currentTarget;
    let ddn = mDate(target.value);

    handleChange(e, ddn);
    setAlternate(0);

    if (nums.test(target.value)) {
      // console.log("Validate - date", target);

      if (ddn.length === 10 && !validateDate(ddn))
        setAlert({ ...showAlert, [target.name]: 1 });

      return false;
    }

    return false;
  }

  function clearAlert(e) {
    let target = e.currentTarget;

    setAlert({ ...showAlert, [target.name]: 0 });
  }

  async function submit(callback) {
    if (true) {
      let newData = Object.assign(data);

      for (let n in newData) {
        if (newData[n] && typeof newData[n] === "string")
          newData[n] = newData[n].toUpperCase();
      }

      if (newData.patrimonio) {
        newData.patrimonio = newData.patrimonio
          .replace(".", "")
          .replace(",", ".");
        newData.patrimonio = parseFloat(newData.patrimonio);
      }

      await sendData({
        uri: uriList("pessoas"),
        content: { ...newData, proposta_uuid: props.proposta, },
        method: "POST",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setSession({
            obj: newData,
            key: "complemento",
          });

          props.toggleStep(1);
          props.alterarTDoc(data.t_doc);
          // console.log("Alterar Tipo Doc: ", data.t_doc)
        })
        .catch((e) => {
          if (callback) callback(false);

          alert("Erro ao gravar dados.");
        });

      for (let d in data) props.clienteDados[d] = data[d];

      return false;
    } else if (callback) callback(false);
  }

  useEffect(() => {
    console.log("Complemento Original - UseEffect: ", props.clienteDados);
    for (let d in data)
      if (props.clienteDados[d])
        if (d == "data_situacao") {
          let date = new Date(props.clienteDados[d]);
          if (!isNaN(date)) date = date.toLocaleDateString();
          else date = mDate(props.clienteDados[d]);

          setData((previous) => ({ ...previous, [d]: date }));
        } else if (d == "patrimonio")
          setData((previous) => ({
            ...previous,
            [d]: formatCurrency(parseFloat(props.clienteDados[d]).toFixed(2)),
          }));
        else
          setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));
  }, []);

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>
      <div className="form">
        <Form
          data={data}
          handle={handleChange}
          warning={showAlert}
          handleDate={handleDate}
          alternate={alternate}
          alternateMessage={alternateMessage}
          tipo_renda={tipo_renda}
          ocupacao={ocupacao}
          profissao={profissao}
          escolaridade={escolaridade}
          situacaoEscolaridade={situacaoEscolaridade}
          atividade={atividade}
          tax_ir={tax_ir}
          pep={pep}
          situacao_documento={situacao_documento}
          permitir_bacen={permitir_bacen}
          permitir_pcam={permitir_pcam}
        />
      </div>

      <Buttons
        goBack={() => goBack(data, "complemento", props.toggleStep)}
        submit={submit}
      />
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarTDoc(t_doc) {
      dispatch(setTDoc(t_doc));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Complement);
