import React, { useState, useEffect } from "react";

import SimulatorLayout from "../components/layout";
import { connect } from "react-redux";
import Simulator from "../views/simulator/simulator";
import SimulatorCPF from "../views/simulator/simulatorCPF";
import Result from "../views/simulator/result";
import Data from "../views/simulator/data";
import Address from "../views/simulator/address";
import Contact from "../views/simulator/contact";
// import Complement from '../views/simulator/complement';
import Bank from "../views/simulator/bank";
import Documents from "../views/simulator/documents";
import Selfie from "../views/simulator/selfie";
import { setProposta } from "../store/actions/dados";

function Main({ ...props }) {
  const [step, setStep] = useState({
    cur: props.screen,
    maxStep: 7,
  });

  useEffect(() => {
  }, []);

  function toggleStep(n) {
    let next = step.cur + n; 
    if (next <= 0) {
      props.alterarProposta("limpar");
    }

    if (step.cur >= step.maxStep && n === 1)
      return setStep({ ...step, cur: 0 });
    setStep({ ...step, cur: next });
  }

  function jumpToStep(n) {
    setStep({ ...step, cur: step.cur + n });
  }

  const RenderScreen = ({ ...props }) =>
    ({
      // '0': <Embed />,

      0: (
        <Simulator
          title="Simulador"
          alteracao={false}
          toggleStep={toggleStep}
          jump={jumpToStep}
        />
      ),

      1: <Result title="Simulação" toggleStep={toggleStep} />,

      2: <Data title="Dados Pessoais" toggleStep={toggleStep} />,

      3: <Address title="Endereço" toggleStep={toggleStep} />,

      4: <Contact title="Contato" toggleStep={toggleStep} />,

      5: <Bank title="Dados Bancários" toggleStep={toggleStep} />,

      // '6': <Complement
      // 	title='Complemento'
      // 	toggleStep={toggleStep} />,

      6: <Documents title="Documentos" toggleStep={toggleStep} />,

      7: <Selfie title="Selfie" toggleStep={toggleStep} />,
    }[props.step] || (
      <>
        <h1>404!</h1>
        <h4>Esse formulário não existe!</h4>
      </>
    ));

  return (
    <>
      <SimulatorLayout>
        <RenderScreen step={step.cur} />
      </SimulatorLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Main);
