import React, { useEffect } from "react";
import Simulador from "./index";
import { sendData } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import {
  setProposta,
  setClienteDados,
  setSimulacao,
  setAlterarProposta,
} from "../../../../store/actions/dados";

const AlterarSimulacao = (props) => {
  useEffect(() => {
    console.log("AlterarSimulação....(useEffect)");
  }, []);

  async function alterarSimulacao(simulacao) {
    console.log("alterarSimulacao: ", simulacao);
    console.log("alterarSimulacao(prop): ", props.detalhes);
    await sendData({
      uri: uriList("alterarSimulacaoOriginal"),
      content: {
        uuid: props.detalhes?.uuid || props.detalhes?.proposta_uuid,
        simulacao,
      },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        if (props.onCloseEv) props.onCloseEv();
      })
      .catch((e) => {
        alert("Erro ao alterar simulação.");
      });
  }

  return <Simulador alterarSimulacao={alterarSimulacao} />;
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    setAlterarProposta(alterar, proposta) {
      dispatch(setAlterarProposta(alterar, proposta));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(AlterarSimulacao);
