import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import '../../../sass/login.scss';
import Logo from '../../../images/logo-inteira.png';
// import Logo from '../../../images/secure_login.svg';

import { width } from '../../../helpers/general';
import { sendData, } from "../../../helpers/auth";
import { validateEmail } from '../../../helpers/validation';
import { uriList } from '../../../helpers/config';
import swal from 'sweetalert';
import { connect } from "react-redux";

const Form = ({ ...props }) => {
    const [novaSenha, setNovaSenha] = useState(false)
    const [urlUserLogo, setUrlUserLogo] = useState(props.user?.url_logo)
    const [captcha, setCaptcha] = useState("");


    function onClickBtn(e) {
        console.log("login.onClickBtn")
        if (captcha)
            props.submit(e, captcha)
    }

    useEffect(() => {
        console.log("[LOGIN-VIEW] useEffect(2): ", props.MfaAuthentication)
    }, [props.MfaAuthentication])

    useEffect(() => {
        console.log("[LOGIN-VIEW] useEffect(1) ")
    }, [])

    function onClickBtnMfa(e) {
        console.log("login.onClickBtn")
        if (props.data["tokenMFA"])
            props.submitmfa(e)
    }

    function handleCaptcha(value) {
        // console.log("login.handleCaptcha: ", value)
        setCaptcha(value)
    }

    const handleNovaSenha = (e) => {
        console.log("login.handleNovaSenha")
        e.preventDefault();
        if (props.data.email && validateEmail(props.data.email)) {
            console.log("login.handleNovaSenha(1)")
            sendData({ uri: uriList('recuperarSenha'), content: { email: props.data.email }, method: 'POST' })
                .then(res => {
                    console.log("login.handleNovaSenha(OK)")
                    if (res.status && parseInt(res.status) > 200) throw new Error();
                    swal({
                        title: "Alteração de Senha",
                        text: "Acesse o email informado e siga as instruções...",
                        timer: 7000
                    })
                        .then(p => {
                            setNovaSenha(false)
                        });

                })
                .catch(e => {
                    console.log("login.handleNovaSenha(Erro)")
                    console.log(e)
                    // alert("Erro ao tentar recuperar Senha")
                });
        }
    }

    const NovaSenha = () => {
        return (
            <>
                <div className="form-group">
                    <label
                        style={{ fontSize: '1em' }}
                        htmlFor="novaSenha">Email</label>
                    <input
                        type="email"
                        className={`form-control ${props.warning?.email ? 'is-invalid' : ''}`}
                        id="email"
                        name="email"
                        required
                        value={props.data?.email}
                        onChange={props.handle}
                        autoFocus={true}
                        placeholder="Ex.: exemplo@exemplo.com"
                    />
                </div>

                <div className="text-right">
                    <button
                        className="btn btn-success w-md waves-effect waves-light"
                        onClick={handleNovaSenha}
                        style={{ cursor: "pointer" }}
                    >
                        Nova Senha
                    </button>
                </div>

            </>
        )
    }

    return (
        <>
            {/* {width() !== 'mobile' && <div className="accountbg"></div>} */}
            < div className="accountbg" ></div>
            <div
                style={{
                    maxHeight: width() === 'mobile' ? (window.innerHeight + 30) + 'px' : novaSenha ? '15em' : '46em',
                    backgroundColor: width() === 'mobile' ? '#ffffff' : '',
                    overflowY: width() === 'mobile' ? 'auto' : 'hidden',
                    margin: "auto"
                }}
                // className={`col-md-5 mx-auto ${width() !== 'mobile' && 'card'}`}>
                className={`col-md-5 mx-auto card`}>
                <h3 className="text-center m-0">
                    <Link
                        to="/"
                        className="logo logo-admin"
                        title="Sistema"
                    >
                        <img src={Logo}
                            style={{
                                width: width() !== "mobile" ? "200px" : "100px"
                            }}

                            alt="Login" />
                    </Link>
                </h3>

                <br />
                {
                    <>
                        {novaSenha &&
                            <NovaSenha />
                        }

                        {!novaSenha && !props.MfaAuthentication &&
                            <>
                                <div className="form-group">
                                    <label
                                        style={{ fontSize: '1em' }}
                                        htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className={`form-control ${props.warning.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"
                                        required
                                        value={props.data.email}
                                        onChange={props.handle}
                                        autoFocus={true}
                                        placeholder="Ex.: exemplo@exemplo.com"
                                    />

                                    <div className="invalid-feedback">
                                        Email é inválido ou não existe!
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label
                                        style={{ fontSize: '1em' }}
                                        htmlFor="password">Senha</label>
                                    <input
                                        type="password"
                                        className={`form-control ${props.warning.password ? 'is-invalid' : ''}`}
                                        id="password"
                                        name="password"
                                        required
                                        placeholder="Digite sua Senha"
                                        value={props.data.password}
                                        onChange={props.handle}
                                    />

                                    <div className="invalid-feedback">
                                        A senha é obrigatória!
                                    </div>
                                </div>

                                <div className="form-group row" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="col-sm-6">
                                        <div className="custom-control mt-2 custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customCheck1"
                                                checked={props.data.connected}
                                                onChange={props.checked}
                                                name="connected"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customCheck1"
                                            >
                                                Manter Conectado
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-0 row">
                                    <div className="col-12 m-t-20">
                                        <span onClick={() => setNovaSenha(true)} style={{ cursor: "pointer" }}><i className="mdi mdi-lock"></i> Esqueceu sua Senha?</span>
                                    </div>
                                </div>

                                <div className="form-group" >
                                    <div style={{ display: "flex", justifyContent: "center", }}>
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            size={width() == "mobile" ? "compact" : "normal"}
                                            onChange={handleCaptcha} />
                                    </div>
                                </div>

                                <div className="text-right" style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                    <button
                                        className="btn btn-success w-md waves-effect waves-light"
                                        onClick={onClickBtn}
                                        disabled={!captcha}
                                        style={{ cursor: "pointer", width: "40%" }}
                                    >
                                        Acessar
                                    </button>
                                </div>


                            </>
                        }
                        {props.MfaAuthentication &&
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "25px" }}>
                                    <label
                                        style={{ fontSize: '1em' }}
                                        htmlFor="tokenMFA">Codigo MFA</label>
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        id="tokenMFA"
                                        name="tokenMFA"
                                        required
                                        value={props.data["tokenMFA"]}
                                        autoFocus={true}
                                        onChange={props.handle}
                                        placeholder="Código Autenticação MFA"
                                    />
                                </div>
                                <div className="text-right" style={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "20px" }}>
                                    <button
                                        className="btn btn-success w-md waves-effect waves-light"
                                        onClick={onClickBtnMfa}
                                        disabled={!captcha}
                                        style={{ cursor: "pointer", width: "40%" }}
                                    >
                                        Acessar
                                    </button>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </>
    )
};

function mapStateToProps(state) {
    return {
        user: state.dados.user,
    };
}


export default connect(mapStateToProps,)(Form);


