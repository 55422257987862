import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { uriList } from "../../../../helpers/config";
import { sendData } from "../../../../helpers/auth";
import { swalWarning } from "./../../../../components/swal";
import { formatCurrency, width } from "../../../../helpers/general";
import ModalContent from "../../../../components/layout/ModalContent";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import * as moment from "moment";

const AuditBancaria = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [clickedItem, setClickedItem] = useState({})

  function handleClose(e, close) {
    if (e) e.preventDefault();
    if (props.onCloseEv) props.onCloseEv(close);
  }

  useEffect(() => {
    getData()
  }, []);


  async function getData() {
    setLoading(true)
    await sendData({
      uri: uriList("auditoriaBancaria"),
      content: { uuid: props.proposta },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("AuditBancaria.index - data: ", res);
        // if (res?.length > 0)
        setData(res?.data)
        // else
        // throw new Error("Não foi possível carregar o log da proposta")
        setLoading(false)
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        return false;
      });
  }

  function handleClick(data) {
    setClickedItem(data)
    const jsonPayload = {
      payload: data?.payload?.trim(),
      url: data?.url?.trim(),
    };
    if (navigator && navigator.clipboard) navigator.clipboard.writeText(JSON.stringify(jsonPayload));
  }

  function Content() {
    const containerStyle = {
      width: '100%', // Define a largura da div
      // maxWidth: '400px', // Define a largura máxima para garantir que não fique muito larga
      margin: '0 auto', // Centraliza a div
      // padding: '10px', // Adiciona um pouco de espaço interno
      // border: '1px solid #ccc', // Adiciona uma borda para visualização
      overflowWrap: 'break-word', // Quebra palavras longas para evitar overflow
      overflow: 'hidden' // Esconde qualquer conteúdo que ultrapasse as bordas (alternativamente, use 'scroll' para adicionar barras de rolagem)
    };

    return (
      <>
        <div>
          <ListGroup>
            <ListGroup.Item
              style={{ backgroundColor: "#E8E8E8", color: "#636363", maxWidth: "100%", padding: "5px", }}
            >
              <strong>Cliente:</strong>{" "}
              {props.detalhes
                ? props.detalhes.nome +
                " - " +
                props.detalhes.cpf +
                " - " +
                props.detalhes.celular
                : ""}
              <br />
              <strong>Tipo de Operação:</strong>{" "}
              {props.detalhes ? props.detalhes.operacao : ""}
              <br />
              <strong>Status Atual:</strong>{" "}
              {props.detalhes ? props.detalhes.posicao : ""}
              <strong> Data:</strong>{" "}
              {props.detalhes
                ? moment(props.detalhes.status_data).format("DD/MM/YYYY")
                : ""}
              <br />
              <strong>Valor R$:</strong>{" "}
              {props.detalhes ? formatCurrency(props.detalhes.valor_liquido) : ""}
              <strong> Parcela R$:</strong>{" "}
              {props.detalhes
                ? formatCurrency(props.detalhes.valor_parcelas)
                : ""}
              <strong> Prazo:</strong>{" "}
              {props.detalhes ? props.detalhes.prazo : ""}
              <br />
              <strong>Proposta:</strong>{" "}
              {props.detalhes ? props.detalhes.proposta_id : ""}
              <strong> Ccb:</strong>{" "}
              {props.detalhes ? props.detalhes.cedula_numero : ""}
              <strong> uuid:</strong>{" "}
              {props.detalhes ? props.detalhes.proposta_uuid : ""}
            </ListGroup.Item>
            {data &&
              data.map((option, i) => {
                let d = new Date(option.created_at);

                return (
                  <ListGroup.Item
                    key={i}
                    style={{ color: "#636363", padding: "0px", backgroundColor: clickedItem?.id == option?.id ? "#EDFFED" : "" }}
                  >
                    <div className="row" onClick={() => handleClick(option)}>
                      <div
                        className={` ${width() == "mobile" ? "col-12" : "col-9"
                          } `}
                      >
                        <div className="row ml-1 col-12 d-flex mb-2 justify-content-between p-0 mt-2">
                          <div className="col-12">
                            <strong>Payload</strong>
                            <br></br>
                          </div>
                        </div>
                        <div className="row ml-1 col-12">
                          <div className="row col-12">
                            <strong>Url: </strong>
                            {option?.url?.trim()?.slice(0, width() == "mobile" ? 30 : 50)}
                          </div>
                          <div className="row col-12">
                            <strong>Método: </strong>
                            {option?.metodo?.trim()?.slice(0, width() == "mobile" ? 30 : 50)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </div>
      </>
    );
  }

  function LoadingData() {
    return (<div style={{ textAlign: "center", width: "100%" }}>
      <br />
      <h2
        style={{
          opacity: "60%",
          fontSize: width() == "mobile" ? "large" : "",
        }}
      >
        Carregando dados, aguarde...
      </h2>
    </div>)
  }

  return (
    <>
      (
      <ModalContent
        title="Auditoria Bancária"
        onCloseEv={handleClose}
        size="lg"
        concorda={false}
      >
        <Content />
        {loading && <LoadingData />}
      </ModalContent>
      )
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(AuditBancaria);
