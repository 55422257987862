import {
    sendForm,
    sendData,
    setSession
} from "./auth";
import { uriList } from "./config";

export const isInvalid = (data) => {
    let [res, obj] = sendForm(data);

    if (!res) {
        return obj;
    };

    return false;
};

export const goBack = (obj, key, next) => {

    setSession({
        obj,
        key
    });
    if (key === 'dados') next(-2);
    else next(-1);
};

export const goForward = (obj, key, next) => {
    setSession({
        obj,
        key
    });
    next(1);
};

export const send = (data, next, opt, opt1, callbackError) => {
    const {
        uri,
        content,
        obj,
        key
    } = data;
    sendData({
        uri,
        content,
        method: "POST"
    })
        .then((res) => {
            if (res && (!res._status || res._status === 200)) { //-- Verificando se o retorno e valido
                goForward(obj, key, next);
                // console.log(res)
                if (opt && res.id) {
                    opt({
                        label: 'cliente_id',
                        content: res.id
                    });
                }

                if (opt1 && res.id) {
                    opt1(res.id)
                }

                return res
            } else {
                let message = res.message
                if (Object.prototype.toString.call(message) === '[object Array]')
                    message = message[0].message
            }
        })
        .catch(error => {
            console.log("Erro: ", error)
            if (callbackError)
                callbackError(false)
        })
};

export const clienteBloqueado = async (cpf) => {
    let result = false
    await sendData({
        uri: uriList("consultarRestricoes"),
        content: { cpf },
        signal: null,
        method: "POST",
    })
        .then((res) => {
            console.log("clienteBloqueado: ", res);
            if (res.status && res.status > 200) {
                result = true
            }
            else
                result = false
        })
        .catch((e) => {
            console.log("clienteBloqueado(erro): ", e);
            result = false
        });

    return result
}