import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import '../../../sass/login.scss';
import Logo from '../../../images/logo-inteira.png';
// import Logo from '../../../images/secure_login.svg';

import { width } from '../../../helpers/general';
import { connect } from "react-redux";

const Form = ({ ...props }) => {
    const [captcha, setCaptcha] = useState("");


    function onClickBtn(e) {
        console.log("login.onClickBtn")
        if (captcha)
            props.submit(e, captcha)
    }

    useEffect(() => {
        console.log("[LOGIN-VIEW] useEffect(2): ", props.MfaAuthentication)
    }, [props.MfaAuthentication])

    useEffect(() => {
        console.log("[LOGIN-VIEW] useEffect(1) ")
    }, [])

    function handleCaptcha(value) {
        // console.log("login.handleCaptcha: ", value)
        setCaptcha(value)
    }

    return (
        <>
            {/* < div className="accountbg" ></div> */}
            <div
                style={{
                    maxHeight: width() === 'mobile' ? (window.innerHeight + 30) + 'px' : '100%',
                    backgroundColor: width() === 'mobile' ? '#ffffff' : '',
                    overflowY: width() === 'mobile' ? 'auto' : 'hidden',
                    width: "100%",
                    margin: "auto",
                }}
                // className={`col-md-5 mx-auto ${width() !== 'mobile' && 'card'}`}>
                className={`col-md-${width() === 'mobile' ? '5' : '8'}  mx-auto card`}>
                <h3 className="text-center m-0">
                    <Link
                        to="/"
                        className="logo logo-admin"
                        title="Sistema"
                    >
                        <img src={Logo}
                            style={{
                                width: width() !== "mobile" ? "200px" : "200px"
                            }}

                            alt="Login" />
                    </Link>
                </h3>

                <br />

                <>
                    <>
                        <div className="form-group" >
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    size={"normal"}
                                    onChange={handleCaptcha} />
                            </div>
                        </div>

                        <div className="text-right" style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                            <button
                                className="btn btn-success w-md waves-effect waves-light"
                                onClick={onClickBtn}
                                disabled={!captcha}
                                style={{ cursor: "pointer", width: "40%" }}
                            >
                                Acessar
                            </button>
                        </div>
                    </>

                </>
            </div>
        </>
    )
};

function mapStateToProps(state) {
    return {
        user: state.dados.user,
    };
}


export default connect(mapStateToProps,)(Form);


