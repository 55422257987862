import { numerosIndividuais, formatCurrency } from "./general";

let fieldsG;
// function removeCaracteres(texto) {
//   return texto.replace(/[^a-zA-Z0-9]/g, '');
// }
const removeCaracteres = (str) => {
  const result = str.replace(/[^\w\s-]/g, '')
  console.log("validation.removeCaracteres: ", result)
  return result;
}

export const isValidForm = (fields) => {
  fieldsG = fields;
  let obj = {};

  for (let key in fields) {
    if (
      key === "parcelas" ||
      key === "tipo" ||
      key === "civil" ||
      key === "sexo" ||
      key === "t_conta" ||
      // key === "cepReady" ||
      key === "obs"
      // key === "numero" ||
      // || key === 'telefone'
      // key === "complemento"
    )
      continue;
    else if (!validate(key)(fields[key])) obj[key] = 1;
    // else obj[key] = 0;
  }

  if (JSON.stringify(obj).indexOf("1") !== -1) return [false, obj];
  return [true, obj];
};

export const verifyValue = (obj) => {
  let rgxp = /\d*\s*/;
  let value = obj.currentTarget.value;
  let matchs = rgxp.test(value);

  // console.log("veryfyValue: ", value, matchs);
  if (matchs && value > 0 && value <= 180) {
    return [value.substr(0, 3), 0];
  }

  if (value > 180 || parseInt(value) === 0) return [value.substr(0, 3), 1];

  return ["", 1];
};

export const validateDate = (date) => {
  let result = false;
  let year = new Date().getFullYear();

  if (date) {
    let newDate = date.split("/");

    if (newDate.length === 3) {
      if (newDate[0] < 0 || newDate[0] > 31) return false;
      if (newDate[1] < 0 || newDate[1] > 12) return false;
      if (newDate[2].length < 4 || newDate[2] < 1900 || newDate[2] > year)
        return false;
      if (/[^0-9]{8}/.test(date.replace(/\//g, "")) === true) return false;

      result = date;
    }
  }

  return result;
};

export const validateNumero = (numero) => {
  return numero && numero?.length <= 10 && (removeCaracteres(numero) === numero);
}

export const validateAgencia = (agencia) => {
  return agencia && agencia.length == 4 && (removeCaracteres(agencia) === agencia);
};

export const validateBanco = (banco) => {
  console.log("validation.validateBanco: ", banco, banco.length, (removeCaracteres(banco) === banco));
  return banco && banco.length == 3 && (removeCaracteres(banco) === banco);
};

export const validateEmail = (email, user, pessoa) => {
  const excluir = ["cred", "consig", "admin", "finan"]
  const invalido = user?.tipo != "ADMINISTRADOR" && pessoa?.tipo == "CLIENTE" && excluir.some(palavra => email.includes(palavra))
  console.log("validation.validateEmail: ", { email, user, pessoa, invalido });

  return !email || (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !invalido);
};

export const validateBirthDate = (date) => {
  let result = false;
  if (date) {
    let newDate = date.split("/");

    let d = new Date(newDate[2], newDate[1] - 1, newDate[0]);
    let today = new Date();
    let age = today.getFullYear() - d.getFullYear();
    let m = today.getMonth() - d.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < d.getDate())) age--;

    result = validateDate(date) && age >= 18 && age <= 100;
  }
  return result;
};

export const validateMonthDate = (date) => {
  let result = false;
  if (date) {
    let newDate = date.split("/");

    let d = new Date(newDate[2], newDate[1] - 1, newDate[0]);
    let today = new Date();
    let age = today.getFullYear() - d.getFullYear();
    let m = today.getMonth() - d.getMonth();
    let day = today.getDate() - d.getDate();

    if (age === 0) {
      if (m < 0) return false;
      if (m === 0 && day < 30) return false;
      if (m === 1 && day < 0) return false;

      return true;
    }

    result = validateDate(date) && true;
  }
  return result;
};

export const validateCPF = (cpf) => {
  let result = cpf;
  if (cpf) {
    cpf = cpf.replace(/\D/g, "");
    if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    [9, 10].forEach(function (j) {
      let soma = 0,
        r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach(function (e, i) {
          soma += parseInt(e) * (j + 2 - (i + 1));
        });
      r = soma % 11;
      r = r < 2 ? 0 : 11 - r;
      if (r.toString() !== cpf.substring(j, j + 1)) result = false;
    });
  }

  return result;
};

export const validateObj = (obj) => {
  // console.log("Validando objeto")
  let isValid = isNotEmpty(obj) && Array.isArray(obj);

  return isValid;
};

export const mock = (mock) => true;

export const validateCelular = (telefone) => {
  try {
    let tel = telefone.replace(/\D/g, "");
    return isNotEmpty(tel) && tel.trim().length === 11;
    // return isNotEmpty(telefone) && /[0-9]{4,5}-[0-9]{4,5}/.test(telefone.trim()) && telefone.trim().length === 10;
  } catch (e) {
    return false;
  }
};

export const compareValues = (value1, value2) => {
  return value1 && value2 && (parseInt(formatCurrency(value1).replace('.', '')) > parseInt(value2))
}

export const validateSalario = (salario) => {
  console.log("validateSalario: ", salario)
  try {
    return true;
  } catch (e) {
    return false
  }
}

export const validateTelefone = (telefone) => {
  try {
    let tel = telefone.replace(/\D/g, "")?.trim();
    return !tel || (tel.trim().length <= 11);
    // return isNotEmpty(telefone) && /[0-9]{4,5}-[0-9]{4,5}/.test(telefone.trim());
  } catch (e) {
    return false;
  }
};

export const validateDDD = (ddd) => {
  try {
    return isNotEmpty(ddd) && /[0-9]{3}/.test(ddd.trim());
  } catch (e) {
    return false;
  }
};

export const validatePrazo = (prazo) => prazo && prazo > 0 && prazo <= 180;

export const validateValor = (valor) => {
  let min = 0,
    max = 500000;
  if (fieldsG) {
    if (fieldsG["tipo"] === "valor") {
      min = 300;
      max = 500000;
    } else {
      min = 30;
    }
  }

  try {
    // console.log("validateValor: ", valor)
    return (
      valor &&
      valor.replace(".", "").replace(",", ".") >= min &&
      valor.replace(".", "").replace(",", ".") <= max
    );
  } catch (e) {
    return false;
  }
};

export const validateCEP = (cep) => {
  return cep && cep.length === 9 && numerosIndividuais(cep).length > 1;
};

export const isNotEmpty = (field) => {
  if (!field) return false;

  try {
    return (
      field.trim() !== "" &&
      field.trim() !== 0 &&
      field.trim() !== "0" &&
      field.trim() !== "0.00"
    );
  } catch (e) {
    return false;
  }
}

export const isNotEmptyAndChar = (field) => {
  if (!field) return false;
  // console.log("isNotEmptyAndChar: ", { field, fieldTrim: field.trim(), valid: removeCaracteres(field.trim()) })
  try {
    return (
      field.trim() != "" &&
      field.trim() != 0 &&
      field.trim() != "0" &&
      field.trim() != "0.00" &&
      removeCaracteres(field) === field
    );
  } catch (e) {
    return false;
  }
};

export const isChar = (field) => {
  if (!field) return true;
  try {
    return (removeCaracteres(field) === field);
  } catch (e) {
    return false;
  }
};

export const onlyAlpha = (field) =>
  isNotEmpty(field) && /[0-9]{1}/.exec(field) === null;

export const validate = (field, user) =>
({
  ddn: validateBirthDate,
  cpf: validateCPF,
  nome: isNotEmptyAndChar,
  matricula: isNotEmptyAndChar,
  mae: isNotEmptyAndChar,
  convenio: isNotEmptyAndChar,
  prazo: validatePrazo,
  valor: validateValor,
  // 'senha': isNotEmpty,
  password: isNotEmpty,
  // salario: validateSalario,
  cep: validateCEP,
  cidade: isNotEmpty,
  t_log: isNotEmpty,
  logradouro: isNotEmptyAndChar,
  bairro: isNotEmptyAndChar,
  uf: isNotEmpty,
  t_doc: onlyAlpha,
  num_doc: isNotEmptyAndChar,
  orgao: onlyAlpha,
  uf_exp: isNotEmpty,
  data_exp: validateMonthDate,
  celular_ddd: validateDDD,
  celular: validateCelular,
  // 'telefone_ddd': validateDDD,
  // telefone: validateTelefone,
  // banco: isNotEmptyAndChar, //-- Comentando pois não estava validando banco vazio
  banco: validateBanco,
  agencia: validateAgencia,
  conta: isNotEmptyAndChar,
  t_conta: isNotEmpty,
  email: validateEmail,
  d_pessoal: validateObj,
  contracheque: validateObj,
  c_bancario: validateObj,
  // 'ded': validateObj,
  selfie: validateObj,
  t_convenio: isNotEmpty,
  numero: validateNumero,
  complemento: isChar,
}[field] || mock);
