import React, { useState, useEffect, useCallback } from "react";

import Form from '../../components/form/admin/pendencias';

function Pendencias({ ...props }) {

    return (
        <>
            <Form />
        </>
    );
};

export default Pendencias;
