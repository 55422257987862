import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { HiRefresh, HiOutlineExclamationCircle } from "react-icons/hi";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal, { swalError, confirmSwal } from "../../../swal";
import { sendData, getData } from "../../../../helpers/auth";
import { Aguarde, width } from "../../../../helpers/general";
import { getItemCrypt } from '../../../../helpers/auth'
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA } from "../../../../helpers/config";
import { connect } from "react-redux";

let dataIni = {
  password: null,
  qrCodeUrl: '',
  status: "ATIVO",
};

const Form = ({ ...props }) => {
  const [data, setData] = useState({ status: "ATIVO", password: null });
  const [method, setMethod] = useState("POST");
  const [show, setShow] = useState(false);

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const fetch2FADetails = async () => {
    if (qrCodeUrl) return false
    try {
      setLoading(true);
      const _result = await sendData({
        uri: uriList("setup2fa"),
        content: { email: props.user.email },
        method: "POST",
      })
      console.log("[Register2FA]: ", _result)
      if (_result?.data) {
        setQrCodeUrl(_result?.data);
        setData({ ...data, qrCodeUrl: _result?.data, secret: _result?.secret });

      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching 2FA setup details:', error);
      setLoading(false);
    }
  };

  const cancelarToken = async () => {
    try {
      setLoading(true);
      const _result = await sendData({
        uri: uriList("cancelar2fa"),
        content: { email: props.user.email },
        method: "POST",
      })
      console.log("[Register2FA]: ", _result)
      if (_result?.data) {
        setQrCodeUrl(null);
        setData({ ...data, qrCodeUrl: null, secret: null });
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching 2FA setup details:', error);
      setLoading(false);
    }
  };

  const getMfaData = async () => {
    try {
      setLoading(true);
      const _result = await sendData({
        uri: uriList("getMfaData"),
        content: { email: props.user.email },
        method: "POST",
      })
      console.log("[getMfaData]: ", _result)
      setLoading(false);
      if (_result?.mfa_data) {
        setQrCodeUrl(_result?.mfa_data.qrCodeUrl);
        setData({ ...data, qrCodeUrl: _result?.mfa_data?.qrCodeUrl, secret: _result?.mfa_data?.secret });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching 2FA setup details:', error);
    }
  }

  useEffect(() => {
    console.log("[PERFIL_USUARIO]: ", props.user)
    setData(dataIni);

    getMfaData();
  }, []);

  async function handle(e) {
    let target = e.currentTarget;
    let name = target.name;
    let value = target.value;
    // console.log("usuarios.handle(1): ", name, value);
    if (value?.toString() == "Selecione um usuário...") value = null;

    setData({
      ...data,
      [name]: name == "email" ? value.toLowerCase() : value,
    });

  }

  function handleClose(e, close) {
    if (e) e.preventDefault();

    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close);

    if (props.callback) props.callback();

    // if (method == "NEW") buscarPessoas();
  }

  async function validarDados() {
    let result = true;

    return result;
  }

  async function handlePost(e) {
    // return false
    e.preventDefault();
    console.log("usuarios.handlePost: ", { data });

    async function callback(res) {
      let result = true
      if (!res)
        return false

      // if (method == "EDIT")
      //   delete _data.password;

      let send = {
        uri: uriList("gravarUsuarioPerfil"),
        content: data,
        signal: null,
        method: "POST",
      };
      await sendData(send)
        .then(async (res) => {
          console.log("usuarios.handlePost(2): ", res);
          if (res?.status > 200) {
            swalError(res.message ? res?.message : "Erro ao gravar usuário!");
            result = false;
          }

          handleClose();
          result = true;
        })
        .catch((e) => {
          console.log(e);
          result = false;
        });
      return result
    }

    const _validarDados = await validarDados();
    if (_validarDados) {
      console.log("usuarios.handlePost(1) ");
      callback(true)
    } else {
      swalError("Favor informar os dados corretamente!");
    }
  }

  return (
    <>
      {(!props.loaded || loading) && <Aguarde legenda={"Aguarde..."} />}
      <Modal
        size="md"
        show={true}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
        scrollable
      >
        <Modal.Header closeButton className="p-1 ">
          <Modal.Title className="p-1 ">
            <span>Perfil de Usuários</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" maxWidth650 flex  flex-col w-full">
          <div style={{ display: "grid", }}  >
            <div className="row w-full " style={{ marginTop: "20px", marginBottom: "10px" }}>
              <div className="form-group col-md-12 ">
                <span style={{ fontWeight: "bold" }}>{`Geração do Token de Autenticação em 2 Fatores(2FA)`}</span>
                <div style={{ fontSize: "13px" }}>A Autenticação em 2 fatores irá garantir a segurança do seu login e proteger sua conta de ataques maliciosos.</div>
                <div style={{ fontSize: "13px" }}>A Plataforma Inconta Digital recomenda fortemente a adoção dessa medida adicional de segurança!</div>
                <div className='flex-col mt-2'>
                  {!qrCodeUrl && <div >
                    <Button color="info" className=" flex items-center text-center " onClick={fetch2FADetails} title="Gerar Token de Autenticação">
                      <HiRefresh className="mr-1 h-5 w-5" />
                      Gerar Token
                    </Button>
                  </div>}
                  {qrCodeUrl && <div >
                    <Button color="danger" className=" flex items-center text-center " onClick={cancelarToken} title="Cancelar Token de Autenticação">
                      <HiOutlineExclamationCircle className="mr-1 h-5 w-5" />
                      Cancelar Token
                    </Button>
                  </div>}
                </div>
                <div>
                  {qrCodeUrl &&
                    <div className="flex " >
                      <img src={qrCodeUrl} alt="QR Code for 2FA Setup" style={{ marginLeft: "-15px", marginTop: "1px", }} />
                      <p style={{ fontSize: "13px", }}>Escaneie o QR Code acima com o aplicativo de autenticação.</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            color="success"
            onClick={handlePost}
          // disabled={!props.loaded}
          >
            Gravar
          </Button> */}
          <Button
            color="secondary"
            onClick={(e) => {
              handleClose(e, true);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);


