import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
// import { sendMultiPartData } from "../../../../helpers/auth";
import { getCookie, width, } from "../../../../helpers/general";
import { sendData } from "../../../../helpers/auth";
import { connect } from "react-redux";

import CardCollapse from "../../../layout/CardCollapse";
import * as moment from "moment";

const AnalisarPendencia = ({ ...props }) => {
  const [loaded, setLoaded] = useState(false)
  const [historico, setHistorico] = useState([])
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    console.log("HistoricoPendencia.useEffect(1): ", props.dados)
    getDados()
  }, []);

  // const [loaded, setLoaded] = useState(true);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    if (props.onCloseEv) props.onCloseEv(close);
  }

  async function getDados() {
    setLoaded(false);
    sendData({
      uri: uriList('pendenciashst'),
      content: {
        pendenciaid: props.dados.pendenciaid || props.dados.id,
        // proposta_uuid: !props.dados.id && props.proposta
      },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        // console.log("ResolverPendencia.getDados(1): ", res);
        setHistorico(res);
        setLoaded(true);
      })

      .catch((e) => {
        // console.log("ResolverPendencia.getDados(2)");
        console.log(e);
        setLoaded(true);
        return false;
      });

  }

  const getHistorico = () => {
    console.log("ResolverPendencia.getHistorico(1): ", historico);
    let fontSize = width() == "mobile" ? "0.5rem" : "0.8rem"
    let bgColor = true
    return (
      <div style={{ border: "1px solid #D5DBE1", }}>
        <div className="d-flex font-weight-bold" style={{ backgroundColor: "#E5E4E4" }} >
          <div style={{ width: "23%", fontSize }}>
            <span>Usuário</span>
          </div>
          <div style={{ width: "17%", fontSize }}>
            <span>Data</span>
          </div>
          <div style={{ width: "60%", fontSize }}>
            <span>Histórico</span>
          </div>
        </div>
        {historico.map((hist, i) => {
          bgColor = !bgColor
          return (
            <div className="d-flex" key={i} style={{ borderTop: "1px solid #D5DBE1", backgroundColor: bgColor ? "#F3F3F3" : "" }}>
              <div style={{ width: "23%", fontSize }}>
                {hist?.usuario}
              </div>
              <div style={{ width: "17%", fontSize }}>
                {moment(hist.updated_at).format("DD/MM/YYYY HH:mm:ss")}
              </div>
              <div style={{ width: "60%", fontSize }}>
                {hist.observacao || hist.descricao || hist.status}
              </div>
            </div>
          )
        })}
      </div>
    )

  }

  return (
    <>
      <Modal
        size="md"
        show={true}
        centered
        onHide={(e) => { handleClose(e, false); }}
        animation={false}
        backdrop="static"
      >
        <div className="p-1">
          {loaded &&
            <div title={!collapsed ? 'Clique para expandir' : "Clique para fechar"}>
              <CardCollapse isOpen={collapsed} hideHeader title={"Histórico de Alterações"} chield={() => getHistorico()}
                setCollapse={(collapse) => setCollapsed(!collapse)} bodyToggle={true}
                forceCollapse={props.dados.status != "Pendente"} />
            </div>
          }
        </div>

        {/* <Modal.Header closeButton>
          <Modal.Title>
            <span>
              Histórico de Pendência
            </span>
          </Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body className="maxWidth750">
          <>
            {loaded &&
              <div title={!collapsed ? 'Clique para expandir' : "Clique para fechar"}>
                <CardCollapse isOpen={collapsed} hideHeader title={"Histórico de Alterações"} chield={() => getHistorico()}
                  setCollapse={(collapse) => setCollapsed(!collapse)} bodyToggle={true}
                  forceCollapse={props.dados.status != "Pendente"} />
              </div>
            }
          </>
        </Modal.Body> */}
        <div className="p-1" style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        {/* <Modal.Footer>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    user: state.dados.user
  };
}

export default connect(mapStateToProps)(AnalisarPendencia);
