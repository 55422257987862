import { createStore, combineReducers } from "redux";
import { setAlterarProposta } from "./actions/dados";

const INITIAL_STATE = {
  proposta: "",
  detalhes: {},
  cliente: 0,
  clienteDados: {},
  t_doc: "",
  user: null,
  preencherCli: "",
  alterarProposta: false,
  buscarPropostas: null,
  notificacoes: null,
  simulacao: null,
  contratosRefin: null,
  clickedMenu: null,
  pendencias: 0,
  filtrosPropostas: null
};

const reducers = combineReducers({
  dados: function (state, action) {
    // console.log(`Redux.combineReducers(${action.type}): `, action.payload);
    switch (action.type) {
      case "SET_FILTROSPROPOSTAS":
        return {
          ...state,
          filtrosPropostas: action.payload || state.filtrosPropostas,
        };

        break;
      case "SET_PENDENCIAS":
        return {
          ...state,
          pendencias: action.payload || state.pendencias,
        };

        break;

      case "SET_CLICKEDMENU":
        return {
          ...state,
          clickedMenu: action.clickedMenu || state.clickedMenu,
        };

        break;

      case "SET_CONTRATOSREFIN":
        let res = {
          ...state,
          contratosRefin: action.payload || state.contratosRefin,
        };

        if (action.payload == "limpar") res = { ...res, contratosRefin: null };

        // console.log("Redux - SET_CONTRATOSREFIN: ", res)
        return res;
        break;
      case "SET_NOTIFICACOES":
        if (action.payload === "limpar") {
          if (state.notificacoes) return { ...state, notificacoes: null };
          else return state;
        } else {
          let not = { ...state.notificacoes, notificacao: action.payload };
          return { ...state, notificacoes: not };
        }
        break;
      case "SET_PROPOSTA":
        let result = {
          ...state,
          proposta:
            action.payload.uuid ||
            action.payload.proposta_uuid ||
            state.proposta,
          detalhes: action.payload || state.detalhes,
          cliente: action.payload?.cliente_id || state.cliente,
          preencherCli: action.payload?.preencherCli || state.preencherCli,
          t_doc: state.t_doc || action.payload.t_doc,
        };
        if (action.payload == "limpar")
          result = {
            ...result,
            proposta: "",
            detalhes: {},
            cliente: 0,
            clienteDados: {},
            t_doc: "",
          };

        // console.log("Redux - SET_PROPOSTA: ", result)
        return result;
        break;
      case "SET_CLIENTE":
        return {
          ...state,
          clienteDados: !action.payload ? {} : state.clienteDados,
        };
        break;
      case "SET_SIMULACAO":
        return {
          ...state,
          cliente: action.payload,
          clienteDados: !action.payload ? {} : state.clienteDados,
          simulacao: action.payLoad || state.simulacao,
        };
        break;
      case "SET_CLIENTEDADOS":
        return {
          ...state,
          clienteDados: action.payload,
        };
        break;
      case "SET_USER":
        return {
          ...state,
          user: action.payload,
        };
        break;
      case "SET_ALTERARPROPOSTA":
        return {
          ...state,
          alterarProposta: action.payload,
        };
        break;
      case "SET_BUSCARPROPOSTAS":
        return {
          ...state,
          buscarPropostas: action.payload,
        };
        break;
      case "SET_TDOC":
        return {
          ...state,
          t_doc: action.payload,
        };
        break;

      default:
        return INITIAL_STATE;
    }
  },
});

function storeConfig() {
  return createStore(reducers);
}

export default storeConfig;
