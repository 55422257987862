import React, { useState, useEffect } from "react";
import { sendData, getData } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import { formatCurrency, width, Aguarde } from "../../../../helpers/general";
// import { highlightedJSON } from "../../../../helpers/Uteis";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import Collapse from "../../../layout/Collapse";
import { Button } from "reactstrap";
import './checklist.css'
// import ConsultarMargem from "../../../../views/admin/ConsultarMargem";
import { confirmSwal, swalError } from "../../../swal";

function Checklist(props) {
    const [items, setItems] = useState(null);
    const [carregando, setCarregando] = useState(true)
    const [habilitaGravacao, setHabilitaGravacao] = useState(false)
    const [conformidade, setConformidade] = useState('nao')
    const [emitente, setEmitente] = useState(null)
    const [collapsed, setCollapsed] = useState(false)
    const [auditAssinatura, setAuditAssinatura] = useState(false)
    const [consultaMargem, setConsultaMargem] = useState(false)
    const [antiFraude, setAntiFraude] = useState(false)
    const [showAntiFraude, setShowAntiFraude] = useState(false)
    const [aguarde, setAguarde] = useState(false)
    const [dadosConf, setDadosConf] = useState(null)
    const [showAllScreen, setShowAllScreen] = useState(false)

    const highlightedJSON = (jsonObj) =>
        Object.keys(jsonObj).map((key) => {
            const value = jsonObj[key];
            let valueType = typeof value;
            const isSimpleValue =
                ["string", "number", "boolean"].includes(valueType) || !value;
            if (isSimpleValue && valueType === "object") {
                valueType = "null";
            }
            return (
                <div key={key} className="line" >
                    <span className="key">{key}:</span>
                    {isSimpleValue ? (
                        <span className={valueType}>{`${value}`}</span>
                    ) : (
                        highlightedJSON(value)
                    )}
                </div>
            );
        });

    const handleAntifraude = async () => {
        async function callback(e) {
            console.log("PreencherChecklist.handleAntifraude(0): ", e)
            if (!e) return false;

            console.log("PreencherChecklist.handleAntifraude(1)")
            setAguarde(true)
            await sendData({
                uri: uriList('checarAntiFraude'),
                content: { proposta_uuid: props?.proposta, forca: true },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    console.log("PreencherChecklist.handleAntifraude(1): ", res)
                    handleConsultaAntifraude()
                })
                .catch((e) => {
                    console.log(e);
                    return false;
                });
            setAguarde(false)
        }
        confirmSwal({ callback, title: "Confirma a execução do processo?" });
    }

    const handleConsultaAntifraude = async () => {
        console.log("PreencherChecklist.handleConsultaAntifraude(0)")
        // if (antiFraude) return setShowAntiFraude(true)
        setAguarde(true)
        await sendData({
            uri: uriList('consultarAntiFraude'),
            content: { proposta_uuid: props?.proposta },
            signal: null,
            method: "POST",
        })
            .then((res) => {
                console.log("PreencherChecklist.handleConsultaAntifraude(1): ", res)
                if (res?.status > 200) throw new Error()
                setAntiFraude(res?.data || res)
                setShowAntiFraude(true)
                getDadosEmitente()
            })
            .catch((e) => {
                console.log(e);
                return false;
            });

        setAguarde(false)
    }

    const handleAuditAssinatura = async () => {
        setAguarde(true)
        await sendData({ uri: uriList('listarAuditAssinatura'), content: { uuid: props.proposta }, method: "POST" })
            .then(res => {
                console.log("PreencherChecklist.handleAuditAssinatura: ", res)
                if (res?.status?.toLowerCase() == 'not') {
                    throw new Error();
                }
                let _res = res?.data?.parts[0]
                console.log("PreencherChecklist.handleAuditAssinatura(res): ", _res)
                setAuditAssinatura(_res)
                getDadosEmitente()
            })
            .catch(e => {
                console.log("Erro: ", e)
                return false;
            });
        setAguarde(false)
    }

    const handleConsultaMargem = async () => {
        setAguarde(true)
        await sendData({ uri: uriList("consultarMargem"), content: { uuid: props.proposta }, method: "POST" })
            .then((res) => {
                if (res.status && parseInt(res.status) != 200) {
                    swalError({
                        title: "Erro ao Consultar Margem do Cliente.",
                        text: JSON.stringify(res.data),
                    });
                    throw new Error();
                }
                setConsultaMargem(res.data)
                console.log("consultaAverbaçãoPortabilidade: ", res.data);
            })
            .catch((e) => {
                console.log("ConsultarMargem - erro: Erro na consulta de margem");
                console.log(e);
                // alert('Erro ao excluir Configuracoes.')
            });
        setAguarde(false)
    }

    const handleCheck = (itemId, show) => {
        if (props.consulta || props.showAll) return false
        const updatedItems = items.map((item) =>
            item.id === itemId ? { ...item, checado: !item.checado, show } : item
        );
        setItems(updatedItems);
        console.log("PreencherChecklist.handleCheck(updatedItems): ", show)
    };

    function handleCollapse() {
        setCollapsed(!collapsed)
    }

    useEffect(() => {
        getChecklists();
    }, []);

    useEffect(() => {
        if (conformidade == 'sim')
            getDadosEmitente()
    }, [conformidade])

    useEffect(() => {
        verificarTodosChecados();
    }, [items]);

    const getDadosEmitente = async () => {
        // return 
        if (props.detalhes?.cliente_id) {
            const content = { id: props.detalhes?.cliente_id }
            console.log("PreencherChecklist.getDadosEmitente(content): ", content)
            setAguarde(true)
            await sendData({
                uri: uriList('pessoa'),
                content,
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    if (res.status && parseInt(res?.status) > 200) throw new Error();
                    console.log("PreencherChecklist.getDadosEmitente: ", res?.data)
                    if (res?.data)
                        setEmitente(res.data);
                })
                .catch((e) => {
                    console.log(e);
                    return false;
                });

            console.log("PreencherChecklist.getDadosEmitente(conformidade): ", props.detalhes?.cpf)
            await sendData({
                uri: uriList('conformidadeshow'),
                content: { cpf: props.detalhes?.cpf, proposta_uuid: props.proposta },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    console.log("PreencherChecklist.getDadosEmitente(conformidade): ", res)
                    if (res?.status && parseInt(res?.status) > 200) throw new Error();
                    if (res)
                        setDadosConf(res);
                })
                .catch((e) => {
                    console.log("PreencherChecklist.getDadosEmitente(conformidade-erro)")
                    console.log(e);
                    return false;
                });
            setAguarde(false)
        }
    }

    const handlePost = async () => {
        console.log("PreencherChecklist.handlePost(items): ", items)
        setAguarde(true)
        await sendData({
            uri: uriList('gravarChecklist'),
            content: { proposta_uuid: props.proposta, items },
            signal: null,
            method: "POST",
        })
            .then((res) => {
                console.log("PreencherChecklist.handlePost(1): ", res)
                if (res.status && parseInt(res.status) > 200) throw new Error();
                handleClose(true)
            })
            .catch((e) => {
                console.log(e);
                return false;
            });
        setAguarde(false)
    }

    const getChecklists = async () => {
        const content = { proposta_uuid: props.proposta, aplicacao: props.acao, showAll: props.consulta, checklist: props.checklist }
        console.log("PreencherChecklist.getChecklists(content): ", content)
        setAguarde(true)
        await sendData({
            uri: uriList('getChecklist'),
            content,
            signal: null,
            method: "POST",
        })
            .then((res) => {
                if (res.status && parseInt(res.status) > 200) throw new Error();
                console.log("PreencherChecklist.getChecklists: ", res)
                if (res && res.length > 0)
                    setItems(res);
                else
                    handleClose(true)
            })
            .catch((e) => {
                console.log(e);
                return false;
            });
        setAguarde(false)
        setCarregando(false)
    }

    const verificarTodosChecados = () => {
        if (items) {
            let todosChecados = true;
            items.forEach((item) => {
                if (!item.checado && item.impeditivo == "sim") {
                    todosChecados = false;
                }
            })
            setHabilitaGravacao(todosChecados);
        }
    }

    const handleClose = (executed = false) => {
        console.log("preencherChecklist.handleClose: ", props)
        if (executed && props.callBack)
            props.callBack()

        if (props.handleCloseEv)
            props.handleCloseEv()
    }

    const GetAssinatura = () => {
        return (
            <div className="mt-3 ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Biometria validada em base pública:</span> {!dadosConf?.dados_assinatura ? "" : dadosConf?.checado_basepublica ? "SIM" : 'NÃO'}
                </div>
                <div>
                    <button
                        className="btn-small"
                        onClick={handleAuditAssinatura}>
                        Visualizar
                    </button>
                </div>
            </div>
        )
    }

    const AntiFraude = () => {
        console.log("PreencherChecklist.AntiFraude(0): ", antiFraude)
        if (!antiFraude) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    {/* <h6 className="text-center">Análise de Documento</h6> */}
                    <span className="title-json" >Análise de Documento</span>
                    <div style={{ fontSize: "10px", width: "100%", maxHeight: "180px", overflow: "auto" }}>
                        {highlightedJSON(antiFraude)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setShowAntiFraude(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const ConsultaMargem = () => {
        console.log("PreencherChecklist.ConsultaMargem(0): ", consultaMargem)
        if (!consultaMargem) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    <span className="title-json" >Consulta de Margem</span>
                    <div style={{ fontSize: "10px", width: "100%", maxHeight: "180px", overflow: "auto" }}>
                        {highlightedJSON(consultaMargem)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setConsultaMargem(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const AuditAssinatura = () => {
        console.log("PreencherChecklist.auditAssinatura(0): ", props.detalhes)
        if (!auditAssinatura) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    <span className="title-json" >Auditoria de Assinatura</span>
                    <div style={{ fontSize: "10px", width: "100%", maxHeight: "180px", overflow: "auto" }}>
                        {highlightedJSON(auditAssinatura)}
                    </div>
                </div>
                {props?.detalhes?.enviada &&
                    <div className="mt-1" style={{ fontSize: "12px" }}>
                        <button
                            // className="btn-small"
                            onClick={() => { setAuditAssinatura(false) }}>
                            Fechar
                        </button>
                    </div>}
            </div>
        );
    }


    const GetAntifraude = () => {
        console.log("PreencherChecklist.GetAntifraude(0): ", { antiFraude, dadosConf })
        return (
            <div className="mt-3 ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Análise do documento realizada:</span> {dadosConf?.dados_antifraude || antiFraude ? "SIM" : 'NÃO'}
                </div>
                <div style={{ fontSize: "12px" }}>
                    {!dadosConf?.dados_antifraude && !antiFraude &&
                        <button
                            className="btn-small"
                            onClick={handleAntifraude}>
                            Analisar</button>}
                    {(dadosConf?.dados_antifraude || antiFraude) &&
                        <button
                            className={"btn-small " + ((!dadosConf?.dados_antifraude && !antiFraude) ? "ml-2" : "")}
                            onClick={handleConsultaAntifraude}
                        >
                            Visualizar</button>}
                    {(dadosConf?.dados_antifraude || antiFraude) && props.user?.tipo == "ADMINISTRADOR" &&
                        <button
                            className={"btn-small ml-2"}
                            onClick={handleAntifraude}
                        >
                            Re-Analisar</button>}
                </div>
            </div>
        )
    }

    const GetConsultaMargem = () => {
        return (
            <div className="mt-3 ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Margem consignável</span>
                </div>
                <div style={{ fontSize: "12px" }}>
                    <button
                        className="btn-small"
                        onClick={handleConsultaMargem}>
                        Consultar
                    </button>
                </div>
            </div>
        )
    }

    const GetEmitente = () => {
        return (
            <div className="col-12" style={{ border: "1px solid #ccc", fontSize: "12px", padding: "5px" }}>
                {/* <div className="text-center mb-1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Dados cadastrados no sistema
                </div> */}
                <div>
                    <span style={{ fontWeight: "bold" }}>Nome:</span> {emitente?.nome}<br />
                    <span style={{ fontWeight: "bold" }}>CPF:</span> {emitente?.cpf} <span style={{ fontWeight: "bold" }}>D. Nascimento:</span> {moment(emitente?.ddn).format("DD/MM/YYYY")}<br />
                    <span style={{ fontWeight: "bold" }}>Banco:</span> {emitente?.banco}
                    <span style={{ fontWeight: "bold", marginLeft: "2px" }}>Agência:</span> {emitente?.agencia}
                    <span style={{ fontWeight: "bold", marginLeft: "2px" }}>Conta:</span> {emitente?.conta} <br />
                    <span style={{ fontWeight: "bold" }}>Tipo de Conta:</span> {emitente?.t_conta} <br />
                    <span style={{ fontWeight: "bold" }}>Mat./Benefício:</span> {emitente?.matricula} <br />
                    <span style={{ fontWeight: "bold" }}>Endereço:</span> {emitente?.endereco + ', ' + emitente.cidade + '-' + emitente.uf + '. CEP: ' + emitente.cep} <br />
                    <span style={{ fontWeight: "bold" }}>Celular:</span> {emitente?.celular} <span style={{ fontWeight: "bold" }}>Telefone:</span> {emitente?.telefone}<br />
                    <span style={{ fontWeight: "bold" }}>email:</span> {emitente?.email} <br />
                    <span style={{ fontWeight: "bold" }}>Vlr Crédito:</span> {formatCurrency(props.detalhes?.valor_liquido)}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>Prazo:</span> {props.detalhes?.prazo}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>Vlr Parcelas:</span> {formatCurrency(props.detalhes?.valor_parcelas)} <br />
                </div>

            </div>
        )
    }

    return (
        <>
            {aguarde && <Aguarde />}
            {/* {consultaMargem && (
                <ConsultarMargem showprops.detalhes={true} onCloseEv={() => { setConsultaMargem(false) }} />
            )} */}
            {items &&
                <Modal
                    size="md"
                    show={true}
                    centered
                    onHide={(e) => {
                        handleClose(false);
                    }}
                    animation={false}
                    scrollable
                    backdrop="static"
                >
                    <div className="p-1" style={{ borderBottom: "0.3px solid #CCC" }}>
                        <h4 style={{ marginLeft: "5px" }}>CheckList: {(props.consulta || props.showAll) ? "Consulta Preenchimento" : props.acao}</h4>
                    </div>
                    <div style={{ width: "100% !important", height: "100% !important", padding: "10px" }}>
                        {!collapsed && items &&
                            <div className="checklist zebra">
                                {items.map((item) => {
                                    if (item.conformidade == 'sim' && conformidade != 'sim')
                                        setConformidade('sim')
                                    return (
                                        <div key={item.id}
                                            onClick={() => handleCheck(item.id, !item.show)}
                                            disabled={props.consulta || props.showAll}
                                            style={{
                                                borderRadius: "5px", border: "0.3px solid #CACCCC",
                                                cursor: "pointer", marginBottom: "5px", backgroundImage: "linear-gradient(to bottom, #DFE1E1, #eee)"
                                            }}>
                                            <input
                                                type="checkbox"
                                                disabled={props.consulta || props.showAll}
                                                checked={item.checado}
                                                onChange={() => handleCheck(item.id)}
                                                style={{ marginLeft: "5px", marginTop: "5px", cursor: "pointer", }}
                                            />
                                            <label
                                                className="ml-1"
                                                disabled={props.consulta || props.showAll}
                                                style={{ color: item.impeditivo == "sim" ? "red" : "black", marginTop: "5px", textDecoration: item.impeditivo == "sim" ? "underline" : "", cursor: "pointer", }}
                                                onClick={() => handleCheck(item.id)}>{item.descricao}
                                            </label>
                                            {item.mensagem && !item.show &&
                                                <label className="ml-2" style={{ fontSize: "10px", fontWeight: "bold", cursor: "pointer" }}>...Saiba mais</label>
                                            }
                                            {item.mensagem && item.show &&
                                                <p className="ml-1">
                                                    {item.mensagem}
                                                </p>
                                            }
                                        </div>
                                    )
                                }
                                )}
                            </div>}
                        {!items &&
                            <h2
                                style={{
                                    opacity: "60%",
                                    fontSize: width() == "mobile" ? "large" : "",
                                }}
                            >
                                Carregando dados...
                            </h2>}
                        {conformidade == 'sim' && emitente &&
                            <>
                                {collapsed && <span style={{ fontWeight: "bold", fontSize: "25px", cursor: "pointer" }} onClick={handleCollapse}>...</span>}
                                <Collapse isOpen={collapsed} hideHeader title={width('', true) >= 800 && width('', true) <= 1300 ? "" : "Conferência de Conformidade"} maxHeigth={400}
                                    setCollapse={handleCollapse}
                                    tip={"Clique para conferir os dados de conformidade"}
                                    bodyToggle={true} className="col-12" />
                                {collapsed &&
                                    <>
                                        <GetEmitente />
                                        {!showAntiFraude && !auditAssinatura && !consultaMargem && <div>
                                            <GetAssinatura />
                                            <GetAntifraude />
                                            {props?.detalhes?.convenio == 'INSS' && <GetConsultaMargem />}
                                        </div>}
                                        {showAntiFraude && <AntiFraude />}
                                        {auditAssinatura && <AuditAssinatura />}
                                        {consultaMargem && <ConsultaMargem />}
                                    </>
                                }
                            </>
                        }
                    </div>

                    <div className="p-1" style={{ display: "flex", justifyContent: "end", marginBottom: "2px", borderTop: "0.3px solid #CCC" }}>
                        {!props.consulta && !props.showAll &&
                            <Button color="success"
                                onClick={handlePost}
                                // disabled={(!habilitaGravacao || (!dadosConf?.checado_basepublica && !dadosConf?.dados_antifraude?.StatusOK &&
                                //     !antiFraude?.StatusOK)) && props.user.tipo != "ADMINISTRADOR"}
                                disabled={!habilitaGravacao}
                                style={{ marginRight: "5px" }}>
                                Gravar
                            </Button>}
                        <Button color="secondary" onClick={() => handleClose(false)} style={{ marginRight: "5px" }}>
                            Fechar
                        </Button>
                    </div>
                </Modal>}
        </>
    );
}

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes,
        user: state.dados.user,
    };
}

export default connect(mapStateToProps,)(Checklist);

