import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { uriList } from "../../../../helpers/config";
import { sendData } from "../../../../helpers/auth";
import { getCookie, Aguarde } from "../../../../helpers/general";
import { swalError } from "../../../../components/swal";

let motivos = [
  "Duplicidade",
  "Erro na inclusao",
  "Saldo nao pago",
  "Desistencia cliente",
  "Virada de folha",
  "Outros",
];

const Averbacao = ({ ...props }) => {
  const [show, setShow] = useState(true);
  const [gravando, setGravando] = useState(false);
  const [data, setData] = useState({
    data: new Date().toISOString().substr(0, 10),
    motivo: "",
    uuid: props.proposta,
    ip: getCookie({ label: "ip" }),
    motivo_cancelamento: "",
  });
  const [showAlert, setAlert] = useState({
    file: 0,
  });


  useEffect(() => {
    console.log("Auxiliar.useEffect(1): ", props?.detalhes?.multSelect);
  }, []);

  useEffect(() => {
    async function callback() {
      await handlePost(null, true);
    }

    if (data.motivo_cancelamento == "Bloqueio")
      callback();
  }, [data]);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close);
  }

  function validarDados() {
    if (!data.motivo_cancelamento) return false;
    if (!data.motivo) return false;
    if (!data.data) return false;

    return true;
  }

  async function handlePost(e, bloquear = false) {
    if (e)
      e.preventDefault();
    console.log("Auxiliar.handlePost(1): ", props.detalhes);

    if (props.cancelar && props?.detalhes?.operacao?.toLowerCase() == "refinanciamento" &&
      props?.detalhes?.assinada &&
      ['CLIENTE', 'AGENTE', 'OPERADOR PARCEIRO'].indexOf(props?.user?.tipo) >= 0)
      return swalError({ title: "Usuário não tem permissão para cancelar proposta de Refinanciamento." });

    if (bloquear || validarDados()) {
      setGravando(true);
      console.log("Auxiliar.handlePost(2)");
      let uri = props.uri;
      // let uri = 'pendenciar'
      // if (props.tela === 'bloquear')
      //   uri = 'bloquear'
      // else if (props.tela === 'cancelar')
      //   uri = 'cancelar'

      // console.log(data)
      // console.log(data)
      if (props.multSelect)
        data.multSelect = props.detalhes.multSelect;

      sendData({
        uri: uriList(uri),
        content: data,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          setGravando(false);
          console.log("Auxiliar.handlePost(3)");
          handleClose();
        })
        .catch((e) => {
          setGravando(false);
          console.log("Auxiliar.handlePost(4)");
          // console.log("Deu errado")
          console.log(e);
          return false;
        });
    }
  }

  return (
    <>
      <Modal
        size="md"
        show={show}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <span>{props.tela === 'pendenciar' ? "Pendenciar Proposta" : props.tela === "bloquear" ? "Bloquear Proposta" : "Cancelar Proposta"}</span> */}
            <span>{props.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          {gravando && <Aguarde legenda={"Aguarde, gravando dados..."} />}

          <div className="form" style={{ overflow: "hidden" }}>
            <div className="row">
              <div className="col-12">
                <label className="m-0 font-90">Data</label>
                <input
                  type="date"
                  id="myDate"
                  className="form-control"
                  value={data.data}
                  required
                  onChange={(e) => setData({ ...data, data: e.target.value })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label className="m-0 font-90">Motivo</label>
                <textarea
                  type="textArea"
                  className="form-control"
                  autoFocus
                  value={data.motivo}
                  required
                  onChange={(e) => setData({ ...data, motivo: e.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label className="m-0 font-90">Tipo de Motivo</label>
                <select
                  className="form-control"
                  defaultValue={data.motivo_cancelamento}
                  onChange={(e) =>
                    setData({ ...data, motivo_cancelamento: e.target.value })
                  }
                  required
                >
                  <option value="">Escolha uma opção...</option>
                  {motivos.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            {["ADMINISTRADOR", "SUPERVISOR", "OPERADOR"].indexOf(props?.user?.tipo) >= 0 &&
              <div >
                <Button color="danger"
                  title="Bloqueia o CPF da proposta para novas operações"
                  onClick={(e) => setData({ ...data, motivo: "Bloqueio", motivo_cancelamento: "Bloqueio", data: data.data || new Date() })}>
                  Bloquear
                </Button>
              </div>}
            <div >
              <Button color="success" onClick={handlePost}>
                Gravar
              </Button>
              <Button
                color="secondary"
                onClick={(e) => {
                  handleClose(e, true);
                }}
                style={{ marginLeft: "10px" }}
              >
                Fechar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Averbacao);
