import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


import Form from "../components/form/auth/loginClient";
import "../sass/login.scss";
import { NotificationManager } from "react-notifications";

import { sendMultiPartData, sendData, inactivityTime, getItemCrypt, setItemCrypt } from "../helpers/auth";
import { uriList, TOKEN_KEY, TOKEN_USER, TOKEN_CAPTCHA } from "../helpers/config";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { setUser } from "../store/actions/dados";
import { swalError } from "../components/swal";
import { width } from "../helpers/general";

let userIni = {
  email: "",
  password: "",
  connected: true,
};

const Login = ({ ...props }) => {
  const history = useHistory();
  const [data, setData] = useState(userIni);
  const [showAlert, setAlert] = useState({
    email: 0,
    password: 0,
  });
  const [loginData, setLoginData] = useState({});
  const [MfaAuthentication, setMfaAuthentication] = useState(false);

  useEffect(() => { }, []);

  const submit = (e, captcha) => {
    console.log("routes.loginClient.js: submit")
    e.preventDefault();

    let dObj = new FormData();

    dObj.append("email", 'client@client.com');
    dObj.append("password", '1234567');
    dObj.append("captcha", captcha);

    console.log("routes.loginClient.js(ok)")

    sendMultiPartData({
      uri: uriList("login"),
      content: dObj,
      signal: null,
      method: "POST",
      errorTitle: "Erro ao fazer login",
      errorMsg: "Api temporariamente indisponível."
    })
      .then((response) => {
        console.log("routes.loginClient.js: submit - response.data: ", response.data)
        handleLogin(response.data)
      })
  };

  function handleLogin(_data) {
    const { token, user } = _data;

    props.alterarUser(user);
    setItemCrypt(TOKEN_KEY, token);
    // setItemCrypt(TOKEN_CAPTCHA, captcha)
    setItemCrypt(TOKEN_USER, JSON.stringify(user));
    console.log("loginClient.js: localStorage.setItemCrypt(TOKEN_KEY)")

    if (props.toggleStep)
      props.toggleStep(1);
  }

  return (
    <>

      <div
        style={{
          margin: "auto",
          marginBottom: "20px",
          marginTop: "20px",
          width: width() !== "mobile" ? "100%" : "",
        }}
        className="wrapper-page"
      >
        <Form
          data={data}
          submit={submit}
          warning={showAlert}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Login);
