import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { width, mCPF, Aguarde } from "../../helpers/general";
import * as moment from "moment";
import { sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import Geolocalizacao from "../../helpers/geolocalizacao";

function ConfirmAutorizacaoSicoob(props) {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(props.data);
  const [aguarde, setAguarde] = useState(false);
  const [autorizado, setAutorizado] = useState(false);
  const [naoExiste, setNaoExiste] = useState(false);
  const [getLocation, setGetLocation] = useState(false);

  function toggleAccept() {
    if (props.onAccept) props.onAccept();
  }

  // const Aguarde = () => {
  //   return (
  //     <Modal
  //       size={"md"}
  //       scrollable
  //       show={true}
  //       animation={false}
  //       backdrop="static"
  //       centered
  //     >
  //       <Modal.Body
  //         className="maxWidth750"
  //         style={{
  //           width: "100%",
  //           justifyContent: "center",
  //           textAlign: "center",
  //         }}
  //       >
  //         <Spinner animation="border" />
  //         {"  "}
  //         Aguarde enquanto o processo é finalizado...
  //       </Modal.Body>
  //     </Modal>
  //   );
  // };

  const NaoExiste = () => {
    return (
      <Modal
        size={"md"}
        scrollable
        show={true}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Body
          className="maxWidth750"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#CACDCD",
          }}
        >
          <span className="font-weight-bold" style={{ fontSize: "25px" }}>
            Não existe autorização para o CPF:{" "}
            <span style={{ color: "darkRed", textDecoration: "underline" }}>
              {props.data && mCPF(props.data)}
            </span>
          </span>
        </Modal.Body>
      </Modal>
    );
  };

  const Autorizado = () => {
    return (
      <Modal
        size={"md"}
        scrollable
        show={true}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Body
          className="maxWidth750"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            color: "darkGreen",
            backgroundColor: "#CACDCD",
          }}
        >
          <span className="font-weight-bold" style={{ fontSize: "35px" }}>
            Autorização concedida
          </span>
          <br />
          {/* <span style={{ fontSize: "25px" }}>Valida até: {moment(data.validade).format('DD/MM/YYYY')}</span>
          <br /> */}
          <span
            className="font-weight-bold"
            style={{ fontSize: "18px", color: "black", opacity: "50%" }}
          >
            (Proposta em andamento, aguarde)
          </span>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    setAguarde(true);
    sendData({
      uri: uriList("buscarAutorizacao"),
      content: { cpf: props.data, tipo: "SICOOB" },
      method: "POST",
    })
      .then((res) => {
        console.log("Autorizacao Sicoob:", res);

        if (res.status && res.status > 200) {
          setAguarde(false);
          setNaoExiste(true);
        }

        setAguarde(false);
        if (res.data) {
          setData(res.data);
          setAutorizado(res.data.autorizado);
        } else setNaoExiste(true);
      })
      .catch((e) => {
        console.log("autorizar Sicoob(Erro): ", e);
        setAguarde(false);
        setNaoExiste(true);
      });
  }, []);

  function submit() {
    if (checked) {
      setAguarde(true);
      let content = {
        cpf: data.cpf,
        tipo: "SICOOB",
      };
      sendData({ uri: uriList("autorizar"), content, method: "POST" })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          console.log("confirmar autorizacao sicoob:", res);
          toggleAccept();
          setAguarde(false);
          setAutorizado(true);
          // setInterval(() => setAguarde(false), 50000);
        })
        .catch((e) => {
          console.log("autorizar Sicoob(Erro): ", e);
          setAguarde(false);
        });
    }
  }

  return (
    <>
      {!getLocation && <Geolocalizacao onCloseEv={() => setGetLocation(true)} />}
      {getLocation && (
        <Modal
          size={width() !== "mobile" ? "lg" : "sm"}
          show={true}
          animation={false}
          backdrop="static"
          centered={true}
        >
          <Modal.Header
            className="formsign-header-custom text-center pt-2 pb-2"
            style={{ fontSize: "25px" }}
          >
            <strong>Declaração/Autorização SiCoob</strong>
          </Modal.Header>
          <Modal.Body
            style={{ padding: "15px", fontFamily: "Arial", fontSize: "12px" }}
          >
            <div className="form" style={{ overflow: "hidden" }}>
              {!aguarde && (
                // <div style={{ opacity: autorizado || naoExiste ? "0.4" : "" }}>
                <div>
                  <div
                    className="form-group row"
                    style={{ marginBottom: "0px" }}
                  >
                    <div className="col-sm-12">
                      <h6
                        className="text-justify mb-1"
                        style={{
                          margin: "0px",
                          fontSize: width() == "mobile" ? "x-small" : "",
                          textAlign: "justify",
                        }}
                      >
                        <span>
                          Eu:{" "}
                          <span class="font-weight-bold">
                            {data && data.nome}
                          </span>
                        </span>
                      </h6>
                      <h6
                        className="text-justify mb-1"
                        style={{
                          margin: "0px",
                          fontSize: width() == "mobile" ? "x-small" : "",
                          textAlign: "justify",
                        }}
                      >
                        <span>
                          CPF:{" "}
                          <span class="font-weight-bold">
                            {data && data.cpf && mCPF(data.cpf)}
                          </span>
                        </span>
                      </h6>
                      <h6
                        className="text-justify mb-4 mt-4"
                        style={{
                          margin: "0px",
                          fontSize: width() == "mobile" ? "x-small" : "",
                          textAlign: "justify",
                        }}
                      >
                        Declaro que as informações prestadas são verdadeiras,
                        responsabilizando-me civil e criminalmente por sua
                        autenticidade. Estou ciente de que deverei comunicar
                        toda e qualquer alteração em tais informações,
                        inclusive, sem limitação, em relação a seus dados
                        cadastrais e aos seus documentos comprobatórios, bem
                        como a revogação de mandatos, procurações e instruções,
                        no prazo de dez dias. Autorizo que meus dados
                        cadastrais, patrimoniais e financeiros informados a
                        qualquer das entidades Sicoob – Sistema de Cooperativas
                        de crédito do Brasil possam ser compartilhados entre
                        todas as entidades desse sistema, inclusive Fundação
                        Sicoob de Previdência Privada, Bancoob DTVM Ltda e
                        outras empresas com participação direta ou indireta de
                        qualquer entidade Sicoob. Autorizo tais entidades a
                        consultar e registrar no SCR ou em qualquer sistema de
                        proteção ao crédito eventuais informações existentes ao
                        meu respeito, inclusive sobre operações de crédito e
                        aquelas realizadas no mercado de câmbio.
                      </h6>
                    </div>
                  </div>

                  {!autorizado && (
                    <>
                      <div
                        className="form-group row"
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          marginBottom: "15px",
                          fontSize: "15px",
                        }}
                      >
                        <div className="col-sm-12">
                          <div className="custom-control mt-2 custom-checkbox font-weight-bold">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                              checked={checked}
                              onChange={(e) => setChecked(!checked)}
                              name="connected"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              <strong>Autorizar?</strong>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <button
                          style={{ backgroundColor: "#3E6C84", width: "100%" }}
                          onClick={submit}
                          disabled={!checked || aguarde}
                          className="btn btn-md btn-info font-weight-bold"
                        >
                          Avançar
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {aguarde && <Aguarde />}
      {autorizado && <Autorizado />}
      {naoExiste && <NaoExiste />}
    </>
  );
}

export default ConfirmAutorizacaoSicoob;
