import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as moment from 'moment'
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";

import "react-datepicker/dist/react-datepicker.css";
import { sendData, } from "../../../helpers/auth";

import { uriList } from "../../../helpers/config";
import { Pagination, } from "../../layout";
import CustomInput from "../../datepicker";
import {
  getTiposDocumentos,
  width,
  formatCurrency
} from "../../../helpers/general";
import AnalisarPendencia from "./Pendencias/AnalisarPendencia";
import ResolverPendencia from "./Pendencias/ResolverPendencia";

let filterIni = {
  ocultarFinalizadas: "sim",
  filtrarPor: "todos",
  cliente: "<todos>",
  pesquisa: "",
  convenio: "<todos>",
  status: "<todos>",
  automaticas: "<todos>",
  startDate: new Date(),
  finalDate: new Date()
}

const Status = [
  { nome: 'Aguardando Analise' },
  { nome: 'Pendente' },
  { nome: 'Resolvida' },
]

const Form = ({ ...props }) => {
  const [data, setData] = useState();
  const [filtro, setFiltro] = useState(filterIni);
  const [alterarItem, setAlterarItem] = useState(false)
  const [actualPage, setActualPage] = useState(0);
  const [qtdLinesPage, setQtdLinesPage] = useState(12);
  const [lastPage, setLastPage] = useState(0);
  const [carregando, setCarregando] = useState(false);
  const [total, setTotal] = useState(0)
  const pesquisaRef = useRef();
  const arrayPagination = [12, 30, 60, 300, 600, 900]
  const [periodo, setPeriodo] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())

  let _tipos = getTiposDocumentos(props?.user);
  let exibindoItens = false

  useEffect(() => {
    if (!carregando)
      _getDados();
  }, [actualPage, qtdLinesPage]);

  function handlePesquisar() {
    setCarregando(true)
    setActualPage(0)
    setData(null)
    _getDados()
  }

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  function handleQtdLinesPage(e) {
    setQtdLinesPage(e.currentTarget.value)
  }

  async function _getDados() {
    setCarregando(true)

    console.log("pendencias._getDados(0): ", props?.user?.tipo)
    let content = {
      userid: props.user.pessoa_id,
      agenteid: ['AGENTE', 'OPERADOR PARCEIRO'].indexOf(props?.user?.tipo) >= 0 ? props.user.pessoa_id : null,
      operadorid: ['AGENTE', 'OPERADOR PARCEIRO', 'CLIENTE'].indexOf(props?.user?.tipo) < 0 ? props.user.pessoa_id : null,
      tipoUsuario: props?.user?.tipo,
      ocultarFinalizadas: filterIni.ocultarFinalizadas == "sim",
      actualPage,
      periodo: periodo ? 1 : 0,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      finalDate: moment(finalDate).format('YYYY-MM-DD'),
      qtdLinesPage,
      filtro
    }

    console.log("pendencias._getDados(1): ", content)

    await sendData({
      uri: uriList('pendencias'),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (!res || parseInt(res.status) > 200) throw new Error();
        console.log("Pendencias.res(0): ", { data: res.data, res });

        setData(res.data)
        setLastPage(res.lastPage)
        setTotal(res.total)
        setCarregando(false)
        if (actualPage > res.lastPage)
          setActualPage(0)
      })
      .catch((e) => {
        setCarregando(false)
        console.log("Pendencias.erro(0)");
        console.log(e);
        return false;
      });

    //-- Setar foco no campo de pesquisa
    if (pesquisaRef?.current)
      pesquisaRef.current.focus();
  }

  const handleOption = (e) => {
    if (e?.currentTarget) {
      let _filtro = { ...filtro, [e.currentTarget.id]: e.currentTarget.value }
      setFiltro(_filtro)
    }
  }

  const handleClose = (select) => {
    setAlterarItem(false)
    if (select)
      _getDados()
  }

  const limparFiltros = () => {
    setFiltro(filterIni);
    setData(null)
    setPeriodo(false)

    //-- Setar foco no campo de pesquisa
    if (pesquisaRef?.current)
      pesquisaRef.current.focus();
  }

  return (
    <>
      {alterarItem &&
        (["OPERADOR", 'ADMINISTRADOR'].indexOf(props?.user?.tipo) >= 0 ?
          <ResolverPendencia dados={alterarItem} onCloseEv={handleClose} /> :
          <AnalisarPendencia dados={alterarItem} onCloseEv={handleClose} />)}
      <div className="row mb-2" style={{ padding: "5px", border: "1px solid #E5E5E5" }}>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"}`} style={{ display: "flex" }} >
          <div className={` col-md-2 mr-1`}>
            <label htmlFor="periodo" className="no-margin">
              Período
            </label>
            <input
              id="periodo"
              type="checkbox"
              checked={periodo}
              style={{ width: "15px", height: "15px" }}
              onChange={() => setPeriodo(!periodo)}
            />
          </div>

          <div className={width() == "mobile" ? "col-md-12" : "col-md-6"} style={{ marginTop: width() != "mobile" ? "23px" : "" }}>
            <DatePicker
              locale={pt}
              selected={startDate}
              className="form-control"
              onChange={(date) => setStartDate(date)}
              withPortal
              disabled={!periodo}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          <div className={width() == "mobile" ? "col-md-12" : "col-md-6"} style={{ marginTop: width() != "mobile" ? "23px" : "" }}>
            <DatePicker
              locale={pt}
              selected={finalDate}
              className="form-control"
              onChange={(date) => setFinalDate(date)}
              withPortal
              disabled={!periodo}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>
        </div>
        <div className={`ml-5 col-md-${width() != "mobile" ? "3" : "12"}`}>
          <label className="m-0 font-90">Pesquisar(CPF, Cliente, Agente...)</label>
          <input type="text" ref={pesquisaRef} className="form-control" id="pesquisa"
            autoFocus={true} value={filtro.pesquisa} onChange={handleOption}
            title={"Digite para pesquisar por: CPF, Cliente, Agente, Convênio, Observacao, Descricao"} />
        </div>
        <div className={`col-md-${width() != "mobile" ? "1" : "12"}`}>
          <label className="m-0 font-90">Ocultar Resolvidas</label>
          <select
            onChange={handleOption}
            style={{
              lineHeight: "2.2em",
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Ocultar Pendências Finalizadas"
            id="ocultarFinalizadas"
          >
            <option value="nao" selected={filtro.ocultarFinalizadas == "nao"}>Não</option>
            <option value="sim" selected={filtro.ocultarFinalizadas == "sim"}>Sim</option>
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "1" : "12"}`}>
          <label className="m-0 font-90">Status</label>
          <select
            onChange={handleOption}
            style={{
              lineHeight: "2.2em",
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Selecionar o Status"
            id="status"
          >
            <option selected={filtro.status == "<todos>"}>{"<todos>"}</option>
            {Status.map((item, index) => (<option value={item.nome}>{item.nome}</option>))}
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"}`}>
          <label className="m-0 font-90">Automáticas</label>
          <select
            onChange={handleOption}
            style={{
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Selecionar"
            id="automaticas"
          >
            <option selected={filtro.automaticas == "<todos>"}>{"<todos>"}</option>
            <option value={'sim'}>Sim</option>
            <option value={'nao'}>Não</option>
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "1" : "12"} d-flex`}>
          <button className="btn btn-primary btn-block mt-4" onClick={handlePesquisar}>Pesquisar</button>
          <button className="btn btn-secondary btn-block mt-4 ml-1" onClick={limparFiltros}>Limpar</button>
        </div>

      </div>

      <div className="row mb-2" style={{ padding: "10px", border: "1px solid #E5E5E5", justifyContent: "center", }}>
        {data &&
          <div className="row d-flex col-12 mb-2" style={{ flexWrap: "wrap", flexDirection: "row", marginLeft: "10px" }} >
            <Pagination
              detailed={data}
              actual={actualPage}
              changePage={handlePage}
              qtdLinesPage={qtdLinesPage}
              lastPage={lastPage}
            />
            <div className="page-item ml-1" style={{ padding: "0.05rem" }}>
              <select
                defaultValue={qtdLinesPage}
                name="qtdLinesPage"
                id="qtdLinesPage"
                onChange={handleQtdLinesPage}
                style={{
                  width: "75px",
                  height: "100%",
                  textAlign: "center",
                  backgroundColor: "#EBEEE9",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                  backgroundImage:
                    "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                }}
              >
                {arrayPagination.map((lines, i) => (
                  <option
                    key={`lines-${i}`}
                    label={lines}
                    value={lines}
                  >
                    {lines}
                  </option>
                ))}
              </select>
            </div>
            <div className="page-item ml-1 mt-2" style={{ padding: "0.05rem" }}>
              <span className="ml-3 mr-1 ">Registros: <span className="font-weight-bold">{total}</span></span>
            </div>
          </div>}
        <div className="row d-flex col-12" style={{ flexWrap: "wrap", flexDirection: "row", }} >
          {carregando && <div className="d-flex justify-content-center font-weight-bold" style={{ width: "100%" }}><h4>Carregando dados, aguarde...</h4></div>}
          {!carregando && data && data.map((item, index) => {
            let _tipo = _tipos.filter(_item => _item?.value == item?.anexar_doc_tipo)
            if (_tipo.length > 0)
              item.anexar_doc_tipo = _tipo[0].nome
            exibindoItens = true

            let _concluida = ["Resolvida"].indexOf(item.status) >= 0
            return (
              <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`} style={{ cursor: "pointer", }}
                onClick={() => { setAlterarItem(item) }}               >
                <div className="card"
                  style={{
                    border: `1px solid ${_concluida ? "#215F04" : "#8F1502"}`,
                    opacity: _concluida ? "50%" : "100%"
                  }}>
                  <div className="card-header font-weight-bold text-center" style={{ backgroundColor: "#ECECEC", padding: "0px" }}>
                    <h5>{item?.descricao}</h5>
                  </div>
                  <div className="col-12 mb-1" style={{ padding: "3px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>Status: {item?.status}</div>
                      <div>Id: {item?.id}</div>
                    </div>
                    <div>
                      Dt Atualização: {moment(item?.updated_at).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                    {item.anexar_doc_tipo &&
                      <div>
                        Anexar: {item.anexar_doc_tipo}
                      </div>
                    }
                    {item?.proposta_uuid &&
                      <>
                        <div style={{ marginBottom: "5px", marginTop: "5px", borderBottom: "1px solid #aaa", padding: "0px" }}></div>
                        <div>
                          Cliente: {item?.cliente_cpf + '-' + item?.cliente_nome}
                        </div>
                        <div>
                          Agente: {item?.agente_nome}
                        </div>
                        <div>
                          Convênio: {item?.convenio_nome}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>Valor: {formatCurrency(item?.valor_total)}</div>
                          <div>Parcela: {formatCurrency(item?.valor_parcelas)}</div>
                          <div>Prazo: {item?.prazo}</div>
                        </div>
                      </>
                    }
                  </div>
                  <div className="mb-1" style={{ borderTop: "1px solid #aaa", }}>
                    <div style={{ padding: "5px", textAlign: "center", }}>
                      <span className="font-weight-bold">{item?.observacao}</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {!carregando && !exibindoItens && <div className="d-flex justify-content-center font-weight-bold" style={{ width: "100%" }}><h4>Sem dados para exibição</h4></div>}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);

