import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../components/form/simulator/selfie.js";
import ModalLoc from "../../components/modal";
import Terms from "./terms/terms";
import Confirm from "./confirm";
import { Buttons } from "../../components/layout";

import {
  sendForm,
  setSession,
  getSession,
  sendData,
  clearSession,
  gravarEtapaProposta,
} from "../../helpers/auth";
import { exportAsImage, getCookie, } from "../../helpers/general";
import { getItemCrypt } from '../../helpers/auth'
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA } from "../../helpers/config";
import { connect } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import Geolocalizacao from "../../helpers/geolocalizacao";
import { post } from "axios";

function Selfie({ ...props }) {
  const [data, setData] = useState({
    selfie: "",
    latitude: 0,
    longitude: 0,
    proposta_uuid: props.proposta,
  });

  const [showAlert, setAlert] = useState({
    selfie: 0,
  });

  const [aguarde, setAguarde] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [accept, setAccept] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [modal, setModal] = useState("");
  const [codigoValido, setCodigoValido] = useState(false);
  const [getLocation, setGetLocation] = useState(false);
  const [screenShot, setScreenShot] = useState();

  const closeModal = () => {
    console.log("selfie.CloseModal");
    setModal("");
  };

  const openInfoModal = async ({ title, text, list, bold }) => {
    console.log("selfie.openInfoModal");
    setModal(
      <ModalLoc
        title={title}
        text={text}
        list={list}
        bold={bold}
        onClick={closeModal}
        divID={"termos-lgpd"}
      />
    );
  };

  const gravarScreenShot = async (screenShot, uuid) => {
    if (!screenShot) return false;
    console.log("result.gravarScreenShot(1): ", screenShot);
    let dObj = new FormData();
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];

    dObj.append("tipo", "termos-lgpd");
    dObj.append("proposta_uuid", uuid);
    dObj.append("file", screenShot);
    dObj.append("ip", getCookie({ label: "ip" }));
    dObj.append("geolocation", JSON.stringify({ latitude, longitude }));
    console.log("result.gravarScreenShot(2)");

    const url = uriList("documentos");
    const config = {
      headers: {
        Accept: "*",
        key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
        "content-type": "multipart/form-data",
        "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
        "captcha": getItemCrypt(TOKEN_CAPTCHA),
      },
    };

    console.log("result.gravarScreenShot(3)");
    post(url, dObj, config)
      .then((response) => {
        console.log("Result.gravarScreenShot(ok)");
      })
      .catch((err) => {
        console.log("Result.gravarScreenShot(error)");
      });
    console.log("result.gravarScreenShot(4)");
  };

  const Btn = () => (
    <button
      className="btn btn-lg btn-info col-12 mt-3"
      onClick={() => setAccept(true)}
    >
      Enviar
    </button>
  );

  let history = useHistory();

  function Aguardar(aguarde = true) {
    setAguarde(aguarde);
  }

  function handleChange(field, innerData) {
    let obj = Array.isArray(innerData)
      ? Object.assign(innerData)
      : Object.assign([innerData]);

    setData({ ...data, [field]: obj });
    setAlert({ ...showAlert, [field]: 0 });
  }

  function handleDelete(field) {
    let uuid = props.proposta;
    setData({ ...data, [field]: "" });

    function sendDocs() {
      sendData({
        uri: uriList("docs-remove"),
        content: { uuid, tipo: field },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          setData({ ...data, [field]: "" });
        })
        .catch((e) => {
          console.log("Removendo...", openModal);
          sendDocs();
        });
    }

    sendDocs();
  }

  function goBack(to) {
    setSession({ obj: data, key: "selfie" });
    props.toggleStep(-1);
  }

  async function submit(e, to) {
    if (e && e.preventDefault) e.preventDefault();

    let [res, obj] = sendForm(e, data);
    res = res && data.selfie;
    if (!res) {
      setAlert({ ...obj });
      return false;
    }

    setSession({ obj: data, key: "selfie" });

    await gravarEtapaProposta({ uuid: props.proposta, gravou_selfie: 1 });
    if (!props.preencherCli) setOpenConfirm(true);
    else {
      setOpenModal(true);
      setScreenShot(
        await exportAsImage("termos-lgpd", "termos-lgpd.jpg", props.user?.name)
      );
    }

    return true;
  }

  useEffect(() => {
    function _goBack() {
      if (!props.preencherCli) {
        clearSession();

        history.push("./status");
      } else if (props.confirmCli) props.confirmCli();
    }

    if (accept) {
      if (!props.detalhes.envio_assinatura) {
        sendData({
          uri: uriList("submit"),
          content: { uuid: props.proposta },
          method: "POST",
        })
          .then((res) => {
            if (res.status && res.status > 200) throw new Error();
            _goBack();
          })
          .catch((err) => {
            console.log("Erro: ", err);
          });
      } else _goBack();
    }
    // eslint-disable-next-line
  }, [accept]);

  useEffect(() => {
    setCodigoValido(false);
    let storeData = getSession({ key: "selfie" });

    sendData({
      uri: uriList("docs-show"),
      content: { uuid: props.proposta },
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        console.log("Selfie: ", res);
        if (res.length > 0) {
          for (let r in res) {
            if (res[r].tipo === "selfie")
              setData({
                selfie: [
                  {
                    ext: "doc",
                    tipo: res[r].tipo,
                    preview: null,
                    url: res[r].url,
                    contentType: res[r].tipo_conteudo,
                  },
                ],
              });
          }
        }

        setLoaded(false);
      })
      .catch((e) => {
        if (storeData) setData({ ...storeData });
        setLoaded(false);
      });

    sendData({
      uri: uriList("checarCodigo"),
      content: { uuid: props.proposta },
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        console.log(props.proposta);
        setCodigoValido(true);
      })
      .catch((e) => {
        console.log("Erro ao obter código válido!");
        console.log(props.proposta);
      });
  }, []);

  async function toggleConfirm(e) {
    if (e && e.preventDefault) e.preventDefault();

    setOpenConfirm(false);
    setOpenModal(true);
    setScreenShot(
      await exportAsImage("termos-lgpd", "termos-lgpd.jpg", props.user?.name)
    );
  }

  function toggleCloseModal(e) {
    if (e && e.preventDefault) e.preventDefault();

    setOpenModal(false);
  }

  async function toggleAccept(e) {
    if (e && e.preventDefault) e.preventDefault();

    setAccept(true);

    await gravarScreenShot(screenShot, props.proposta);

    if (props.confirmCli) props.confirmCli();
  }

  return (
    <>
      {!getLocation && (
        <Geolocalizacao onCloseEv={() => setGetLocation(true)} />
      )}
      {getLocation && (
        <>
          <fieldset className="col-12">
            <h4
              className="display-4 text-center font-weight-bold"
              style={{ marginBottom: "15px", color: "#3E6C84" }}
            >
              {props.title}
            </h4>
            {!openModal && !openConfirm && (
              <>
                <Form
                  data={data}
                  modal={openInfoModal}
                  warning={showAlert}
                  change={handleChange}
                  del={handleDelete}
                  aguardar={Aguardar}
                  disabled={loaded}
                  setGravarSelfie={props.setGravarSelfie}
                />

                <Buttons goBack={goBack} submit={submit} />
              </>
            )}
          </fieldset>
          {openConfirm && (
            <Confirm
              onCloseEv={() => setOpenConfirm(false)}
              onAccept={toggleConfirm}
            />
          )}
          {openModal && (
            <Terms
              onCloseEv={toggleCloseModal}
              onAccept={toggleAccept}
              codigoValido={codigoValido}
            />
          )}
          {aguarde && (
            <Modal size={"sm"} show={true} animation={false} backdrop="static">
              <Modal.Body
                className="maxWidth750"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Spinner animation="border" />
                {"  "}
                Aguarde...
              </Modal.Body>
            </Modal>
          )}
        </>
      )}
      {modal}
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
    user: state.dados.user,
    preencherCli: state.dados.preencherCli,
  };
}

export default connect(mapStateToProps)(Selfie);
