import { Collapse, CardBody, Card, CardHeader, Button, CardFooter } from 'reactstrap';
import React, { useEffect, useState } from "react";
import { width } from "../../helpers/general";


function CardCollapse(props) {
    const [toggleQuestion, setToggleQuestion] = useState(props.isOpen ? 1 : 0)

    function handleToggle(area) {
        let collapse = toggleQuestion && !props.forceCollapse ? 0 : 1

        if (area != "body" || props.bodyToggle) {
            setToggleQuestion(collapse)

            if (props.setCollapse)
                props.setCollapse(!collapse)
        }
    }

    // e9ecef
    return (
        <Card className='' style={{ width: width() == "mobile" ? "" : "100%", padding: "0 !important" }}>
            <div className='' style={{ backgroundColor: "#D5DBE1" }}>
                <div className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={handleToggle}>
                    <h5 className='font-weight-bold mr-1'>{props.title}</h5>
                    {!props.forceCollapse && <h5><i className={`mdi ${toggleQuestion == 0 ? 'mdi-arrow-up-bold' : 'mdi-arrow-down-bold'}`} /></h5>}
                </div>
            </div>
            <Collapse isOpen={toggleQuestion === 1 ? true : false} >
                <div style={{ overflowX: "auto", height: props.maxHeigth || "300px", cursor: props.bodyToggle ? "pointer" : "" }} onClick={() => handleToggle("body")}>
                    {props.chield && <props.chield />}
                </div>
            </Collapse>
        </Card>
    )
}

export default CardCollapse;