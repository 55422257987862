import React, { useEffect } from "react";
import { InputLabelAlert } from "../../layout";

const Form = ({ ...props }) => {
  return (
    <>
      <div className="row">
        <div className="form-group col-md-3">
          <InputLabelAlert
            type="tel"
            label="CEP"
            id="cep"
            value={props.data.cep}
            minLength={9}
            handle={props.CEP}
            warning={props.warning.cep}
            length="9"
            focus={true}
          />
        </div>
        <div className="form-group col-md-5">
          <label htmlFor="tipo_endereco" className="no-margin">
            Tipo de Endereço
          </label>
          <select
            id="tipo_endereco"
            name="tipo_endereco"
            value={props.data.tipo_endereco}
            onChange={props.changeData}
            style={{
              borderColor: props.warning.tipo_endereco ? "red" : "",
              textAlign: "left",
            }}
            className="form-control custom-select"
          >
            {props.tipo_endereco &&
              props.tipo_endereco.map((tipo, i) => (
                <option key={`${tipo.CODIGO}${i}}`} value={tipo.CODIGO}>
                  {tipo.DESCRICAO}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-3">
          <label htmlFor="t_log" className="no-margin">
            Tipo
          </label>

          <select
            id="t_log"
            name="t_log"
            minLength={2}
            value={props.data.t_log}
            onChange={props.changeData}
            style={{
              borderColor: props.warning.t_log ? "red" : "",
              textAlign: "left",
            }}
            className="form-control custom-select text-capitalize"
          >
            {props.logradouros.map((logradouro, i) => (
              <option
                key={`${logradouro}-${i}`}
                value={logradouro.toUpperCase()}
              >
                {logradouro}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group col-md-9">
          <InputLabelAlert
            type="text"
            label="Logradouro"
            minLength={5}
            id="logradouro"
            length="150"
            warning={props.warning.logradouro}
            value={props.data.logradouro}
            handle={props.changeData}
            focus={false}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-8">
          <InputLabelAlert
            type="text"
            label="Complemento"
            id="complemento"
            length="150"
            warning={props.warning.complemento}
            value={props.data.complemento}
            handle={props.changeData}
            focus={false}
          />
        </div>

        <div className="form-group col-md-4">
          <InputLabelAlert
            type="text"
            label="Numero"
            id="numero"
            length="25"
            warning={props.warning.numero}
            value={props.data.numero}
            handle={props.changeData}
            focus={false}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-12">
          <InputLabelAlert
            type="text"
            label="Bairro"
            id="bairro"
            length="50"
            warning={props.warning.bairro}
            value={props.data.bairro}
            handle={props.changeData}
            focus={false}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="uf" className="no-margin">
            UF
          </label>

          <select
            id="uf"
            name="uf"
            minLength={2}
            value={props.data.uf}
            onChange={props.changeData}
            style={{
              borderColor: props.warning.uf ? "red" : "",
              textAlign: "left",
            }}
            className="form-control custom-select"
          >
            {[...props.estados.estados].map((estados, i) => (
              <option key={`${estados.sigla}${i}}`} value={estados.sigla}>
                {estados.nome}
              </option>
            ))}
          </select>
        </div> 

        <div className="form-group col-md-8">
          <label htmlFor="cidade" className="no-margin">
            Cidade
          </label>

          <select
            id="cidade"
            name="cidade"
            minLength={3}
            // value={props.data.cidade}
            onChange={props.changeData}
            style={{
              borderColor: props.warning.cidade ? "red" : "",
              textAlign: "left",
            }}
            className="form-control custom-select"
          >
            {props.data.uf &&
              [...props.estados.estados].map(
                (estados) =>
                  estados.sigla === props.data.uf &&
                  estados.cidades.map((cidades, i) => (
                    <option
                      selected={ cidades && props.data.cidade &&
                        cidades.toUpperCase() == props.data.cidade.toUpperCase()
                      }
                      key={`${cidades}-$i`}
                      value={cidades}
                    >
                      {cidades}
                    </option>
                  ))
              )}
          </select>
          {/* </datalist> */}
        </div>
      </div>
    </>
  );
};

export default Form;
