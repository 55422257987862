import React from "react";

const Modal = ({ ...props }) => {
  return (
    <div
      className="modal"
      tabIndex="-1"
      centered
      id={props.divID || "modal-local"}
      role="dialog"
      style={{
        display: "flex",
      }}
      onClick={props.selfie ? null : props.onClick}
    >
      <div
        className={`${
          props.selfie || props.page
            ? "card bg-light col-md-6 px-0 m-auto"
            : "modal-dialog"
        }`}
        style={{
          border: props.selfie ? "none" : "",
          left: props.page && "50%",
          transform: props.page && "translateX(-50%)",
        }}
        role="document"
      >
        <div
          className="modal-content"
          style={{
            height: props.selfie || props.page ? window.innerHeight + "px" : "",
            overflowY: "auto",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <h5 className="modal-title font-weight-bold text-info">
              {props.title}
            </h5>
            <button
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClick}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {props.text ? (
            !props.list ? (
              <div className="modal-body">
                {props.text.map((text, i) => (
                  <div key={i}>{text}</div>
                ))}
              </div>
            ) : (
              <div className="modal-body">
                <ul>
                  {props.text.map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ul>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
