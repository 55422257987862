import React, { useState, useEffect } from "react";

import SimulatorLayout from "../components/layout";
import Result from "../views/simulator/result";
import { connect } from "react-redux";
import {
  setUser,
  setAlterarProposta,
  setCliente,
  setProposta,
} from "../store/actions/dados";
import Status from "./status";

function RefinPort({ ...props }) {
  const [data, setData] = useState({});
  const [result, setResult] = useState({});
  const [enviando, setEnviando] = useState(false);
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 1,
  });

  function goBack() {
    console.log("refinPort - goBack");
    props.setProposta("limpar");
    props.setAlterarProposta(false, props.detalhes);
    props.alterarCliente(null);

    if (props.onCloseEv) props.onCloseEv();
  }

  useEffect(() => {
    console.log("RefinPort: ", props.detalhes);
  }, []);

  const toggleStep = async (n, dados, result) => {
    console.log("refinPort - toggleStep: ", n);
    setData(dados);
    setResult(result);
    if (!n || n === undefined) n = 1;
    let _next = step.cur + n;
    if (_next >= 0 && _next <= step.maxStep) setStep({ ...step, cur: _next });

    //-- Enviando proposta para o cliente
    if (step.cur >= step.maxStep) {
      console.log("refinPort - toggleStep(1): ", n);
      setEnviando(true);
      goBack();
      return setStep({ ...step, cur: 0 });
    }
  };

  const RenderScreen = ({ ...props }) =>
    ({
      0: (
        <Result
          title="Simulação"
          toggleStep={toggleStep}
          result={data}
          data={result}
          admin={true}
          refinPort={true}
          goBack={goBack}
        />
      ),

      1: <Status toggleStep={toggleStep} admin={true} enviando={enviando} />,
    }[props.step] || (
      <>
        <h1>404!</h1>
        <h4>Esse formulário: {props.step}não existe! </h4>
      </>
    ));

  return (
    <>
      {step.cur >= step.maxStep ? (
        <RenderScreen step={step.cur} />
      ) : (
        <SimulatorLayout admin={true}>
          <RenderScreen step={step.cur} />
        </SimulatorLayout>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(RefinPort);
