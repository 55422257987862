import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { width } from "../../helpers/general";
import { connect } from "react-redux";
import {
  setAlterarProposta,
  setCliente,
  setClickedMenu,
} from "../../store/actions/dados";

const MenuItem = ({ ...props }) => {
  const item = props.item || {};
  const [clicked, setClicked] = useState(props.clickedMenu);

  useEffect(() => {
    // console.log("UseEffect: ", props.clickedMenu);
  }, [props.clickedMenu]);

  const handleClick = () => {
    if (width() === "mobile") {
      document.body.classList.toggle("fixed-left-void");
      document.getElementById("wrapper").classList.toggle("enlarged");
    }

    //-- Sainda da tela de edição da proposta
    props.setAlterarProposta(false, props.detalhes);
    props.alterarCliente(null);

    //-- Setando menu clicado
    props.clicarMenu(item);
    // setClicked(item)
  };

  return (
    <>
      {item.visible && (
        <Link
          className={item.class}
          to={item.url}
          onClick={() => handleClick(item)}
          style={{
            backgroundColor: clicked?.id == item.id ? "#D6D6D6" : "",
            // backgroundColor:  "#D6D6D6",
          }}
        >
          {item.icon && <i className={item.icon} ></i>}
          <span>
            {" "}
            {item.label}
            {item.badgeclass ? (
              <span className={item.badgeclass}>{item.badgevalue}</span>
            ) : null}
          </span>
        </Link>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    detalhes: state.dados.detalhes,
    clickedMenu: state.dados.clickedMenu,
  };
}

function mapActionToProps(dispatch) {
  return {
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
    clicarMenu(novoCliente) {
      dispatch(setClickedMenu(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(MenuItem);
