import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { InputLabelAlert } from "../../layout";
import { sendData } from "../../../helpers/auth";
import { width } from "../../../helpers/general";
import { uriList } from "../../../helpers/config";
import { connect } from "react-redux";

const Form = ({ ...props }) => {
  const [codigo, setCodigo] = useState("");
  const [enviado, setEnviado] = useState(false);
  const [warning, setWarning] = useState(false);
  const [habilitado, setHabilitado] = useState(true);
  const [validado, setValidado] = useState(true);

  useEffect(() => {
    console.log("validarCodigo.useEffect: ", props.detalhes.uuid, props.proposta)
  }, []);

  const handleChange = (e) => {
    let target = e.currentTarget;
    let value = target.value;
    value = value.toUpperCase().trim();
    setCodigo(value);
    setWarning(false);
  };

  const handleEnviar = async () => {
    setHabilitado(false);
    await sendData({
      uri: uriList("enviarCodigo"),
      content: { uuid: props.detalhes.uuid || props.proposta.uuid },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setEnviado(true);
        setValidado(false);

        window.setTimeout(() => setHabilitado(true), 30000);
      })
      .catch((e) => {
        setHabilitado(true);
        setValidado(true);
        console.log(e.message);
        console.log(e.data);
        if (e.data) console.log(e.data.message);
        alert("Erro ao enviar código!");
      });
  };

  const handleValidar = async () => {
    if (codigo) {
      let content = { uuid: props.proposta, codigo };
      // console.log("ValidarCodigo: ", content)
      await sendData({ uri: uriList("validarCodigo"), content, method: "POST" })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();

          setValidado(true);  
          if (props.onCloseEv) props.onCloseEv(true);
        })
        .catch((e) => {
          setValidado(true);
          setWarning(true);
          console.log(e);
          // alert('Código inválido.')
        });
    } else setWarning(true);
  };

  return (
    <div
      style={{
        border: "0.3px solid",
        width: "100%",
        height: "100%",
        borderColor: "#8C8C8C",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          <strong>
            <p style={{ fontSize: "23px" }}>Validar Proposta</p>
          </strong>
        </div>
        {
          <div
            style={{
              marginBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {habilitado && (
              <button onClick={handleEnviar} disabled={!habilitado}>
                {!enviado ? "Receber Código" : "Reenviar Código"}
              </button>
            )}
            {!habilitado && (
              <div style={{ color: "#8C8C8C" }}>Aguarde para Reenviar...</div>
            )}
            <div
              style={{
                fontSize: "12px",
                color: "#3E6C84",
                width: "80%",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <p style={{ textAlign: "center" }}>
                * Você receberá um código através de SMS/email.
              </p>
            </div>
          </div>
        }
        {enviado && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: "10px" }}>
              <InputLabelAlert
                type="text"
                label="Código:"
                id="codigo"
                cifrao={false}
                value={codigo}
                length={5}
                onChange={codigo}
                handle={handleChange}
                autoComplete="off"
                warning={warning}
                focus={true}
                msgErro="Código inválido!"
              />
            </div>
            <Button color="success" onClick={handleValidar}>
              Validar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(Form);
