import React from "react";

import { InputAlert, InputLabelAlert } from "../../layout";
import { orgaosList } from '../../../helpers/Uteis'

const Form = ({ ...props }) => (
  <>
    <div className="row">
      <div className="form-group col-md-4">
        <InputLabelAlert
          type="text"
          label="Tipo de Documento"
          id="t_doc"
          length="25"
          minLength={2}
          list="t_doc-list"
          inputClass="custom-select"
          warning={props.warning.t_doc}
          value={props.data.t_doc}
          handle={props.alpha}
          focus={true}
        />

        <datalist id="t_doc-list">
          <option value="CNH">CNH</option>
          <option value="RG">RG</option>
          <option value="Carteira Funcional">Carteira Funcional</option>
          <option value="Passaporte">Passaporte</option>
        </datalist>
        <div
          className="valid-feedback text-info"
          style={{ display: "block", textAlign: "left" }}
        >
          * Para tornar o procedimento mais ágil utilize a CNH.
        </div>
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-5">
        <InputLabelAlert
          type="text"
          label="Numero de Documento"
          id="num_doc"
          length="50"
          minLength={3}
          warning={props.warning.num_doc}
          value={props.data.num_doc}
          handle={props.changeDoc}
          focus={false}
        />
      </div>
      <div className="form-group col-md-4">
        <InputLabelAlert
          type="tel"
          label="Data de Expedição"
          id="data_exp"
          warning={props.warning.data_exp}
          value={props.data.data_exp}
          handle={props.nums}
          length="10"
          focus={false}
        />

        <InputAlert
          display={props.alternate.data_exp}
          messages={props.alternateMessage.data_exp}
        />
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="text"
          label="Órgão Expedidor"
          id="orgao"
          warning={props.warning.orgao}
          value={props.data.orgao}
          handle={props.alpha}
          inputClass="custom-select"
          list="orgao-list"
          length="25"
          focus={false}
        />

        <datalist id="orgao-list">
          {orgaosList.map((item, i) => (
            <option value={item.value} key={i}>{item.descricao}</option>
          ))}
        </datalist>
      </div>

      <div className="form-group col-md-6">
        <label htmlFor="uf_exp" className="no-margin">
          UF de Expedição
        </label>

        <select
          id="uf_exp"
          name="uf_exp"
          minLength={2}
          value={props.data.uf_exp}
          onChange={props.changeDoc}
          style={{
            borderColor: props.warning.uf_exp ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {[...props.estados.estados].map((estados, i) => (
            <option key={`${estados.sigla}${i}}`} value={estados.sigla}>
              {estados.nome}
            </option>
          ))}
        </select>
        {/* </datalist> */}
      </div>
    </div>
  </>
);

export default Form;
