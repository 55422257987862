import React from "react";
import { connect } from "react-redux";

const ContextMenu = ({ options, display, ...props }) => {
  let multSelect = props.detalhes.multSelect;
  // console.log("context: ", display)
  let _options =
    options &&
    options.filter(
      (option) =>
        option.enabled && (multSelect.length <= 1 || option.multSelect)
    );

  return _options && display ? (
    <ul
      className="card px-3 py-1 m-0 border border-secondary d-print-none"
      id="context-menu"
      style={{
        minWidth: "100px",
        maxHeight: 28 * _options + "px",
        position: "absolute",
        top: display.y,
        left: display.x,
        listStyle: "none",
        zIndex: 9999,
        display: display.up ? "block" : "none",
        borderWidth: "2px",
      }}
    >
      {options &&
        options.length > 0 &&
        options.map((option, i) => {
          let enabled =
            option.enabled && (multSelect.length <= 1 || option.multSelect);

          if (enabled)
            return (
              <li
                onClick={() => {
                  if (enabled) listenToClick(option.action, option);
                }}
                onMouseOver={(e) => {
                  if (enabled) e.currentTarget.classList.add(option.colorClass);
                }}
                onMouseOut={(e) => {
                  e.currentTarget.classList.remove(option.colorClass);
                }}
                key={option.id}
                className={
                  option.borderBottom
                    ? "border-bottom border-secondary my-1"
                    : option.borderTop
                      ? "border-top border-secondary my-1"
                      : "my-1"
                }
                title={
                  props &&
                    props.detalhes &&
                    props.detalhes.averbacao_solicitada &&
                    option.action == "solicitarAverbacao"
                    ? "Consulta a anuência da Averbação"
                    : option.tip
                }
                style={{
                  cursor: enabled ? "pointer" : "text",
                  // textTransform:
                  //     'capitalize',
                  fontSize: "0.85em",
                  color: enabled ? "#000" : "#d3d3d3",
                }}
              >
                <i className={option.icon} style={{ marginRight: "5px" }} />
                {props &&
                  props.detalhes &&
                  props.detalhes.averbacao_solicitada &&
                  option.action == "solicitarAverbacao"
                  ? "Consultar Averbação"
                  : props &&
                    props.detalhes &&
                    props.detalhes.posicao &&
                    props.detalhes.posicao.toLowerCase() ==
                    "assinando ccb" &&
                    option.action == "enviarParaAssinatura"
                    ? "Reenviar para Assinatura"
                    : option.title}
              </li>
            );
        })}
    </ul>
  ) : null;
};

const listenToClick = (action, option) => {
  console.log("context.listenToClick: ", { action, option })
  localStorage.setItem("action", action);
  if (option.checklist)
    localStorage.setItem("checklist", "true");
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
  };
}

export default connect(mapStateToProps)(ContextMenu);
