import React, { useState, useEffect, useCallback } from "react";
import { Button } from "reactstrap";
import { sendData } from "../../../helpers/auth";
import { Aguarde, getTiposDocumentos } from "../../../helpers/general";
import { uriList, } from "../../../helpers/config";
import Card from "./CardDocs/Card";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import Upload from "./uploadDocumentos";
import { confirmSwal, swalError } from "../../../components/swal";

const Form = ({ ...props }) => {
  const [deleteDoc, setDeleteDoc] = useState(false);
  const [message, setMessage] = useState("Carregando documentos...");
  const [upload, setUpload] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [tipos, setTipos] = useState(false);
  const [existeCcb, setExisteCcb] = useState(false);
  const [load, setLoad] = useState(false);
  const [dossie, setDossie] = useState(false);
  const [selfie, setSelfie] = useState(false);
  const [docsChecados, setDocsChecados] = useState(false);

  const handleUpload = () => {
    setUpload(false);
    setMessage("Carregando documentos...");
    _documentos();
  };

  const WaitDelete = () => {
    return <Aguarde />;
  };

  const aprovarDocumentos = () => {
    setMessage("Aprovando documentos...");
    console.log("aprovarDocumentos(0): ", { uuid: props.proposta });
    sendData({
      uri: uriList("aprovardocumentos"),
      content: { uuid: props.proposta },
      method: "POST",
    })
      .then((res) => {
        setMessage("");
        console.log("aprovarDocumentos (1)");
        setDocsChecados(true)
      })
      .catch((e) => {
        setMessage("");
        console.log("aprovarDocumentos (erro)");
        return false;
      });
  }

  const uploadNuvem = () => {
    setMessage("Fazendo upload dos documentos para nuvem...");
    console.log("docs-upload-nuvem (0): ", { uuid: props.proposta });
    sendData({
      uri: uriList("docs-upload-nuvem"),
      content: { uuid: props.proposta },
      method: "POST",
    })
      .then((res) => {
        setMessage("");
        console.log("docs-upload-nuvem (1)");
        _documentos();
      })
      .catch((e) => {
        setMessage("");
        console.log("docs-upload-nuvem (erro)");
        return false;
      });
  };

  const _documentos = async () => {
    let _tipos = getTiposDocumentos(props?.user);
    setLoad(false);
    setExisteCcb(false);
    setTipos(_tipos);
    setDossie(false)
    setSelfie(false)
    console.log("gerirDocumentos._documentos-Tipos: ", props?.detalhes);
    // sendData({ uri: uriList('docs-upload-nuvem'), content: { uuid: props.proposta }, method: 'POST' })
    await sendData({
      uri: uriList("docs-show"),
      content: { uuid: props?.proposta, cliente_id: props?.detalhes?.cliente_id },
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        if (res.length > 0) {
          let _tipos = getTiposDocumentos(props?.user);
          for (let elemento of res) {

            if (elemento?.tipo == "ccb") setExisteCcb(true);
            if (elemento?.tipo == "dossie") setDossie(true)
            if (elemento?.tipo == "selfie") setSelfie(true)
            _tipos = _tipos.filter(
              (elemn) => elemn.value == "outros" || elemn.value != elemento?.tipo
            );
          }

          setTipos(_tipos);
          setDocumentos(res);
          setMessage("");
          setLoad(true);
          console.log("Documentos.gerirDocumentos(tipos): ", _tipos);
        } else {
          setMessage("Proposta sem documentos!");
          setDocumentos(false);
        }
      })
      .catch((e) => {
        setMessage("Erro ao buscar documentos!");
        return false;
      });
  };

  useEffect(() => {
    _documentos();
  }, []);

  const handleClose = () => {
    if (props.onCloseEv) props.onCloseEv();
  };

  const handleDelete = (doc) => {
    // if (["ADMINISTRADOR"].indexOf(props.user?.tipo) < 0) return;    

    if (["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) < 0 && props.detalhes?.enviada) //-- Somente administradores excluem documentos(Alexandre 23.12.2022)
      return;

    setDeleteDoc(true);
    sendData({
      uri: uriList("docs-remove"),
      content: { uuid: props.proposta, id: doc.id },
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        setDeleteDoc(false);
        _documentos();
      })
      .catch((e) => {
        setDeleteDoc(false);
        console.log("Erro ao remover: ", e);
      });
  };

  const Render = () => {
    return (
      <>
        {message ? (
          <div>{message}</div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              position: "relative",
              justifyContent: "center",
              maxHeight: "500px",
            }}
            name="modalContainer"
          >
            {documentos.map((row, i) => {
              // console.log("gerirDocumentos.Render(row): ", row);

              return (
                <>
                  <Card
                    titulo={row.tipo}
                    url={row.url}
                    descricao={row.descricao || row.tipo}
                    tipo={row.tipo}
                    onDelete={() => {
                      handleDelete(row);
                    }}
                  />
                </>
              );
            })}
          </div>
        )}{" "}
      </>
    );
  };

  const gerarCcb = () => {
    function callback() {
      setDeleteDoc(true);
      sendData({
        uri: uriList("gerarCcb"),
        content: { uuid: props.proposta, forca: true },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          _documentos();
          setDeleteDoc(false);
        })
        .catch((e) => {
          setDeleteDoc(false);
          console.log("Erro ao gerar CCB: ", e);
        });
    }

    confirmSwal({ callback, title: "Confirma a execução do processo?" });
  };

  const obterSelfie = () => {
    function callback(e) {
      if (!e) return false;
      setDeleteDoc(true);
      console.log("gerirDocumentos.obterSelfie(0)");
      sendData({
        uri: uriList("obterSelfie"),
        content: { uuid: props.proposta, forca: true },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          _documentos();
          setDeleteDoc(false);
          console.log("gerirDocumentos.obterSelfie(1)");
        })
        .catch((e) => {
          setDeleteDoc(false);
          console.log("Erro ao gerar Dossiê: ", e);
        });
    }

    confirmSwal({ callback, title: "Confirma a execução do processo?" });
  }

  const gerarDossie = () => {
    function callback(e) {
      if (!e) return false;
      setDeleteDoc(true);
      console.log("gerirDocumentos.gerarDossie(0)");
      sendData({
        uri: uriList("gerarDossieExterno"),
        content: { uuid: props.proposta, forca: true },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          _documentos();
          setDeleteDoc(false);
          console.log("gerirDocumentos.gerarDossie(1)");
        })
        .catch((e) => {
          setDeleteDoc(false);
          console.log("Erro ao gerar Dossiê: ", e);
        });
    }

    confirmSwal({ callback, title: "Confirma a execução do processo?" });
  };

  return (
    <>
      {upload ? (
        <Upload onCloseEv={handleUpload} tipos={tipos} />
      ) : (
        <>
          <Modal
            size="md"
            show={true}
            onHide={handleClose}
            centered
            animation={false}
            scrollable
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Documentos da Proposta</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="col-12">
              {(docsChecados || props?.detalhes?.documentos_checados == 'SIM') &&
                (<div style={{ textAlign: "center", color: "green", marginBottom: "5px", fontWeight: "bold" }}>!!  Documentos Aprovados !!</div>)}
              {((props?.detalhes?.posicao.toLowerCase() != 'pago' || ['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
                <Render />) ||
                <div style={{ textAlign: "center", flexDirection: "center" }}>Documentos não disponíveis após pagamento.</div>}
            </Modal.Body>
            <Modal.Footer>
              {props.user?.tipo != "AGENTE" && (
                <>
                  {load &&
                    !docsChecados &&
                    (!props?.detalhes?.documentos_checados || props?.detalhes?.documentos_checados == 'NAO') &&
                    (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
                    (<Button
                      color="primary"
                      onClick={() => aprovarDocumentos()}
                      title="Aprova análise dos documentos da proposta"
                    >
                      Aprovar
                    </Button>)
                  }
                  {load &&
                    !selfie &&
                    props.detalhes.assinada &&
                    (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
                    (<Button
                      color="primary"
                      onClick={() => obterSelfie()}
                      title="Baixa a Selfie do cliente"
                    >
                      Selfie
                    </Button>)
                  }
                  {load &&
                    !dossie &&
                    (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
                    (<Button
                      color="info"
                      onClick={() => gerarDossie()}
                      title="Gera e grava o dossiê da proposta"
                    >
                      Dossiê
                    </Button>)
                  }
                  {load && documentos && documentos.length > 0 && (
                    <Button color="success" onClick={uploadNuvem}>
                      {" "}
                      Nuvem{" "}
                    </Button>
                  )}
                  {load &&
                    !existeCcb &&
                    (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
                    (<Button
                      color="success"
                      disabled={existeCcb}
                      onClick={() => gerarCcb()}
                    >
                      {" "}
                      Gerar Ccb{" "}
                    </Button>)
                  }

                  <Button color="success" onClick={() => setUpload(true)}>
                    Upload
                  </Button>

                </>
              )}
              <Button color="secondary" onClick={handleClose}>
                {" "}
                Fechar{" "}
              </Button>
            </Modal.Footer>
          </Modal>

          {deleteDoc && <WaitDelete />}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);
