import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal from "../../swal";
import { sendMultiPartData, sendData, getData } from "../../../helpers/auth";

import { uriList } from "../../../helpers/config";
import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";
import {
  formatFloat,
  formatCurrency,
  mDate,
  width,
} from "../../../helpers/general";
import * as moment from "moment";

let warning = {
  email: 0,
};

let apis_assinatura = [
  {
    id: "D4",
    nome: "D4 Sign",
  },
  {
    id: "Confia",
    nome: "Confia",
  },
];

const Form = ({ ...props }) => {
  const [data, setData] = useState({});
  const [tabelas_cPessoal, setTabelas_cPessoal] = useState([]);
  const [tabelasRetencao, setTabelasRetencao] = useState([]);

  const [gravando, setGravando] = useState(false);

  const handlePost = async () => {
    setGravando(true);
    let content = {
      id: data.id,
      tabela_cpessoal_id: data.tabela_cpessoal_id,
      tabela_retencao_id: data.tabela_retencao_id,
      email_notificacoes: data.email_notificacoes,
      api_assinatura: data.api_assinatura,
    };

    console.log("handlePost: ", data);
    await sendData({ uri: uriList("parametros"), content, method: "POST" })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setGravando(false);
      })
      .catch((e) => {
        setGravando(false);
        alert("Erro ao gravar parâmetros.");
      });
  };

  useEffect(() => {
    _getTabelas();
  }, []);

  useEffect(() => {
    _getParametros();
  }, [tabelas_cPessoal]);

  async function _getParametros() {
    await getData({ uri: uriList("parametros") }).then((res) => {
      console.log("configuracoes._getParametros: ", res.data);
      let _data = {
        email_notificacoes: res.data.email_notificacoes,
        tabela_cpessoal_id:
          res.data.tabela_cpessoal_id || tabelas_cPessoal?.[0]?.id,
        id: res.data.id,
        api_assinatura: res.data.api_assinatura,
      };
      setData(_data);

      console.log("_getParametros - parametros", _data);
    });
  }

  async function _getTabelas() {
    await sendData({
      uri: uriList("getTabelasCPessoal"),
      content: {},
      method: "POST",
    }).then((res) => {
      setTabelas_cPessoal(res.data);
    });

    await sendData({
      uri: uriList("getTabelasRetencao"),
      content: { nome: "INSS" },
      method: "POST",
    }).then((res) => {
      setTabelasRetencao(res.data);
    });
  }

  const handleChange = (e, userData) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;

    if (name == "dt_inicial" || name == "dt_final") {
      let value = mDate(e.currentTarget.value);
      setData({ ...data, [name]: value });
    } else setData({ ...data, [name]: value });

    console.log("configuracoes.handleChange: ", { name, value });
  };

  return (
    <>
      {gravando ? (
        <div>Gravando registro...</div>
      ) : (
        <div className="form" style={{ overflow: "hidden" }}>
          <div className="row">
            <div
              className={`form-group ${width() != "mobile" ? "col-6" : "col-12"
                }`}
            >
              <label htmlFor="email_notificacoes" className="no-margin">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  E-mails de Notificação
                </div>
              </label>
              <InputLabelAlert
                type="email_notificacoes"
                id="email_notificacoes"
                minLength={5}
                value={data.email_notificacoes}
                warning={warning.email_notificacoes}
                handle={handleChange}
                lowercase={true}
                focus={false}
              />
            </div>
          </div>

          <div className="row">
            <div
              className={`form-group ${width() != "mobile" ? "col-6" : "col-12"
                }`}
            >
              <label className="m-0 font-90">Tabela Crédito Pessoal</label>
              <select
                className="form-control"
                defaultValue={data.tabela_cpessoal_id}
                onChange={handleChange}
                id="tabela_cpessoal_id"
                name="tabela_cpessoal_id"
              >
                {tabelas_cPessoal &&
                  tabelas_cPessoal.map((item, key) => {
                    return (
                      <option key={key} value={item.id}>
                        {item.nome}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div
              className={`form-group ${width() != "mobile" ? "col-6" : "col-12"
                }`}
            >
              <label className="m-0 font-90">Tabela Retenção INSS</label>
              <select
                className="form-control"
                defaultValue={data.tabela_retencao_id}
                onChange={handleChange}
                id="tabela_retencao_id"
                name="tabela_retencao_id"
              >
                {tabelasRetencao &&
                  tabelasRetencao.map((item, key) => {
                    return (
                      <option key={key} value={item.id}>
                        {item.nome}
                      </option>
                    );
                  })}
              </select>
            </div>

          </div>

          <div className="row">
            <div
              className={`form-group ${width() != "mobile" ? "col-4" : "col-12"
                }`}
            >
              <label className="m-0 font-90">API de Assinatura</label>
              <select
                className="form-control"
                defaultValue={data.api_assinatura}
                onChange={handleChange}
                id="api_assinatura"
                name="api_assinatura"
              >
                {apis_assinatura.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={item.id == data?.api_assinatura}
                    >
                      {item.nome}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="col-6 py-1 px-0">
            <button
              onClick={() => handlePost()}
              className="btn text-light"
              title="Gravar"
              style={{
                backgroundColor: "#00bdff",
                marginRight: "3px",
                display: "flex",
              }}
            >
              <i className="mdi mdi-content-save-all" />
              Gravar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
