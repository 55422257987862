import React, { useState, useEffect } from "react";
import { InputAlert, InputLabelAlert } from "../../components/layout";
import Modal from "../../components/modal";
import Form from "../../components/form/simulator/simulator";
import {
  sendForm,
  setSession,
  sendData,
  getData,
  clearSession,
  gravarIp,
} from "../../helpers/auth";
import { verifyValue } from "../../helpers/validation";
import { uriList, enviroment } from "../../helpers/config";
import {
  saveCookie,
  getCookie,
  width,
  formatCurrency,
  operacaoList,
} from "../../helpers/general";

import { connect } from "react-redux";
import { setProposta, setCliente } from "../../store/actions/dados";
import * as moment from "moment";

function Simulator({ ...props }) {
  const [cpf, setCPF] = useState("");
  const [warning, setWarning] = useState(false);

  const [data, setData] = useState({
    convenio_id: 1,
    tipo: "valor",
    valor: "0",
    parcelas: "",
    prazo: "0",
    operacao: "CONTRATO NOVO",
    rangeValor: 0,
    rangePrazo: 0,
    ip: getCookie({ label: "ip" }),
    proposta_uuid: props.proposta,
  });

  const [showAlert, setAlert] = useState({
    convenio: 0,
    tipo: 0,
    valor: 0,
    prazo: 0,
  });

  const [simulador, setSimulador] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [convenioList, setConvenioList] = useState("");
  const [prazoSteps, setPrazoSteps] = useState(false);
  const [valorLabel, setValorLabel] = useState({
    message: "",
    def: props.alterar
      ? "Valor"
      : props.user
      ? "Valor do Empréstimo"
      : `De quanto você precisa?`,
  });
  // const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const openInfoModal = ({ title, text, list }) => {
    setModal(
      <Modal
        title={title}
        text={text}
        list={list}
        onClick={() => setModal("")}
      />
    );
  };

  const handlechange = (e, innerData, alternative) => {
    let target = e.currentTarget || alternative;

    setData({ ...data, [target.name]: innerData || target.value });
    clearAlert(target);
  };

  const handleFT = (e) => {
    let [prazo, alerta] = verifyValue(e);

    setData({
      ...data,
      prazo: prazoSteps[prazo].prazo,
      rangePrazo: prazo ? prazo : 0,
    });
    setAlert({ ...showAlert, prazo: alerta });

    return false;
  };

  const handleValor = (e) => {
    let name = e.currentTarget.name;

    let val =
      name === "valor"
        ? formatCurrency(e.currentTarget.value)
        : e.currentTarget.value;
    let valParsed =
      name === "valor"
        ? val.replace(".", "").replace(",", ".")
        : parseFloat(val).toFixed(2);

    setData({
      ...data,
      valor: formatCurrency(valParsed),
      rangeValor: valParsed ? valParsed : 0,
    });
    setAlert({
      ...showAlert,
      valor: valParsed > 500000 || valParsed <= 0 ? 1 : 0,
    });
  };

  const clearAlert = (target) => {
    let field = target.name;

    setAlert({ ...showAlert, [field]: 0 });
  };

  const changeValorLabel = (e) => {
    setData({ ...data, tipo: e.currentTarget.value });
    e.currentTarget.value === "parcela"
      ? setValorLabel({
          ...valorLabel,
          message: props.user
            ? "Valor das Parcelas"
            : "Quanto você quer pagar?",
        })
      : setValorLabel({ ...valorLabel, message: valorLabel.def });
  };

  const handleConvenio = (e) => {
    setData({ ...data, convenio_id: e.currentTarget.value });
  };

  const toogleClient = (e, nova) => {
    // e.preventDefault();

    if (nova) {
      setSimulador(true);
    } else {
      let cliente = getCookie({ label: "cliente_id" });
      if (cliente) props.alterarCliente(cliente);

      if (
        e.currentTarget.dataset.posicao &&
        e.currentTarget.dataset.posicao.toLowerCase() !== "simulacao"
      ) {
        props.alterarProposta(isClient[e.currentTarget.id]);
        setShowStatus(true);
      } else {
        props.alterarProposta(isClient[e.currentTarget.id]);

        setIsClient(false);
        props.jump(2);
      }
    }
  };

  const submit = (e) => {
    console.log("Simulando(CPF)... ");
    let [res, obj] = sendForm(e, data);
    let newData = JSON.parse(JSON.stringify(data));

    if (!res) {
      setAlert({ ...obj });

      return false;
    }

    newData.valor = newData.valor.replace(".", "").replace(",", ".");
    newData.valor = parseFloat(newData.valor);

    newData.parcelas = newData.valor / newData.prazo;

    let submitData = JSON.parse(JSON.stringify(newData));

    delete submitData.parcelas;
    delete submitData.rangePrazo;
    delete submitData.rangeValor;
    submitData["ip"] = getCookie({ label: "ip" });
    submitData["operador_id"] = props.user?.pessoa_id;
    if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0)
      submitData["agente_id"] = props.user?.pessoa_id;

    sendData({
      uri: uriList("simulador"),
      content: submitData,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        res.tipo = submitData.tipo;
        if (res.status && res.status > 200) {
          console.log("Erro ao simular: ", res);
          window.alert("Ocorreu um erro ao Simular!");
          return false;
        }

        if (props.user) {
          setSession({ obj: res, key: "status" });
          setSession({ obj: submitData, key: "resultado" });

          props.jump(1);
        } else {
          setSession({ obj: res, key: "status" });
          setSession({ obj: submitData, key: "resultado" });

          props.toggleStep(1);
        }
      })
      .catch((e) => {
        if (enviroment === "dev") {
          setSession({ obj: newData, key: "status" });
          props.toggleStep(1);
        }
      });
  };

  useEffect(() => {
    if (props.proposta) {
      setShowStatus(true);
    } else {
      let ip = getCookie({ label: "ip" });
      if (!props.user) clearSession();

      getData({ uri: uriList("convenios") })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          if (!Array.isArray(res)) throw new Error();

          setConvenioList((convenio) => res);
        })
        .catch((e) => setConvenioList([]));

      gravarIp();
      // if (!ip || ip === "10") {
      //     fetch("https://api.ipify.org?format=json")
      //         .then((res) => res.json())
      //         .then((data) => {
      //             if (!data.ip) throw new Error();
      //             else {
      //                 saveCookie({ label: 'ip', content: data.ip });
      //                 setData((previous) => ({ ...previous, ip: data.ip }));
      //             }
      //         })
      //         .catch((e) => {
      //             saveCookie({ label: 'ip', content: '10' });
      //             setData((previous) => ({ ...previous, ip: 10 }))
      //             // dispatch({ type: 'SET_IP', ip: '10' })
      //         });
      // }

      setData((previous) => ({ ...previous, ip: getCookie({ label: "ip" }) }));
    }
  }, []);

  useEffect(() => {
    getData({ uri: uriList("prazos") + "/" + data.convenio_id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        setPrazoSteps(res);
        setData((previous) => ({
          ...previous,
          prazo: res[0].prazo,
        }));
      })
      .catch((e) => {
        return false;
      });
  }, [data.convenio_id]);

  useEffect(() => {
    if (data.ip) {
      sendData({
        uri: uriList("buscar"),
        content: { ip: data.ip },
        method: "POST",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();

          setLoaded(true);
          setIsClient(res);
        })
        .catch((e) => {
          console.log("Simulator-useEffect(buscar) - 3");
          setLoaded(true);
          console.log("Erro: ", e);
        });
    }
  }, [data.ip]);

  function RenderSimulator() {
    return (
      <>
        <Form
          data={data}
          warning={showAlert}
          modal={openInfoModal}
          changeLabel={changeValorLabel}
          clearWarning={clearAlert}
          change={handlechange}
          valor={handleValor}
          ft={handleFT}
          label={valorLabel}
          operacaoList={operacaoList}
          convenio={handleConvenio}
          convenioList={convenioList}
          prazoSteps={prazoSteps}
        />

        <div style={{ padding: "0 20px" }} className="row buttons">
          <button
            style={{
              fontSize: "1.1em",
              margin: "15px 0",
              backgroundColor: "#3E6C84",
            }}
            className="btn btn-info text-light font-weight-bold col-12"
            onClick={(e) => submit(e, data)}
          >
            Simular
          </button>
        </div>
      </>
    );
  }

  function RenderSelect() {
    return (
      <>
        {loaded ? (
          isClient ? (
            <div
              style={{
                marginTop: "2%",
                padding: "1.2em 0",
                // maxHeight: document.body.clientHeight * 0.80 + 'px',
                maxHeight: document.body.clientHeight * 0.65 + "px",
                overflowY: width() === "mobile" ? "auto" : "hidden",
              }}
              onMouseOver={(e) => (e.currentTarget.style.overflowY = "auto")}
              onMouseOut={(e) => (e.currentTarget.style.overflowY = "hidden")}
            >
              <button
                onClick={(e) => toogleClient(e, true)}
                className="btn btn-lg d-block mx-auto btn-outline-info col-md-6 col-10 mb-3 font-weight-bold"
                style={{ borderWidth: "3px" }}
              >
                <p className="m-0">
                  Nova <br /> Proposta
                </p>
              </button>

              {isClient.map((client, i) => (
                <div>
                  <div style={{ display: "flex", flexDirection: "line" }}>
                    <div
                      onClick={toogleClient}
                      key={client.uuid}
                      data-posicao={client.posicao}
                      id={i}
                      className="btn btn-lg d-block mx-auto btn-outline-secondary col-md-6 col-10 mb-3 font-weight-bold"
                      style={{
                        borderRadius: "15px",
                        border: "3px solid #797776",
                        borderColor:
                          client.posicao.toLowerCase() !== "simulacao"
                            ? "#077097"
                            : "#05535D",
                      }}
                    >
                      {client.cliente_id &&
                        saveCookie({
                          label: "cliente_id",
                          content: client.cliente_id,
                        })}
                      <p className="m-0">
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {client.posicao.toLowerCase() !== "simulacao"
                            ? "Acompanhar"
                            : "Continuar"}
                        </div>

                        <span
                          className="font-weight-normal"
                          style={{ fontSize: ".76em" }}
                        >
                          {/* {props.user && <div><div>{client.cliente}</div> <div>{client.cpf + " - " + client.convenio}</div></div>} */}
                          R${" "}
                          {formatCurrency(
                            parseFloat(client.valor_liquido).toFixed(2)
                          )}{" "}
                          - {client.prazo}x -{" "}
                          {client.status_data &&
                            moment(client.status_data).format(
                              "DD/MM/YYYY"
                            )}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            RenderSimulator()
          )
        ) : (
          <div className="text-center text-info my-3">
            <span className="d-block lead">Carregando Dados da Proposta!</span>
          </div>
        )}
      </>
    );
  }

  function handle(e) {}

  function onBlur(e) {}

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        Simulador
      </h4>

      <div className="form">
        <div className="row">
          <div
            className="form-group col-md-6"
            style={{ margin: "auto", marginTop: "20%", marginBottom: "20%" }}
          >
            <InputLabelAlert
              type="tel"
              label="Informe o seu CPF"
              id="cpf"
              value={cpf}
              handle={handle}
              onBlur={onBlur}
              autoComplete="off"
              warning={warning}
              length="14"
              focus={true}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </div>
      </div>
      {/* {modal}
            {props.user || simulador ? RenderSimulator() : RenderSelect()}

            {showStatus && <Redirect to="./status" />} */}
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Simulator);
