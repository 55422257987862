import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { setNotificacoes } from "../../../store/actions/dados";
import swal from "sweetalert";

function NotificationMenu({ ...props }) {
  const [menu, setMenu] = useState(false);
  const [notificacoes, setNotificacoes] = useState(false);

  useEffect(() => {
    setNotificacoes(props.notificacoes);
    if (props.notificacoes) AutoCloseAlert(props.notificacoes);
  }, [props.notificacoes]);

  function AutoCloseAlert(not) {
    if (window.Notification && Notification.permission !== "denied") {
      Notification.requestPermission((status) => {
        let n = new Notification("Nova Notificação", {
          body: "Nova notificação no sistema InConta!",
        });
      });
    }

    // swal({
    //     title: "Nova Notificação",
    //     text: "Nova notificação no sistema InConta!",
    //     timer: 7000
    // });
  }

  function handleNotificacao(notificacao) {
    if (notificacao.toUpperCase().indexOf("PROPOSTA") >= 0)
      if (props.buscarPropostas) props.buscarPropostas();
    props.setNotificacoes("limpar");
  }

  function Notificacoes() {
    if (!notificacoes)
      return <p style={{ textAlign: "center" }}>Sem notificações!</p>;
    else {
      console.log("Notificacoes: \n", notificacoes);
      let _not = [];
      for (let not in notificacoes) {
        _not.push(notificacoes[not].toUpperCase());
      }

      return _not.map((row, i) => (
        <DropdownItem
          key={`item: ${i}`}
          tag="a"
          htef="#"
          className="notify-item"
          onClick={() => {
            handleNotificacao(row);
          }}
        >
          {/* <div className="notify-icon">
                        <i className="mdi mdi-emoticon-happy text-warning"></i>
                    </div> */}
          <p style={{ textAlign: "center" }}>
            Notificação: <b>{row}</b>
          </p>
        </DropdownItem>
      ));
    }
  }

  function toggle() {
    setMenu(!menu);
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="list-inline-item dropdown notification-list mr-2 ml-1"
        tag="li"
      >
        <DropdownToggle
          className="nav-link arrow-none waves-effect"
          tag="a"
          href="#"
        >
          {!notificacoes ? (
            <i className="mdi mdi-bell noti-icon"></i>
          ) : (
            <i className="mdi mdi-bell-ring noti-icon"></i>
          )}
          {notificacoes ? (
            <span className="badge badge-danger noti-icon-badge"></span>
          ) : (
            ""
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg  dropdown-arrow" right>
          <div className="dropdown-item noti-title">
            <h5>Notificações</h5>{" "}
          </div>
          <hr />
          <Notificacoes />
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    notificacoes: state.dados.notificacoes,
    buscarPropostas: state.dados.buscarPropostas,
  };
}

function mapActionToProps(dispatch) {
  return {
    setNotificacoes(notificacao) {
      dispatch(setNotificacoes(notificacao));
    },
  };
}

// export default App;
export default connect(mapStateToProps, mapActionToProps)(NotificationMenu);
