import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
import { sendData, getData } from "../../../../helpers/auth";
import Swal from "../../../swal";
import {
    getCookie,
    Aguarde,
    DefaultOrder,
    sortByString,
    sortByNumber,
    compareDate,
} from "../../../../helpers/general";

import { connect } from "react-redux";
import { Pagination, FilterSelect, } from "../../../layout";

const swalOptions = {
    title: "Deseja excluir o registro?",
    icon: "warning",
    buttons: {
        cancel: {
            text: "Não",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
        },
        confirm: {
            text: "Sim",
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true,
        },
    },
};


const IncluirCheckpoint = ({ ...props }) => {
    const [warning, setWarning] = useState({ descricao: 0, observacao: 0 })
    const [show, setShow] = useState(false);
    const [gravando, setGravando] = useState(false);
    const [checkPoints, setCheckPoints] = useState([]);
    const [checkPointsC, setCheckPointsC] = useState([]);
    const [ascDesc, setAscDesc] = useState(true);
    const [clickedTr, setClickedTr] = useState("");
    const [filterOptions, setFilterOptions] = useState(false);
    const [actualPage, setActualPage] = useState(0);
    const [data, setData] = useState({
        data: new Date().toISOString().substr(0, 10),
        descricao: "",
        status: "Pendente",
        ip: getCookie({ label: "ip" }),
    });
    const [showAlert, setAlert] = useState({
        file: 0,
    });

    useEffect(() => {
        getCheckPoints();
    }, []);

    function handleClose(e, close) {
        if (e) e.preventDefault();
        setShow(false);
        if (props.onCloseEv) props.onCloseEv(close);
    }

    const handlePage = (e) => {
        setActualPage(e.currentTarget.value);
    };

    function validarDados() {
        let _warning = { descricao: 0, observacao: 0 }

        if (!data.descricao)
            _warning = { descricao: 1 }

        // if (!data.observacao)
        //     _warning = { ..._warning, observacao: 1 }

        console.log("IncluirCheckpoint.validarDados():", _warning)
        setWarning(_warning)

        return !_warning.descricao && !_warning.observacao;
    }

    function handleChange(e) {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    async function handlePost(e) {
        e.preventDefault();
        console.log("Auxiliar.handlePost(1):", data);

        if (validarDados()) {
            setGravando(true);
            console.log("Auxiliar.handlePost(2)");
            // let uri = 'Checkpointr'
            // if (props.tela === 'bloquear')
            //   uri = 'bloquear'
            // else if (props.tela === 'cancelar')
            //   uri = 'cancelar'

            // console.log(data)
            // console.log(data)
            sendData({
                uri: uriList('checkpoint'),
                content: data,
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    setGravando(false);
                    console.log("Auxiliar.handlePost(3)");
                    handleClose();
                    getCheckPoints();
                })
                .catch((e) => {
                    setGravando(false);
                    console.log("Auxiliar.handlePost(4)");
                    // console.log("Deu errado")
                    console.log(e);
                    return false;
                });
        }
    }

    const getCheckPoints = async () => {
        await getData({ uri: uriList("checkpoint") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                DefaultOrder(
                    "checkpoint",
                    res.map((checkpoints) => checkpoints),
                    setCheckPoints
                );
                createFilter(res.map((checkpoints) => checkpoints));
                setCheckPointsC(res);
            })
            .catch((e) => {
                return false;
            });

    };

    const createFilter = (obj) => {
        let newObj = {
            descricao: [],
            tipo: [],
            funcao: [],
            impeditivo: [],
        };

        for (let o of obj) {
            for (let i in o) {
                if (newObj[i]) {
                    newObj[i].push(o[i]);
                }
            }
        }

        for (let i in newObj) {
            newObj[i] = [...new Set(newObj[i])];
        }

        setFilterOptions(newObj);
    };

    const sortArrObj = (key, type) => {
        let arr = [...checkPoints];
        let asc = !ascDesc;

        arr = arr.sort((a, b) => {
            if (type === "string") {
                if (asc) {
                    setAscDesc(asc);
                    return sortByString(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByString(b, a, key, type);
                }
            } else if (type === "numeric") {
                if (asc) {
                    setAscDesc(asc);
                    return sortByNumber(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByNumber(b, a, key, type);
                }
            } else {
                if (asc) {
                    setAscDesc(asc);
                    return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
                } else {
                    setAscDesc(asc);
                    return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
                }
            }
        });

        setCheckPoints(arr);
    };

    const handleNew = () => {
        setData({})

        setShow(true);
    };

    const handleEdit = async (id) => {
        console.log("checkpoint.handleEdit(1):", id)
        getData({ uri: uriList("checkpoint") + "/" + id })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();

                console.log("checkpoint.handleEdit(ok):", res)
                setData(res)
                setShow(true);
            })
            .catch((e) => {
                return false;
            });
    };

    const handleOption = (e) => {
        let label = e.currentTarget.dataset.label || e.currentTarget.name;
        let value = e.currentTarget.value;
        let newDetailed;

        if (value !== "todos" || value !== "") {
            newDetailed = checkPointsC.filter((proposta) => {
                return proposta[label] === value;
            });

            setCheckPoints(newDetailed);
            setActualPage(0);
        } else {
            setCheckPoints(checkPointsC);
            setActualPage(0);
        }
    };

    const handleDelete = async (isConfirm, swal, id) => {
        if (isConfirm) {
            await sendData({
                uri: uriList("checkpoint") + "/" + id,
                content: {},
                method: "DELETE",
            })
                .then((res) => {
                    if (res.status && parseInt(res.status) > 200) throw new Error();
                    getCheckPoints();
                })
                .catch((e) => {
                    alert("Erro ao excluir Checkpoint.");
                });
        }
    };


    return (
        <>
            {gravando && <Aguarde legenda={"Aguarde, gravando dados..."} />}
            <Modal
                size="md"
                show={show}
                centered
                onHide={(e) => {
                    handleClose(e, true);
                }}
                animation={false}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {/* <span>{props.tela === 'Checkpointr' ? "Checkpointr Proposta" : props.tela === "bloquear" ? "Bloquear Proposta" : "Cancelar Proposta"}</span> */}
                        <span>Cadastro de Checkpoints</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="maxWidth650">
                    <div className="col-md-12">
                        <label className="m-0 font-90">Descrição</label>
                        <input
                            type="text"
                            name="descricao"
                            className="form-control"
                            autoFocus={true}
                            value={data.descricao}
                            onChange={handleChange}
                        />
                    </div>


                    <div className="d-flex">
                        <div className="col-md-6">
                            <label className="m-0 font-90">Tipo</label>
                            <select
                                className="form-control"
                                name="tipo"
                                defaultValue={data.tipo}
                                style={{ borderColor: warning?.tipo ? "red" : "", }}
                                onChange={handleChange}

                                required
                            >
                                <option value="">Escolha uma opção...</option>
                                <option key={'1'} value={'checkbox'} selected={data.tipo == "checkbox"}>{'Checkbox'}</option>
                                <option key={'2'} value={'texto'} selected={data.tipo == "text"}>{'Texto'}</option>
                                <option key={'3'} value={'documento'} selected={data.tipo == "documento"}>{'Documento'}</option>
                                <option key={'4'} value={'funcao_interna'} selected={data.tipo == "funcao_interna"}>{'Função Interna'}</option>
                                <option key={'5'} value={'funcao_externa'} selected={data.tipo == "funcao_externa"}>{'Função Externa'}</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label className="m-0 font-90">Impeditivo</label>
                            <select
                                className="form-control"
                                name="impeditivo"
                                defaultValue={data.impeditivo}
                                onChange={handleChange}

                                required
                            >
                                <option value="">Escolha uma opção...</option>
                                <option key={'1'} value={'sim'} selected={data.impeditivo == "sim"}>{'Sim'}</option>
                                <option key={'2'} value={'nao'} selected={data.impeditivo == "nao"}>{'Não'}</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label className="m-0 font-90">Função</label>
                        <input
                            type="text"
                            className="form-control"
                            name="funcao"
                            value={data.funcao}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-12">
                        <label className="m-0 font-90">Mensagem</label>
                        <textarea
                            type="textArea"
                            id="mensagem"
                            name="mensagem"
                            rows="3"
                            required={true}
                            className="form-control"
                            value={data.mensagem}
                            onChange={handleChange}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button color="success" onClick={handlePost}>
                        Gravar
                    </Button>
                    <Button
                        color="secondary"
                        onClick={(e) => {
                            handleClose(e, true);
                        }}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-4 py-1 px-0">
                <button
                    onClick={() => handleNew()}
                    className="btn btn-sm text-light"
                    title="Novo Checkpoint"
                    style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
                >
                    <i className="mdi mdi-plus-box" />
                </button>
                <button
                    onClick={() => window.print()}
                    className="btn btn-sm text-light"
                    title="Imprimir"
                    style={{ backgroundColor: "#00bdff" }}
                >
                    <i className="mdi mdi-printer" />
                </button>
            </div>

            <div className="row ">
                <div className={`col-md-2`}>
                    <label className="m-0 font-90">CheckPoint</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="descricao"
                    />
                </div>

                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Tipo</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="tipo"
                    />
                </div>

                <div className={`col-md-2`}>
                    <label className="m-0 font-90">Impeditivo</label>
                    <FilterSelect
                        detailed={filterOptions}
                        handleOption={handleOption}
                        oLabel="impeditivo"
                    />
                </div>
            </div>

            <table className="table table-bordered table-striped mt-2">
                <thead>
                    <tr
                        title="Clique para ordenar"
                        style={{ backgroundColor: "#f2f2f2" }}
                    >
                        <th scope="col" className="font-80">
                            #
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("descricao", "string")}
                        >
                            Descrição
                        </th>

                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("tipo", "string")}
                        >
                            Tipo
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("impeditivo", "string")}
                        >
                            Impeditivo
                        </th>
                        <th
                            scope="col"
                            className="font-80"
                            onClick={() => sortArrObj("funcao", "string")}
                        >
                            Função
                        </th>
                        <th>Opções</th>
                    </tr>
                </thead>
                <tbody>
                    {checkPoints.length > 0 ? (
                        checkPoints.map(
                            (checkpoint, i) =>
                                i >= actualPage * 10 &&
                                i < (actualPage + 1) * 10 && (
                                    <tr
                                        key={i}
                                        className={`${clickedTr === checkpoint.id ? "bg-secondary text-light" : ""
                                            }`}
                                        onClick={() => {
                                            setClickedTr(checkpoint.id);
                                        }}
                                        onDoubleClick={() => {
                                            handleEdit(checkpoint.id);
                                        }}
                                        onContextMenu={(e) => {
                                            e.preventDefault();
                                            setClickedTr(checkpoint.id);
                                        }}
                                    >
                                        <td>{i + 1}</td>
                                        <td>{checkpoint.descricao}</td>
                                        <td>{checkpoint.tipo}</td>
                                        <td>{checkpoint.impeditivo}</td>
                                        <td>{checkpoint.funcao}</td>
                                        <td className="text-center">
                                            <button
                                                className="btn btn-sm btn-warning"
                                                title="Altera um registro"
                                                onClick={(e) => handleEdit(checkpoint.id)}
                                            >
                                                <i className="mdi mdi-file-edit-outline"></i>
                                            </button>
                                            <Swal
                                                options={swalOptions}
                                                id={checkpoint.id}
                                                title="Exclusão de Registro"
                                                callback={handleDelete}
                                                className="btn btn-sm btn-danger"
                                                style={{ marginLeft: "2px", cursor: "pointer" }}
                                            >
                                                <i className="mdi mdi-delete"></i>
                                            </Swal>
                                        </td>
                                    </tr>
                                )
                        )
                    ) : (
                        <tr>
                            <td>Sem dados</td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2" className="text-left">
                            Total de CheckPoints: {checkPoints?.length}
                        </td>

                        <td colSpan="7"></td>
                    </tr>
                </tfoot>
            </table>
            <Pagination
                detailed={checkPoints}
                actual={actualPage}
                changePage={handlePage}
            />


        </>
    );
};

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        user: state.dados.user,
    };
}

export default connect(mapStateToProps)(IncluirCheckpoint);
