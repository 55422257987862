import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { convertNumberWithMagnitude } from './convertNumbers'

import style from './dashboard.module.css'


const xAxisRightTick = (props) => {
    const { x, y, stroke, payload } = props
    const value = payload.value
    let WidthLine = 58

    if (payload.index === 11)
        WidthLine = 5
    // if (payload.value === 'Dez') {
    // }
    return (
        <g transform={`translate(${x},${y - 12})`}>
            <text
                x={-17}
                y={0}
                dy={16}
                textAnchor="center"
                fill="#fff"
                fontSize={10}
                fontWeight={700}
                alignmentBaseline="auto"
            >
                {value}
            </text>
            {value !== 'Dez' && <path d={`M-25,23 L${WidthLine},23`} stroke="#fff" strokeWidth="2" />}
        </g>
    )
}

const RenderQuarterTick = (tickProps) => {
    const { x, y, payload, yearAndMidMonth } = tickProps
    const { value, offset } = payload

    const date = new Date(value)
    const month = date.getMonth()
    const year = date.getFullYear()

    if (yearAndMidMonth[0].midMonth === month) {
        return (
            <g>
                <text fill="#fff" x={x + 15} y={y + 5} textAnchor="middle" fontSize={20}>
                    {year}
                </text>
            </g>
        )
    }

    if (yearAndMidMonth.length > 1) {
        if (yearAndMidMonth[1].midMonth === month) {
            return (
                //M300 ponto inicial
                <g>
                    <text fill="#fff" x={x - 20} y={y + 5} textAnchor="middle" fontSize={20}>
                        {year}
                    </text>
                </g>
            )
        }
    }

    return null
}

let BarLabel = (labelProps) => {
    const { x, y, value } = labelProps

    return (
        <g transform={`translate(${x + 10},${y - 12})`} className={style.barLabel}>
            <rect x={-10} y={-10} width="20" height="14" fill="#D9D9D9"></rect>
            <text textAnchor="middle" fontSize={6} fontWeight={700}>
                {convertNumberWithMagnitude(value)}
            </text>
        </g>
    )
}

export default function DailyProductionGraph({ title, data }) {
    let yearAndMidMonth = []

    // Encontra todos os anos únicos na matriz de dados
    const anosUnicos = [...new Set(data.map((item) => item.date.split('-')[0]))]

    // Função para calcular a data do meio de um ano
    function calcularDataMeio(ano) {
        const datasDoAno = data.filter((item) => item.date.startsWith(ano))
        const dataInicio = new Date(Math.min(...datasDoAno.map((item) => new Date(item.date))))
        const dataFim = new Date(Math.max(...datasDoAno.map((item) => new Date(item.date))))
        return new Date((dataInicio.getTime() + dataFim.getTime()) / 2)
    }

    // Encontra o mês correspondente a cada data do meio para cada ano
    anosUnicos.forEach((ano) => {
        const dataMeio = calcularDataMeio(ano)
        const mesMeio = dataMeio.getMonth()
        let obj = { year: Number(ano), midMonth: Number(mesMeio) }

        yearAndMidMonth.push(obj)
        // return { midMonth: mesMeio }
    })

    return (
        <div className={style.dailyProductionContainer}>
            {/* <p className="text-white text-center font-weight-600  text-capitalize">{title}</p> */}
            <p style={{ fontSize: "18px", color: "white", textAlign: "center", fontWeight: "600", textTransform: "capitalize" }}>{title}</p>
            <div className={style.graphLegend}>
                <span className={style.legendText}>
                    <span></span>Total Líquido
                </span>
                <span className={style.legendText}>
                    <span></span>Total Burto
                </span>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={150}
                    height={220}
                    data={data}
                    margin={{ left: 10, right: 20, top: 0, bottom: 40 }}
                    barSize={28}
                >
                    <CartesianGrid vertical={false} stroke="#3E6C84" padding={{ right: "200px" }} />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) => {
                            return convertNumberWithMagnitude(value)
                        }}
                        fontSize={10}
                        stroke="#fff"
                        fontWeight={700}
                        padding={{ top: 30 }}
                    />
                    <XAxis
                        dataKey="month"
                        axisLine={false}
                        tickLine={false}
                        stroke="#fff"
                        fontSize={7}
                        tick={xAxisRightTick}
                    />
                    <XAxis
                        dataKey="date"
                        axisLine={false}
                        tickLine={true}
                        interval={0}
                        tick={<RenderQuarterTick yearAndMidMonth={yearAndMidMonth} />}
                        // height={4}
                        scale="band"
                        xAxisId="quarter"
                    />
                    <Bar
                        dataKey="net"
                        fill="#D9D9D9"
                        radius={[10, 10, 0, 0]}
                    //  label={<BarLabel />}
                    // barSize={12}
                    />
                    <Bar
                        dataKey="gross"
                        fill="#3E6C84"
                        radius={[10, 10, 0, 0]}
                    //      label={<BarLabel />}
                    // barSize={12}
                    />

                    <Tooltip cursor={{ fill: 'transparent' }} />

                    {/* <svg>
                        <circle cx={100} cy={5} r={5} fill="#D9D9D9" />
                        <text x="110" y="10" fontSize="12" fontWeight={700} fill="#fff">
                            <rect></rect>
                            Total Líquido
                        </text>
                    </svg>
                    <svg>
                        <circle cx={100} cy={20} r={5} fill="#3E6C84" />
                        <text x="110" y="25" fontSize="12" fontWeight={700} fill="#fff">
                            Total Bruto
                        </text>
                    </svg> */}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
