import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "../../../layout";
import {
  mCPF,
  width,
  getCookie,
  formatCurrency,
  formatFloat,
} from "../../../../helpers/general";
import { validateCPF, validatePrazo } from "../../../../helpers/validation";
import { sendData, getData } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import { setProposta, setClienteDados } from "../../../../store/actions/dados";
import { swalError } from "../../../swal";

function CalculadoraMargem({ ...props }) {
  const [tabelaList, setTabelaList] = useState([]);
  const [convenioList, setConvenioList] = useState([]);
  const [prazoSteps, setPrazoSteps] = useState([]);
  const [tabela_id, setTabela_id] = useState(0);
  const [convenio_id, setConvenio_id] = useState(0);
  const [prazo, setPrazo] = useState(0);
  const [valor, setValor] = useState(0);
  const [consultando, setConsultando] = useState(0);
  const [salario, setSalario] = useState(0);
  const [descontos_compulsorios, setDescontosCompulsorios] = useState(0);
  const [descontos_facultativos, setDescontosFacultativos] = useState(0);
  const [margem_livre, setMargemLivre] = useState(0);
  const [warning, setWarning] = useState({ valor: 0 });

  useEffect(() => {
    console.log("CalculadoraMargem: ", props.detalhes);

    getData({ uri: uriList("convenios") })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        if (!Array.isArray(res)) throw new Error();

        setConvenioList((convenio) => res);
        if (res && res[0]) handleConvenio(res[0].id);
      })
      .catch((e) => setConvenioList([]));
  }, []);

  async function _getPrazos(tabela_id) {
    setPrazoSteps([]);
    if (tabela_id)
      getData({ uri: uriList("prazos") + "/" + tabela_id })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();

          setPrazoSteps(res);
          setPrazo(res[0].prazo);
        })
        .catch((e) => {
          return false;
        });
  }

  async function _getTabelas(convenio_id) {
    setTabelaList([]);
    handleTabela(0);
    if (convenio_id)
      await sendData({
        uri: uriList("listarTabelas"),
        content: { convenio_id, operacao: "CONTRATO NOVO" },
        method: "POST",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setTabelaList(res);
          handleTabela(res[0].id);
        })
        .catch((e) => {
          // alert('Erro ao obter Tabelas.\n' + e.message)
        });
  }

  async function handleConvenio(convenio_id) {
    setConvenio_id(convenio_id);
    _getTabelas(convenio_id);
  }

  const handleTabela = (tabela_id) => {
    setTabela_id(tabela_id);
    _getPrazos(tabela_id);
  };

  async function submit() {
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];
    let content = {
      prazo,
      tabela_id,
      convenio_id,
      valor: formatFloat(valor),
      tipo: "parcela",
      operacao: "CONTRATO NOVO",
      ip: getCookie({ label: "ip" }),
      latitude,
      longitude,
      proposta_uuid: "",
      agente_id:
        ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0
          ? props.user?.pessoa_id
          : null,
      operador_id: props.user?.pessoa_id,
    };
    setConsultando(true);
    console.log("CalculadoraMargem: ", content);
    sendData({
      uri: uriList("simulador"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setConsultando(false);
        if (res.status && res.status > 200) {
          let erro = JSON.parse(res.erro || res.message);
          let msgErro = String(erro.msg || erro);
          console.log("Erro ao efetuar consulta: ", msgErro);

          swalError({ title: msgErro });
          // throw new Error();
        } else if (props) {
          if (props.alterarProposta) props.alterarProposta(res);

          if (props.toggleStep) props.toggleStep(1, content, res);
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: e.message });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  }

  //-- Validações
  useEffect(() => {
    if (
      parseFloat(formatFloat(formatCurrency(valor))) >
      parseFloat(formatFloat(margem_livre))
    )
      setWarning({ valor: 1 });
  }, [valor, margem_livre]);

  function handleValor(e) {
    setWarning({});
    let target = e.currentTarget;
    let _margem = 0,
      _salario = salario,
      _descc = descontos_compulsorios,
      _descf = descontos_facultativos;

    if (target.name == "salario") {
      setSalario(formatCurrency(target.value));
      _salario = formatCurrency(target.value);
    } else if (target.name == "descontos_compulsorios") {
      setDescontosCompulsorios(formatCurrency(target.value));
      _descc = formatCurrency(target.value);
    } else if (target.name == "descontos_facultativos") {
      setDescontosFacultativos(formatCurrency(target.value));
      _descf = formatCurrency(target.value);
    } else if (target.name == "valor") setValor(formatCurrency(target.value));

    //-- Calculando margem
    _margem = (formatFloat(_salario) - formatFloat(_descc)) * 0.3;
    _margem = formatFloat(_margem - formatFloat(_descf));

    // _margem = (formatFloat(_salario) - formatFloat(_descc)) * 0.3
    if (_margem < 0) _margem = 0;
    setMargemLivre(formatCurrency(_margem));

    //-- Atribuindo valor da margem ao valor da parcela
    if (target.name != "valor") setValor(formatCurrency(_margem));
  }

  return (
    <fieldset className="col-12" style={{ height: "100%" }}>
      <h4
        className="display-4 text-center font-weight-bold "
        style={{ marginBottom: "40px", color: "#3E6C84" }}
      >
        Calculadora de Margem
      </h4>

      {!consultando ? (
        <div className="form" style={{ overflow: "hidden" }}>
          <>
            <div className="row d-flex ">
              <div className="form-group col-md-4">
                <InputLabelAlert
                  type="tel"
                  label="Remuneração"
                  id="salario"
                  cifrao={true}
                  value={formatCurrency(salario)}
                  onChange={handleValor}
                  msgErro={"É necessário informar o Salário"}
                  focus={true}
                />
              </div>
              <div className="form-group col-md-4">
                <InputLabelAlert
                  type="tel"
                  label="Desc. Compulsórios"
                  id="descontos_compulsorios"
                  cifrao={true}
                  value={formatCurrency(descontos_compulsorios)}
                  onChange={handleValor}
                  focus={false}
                />
              </div>
              <div className="form-group col-md-4">
                <InputLabelAlert
                  type="tel"
                  label="Desc. Facultativos"
                  id="descontos_facultativos"
                  cifrao={true}
                  value={formatCurrency(descontos_facultativos)}
                  onChange={handleValor}
                  focus={false}
                />
              </div>
            </div>
            <div className="row d-flex ">
              <div className="form-group col-md-4">
                <InputLabelAlert
                  type="tel"
                  label="Margem Livre"
                  id="descontos_compulsorios"
                  disabled={true}
                  cifrao={true}
                  value={formatCurrency(margem_livre)}
                  focus={false}
                />
              </div>
            </div>
            <div className="form-group">
              <div
                style={{
                  pointerEvents: "none",
                  opacity: "0.5",
                  background: "#CCC",
                  textAlign: "center",
                }}
              >
                Dados para Simulação
              </div>
              <hr className="mt-1" />
            </div>

            <div className="row d-flex ">
              <div className="col-md-7">
                <label className="mb-0" htmlFor="convenio_id">
                  Convênio
                </label>
                <select
                  className="form-control "
                  defaultValue={convenio_id}
                  onChange={(e) => handleConvenio(e.currentTarget.value)}
                  name="convenio_id"
                  id="convenio_id"
                  style={{
                    backgroundColor: "#EBEEE9",
                    borderRadius: "4px",
                    border: "1.5px solid #727476",
                    backgroundImage:
                      "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                  }}
                >
                  {convenioList ? (
                    convenioList.map((convenio, index) => (
                      <option
                        key={index}
                        title={convenio.sigla}
                        value={convenio.id}
                      >
                        {convenio.nome.toUpperCase()}
                      </option>
                    ))
                  ) : (
                    <option>Carregando</option>
                  )}
                </select>
              </div>

              <div className="col-md-5">
                <label className="mb-0" htmlFor="tabela_id">
                  Tabela
                </label>
                <select
                  className="form-control "
                  // value={props.data.tabela_id}
                  defaultValue={tabela_id}
                  onChange={(e) => handleTabela(e.currentTarget.value)}
                  name="tabela_id"
                  id="tabela_id"
                  style={{
                    backgroundColor: "#EBEEE9",
                    borderRadius: "4px",
                    border: "1.5px solid #727476",
                    backgroundImage:
                      "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                  }}
                >
                  {tabelaList ? (
                    tabelaList.map((tabela, index) => (
                      <option
                        key={`tabela_id-${tabela.id}`}
                        title={tabela.nome}
                        value={tabela.id}
                      >
                        {tabela.nome}
                      </option>
                    ))
                  ) : (
                    <option>Escolha a Tabela</option>
                  )}
                </select>
              </div>
            </div>

            <div className="row mt-2 col-md-8 flex">
              <div>
                <label htmlFor="valor">Vlr Parcela(R$)</label>
                <input
                  type="tel"
                  value={valor}
                  style={{
                    marginLeft: "0.3em",
                    width: "120px",
                    borderRadius: "4px",
                    border: `${!warning.valor ? "1.5px" : "3px"} solid #727476`,
                    borderColor: warning.valor ? "red" : "",
                  }}
                  onChange={handleValor}
                  name="valor"
                  id="valor"
                />
              </div>
              <div className="ml-3">
                <label htmlFor="prazo">Prazo</label>
                <select
                  className="ml-1"
                  defaultValue={prazo}
                  // className='custom-input'
                  name="prazo"
                  id="prazo"
                  onChange={(e) => setPrazo(e.currentTarget.value)}
                  style={{
                    width: "120px",
                    backgroundColor: "#EBEEE9",
                    borderRadius: "4px",
                    border: "1.5px solid #727476",
                    backgroundImage:
                      "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                  }}
                >
                  {prazoSteps &&
                    prazoSteps.map((prazo, i) => (
                      <option
                        key={`prazo-${i}`}
                        label={prazo.prazo}
                        value={prazo.prazo}
                      >
                        {prazo.prazo}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* <div className='row mt-2 col-md-8 flex'>
                            <InputAlert
                                display={warning.valor}
                                field={'valor'}
                                messages={"Valor da Parcela maior que a Margem Livre!"} />
                        </div> */}

            <div className="row mt-5 d-flex justify-content-center">
              <button
                style={{ backgroundColor: "#3E6C84", width: "30%" }}
                onClick={props.reRender}
                disabled={!valor}
                className="btn btn-md btn-info font-weight-bold mr-2"
              >
                Limpar
              </button>
              <button
                style={{ backgroundColor: "#3E6C84", width: "30%" }}
                onClick={submit}
                disabled={!valor || warning.valor}
                className="btn btn-md btn-info font-weight-bold mr-2"
              >
                Simular
              </button>
            </div>
          </>
        </div>
      ) : (
        <div style={{ textAlign: "center", width: "100%" }}>
          <br />
          <h2 style={{ opacity: "60%" }}>Aguarde, fazendo simulação...</h2>
        </div>
      )}
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(CalculadoraMargem);
