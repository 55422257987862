/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as moment from 'moment'
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { uriList } from "../../../../helpers/config";
import { confirmSwal, swalError } from "../../../swal";
import { sendData } from "../../../../helpers/auth";
import { Aguarde, } from "../../../../helpers/general";

const IncluirRetencao = ({ ...props }) => {
  const [item, setItem] = useState(props.item);
  const [show, setShow] = useState(true);
  const [executando, setExecutando] = useState(false);

  function handleClose(e, select = false) {
    if (e?.preventDefault) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(select);
  }

  async function handleAdiantamento(e) {
    e.preventDefault();
    async function callback(e) {
      if (!e) return;
      setExecutando(true)

      const content = {
        uuid: item?.uuid,
        item
      }

      await sendData({ uri: uriList("adiantamentoRetencao"), content, method: "POST" })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) {
            swalError({ title: `Erro ao gerar estorno da retenção!`, text: res.data || res.message })
          };
          setExecutando(false);
          handleClose(e, true);
        })
        .catch((e) => {
          setExecutando(false);
          console.log(e)
          // alert("Erro ao executar Retenção.");
        });
    }

    confirmSwal({ callback, title: `Confirma geração do Estorno?` })
  }

  async function handleRefinanciamento(e) {
    e.preventDefault();
    async function callback(e) {
      if (!e || !item?.uuid) return;
      setExecutando(true)

      const content = {
        uuid: item?.uuid,
        item
      }

      await sendData({ uri: uriList("refinanciamentoRetencao"), content, method: "POST" })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) {
            swalError({ title: `Erro ao gerar retenção!`, text: res.data || res.message })
          };
          setExecutando(false);
          handleClose(e, true);
        })
        .catch((e) => {
          setExecutando(false);
          console.log(e)
          // alert("Erro ao executar Retenção.");
        });
    }

    confirmSwal({ callback, title: `Confirma geração da Retenção?` })
  }

  async function handleCancelar(e) {
    e.preventDefault();
    async function callback(e) {
      if (!e) return;
      setExecutando(true)

      const content = {
        uuid: item?.uuid,
        item
      }

      await sendData({ uri: uriList("cancelarRetencao"), content, method: "POST" })
        .then((res) => {
          console.log("[CANCELAR_RETENCAO-RES]: ", res)
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setExecutando(false);
          handleClose(e, true);
        })
        .catch((e) => {
          setExecutando(false);
          console.log(e)
          alert("Erro ao cancelar Retenção.");
        });
    }

    confirmSwal({ callback, title: `Confirma o cancelamento da Retenção?` })
  }

  return (
    <>
      <Modal
        size="md"
        show={show}
        centered
        onHide={(e) => { handleClose(e, false); }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Retenção Adiantamento/Refin</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          {executando && <Aguarde legenda={"Aguarde, executando Retenção..."} />}
          <div className="card"
            style={{
              border: `1px solid ${"#FFA500"}`,
              opacity: "100%",
              borderRadius: "5px",
              padding: "5px",
              marginBottom: "15px",
            }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Criação: {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}</div>
              <div>Id: {item?.id}</div>
            </div>
            <div>
              Uuid: {item?.uuid || item?.proposta_uuid}
            </div>
            <div>
              Cliente: {item?.cliente_nome + ' - ' + item?.cliente_cpf}
            </div>
            {(item?.tabela_nome) && <div>
              Tabela: {item?.tabela_nome}
            </div>}
            <div >
              Agente: {item?.agente_nome}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {(item?.tabela_nome || item?.tabela_id) &&
              <Button color="success" onClick={handleRefinanciamento} style={{ width: "150px" }} title="Gera retenção da proposta">
                Reter/Refinanciar
              </Button>}
            {(item?.uuid || item?.proposta_uuid) && ["ADMINISTRADOR", "OPERADOR"].indexOf(props?.user?.tipo) >= 0 &&
              <>
                <Button color="info" onClick={handleAdiantamento} style={{ width: "150px" }} title="Gera Estorno de Comissão para o parceiro">
                  Estornar
                </Button>
                <Button color="danger" onClick={handleCancelar} style={{ width: "150px" }} title="Cancelar Retenção">
                  Cancelar
                </Button>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(IncluirRetencao);
