import { Collapse, CardBody, Card, CardHeader, Button } from 'reactstrap';
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { width, mCPF, Aguarde, formatCurrency } from "../../helpers/general";
import * as moment from "moment";
import { sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import Geolocalizacao from "../../helpers/geolocalizacao";
import Accordion from 'react-bootstrap/Accordion';

function ConfirmAutorizacaoInss(props) {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(props.data);
  const [aguarde, setAguarde] = useState(false);
  const [autorizado, setAutorizado] = useState(false);
  const [naoExiste, setNaoExiste] = useState(false);
  const [getLocation, setGetLocation] = useState(false);

  const [toggleQuestion, setToggequestion] = useState(1);//1 is the default id to be opened by default

  function toggleAccept() {
    if (props.onAccept) props.onAccept();
  }


  const NaoExiste = () => {
    return (
      <Modal
        size={"md"}
        scrollable
        show={true}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Body
          className="maxWidth750"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#CACDCD",
          }}
        >
          <span className="font-weight-bold" style={{ fontSize: "25px" }}>
            Não existe autorização para o CPF:{" "}
            <span style={{ color: "darkRed", textDecoration: "underline" }}>
              {props.data && mCPF(props.data)}
            </span>
          </span>
        </Modal.Body>
      </Modal>
    );
  };

  const Autorizado = () => {
    return (
      <Modal
        size={"md"}
        scrollable
        show={true}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Body
          className="maxWidth750"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            color: "darkGreen",
            backgroundColor: "#CACDCD",
          }}
        >
          <span className="font-weight-bold" style={{ fontSize: "35px" }}>
            Autorização concedida
          </span>
          <br />
          <span style={{ fontSize: "25px" }}>
            Valida até: {moment(data.validade).format("DD/MM/YYYY")}
          </span>
          <br />
          <span
            className="font-weight-bold"
            style={{ fontSize: "18px", color: "black", opacity: "50%" }}
          >
            (Proposta em andamento, aguarde)
          </span>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    setAguarde(true);
    sendData({
      uri: uriList("buscarAutorizacao"),
      content: { cpf: props.data, tipo: "INSS_IN100" },
      method: "POST",
    })
      .then((res) => {
        console.log("Autorizacao inss:", res);

        if (res.status && res.status > 200) {
          setAguarde(false);
          setNaoExiste(true);
        }

        setAguarde(false);
        if (res.data) {
          setData(res.data);
          setAutorizado(res.data.autorizado);
        } else setNaoExiste(true);
      })
      .catch((e) => {
        console.log("autorizar inss(Erro): ", e);
        setAguarde(false);
        setNaoExiste(true);
      });
  }, []);


  function submit() {
    if (checked) {
      setAguarde(true);
      let content = {
        cpf: data.cpf,
        tipo: "INSS_IN100",
      };
      sendData({ uri: uriList("autorizar"), content, method: "POST" })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          console.log("confirmar autorizacao inss:", res);
          toggleAccept();
          setAguarde(false);
          setAutorizado(true);
          // setInterval(() => setAguarde(false), 50000);
        })
        .catch((e) => {
          console.log("autorizar inss(Erro): ", e);
          setAguarde(false);
        });
    }
  }


  const handleToggle = (_tog) => {
    if (toggleQuestion == 1)
      setToggequestion(2)
    else
      setToggequestion(1)
  }

  return (
    <>
      {!getLocation && <Geolocalizacao onCloseEv={() => setGetLocation(true)} />}
      {getLocation &&
        (
          <Modal
            size={width() !== "mobile" ? "lg" : "sm"}
            show={true}
            animation={false}
            backdrop="static"
            centered={true}
          >
            <Modal.Header
              className="formsign-header-custom text-center pt-2 pb-2"
              style={{ fontSize: "25px" }}
            >
              <strong>Autorização INSS/Portabilidade</strong>
            </Modal.Header>
            <Modal.Body
              style={{ padding: "15px", fontFamily: "Arial", fontSize: "12px" }}
            >
              <div style={{ overflowY: 'auto', height: width() !== "mobile" ? "600px" : "" }}>
                {
                  <>
                    <Card className='mb-2'>
                      <CardHeader onClick={handleToggle}>
                        <div className='d-flex justify-content-between'>
                          <h5>Autorização INSS/IN100</h5>
                          <h5><i className={`mdi mdi-arrow-${toggleQuestion == 1 ? 'up' : 'down'}-thick`} /></h5>
                        </div>
                      </CardHeader>
                      <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                        <CardBody>
                          <div className="form" style={{ overflow: "hidden" }}>
                            {!aguarde && (
                              <div style={{ opacity: autorizado || naoExiste ? "0.4" : "" }}>
                                <div
                                  className="form-group row"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="col-sm-12">
                                    <h6
                                      className="text-justify mb-1"
                                      style={{
                                        margin: "0px",
                                        fontSize: width() == "mobile" ? "x-small" : "",
                                        textAlign: "justify",
                                      }}
                                    >
                                      <span>
                                        Eu:{" "}
                                        <span className="font-weight-bold">
                                          {data && data.nome}
                                        </span>
                                      </span>
                                    </h6>
                                    <h6
                                      className="text-justify mb-1"
                                      style={{
                                        margin: "0px",
                                        fontSize: width() == "mobile" ? "x-small" : "",
                                        textAlign: "justify",
                                      }}
                                    >
                                      <span>
                                        CPF:{" "}
                                        <span className="font-weight-bold">
                                          {data && data.cpf && mCPF(data.cpf)}
                                        </span>
                                      </span>
                                    </h6>
                                    <h6
                                      className="text-justify mb-1"
                                      style={{
                                        margin: "0px",
                                        fontSize: width() == "mobile" ? "x-small" : "",
                                        textAlign: "justify",
                                      }}
                                    >
                                      Autorizo o INSS/DATAPREV a disponibilizar as informações
                                      abaixo indicadas para apoiar a contratação/simulação de
                                      empréstimo consignado/cartão consignado de benefícios de
                                      INSS para subsidiar a proposta de uma das seguintes
                                      Instituições Financeiras:
                                    </h6>
                                    <h6
                                      className="text-justify mb-1 mt-2"
                                      style={{
                                        margin: "0px",
                                        fontSize: width() == "mobile" ? "x-small" : "",
                                      }}
                                    >
                                      <strong>
                                        BRB-Crédito, Financiamento e Investimento S.A - CNPJ:
                                        33.136.888/0001-43 / CBC: 925
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                                <div className="form-group row mt-1 mb-0 ">
                                  <div className="col-sm-12">
                                    <table
                                      className="table c-table c-table-bordered"
                                      style={{
                                        marginBottom: "0px",
                                        borderCollapse: "separate",
                                      }}
                                    >
                                      <tr className="tr-custom" key="row1">
                                        <td
                                          className="detailed-td text-center align-middle "
                                          style={{
                                            width: "30%",
                                            backgroundColor: "#F2F3F3",
                                            margin: "2px",
                                          }}
                                        >
                                          <span>Dados de Identificação</span>
                                        </td>
                                        <td
                                          className="detailed-td text-center "
                                          style={{
                                            width: "70%",
                                            backgroundColor: "#F2F3F3",
                                            margin: "2px",
                                            overflowY: width() == "mobile" ? "auto" : "",
                                          }}
                                        >
                                          <div className="row ml-3">- CPF</div>
                                          <div className="row ml-3">- Data de Nascimento</div>
                                          <div className="row ml-3">- Nome Completo</div>
                                        </td>
                                      </tr>

                                      <tr className="tr-custom" key="row2">
                                        <td
                                          className="detailed-td text-center align-middle "
                                          style={{
                                            width: "30%",
                                            backgroundColor: "#F2F3F3",
                                            margin: "2px",
                                          }}
                                        >
                                          <span>Dados do Benefício</span>
                                        </td>
                                        <td
                                          className="detailed-td text-center "
                                          style={{
                                            width: "70%",
                                            backgroundColor: "#F2F3F3",
                                            margin: "2px",
                                            overflowY: width() == "mobile" ? "auto" : "",
                                          }}
                                        >
                                          <div className="row ml-3">
                                            - Número do Benefício
                                          </div>
                                          <div className="row ml-3">
                                            - Situação do Benefício
                                          </div>
                                          <div className="row ml-3">
                                            - Espécie do Benefício
                                          </div>
                                          <div className="row ml-3" style={{}}>
                                            - Indicação de que o Benefício foi Concedido por
                                            Liminar
                                          </div>
                                          <div className="row ml-3">
                                            - Data de Cessação do Benefício – DCB (se houver)
                                          </div>
                                          <div className="row ml-3">
                                            - Possui Representante Legal
                                          </div>
                                          <div className="row ml-3">- Possui Procurador</div>
                                          <div className="row ml-3">
                                            - Possui Entidade Representação
                                          </div>
                                          <div className="row ml-3">- Pensão Alimentícia</div>
                                          <div className="row ml-3">
                                            - Empréstimo não permitido
                                          </div>
                                          <div className="row ml-3">
                                            - Data da Última Perícia Médica
                                          </div>
                                          <div className="row ml-3">
                                            - Data do Despacho do Benefício – DDB dados
                                          </div>
                                        </td>
                                      </tr>

                                      <tr className="tr-custom" key="row3">
                                        <td
                                          className="detailed-td text-center align-middle "
                                          style={{
                                            width: "30%",
                                            backgroundColor: "#F2F3F3",
                                            margin: "2px",
                                          }}
                                        >
                                          <span>Dados do pagamento do Benefício</span>
                                        </td>
                                        <td
                                          className="detailed-td text-center "
                                          style={{
                                            width: "70%",
                                            backgroundColor: "#F2F3F3",
                                            margin: "2px",
                                            overflowY: width() == "mobile" ? "auto" : "",
                                          }}
                                        >
                                          <div className="row ml-3">
                                            - UF onde o Beneficiário recebe os proventos
                                          </div>
                                          <div className="row ml-3">
                                            - Tipo de Crédito (Cartão ou Conta-Corrente)
                                          </div>
                                          <div className="row ml-3">
                                            - Indicação da Instituição Financeira que paga o
                                            Benefício
                                          </div>
                                          <div className="row ml-3">- Agência Pagadora</div>
                                          <div className="row ml-3">
                                            - Conta-Corrente onde o Benefício é pago
                                          </div>
                                          <div className="row ml-3">
                                            - Margem Consignável Disponível
                                          </div>
                                          <div className="row ml-3">
                                            - Margem Consignável Disponível para Cartão
                                          </div>
                                          <div className="row ml-3">
                                            - Quantidade de empréstimos ativos/suspensos
                                          </div>
                                          <div className="row ml-3">
                                            - Valor total comprometido
                                          </div>
                                          <div className="row ml-3">
                                            - Valor máximo de comprometimento
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>

                                <div
                                  className="form-group row"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="col-sm-12">
                                    <h6
                                      className="text-justify mb-1"
                                      style={{
                                        margin: "0px",
                                        fontSize: width() == "mobile" ? "x-small" : "",
                                        textAlign: "justify",
                                      }}
                                    >
                                      Este termo autoriza uma das Instituições Financeiras
                                      citadas a consultar as informações acima descritas
                                      durante um período de 30 dias. Este pedido poderá ser
                                      efetuado pela Instituição Financeira em até 45 dias após
                                      a assinatura deste instrumento.
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CardBody>
                      </Collapse>
                    </Card>

                    <Card className='mb-2'>
                      <CardHeader onClick={handleToggle}>
                        <div className='d-flex justify-content-between'>
                          <h5>Autorização de Portabilidade</h5>
                          <h5><i className={`mdi mdi-arrow-${toggleQuestion == 2 ? 'up' : 'down'}-thick`} /></h5>
                        </div>
                      </CardHeader>
                      <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                        <CardBody>
                          <div className="form" style={{ overflow: "hidden" }}>
                            <div className="form-group row" style={{ marginBottom: "0px" }}>
                              <div className="col-sm-12">
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                  }}
                                >
                                  1 – Solicito e autorizo a BRB – Crédito, Financiamento e Investimento S/A,
                                  localizada no endereço Centro Empresarial CNC - ST SAUN Quadra 5 Lote C, Bloco C, 3º andar Brasília/DF – CEP 70.091-900,
                                  nos termos da Resolução CMN Nº 5.057, de 15/12/2022, e demais legislações e regulamentações vigentes,
                                  requisitar junto à Instituição Credora Original consulta às informações necessárias de todas as operações de crédito
                                  ativas para realização de portabilidade de operação de crédito de minha titularidade, relacionada abaixo.
                                </h6>
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                  }}
                                >
                                  2 – Declaro ciência de que a portabilidade depende de aprovação cadastral na
                                  Financeira BRB.
                                </h6>
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                  }}
                                >
                                  3 – Declaro que tomei ciência e estou de acordo com o Custo Efetivo Total – CET
                                  simulado para a portabilidade da operação de crédito.
                                </h6>
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                  }}
                                >
                                  4 – Declaro que tomei ciência e estou de acordo que a solicitação da portabilidade
                                  poderá ser cancelada se o contrato original contiver parcela(s) vencida(s) e não
                                  paga(s).
                                </h6>
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                    fontWeight: "bold",
                                  }}
                                >
                                  5 – Declaro que fui informado/a e concordo que o valor de parcela poderá sofrer
                                  alterações em função do saldo devedor atualizado da operação de crédito, conforme
                                  apresentação em cédula de crédito bancária referente à portabilidade
                                </h6>
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                  }}
                                >
                                  6 – Declaro que tenho ciência que as operações de crédito consignado possuem taxa pré-fixada e
                                  seguem o Sistema Price de pagamento.
                                </h6>
                                <h6
                                  className="text-justify mb-1"
                                  style={{
                                    margin: "0px",
                                    fontSize: width() == "mobile" ? "x-small" : "",
                                  }}
                                >
                                  7 - Este termo autoriza a Instituição Financeira citada a consultar as informações
                                  acima descritas durante um período de 15 dias.
                                </h6>
                              </div>
                            </div>

                            <div className="form-group row mt-1 mb-0">
                              <div className="col-sm-12">
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                                      DADOS PESSOAIS DO SOLICITANTE
                                    </td>
                                  </tr>
                                </table>

                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td" style={{ width: "100%" }}>
                                      Nome Completo: {data.nome}
                                    </td>
                                  </tr>
                                </table>

                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td col-60">
                                      CPF: {data.cpf}
                                    </td>
                                    <td className="detailed-td col-40">
                                      Telefone: {data.celular || data.telefone}
                                    </td>
                                  </tr>
                                </table>
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td" style={{ width: "100%" }}>
                                      Nome da Mãe: {data.mae}
                                    </td>
                                  </tr>

                                </table>

                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td col-50" style={{ width: "50%" }}>
                                      Documento de Identificação
                                    </td>
                                    <td className="detailed-td col-50" style={{ width: "50%" }}>
                                      Tipo: {data.t_doc}
                                    </td>
                                  </tr>
                                </table>
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td col-40" style={{ width: "40%" }}>
                                      Número: {data.num_doc}
                                    </td>
                                    <td className="detailed-td col-40" style={{ width: "30%" }}>
                                      Órgão emissor: {data.orgao}
                                    </td>
                                    <td className="detailed-td col-40" style={{ width: "30%" }}>
                                      Data de emissão: {moment(data.data_exp || 0).format("DD/MM/YYYY")}
                                    </td>
                                  </tr>
                                </table>

                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                                      DADOS DA OPERAÇÃO ORIGINAL
                                    </td>
                                  </tr>
                                </table>
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td" style={{ width: "100%" }}>
                                      IF Credora: {data.banco_original}
                                    </td>
                                  </tr>
                                </table>
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td" style={{ width: "100%" }}>
                                      Número do contrato original: {data.contrato_original}
                                    </td>
                                  </tr>
                                </table>
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td" style={{ width: "100%" }}>
                                      Valor da parcela: {data.parcela_original}
                                    </td>
                                  </tr>
                                </table>

                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                                      DADOS DA OPERAÇÃO PROPOSTA
                                    </td>
                                  </tr>
                                </table>
                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td col-33" style={{ width: "33%" }}>
                                      Taxa de Juros(a.m): {data.taxa_juros_mensal}
                                    </td>
                                    <td className="detailed-td col-33" style={{ width: "33%" }}>
                                      Taxa de Juros(a.a): {data.taxa_juros_anual}
                                    </td>
                                    <td className="detailed-td col-33" style={{ width: "34%" }}>
                                      CET: {data.custo_efetivo}
                                    </td>
                                  </tr>
                                </table>

                                <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                  <tr className="tr-custom" key="row1">
                                    <td className="detailed-td col-50" style={{ width: "50%" }}>
                                      Prazo: {data.prazo}
                                    </td>
                                    <td className="detailed-td col-50" style={{ width: "50%" }}>
                                      Valor da Parcela: {data.valor_parcelas}
                                    </td>
                                  </tr>
                                </table>
                                <>
                                  <table className="table c-table c-table-bordered" style={{ marginTop: "5px", marginBottom: "0px", borderCollapse: "separate" }}>
                                    <tr className="tr-custom" key="row1">
                                      <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                                        DADOS DO CORRESPONDENTE
                                      </td>
                                    </tr>
                                  </table>

                                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                    <tr className="tr-custom" key="row1">
                                      <td className="detailed-td col-70" style={{ width: "60%" }}>
                                        Empresa: {data.empresa_nome}
                                      </td>
                                      <td className="detailed-td col-30" style={{ width: "40%" }}>
                                        Cnpj: {data.empresa_cnpj}
                                      </td>
                                    </tr>
                                  </table>

                                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                                    <tr className="tr-custom" key="row1">
                                      <td className="detailed-td col-60" style={{ width: "60%" }}>
                                        Atendente: {data.atendente_nome}
                                      </td>
                                      <td className="detailed-td col-40" style={{ width: "40%" }}>
                                        CPF: {data.atendente_cpf}
                                      </td>
                                    </tr>
                                  </table>
                                </>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </>}
              </div>
              {!autorizado &&
                <>
                  <div
                    className="form-group row"
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      marginBottom: "15px",
                      fontSize: "15px",
                    }}
                  >
                    <div className="col-sm-12">
                      <div className="custom-control mt-2 custom-checkbox font-weight-bold">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          checked={checked}
                          onChange={(e) => setChecked(!checked)}
                          name="connected"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          <strong>Autorizar INSS/ IN100 e Portabilidade?</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </>}

              <div className="row mr-5 ml-5">
                <button
                  style={{ backgroundColor: "#3E6C84", width: "100%" }}
                  onClick={submit}
                  disabled={!checked || aguarde}
                  className="btn btn-md btn-info font-weight-bold"
                >
                  Avançar
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}

      {aguarde && <Aguarde />}
      {naoExiste && <NaoExiste />}

      {autorizado && <Autorizado />}

    </>
  );
}

export default ConfirmAutorizacaoInss;
