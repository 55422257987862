import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
// import { sendMultiPartData } from "../../../../helpers/auth";
import { getCookie, getTiposDocumentos, width, } from "../../../../helpers/general";
import Dropper from "../../../dropzone.js";
import { sendMultiPartData, sendData } from "../../../../helpers/auth";
import { connect } from "react-redux";
import CardCollapse from "../../../layout/CardCollapse";
import * as moment from "moment";

const AnalisarPendencia = ({ ...props }) => {
  const [dados,] = useState(props.dados)
  const [loaded, setLoaded] = useState(false)
  const [historico, setHistorico] = useState([])
  const [collapsed, setCollapsed] = useState(props.dados.status != "Pendente")
  const [warning, setWarning] = useState({ documento: 0 })
  const [data, setData] = useState({
    pendencia: props.dados,
    data: new Date().toISOString().substr(0, 10),
    file: null,
    observacao: "",
    proposta_uuid: props.proposta,
    ip: getCookie({ label: "ip" }),
  });
  // let _tipos = getTiposDocumentos();

  const [aguarde, setAguarde] = useState(false);

  useEffect(() => {
    getDados()
  }, []);

  function handleChange(files) {
    let obj = Object.assign(files);

    setData({ ...data, file: obj });
  }

  function handleDelete(e) {
    setData({ ...data, file: "" });
  }

  // const [loaded, setLoaded] = useState(true);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    if (props.onCloseEv) props.onCloseEv(close);
  }

  function validarDados() {
    console.log("AnalisarPendencia.validarDados: ", data)
    let result = true;

    if (data.anexar_doc_tipo != 'nenhum' && !data.file) {
      warning.documento = 1
      result = false
      alert('Selecione um documento');
    }

    return result;
  }

  async function getDados() {
    setLoaded(false);
    sendData({
      uri: uriList('pendenciashst'),
      content: { pendenciaid: props.dados.id },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("ResolverPendencia.getDados(1): ", res);
        setHistorico(res);
        setLoaded(true);
      })

      .catch((e) => {
        console.log("ResolverPendencia.getDados(2)");
        console.log(e);
        setLoaded(true);
        return false;
      });

  }

  async function handlePost(e) {
    console.log("AnalisarPendencia(1)");
    e.preventDefault();
    let dObj = new FormData();

    if (validarDados()) {
      console.log("AnalisarPendencia(2): ", data);
      setAguarde(true);
      dObj.append("proposta_uuid", props.dados.proposta_uuid);
      dObj.append("observacao", data.observacao || "Favor analisar pendência");
      dObj.append("pendencia", props.dados);
      dObj.append("userid", props.user?.id)
      dObj.append("id", props.dados.id)
      if (data.file) {
        dObj.append("file", data?.file[0]);
        dObj.append("data_arquivo", data?.file[0]?.lastModifiedDate);
      }
      dObj.append("ip", getCookie({ label: "ip" }));

      console.log("AnalisarPendencia(3)");

      sendMultiPartData({
        uri: uriList('analisarPendencia'),
        content: dObj,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          console.log("AnalisarPendencia(4)");
          setAguarde(false);
          handleClose(null, true);
        })

        .catch((e) => {
          console.log("AnalisarPendencia(erro)");
          setAguarde(false);
          console.log(e);
          return false;
        });
    }
  }

  const getHistorico = () => {
    console.log("ResolverPendencia.getHistorico(1): ", historico);
    let fontSize = width() == "mobile" ? "0.5rem" : "0.8rem"
    let bgColor = true
    return (
      <div style={{ border: "1px solid #D5DBE1", }}>
        <div className="d-flex font-weight-bold" style={{ backgroundColor: "#E5E4E4" }} >
          <div style={{ width: "23%", fontSize }}>
            <span>Usuário</span>
          </div>
          <div style={{ width: "17%", fontSize }}>
            <span>Data</span>
          </div>
          <div style={{ width: "60%", fontSize }}>
            <span>Histórico</span>
          </div>
        </div>
        {historico.map((hist, i) => {
          bgColor = !bgColor
          return (
            <div className="d-flex" key={i} style={{ borderTop: "1px solid #D5DBE1", backgroundColor: bgColor ? "#F3F3F3" : "" }}>
              <div style={{ width: "23%", fontSize }}>
                {hist?.usuario}
              </div>
              <div style={{ width: "17%", fontSize }}>
                {moment(hist.updated_at).format("DD/MM/YYYY HH:mm:ss")}
              </div>
              <div style={{ width: "60%", fontSize }}>
                {hist.observacao}
              </div>
            </div>
          )
        })}
      </div>
    )

  }

  return (
    <>
      <Modal
        size="md"
        show={true}
        centered
        onHide={(e) => { handleClose(e, false); }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              Enviar Pendência para Análise - {props.dados?.id}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <>
            {!collapsed &&
              <>
                <div style={{ borderBottom: "1px solid black", marginBottom: "30px" }}>
                  <div><strong>{dados.descricao}</strong></div>
                  <div><italic>{dados.observacao}</italic></div>
                </div>
                <div>
                  <div style={{ margin: "10px auto" }}>
                    <label className="m-0 font-90">
                      Observação
                    </label>
                    {/* <textarea
                  type="text"
                  className="form-control"
                  autoFocus
                  value={data.observacao}
                  onChange={(e) => setData({ ...data, observacao: e.target.value })}
                /> */}
                    <textarea
                      type="textArea"
                      id="observacao"
                      name="observacao"
                      rows="3"
                      autoFocus={true}
                      required={true}
                      className="form-control"
                      value={data.observacao}
                      onChange={(e) => setData({ ...data, observacao: e.target.value })}
                    />
                  </div>
                </div>

                <br></br>

                {dados.anexar_doc_tipo && dados.anexar_doc_tipo != "nenhum" &&
                  <Dropper
                    name="file"
                    title={`${dados.anexar_doc_tipo}`}
                    data={data.file}
                    change={handleChange}
                    del={handleDelete}
                    disabled={props.dados.status != "Pendente"}
                    accept={"application/pdf,image/*,text/plain"}
                    // warning="ADE - Doc. Auxiliar de Averbação"
                    field="file"
                    telaAux="averbacao"
                  />}
              </>
            }

            {aguarde && (
              <Modal size={"sm"} show={true} animation={false} backdrop="static">
                <Modal.Body
                  className="maxWidth750 mt-10"
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner animation="border" />
                  {"  "}
                  Aguarde...
                </Modal.Body>
              </Modal>
            )}
            {loaded &&
              <div title={!collapsed ? 'Clique para expandir' : "Clique para fechar"}>
                <CardCollapse isOpen={collapsed} hideHeader title={"Histórico de Alterações"} chield={() => getHistorico()}
                  setCollapse={(collapse) => setCollapsed(!collapse)} bodyToggle={true}
                  forceCollapse={props.dados.status != "Pendente"} />
              </div>
            }
          </>
        </Modal.Body>
        <Modal.Footer>
          {props.dados.status == "Pendente" &&
            <Button color="success" onClick={handlePost}>
              Enviar para Análise
            </Button>}
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    user: state.dados.user
  };
}

export default connect(mapStateToProps)(AnalisarPendencia);
