import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
// import { sendMultiPartData } from "../../../../helpers/auth";
import { getCookie } from "../../../../helpers/general";
import Dropper from "../../../dropzone.js";
import { sendMultiPartData } from "../../../../helpers/auth";
import { connect } from "react-redux";

const Averbacao = ({ ...props }) => {
  const [show, setShow] = useState(true);
  const [data, setData] = useState({
    codigo: "",
    data: new Date().toISOString().substr(0, 10),
    file: null,
    proposta_uuid: props.proposta,
    ip: getCookie({ label: "ip" }),
  });
  const [showAlert, setAlert] = useState({
    file: 0,
  });
  const [aguarde, setAguarde] = useState(false);

  function handleChange(files) {
    let obj = Object.assign(files);

    setData({ ...data, file: obj });
  }

  function handleDelete(e) {
    setData({ ...data, file: "" });
  }

  // const [loaded, setLoaded] = useState(true);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close);
  }

  function validarDados() {
    let result = true;
    if (!data.codigo && props.tela === "averbacao") {
      alert("Informe o Número da Ade/Comprovante");
      result = false;
    }

    if (result && !data.data) {
      alert("Informe a Data");
      result = false;
    }

    if (result && !data.file && props.tela === "averbacao") {
      alert("Anexe o Documento");
      result = false;
    }

    return result;
  }

  async function handlePost(e) {
    console.log("Informar Pagamento/Averbacao(1)");
    e.preventDefault();
    let dObj = new FormData();

    if (validarDados()) {
      console.log("Informar Pagamento/Averbacao(2)");
      setAguarde(true);
      dObj.append("proposta_uuid", props.proposta);
      dObj.append("codigo", data.codigo);
      dObj.append("data", data.data);
      if (data.file) {
        dObj.append("file", data?.file[0]);
        dObj.append("data_arquivo", data?.file[0]?.lastModifiedDate);
      }
      dObj.append("ip", getCookie({ label: "ip" }));
      let uri = "averbar";
      if (props.tela === "pagamento") uri = "informarPagamento";

      console.log("Informar Pagamento/Averbacao(3)");

      sendMultiPartData({
        uri: uriList(uri),
        content: dObj,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          console.log("Informar Pagamento/Averbacao(4)");
          setAguarde(false);
          handleClose();
        })

        .catch((e) => {
          console.log("Informar Pagamento/Averbacao(erro)");
          setAguarde(false);
          console.log(e);
          return false;
        });
    }
  }

  return (
    <>
      <Modal
        size="md"
        show={show}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              {props.tela === "averbacao"
                ? "Averbar Proposta"
                : "Informar Pagamento"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <>
            <div style={{ display: "flex", flexDirection: "line" }}>
              <div style={{ margin: "10px auto" }}>
                <label className="m-0 font-90">
                  {props.tela === "averbacao"
                    ? "Número ADE/Comprovante"
                    : "Número Comprovante"}{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  autoFocus
                  value={data.codigo}
                  onChange={(e) => setData({ ...data, codigo: e.target.value })}
                />
              </div>
              {/* <div style={{ width: '20px' }}></div> */}
              <div style={{ margin: "10px auto" }}>
                <label className="m-0 font-90">Data</label>
                <input
                  type="date"
                  className="form-control"
                  value={data.data}
                  onChange={(e) => setData({ ...data, data: e.target.value })}
                />
              </div>
            </div>

            <br></br>

            <Dropper
              name="file"
              title={
                props.tela === "averbacao"
                  ? "ADE - Doc. Auxiliar de Averbação"
                  : "Comprovante de Pagamento"
              }
              data={data.file}
              change={handleChange}
              del={handleDelete}
              disabled={false}
              accept={"application/pdf,image/*,text/plain"}
              // warning="ADE - Doc. Auxiliar de Averbação"
              field="file"
              telaAux="averbacao"
            />
          </>

          {aguarde && (
            <Modal size={"sm"} show={true} animation={false} backdrop="static">
              <Modal.Body
                className="maxWidth750 mt-10"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Spinner animation="border" />
                {"  "}
                Aguarde...
              </Modal.Body>
            </Modal>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handlePost}>
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
  };
}

export default connect(mapStateToProps)(Averbacao);
