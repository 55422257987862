import React, { useState, useEffect, useCallback } from "react";

import Form from '../../components/form/admin/coeficientes';

import { getData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import { DefaultOrder, sortByString, sortByNumber, compareDate } from '../../helpers/general';

function Coeficientes({ ...props }) {
    const [coeficientes, setCoeficientes] = useState([]);
    const [coeficientesC, setCoeficientesC] = useState([]);
    const [tabelas, setTabelas] = useState([]);
    const [total, setTotal] = useState(false);
    const [actualPage, setActualPage] = useState(0);
    const [filterOptions, setFilterOptions] = useState(false);
    const [dateVisible, setDateVisible] = useState('todos');
    const [modal, setModal] = useState(false);
    const [clickedTr, setClickedTr] = useState("")
    const [ascDesc, setAscDesc] = useState(true);
    const [coeficiente_filter, setCoeficienteFilter] = useState("");

    const handleOption = (e) => {
        let label = e.currentTarget.dataset.label;
        let value = e.currentTarget.value
        let newDetailed;

        if (value !== "todos" && value !== "") {
            newDetailed = coeficientesC.filter((proposta) => {
                if (label = "coeficiente_filter") {
                    let result = (proposta["tabela"] && proposta["tabela"].toLowerCase().indexOf(value.toLowerCase()) >= 0) ||
                        (proposta["convenio"] && proposta["convenio"].toLowerCase().indexOf(value.toLowerCase()) >= 0)

                    return result
                }
                else
                    return proposta[label] === value
            });


            setCoeficientes(newDetailed);
            setActualPage(0);
        } else {
            setCoeficientes(coeficientesC);
            setActualPage(0);
        }
    };

    const handleCoeficiente = (e) => {
        let value = e.currentTarget.value
        setCoeficienteFilter(value);
        handleOption(e)
    }

    const handlePage = (e) => {
        setActualPage(e.currentTarget.value);
    };

    const createFilter = (obj) => {
        let newObj = {
            coeficiente: [],
            convenio_id: [],
            tabela_id: [],
        };

        for (let o of obj) {
            for (let i in o) {
                if (newObj[i]) {
                    newObj[i].push(o[i]);
                }
            }
        }

        for (let i in newObj) {
            newObj[i] = [...new Set(newObj[i])];
        }

        setFilterOptions(newObj);
    };

    const _tabelas = useCallback(() => {
        getData({ uri: uriList("tabelas") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                setTabelas(res);
            })
            .catch((e) => {
                return false;
            });
    })

    const _coeficientes = useCallback(() => {
        getData({ uri: uriList("coeficientes") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                DefaultOrder('coeficientes', res.map((coeficientes) => coeficientes), setCoeficientes)
                createFilter(res.map((coeficientes) => coeficientes))
                setCoeficientesC(res)
            })
            .catch((e) => {
                return false;
            });
    })

    useEffect(() => {
        _coeficientes()
        _tabelas()
    }, []);

    const sortArrObj = (key, type) => {
        let arr = [...coeficientes];
        let asc = !ascDesc

        arr = arr.sort((a, b) => {
            if (type === 'string') {
                if (asc) {
                    setAscDesc(asc);
                    return sortByString(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByString(b, a, key, type);
                }
            } else if (type === 'numeric') {
                if (asc) {
                    setAscDesc(asc);
                    return sortByNumber(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByNumber(b, a, key, type);
                }
            } else {
                if (asc) {
                    setAscDesc(asc);
                    return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
                } else {
                    setAscDesc(asc);
                    return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
                }
            }
        });

        setCoeficientes(arr);
    };


    return (
        <>
            <Form
                filterOptions={filterOptions}
                handleOption={handleOption}
                setDateVisible={setDateVisible}
                coeficiente_filter={coeficiente_filter}
                handleCoeficiente={handleCoeficiente}
                setModal={setModal}
                setCoeficientes={setCoeficientes}
                setClickedTr={setClickedTr}
                sortArrObj={sortArrObj}
                clickedTr={clickedTr}
                coeficientes={coeficientes}
                tabelas={tabelas}
                actualPage={actualPage}
                handlePage={handlePage}
                dateVisible={dateVisible}
                total={total}
                callback={_coeficientes}
                context={props.context} />

            {modal}
        </>
    );
};

export default Coeficientes;
