import React, { useState, useEffect, useCallback } from "react";

import Form from "../../components/form/admin/convenios";

import { getData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import {
  DefaultOrder,
  sortByString,
  sortByNumber,
  compareDate,
} from "../../helpers/general";

function Convenios({ ...props }) {
  const [convenios, setConvenios] = useState([]);
  const [conveniosC, setConveniosC] = useState([]);
  const [integracoes, setIntegracoes] = useState([]);
  const [total, setTotal] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState(false);
  const [dateVisible, setDateVisible] = useState("todos");
  const [modal, setModal] = useState(false);
  const [clickedTr, setClickedTr] = useState("");
  const [ascDesc, setAscDesc] = useState(true);
  const [convenio_filter, setConvenioFilter] = useState("");

  const handleOption = (e) => {
    let label = e.currentTarget.dataset.label || e.currentTarget.name;
    let value = e.currentTarget.value;
    let newDetailed;

    if (value !== "todos" || value !== "") {
      newDetailed = conveniosC.filter((proposta) => {
        if (label == "convenio_filter") {
          let result =
            (proposta["nome"] &&
              proposta["nome"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (proposta["codigo"] &&
              proposta["codigo"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (proposta["linha"] &&
              proposta["linha"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (proposta["orgao"] &&
              proposta["orgao"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (proposta["tabelapadrao"] &&
              proposta["tabelapadrao"]
                .toLowerCase()
                .indexOf(value.toLowerCase()) >= 0);

          return result;
        } else return proposta[label] === value;
      });

      setConvenios(newDetailed);
      setActualPage(0);
    } else {
      setConvenios(conveniosC);
      setActualPage(0);
    }
  };

  const handleConvenio = (e) => {
    let value = e.currentTarget.value;
    setConvenioFilter(value);
    handleOption(e);
  };

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  const createFilter = (obj) => {
    let newObj = {
      nome: [],
      sigla: [],
      codigo: [],
      linha: [],
      orgao: [],
      tabelapadrao_id: [],
    };

    for (let o of obj) {
      for (let i in o) {
        if (newObj[i]) {
          newObj[i].push(o[i]);
        }
      }
    }

    for (let i in newObj) {
      newObj[i] = [...new Set(newObj[i])];
    }

    setFilterOptions(newObj);
  };

  const _convenios = useCallback(() => {
    getData({ uri: uriList("convenios") })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        //setConvenios(res.map((convenios) => convenios));
        DefaultOrder(
          "convenios",
          res.map((convenios) => convenios),
          setConvenios
        );
        createFilter(res.map((convenios) => convenios));
        setConveniosC(res);
      })
      .catch((e) => {
        return false;
      });

    getData({ uri: uriList("integracoes") })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        console.log("Convenios.Integracoes: ", res[0]?.id)
        setIntegracoes(res.map((integracoes) => integracoes));
      })
      .catch((e) => {
        return false;
      });
  });

  useEffect(() => {
    _convenios();
  }, []);

  const sortArrObj = (key, type) => {
    let arr = [...convenios];
    let asc = !ascDesc;

    arr = arr.sort((a, b) => {
      if (type === "string") {
        if (asc) {
          setAscDesc(asc);
          return sortByString(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByString(b, a, key, type);
        }
      } else if (type === "numeric") {
        if (asc) {
          setAscDesc(asc);
          return sortByNumber(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByNumber(b, a, key, type);
        }
      } else {
        if (asc) {
          setAscDesc(asc);
          return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
        } else {
          setAscDesc(asc);
          return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
        }
      }
    });

    setConvenios(arr);
  };

  return (
    <>
      <Form
        filterOptions={filterOptions}
        handleOption={handleOption}
        setDateVisible={setDateVisible}
        convenio_filter={convenio_filter}
        handleConvenio={handleConvenio}
        setModal={setModal}
        setClickedTr={setClickedTr}
        clickedTr={clickedTr}
        sortArrObj={sortArrObj}
        setConvenios={setConvenios}
        convenios={convenios}
        integracoes={integracoes}
        actualPage={actualPage}
        handlePage={handlePage}
        dateVisible={dateVisible}
        total={total}
        callback={_convenios}
        context={props.context}
      />

      {modal}
    </>
  );
}

export default Convenios;
