import React, { useState, useEffect } from "react";

import SimulatorLayout from "../components/layout";
import { connect } from "react-redux";
import {
  setUser,
  setAlterarProposta,
  setCliente,
  setProposta,
} from "../store/actions/dados";

import { sendData } from "../helpers/auth";
import { uriList } from "../helpers/config";
import AutSicoob from "../components/form/admin/AutorizacaoSicoob";
import Data from "../views/simulator/data";
import Address from "../views/simulator/address";
import Contact from "../views/simulator/contact";
import Complement from "../views/simulator/complement";
import ComplementSicoob from "../views/simulator/complement_sicoob";
import Bank from "../views/simulator/bank";
import Documents from "../views/simulator/documents";

// import AutSicoob from '../views/simulator/confirmAutorizacaoInss'

function AutorizacaoSicoob({ ...props }) {
  const [data, setData] = useState({});
  const [result, setResult] = useState({});
  const [enviando, setEnviando] = useState(false);
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 1,
  });

  useEffect(() => {
    if (!props.alterar) _iniciar();
    else {
      console.log(
        "AutorizacaoSicoob - detalhes: ",
        props.detalhes.posicao
      );
      if (["simulacao"].indexOf(props.detalhes.posicao.toLowerCase()) >= 0)
        setStep({ ...step, cur: 1 });
      else setStep({ ...step, cur: step.maxStep });
    }
  }, []);

  function _iniciar() {
    props.setProposta("limpar");
    props.setAlterarProposta(false, props.detalhes);
    props.alterarCliente(null);
  }

  const reRender = () => {
    setStep({ ...step, cur: -1 });
    setStep({ ...step, cur: 0 });
  };

  const toggleStep = async (_step, _data) => {
    if (_data) setData(_data);
    console.log("autorizacaoSicoob - toggleStep: ", _step, _data);
    let cur = step.cur + _step;

    if (cur <= step.maxStep) {
      console.log("autorizacaoSicoob - toggleStep(1): ", cur);
      if (step.cur >= 0) setStep({ ...step, cur });
    } else {
      console.log("autorizacaoSicoob - toggleStep(2)");
      setEnviando(true);

      await sendData({
        uri: uriList("enviarEmailAutorizacao"),
        content: data,
        method: "POST",
      })
        .then((res) => {
          console.log("autorizacaoSicoob - toggleStep(3): ", res);
          setEnviando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();
        })
        .catch((e) => {
          console.log("autorizacaoSicoob - toggleStep(4): ", e);
          setEnviando(false);
        });

      console.log("autorizacaoSicoob - toggleStep(5)");
      props.alterarCliente(null);
      setStep({ ...step, cur: 0 });
    }
  };

  const RenderScreen = ({ ...props }) =>
  ({
    0: (
      <AutSicoob
        data={data}
        reRender={reRender}
        toogleStep={toggleStep}
        admin={true}
      />
    ),
    // 1: <Data title="Dados Pessoais" toggleStep={toggleStep} admin={true} />,

    // 2: <Address title="Endereço" toggleStep={toggleStep} admin={true} />,

    // 3: <Contact title="Contato" toggleStep={toggleStep} admin={true} />,

    // 4: <Bank title="Dados Bancários" toggleStep={toggleStep} admin={true} />,

    // 5: (
    //   <Complement title="Complemento" toggleStep={toggleStep} admin={true} />
    // ),

    // 6: (
    //   <ComplementSicoob
    //     title="Complemento Sicoob"
    //     toggleStep={toggleStep}
    //     admin={true}
    //   />
    // ),

    1: <Documents title="Documentos" toggleStep={toggleStep} admin={true} docPessoal={true} data={data} />,
  }[props.step] || (
      <>
        <h1>404!</h1>
        <h4>Esse formulário: {props.step}não existe! </h4>
      </>
    ));

  return (
    <>
      <SimulatorLayout admin={true}>
        <RenderScreen step={step.cur} />
      </SimulatorLayout>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
  };
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(AutorizacaoSicoob);
