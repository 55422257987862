import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import Dropper from "../../dropzone";
import { post } from "axios";
import { getCookie, getLocation, Aguarde } from "../../../helpers/general";
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA } from "../../../helpers/config";
import { InputLabelAlert } from "../../layout";
import { swalError } from "../../../components/swal";
import { getItemCrypt } from '../../../helpers/auth'

function Documents({ ...props }) {
  const [tipo, setTipo] = useState(props.tipos[0].value);
  const [documento, setDocumento] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [dataArquivo, setDataArquivo] = useState(null);
  const [descricao, setDescricao] = useState("");

  useEffect(() => {
    console.log("uploadDocumentos.Documents-Tipos: ", props.tipos);
    getLocation();
  }, []);

  const handleClose = () => {
    if (props.onCloseEv) props.onCloseEv();
  };

  const Enviando = () => {
    return <Aguarde />;
    // return (
    //     <Modal size={'md'} scrollable show={true} animation={false} backdrop="static" centered>
    //         <Modal.Body className="maxWidth750"
    //             style={{ width: '100%', justifyContent: "center", textAlign: "center", color: "#66b2cc" }}
    //         >
    //             <Spinner animation="border" />
    //             {'  '}
    //         Aguarde enquanto o processo é finalizado...
    //     </Modal.Body>
    //     </Modal>

    // )
  };

  const handleGravar = () => {
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];

    if (documento[0]) {
      setEnviando(true);
      let dObj = new FormData();

      dObj.append("tipo", tipo);
      dObj.append("uuid", props.proposta);
      dObj.append("file", documento[0]);
      dObj.append("descricao", descricao);
      dObj.append("ip", getCookie({ label: "ip" }));
      dObj.append("data_arquivo", documento[0].lastModifiedDate);
      dObj.append("geolocation", JSON.stringify({ latitude, longitude }));

      const url = uriList("documentos");
      const config = {
        // headers: new Headers(defaultHeaders())
        headers: {
          key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
          "content-type": "multipart/form-data",
          "Accept": '*',
          "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
          "captcha": getItemCrypt(TOKEN_CAPTCHA),
        },
      };
      post(url, dObj, config)
        .then((response) => {
          setEnviando(false);
          if (props.onCloseEv) props.onCloseEv(true);
        })
        .catch((err) => {
          setEnviando(false);
        });
    } else {
      swalError({ title: "É necessário anexar o documento." });
    }
  };

  const handleDelete = (e) => {
    setDocumento(false);
  };

  const handleChange = (e) => {
    setDocumento(e);
    setDataArquivo(e.lastModifiedDate);
  };

  function handleChangeDesc(e, innerData) {
    let target = e.currentTarget;
    let value = innerData || target.value;

    if (value) setDescricao(value.toUpperCase());
  }

  return (
    <>
      <Modal
        size="md"
        show={true}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Upload de Documentos</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          {enviando ? (
            <Enviando />
          ) : (
            <div>
              <label className="m-0 font-90">Tipo de Documento</label>
              <select
                className="form-control"
                onChange={(e) => setTipo(e.target.value)}
              >
                {props.tipos.map((item, key) => {
                  return (
                    <option key={key} value={item.value}>
                      {item.nome}
                    </option>
                  );
                })}
              </select>

              <br />
              <InputLabelAlert
                type="text"
                label="Descrição do Documento"
                id="descricao"
                length="200"
                // warning={props.warning.num_doc}
                value={descricao}
                handle={handleChangeDesc}
                focus={false}
              />

              <br />
              <Dropper
                name="documento"
                title={"Download de documento"}
                data={documento}
                change={handleChange}
                del={handleDelete}
                disabled={false}
                tipo={tipo}
                accept={
                  tipo == "outros"
                    ? "*/*"
                    : "application/pdf,image/*,text/plain"
                }
                field="documento"
                telaAux="upload"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handleGravar}>
            {" "}
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
  };
}

export default connect(mapStateToProps)(Documents);
