import React, { useState, useEffect } from "react";
import { width } from '../helpers/general';
import { sendData, } from "../helpers/auth";
import { uriList } from '../helpers/config';
// import { Route, Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import { setUser, } from './../store/actions/dados'
import { connect } from 'react-redux'

const Form = ({ ...props }) => {
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [novaSenha, setNovaSenha] = useState('')
    const [novaSenhaOk, setNovaSenhaOk] = useState(false)

    useEffect(() => {
        let _email = props.match.params.email
        if (_email)
            setEmail(_email)
    }, [])

    const submit = (e) => {
        e.preventDefault()
        if (!senha || !novaSenha) {
            alert("Informe a nova senha!")
        }

        if (senha !== novaSenha) {
            alert("Senhas não coincidem!")
        }

        sendData({ uri: uriList('novaSenha'), content: { email, password: senha }, method: 'POST' })
            .then(res => {
                if (res.status && parseInt(res.status) > 200) throw new Error();
                setNovaSenhaOk(true)
            })
            .catch(e => {
                console.log(e)
                alert("Erro ao tentar alterar a Senha!")
            });
    }

    const Redireciona = () => {
        console.log("Redirecionando...")

        localStorage.clear()
        props.alterarUser(null)
    }

    return (
        <>
            < div className="accountbg" ></div>
            <div
                style={{
                    maxHeight: width() === 'mobile' ? (window.innerHeight + 10) + 'px' : '16em',
                    backgroundColor: width() === 'mobile' ? '#ffffff' : '',
                    overflowY: width() === 'mobile' ? 'auto' : 'hidden',
                    margin: "auto",
                    marginTop: "30px",
                    padding: "20px",
                    width: width() === 'mobile' ? "" : "400px"
                }}
                className={`col-md-5 mx-auto card`}>
                {novaSenhaOk &&
                    <div className="box" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <div>
                            <p>
                                <strong>Senha alterada com sucesso...</strong>
                            </p>
                        </div>
                        <div>
                            {/* <a href="">Novo Login</a> */}
                            <Button color="success" onClick={Redireciona} href="https://simulador.incontadigital.com.br/login">Novo Login</Button>

                            {/* <Button color="success" onClick={Redireciona} >Novo Login</Button> */}
                        </div>
                    </div>
                }
                {!novaSenhaOk &&
                    <form>
                        <div>
                            <div className="form-group">
                                <label
                                    style={{ fontSize: '1em' }}
                                    htmlFor="password">Nova Senha</label>
                                <input
                                    type="password"
                                    className={`form-control`}
                                    id="password"
                                    name="password"
                                    required
                                    autoFocus={true}
                                    placeholder="Digite a nova Senha"
                                    value={senha}
                                    onChange={(e) => setSenha(e.currentTarget.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label
                                    style={{ fontSize: '1em' }}
                                    htmlFor="password">Nova Senha</label>
                                <input
                                    type="password"
                                    className={`form-control`}
                                    id="password"
                                    name="password"
                                    required
                                    placeholder="Redigite a nova Senha"
                                    value={novaSenha}
                                    onChange={(e) => setNovaSenha(e.currentTarget.value)}
                                />
                            </div>


                            <div className="text-right">
                                <button
                                    className="btn btn-success w-md waves-effect waves-light"
                                    onClick={submit}
                                >
                                    Enviar
                        </button>
                            </div>

                        </div>
                    </form>
                }
            </div>
        </>
    )
};

function mapStateToProps(state) {
    return {
        user: state.dados.user,
        proposta: state.dados.proposta,
    }
}

function mapActionToProps(dispatch) {
    return {
        alterarUser(novoUser) {
            dispatch(setUser(novoUser))
        },
    }
}

export default connect(
    mapStateToProps,
    mapActionToProps
)(Form)
