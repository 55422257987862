import React, { useState, useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { uriList } from '../../../../helpers/config';
import { sendData } from "../../../../helpers/auth";
import { formatCurrency } from '../../../../helpers/general';
import ModalContent from '../../../layout/ModalContent';
import { connect } from 'react-redux'
import "./index.css"
import * as moment from 'moment'

const AntiFraude = ({ ...props }) => {
  const [data, setData] = useState([])
  const [res, setRes] = useState(props.res && props.res.message)
  const [modal, setModal] = useState('');

  function handleClose(e, close) {
    if (e)
      e.preventDefault()
    if (props.onCloseEv)
      props.onCloseEv(close)
  }

  const handleModal = () => {
    setModal(!modal)
  }

  const ExibirRes = ({ json }) => {
    if (!json)
      return
    const highlightedJSON = jsonObj =>
      Object.keys(jsonObj).map(key => {
        const value = jsonObj[key];
        let valueType = typeof value;
        const isSimpleValue =
          ["string", "number", "boolean"].includes(valueType) || !value;
        if (isSimpleValue && valueType === "object") {
          valueType = "null";
        }
        return (
          <div key={key} className="line">
            <span className="key">{key}:</span>
            {isSimpleValue ? (
              <span className={valueType}>{`${value}`}</span>
            ) : (
              highlightedJSON(value)
            )}
          </div>
        );
      });
    return <div className="json">{highlightedJSON(json)}</div>;
  };

  useEffect(() => {
    console.log(props.res)
    sendData({ uri: uriList('listarAntifraude'), content: { uuid: props.proposta }, method: "POST" })
      .then(res => {
        let _res = res.observacoes.split(';')
        setData(_res)
        setRes(JSON.parse(res.retorno))
      })
      .catch(e => {
        console.log("Erro: ", e)
        return false;
      });

  }, []);

  function Content() {
    return (
      <div>
        {!modal && <ListGroup>
          <ListGroup.Item style={{ backgroundColor: "#E8E8E8", color: "#636363" }}>
            <strong>Cliente:</strong> {props.detalhes ? props.detalhes.nome + " - " + props.detalhes.cpf + " - " + props.detalhes.celular : ""}
            <br />
            <strong>Status Atual:</strong> {props.detalhes ? props.detalhes.posicao : ""}
            <br />
            {/* <strong> Data:</strong> {props.detalhes ? new Date(props.detalhes.status_data).toLocaleDateString() : ""} */}
            <strong> Data:</strong> {props.detalhes ? moment(props.detalhes.status_data).format('DD/MM/YYYY') : ""}
            <br />
            <strong>Valor R$:</strong> {props.detalhes ? formatCurrency(props.detalhes.valor_liquido) : ""}
            <strong> Parcela R$:</strong> {props.detalhes ? formatCurrency(props.detalhes.valor_parcelas) : ""}
            <strong> Prazo:</strong> {props.detalhes ? props.detalhes.prazo : ""}
            <br />
          </ListGroup.Item>
          <ListGroup.Item style={{ backgroundColor: "#F3F3F3", color: "#979797" }}><h4>Observações:</h4></ListGroup.Item>
          {data && data.map((option, i) => (
            option && option.trim() && <ListGroup.Item key={i} style={{ color: "#636363" }}>
              {/* <div>
                Data: {new Date(option.created_at).toLocaleDateString()}
              </div>
              <br /> */}
              <div>
                <div><strong>{option}</strong></div>
              </div>
            </ListGroup.Item>))
          }
        </ListGroup>
        }
        {modal && <ExibirRes json={res} />}
      </div>
    )
  }
  return (
    <>
      <ModalContent title="AntiFraude" onCloseEv={handleClose} size="lg" concorda={false} showInfo={handleModal} >
        <Content />
      </ModalContent>

    </>
  )
}


function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  }
}

export default connect(
  mapStateToProps
)(AntiFraude)
