import React from 'react';

const NoMatch = ({ ...props }) => (
    <div
    style={{ padding: '30px 30px' }}>
        <h4
        className='display-3 text-info text-center font-weight-bold'
        style={{ marginBottom: '15px' }}>{props.title}</h4>
        
        <h1
        className="font-weight-bold display-4 lead">
            Erro 404 !
        </h1>
        <p
        className='lead'>
            Endereço não encontrado!
        </p>
    </div>
);

export default NoMatch;
