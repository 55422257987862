import React from "react";

import { useDashboardData } from './hooks/DashboardDataContext'
import { convertToBrl } from './convertNumbers'

import style from './dashboard.module.css'

export default function ProductionInfo() {
    const { productionsAmount } = useDashboardData()

    return (
        <div className={`${style.productionInfoContainer}`}>
            <div className={`${style.productionInfoContent}`}>
                <span className={style.titleProduction}>Saldo Pago</span>
                <span className={style.valueProduction}>
                    {productionsAmount && convertToBrl(productionsAmount.balancePaid || 0)}
                </span>
            </div>
            <div className={`${style.productionInfoContent}`}>
                <span className={style.titleProduction}>
                    Valor Base
                </span>
                <span className={style.valueProduction}>
                    {productionsAmount && convertToBrl(productionsAmount.grossProductionMonth || 0)}
                </span>
            </div>
            <div className={`${style.productionInfoContent}`}>
                <span className={style.titleProduction}>Total Geral</span>
                <span className={style.valueProduction}>
                    {productionsAmount &&
                        convertToBrl(productionsAmount.netProduction || 0)}
                </span>
            </div>
            <div className={`${style.productionInfoContent}`}>
                <span className={style.titleProduction}>Projeção Saldo</span>
                <span className={style.valueProduction}>
                    {productionsAmount &&
                        convertToBrl(productionsAmount.averageBalance || 0)}
                </span>
            </div>
        </div>
    )
}
