import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';

class MenuItemWithChildren extends Component {

  constructor(props) {
    super(props);
    this.SubMenuClick = this.SubMenuClick.bind(this);
  }

  SubMenuClick = (event) => {

    event.preventDefault();
    event.nativeEvent.preventDefault();
    const parent = event.currentTarget.parentElement;
    if (parent) parent.classList.toggle('nav-active');
    return false;
  }
  render() {
    const item = this.props.item || {};

    return (
      <React.Fragment>
        <li className="has_sub">
          <Link className={item.class} to={item.url} onClick={this.SubMenuClick}>
            {item.icon && <i className={item.icon} ></i>}
            <span>  {item.label}
              {item.badgeclass ?
                <span className={item.badgeclass}>{item.badgevalue}</span> :
                <span className="float-right"><i className="mdi mdi-chevron-right"></i></span>}
            </span>
          </Link>
          <ul className="list-unstyled">
            {item.children.map((child, i) => {
              if (child.children) {
                return <MenuItemWithChildren item={child} key={i} />
              } else {
                return <li key={i}> <MenuItem item={child} /></li>
              }
            })}
          </ul>
        </li>
      </React.Fragment>
    );
  }
}

export default MenuItemWithChildren;
















