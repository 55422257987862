import React, { useState } from "react";
import { formatCurrency } from "../helpers/general";
import { width } from "../helpers/general";
import { connect } from "react-redux";
import { Buttons } from "../components/layout";

function Status({ ...props }) {
  const [data, setData] = useState(props.detalhes);

  function onClick(e) {
    e.preventDefault();
    props.toggleStep();
  }

  return (
    <>
      <div
        style={{
          paddingBottom: !props.preencherCli && !props.modal ? "20px" : "0px",
          backgroundColor: "rgb(236, 236, 236)",
          marginTop: "50px",
          fontFamily: "Roboto Mono",
        }}
        className="col-md-8 m-auto custom-status card"
      >
        <i
          className="text-justify"
          style={{
            fontSize: "19px",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          Confira abaixo o resumo da sua proposta e a seguir complete as
          informações cadastrais. Para isso, tenha em mãos seus documentos
          pessoais.
        </i>

        {data && (
          <div
            className={width() === "mobile" ? "p-1" : ""}
            style={{
              fontSize: "20px",
              border: "1px solid rgba(0, 0, 0, .125)",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <table
              style={{
                width: "100%",
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "10px 20px", width: "33%" }}>
                    <h6 className="no-margin">Valor do Crédito</h6>
                    <h6 className="font-weight-bold m-0">
                      R$ {format(data?.valor_liquido)}
                    </h6>
                  </td>
                  <td style={{ padding: "10px 20px", width: "33%" }}>
                    <h6 className="no-margin">Prazo</h6>
                    <h6 className="font-weight-bold m-0">
                      {data?.prazo} {width() !== "mobile" && "meses"}
                    </h6>
                  </td>
                  <td style={{ padding: "10px 20px", width: "33%" }}>
                    <h6 className="no-margin">
                      {data?.tipo_convenio === "FGTS"
                        ? "Total das Parcelas"
                        : "Valor das Parcelas"}
                    </h6>
                    <h6 className="font-weight-bold m-0">
                      R$ {format(data?.valor_parcelas)}
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px 20px", width: "33%" }}>
                    <h6 className="no-margin">IOF</h6>
                    <h6 className="font-weight-bold m-0">
                      R$ {format(data?.iof)}
                    </h6>
                  </td>
                  <td style={{ padding: "10px 20px", width: "33%" }}>
                    <h6 className="no-margin">Juros Mensal</h6>
                    <h6 className="font-weight-bold m-0">
                      {format(data?.taxa_juros_mensal)} %
                    </h6>
                  </td>
                  <td style={{ padding: "10px 20px", width: "33%" }}>
                    <h6 className="no-margin">Juros Anual</h6>
                    <h6 className="font-weight-bold m-0">
                      {format(data?.taxa_juros_anual)} %
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div style={{ marginTop: "20px", width: "100%", padding: "0 20px" }}>
        <Buttons
          submit={() => props.toggleStep(1)}
          goBack={() => props.toggleStep(-1)}
        />
      </div>
    </>
  );
}

const format = (value) => {
  value = formatCurrency(value);
  return value;
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    user: state.dados.user,
    alterarProposta: state.dados.alterarProposta,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(Status);

// import React, { useState, useEffect } from "react";
// import { formatCurrency } from "../helpers/general";
// import { width, } from "../helpers/general";
// import { connect } from "react-redux";
// import { Buttons } from "../components/layout";

// function Status({ ...props }) {
//   const [data, setData] = useState(props.detalhes)

//   function onClick(e) {
//     e.preventDefault();
//     props.toggleStep();
//   }

//   return (
//     <>
//       <div
//         style={{
//           paddingBottom: !props.preencherCli && !props.modal ? "20px" : "0px",
//           backgroundColor: "rgb(236, 236, 236)",
//           marginTop: "50px",
//           fontFamily: "Roboto Mono"
//         }}
//         className="col-md-8 m-auto custom-status card"
//       >
//         <i className="text-justify" style={{ fontSize: "19px", marginTop: "20px", marginBottom: "30px" }}>
//           Confira abaixo o resumo da sua proposta e a seguir complete as informações cadatrais. Para isso tenha em mãos seus documentos pessoais.
//         </i>

//         {data &&
//           <div className={width() === "mobile" ? "p-1" : ""}
//             style={{ fontSize: "20px", border: "1px solid rgba(0, 0, 0, .125)", display: "flex", justifyContent: "center", alignItems: "center" }}>
//             <div style={{ width: "100%", padding: "0 20px" }}>
//               <div
//                 className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
//                 style={{
//                 }}
//               >
//                 <div className="col-md-4 col-4 px-0">
//                   <h6 className="no-margin">Valor do Crédito</h6>
//                   <h6 className="font-weight-bold m-0">
//                     R$ {format(data?.valor_liquido)}
//                   </h6>
//                 </div>
//                 <div className="col-md-4 col-4 px-0">
//                   <h6 className="no-margin">Prazo</h6>
//                   <h6 className="font-weight-bold m-0">
//                     {data?.prazo} {width() !== "mobile" && "meses"}
//                   </h6>
//                 </div>

//                 <div className="col-md-4 col-4 px-0">
//                   <h6 className="no-margin">
//                     {data?.tipo_convenio == "FGTS"
//                       ? "Total das Parcelas"
//                       : "Valor das Parcelas"}
//                   </h6>
//                   <h6 className="font-weight-bold m-0">
//                     R$ {format(data?.valor_parcelas)}
//                   </h6>
//                 </div>
//               </div>

//               <div
//                 style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
//                 className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
//               >
//                 <div className="col-md-4 col-4 px-0">
//                   <h6 className="no-margin">IOF</h6>
//                   <h6 className="font-weight-bold m-0">
//                     R$ {format(data?.iof)}
//                   </h6>
//                 </div>

//                 <div className="col-md-4 col-4 px-0">
//                   <h6 className="no-margin">JurosM</h6>
//                   <h6 className="font-weight-bold m-0">
//                     {format(data?.taxa_juros_mensal)} %
//                   </h6>
//                 </div>

//                 <div className="col-md-4 col-4 px-0">
//                   <h6 className="no-margin">JurosA</h6>
//                   <h6 className="font-weight-bold m-0">
//                     {format(data?.taxa_juros_anual)} %
//                   </h6>
//                 </div>
//               </div>
//             </div>
//           </div>}
//       </div>

//       <div style={{ marginTop: "20px", width: "100%", padding: "0 20px" }}>
//         <Buttons submit={() => props.toggleStep(1)} goBack={() => props.toggleStep(-1)} />
//       </div>
//     </>
//   );
// }

// const format = (value) => {
//   value = formatCurrency(value);

//   return value;
// };

// function mapStateToProps(state) {
//   return {
//     proposta: state.dados.proposta,
//     user: state.dados.user,
//     alterarProposta: state.dados.alterarProposta,
//     detalhes: state.dados.detalhes,
//   };
// }


// export default connect(mapStateToProps,)(Status);
