import React, { useState, useEffect } from "react";
import ModalContent from "../../../components/layout/ModalContent";
import ValidarCodigo from "../../../components/form/simulator/validarCodigo";
import { Link } from "react-router-dom";
import Logo from "../../../images/logo-inteira.png";
import LogoTransp from "../../../assets/logo-transparente1.png";
import { width } from "../../../helpers/general";
import { setAlterarProposta, setProposta } from "../../../store/actions/dados";
import { connect } from "react-redux";

import "./terms.css";

let _html = `<div class="WordSection1"> 

<p class="MsoNormal" style="text-align:justify; font-weight:bold; font-size:15pt">Solicitamos a minuciosa
    leitura do presente documento antes de aceitá-lo, em caso de dúvida utilize um
    dos canais de atendimento para esclarecimentos.</p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-14pt;
mso-list:l2 level1 lfo4">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:Arial"><span
      style="mso-list:Ignore">a)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">TERMOS E CONDIÇÕES DE USO<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-14pt;
mso-list:l2 level1 lfo4">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:Arial"><span
      style="mso-list:Ignore">b)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">TERMO DE CONSENTIMENTO LGPD - POLÍTICA DE PRIVACIDADE<o:p></o:p></span>
</p>


<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:14pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
      <o:p>&nbsp;</o:p>
    </span></b></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:14pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">TERMOS E CONDIÇÕES DE USO.<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">Solicitamos a minuciosa
    leitura do presente documento antes de aceitá-lo, em caso de dúvida utilize um
    dos canais de atendimento para esclarecimentos.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1 – FUNCIONALIDADES E ACESSO
      AO INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.1 Para fazer uso do INCONTA CRÉDITO
    DIGITAL, deverá ser criada uma conta de titularidade do proponente, sendo este
    o seu cadastro em nosso sistema.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.2 – O acesso ocorrerá
    mediante preenchimento de senha e login os quais serão de uso pessoal,
    portanto, não se deve repassar a terceiros. <o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.3 – Estão aptos a criarem a
    conta no INCONTA CRÉDITO DIGITAL pessoas maiores de 18 (dezoito) anos e
    plenamente dotadas de exercer atos da vida civil para celebração de negócios
    jurídicos.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.4 – Este cadastro deverá ser
    aprovado pelo INCONTA CRÉDITO DIGITAL mediante avaliações e critérios próprios
    através de análise dos dados informados.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.5 - Caso os dados informados
    sejam insuficientes ou divergentes da realidade o cadastro não será concluído.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.6- Será de atribuição
    exclusiva do INCONTA CRÉDITO DIGITAL a possibilidade de alterar, criar ou
    extinguir critérios de elegibilidade de utilização e de acesso ao sistema bem
    como de contratação de quaisquer produtos nele oferecidos.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.7- O INCONTA CRÉDITO DIGITAL
    exercerá o direito de recusar, suspender e cancelar a conta do usuário que
    utilizar da funcionalidade de forma diversa de sua real finalidade, bem como
    daquele que agir de forma fraudulenta, afrontando o aparato legal que rege os
    negócios jurídicos do País no intuito de violar e lesar o INCONTA CRÉDITO
    DIGITAL, [financeira] e qualquer outro elo da relação que de boa-fé irá
    agir. A relação deverá ser norteada nos princípios e na legislação civil
    pátria, dos termos e condições de uso, política de privacidade e contratos
    entabulados com [financeira].<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.8- O usuário irá criar o seu
    acesso e conta mediante preenchimento dos dados cadastrais solicitados e do
    envio da documentação pessoal e foto (selfie) no formato em que for solicitado
    pelo INCONTA CRÉDITO DIGITAL no ato do cadastro.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.9 – A contratação de
    qualquer produto será celebrada mediante a assinatura eletrônica da cédula de
    crédito bancário d[financeira], caso haja necessidade de celebração de
    outro documento o usuário será informado previamente para conclusão da
    operação.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.11 – Os produtos e serviços
    disponibilizados pelo INCONTA CRÉDITO DIGITAL, de propriedade da RBCB, estão
    respaldados pela Resolução 3.954/2011 do Banco Central do Brasil.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.12<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;
    </span>– Política de crédito e condições
    operacionais são atribuições privativas da Instituição Financeira, no INCONTA
    CRÉDITO DIGITAL trata-se d[financeira], as quais por força contratual e do
    Banco Central do Brasil o Correspondente no País não possui autonomia para tal.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1.13 – Toda simulação
    realizada estará sujeita a análise de crédito a ser realizada exclusivamente
    pel[financeira], o envio da simulação pelo usuário não caracteriza, em
    hipótese nenhuma, aceite por parte do INCONTA CRÉDITO DIGITAL, tampouco da
    instituição financeira correspondida, trata-se apenas de uma expectativa de
    negócio, a viabilidade da operação demandará análises. <o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.<span style="mso-spacerun:yes">&nbsp; </span>DAS
      OBRIGAÇÕES DOS PROPONENTES<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.1 –<b> </b>A Utilização do
    sistema deverá ocorrer única e exclusivamente para a finalidade<b> </b>a qual
    se destina. <o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.2 – O usuário compromete-se
    a utilizá-la sob os pilares da ética, moralidade e boa-fé que deve reger todas
    as relações comerciais no País;<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.3 – Informar corretamente
    seus dados cadastrais, bem como referências pessoais, números de telefones e
    e-mails para contatos que serão feitos pela equipe de atendimento da RBCB.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.4 - Em caso de dados
    divergentes e/ou falsos, o INCONTA CRÉDITO DIGITAL excluirá o cadastro do
    usuário, sem prejuízo de demais medidas a serem tomadas, no intuito de
    resguardar a segurança da operação e de terceiros de boa-fé envolvidos.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.5 – Login e senhas
    cadastradas são pessoais e intransferíveis. Em caso de repasse a terceiros o
    usuário se responsabilizará por sua conduta, caso suspeite que o a senha foi
    violada, deverá trocá-la imediatamente. A Senha deverá ser considerada forte
    para resguardar os interesses do Usuário. Recomenda-se a não utilização de
    senhas utilizadas em outros sites.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.6- Reconhecer todos os
    acessos e operações contratadas, intermediadas pelo INCONTA CRÉDITO DIGITAL e
    concluídas pel[financeira], após todos os procedimentos realizados,
    validados e com a anuência do usuário, de forma incontestável e irretratável, inclusive
    em caso de uso indevido ou de divulgação de dados, informações e cadastro para
    terceiros. Sob pena de em caso de questionamento na esfera administrativo e
    judicial, acarretarem litigância de má-fé, nos moldes insculpidos pelos artigos
    73 e seguintes do Código de Processo Civil.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.7 – O usuário concederá,
    durante o uso do sistema, o acesso a sua geolocalização ao INCONTA CRÉDITO
    DIGITAL, sendo uma das formas de garantir a segurança da operação pactuada.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.8 - Serão feitos contatos
    telefônicos, via <i>e-mail</i> e <span class="SpellE"><i>sms</i></span>, através
    dos contatos informados no cadastro prévio, acerca de informações de interesse
    do usuário, será vedado que um terceiro fale por ele, sendo qualquer contato
    feito apenas com o titular dos dados apostados. Logo, durante o trâmite da
    proposta o usuário deve ficar atento aos seus dispositivos, pois o andamento do
    feito se dará mediante a interação.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.9 – O usuário deverá ter
    atenção quanto a pessoas que de má-fé podem vir a falar em nome do INCONTA
    CRÉDITO DIGITAL, da RBCB e d[financeira] no intuito de ofertar produtos,
    prestar esclarecimentos entre outros. Em caso de dúvidas favor entrar em
    contato, imediatamente, para certificar acerca da pessoa e/ou informação
    coibindo fraudes, propostas infundadas e tentativas de ataques de <span
      class="SpellE">cibercriminosos</span>, via <span class="SpellE">phishing</span>
    entre outros.<i>
      <o:p></o:p>
    </i></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.10 – É vedado o uso da marca
    e nomenclatura INCONTA CRÉDITO DIGITAL, RBCB e Financeira BRB por terceiros. O
    usuário deve respeitar os direitos de propriedade intelectual. Além de não
    proceder com a tentativa de acessar bancos de dados, código fonte, mecanismos
    de proteção, aproveitar de forma ilegal de dados, informações, estrutura de
    software, sob pena de configuração de delito tipificado pelo Código Penal.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.11- Caso o usuário, mediante
    ferramentas ardilosas, <span class="SpellE">independente</span> de sua forma,
    causar danos ao INCONTA CRÉDITO DIGITAL, RBCB, Financeira BRB e terceiros irá
    arcar com a indenização e todo o prejuízo causado ao lesado, integrando a lide
    na condição de polo passivo de todo e qualquer dano que tenha sido causado
    através de sua conta de acesso, incluindo custas processuais, honorários
    advocatícios e demais ônus. <o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.11 – O usuário tem acesso ao
    simulador de crédito oferecido pelo INCONTA CRÉDITO DIGITAL o qual não
    significa efetivação nem promessa de crédito, podendo ou não ser elegível a
    contratação de determinado produto ofertado, o qual depende de critérios únicos
    e exclusivamente determinados pela Instituição Correspondida. <o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.12 – O INCONTA CRÉDITO
    DIGITAL se resguardará ao não encaminhar operações com suspeitas de adulteração
    de dados e aquelas que sejam objetos de fraudes podendo cancelar, de imediato,
    o cadastro do usuário sem prejuízo de outras medidas.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.13 - Descumprimento de
    qualquer obrigação prevista neste instrumento contratual poderá acarretar a
    suspensão ou exclusão do aceso ao INCONTA CRÉDITO DIGITAL, sem aviso prévio.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.14 – O usuário foi
    esclarecido sobre os valores cobrados pela BRB CFI – Crédito, Financiamento e
    Investimentos, resultam apenas do CET – Custo Efetivo Total, da operação do
    empréstimo consignado ora pleiteado, inexistindo qualquer <span class="GramE">outra<span
        style="mso-spacerun:yes">&nbsp; </span>cobrança</span> de valores diversos do CET.
    Não será cobrado nenhuma tarifa, taxa, comissionamento, depósito prévio do
    usuário.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.15 – O Usuário isenta o
    INCONTA CRÉDITO DIGITAL e a detentora RBCB de quaisquer questionamentos quanto
    a formalização de propostas efetuadas em sua conta de acesso, bem como das
    condições negociais celebradas, tendo em vista consistir em atribuição da
    Instituição Financeira Correspondida.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.16 – O Usuário reconhece que
    toda comunicação realizada por números de telefone, e-mails, correspondências
    enviadas ao endereço cadastrados pelo USUÁRIO no sistema será considerada
    válida e recebida para qualquer assunto pertinente a utilização do INCONTA
    CRÉDITO DIGITAL, bem como das simulações e propostas realizadas, dando-se por
    eficazes e válidas.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3 – RESPONSABILIDADE RBCB E
      INCONTA CRÉDITO DIGITAL<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.1 – Agir dentro de suas
    atribuições na condição de Correspondente no País, nos moldes da Resolução
    3.954/2011 do Banco Central do Brasil, na oferta e intermediação de produtos e
    linhas de crédito disponibilizados de forma ética, legal e buscando suprir as
    necessidades de seus usuários.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.2 – A RBCB, detentora dos
    direitos do INCONTA CRÉDITO DIGITAL, buscará sempre as melhores condições
    negociais aos seus clientes, bem como empreenderá os melhores esforços para a
    disponibilização de uma ferramenta intuitiva e assertiva e de resguardar o
    pleno funcionamento da ferramenta, em caso de oscilações e indisponibilidade
    buscará em tempo hábil retornar <span class="GramE">a</span> normalidade.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.3 – Em casos de força maior,
    desastres naturais, calamidade pública a ferramenta poderá ficar com uso
    comprometido.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.4 – Procederá com a
    constante atualização da ferramenta a fim de acompanhamento das inovações
    tecnológicas no tocante a disponibilização de funcionalidades e segurança.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.5 – Manter um canal ativo
    para contato de esclarecimentos de dúvidas e acompanhamento de propostas.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.6 – Em caso de
    indisponibilidade do INCONTA CRÉDITO DIGITAL o usuário não tem o direito de
    exigir o pronto restabelecimento nem mesmo pleitear eventual reparação de
    danos, indenizações ou qualquer outro pleito de natureza similar independente
    da motivação e do período que perdurar a indisponibilidade visto que é de
    interesse mútuo a reativação. O INCONTA CRÉDITO DIGITAL não garante que as
    funcionalidades atendam todos os anseios do Usuário nem mesmo a ininterrupta
    utilização.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.7 – Comunicados prévios
    quanto a grandes momentos de instabilidade e indisponibilidade serão realizados
    pela equipe técnica do INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.8 – Não é de
    responsabilidade da RBCB, INCONTA CRÉDITO DIGITAL nem Financeira BRB quaisquer
    problemas, <i>bugs</i>, <span class="SpellE">phishing</span>, funcionamento
    anormal que ocorrerem nos dispositivos do usuário resultante do uso
    convencional direto ou indireto do INCONTA CRÉDITO DIGITAL, bem como qualquer
    dano ocasionado por ações de terceiros mediante ataque de <i>crackers, </i>instalações
    de softwares maliciosos, que venham a danificar ativos, corromper informações e
    acessos durante a utilização do INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.9 – Seguirá o horário
    oficial de Brasília – DF.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">4.DA PRIVACIDADE DE DADOS<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">4.1 –<b> </b>A RBCB e o
    INCONTA CRÉDITO DIGITAL possuem um documento apartado responsável por detalhar
    o uso, proteção e segurança dos dados de seus usuários, para acessá-lo basta
    clicar no link ao lado direito do rodapé. Os dados têm como destinação a
    concessão de crédito consignado para usuários elegíveis que utilizam da
    ferramenta. Os dados informados serão remetidos à Financeira BRB a qual dentro
    de sua política de crédito irá ou não autorizar a finalização da operação
    intermediada via INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">4.2 – O usuário autoriza
    expressamente o recebimento de informações acerca de educação financeira,
    orçamento familiar, planilhas, conteúdos e notícias de temas financeiros, além
    de oferta de novos produtos personalizados; refinanciamentos, portabilidade de
    crédito ou uma utilização de margem livre.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5. DISPOSIÇÕES GERAIS<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.1 – O presente instrumento
    poderá ter alterações, atualizações, melhorias, acréscimos e subtração de
    dispositivos, logo reserva-se o direito de fazê-los a qualquer momento,
    mediante sua conveniência. O usuário deverá acompanhar modificações que
    porventura ocorram.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.2- Telefones de contato, 08h
    às 18h, segundas às sextas-feiras, à exceção de feriados, através do (62) 3223-3215.
    Com atendimento presencial em Avenida Tocantins, n° 375, Setor Central, Goiânia
    – GO, 74015-010, dúvidas durante a simulação no endereço eletrônico em </span><a
    href="mailto:contato@incontadigital.com.br%20">contato@incontadigital.com.br</a><span
    style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"> e </span><a
    href="mailto:atendimento@incontadigital.com.br"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">atendimento@incontadigital.com.br</span></a><span
    style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"> para
    assuntos gerais. SAC BRB 0800.648.6161, SAC – PNE 0800.648.6162, Ouvidoria BRB
    0800.642.1105<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.3 – Os usuários poderão
    utilizar dos canais de atendimento para informar acerca de qualquer fato tido
    como anormal no INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.4 – O usuário reconhece os
    presentes termos e condições de uso como um dos instrumentos jurídicos
    responsável por reger a utilização e contratação das funcionalidades do INCONTA
    CRÉDITO DIGITAL, com prazo de vigência indeterminado. Caso algum dispositivo
    constante nestes termos e condições seja julgada como inaplicável, sem eficácia
    ou validade, as demais disposições e instrumentos jurídicos celebrados continuarão
    em pleno vigor.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.5 – Qualquer tolerância da
    parte do INCONTA CRÉDITO DIGITAL no quesito de descumprimento de alguma
    cláusula ou instrumento não implica em exigibilidade das obrigações do usuário
    a qualquer tempo.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.6 – A RBCB poderá, a seu
    exclusivo critério, suspender, modificar, atualizar ou até encerrar as
    atividades do INCONTA CRÉDITO DIGITAL, mediante comunicação prévia, salvo casos
    de força maior.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.7 – O INCONTA CRÉDITO
    DIGITAL não se responsabiliza por anúncios da Instituição Financeira Correspondida,
    bem como por qualquer decisão financeira tomada pelo usuário e intermediada
    pelo sistema.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.8- Isenta-se o INCONTA
    CRÉDITO DIGITAL e a RBCB de responsabilidade em virtude de casos fortuitos e de
    força maior, nos termos do artigo 393 do Código Civil, bem como de eventuais
    prejuízos sofridos pelo Usuário em razão de falhas sistêmicas que independam do
    INCONTA CRÉDITO DIGITAL, o Usuário deve manter conexão segura à internet.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.8 – Ao contratar uma nova
    operação via INCONTA CRÉDITO DIGITAL o usuário expressamente para reconhece
    todas as operações vigentes e vencidas entabuladas com [financeira],
    intermediada pelo INCONTA CRÉDITO DIGITAL/RBCB.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">6 – DO FORO <o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">6.1 – Elege-se o Foro da
    Comarca do Usuário para dirimir eventuais litígios e assuntos controversos que
    venham a ocorrer em razão deste instrumento, com excepcionalidades de
    competência pessoal, territorial e/ou funcional, de acordo com a Lei.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">7 – LEI A SER APLICADA E
      IDIOMA<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">7.1 – A legislação brasileira
    é a responsável por reger as relações jurídicas entabuladas pelo presente
    instrumento contratual em língua portuguesa.<o:p></o:p></span></p>

<p class="MsoNormal" align="center" style="text-align:center"><b><span
      style="font-size:14pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
      <o:p>&nbsp;</o:p>
    </span></b></p>

<p class="MsoNormal"><b><span style="font-size:14pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">TERMO DE CONSENTIMENTO LGPD- POLÍTICA DE
      PRIVACIDADE.<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify;line-height:115%"><span
    style="font-size:10pt;line-height:115%;font-family:&quot;Arial&quot;,sans-serif">Declaro
    que fui orientado (a) de forma clara sobre o tratamento de dados pessoais pela
    Rede Brasileira de Correspondentes e Business LTDA e INCONTA CRÉDITO DIGITAL,
    conforme as disposições abaixo:<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">O presente instrumento integra
    o aparato jurídico que abarca o relacionamento comercial entre INCONTA CRÉDITO
    DIGITAL e RBCB e o Usuário tendo como principal razão a elucidação e
    detalhamento de regras acerca da obtenção, uso e armazenamento de dados e
    informações obtidas no sistema de inclusão de propostas digitais, tendo toda a
    coleta e destinação de dados devidamente especificadas ao usuário e à luz do
    ordenamento jurídico pátrio.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">O usuário para utilizar e
    acessar os serviços disponibilizados pelo INCONTA CRÉDITO DIGITAL deverá fazer
    uma minuciosa leitura desta política. Recomendamos que em caso de dúvida nos
    consulte para prestar os devidos esclarecimentos pois o aceite pelo usuário
    implica em expressa de concordância pelo usuário.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify;line-height:115%"><span
    style="font-size:10pt;line-height:115%;font-family:&quot;Arial&quot;,sans-serif">Em
    observância à Lei n° 13.709/18 – Lei Geral de Proteção de Dados Pessoais e
    demais normativas aplicáveis sobre a proteção de Dados Pessoais, manifesto-me
    de forma informada, livre expressa e consciente, no sentido de autorizar a RBCB
    e INCONTA CRÉDITO DIGITAL a realizarem o tratamento de meus Dados Pessoais
    (informações relacionadas a pessoa natural identificada ou identificável) para
    finalidades de acordo com as condições aqui estabelecidas.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
      <o:p>&nbsp;</o:p>
    </span></b></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">1 - COLETA DE INFORMAÇÕES E
      DADOS<o:p></o:p></span></b></p>

<p class="MsoListParagraphCxSpFirst" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify;text-indent:-19.8pt;mso-list:l0 level2 lfo1">
  <!--[if !supportLists]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">1.1<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">–<b>
    </b>A
    interação com o sistema pedirá alguns dados para acesso ao INCONTA CRÉDITO
    DIGITAL e aos produtos disponibilizados. Caso seja interesse do usuário, deverá
    fornecer informações e dados voluntariamente ao efetuar o cadastro e na
    solicitação de algum produto e/ou serviço disponibilizado.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify;text-indent:-19.8pt;mso-list:l0 level2 lfo1">
  <!--[if !supportLists]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">1.2<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">Serão
    coletadas as seguintes informações, vale ressaltar que outros dados poderão ser
    solicitados a qualquer tempo;<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify"><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">CPF<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Data de nascimento<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Estado Civil<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Sexo<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Nome da mãe<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">CEP<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Tipo do Logradouro<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Logradouro<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Número<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Complemento<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Bairro<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Cidade<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">UF<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Telefone<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">E-mail<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Tipo de documento<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Número do
    documento<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Órgão expedidor<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">UF de expedição<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Data de emissão<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Banco<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Agência <o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Conta<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Tipo de conta<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Fonte Pagadora<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Remuneração<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Renda Líquida<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-14pt;line-height:
normal;mso-list:l3 level1 lfo2">
  <!--[if !supportLists]--><span style="font-size:
9.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:9.0pt">Margem Consignável<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="line-height:normal"><span style="font-size:9.0pt">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoListParagraphCxSpMiddle" style="line-height:normal"><span style="font-size:9.0pt">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify;text-indent:-19.8pt;mso-list:l0 level2 lfo1">
  <!--[if !supportLists]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">1.3<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">– A
    responsabilidade pela veracidade e fidedignidade de informações e dados
    coletados, bem como por suas atualizações é única e exclusivamente do Usuário
    que deverá reconhecer, por verdadeiro, tudo aquilo que informar, sob à luz da
    boa-fé que rege as relações comerciais no País.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify;text-indent:-19.8pt;mso-list:l0 level2 lfo1">
  <!--[if !supportLists]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">1.4<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><span
      style="mso-spacerun:yes">&nbsp;</span>A utilização dos serviços e funcionalidades
    constantes no INCONTA CRÉDITO DIGITAL destinam-se única e exclusivamente a
    maiores de 18 (dezoito) anos de idade e capazes, nos termos da lei, dotadas de
    higidez mental plena para realização de todos os atos da vida civil.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify;text-indent:-19.8pt;mso-list:l0 level2 lfo1">
  <!--[if !supportLists]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">1.5<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">O
    INCONTA CRÉDITO DIGITAL poderá utilizar-se de Cookies, o usuário deverá
    configurar o seu navegador de acesso à internet para bloquear, caso assim
    deseje, no entanto, algumas funcionalidades e ferramentas poderão ser
    limitadas.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify"><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify;text-indent:-19.8pt;mso-list:l0 level1 lfo1">
  <!--[if !supportLists]--><b><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">2<span
          style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span></b>
  <!--[endif]--><b><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">–
      DO
      USO, TRATAMENTO E FINALIDADE DOS DADOS COLETADOS<o:p></o:p></span></b>
</p>

<p class="MsoListParagraphCxSpMiddle" style="margin-left:19.8pt;mso-add-space:
auto;text-align:justify"><b><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">
      <o:p>&nbsp;</o:p>
    </span></b></p>

<p class="MsoListParagraphCxSpLast" style="margin-left:19.8pt;mso-add-space:auto;
text-align:justify;text-indent:-19.8pt;mso-list:l0 level2 lfo1">
  <!--[if !supportLists]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial"><span style="mso-list:Ignore">2.1<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">– Os
    dados e informações coletadas têm como finalidade identificar, autenticar e
    validar o usuário do INCONTA CRÉDITO DIGITAL;<o:p></o:p></span>
</p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.2- Ofertar seus produtos e
    serviços de forma mais segura e da melhor maneira possível, novas operações de
    crédito, renovação e portabilidade de dívidas.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify;line-height:115%"><span
    style="font-size:10pt;line-height:115%;font-family:&quot;Arial&quot;,sans-serif">2.3-
    Prestar atendimento de forma personalizada no escopo de atender as expectativas
    de cada usuário. Realizar comunicação desde que em nome da RBCB e <span class="SpellE">InConta</span>
    Crédito Digital por seus atendentes à título de
    pós-venda, confirmações, por quaisquer canais de comunicação por mim informados
    na ficha de cadastro;<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.4 – O usuário aceita em
    caráter expresso o uso dos dados fornecidos e coletados pelo INCONTA CRÉDITO
    DIGITAL, de modo que possa ser utilizado para ofertas de produtos, serviços,
    instruções, notícias de conteúdos relevantes, envio de planilhas de orçamento
    doméstico e familiar, informativos acerca de educação financeira entre outros
    assuntos pertinentes aos produtos ofertados no INCONTA CRÉDITO DIGITAL. Em caso
    de venda, fusão do INCONTA CRÉDITO DIGITAL, os dados serão incluídos dentre os
    ativos, por meio desta política.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.5 – Trata-se de uma das
    responsabilidades do usuário a manutenção de seu cadastro sempre atualizado
    para finalidade de contatos por parte dos membros da equipe da RBCB.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.6- Elaboração de estudos
    estatísticos e mercadológicos para fins de prestação de serviços oferecidos via
    INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.7- Colaborar e cumprir de
    imediato com ordens judiciais e administrativas que determinem fornecimento de
    informações e dados apostados no INCONTA CRÉDITO DIGITAL.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.8 – O INCONTA CRÉDITO
    DIGITAL de propriedade da RBCB irá resguardar todos os seus direitos inerentes
    ao sistema.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.9 – O usuário autoriza
    expressamente compartilhamento de informações e dados fornecidos no INCONTA
    CRÉDITO DIGITAL com terceiros os quais são partes envolvidas no processo, não
    se limitando a instituição financeira correspondida, para fins operacionais do
    produto pretendido pelo usuário bem como para processamento das informações.
    Autoriza ainda consulta ao SCR por parte da IF correspondida.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.10 – Os dados e informações
    serão todas validadas, apuradas e checadas em caso de detecção de fraudes e
    adulteração dos dados por parte do usuário, as autoridades competentes serão
    prontamente cientificadas acerca da conduta. O escopo da medida é de resguardar
    a boa-fé contratual, as partes envolvidas e a operação.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.11 – O Usuário está ciente e
    autoriza desde já que o INCONTA CRÉDITO DIGITAL componha sua base de dados com
    informações obtidas através do contato direto com o cliente através de variados
    canais, bem como oriundas de outras fontes desde que legítimas.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">2.12 - Disponibilizará o canal
    de atendimento para esclarecimentos de quaisquer dúvidas que possam vir a
    surgir durante a utilização do INCONTA CRÉDITO DIGITAL acerca da Política de
    Privacidade, de segunda à sexta-feira, das 08h às 18h, exceto feridos
    nacionais.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3 – ATUALIZAÇÕES DA POLÍTICA
      DE PRIVACIDADE<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">3.1 – No intuito de resguardar
    os dados e informações pessoais de nossos usuários e clientes a RBCB e INCONTA
    CRÉDITO DIGITAL se ampara no direito de alterar e atualizar sua política de
    privacidade sempre que visualizar necessidade. Portanto, recomendamos que
    periodicamente seja feita nova leitura pelo usuário principalmente para
    contratação de novos produtos. Caso ocorram alterações relevantes e que sejam
    necessárias solicitações de autorizações e atualizações o usuário será
    prontamente comunicado.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">4 – SEGURANÇA DE DADOS E
      INFORMAÇÕES<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">4.1 - O manuseio de dados
    ocorrerá por profissionais devidamente habilitados e treinados, monitorados por
    supervisores para ofertar os produtos e serviços disponibilizados, os quais
    serão operacionalizados à luz dos princípios norteadores do Estado de Direito
    para finalidade que se destina, bem como a legislação em voga. Todos os esforços
    serão empreendidos no escopo de assegurar os dados e informações dos usuários.
    Os dados serão armazenados em locais seguros e de acesso restrito.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">4.2 – O usuário exime de
    responsabilidade o INCONTA CRÉDITO DIGITAL/RBCB, bem como [financeira], por
    eventuais danos e prejuízos decorrentes de acessos não autorizados oriundos de
    falhas técnicas, <i>malwares, </i>vírus e outras invasões ao banco de dados.
    Haverá responsabilidade em casos de dolo, tendo em vista que diversos
    mecanismos de segurança serão cumpridos e o INCONTA CRÉDITO DIGITAL não medirá
    esforços para manter-se sempre atualizado no aspecto de segurança da
    informação.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5 – REGISTRO DE ATIVIDADES<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">5.1 – Os acesos do usuário ao
    INCONTA CRÉDITO DIGITAL serão todos monitorados, com levantamento de informações,
    utilizando-se logs, registros de acessos incluindo;<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Endereço de IP;<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Telas acessadas, tempo de utilização no INCONTA
    CRÉDITO DIGITAL, Ações e movimentos realizados.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Datas, localização e horários de atividade, bem
    como de acesso.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Dados sobre o dispositivo do usuário o qual
    acessou o INCONTA CRÉDITO DIGITAL, a fim de levantar sistema operacional,
    funcionalidades.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Histórico de operações<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Comportamentos do usuário.<o:p></o:p></span>
</p>

<p class="MsoListParagraphCxSpLast" style="text-align:justify;text-indent:-14pt;
mso-list:l1 level1 lfo3">
  <!--[if !supportLists]--><span style="font-size:10pt;
line-height:107%;font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol"><span style="mso-list:Ignore">·<span
        style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span></span>
  <!--[endif]--><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">Tentativa de acesso por dispositivos diversos.<o:p></o:p></span>
</p>

<p class="MsoNormal"><span style="font-size:10pt;line-height:107%;font-family:
&quot;Arial&quot;,sans-serif">5.2 - Em caso de indícios de incongruência de informações,
    atividades e dados, o usuário será comunicado de imediato. Nas hipóteses de
    adulteração e/ou falsificação medidas legais serão tomadas.<o:p></o:p></span></p>

<p class="MsoNormal"><b><span style="font-size:10pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">6 – ARMAZENAMENTO DE DADOS E INFORMAÇÕES<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">6.2- Os dados e informações
    coletadas poderão ser armazenadas em servidor próprio da RBCB e/ou algum
    contratado, após prévia pesquisa mercadológica, poderão ainda ser alocadas em
    território nacional, bem como no exterior utilizando-se de tecnologias no
    intuito de armazenamento e proteção das informações. Sempre ocorrerá
    atualizações a fim de garantir aperfeiçoamento de processos internos.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify;line-height:normal"><span
    style="font-size:10pt;font-family:&quot;Arial&quot;,sans-serif">6.3- O usuário do
    INCONTA CRÉDITO DIGITAL poderá requisitar alteração, atualização e até mesmo
    exibição de seus dados pessoais a um dos membros da equipe de atendimento da
    RBCB e a exclusão de dados desde que não tenha operações contratadas com a
    Instituição Financeira Correspondida, ou seja, tenha realizado apenas
    simulações. Estou ciente que, a qualquer tempo, posso retirar o consentimento
    ora fornecido, hipótese em que as atividades desenvolvidas pela RBCB e INCONTA
    CRÉDITO DIGITAL, no âmbito desta relação, poderão restar prejudicadas. Declaro
    e concordo que meus Dados Pessoais poderão ser armazenados, mesmo após o
    término do tratamento – inclusive após a revogação do consentimento -, (i) para
    cumprimento de obrigação legal, judicial ou regulatória pela RBCB e INCONTA
    CRÉDITO DIGITAL ou (<span class="SpellE">ii</span>) desde que tornados anônimos.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">6.4 – Em casos de eventuais
    cancelamentos do cadastro do usuário, nos termos do item anterior, o INCONTA
    CRÉDITO DIGITAL para finalidades específicas, tais como; processos de
    auditoria, avaliação dentre outros similares, poderá permanecer com histórico e
    registros de acessos, dados e informações pelo período de 5 (cinco) anos a
    contar da data do pleito. Em casos de previsão legal acerca da matéria, medidas
    administrativas e /ou judiciais e preservação de direitos o prazo poderá ser
    alongado.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">7 – DISPOSIÇÕES GERAIS<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">7.1 - Caso algum dispositivo
    constante nestes termos e condições seja julgada como inaplicável, sem eficácia
    ou validade, as demais disposições e instrumentos jurídicos celebrados
    continuarão em pleno vigor.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">7.2 – Empresas terceirizadas
    que realizem processamento de dados e informações contidas no INCONTA CRÉDITO
    DIGITAL seguirão em caráter obrigatório o inteiro teor desta Política de
    Privacidade.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify;line-height:115%"><span
    style="font-size:10pt;line-height:115%;font-family:&quot;Arial&quot;,sans-serif">7.3 - Estou
    ciente que posso utilizar os canais de atendimento à LGPD da RBCB e <span class="SpellE">InConta</span>
    Crédito Digital, por telefones disponibilizados,
    endereços eletrônicos e e-mail, para tirar dúvidas e/ou realizar solicitações
    relacionadas ao tratamento de meus Dados Pessoais.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify;line-height:115%"><span
    style="font-size:10pt;line-height:115%;font-family:&quot;Arial&quot;,sans-serif">Por
    fim, declaro ter lido e ter sido suficientemente informado sobre o conteúdo
    deste Termo e concordo com o tratamento dos meus Dados Pessoais aqui descrito
    de forma livre e esclarecida, em observância à Lei Geral de Proteção de Dados e
    às demais normativas sobre proteção de Dados Pessoais aplicáveis.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">8 – LEI A SER APLICADA E
      IDIOMA<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">8.1 - A legislação brasileira
    é a responsável por reger as relações jurídicas entabuladas pelo presente
    instrumento contratual em língua portuguesa.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">9 – DO FORO<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">9.1 - Elege-se o Foro da
    Comarca do Usuário para dirimir eventuais litígios e assuntos controversos que
    venham a ocorrer em razão deste instrumento, com excepcionalidades de
    competência pessoal, territorial e/ou funcional, de acordo com a Lei.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><b><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">10 – GLOSSÁRIO:<o:p></o:p></span></b></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">10.1- Para alcançar a
    finalidade deste documento e tornar o entendimento de nomenclaturas mais claro,
    abaixo alguns termos, em casos de dúvidas, solicita-se que somente aceite a
    Política de Privacidade quando tudo estiver esclarecido.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">a) <b>INCONTA CRÉDITO DIGITAL</b>:
    Sistema de Inclusão de Propostas Digitais – INCONTA CRÉDITO DIGITAL, pertence a
    Rede Brasileira de Correspondentes e Business LTDA;<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">b) <b>RBCB</b>: Rede
    Brasileira de Correspondentes e Business LTDA, CNPJ: 06.939.746.0001/41, <span
      class="GramE">Correspondente</span> no País, Resolução 3.954/2011 do Banco
    Central do Brasil, d[financeira].<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">c) <b>FINANCEIRA BRB</b>: BRB Crédito,
    Financiamento e Investimento S/A, CNPJ: 33.136.888/0001-43, empresa integrante
    do Banco de Brasília S.A, a qual detêm 100% de suas participações acionárias,
    cujo acionista majoritário é o Governo do Distrito Federal, 96,85%<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">d) <b>ENDEREÇO DE IP: </b>Endereço
    de Protocolo de Internet.<b>
      <o:p></o:p>
    </b></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">e)<b> MALWARES: </b>Código,
    programa ou software maliciosos utilizados com intuito de causar danos,
    alterações ou roubo de informações.<b>
      <o:p></o:p>
    </b></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">f)<b> COOKIES: </b>Pequenos
    arquivos que armazenam em caráter temporário o que o usuário está buscando na
    rede.<o:p></o:p></span></p>

<p class="MsoNormal" style="text-align:justify"><span style="font-size:10pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
    <o:p>&nbsp;</o:p>
  </span></p>

<p class="MsoNormal">
  <o:p>&nbsp;</o:p>
</p>

</div>`;

const Terms = (props) => {
  // const [codigo, setCodigo] = useState(!props.codigoValido);
  const [codigo, setCodigo] = useState(false);

  function handleCodigo(validou) {
    if (validou) setCodigo(false);
  }

  function toggleClose() {
    if (props.onCloseEv) props.onCloseEv();
  }

  function toggleAccept() {
    if (props.onAccept) props.onAccept();
  }

  function Inside(props) {
    let financeira = "a financeira BRB";
    if (props.tipo_convenio == "ORIGINAL") financeira = "o banco Original";
    else if (props.tipo_convenio == "SICOOB") financeira = "o banco SICOOB";

    let __html = _html.replace(/\[financeira\]/g, financeira);
    console.log("Terms: ", props.tipo_convenio);

    return (
      <>
        <h3 className="text-center m-0 mb-3">
          <img
            src={Logo}
            style={{ width: width() !== "mobile" ? "200px" : "200px" }}
          />
        </h3>

        <div
          id="termos-lgpd"
          style={{ wordWrap: "break-word" }}
          dangerouslySetInnerHTML={{ __html }}
        />
      </>
    );
  }

  return (
    <>
      <ModalContent
        onCloseEv={toggleClose}
        onAccept={toggleAccept}
        size={!codigo ? "lg" : "md"}
        concorda={!codigo && true}
      >
        {!codigo && <Inside tipo_convenio={props.detalhes.tipo_convenio} />}
        {codigo && <ValidarCodigo onCloseEv={handleCodigo} />}
      </ModalContent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    alterarProposta: state.dados.alterarProposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
  };
}
export default connect(mapStateToProps, mapActionToProps)(Terms);
