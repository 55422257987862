import React, { useState, useEffect } from "react";
import { Modal, } from "react-bootstrap";
import { Button } from "reactstrap";
import {
  width,
  getCookie,
  formatCurrency,
  formatFloat,
} from "../../../../helpers/general";
import { sendData, getData, buscarAutorizacao } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import { setProposta, setClienteDados } from "../../../../store/actions/dados";
import { swalError, confirmSwal } from "../../../swal";
import Result from "../../../../views/simulator/result";
import "../../../../css/style.css";

let msgIni = { cpf: 0 };
function GerarRefinAut({ ...props }) {
  const [tabelaList, setTabelaList] = useState([]);
  const [tabelaGlobal, setTabelaGlobal] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [limpando, setLimpando] = useState(false);
  const [prazoSteps, setPrazoSteps] = useState([]);
  const [msg, setMsg] = useState(msgIni);
  const [msgErro, setMsgErro] = useState("");
  const [contratos, setContratos] = useState([]);
  const [consultando, setConsultando] = useState(false);
  const [limpo, setLimpo] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [multSelect, setMultSelect] = useState(true);
  const [showConsultar, setShowConsultar] = useState(false);
  const [data, setData] = useState({ cpf: '', cliente: '', valor: '', tabela_id: '', prazo: '' })
  const [dadosRefin, setDadosRefin] = useState({})
  const [show, setShow] = useState(true);
  const [clickedTr, setClickedTr] = useState({});
  const [simulation, setSimulation] = useState(null);
  const [simulando, setSimulando] = useState(false);

  useEffect(() => {
    console.log("Refinanciamento.useEffect(0): ", props.detalhes)
    _carregarDados()
  }, []);

  useEffect(() => {
    _getTabelas(dadosRefin.convenio_id);
    _getContratos();
  }, [dadosRefin]);


  async function _carregarDados() {
    setCarregando(true);
    await _getDadosRefin();
    setCarregando(false);
  }

  async function _getDadosRefin() {
    await sendData({
      uri: uriList("getPropostaRefinPort"),
      content: { proposta_uuid: props.proposta_uuid || props.uuid },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setDadosRefin(res)
        const data = { cpf: res?.cpf, cliente: res?.cliente, valor: res?.valor, tabela_id: res?.tabela_id, prazo: res?.prazo, convenio_id: res?.convenio_id }
        setData(data)
      })
      .catch((e) => {
      });
  }

  async function _getPrazos(tabela_id) {
    setPrazoSteps([]);
    if (tabela_id)
      getData({ uri: uriList("prazos") + "/" + tabela_id })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          setPrazoSteps(res);
        })
        .catch((e) => {
          return false;
        });
  }

  async function _getTabelas(convenio_id) {
    if (!convenio_id) return

    setTabelaList([]);
    await sendData({
      uri: uriList("listarTabelas"),
      content: { operacao: "REFINANCIAMENTO", convenio_id, portabilidade: true },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setTabelaGlobal(res);
        setTabelaList(res);
      })
      .catch((e) => {
      });

    _getPrazos(dadosRefin.tabela_id);
  }

  const handleFilterTabelas = (tabelas) => {
    console.log("Refinanciamento.handleContrato(0): ", tabelas)
    if (tabelas) {
      console.log("Refinanciamento.handleContrato(1): ", clickedTr)
      const _tabelas = tabelas.filter((c) => {
        console.log("Refinanciamento.handleContrato(3): ", { taxa: clickedTr.taxaNominal, refinorgini: c.taxa_refinorg_ini, refinorgfin: c.taxa_refinorg_fin })
        return c?.nome?.indexOf("PORT") >= 0 &&
          ((!parseFloat(c.taxa_refinorg_ini)) || (clickedTr.taxaNominal >= c.taxa_refinorg_ini && clickedTr.taxaNominal <= c.taxa_refinorg_fin))
      });
      console.log("Refinanciamento.handleContrato(4): ", _tabelas)

      if (!_tabelas || _tabelas.length == 0) {
        if (!tabelas || tabelas.length == 0) return
        console.log("Refinanciamento.handleContrato(3.1)")
        setTabelaList(tabelas)
        handleTabela(tabelas[0].id);
      }
      else {
        setTabelaList(_tabelas);
        handleTabela(_tabelas[0].id);
        console.log("Refinanciamento.handleContrato(3.2)")
      }
    }
  }

  async function _getContratos() {
    const cpf = dadosRefin?.cpf
    const contrato = dadosRefin?.contrato || dadosRefin?.cedula_numero
    if (!cpf && !contrato) return;
    setShowConsultar(false);
    setCarregando(true);
    let content = { cpf, contrato, ocultarInativos: true };
    await sendData({
      uri: uriList("consultarCPFBanco"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) {
          setCarregando(false);
          swalError({ title: String(res.message || res) });
          // throw new Error(res.message);
        }
        else {
          setContratos(res.message);
          // if (res.message && res.message.length > 0)
          //   res.message.map((c, i) => {
          //     if (c?.numeroContrato == dadosRefin?.contrato || c?.numeroContrato == dadosRefin?.cedula_numero) {
          //       c.selected = true;
          //       handleContrato(c, 0);
          //     }
          //   });

          if (!res.message || res.message.length == 0)
            setMsgErro(
              "Não foi possivel encontrar contratos para o CPF informado"
            );

          setCarregando(false);
        }
      })
      .catch((e) => {
        swalError({ title: String(e) });
        setCarregando(false);
        setMsgErro("Ocorreu um erro ao buscar os contratos do CPF!");
        setShowConsultar(true);
      });
  }

  const handleContrato = (_contrato, i) => {
    if (!_contrato) return;

    setClickedTr(_contrato);
    handleFilterTabelas(tabelaList);

    if (i >= 0) {
      let _contratos = contratos;
      _contratos[i] = { ..._contrato, selected: !_contrato?.selected };
      setContratos(_contratos);
      if (!_contrato?.selected) setSelectAll(false);

      calcularTotais();
    }
  };

  const calcularTotais = () => {
    let _valor = 0;
    let _selectAll = true;
    for (let i = 0; i < contratos.length; i++) {
      _selectAll = _selectAll && contratos[i]?.selected;
      if (contratos[i]?.selected) {
        _valor += parseFloat(contratos[i].valorParcela);
      }
    }

    setSelectAll(_selectAll);
    setData({ ...data, valor: parseFloat(_valor).toFixed(2) });
  };

  const handleTabela = (tabela_id) => {
    setData({ ...data, tabela_id });
    _getPrazos(tabela_id);
  };

  async function handleGerar() {
    setSimulation(null);
    async function callback(e) {
      if (!e) return

      const [latitude, longitude] = getCookie({ label: "geolocation" })
        ? getCookie({ label: "geolocation" }).split(",")
        : ["", ""];

      const content = {
        prazo: data?.prazo,
        tabela_id: data?.tabela_id,
        convenio_id: dadosRefin?.convenio_id,
        valor: formatFloat(data?.valor),
        tipo: "parcela",
        operacao: "REFINANCIAMENTO",
        contrato: clickedTr.numeroContrato,
        cpf: dadosRefin?.cpf,
        proposta_uuid: props.proposta_uuid || props.uuid,
        operador_id: props.user?.pessoa_id,
        contratos: contratos.filter((c) => c?.selected),
        dadosRefin: clickedTr,
        ip: getCookie({ label: "ip" }),
        latitude,
        longitude,
        simulacao: simulation?.result
      };

      console.log("Refinanciamento.handleGerar(0): ", content)
      setConsultando(true);

      let _autorizacao = await buscarAutorizacao({ cpf: data?.cpf, tabela_id: data?.convenio_id });
      if (!_autorizacao) {
        setConsultando(false);
        swalError("Não existe autorização para o cliente no convênio informado.");
        return;
      }
      sendData({
        uri: uriList("gerarPropostaRefinPort"),
        content,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          setConsultando(false);
          const _erro = res.message?.msg || res.message || res.erro
          if (res.status && res.status > 200) {
            swalError({ title: _erro });
          } else if (props) {
            props.setClienteDados(res);
            handleClose(null, true, res);
          }
        })
        .catch((e) => {
          setConsultando(false);
          swalError({ title: e.message });
        });
    }

    if (!data?.tabela_id) return swalError({ title: "Selecione a tabela." })
    if (!parseFloat(data?.valor)) return swalError({ title: "Informe o valor da parcela." })
    if (!parseFloat(data?.prazo)) return swalError({ title: "Selecione o prazo." })
    if (contratos.filter((c) => c?.selected).length == 0)
      return swalError({ title: "Selecione ao menos um contrato para refinanciar." });

    callback(true)
    // confirmSwal({ callback, title: "Confirma geração da proposta?" });
  }

  async function handleSimular() {
    const [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];

    const content = {
      prazo: data?.prazo,
      tabela_id: data?.tabela_id,
      convenio_id: dadosRefin?.convenio_id,
      valor: formatFloat(data?.valor),
      tipo: "parcela",
      operacao: "REFINANCIAMENTO",
      contrato: clickedTr.numeroContrato,
      cpf: dadosRefin?.cpf,
      proposta_uuid: props.proposta_uuid || props.uuid,
      operador_id: props.user?.pessoa_id,
      contratos: contratos.filter((c) => c?.selected),
      dadosRefin: clickedTr,
      ip: getCookie({ label: "ip" }),
      latitude,
      longitude,
    };

    setSimulando(true);
    sendData({
      uri: uriList("simulador"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setSimulando(false);
        if (res.status && res.status > 200) {
          let erro = JSON.parse(res.erro || res.message);
          let msgErro = String(erro.msg || erro);
          console.log("Erro ao simular refin de port: ", msgErro);

          swalError({ title: msgErro });
          // throw new Error();
        } else if (props) {
          console.log("rerarrefinAut.handleSimular: ", res);
          setSimulation({ result: res, content });
        }
      })
      .catch((e) => {
        setSimulando(false);
        swalError({ title: e.message });
        console.log("Erro de Simulação de Refin de Port\n: ", e);
      });
  }


  const handleSelectAll = () => {
    let _contratos = contratos;
    let _selectAll = !selectAll;
    for (let i = 0; i < _contratos.length; i++)
      _contratos[i].selected = _selectAll;

    handleContrato(_contratos[0], -1);
    setContratos(_contratos);
    calcularTotais();
  };

  function handleClose(e, close, data) {
    if (e) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(data);
  }

  return (
    <>
      {simulation &&
        < Modal
          size="md"
          show={show}
          centered
          onHide={(e) => setSimulation(null)}
          animation={false}
          backdrop="static"
        >
          <Result
            title="Simulação"
            result={simulation.content}
            data={simulation.result}
            admin={true}
            gerarRefinPort={true}
            toggleStep={handleGerar}
            // toggleStep={() => setSimulation(null)}
            goBack={() => setSimulation(null)}
          />
        </Modal>
      }
      {!simulation &&
        < Modal
          size="md"
          show={show}
          centered
          onHide={(e) => handleClose(e, true)}
          animation={false}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Refinanciamento Automático</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="maxWidth650">
            <fieldset className="col-12" style={{ height: "100%" }}>
              {limpando ? (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <br />
                  <h4 style={{ opacity: "60%" }}>Limpando dados...</h4>
                </div>
              ) : !carregando && !consultando && !simulando ? (
                <div className="form" style={{ overflow: "hidden" }}>
                  {/* <div className="row flex">
                    <div className="form-group col-md-12 d-flex" >
                      <div className="col-md-10" style={{ display: "flex", flexDirection: "column" }}>
                        <div>Cliente</div> <div><strong>{dadosRefin?.cliente}</strong></div>
                      </div>
                      <div className=" col-md-3" style={{ display: "flex", flexDirection: "column" }}>
                        <div>Parc Port</div> <div><strong>{dadosRefin?.valor_parcela || dadosRefin?.valor}</strong></div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row flex">
                    <div className="form-group col-md-12 d-flex" style={{ justifyContent: "space-between" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Cliente</div>
                        <div><strong>{dadosRefin?.cliente}</strong></div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Parc Port</div>
                        <div><strong>R$ {dadosRefin?.valor_parcela || dadosRefin?.valor}</strong></div>
                      </div>
                    </div>
                  </div>
                  <div className="row flex">
                    <div className="form-group col-md-12 d-flex" style={{ justifyContent: "space-between" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>CPF</div>
                        <div><strong>{dadosRefin?.cpf}</strong></div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Cédula</div>
                        <div><strong>{dadosRefin?.contrato}</strong></div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Proposta</div>
                        <div><strong>{dadosRefin?.propostaid}</strong></div>
                      </div>
                    </div>
                  </div>

                  {contratos && contratos.length > 0 ? (
                    <div
                      className="mb-2 border pt-1 pb-1"
                      style={{ backgroundColor: "rgba(231, 230, 230,  0.18)" }}
                    >
                      <div
                        className="form"
                        style={{
                          maxHeight: width() == "mobile" ? "100px" : "150px",
                          fontSize: width() == "mobile" ? "x-small" : "small",
                          overflowY: "scroll",
                        }}
                      >
                        <table
                          className="table-hover table-bordered table-responsive"
                          border="1"
                          style={{ cursor: "pointer" }}
                        >
                          <thead style={{ position: "sticky", top: "0px" }}>
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                              {multSelect && (
                                <td
                                  className="text-center font-weight-bold"
                                  style={{ width: "35px" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectAll}
                                      style={{ width: "15px", height: "15px" }}
                                      onChange={() => handleSelectAll()}
                                    />
                                  </div>
                                </td>
                              )}

                              <td
                                className="text-left font-weight-bold"
                                style={{ width: "120px" }}
                              >
                                Contrato
                              </td>
                              <td
                                className="text-right font-weight-bold"
                                style={{ width: "120px" }}
                              >
                                Saldo
                              </td>
                              <td
                                className="text-right font-weight-bold"
                                style={{ width: "120px" }}
                              >
                                Parcelas
                              </td>
                              <td
                                className="text-right font-weight-bold"
                                style={{ width: "100px" }}
                              >
                                Pagas
                              </td>
                              <td
                                className="text-right font-weight-bold"
                                style={{ width: "60px" }}
                              >
                                Taxa
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {contratos &&
                              contratos.map((contrato, i) => (
                                <tr
                                  key={i}
                                  className={`${clickedTr.numeroContrato === contrato.numeroContrato
                                    ? "bg-secondary text-light"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    handleContrato(contrato, i);
                                  }}
                                  onDoubleClick={() => {
                                    handleContrato(contrato, i);
                                  }}
                                >
                                  {multSelect && (
                                    <td
                                      className="text-center"
                                      style={{
                                        width: "35px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={contrato?.selected}
                                          style={{ width: "15px", height: "15px" }}
                                          onChange={() => handleContrato(contrato, i)}
                                        />
                                      </div>
                                    </td>
                                  )}
                                  <td id={i} style={{ width: "120px" }}>
                                    {
                                      (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) < 0)
                                        ? contrato.numeroContrato
                                        : '...' + contrato.numeroContrato.slice(-3)
                                    }
                                  </td>
                                  <td
                                    id={i}
                                    className="text-right"
                                    style={{ width: "120px" }}
                                  >{`${width() == "mobile" ? "" : "R$"
                                    } ${["AGENTE", "OPERADOR PARCEIRO",].indexOf(props.user?.tipo) >= 0 ? '...' : formatCurrency(
                                      parseFloat(contrato.saldoDevedor).toFixed(2)
                                    )}`}</td>
                                  <td
                                    id={i}
                                    className="text-right"
                                    style={{ width: "120px" }}
                                  >{`${width() == "mobile" ? "" : "R$"} ${parseFloat(
                                    contrato.valorParcela
                                  ).toFixed(2)}`}</td>
                                  <td
                                    id={i}
                                    className="text-right"
                                    style={{ width: "100px" }}
                                  >{`${contrato.qtdParcelasPagas}/${contrato.quantidadeDocumentos}`}</td>
                                  <td
                                    id={i}
                                    className="text-right"
                                    style={{ width: "60px" }}
                                  >{`${parseFloat(contrato.taxaNominal).toFixed(
                                    2
                                  )}%`}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : msgErro ? (
                    <h5
                      className="text-center border p-2"
                      style={{
                        color: "red",
                        minHeight: width() == "mobile" ? "" : "50px",
                      }}
                    >
                      {msgErro}
                    </h5>
                  ) : (
                    <h5
                      className="text-center border p-2"
                      style={{
                        opacity: "60%",
                        minHeight: width() == "mobile" ? "" : "50px",
                        fontSize: width() == "mobile" ? "large" : "",
                      }}
                    >
                      Aguardando dados para exibição...
                    </h5>
                  )}
                  <>
                    {!carregando &&
                      <div className="row d-flex ">
                        <div className="col-md-12">
                          <select
                            className="form-control "
                            defaultValue={data.tabela_id}
                            onChange={(e) => handleTabela(e.currentTarget.value)}
                            name="tabela_id"
                            id="tabela_id"
                            style={{
                              backgroundColor: "#EBEEE9",
                              borderRadius: "4px",
                              border: "1.5px solid #727476",
                              backgroundImage:
                                "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                            }}
                          >
                            {tabelaList ? (
                              tabelaList.map((tabela, index) => (
                                <option
                                  key={`tabela_id-${tabela.id}`}
                                  title={tabela.nome}
                                  value={tabela.id}
                                >
                                  {tabela.nome}
                                </option>
                              ))
                            ) : (
                              <option>Escolha a Tabela</option>
                            )}
                          </select>
                        </div>

                        <div className=" mt-2 col-md-12 ">
                          <label htmlFor="valor">Vlr Parcela(R$)</label>

                          <input
                            type="tel"
                            value={data.valor}
                            style={{
                              marginLeft: "0.3em",
                              width: "120px",
                              borderRadius: "4px",
                              border: "1.5px solid #727476",
                            }}
                            onChange={(e) =>
                              setData({ ...data, valor: formatCurrency(e.currentTarget.value) })
                            }
                            name="valor"
                            id="valor"
                          />
                        </div>
                        {prazoSteps?.length &&
                          <div className=" mt-2 col-md-12 ">
                            <label htmlFor="prazo">Prazo</label>
                            <select
                              className="ml-1"
                              defaultValue={data.prazo}
                              name="prazo"
                              id="prazo"
                              onChange={(e) => setData({ ...data, prazo: e.currentTarget.value })}
                              style={{
                                width: "120px",
                                backgroundColor: "#EBEEE9",
                                borderRadius: "4px",
                                border: "1.5px solid #727476",
                                backgroundImage:
                                  "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                              }}
                            >
                              {prazoSteps &&
                                prazoSteps.map((prazo, i) => (
                                  <option
                                    key={`prazo-${i}`}
                                    label={prazo.prazo}
                                    value={prazo.prazo}
                                  >
                                    {prazo.prazo}
                                  </option>
                                ))}
                            </select>
                          </div>}
                      </div>
                    }
                  </>
                </div>
              ) : !consultando && !simulando ? (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <br />
                  <h4
                    style={{
                      opacity: "60%",
                      fontSize: width() == "mobile" ? "large" : "",
                    }}
                  >
                    Carregando dados...
                  </h4>
                </div>
              ) : (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <br />
                  <h4
                    style={{
                      opacity: "60%",
                      fontSize: width() == "mobile" ? "large" : "",
                    }}
                  >
                    {simulando ? "Aguarde, simulando proposta" : "Aguarde, gerando proposta..."}
                  </h4>
                </div>
              )}
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button color="success" onClick={handleSimular} disabled={carregando || simulando || consultando || !contratos?.length || !(contratos.filter((c) => c?.selected))?.length}>
              Gerar
            </Button>
            <Button
              color="secondary"
              disabled={carregando || simulando || consultando}
              onClick={(e) => { handleClose(e, true) }}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal >
      }
    </>

  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(GerarRefinAut);
