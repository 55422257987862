import React from "react";

import { InputAlert, InputLabelAlert } from "../../layout";

const Form = ({ ...props }) => {
  return (
    <>
      <div className="row">
        {/* <div
					className="form-group col-md-2">
					<InputLabelAlert
						type='tel'
						label='DDD'
						id='celular_ddd'
						length='3'
						// minLength={2}
						click={e => props.changeData(e, e.currentTarget.value.replace(/\D/g, ''))}
						warning={props.warning.celular_ddd}
						value={props.data.celular_ddd}
						handle={props.changeData}
						onBlur={props.changeDDD}
						focus={true} />
				</div> */}
        <div className="form-group col-md-6">
          <InputLabelAlert
            type="tel"
            label="Celular (com DDD)"
            id="celular"
            // length='11'
            // minLength={9}
            click={props.onClick}
            onFocus={props.onFocus || props.onEnter}
            warning={props.warning.celular}
            value={props.data.celular}
            handle={props.changeData}
            onBlur={props.changeTel}
            focus={true}
          />
        </div>

        {/* <div
					className="form-group col-md-2">
					<InputLabelAlert
						type='tel'
						label="DDD"
						id='telefone_ddd'
						length='3'
						// minLength={2}
						click={e => props.changeData(e, e.currentTarget.value.replace(/\D/g, ''))}
						warning={props.warning.telefone_ddd}
						value={props.data.telefone_ddd}
						handle={props.changeData}
						onBlur={props.changeDDD}
						focus={false} />
				</div> */}
        <div className="form-group col-md-6">
          <InputLabelAlert
            type="tel"
            label="Telefone Fixo (com DDD)"
            id="telefone"
            // length='11'
            // minLength={9}
            // click={e => props.changeData(e, e.currentTarget.value.replace(/\D/g, ''))}
            click={props.onClick}
            onFocus={props.onFocus || props.onEnter}
            warning={props.warning.telefone}
            value={props.data.telefone}
            handle={props.changeData}
            onBlur={props.changeTel}
            focus={false}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col">
          <label htmlFor="email" className="no-margin">
            <div style={{ display: "flex", flexDirection: "column" }}>
              E-mail {!props.leilaoInss && <b>(Informar email funcional)</b>}
            </div>
          </label>
          <InputLabelAlert
            type="email"
            id="email"
            minLength={5}
            value={props.data.email}
            warning={props.warning.email}
            handle={props.email}
            lowercase={true}
            focus={false}
          />

          <InputAlert
            display={props.alternate.email}
            messages={props.alternateMessage.email}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col">
          <label htmlFor="horario" className="no-margin">
            Melhor horário para contato
          </label>
          <select
            className="form-control text-capitalize"
            // value={props.data.horario}
            onChange={props.changeData}
            // defaultValue={props.data.horario}
            name="horario"
            id="horario"
            style={{
              backgroundColor: "#EBEEE9",
              borderRadius: "4px",
              border: "1.5px solid #727476",
              backgroundImage: "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
            }}
          >
            {props.horarioList ? (
              props.horarioList.map((horario, index) => {
                return (
                  <option
                    key={`horario-${index}`}
                    title={horario.descricao}
                    selected={
                      props.data &&
                      props.data.horario &&
                      horario.value.toUpperCase() ==
                      props.data.horario.toUpperCase()
                    }
                    value={horario.value}
                  >
                    {horario.descricao}
                  </option>
                );
              })
            ) : (
              <option>Carregando</option>
            )}
          </select>
        </div>
      </div>
    </>
  );
};

export default Form;
