import React, { useState, useEffect, useRef } from "react";
import { Button } from 'reactstrap';
import { Modal } from 'react-bootstrap'
import Swal, { swalError } from './../../../components/swal'
import { sendData, getData } from "../../../helpers/auth";
import { uriList } from '../../../helpers/config';
import { mDate, width } from '../../../helpers/general';
import { InputLabelAlert } from "../../layout";
import * as moment from 'moment'
import { connect } from 'react-redux'

function Observacoes({ ...props }) {
    const [show, setShow] = useState(true)
    const [gravando, setGravando] = useState(false)
    const [excluindo, setExcluindo] = useState(false)
    const [dados, setDados] = useState({})
    const [observacoes, setObservacoes] = useState([])
    const [clickedTr, setClickedTr] = useState({})
    const inputRef = useRef(null)

    useEffect(() => {
        setDados({ ...dados, data: mDate(new Date().toLocaleDateString()), hora: moment(new Date()).format('hh:mm') })
        listarObservacoes()
    }, []);

    async function listarObservacoes() {
        console.log("Listando observações")
        await sendData({ uri: uriList('listarObservacoes'), content: { proposta_uuid: props.proposta }, method: 'POST' })
            .then((res) => {
                if (res.status && parseInt(res.status) > 200) throw new Error();
                console.log("Listou...", res)
                let _obs = []
                res.forEach(element => {
                    _obs.push({ ...element, publico: element.publico ? "SIM" : "NAO", data: moment(element.data).format('DD/MM/YYYY') })
                });

                setObservacoes(_obs)
            })
            .catch((e) => {
                console.log(e)
                swalError({ title: `Erro ao listar observações!` })
            });
    }

    function handleClose() {
        setShow(false)
        let close = props.handleClose || props.onCloseEv || props.onClose
        if (close)
            close()
    }

    async function handlePost() {
        console.log("dados de gravação:")
        console.log(dados)
        if (dados.observacao) {
            setGravando(true)
            await sendData({ uri: uriList('observacoes'), content: { ...dados, proposta_uuid: props.proposta }, method: 'POST' })
                .then(res => {
                    setGravando(false)
                    if (res.status && parseInt(res.status) > 200) throw new Error();
                    console.log("Gravou Observação")
                    handleNew()
                })
                .catch(e => {
                    setGravando(false)
                    swalError({ title: "Erro ao gravar Observação." })
                    console.log(e)
                });
        }
    }

    async function handleNew() {
        setGravando(false)
        setExcluindo(false)
        setClickedTr({})
        listarObservacoes()

        setDados({ id: 0, data: mDate(new Date().toLocaleDateString()), hora: moment(new Date()).format('hh:mm'), observacao: "", usuario: "", publico: "SIM" })
        if (inputRef.current) inputRef.current.focus()
    }

    function handleChange(e) {
        if (e.target.name == "data")
            setDados({ ...dados, [e.target.name]: mDate(e.target.value) })
        else
            setDados({ ...dados, [e.target.name]: e.target.value })
    }

    function handleObservacao(obs) {
        setClickedTr(obs)
        console.log(obs)
        if (width == "mobile")
            setDados(obs)
    }

    async function handleDelete() {
        if (dados && dados.id) {
            setExcluindo(true)
            await sendData({ uri: uriList('observacoes') + '/' + dados.id, content: {}, method: 'DELETE' })
                .then(res => {
                    handleNew()
                    listarObservacoes()
                    setExcluindo(false)
                })
                .catch(e => {
                    swalError({ title: "Erro ao excluir Observação." })
                    setExcluindo(false)
                });
        }
    }

    return (
        <Modal size='lg' show={show} onHide={handleClose} centered animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Observações da Proposta</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="maxWidth750">
                {gravando ?
                    <div>Gravando registro...</div> :
                    excluindo ?
                        <div>Excluindo registro...</div> :
                        <>
                            <div className="form" style={{ overflow: "hidden" }}>
                                <div className="row">
                                    <div className="form-group col-md-3">
                                        <label className="m-0 font-90">Público</label>
                                        <select
                                            className="form-control custom-select"
                                            name='publico'
                                            id='publico'
                                            minLength={5}
                                            value={dados.publico}
                                            // defaultValue={dados.publico}
                                            onChange={handleChange}>
                                            <option value='SIM'>Sim</option>
                                            <option value='NAO'>Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label className="m-0 font-90">Observações</label>
                                        <textarea
                                            type="textArea"
                                            id="observacao"
                                            name="observacao"
                                            rows="5"
                                            ref={inputRef}
                                            className="form-control"
                                            value={dados.observacao}
                                            onChange={handleChange}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label className="m-0 font-90">Usuário</label>
                                        <input type="text" style={{ width: "100%" }} disabled value={dados.user_create}></input>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <strong>Observações da Proposta</strong>
                                        <div className="form " style={{ maxHeight: "200px", overflowY: "scroll", }}>
                                            <table border="1" style={{ cursor: "pointer", }} >
                                                <thead style={{ position: "sticky", top: "0px" }}>
                                                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                        <td className="font-weight-bold" style={{ width: "140px" }}>Criação</td>
                                                        {/* <td className="font-weight-bold" style={{ width: "60px" }}>Hor Criaçãoa</td> */}
                                                        <td className="font-weight-bold" style={{ width: "150px" }}>Usuário</td>
                                                        {/* <td className="font-weight-bold" style={{ width: "100px" }}>Alteração</td> */}
                                                        {/* <td className="font-weight-bold" style={{ width: "60px" }}>Hor Criaçãoa</td> */}
                                                        {/* <td className="font-weight-bold" style={{ width: "150px" }}>Usuário A.</td> */}
                                                        <td className="font-weight-bold" style={{ width: "500px" }}>Observação</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {observacoes && observacoes.map((obs, i) => (
                                                        <tr key={i}
                                                            className={`${clickedTr.id ===
                                                                obs.id
                                                                ? "bg-secondary text-light"
                                                                : ""
                                                                }`}

                                                            onClick={() => {
                                                                handleObservacao(obs);
                                                            }}

                                                            onDoubleClick={() => {
                                                                handleObservacao(obs)
                                                                setDados(obs)
                                                            }}
                                                        >
                                                            <td id={i} style={{ width: "140px" }}>{moment.parseZone(obs.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                                            <td id={i} style={{ width: "150px" }}>{obs?.user_create?.substr(0, 10)}</td>
                                                            {/* <td id={i} style={{ width: "150px" }}>{moment(obs.updated_at).format('DD/MM/YYYY')}</td> */}
                                                            {/* <td id={i} style={{ width: "150px" }}>{obs?.user_update?.substr(0, 10)}</td> */}
                                                            <td id={i} style={{ width: "450px" }}>{obs.observacao && obs.observacao.substr(0, 53)} {obs.observacao.length > 53 ? "..." : ""}</td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button color="success" onClick={handlePost} disabled={dados?.id}>
                    Gravar
                </Button>
                <Button color="warning" onClick={handleNew}>
                    Limpar
                </Button>
                {['ADMINISTRADOR', 'OPERADOR'].indexOf(props.user.tipo) >= 0 &&
                    <Button Button color="danger" onClick={handleDelete}
                        disabled={['ADMINISTRADOR', 'OPERADOR'].indexOf(props.user.tipo) < 0}>
                        Excluir
                    </Button>}
                <Button color="secondary" onClick={handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes,
        user: state.dados.user
    }
}

export default connect(
    mapStateToProps,
)(Observacoes)
