import React, { useState, useEffect, useCallback } from "react";
import { Button } from 'reactstrap';
import { getData, sendData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

const Form = ({ ...props }) => {
    const [emails, setEmails] = useState([])

    const _emails = useCallback(() => {
        getData({ uri: uriList("emails") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                setEmails(res);
            })
            .catch((e) => {
                return false;
            });
    })

    useEffect(() => {
        _emails()
    }, []);

    const handleClose = () => {
        if (props.onCloseEv)
            props.onCloseEv()
    }

    const handleSend = (email) => {
        let content = {
            uuid: props.proposta,
            email: props.detalhes.email,
            assunto: email.assunto,
            corpo: email.corpo,
            preencherTags: true
        }

        if (props.onCloseEv)
            props.onCloseEv()
        if (props.onSend)
            props.onSend()

        sendData({ uri: uriList('enviarEmail'), content, signal: null, method: 'POST' })
            .then(res => {
                if (props.onEndSend)
                    props.onEndSend()
            })
            .catch(e => {
                if (props.onEndSend)
                    props.onEndSend()
                console.log(e)
                return false;
            });
    }

    return (
        <Modal size='md' show={true} onHide={handleClose} centered animation={false} backdrop="static" >
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Clique para enviar o email</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="maxWidth750" >
                {/* <fieldset className="col-12" >  */}
                <div >
                    {emails.map((row, i) => {
                        return (
                            <>
                                <div style={{ display: "flex", flexDirection: "line" }} key={i} onClick={() => { handleSend(row) }} >
                                    <button style={{ width: "100%", borderRadius: "10px" }}>
                                        <div><strong>{row.nome}</strong></div>
                                    </button>
                                </div>
                                <br />
                            </>
                        )
                    })}
                </div>
                {/* </fieldset> */}
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={handleClose}> Fechar </Button>
                {/* <Button color="success" onClick={handleSend}> Enviar  </Button> */}
            </Modal.Footer>
        </Modal>
    )
}


function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes
    }
}


export default connect(
    mapStateToProps,
)(Form)
