import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { width, formatCurrency, Aguarde } from "../../helpers/general";
import * as moment from 'moment'
import { sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import Geolocalizacao from "../../helpers/geolocalizacao";

function ConfirmPort(props) {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(props.data);
  const [aguarde, setAguarde] = useState(false);
  const [getLocation, setGetLocation] = useState(false);

  function toggleAccept() {
    if (props.onAccept) props.onAccept();
  }

  useEffect(() => {
    console.log("!!!!Autorizando portabilidade!!!!", props.data);
  }, []);

  function submit() {
    if (checked) {
      setAguarde(true);
      sendData({
        uri: uriList("autorizarPortabilidade"), 
        content: {
          uuid: props.data.proposta_uuid || props.data.uuid,
          dadosPort: data,
        },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          console.log("Confirmar portabilidade:", res);
          toggleAccept();
          setAguarde(false);
          // setInterval(() => setAguarde(false), 50000);
        })
        .catch((e) => {
          console.log("autorizar portabilidade(Erro): ", e);
          setAguarde(false);
          // setInterval(() => setAguarde(false), 50000);
        });
    }
  }

  return (
    <>
      {!getLocation && (
        <Geolocalizacao onCloseEv={() => setGetLocation(true)} />
      )}
      {getLocation && (
        <Modal
          size={width() !== "mobile" ? "lg" : "sm"}
          show={true}
          animation={false}
          backdrop="static"
          centered={true}
        >
          <Modal.Header
            className="formsign-header-custom text-center pt-2 pb-2"
            style={{ fontSize: "25px" }}
          >
            <strong>Autorização de Portabilidade</strong>
          </Modal.Header>
          <Modal.Body style={{ padding: "5px" }}>
            <div className="form" style={{ overflow: "hidden" }}>
              <div className="form-group row" style={{ marginBottom: "0px" }}>
                <div className="col-sm-12">
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "0px",
                      fontSize: width() == "mobile" ? "x-small" : "",
                    }}
                  >
                    1 – Solicito e autorizo a BRB – Crédito, Financiamento e Investimento S/A,
                    localizada no endereço Centro Empresarial CNC - ST SAUN Quadra 5 Lote C, Bloco C, 3º andar Brasília/DF – CEP 70.091-900,
                    nos termos da Resolução CMN Nº 5.057, de 15/12/2022, e demais legislações e regulamentações vigentes,
                    requisitar junto à Instituição Credora Original consulta às informações necessárias de todas as operações de crédito
                    ativas para realização de portabilidade de operação de crédito de minha titularidade, relacionada abaixo.
                  </h6>
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "0px",
                      fontSize: width() == "mobile" ? "x-small" : "",
                    }}
                  >
                    2 – Declaro ciência de que a portabilidade depende de aprovação cadastral na
                    Financeira BRB.
                  </h6>
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "0px",
                      fontSize: width() == "mobile" ? "x-small" : "",
                    }}
                  >
                    3 – Declaro que tomei ciência e estou de acordo com o Custo Efetivo Total – CET
                    simulado para a portabilidade da operação de crédito.
                  </h6>
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "0px",
                      fontSize: width() == "mobile" ? "x-small" : "",
                    }}
                  >
                    4 – Declaro que tomei ciência e estou de acordo que a solicitação da portabilidade
                    poderá ser cancelada se o contrato original contiver parcela(s) vencida(s) e não
                    paga(s).
                  </h6>
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "0px",
                      fontSize: width() == "mobile" ? "x-small" : "",
                      fontWeight: "bold",
                    }}
                  >
                    5 – Declaro que fui informado/a e concordo que o valor de parcela poderá sofrer
                    alterações em função do saldo devedor atualizado da operação de crédito, conforme
                    apresentação em cédula de crédito bancária referente à portabilidade
                  </h6>
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "0px",
                      fontSize: width() == "mobile" ? "x-small" : "",
                    }}
                  >
                    6 - Este termo autoriza a Instituição Financeira citada a consultar as informações
                    acima descritas durante um período de 15 dias.
                  </h6>
                </div>
              </div>

              <div className="form-group row mt-1 mb-0">
                <div className="col-sm-12">
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                        DADOS PESSOAIS DO SOLICITANTE
                      </td>
                    </tr>
                  </table>

                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td" style={{ width: "100%" }}>
                        Nome Completo: {data.nome}
                      </td>
                    </tr>
                  </table>

                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td col-60">
                        CPF: {data.cpf}
                      </td>
                      <td className="detailed-td col-40">
                        Telefone: {data.celular || data.telefone}
                      </td>
                    </tr>
                  </table>
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td" style={{ width: "100%" }}>
                        Nome da Mãe: {data.mae}
                      </td>
                    </tr>

                  </table>

                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td col-50" style={{ width: "50%" }}>
                        Documento de Identificação
                      </td>
                      <td className="detailed-td col-50" style={{ width: "50%" }}>
                        Tipo: {data.t_doc}
                      </td>
                    </tr>
                  </table>
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td col-40" style={{ width: "40%" }}>
                        Número: {data.num_doc}
                      </td>
                      <td className="detailed-td col-40" style={{ width: "30%" }}>
                        Órgão emissor: {data.orgao}
                      </td>
                      <td className="detailed-td col-40" style={{ width: "30%" }}>
                        Data de emissão: {moment(data.data_exp || 0).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  </table>

                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                        DADOS DA OPERAÇÃO ORIGINAL
                      </td>
                    </tr>
                  </table>
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td" style={{ width: "100%" }}>
                        IF Credora: {data.banco_original}
                      </td>
                    </tr>
                  </table>
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td" style={{ width: "100%" }}>
                        Número do contrato original: {data.contrato_original}
                      </td>
                    </tr>
                  </table>
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td" style={{ width: "100%" }}>
                        Valor da parcela: {data.parcela_original}
                      </td>
                    </tr>
                  </table>

                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                        DADOS DA OPERAÇÃO PROPOSTA
                      </td>
                    </tr>
                  </table>
                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td col-33" style={{ width: "33%" }}>
                        Taxa de Juros(a.m): {data.taxa_juros_mensal}
                      </td>
                      <td className="detailed-td col-33" style={{ width: "33%" }}>
                        Taxa de Juros(a.a): {data.taxa_juros_anual}
                      </td>
                      <td className="detailed-td col-33" style={{ width: "34%" }}>
                        CET: {data.custo_efetivo}
                      </td>
                    </tr>
                  </table>

                  <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                    <tr className="tr-custom" key="row1">
                      <td className="detailed-td col-50" style={{ width: "50%" }}>
                        Prazo: {data.prazo}
                      </td>
                      <td className="detailed-td col-50" style={{ width: "50%" }}>
                        Valor da Parcela: {data.valor_parcelas}
                      </td>
                    </tr>
                  </table>
                  <>
                    <table className="table c-table c-table-bordered" style={{ marginTop: "5px", marginBottom: "0px", borderCollapse: "separate" }}>
                      <tr className="tr-custom" key="row1">
                        <td className="detailed-td text-center font-weight-bold" style={{ width: "100%", backgroundColor: "lightgray" }}>
                          DADOS DO CORRESPONDENTE
                        </td>
                      </tr>
                    </table>

                    <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                      <tr className="tr-custom" key="row1">
                        <td className="detailed-td col-70" style={{ width: "60%" }}>
                          Empresa: {data.empresa_nome}
                        </td>
                        <td className="detailed-td col-30" style={{ width: "40%" }}>
                          Cnpj: {data.empresa_cnpj}
                        </td>
                      </tr>
                    </table>

                    <table className="table c-table c-table-bordered" style={{ marginBottom: "0px", borderCollapse: "separate" }}>
                      <tr className="tr-custom" key="row1">
                        <td className="detailed-td col-60" style={{ width: "60%" }}>
                          Atendente: {data.atendente_nome}
                        </td>
                        <td className="detailed-td col-40" style={{ width: "40%" }}>
                          CPF: {data.atendente_cpf}
                        </td>
                      </tr>
                    </table>
                  </>
                </div>
              </div>

              <div
                className="form-group row"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div className="col-sm-12">
                  <div className="custom-control mt-2 custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      checked={checked}
                      onChange={(e) => setChecked(!checked)}
                      name="connected"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      <strong>Autorizar Portabilidade?</strong>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ">
                {aguarde ? (
                  <Aguarde />
                ) : (
                  <button
                    style={{ backgroundColor: "#3E6C84", width: "100%" }}
                    onClick={submit}
                    disabled={!checked || aguarde}
                    className="btn btn-md btn-info font-weight-bold"
                  >
                    Avançar
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ConfirmPort;
