export function setProposta(novaProposta) {
    return {
        type: 'SET_PROPOSTA',
        payload: novaProposta
    }
}

export function setSimulacao(simulacao) {
    return {
        type: 'SET_SIMULACAO',
        payload: simulacao
    }
}

export function setCliente(novoCliente) {
    return {
        type: 'SET_CLIENTE',
        payload: novoCliente
    }
}

export function setUser(novoUser) {
    return {
        type: 'SET_USER',
        payload: novoUser
    }
}

export function setAlterarProposta(alterar, proposta = null) {
    // console.log("setAlterarProposta")
    // sendData({ uri: uriList('alterarproposta'), content: { proposta, alterarProposta: alterar }, method: 'POST' })

    return {
        type: 'SET_ALTERARPROPOSTA',
        payload: alterar
    }
}

export function setBuscarPropostas(buscar) {
    return {
        type: 'SET_BUSCARPROPOSTAS',
        payload: buscar
    }
}

export function setTDoc(t_doc) {
    return {
        type: 'SET_TDOC',
        payload: t_doc
    }
}

export function setClienteDados(dados) {
    return {
        type: 'SET_CLIENTEDADOS',
        payload: dados
    }
}

export function setContratosRefin(dados) {
    return {
        type: 'SET_CONTRATOSREFIN',
        payload: dados
    }
}

export function setNotificacoes(notificacao) {
    return {
        type: 'SET_NOTIFICACOES',
        payload: notificacao
    }
}

export function setClickedMenu(menu) {
    return {
        type: 'SET_CLICKEDMENU',
        payload: menu
    }
}

export function setPendencias(pend) {
    return {
        type: 'SET_PENDENCIAS',
        payload: pend
    }
}

export function setFiltrosPropostas(filtros) {
    return {
        type: 'SET_FILTROSPROPOSTAS',
        payload: filtros
    }
}