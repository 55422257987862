import React, { useState, useEffect } from "react";

import SimulatorLayout from "../components/layout";
import { sendData, getData, setSession } from "../helpers/auth";
import { uriList } from "../helpers/config";
import { validateCPF } from "../helpers/validation";
import Selfie from "../views/simulator/selfie";
import Result from "../views/simulator/result";
import ConfirmPort from "../views/simulator/confirmport";
import ConfirmProp from "../views/simulator/confirmproporig";

import ConfirmInss from "../views/admin/confirmAutorizacaoInss";
import ConfirmSicoob from "../views/admin/confirmAutorizacaoSicoob";
import { connect } from "react-redux";
import { setProposta, setClienteDados } from "../store/actions/dados";
import Status from "../routes/status";
import Embed from "../views/simulator/embedD4Sign";
import { width } from "../helpers/general";
import Logo from "../images/logo-inteira.png";

function PreencherCli({ ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(false);
  const [assinou, setAssinou] = useState(false);
  const [comando, setComando] = useState(false);
  const [cliDesktop, setCliDesktop] = useState(false);
  const [step, setStep] = useState({
    cur: -1,
    maxStep: 3,
  });

  useEffect(() => {
    let uuid =
      (props.data && props.data.proposta_uuid) ||
      (props &&
        props.match &&
        props.match.params &&
        props.match.params.proposta);
    // console.log("PreencherCli.useEffect: ", uuid);
    setData(props.match.params.proposta);
    let array = window.location.href.split("/");
    let _comando;
    if (array && array.length > 0) _comando = array.pop()?.toLowerCase();
    setComando(_comando);
    // console.log("preencherCLi - _Comando: ", _comando);

    //-- Buscando dados da Autorização
    if (
      ["autorizarinss", "inss_in100", "autorizarsicoob", "sicoob"].indexOf(
        _comando
      ) > -1
    ) {
      console.log("preencherCLi - autorizacao");

      console.log("preencherCLi - autorizacao(1)", _comando);
      if (_comando == "internaluser") setLoaded(true);
      else if (_comando == "autorizarinss" || _comando == "inss_in100")
        setStep({ ...step, cur: 5 });
      else if (_comando == "autorizarsicoob" || _comando == "sicoob")
        setStep({ ...step, cur: 6 });
      else setLoaded(true);

      return;
    }

    setCliDesktop(false);
    if (width() !== "mobile" && _comando != "internaluser") {
      setCliDesktop(true);
      return;
    }
    console.log("uuid: ", uuid);
    sendData({
      uri: uriList("acompanharccb"),
      content: {
        uuid: _comando != "id" ? uuid : "",
        id: _comando == "id" ? uuid : "",
      },
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        console.log("preencherCli.useEffect - Acompanhar:", res);
        if (res.cliente_id) {
          let assinando = res.assinando;

          res["preencherCli"] = true;
          props.alterarProposta(res);
          setData(res);

          console.log("preencherCli: ", res);
          setSession({ obj: res, key: "status" });
          if (res?.status?.toLowerCase() == "cancelada")
            setStep({ ...step, cur: 3 });
          else if (
            res.operacao &&
            res?.operacao?.toLowerCase() == "portabilidade"
          ) {
            let _assinandoport =
              !res.assinada &&
              (assinando || res.assinando_port || res.assinando_refinport) &&
              (res?.status?.toLowerCase() != "portabilidade autorizada");
            console.log("preencherCli(Portabiliade):", res.status);
            if (!res.port_autorizada) setStep({ ...step, cur: 4 });
            else if (_assinandoport && res.confirmada)
              setStep({ ...step, cur: 2 });
            // else if (assinando && res.status != "Portabilidade Autorizada")
            else if (_assinandoport) setStep({ ...step, cur: 0 });
            else setStep({ ...step, cur: 3 });
          } else if (
            !assinando &&
            (!res.enviada ||
              ["cancelada", "pendente", "inutilizada"].indexOf(
                res?.posicao?.toLowerCase()
              ) >= 0)
          )
            setStep({ ...step, cur: 3 });
          else if (assinando && res.confirmada) setStep({ ...step, cur: 2 });
          else if (res?.posicao?.toLowerCase() == "simulacao" || assinando)
            setStep({ ...step, cur: 0 });
          else setStep({ ...step, cur: 3 });

          //-- Carregando dados do cliente
          getData({ uri: uriList("pessoas") + "/" + res.cliente_id }).then(
            (res) => {
              // console.log("GetData - PreencherCli: ", res)
              props.setClienteDados(res);
            }
          );

          // console.log("preencherCli(Step): ", step)
        }

        setLoaded(true);
      })
      .catch((e) => {
        console.log("preencherCli(Erro): ", e.message);
        setStep({ ...step, cur: -1 });
        setLoaded(true);
      });
  }, []);

  const toggleConfirmCli = async () => {
    console.log("preencherCli - toggleConfirmCli");

    await sendData({
      uri: uriList("confirmarProposta"),
      content: { uuid: props.proposta },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        console.log("preencherCli - toggleConfirmCli(OK)");
        setStep({ ...step, cur: 2 });
      })
      .catch((e) => {
        console.log("preencherCli - toggleConfirmCli(Error)");
        console.log(e);
      });
  };

  const toggleStep = (n) => {
    console.log("preencherCli - toggleStep: ", n);
    if (!n || n === undefined) n = 1;
    let _next = step.cur + n;
    if (_next >= 0 && _next <= step.maxStep) {
      if (step.cur === 2 && n > 0) toggleConfirmCli();

      setStep({ ...step, cur: _next });
    }
  };

  const toggleSign = (step = true) => {
    setStep({ ...step, cur: 2 });
    let content = { uuid: data.uuid || data.proposta_uuid };
    console.log("preencherCli - toggleSign: ", content);
    sendData({
      uri: uriList("informarAssinatura"),
      content,
      method: "POST",
    }).then((data) => {
      setAssinou(true);
    });
  };

  const setGravarSelfie = (gravou) => {
    console.log("preencherCli.setGravarSelfie");
    setData({ ...data, gravou_selfie: gravou });
  };

  const RenderScreen = ({ ...props }) => {
    console.log("preencherCli.RenderScreen: ", {
      step: props.step,
      gravou_selfie: props.gravou_selfie,
    });

    if (data?.api_assinatura == "Confia") {
      props.step = 3;
      console.log("preencherCli.RenderScreen(Confia)");
    } else {
      //-- Obrigando a gravar selfie
      if (props.step == 2 && !props.gravou_selfie) props.step = 1;
      if (props.step == 2 && !data.uuid_documento) props.step = 3;
    }

    return (
      {
        // 0:(<ConfirmProp data={data} onAccept={toggleSign} />),
        0: (
          <SimulatorLayout>
            <Result
              title="Simulação"
              toggleStep={toggleStep}
              internalUser={comando == "internaluser"}
            />
          </SimulatorLayout>
        ),
        1: (
          <SimulatorLayout>
            <Selfie
              title="Selfie"
              toggleStep={toggleStep}
              confirmCli={toggleConfirmCli}
              setGravarSelfie={setGravarSelfie}
              internalUser={comando == "internaluser"}
            />
          </SimulatorLayout>
        ),

        2: (data.tipo_convenio != "ORIGINAL" && (
          <Embed
            data={data}
            onAccept={toggleSign}
            internalUser={comando == "internaluser"}
          />
        )) || (
            <SimulatorLayout hideContact={true}>
              <ConfirmProp
                data={data}
                onAccept={() => setStep({ ...step, cur: 2 })}
                internalUser={comando == "internaluser"}
              />
            </SimulatorLayout>
          ),
        3: (
          <Status
            preencherCli={true}
            portabilidade={data.assinando_port}
            data={data}
            internalUser={comando == "internaluser"}
          />
        ),

        4: (
          <ConfirmPort
            data={data}
            onAccept={() => setStep({ ...step, cur: 3 })}
            internalUser={comando == "internaluser"}
          />
        ),

        5: <ConfirmInss data={data} internalUser={comando == "internaluser"} />,

        6: (
          <ConfirmSicoob data={data} internalUser={comando == "internaluser"} />
        ),
      }[props.step] || (
        <>
          {cliDesktop ? (
            <div className="p-5 text-center">
              <img
                className="img-fluid mw-25 mh-25"
                style={{ width: "25%" }}
                src={Logo}
                alt="InConta Digital"
              />
              <h1>
                Caro cliente, favor utilizar um smartphone para acessar sua
                proposta.
              </h1>{" "}
            </div>
          ) : loaded ? (
            <h1 style={{ textAlign: "center", color: "red" }}>
              !!! Proposta não encontrada !!!
            </h1>
          ) : (
            <h1 style={{ textAlign: "center" }}>Carregando a Proposta!</h1>
          )}
        </>
      )
    );
  };

  return (
    <RenderScreen
      step={step.cur}
      gravou_selfie={props.detalhes.gravou_selfie || data.gravou_selfie}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(PreencherCli);
