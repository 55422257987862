import React, { useEffect, useState } from "react";

import {
  formatCurrency,
  formatFloat,
  width,
} from "../../../../helpers/general";
import { sendData } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { swalError } from "../../../swal";
import { suppressDeprecationWarnings } from "moment";
import "./styles.scss";

const Form = ({ ...props }) => {
  let minValor = props.data?.limit.minimumOperationValue || 0;
  let maxValor =
    props.data?.simulations[0]?.requestedValue ||
    props.data?.limit.availableLimit ||
    0;

  let minPrazo = props.data?.limit.minimumOperationPeriod || 0;
  let maxPrazo = props.data?.limit.maximumOperationPeriod || 0;

  let maxParc = props.data?.limit.maximumTrancheValue || 0;
  let minParc = props.data?.limit.minimumTrancheValue || 0;
  let prazoSteps = props.data?.simulations;

  const [warning, setWarning] = useState({});
  const [simulando, setSimulando] = useState(false);
  const [data, setData] = useState({
    tipo: "valor",
    valor: formatFloat(maxValor),
    prazo: maxPrazo,
    rangeValor: formatFloat(maxValor),
    rangePrazo: formatFloat(maxPrazo),
    valorParcela: 0,
  });

  useEffect(() => {
    handleSimulation();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "rangeValor")
      setData({
        ...data,
        ["valor"]: formatFloat(value),
        ["valorParcela"]: 0,
      });
    else if (name == "rangePrazo")
      setData({ ...data, ["prazo"]: value, ["valorParcela"]: 0 });
    else if (name == "valor")
      setData({ ...data, [name]: formatCurrency(value), ["valorParcela"]: 0 });
    else if (name == "prazo")
      setData({ ...data, [name]: parseInt(value), ["valorParcela"]: 0 });

    if (name == "valor" && (value > maxValor || value < minValor))
      setWarning({ ...warning, [name]: true });

    if (props.handleSimulacao) props.handleSimulacao(null);
  };

  const handleSimulation = async (simulacao) => {
    let _dados = { ...props.dados };
    _dados["ddn"] = _dados.ddn.split("/").reverse().join("-");
    _dados["cpf"] = _dados.cpf.replace(/\D/g, "");
    _dados["valorOperacao"] = formatFloat(data.valor);
    _dados["prazo"] = data.prazo;
    _dados = { ..._dados, gravar: false };
    console.log("simulator.handleSimulation", _dados);
    setSimulando(true);
    sendData({
      uri: uriList("simulacaoOriginal"),
      content: _dados,
      signal: null,
      method: "POST",
    })
      .then(async (res) => {
        setSimulando(false);
        console.log("simulator.handleSimulation - res: ", res);
        if (
          (res.status && res.status > 200) ||
          (res._status && res._status > 200)
        ) {
          let erro = "Erro ao fazer simulação";
          console.log(erro + ": ", res.message);

          swalError({ title: erro, text: res.message });
          // throw new Error();
        } else {
          console.log(
            "simulator.handleSimulation - res.data: ",
            res.data.simulations[0]
          );
          setData({
            ...data,
            valorParcela: formatFloat(res.data.simulations[0].trancheValue),
          });

          if (props.handleSimulacao)
            props.handleSimulacao(res.data.simulations[0]);
        }
      })
      .catch((e) => {
        setSimulando(false);
        swalError({ title: JSON.stringify(e.message) });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  };

  const handleAddValue = (campo) => {
    if (campo == "valor") {
      let _valor = parseFloat(data.valor) + 100;
      if (_valor > maxValor) _valor = maxValor;
      setData({ ...data, valor: formatFloat(_valor), valorParcela: 0 });
    } else if (campo == "prazo") {
      let _prazo = parseInt(data.prazo) + 1;
      if (_prazo > maxPrazo) _prazo = maxPrazo;
      setData({ ...data, prazo: _prazo, valorParcela: 0 });
    }
    if (props.handleSimulacao) props.handleSimulacao(null);
  };

  const handleMinValue = (campo) => {
    if (campo == "valor") {
      let _valor = parseFloat(data.valor) - 100;
      if (_valor < 100) _valor = 100;
      setData({ ...data, valor: formatFloat(_valor), valorParcela: 0 });
    } else if (campo == "prazo") {
      let _prazo = parseInt(data.prazo) - 1;
      if (_prazo < 1) _prazo = 1;
      setData({ ...data, prazo: _prazo, valorParcela: 0 });
    }
    if (props.handleSimulacao) props.handleSimulacao(null);
  };

  return (
    <div className="form" style={{ overflow: "hidden" }}>
      <div className="form-group" style={{ position: "relative" }}>
        <div style={{ marginBottom: "15px" }}>
          Valor total disponivel: <strong>R$ {formatCurrency(maxValor)}</strong>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-12">
          <label htmlFor="valor" className="font-weight-bold custom-p">
            {"Valor do Empréstimo"}
          </label>
          <button className="button0" onClick={() => handleMinValue("valor")}>
            -
          </button>
          <input
            className="input0"
            type="text"
            value={data.valor}
            onChange={handleChange}
            name="valor"
            id="valor"
          />
          <button className="button0" onClick={() => handleAddValue("valor")}>
            +
          </button>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-8">
          <label htmlFor="prazo" className="font-weight-bold custom-p">
            Prazo para Pagamento
          </label>
          <button className="button0" onClick={() => handleMinValue("prazo")}>
            -
          </button>
          <input
            className="input0"
            type="text"
            value={data.prazo}
            onChange={handleChange}
            name="prazo"
            id="prazo"
          />
          <button className="button0" onClick={() => handleAddValue("prazo")}>
            +
          </button>
        </div>
      </div>

      <div className="form-group">
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          Valor da parcela:{" "}
          <span style={{ fontWeight: "bold" }}>
            R$ {formatCurrency(data.valorParcela)}
          </span>
        </div>
      </div>

      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #727476",
        }}
      >
        <button
          className="button01"
          style={{
            width: "100px",
            marginBottom: "10px",
            borderRadius: "4px",
            fontWeight: "bold",
            height: "30px",
          }}
          onClick={handleSimulation}
          disabled={simulando}
        >
          Simular
        </button>
      </div>
    </div>
  );
};

export default Form;
