/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
import { sendData, } from "../../../helpers/auth";

import { uriList } from "../../../helpers/config";
import { Pagination, } from "../../layout";
import {
  getTiposDocumentos,
  width,
  formatCurrency
} from "../../../helpers/general";
// import AnalisarPendencia from "./Solicitacoes/AnalisarPendencia";
import NovaSolicitacao from "./Solicitacoes/IncluirSolicitacao";
import ExecutarSolicitacao from "./Solicitacoes/ExecutarSolicitacao";

let filterIni = {
  ocultarFinalizadas: "sim",
  filtrarPor: "todos",
  cliente: "<todos>",
  pesquisa: "",
  convenio: "<todos>",
  status: "<todos>",
  automaticas: "<todos>",
  startDate: new Date(),
  finalDate: new Date()
}

const Status = [
  { nome: 'Aguardando Analise' },
  { nome: 'Pendente' },
  { nome: 'Resolvida' },
]

const Form = ({ ...props }) => {
  const [data, setData] = useState();
  const [filtro, setFiltro] = useState(filterIni);
  const [alterarItem, setAlterarItem] = useState(false)
  const [actualPage, setActualPage] = useState(0);
  const [qtdLinesPage, setQtdLinesPage] = useState(12);
  const [lastPage, setLastPage] = useState(0);
  const [carregando, setCarregando] = useState(false);
  const [total, setTotal] = useState(0)
  const [novaSolicitacao, setNovaSolicitacao] = useState(false)

  const pesquisaRef = useRef();
  const arrayPagination = [12, 30, 60, 300, 600, 900]

  let _tipos = getTiposDocumentos(props?.user);
  let exibindoItens = false

  useEffect(() => {
    console.log("Solicitacoes.useEffect(1)")
    if (!carregando)
      _getDados();
  }, [actualPage, qtdLinesPage]);

  function handlePesquisar() {
    setCarregando(true)
    setActualPage(0)
    setData(null)
    _getDados()
  }

  function temPermissao(_permissao = 'solicitacoesexec') {
    if (props?.user?.tipo == "ADMINISTRADOR" || props?.user?.tipo == "OPERADOR")
      return true

    const permissoes = props.user?.permissoes || props.user?.controle_acesso?.permissoes;
    if (!permissoes) return false;

    const _temPermissao = permissoes.some(permissao => _permissao.includes(permissao.name))
    console.log("solicitacoes.temPermissao(_temPermissao): ", _temPermissao)
    return _temPermissao
  }

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  function handleQtdLinesPage(e) {
    setQtdLinesPage(e.currentTarget.value)
  }

  async function _getDados() {
    setCarregando(true)

    console.log("Solicitacoes._getDados(0): ", props?.user?.tipo)
    let content = {
      userid: props?.user?.pessoa_id,
      agenteid: ['AGENTE', 'OPERADOR PARCEIRO'].indexOf(props?.user?.tipo) >= 0 ? props?.user?.pessoa_id : null,
      operadorid: ['AGENTE', 'OPERADOR PARCEIRO', 'CLIENTE'].indexOf(props?.user?.tipo) < 0 ? props?.user?.pessoa_id : null,
      tipoUsuario: props?.user?.tipo,
      ocultarFinalizadas: filterIni.ocultarFinalizadas == "sim",
      actualPage,
      qtdLinesPage,
      filtro
    }

    console.log("Solicitacoes._getDados(1): ", content)

    await sendData({
      uri: uriList('listarSolicitacoes'),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (!res || parseInt(res.status) > 200) throw new Error();
        console.log("Solicitacoes.res(0): ", { data: res.data, res });

        setData(res.data)
        setLastPage(res.lastPage)
        setTotal(res.total)
        setCarregando(false)
        if (actualPage > res.lastPage)
          setActualPage(0)
      })
      .catch((e) => {
        setCarregando(false)
        console.log("Solicitacoes.erro(0)");
        console.log(e);
        return false;
      });

    //-- Setar foco no campo de pesquisa
    if (pesquisaRef?.current)
      pesquisaRef.current.focus();
  }

  const handleOption = (e) => {
    if (e?.currentTarget) {
      let _filtro = { ...filtro, [e.currentTarget.id]: e.currentTarget.value }
      setFiltro(_filtro)
    }
  }

  const handleClose = (close) => {
    setAlterarItem(false)
    if (!close)
      _getDados()
  }

  const limparFiltros = () => {
    setFiltro(filterIni);
    setData(null)

    //-- Setar foco no campo de pesquisa
    if (pesquisaRef?.current)
      pesquisaRef.current.focus();
  }

  const handleNew = () => {
    setNovaSolicitacao(true)
  }

  const handleCloseNovaS = async (close, _ok = false) => {
    console.log("Solicitacoes.handleCloseNovaS(0): ", novaSolicitacao)
    setNovaSolicitacao(false)
    await _getDados()
  }

  return (
    <>
      {novaSolicitacao && <NovaSolicitacao onCloseEv={handleCloseNovaS} />}
      {alterarItem && <ExecutarSolicitacao onCloseEv={handleClose} item={alterarItem} />}
      <div className="row mb-2" style={{ padding: "5px", border: "1px solid #E5E5E5" }}>
        <div className={`col-md-${width() != "mobile" ? "3" : "12"}`}>
          <label className="m-0 font-90">Pesquisar(Tipo, Agente...)</label>
          <input type="text" ref={pesquisaRef} className="form-control" id="pesquisa"
            autoFocus={true} value={filtro.pesquisa} onChange={handleOption}
            title={"Digite para pesquisar por: Tipo, Agente, etc..."} />
        </div>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"}`}>
          <label className="m-0 font-90">Ocultar Resolvidas</label>
          <select
            onChange={handleOption}
            style={{
              lineHeight: "2.2em",
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Ocultar Pendências Finalizadas"
            id="ocultarFinalizadas"
          >
            <option value="nao" selected={filtro.ocultarFinalizadas == "nao"}>Não</option>
            <option value="sim" selected={filtro.ocultarFinalizadas == "sim"}>Sim</option>
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"}`}>
          <label className="m-0 font-90">Status</label>
          <select
            onChange={handleOption}
            style={{
              lineHeight: "2.2em",
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Selecionar o Status"
            id="status"
          >
            <option selected={filtro.status == "<todos>"}>{"<todos>"}</option>
            {Status.map((item, index) => (<option value={item.nome}>{item.nome}</option>))}
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "1" : "12"} d-flex`}>
          <button className="btn btn-primary btn-block mt-4" onClick={handlePesquisar}>Pesquisar</button>
          <button className="btn btn-secondary btn-block mt-4 ml-1" onClick={limparFiltros}>Limpar</button>
        </div>

      </div>

      <div className="row mb-2" style={{ padding: "10px", border: "1px solid #E5E5E5", justifyContent: "center", }}>
        {data &&
          <div className="row d-flex col-12 mb-2" style={{ flexWrap: "wrap", flexDirection: "row", marginLeft: "10px" }} >
            <button
              onClick={() => handleNew()}
              className="btn btn-sm text-light mr-2"
              title="Incluir nova solicitação"
              style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
            >
              <i className="mdi mdi-plus-box" />
            </button>
            <Pagination
              detailed={data}
              actual={actualPage}
              changePage={handlePage}
              qtdLinesPage={qtdLinesPage}
              lastPage={lastPage}
            />
            <div className="page-item ml-1" style={{ padding: "0.05rem" }}>
              <select
                defaultValue={qtdLinesPage}
                name="qtdLinesPage"
                id="qtdLinesPage"
                onChange={handleQtdLinesPage}
                style={{
                  width: "75px",
                  height: "100%",
                  textAlign: "center",
                  backgroundColor: "#EBEEE9",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                  backgroundImage:
                    "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                }}
              >
                {arrayPagination.map((lines, i) => (
                  <option
                    key={`lines-${i}`}
                    label={lines}
                    value={lines}
                  >
                    {lines}
                  </option>
                ))}
              </select>
            </div>
            <div className="page-item ml-1 mt-2" style={{ padding: "0.05rem" }}>
              <span className="ml-3 mr-1 ">Registros: <span className="font-weight-bold">{total}</span></span>
            </div>
          </div>
        }
        <div className="row d-flex col-12" style={{ flexWrap: "wrap", flexDirection: "row", }} >
          {carregando && <div className="d-flex justify-content-center font-weight-bold" style={{ width: "100%" }}><h4>Carregando dados, aguarde...</h4></div>}
          {!carregando && data && data.map((item, index) => {
            let _tipo = _tipos.filter(_item => _item?.value == item?.anexar_doc_tipo)
            if (_tipo.length > 0)
              item.anexar_doc_tipo = _tipo[0].nome
            exibindoItens = true

            const _concluida = ["resolvida", "finalizada"].indexOf(item?.status?.toLowerCase()) >= 0
            const _cancelada = ["cancelada"].indexOf(item?.status?.toLowerCase()) >= 0
            const _erro = ["erro"].indexOf(item?.status?.toLowerCase()) >= 0
            return (
              <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`}
                style={{ cursor: ["pendente", "erro"].indexOf(item?.status?.toLowerCase()) >= 0 ? "pointer" : "", }}
              >
                <div className="card"
                  style={{
                    border: `1px solid ${_concluida ? "#215F04" : _cancelada || _erro ? "red" : "#FFA500"}`,
                    opacity: _concluida || _cancelada ? "50%" : "100%"
                  }}
                  onClick={() => {
                    if (["pendente", "erro"].indexOf(item?.status?.toLowerCase()) >= 0 && temPermissao())
                      setAlterarItem(item)
                  }}
                >
                  <div className="card-header font-weight-bold text-center" style={{ backgroundColor: "#ECECEC", padding: "0px" }}>
                    <h5>{item?.tipo}</h5>
                  </div>
                  <div className="col-12 mb-1" style={{ padding: "3px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ fontWeight: "bold", }}>Status: {item?.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1)}</div>
                      <div>Id: {item?.id}</div>
                    </div>
                    <div>
                      Criação: {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                    <div>
                      Uuid: {item?.uuid}
                    </div>
                    <div>
                      Usuário: {item?.nome_usuario}
                    </div>
                  </div>
                  <div className="mb-1" style={{ borderTop: "1px solid #aaa", }}>
                    <div style={{ padding: "5px", textAlign: "center", }}>
                      <span className="font-weight-bold">{item?.observacao}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center" style={{ marginTop: "5px", }}>
                  {!item?.url_erro &&
                    item?.url_arquivo &&
                    <div>
                      <a
                        href={item?.url_arquivo}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Clique para abrir o arquivo
                      </a>

                    </div>}
                  {item?.url_erro &&
                    <div title="Clique para abrir o log de erros" >
                      <a
                        href={item?.url_erro}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "red" }}
                      >
                        Clique para abrir o log de erros
                      </a>

                    </div>}
                </div>
              </div>
            )
          })}
          {!carregando && !exibindoItens &&
            <div className="d-flex justify-content-center font-weight-bold"
              style={{ width: "100%" }}><h4>Sem dados para exibição</h4></div>}
        </div>
      </div >
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);

//-- Teste