import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as moment from 'moment'
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";

import "react-datepicker/dist/react-datepicker.css";
import { sendData, } from "../../../../helpers/auth";

import { uriList } from "../../../../helpers/config";
import { Pagination, } from "../../../layout";
import CustomInput from "../../../datepicker";
import {
  // getTiposDocumentos,
  width,
  formatCurrency
} from "../../../../helpers/general";

import NovaRetencao from "./IncluirRetencao";
import ExecutarRetencao from "./ExecutarRetencao";

let filterIni = {
  ocultarConcluidas: "sim",
  filtrarPor: "todos",
  cliente: "<todos>",
  pesquisa: "",
  convenio: "<todos>",
  status: "<todos>",
  startDate: new Date(),
  finalDate: new Date()
}

const Status = [
  { nome: 'Pendentes', value: "pendente" },
  { nome: 'Concluídas', value: "concluida" },
]

const Form = ({ ...props }) => {
  const [data, setData] = useState();
  const [filtro, setFiltro] = useState(filterIni);
  const [alterarItem, setAlterarItem] = useState(false)
  const [actualPage, setActualPage] = useState(0);
  const [qtdLinesPage, setQtdLinesPage] = useState(12);
  const [lastPage, setLastPage] = useState(0);
  const [carregando, setCarregando] = useState(false);
  const [total, setTotal] = useState(0)
  const [novaRetencao, setNovaRetencao] = useState(false)
  const [periodo, setPeriodo] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())
  const [estatisticas, setEstatisticas] = useState(false)

  const pesquisaRef = useRef();
  const arrayPagination = [12, 30, 60, 300, 600, 900]

  let exibindoItens = false

  useEffect(() => {
    if (!carregando)
      _getDados();
  }, [actualPage, qtdLinesPage]);

  function handlePesquisar() {
    setCarregando(true)
    setActualPage(0)
    setData(null)
    _getDados()
  }

  const handlePeriodo = () => {
    setPeriodo(!periodo)
  }

  const handleCloseNova = (select) => {
    setNovaRetencao(false)
    if (select)
      _getDados()
  }

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  function handleQtdLinesPage(e) {
    setQtdLinesPage(e.currentTarget.value)
  }

  async function _getDados() {
    setCarregando(true)

    console.log("retencoes._getDados(0): ", props?.user?.tipo)
    let content = {
      userid: props.user.pessoa_id,
      agenteid: ['AGENTE', 'OPERADOR PARCEIRO'].indexOf(props?.user?.tipo) >= 0 ? props.user.pessoa_id : null,
      operadorid: ['AGENTE', 'OPERADOR PARCEIRO', 'CLIENTE'].indexOf(props?.user?.tipo) < 0 ? props.user.pessoa_id : null,
      tipoUsuario: props?.user?.tipo,
      ocultarConcluidas: filterIni.ocultarConcluidas == "sim",
      actualPage: actualPage + 1,
      periodo: periodo ? 1 : 0,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      finalDate: moment(finalDate).format('YYYY-MM-DD'),
      qtdLinesPage,
      filtro
    }

    console.log("retencoes._getDados(1): ", content)

    await sendData({
      uri: uriList('retencoes'),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (!res || parseInt(res.status) > 200) throw new Error();
        console.log("retencoes.res(0): ", { data: res.data, res });

        setData(res.data)
        setLastPage(res.lastPage)
        setTotal(res.total)
        setCarregando(false)
        if (actualPage > res.lastPage)
          setActualPage(0)
      })
      .catch((e) => {
        setCarregando(false)
        console.log("retencoes.erro(0)");
        console.log(e);
        return false;
      });

    //-- Setar foco no campo de pesquisa
    if (pesquisaRef?.current)
      pesquisaRef.current.focus();
  }

  const handleOption = (e) => {
    if (e?.currentTarget) {
      let _filtro = { ...filtro, [e.currentTarget.id]: e.currentTarget.value }
      setFiltro(_filtro)
    }
  }

  const handleClose = (select) => {
    setAlterarItem(false)
    if (select)
      _getDados()
  }

  const limparFiltros = () => {
    setFiltro(filterIni);
    setData(null)
    setPeriodo(false)

    //-- Setar foco no campo de pesquisa
    if (pesquisaRef?.current)
      pesquisaRef.current.focus();
  }

  function transformarEmData(numeroDias, diasParaAdicionar = 0) {
    if (!numeroDias) return null

    const dataBase = new Date('1899-12-30'); // Ajustando para a base do Excel que começa em 1900
    const diasTotal = numeroDias + diasParaAdicionar;
    dataBase.setDate(dataBase.getDate() + diasTotal);
    return dataBase.toLocaleDateString(); // Retorna a data no formato local
  }

  const handleEstatisticas = async () => {
    console.log("Retenções - Estatísticas")
    if (estatisticas) {
      setEstatisticas(false)
      return true
    }

    setCarregando(true)

    console.log("retencoes.handleEstatisticas(0): ", props?.user?.tipo)
    let content = {
      userid: props.user.pessoa_id,
      agenteid: ['AGENTE', 'OPERADOR PARCEIRO'].indexOf(props?.user?.tipo) >= 0 ? props.user.pessoa_id : null,
      operadorid: ['AGENTE', 'OPERADOR PARCEIRO', 'CLIENTE'].indexOf(props?.user?.tipo) < 0 ? props.user.pessoa_id : null,
      tipoUsuario: props?.user?.tipo,
      ocultarConcluidas: filterIni.ocultarConcluidas == "sim",
      periodo: periodo ? 1 : 0,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      finalDate: moment(finalDate).format('YYYY-MM-DD'),
      filtro
    }

    console.log("retencoes.handleEstatisticas(1): ", content)

    await sendData({
      uri: uriList('estatisticasRetencao'),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (!res || parseInt(res.status) > 200) throw new Error();
        console.log("retencoes.handleEstatisticas(ok): ", res);

        setCarregando(false)
        setEstatisticas(res)
      })
      .catch((e) => {
        setCarregando(false)
        console.log("retencoes.handleEstatisticas(error)");
        console.log(e);
        return false;
      });
  }

  return (
    <>
      {novaRetencao && <NovaRetencao onCloseEv={handleCloseNova} />}
      {alterarItem && <ExecutarRetencao onCloseEv={handleClose} item={alterarItem} />}
      <div className="row mb-2" style={{ padding: "5px", border: "1px solid #E5E5E5" }}>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"}`} style={{ display: "flex" }} >
          <div className={` col-md-2 mr-1`}>
            <label htmlFor="periodo" className="no-margin">
              Período
            </label>
            <input
              id="periodo"
              type="checkbox"
              checked={periodo}
              style={{ width: "15px", height: "15px" }}
              onChange={() => handlePeriodo()}
            />
          </div>

          <div className={width() == "mobile" ? "col-md-12" : "col-md-6"} style={{ marginTop: width() != "mobile" ? "23px" : "" }}>
            <DatePicker
              locale={pt}
              selected={startDate}
              className="form-control"
              onChange={(date) => setStartDate(date)}
              withPortal
              disabled={!periodo}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>

          <div className={width() == "mobile" ? "col-md-12" : "col-md-6"} style={{ marginTop: width() != "mobile" ? "23px" : "" }}>
            <DatePicker
              locale={pt}
              selected={finalDate}
              className="form-control"
              onChange={(date) => setFinalDate(date)}
              withPortal
              disabled={!periodo}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
            />
          </div>
        </div>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"} ml-5`}>
          <label className="m-0 font-90">Ocultar Concluídas</label>
          <select
            onChange={handleOption}
            style={{
              lineHeight: "2.2em",
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Ocultar Retenções Concluídas"
            id="ocultarConcluidas"
          >
            <option value="nao" selected={filtro.ocultarConcluidas == "nao"}>Não</option>
            <option value="sim" selected={filtro.ocultarConcluidas == "sim"}>Sim</option>
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "2" : "12"}`}>
          <label className="m-0 font-90">Status</label>
          <select
            onChange={handleOption}
            style={{
              lineHeight: "2.2em",
              fontSize: "0.8em",
            }}
            className="form-control"
            title="Selecionar o Status"
            id="status"
          >
            <option selected={filtro.status == "<todos>"}>{"<todos>"}</option>
            {Status.map((item, index) => (<option value={item.nome}>{item.nome}</option>))}
          </select>
        </div>
        <div className={`col-md-${width() != "mobile" ? "3" : "12"}`}>
          <label className="m-0 font-90">Pesquisar(CPF, Cliente, Agente...)</label>
          <input type="text" ref={pesquisaRef} className="form-control" id="pesquisa"
            autoFocus={true} value={filtro.pesquisa} onChange={handleOption}
            title={"Digite para pesquisar por: CPF, Cliente, Agente, Convênio, Observacao, Descricao"} />
        </div>
        <div className={`col-md-${width() != "mobile" ? "1" : "12"} d-flex`}>
          <button className="btn btn-primary btn-block mt-4" onClick={handlePesquisar}>Pesquisar</button>
          <button className="btn btn-secondary btn-block mt-4 ml-1" onClick={limparFiltros}>Limpar</button>
          {["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) >= 0 &&
            <button className="btn btn-info btn-block mt-4 ml-3" onClick={handleEstatisticas}>Estatísticas</button>
          }
        </div>

      </div>

      <div className="row mb-2" style={{ padding: "10px", border: "1px solid #E5E5E5", justifyContent: "center", }}>
        {data && !estatisticas &&
          <div className="row d-flex col-12 mb-2" style={{ flexWrap: "wrap", flexDirection: "row", marginLeft: "10px" }} >
            <Pagination
              detailed={data}
              actual={actualPage}
              changePage={handlePage}
              qtdLinesPage={qtdLinesPage}
              lastPage={lastPage}
            />
            <div className="page-item ml-1" style={{ padding: "0.05rem" }}>
              <select
                defaultValue={qtdLinesPage}
                name="qtdLinesPage"
                id="qtdLinesPage"
                onChange={handleQtdLinesPage}
                style={{
                  width: "75px",
                  height: "100%",
                  textAlign: "center",
                  backgroundColor: "#EBEEE9",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                  backgroundImage:
                    "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                }}
              >
                {arrayPagination.map((lines, i) => (
                  <option
                    key={`lines-${i}`}
                    label={lines}
                    value={lines}
                  >
                    {lines}
                  </option>
                ))}
              </select>
            </div>
            <div className="page-item ml-1 mt-2" style={{ padding: "0.05rem" }}>
              <span className="ml-3 mr-1 ">Registros: <span className="font-weight-bold">{total}</span></span>
            </div>
          </div>}
        <div className="row d-flex col-12" style={{ flexWrap: "wrap", flexDirection: "row", }} >
          {carregando && <div className="d-flex justify-content-center font-weight-bold" style={{ width: "100%" }}><h4>Carregando dados, aguarde...</h4></div>}
          {estatisticas && !carregando &&
            <>
              <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`} >
                <div className="card" style={{ border: `1px solid "#215F04"`, }}>
                  <div className="col-12 mb-1" style={{ padding: "0px" }}>
                    <div style={{
                      backgroundColor: "lightgray", width: "100%", border: "1px solid #E5E5E5", flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontWeight: "bold"
                    }}>BANCOS QUE MAIS PORTAM</div>
                    <div style={{ padding: "0px" }}>
                      <div style={{ display: "flex", marginLeft: "1px", fontWeight: "bold" }}>
                        <div className="col-2">#</div>
                        <div className="col-5">Banco</div>
                        <div className="col-3 text-right">Total</div>
                        <div className="col-2 text-right">Qtdade</div>
                      </div>

                      {estatisticas?.TotaisBancoPort &&
                        estatisticas?.TotaisBancoPort?.map((item, index) => (
                          index < 5 &&
                          <div style={{ display: "flex", marginLeft: "1px", }}>
                            <div className="col-2">{index + 1}</div>
                            <div className="col-5">{item?.banco_port}</div>
                            <div className="col-3 text-right">{formatCurrency(parseFloat(item?.totalCcb || 0).toFixed(2))}</div>
                            <div className="col-2 text-right">{parseFloat(item?.qtdade || 0).toFixed(0)}</div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`} >
                <div className="card" style={{ border: `1px solid "#215F04"`, }}>
                  <div className="col-12 mb-1" style={{ padding: "0px" }}>
                    <div style={{
                      backgroundColor: "lightgray", width: "100%", border: "1px solid #E5E5E5", flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontWeight: "bold"
                    }}>AGENTES QUE MAIS PORTAM</div>
                    <div style={{ padding: "0px" }}>
                      <div style={{ display: "flex", marginLeft: "1px", fontWeight: "bold" }}>
                        <div className="col-2">#</div>
                        <div className="col-5">Agente</div>
                        <div className="col-3 text-right">Total</div>
                        <div className="col-2 text-right">Qtdade</div>
                      </div>

                      {estatisticas?.TotaisAgente &&
                        estatisticas?.TotaisAgente?.map((item, index) => (
                          index < 5 &&
                          <div style={{ display: "flex", marginLeft: "1px", }}>
                            <div className="col-2">{index + 1}</div>
                            <div className="col-5">{item?.agente}</div>
                            <div className="col-3 text-right">{formatCurrency(parseFloat(item?.totalCcb || 0).toFixed(2))}</div>
                            <div className="col-2 text-right">{parseFloat(item?.qtdade || 0).toFixed(0)}</div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`} >
                <div className="card" style={{ border: `1px solid "#215F04"`, }}>
                  <div className="col-12 mb-1" style={{ padding: "0px" }}>
                    <div style={{
                      backgroundColor: "lightgray", width: "100%", border: "1px solid #E5E5E5", flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontWeight: "bold"
                    }}>TOTALIZAÇÃO POR CONCLUSÃO</div>
                    <div style={{ padding: "0px" }}>
                      <div style={{ display: "flex", marginLeft: "1px", fontWeight: "bold" }}>
                        <div className="col-2">#</div>
                        <div className="col-5">Conclusão</div>
                        <div className="col-3 text-right">Total</div>
                        <div className="col-2 text-right">Qtdade</div>
                      </div>

                      {estatisticas?.TotaisStatus &&
                        estatisticas?.TotaisStatus?.map((item, index) => (
                          index < 5 &&
                          <div style={{ display: "flex", marginLeft: "1px", }}>
                            <div className="col-2">{index + 1}</div>
                            <div className="col-5">{item?.conclusao || '<pendente>'}</div>
                            <div className="col-3 text-right">{formatCurrency(parseFloat(item?.totalCcb || 0).toFixed(2))}</div>
                            <div className="col-2 text-right">{parseFloat(item?.qtdade || 0).toFixed(0)}</div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>


            </>
          }
          {!estatisticas && !carregando && data && data.map((item, index) => {
            exibindoItens = true
            const _concluida = ["concluida", "concluido"].indexOf(item.status) >= 0
            const _cancelada = ["cancelada", "cancelado"].indexOf(item.status) >= 0
            return (
              // <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`} style={{ cursor: (!_concluida && !_cancelada) ? "pointer" : "", }}
              <div className={`${width() == "mobile" ? "col-12  " : "col-4"} mb-2`} style={{ cursor: (!_cancelada) ? "pointer" : "", }}
                onClick={() => {
                  if (!_cancelada)
                    setAlterarItem(item)
                }}               >
                <div className="card"
                  style={{
                    border: `1px solid ${_concluida && !_cancelada ? "#215F04" : "#8F1502"}`,
                    opacity: (_concluida || _cancelada) ? "50%" : "100%"
                  }}>
                  <div className="col-12 mb-1" style={{ padding: "3px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>Status: {item?.status}</div>
                      <div>Contrato: {item?.contrato}</div>
                      <div>Id: {item?.id}</div>
                    </div>
                    <div>
                      Dt Atualização: {moment(item?.updated_at || item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                    {item?.proposta_uuid &&
                      <>
                        <div style={{ marginBottom: "5px", marginTop: "5px", borderBottom: "1px solid #aaa", padding: "0px" }}></div>
                        <div style={{ fontWeight: "bold" }}>
                          Cliente: {item?.cliente_cpf + '-' + item?.cliente_nome}
                        </div>
                        <div >
                          Proposta: {item?.propostaid.toString() + ' - ' + item?.proposta_uuid}
                        </div>
                        <div >
                          Agente: {item?.agente_nome}
                        </div>
                        <div>
                          Tabela: {item?.tabela_nome}
                        </div>
                        {item?.conclusao &&
                          <div>
                            Conclusão: {item?.conclusao?.toUpperCase()}
                          </div>}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>Valor: {formatCurrency(item?.valor_total)}</div>
                          <div>Parcela: {formatCurrency(item?.valor_parcelas)}</div>
                          <div>Prazo: {item?.prazo}</div>
                          <div>Operação: {item?.operacao}</div>
                        </div>
                        {item?.dados_intensionamento && <>
                          <div style={{ backgroundColor: "lightgray", width: "100%", border: "1px solid #E5E5E5", display: "flex", justifyContent: "center", fontWeight: "bold" }}>DADOS DO INTENCIONAMENTO</div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>Banco: {item?.dados_intensionamento?.m_banco_prop}</div>
                            <div>Taxa Oferecida: {formatCurrency(parseFloat(item?.dados_intensionamento?.taxa_oferecida).toFixed(2))}</div>
                            <div>Parcela: {formatCurrency(item?.dados_intensionamento?.z_parcela)}</div>
                            <div>Tempo: {item?.dados_intensionamento?.["tempo na cart (dias)"]}</div>
                            <div>Taxa Ret: {formatCurrency(item?.dados_intensionamento?.["tx retencao"])}</div>
                            <div>Limite: {transformarEmData(item?.dados_intensionamento?.b_dt_sol, 5)}</div>
                          </div>
                        </>}
                      </>
                    }
                  </div>
                </div>
              </div>
            )
          })}
          {!carregando && !estatisticas && !exibindoItens && <div className="d-flex justify-content-center font-weight-bold" style={{ width: "100%" }}><h4>Sem dados para exibição</h4></div>}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Form);

