import React, { useState, useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { uriList } from '../../../../helpers/config';
import { sendData } from "../../../../helpers/auth";
import { Spinner } from "react-bootstrap";
import { width, formatCurrency } from '../../../../helpers/general';


import ModalContent from '../../../layout/ModalContent';
import { connect } from 'react-redux'
import "./index.css"
import * as moment from 'moment'

const AuditAssinatura = ({ ...props }) => {
  const [data, setData] = useState([])
  const [modal, setModal] = useState('');
  const [erro, setErro] = useState(false)
  const [loading, setLoading] = useState(false)

  function AguardeLoc() {
    return (
      <div
        style={{
          margin: "10px",
          fontSize: width() == "mobile" ? "small" : "",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          animation="border"
          size={width() == "mobile" ? "sm" : "md"}
          style={{ marginRight: "5px", color: "#777575" }}
        />
        Aguarde, carregando dados...
      </div>
    )
  }

  useEffect(() => {
    console.log(props.res)
    async function CallBack() {
      try {
        setErro(false)
        setLoading(true)
        setData(false)
        console.log("AuditAssinatura.useEffect: ", props.proposta)
        await sendData({ uri: uriList('listarAuditAssinatura'), content: { uuid: props.proposta }, method: "POST" })
          .then(res => {
            console.log("index.AuditAssinatura(ok): ", res)
            if (res?.status?.toString()?.toLowerCase() != 'ok' || !res?.data?.parts) {
              setErro(true)
              // throw new Error();
              console.log("index.AuditAssinatura(ok-erro): ", JSON.stringify(res))
            }
            else {
              console.log("index.AuditAssinatura(ok-1): ", res?.data)
              let _res = res?.data?.parts[0]

              console.log("index.AuditAssinatura(ok-2): ", _res)
              setData(_res)

              setLoading(false)
            }
          })
      } catch (error) {
        setErro(true)
        setLoading(false)
        console.log("index.AuditAssinatura - Erro: ", JSON.stringify(error))
      }

      return true
    }

    CallBack()

  }, []);

  function handleClose(e, close) {
    if (e)
      e.preventDefault()
    if (props.onCloseEv)
      props.onCloseEv(close)
  }

  const handleModal = () => {
    setModal(!modal)
  }

  const ExibirRes = ({ json }) => {
    if (!json || erro)
      return

    const highlightedJSON = jsonObj =>
      Object.keys(jsonObj).map(key => {
        const value = jsonObj[key];
        let valueType = typeof value;
        const isSimpleValue =
          ["string", "number", "boolean"].includes(valueType) || !value;
        if (isSimpleValue && valueType === "object") {
          valueType = "null";
        }
        return (
          <div key={key} className="line">
            <span className="key">{key}:</span>
            {isSimpleValue ? (
              <span className={valueType}>{`${value}`}</span>
            ) : (
              highlightedJSON(value)
            )}
          </div>
        );
      });
    return <div className="json">{highlightedJSON(json)}</div>;
  };

  return (
    <>


      <ModalContent title="AuditAssinatura" onCloseEv={handleClose} size="lg" concorda={false} >
        <div>
          {!modal &&
            <ListGroup>
              <ListGroup.Item style={{ backgroundColor: "#E8E8E8", color: "#636363" }}>
                <strong>Cliente:</strong> {props.detalhes ? props.detalhes.nome + " - " + props.detalhes.cpf + " - " + props.detalhes.celular : ""}
                <br />
                <strong>Status Atual:</strong> {props.detalhes ? props.detalhes.posicao : ""}
                <br />
                <strong>uuid: </strong> {props.detalhes?.uuid || props.detalhes?.proposta_uuid}
              </ListGroup.Item>
              {loading && !erro && <AguardeLoc />}
              {!loading && !erro &&
                <ListGroup.Item>
                  <ExibirRes json={data} />
                </ListGroup.Item>
              }
            </ListGroup>
          }
        </div>
      </ModalContent>

    </>
  )
}


function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  }
}

export default connect(
  mapStateToProps
)(AuditAssinatura)
