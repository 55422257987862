import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";

import IconImage from "../images/cloud.png";
import FileImage from "../images/file.png";
// import FileImage from '../images/img_file.jpg'
import Modal from "../components/modal";

import { InputAlert } from "./layout";
import { sendMultiPartData } from "../helpers/auth";
import { getCookie } from "../helpers/general";
import { uriList } from "../helpers/config";

import { connect } from "react-redux";
const minImageSize = { width: 250, height: 250 };

const Dropper = ({ ...props }) => {
  const [modal, setModal] = useState("");
  // const [maxSize, setMaxSize] = useState(10485760);
  const [maxSize, setMaxSize] = useState(3145728 * (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0 ? 10 : 1));
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [tipo, setTipo] = useState(props?.tipo);

  useEffect(() => {
    // console.log("DROPZONE(disabled): ", props.disabled);
  }, []);

  useEffect(() => {
    console.log("DROPZONE(tipo): ", props?.tipo);
    setTipo(props?.tipo);
  }, [props?.tipo]);

  const openInfoModal = ({ title, text }) => {
    setModal(<Modal title={title} text={text} onClick={() => setModal("")} />);
  };

  const handleImageUpload = async (file) => {
    // const file = event.target.files[0];

    // console.log("dropzone.handleImageUpload(tipo): ", tipo)
    if (file && ['d_pessoal', 'd_pessoalv'].indexOf(props?.tipo) >= 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          setImageSize({ width, height });
          console.log("dropzone.handleImageUpload: ", { width, height })
        };
        img.src = e.target.result;
      };


      reader.readAsDataURL(file);
    }

  };

  useEffect(() => {
    if (imageSize && imageSize.width && imageSize.height) {
      if (imageSize.width < minImageSize.width || imageSize.height < minImageSize.height)
        openInfoModal({
          title: "Saiba mais: Tamanho Mínimo",
          text: [`Tamanho mínimo de imagens é de: ${minImageSize.width} x ${minImageSize.height} pixels`],
        });
    }
  }, [imageSize]);

  return (
    <div style={{ marginTop: props.marginTop }}>
      <Dropzone
        name={props.name}
        minSize={props.minSize}
        maxSize={props.maxSize}
        accept={`.jpg,.jpeg,.bmp,.gif,.png,.doc,.docx,.svg,.pdf,.ico,.json${['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo?.toUpperCase()) >= 0 ? ',.mp3,.mp4' : ''}`}
        onDrop={(acceptedFiles) => {
          try {
            // console.log("dropzone - ini");
            let files = acceptedFiles.map(async (file, i) => {
              // const img = new Image();

              let url = URL.createObjectURL(file);
              let ext = file.name.split(".");
              //   console.log("dropzone - 0");
              //   console.log("Dados do arquivo:\n", file);
              ext = ext[ext.length - 1];
              //   console.log("dropzone - 1");
              // img.src = url;


              file.preview = url;
              file.ext = ext;
              //   console.log("dropzone - 2");
              if (file.size > maxSize) {
                openInfoModal({
                  title: "Saiba mais: Tamanho Máximo",
                  text: [`Tamanho máximo de arquivos é de: ${maxSize ? (maxSize / 1024 / 1024) : 3}mB`],
                });
                return false;
              }
              // throw new Error(`Tamanho máximo do arquivo: ${(maxSize / 1024 / 1024).toString()}mb`);
              console.log("dropzone - 2.1(tipo): ", tipo);
              if (['jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'png', 'msword'].indexOf(ext?.toLowerCase()) >= 0)
                handleImageUpload(file)
              //   console.log("dropzone - 3");
              // console.log("dropzo;ne(file): ", { width: file?.naturalWidth, height: file?.naturalHeight })
              !props.telaAux &&
                sendDoc(
                  props.name,
                  props.proposta,
                  file,
                  props.descricao,
                  props,
                  props.cliente || props.clienteDados?.id
                );
              if (props.telaAux) props.change([file]);

              //   console.log("dropzone - 4");
              return file;
            });
          } catch (e) {
            // console.log("Erro ao fazer OnDrop....: ", e);
            openInfoModal({
              title: "Saiba mais: Tamanho Máximo",
              text: [`Tamanho máximo de arquivos é de: ${maxSize ? (maxSize / 1024 / 1024) : 3}mB`],
            });
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <p
              className="no-margin text-center"
              style={{ fontSize: "12px", fontWeight: "bold", color: "#696969" }}
            >
              {props.title}
            </p>
            <section
              className={
                props.retirarLegenda ? "drop-box" : "drop-box col-md-6 mb-4"
              }
              style={{ width: props.retirarLegenda ? "100%" : "" }}
            >
              <div
                {...getRootProps({
                  onFocus: (e) => (e.currentTarget.style.outline = "none"),
                })}
              >
                <input
                  {...getInputProps({
                    multiple: false,
                    onClick: (e) => props.data && e.preventDefault(),
                    accept: props.accept,
                    disabled: props.disabled,
                  })}
                />
                <p
                  style={{
                    textAlign: !props.data && "center",
                    position: "relative",
                  }}
                  className="lead text-info box"
                >
                  {!props.data ? (
                    <>
                      {" "}
                      <img
                        src={IconImage}
                        alt="Cloud-icon"
                        className="img-fluid custom-image d-block"
                        style={{ margin: "10px auto" }}
                      />
                      {props.retirarLegenda ? (
                        ""
                      ) : (
                        <span style={{ margin: "0 auto", fontSize: "0.85em" }}>
                          Clique ou arraste o documento
                        </span>
                      )}
                    </>
                  ) : (
                    <span
                      style={{ padding: "0 0", width: "100%", height: "100%" }}
                      key={`preview`}
                      className="d-block"
                    >
                      <a
                        href={props.data[0].preview}
                        // onClick={handleDownload}
                        download
                        target="_blank"
                      >
                        <img
                          alt="Preview"
                          // onclick={handleDownload}
                          style={{
                            width: "100px",
                            height: "100%",
                            float: "left",
                          }}
                          src={
                            props.data[0].ext === "jpg" ||
                              props.data[0].ext === "jpeg" ||
                              props.data[0].ext === "png"
                              ? props.data[0].preview
                              : FileImage
                          }
                        />
                      </a>

                      {props.retirarLegenda ? (
                        ""
                      ) : (
                        <span
                          className="text-center span-del d-block"
                          onClick={() => props.del(props.name)}
                          style={{ fontSize: "0.79em", paddingLeft: "5px" }}
                        >
                          Remover
                        </span>
                      )}
                    </span>
                  )}
                </p>
              </div>

              <InputAlert display={props.warning} field={props.field} />
            </section>
            {modal}
          </>
        )}
      </Dropzone>
    </div>
  );
};

const sendDoc = async (tipo, uuid, file, descricao, props, cliente) => {
  if (props && props.aguardar) props.aguardar(true);

  //   console.log("SendDoc - aguardar: ", props.aguardar);
  let dObj = new FormData();
  let [latitude, longitude] = getCookie({ label: "geolocation" })
    ? getCookie({ label: "geolocation" }).split(",")
    : ["", ""];

  dObj.append("tipo", tipo);
  dObj.append("uuid", uuid);
  if (!uuid) dObj.append("cliente_id", cliente);
  dObj.append("file", file);
  if (descricao) dObj.append("descricao", descricao);
  dObj.append("ip", getCookie({ label: "ip" }));
  dObj.append("geolocation", JSON.stringify({ latitude, longitude }));
  dObj.append("data_arquivo", file.lastModifiedDate);

  await sendMultiPartData({
    uri: uriList("documentos"),
    content: dObj,
    signal: null,
    method: "POST",
  })
    .then((res) => {
      if (res.status && parseInt(res.status) !== 200)
        throw new Error(res.message[0].message);
      //   console.log("DropZone - Documento enviado: ", [file]);
      if (!props.data && !props.telaAux) props.change(props.name, [file]);

      if (props && props.aguardar) props.aguardar(false);
    })
    .catch((e) => {
      //-- Diogo - Voltar?
      // props.del(props.name)
      //   console.log("DropZone - Error: ", e.message);
      if (props && props.aguardar) props.aguardar(false);
    });
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Dropper);
