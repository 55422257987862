const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'mil' },
    { value: 1e6, symbol: 'MI' },
]

export const convertToBrl = (valor) => {
    return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}

export const convertNumberWithMagnitude = (number) => {
    if (Math.abs(number) >= 1e3 && Math.abs(number) < 1e6) {
        return (number / 1e3).toFixed(2) + ' Mil'
    } else if (Math.abs(number) >= 1e6) {
        return (number / 1e6).toFixed(3) + ' Milhões'
    } else {
        if (number.toString().includes('.')) {
            return Number(number).toFixed(2)
        } else {
            return number
        }
    }
}
