import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../helpers/general";
import { width, } from "../helpers/general";
import {
  sendData,
} from "../helpers/auth";
import { uriList, enviroment } from "../helpers/config";
import { connect } from "react-redux";
import { setAlterarProposta, setProposta } from "../store/actions/dados";
import { Redirect } from "react-router-dom";
import * as moment from "moment";

function Status({ ...props }) {
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState(null)
  const [showIn100,] = useState(props.detalhes?.convenio == 'INSS' && props.detalhes?.posicao != 'Simulacao');
  const [obtendoUrl, setObtendoUrl] = useState(false);


  let link =
    (process?.env?.REACT_FRONT_END_URL || "https://simulador.incontadigital.com.br") +
    `/preenchercli/` + data?.uuid || data?.proposta_uuid;

  async function obterUrlAssinatura(callback) {
    console.log("obterUrlAssinatura: ", props.proposta)
    setObtendoUrl(true)
    const uri = uriList('obterURLAssinatura');
    const content = {
      "uuid": props.proposta
    }

    console.log("obterUrlAssinatura: ", { uri, content })

    await sendData({
      uri,
      content,
      method: "POST",
    })
      .then((res) => {
        setObtendoUrl(false)
        console.log("obterUrlAssinatura(res): ", res)
        if (res?.status && parseInt(res.status) > 200) throw new Error();

        if (callback)
          callback(res?.data);

      })
      .catch((e) => {
        setObtendoUrl(false)
        // alert("Erro ao buscar URL.");
      });

    return true
  }

  async function copiarTexto() {
    console.log("copiarTexto(0)")
    const callback = (_data) => {
      link = _data?.signUrl || _data?.data?.signUrl || _data?.data?.data?.signUrl || props?.detalhes?.url_download || link;
      console.log("copiarTexto(callback): ", link)
      if (link && navigator && navigator.clipboard) {
        navigator.clipboard.writeText(link);
      }
    }

    if (props.detalhes?.api_assinatura?.toLowerCase() == "confia") {
      if (!props.detalhes?.url_download) {
        await obterUrlAssinatura(callback)
        return true
      }
    }
    callback();
  }

  const copiarLinkAutorizacao = () => {
    const cpf = props.detalhes?.cpf;
    if (cpf) {
      let link =
        (process?.env?.REACT_FRONT_END_URL ||
          "https://simulador.incontadigital.com.br") +
        `/preenchercli/${cpf?.replace(/\D/g, "")}/inss_in100`;
      if (navigator && navigator.clipboard) navigator.clipboard.writeText(link);
    }
  };

  async function _getProposta() {
    console.log("status._getProposta: ", props.detalhes)
    sendData({ uri: uriList('acompanharProposta'), content: { uuid: props.detalhes.uuid }, method: 'POST' })
      .then(res => {
        setData(previous => ({ ...previous, ...res }))
      })
      .catch(e => {
        // setData(false);
      });
    // setData(props.detalhes);
  }

  useEffect(() => {
    console.log("views status: ", props.detalhes.confirmada);
    // copiarTexto();
    _getProposta()
  }, []);

  function onClick(e) {
    e.preventDefault();
    if (!props.preencherCli) {
      console.log("toggleStep: ", props.toggleStep);
      if (props.toggleStep && props.user) {
        //-- Caso esteja alterando uma proposta sai da alteração
        if (props.alterarProposta)
          props.setAlterarProposta(false, props.detalhes);

        props.toggleStep();
      } else if (props.toggleStep) props.toggleStep();

      //-- Codigo inserido para que o Simulador em modo autocontratação reexiba as propostas do cliente
      console.log("PropsUser: ", props.user);
      if (!props.user) {
        console.log("PropsUser(1)");
        props.alterarProposta("limpar");
        setRedirect(true);
      }
    }
  }

  return (
    <>
      {redirect && <Redirect to="./" />}
      <div
        style={{
          paddingBottom: !props.preencherCli && !props.modal ? "20px" : "0px",
          marginTop: !props.modal ? "15px" : "",
        }}
        className="col-md-8 m-auto custom-status card"
      >
        {!props.hideTitle && (
          <div className="row no-margin">
            <div className="col-12">
              <h3
                className="text-center font-weight-bold custom-title-section py-3 m-0"
                style={{ color: "#3E6C84" }}
              >
                {props.title}
              </h3>
            </div>
          </div>
        )}

        {data &&
          <div className={width() === "mobile" ? "p-1" : ""}>
            <div
              style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
              className={`row ${width() === "mobile" ? "mb-1" : "mb-2"}`}
            >
              <div className="col-md-12 px-0">
                {/* <h6 className="no-margin">Nome</h6> */}
                <h6 className="font-weight-bold m-0">{data?.nome}</h6>
                <div >
                  <h6 className="m-0 d-flex" style={{ fontSize: "0.7em" }}>
                    Celular: {data?.celular}
                  </h6>
                </div>
                <div >
                  <h6 className="m-0 d-flex" style={{ fontSize: "0.7em" }}>
                    Agente: {data?.agente}
                  </h6>
                </div>
                {!props.preencherCli && props.user?.tipo == "ADMINISTRADOR" && (
                  <div >
                    <h6 className="m-0 d-flex" style={{ fontSize: "0.7em" }}>
                      uuid: {data?.proposta_uuid || data?.uuid}{" "}
                      {data?.procreditoid ? "  - ProcreditoID: " + data?.procreditoid : ""}
                    </h6>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
              className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
            >
              <div className="col-md-4 col-4 px-0">
                <h6 className="no-margin">CPF</h6>
                <h6 className="font-weight-bold m-0">{data?.cpf}</h6>
              </div>
              <div className="col-md-4 col-4 px-0">
                <h6 className="no-margin">
                  {data?.tipo_convenio != "ORIGINAL"
                    ? `Cédula`
                    : "Contrato/Sim"}
                </h6>
                <h6 className="font-weight-bold m-0">
                  {data?.tipo_convenio != "ORIGINAL"
                    ? data?.cedula_numero || data?.cedula_port
                    : data?.codigo_cli || data?.contrato}
                </h6>
              </div>
              <div className="col-md-4 col-4 px-0">
                <h6 className="no-margin">Convênio</h6>
                <h6 className="font-weight-bold m-0">{data?.convenio}</h6>
              </div>
            </div>

            {!props.portabilidade &&
              data?.operacao?.toLowerCase() != "portabilidade" && (
                <>
                  <div
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                    style={{
                      backgroundColor: "rgba(127, 200, 233, 0.18)",
                      border: "1px solid rgba(0, 0, 0, .125)",
                      padding: width() !== "mobile" ? "0 10px" : "",
                    }}
                  >
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Valor do Crédito</h6>
                      <h6 className="font-weight-bold m-0">
                        R$ {format(data?.valor_liquido)}
                      </h6>
                    </div>
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Prazo</h6>
                      <h6 className="font-weight-bold m-0">
                        {data?.prazo} {width() !== "mobile" && "meses"}
                      </h6>
                    </div>

                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">
                        {data?.tipo_convenio == "FGTS"
                          ? "Total das Parcelas"
                          : "Valor das Parcelas"}
                      </h6>
                      <h6 className="font-weight-bold m-0">
                        R$ {format(data?.valor_parcelas)}
                      </h6>
                    </div>
                  </div>

                  <div
                    style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                  >
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">IOF</h6>
                      <h6 className="font-weight-bold m-0">
                        R$ {format(data?.iof)}
                      </h6>
                    </div>

                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">JurosM</h6>
                      <h6 className="font-weight-bold m-0">
                        {format(data?.taxa_juros_mensal)} %
                      </h6>
                    </div>

                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">JurosA</h6>
                      <h6 className="font-weight-bold m-0">
                        {format(data?.taxa_juros_anual)} %
                      </h6>
                    </div>
                  </div>
                </>
              )}

            {(props.portabilidade ||
              data?.operacao?.toLowerCase() == "portabilidade") && (
                <>
                  {
                    <div
                      className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                      style={{
                        backgroundColor: "rgba(127, 200, 233, 0.18)",
                        border: "1px solid rgba(0, 0, 0, .125)",
                        padding: width() !== "mobile" ? "0 10px" : "",
                      }}
                    >
                      {props.user && !props.preencherCli && (
                        <>
                          <div className="col-md-4 col-4 px-0">
                            <h6 className="no-margin">Total Bruto</h6>
                            <h6 className="font-weight-bold m-0">
                              R$ {format(data?.valor_total)}
                            </h6>
                          </div>
                          <div className="col-md-4 col-4 px-0">
                            <h6 className="no-margin">Prazo</h6>
                            <h6 className="font-weight-bold m-0">
                              {data?.prazo} {width() !== "mobile" && "meses"}
                            </h6>
                          </div>

                          <div className="col-md-4 col-4 px-0">
                            <h6 className="no-margin">
                              {data?.tipo_convenio == "FGTS"
                                ? "Total das Parcelas"
                                : "Valor das Parcelas"}
                            </h6>
                            <h6 className="font-weight-bold m-0">
                              R$ {format(data?.valor_parcelas)}
                            </h6>
                          </div>
                        </>
                      )}
                    </div>
                  }
                  <div className="row">
                    <div
                      style={{
                        opacity: "0.8",
                        background: "#CCC",
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      Dados da Portabilidade
                    </div>
                  </div>

                  <div
                    style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                  >
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin"> Saldo Devedor</h6>
                      <h6 className="font-weight-bold m-0">
                        R$ {format(data?.saldo_devedor)}
                      </h6>
                    </div>

                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Parcelas Restantes</h6>
                      <h6 className="font-weight-bold m-0">
                        {parseFloat(data?.parcelas_total).toFixed(0)} meses
                      </h6>
                    </div>

                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Parcela Portada</h6>
                      <h6 className="font-weight-bold m-0">
                        R$ {format(data?.parcela_original)}
                      </h6>
                    </div>
                  </div>

                  <div
                    style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                  >
                    {props.user && !props.preencherCli && (
                      <div className="col-md-4 col-4 px-0">
                        <h6 className="no-margin">Valor Líquido</h6>
                        <h6 className="font-weight-bold m-0">
                          R$ {format(data?.valor_liquido)}
                        </h6>
                      </div>
                    )}
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">JurosM</h6>
                      <h6 className="font-weight-bold m-0">
                        {format(data?.taxa_juros_mensal)} %
                      </h6>
                    </div>

                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">JurosA</h6>
                      <h6 className="font-weight-bold m-0">
                        {format(data?.taxa_juros_anual)} %
                      </h6>
                    </div>
                  </div>

                  <div
                    style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                  >
                    <div className="col-md-8 col-8 px-0">
                      <h6 className="no-margin">Banco Portado</h6>
                      <h6 className="font-weight-bold m-0">
                        {data?.banco_original}
                      </h6>
                    </div>
                    <div className="col-md-4 col-4 px-0">
                      <h6 className="no-margin">Previsão Saldo</h6>
                      <h6 className="font-weight-bold m-0">
                        {data?.previsao_saldo &&
                          moment(data?.previsao_saldo).format("DD/MM/YYYY")}
                      </h6>
                    </div>
                  </div>

                  <div
                    style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
                    className={`row ${width() === "mobile" ? "mb-1" : "mb-3"}`}
                  >
                    <div className="col-md-6 col-6 px-0">
                      <h6 className="no-margin">Contrato Portado</h6>
                      <h6 className="font-weight-bold m-0">
                        {data?.contrato_original}
                      </h6>
                    </div>
                    <div className="col-md-6 col-6 px-0">
                      <h6 className="no-margin">N. Portabilidade</h6>
                      <h6 className="font-weight-bold m-0">
                        {data?.numero_portabilidade}
                      </h6>
                    </div>
                  </div>

                  <div
                    className={`row`}
                    style={{
                      backgroundColor: "lightGray",
                      border: "1px solid rgba(0, 0, 0, .125)",
                      padding: width() !== "mobile" ? "0 10px" : "",
                    }}
                  >
                    {/* <div style={{ textAlign: "center", width: "100%", }}> <h5>Dados da Portabilidade</h5></div> */}
                  </div>
                </>
              )}

            <div
              style={{ padding: width() !== "mobile" ? "0 10px" : "" }}
              className="row mb-3 d-flex"
            >
              <div className="col-md-4 col-4 px-0">
                <h6 className="no-margin">Dt. Cadastro</h6>
                <h6 className="font-weight-bold m-0">
                  {moment(data?.created_at).format("DD/MM/YYYY")}
                  {/* {new Date(data?.created_at).toLocaleDateString()} */}
                </h6>
              </div>
              <div className="col-md-4 col-4 px-0">
                <h6 className="no-margin">Última Alteração</h6>
                <h6 className="font-weight-bold m-0">
                  {moment(data?.updated_at).format("DD/MM/YYYY")}
                  {/* {new Date(data?.updated_at).toLocaleDateString()} */}
                </h6>
              </div>
              <div className="col-md-4 col-4 px-0">
                <h6 className="no-margin">Tipo de Operação</h6>
                <h6
                  className="font-weight-bold m-0"
                  style={{ fontSize: width() == "mobile" ? "x-small" : "" }}
                >
                  {data?.operacao}
                </h6>
              </div>
            </div>

            <div
              className={`row ${width() === "mobile" && !props.modal
                ? "mb-1 mt-2"
                : !props.modal
                  ? "mb-3"
                  : ""
                }`}
              style={{
                backgroundColor: "rgba(127, 200, 233, 0.18)",
                border: "1px solid rgba(0, 0, 0, .125)",
                padding: width() !== "mobile" ? "0 10px" : "",
                bottom: props.preencherCli ? "0x" : "",
              }}
            >
              <div className="col-12">
                <h5
                  className="no-margin text-center"
                  style={{ fontSize: width() === "mobile" ? "1em" : "" }}
                >
                  Status Atual
                </h5>
                <h4 className="font-weight-bold text-center m-0">
                  {data?.posicao || data?.status_atual}
                </h4>
                {props.preencherCli && <br />}
              </div>
            </div>

            {!props.hideButton && (
              <div
                style={{ padding: "0 20px" }}
              // className="row buttons"
              >
                {!props.preencherCli && (
                  <button
                    style={{
                      fontSize: "1.1em",
                      margin: "15px 0",
                      backgroundColor: "#3E6C84",
                    }}
                    className="btn btn-info text-light font-weight-bold col-12"
                    onClick={onClick}
                  >
                    OK
                  </button>
                )}
              </div>
            )}


            {!props.preencherCli && (
              <div className="row " style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: width() !== "mobile" ? "0 20px" : "", }}>
                <button
                  type="button"
                  class={`btn btn-outline-dark ${!showIn100 && props.user?.tipo != "ADMINISTRADOR" ? 'col-12' :
                    props.user?.tipo != "ADMINISTRADOR" ? "col-6" : showIn100 ? "col-4" : "col-6"}`}
                  style={{
                    fontSize: "10px",
                    color: "gray",
                  }}
                  onClick={copiarTexto}
                >
                  Copiar Link da Proposta
                </button>

                {props?.user?.tipo == "ADMINISTRADOR" && (
                  <a
                    href={`${link}/internaluser`}
                    class={`btn btn-outline-dark ${showIn100 ? 'col-4' : 'col-6'}`}
                    style={{
                      fontSize: "10px",
                      color: "gray",
                      textDecoration: "none",
                    }}
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acessar Proposta do Cliente
                  </a>
                )}
                {showIn100 &&
                  <button
                    type="button"
                    class={`btn btn-outline-dark ${props.user?.tipo != "ADMINISTRADOR" ? "col-6" : "col-4"
                      }`}
                    style={{
                      fontSize: "10px",
                      color: "gray",
                    }}
                    onClick={copiarLinkAutorizacao}
                  >
                    Copiar link da Autorização
                  </button>}
              </div>
            )}
          </div>}
      </div>
    </>
  );
}

const format = (value) => {
  value = formatCurrency(value);

  return value;
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    user: state.dados.user,
    alterarProposta: state.dados.alterarProposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Status);
