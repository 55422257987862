import React from 'react';

class CustomInput extends React.Component {
    render() {
        return (
            <button 
            className={`form-control font-90`}
            disabled={this.props.disabled} 
            onClick={this.props.onClick}>
                {this.props.value}
            </button>
        );
    }
}

export default CustomInput;