import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import { swalError, confirmSwal } from "../../../swal";
import { uriList } from "../../../../helpers/config";
// import { sendMultiPartData } from "../../../../helpers/auth";
import { getCookie, width } from "../../../../helpers/general";
import { sendData } from "../../../../helpers/auth";
import { connect } from "react-redux";
import CardCollapse from "../../../layout/CardCollapse";
import * as moment from "moment";


const ResolverPendencia = ({ ...props }) => {
  const [dados,] = useState(props.dados)
  const [loaded, setLoaded] = useState(false)
  const [historico, setHistorico] = useState([])
  const [collapsed, setCollapsed] = useState(props.dados.status != "Aguardando Analise")
  const [data, setData] = useState({
    pendencia: props.dados,
    data: new Date().toISOString().substr(0, 10),
    file: null,
    observacao: "",
    proposta_uuid: props.proposta,
    ip: getCookie({ label: "ip" }),
  });
  // let _tipos = getTiposDocumentos();

  const [aguarde, setAguarde] = useState(false);

  // const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    getDados()
  }, []);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    if (props.onCloseEv) props.onCloseEv(close);
  }

  function validarDados() {
    let result = true;

    return result;
  }

  async function handleCancelarProposta(e) {
    function callback(e) {
      if (!e) return false;

      console.log("handleCancelarProposta(1)");
      const content = {
        data: new Date().toISOString().substr(0, 10),
        motivo: "Cancelamento de proposta - pendencias",
        uuid: props.dados.proposta_uuid,
        proposta_uuid: props.dados.proposta_uuid,
        ip: getCookie({ label: "ip" }),
        userid: props?.user?.id,
        motivo_cancelamento: "Cancelamento de proposta - pendencias",
      }

      sendData({
        uri: uriList("cancelar"),
        content,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          handleClose(null, true);
          console.log("handleCancelarProposta(2)");
        })
        .catch((e) => {
          console.log("handleCancelarProposta(erro)");
          swalError({
            title: `Erro ao cancelar Proposta! \n${e.data || e.message || e.erro}`,
          });
        });


    }
    confirmSwal({ callback, title: "Confirma o cancelamento da PROPOSTA?" })
  }

  async function handlePost(e) {
    console.log("ResolverPendencia(1)");
    e.preventDefault();
    let dObj = {};

    if (validarDados()) {
      console.log("ResolverPendencia(2): ", data);
      setAguarde(true);
      dObj["proposta_uuid"] = props.dados.proposta_uuid;
      dObj["observacao"] = data.observacao || "Pendência resolvida";
      dObj["pendencia"] = props.dados;
      dObj["id"] = props.dados.id
      dObj["userid"] = props.user?.id
      dObj["ip"] = getCookie({ label: "ip" });
      let uri = "despendenciar";

      console.log("ResolverPendencia(3)");

      sendData({
        uri: uriList(uri),
        content: dObj,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          console.log("ResolverPendencia(4)");
          setAguarde(false);
          handleClose(null, true);
        })

        .catch((e) => {
          console.log("ResolverPendencia(erro)");
          setAguarde(false);
          console.log(e);
          return false;
        });
    }
  }

  async function handleRetornar(e) {
    console.log("handleRetornar(1)");
    e.preventDefault();
    let dObj = {};

    if (validarDados()) {
      console.log("handleRetornar(2): ", data);
      setAguarde(true);
      dObj["proposta_uuid"] = props.dados.proposta_uuid;
      dObj["observacao"] = data.observacao || "Retorno de pendência para solução";
      dObj["pendencia"] = props.dados;
      dObj["userid"] = props.user?.id
      dObj["id"] = props.dados.id
      dObj["ip"] = getCookie({ label: "ip" });
      let uri = "retornar";

      console.log("handleRetornar(3)");

      sendData({
        uri: uriList(uri),
        content: dObj,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          console.log("handleRetornar(4)");
          setAguarde(false);
          handleClose(null, true);
        })

        .catch((e) => {
          console.log("handleRetornar(erro)");
          setAguarde(false);
          console.log(e);
          return false;
        });
    }
  }

  async function getDados() {
    setLoaded(false);
    sendData({
      uri: uriList('pendenciashst'),
      content: { pendenciaid: props.dados.id },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("ResolverPendencia.getDados(1): ", res);
        setHistorico(res);
        setLoaded(true);
      })

      .catch((e) => {
        console.log("ResolverPendencia.getDados(2)");
        console.log(e);
        setLoaded(true);
        return false;
      });

  }

  const handleHistorico = (hist) => {

  }

  const getHistorico = () => {
    console.log("ResolverPendencia.getHistorico(1): ", historico);
    let fontSize = width() == "mobile" ? "0.65rem" : "0.8rem"
    let bgColor = true
    return (
      <div style={{ border: "1px solid #D5DBE1", }}>
        <div className="d-flex font-weight-bold" style={{ backgroundColor: "#E5E4E4" }} >
          <div style={{ width: "23%", fontSize }}>
            <span>Usuário</span>
          </div>
          <div style={{ width: "17%", fontSize }}>
            <span>Data</span>
          </div>
          <div style={{ width: "60%", fontSize }}>
            <span>Histórico</span>
          </div>
        </div>
        {historico.map((hist, i) => {
          bgColor = !bgColor
          return (
            <div className="d-flex" key={i} style={{ borderTop: "1px solid #D5DBE1", backgroundColor: bgColor ? "#F3F3F3" : "" }}>
              <div style={{ width: "23%", fontSize }}>
                {hist?.usuario}
              </div>
              <div style={{ width: "17%", fontSize }}>
                {moment(hist.updated_at).format("DD/MM/YYYY HH:mm:ss")}
              </div>
              <div style={{ width: "60%", fontSize }}>
                {hist.observacao || hist.status}
              </div>
            </div>
          )
        })}
      </div>
    )

  }

  return (
    <>
      <Modal
        size="md"
        show={true}
        centered
        onHide={(e) => { handleClose(e, false); }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              Resolver Pendência - {props.dados?.id}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <>
            {!collapsed &&
              <>
                <div style={{ borderBottom: "1px solid black", marginBottom: "20px" }}>
                  <div><strong>{dados.descricao}</strong></div>
                  <div><italic>{dados.observacao}</italic></div>
                </div>
                <div>
                  <div style={{ margin: "10px auto" }}>
                    <label className="m-0 font-90">
                      Observação
                    </label>
                    <textarea
                      type="textArea"
                      id="observacao"
                      name="observacao"
                      rows="3"
                      autoFocus={true}
                      required={true}
                      className="form-control"
                      value={data.observacao}
                      onChange={(e) => setData({ ...data, observacao: e.target.value })}
                    />
                  </div>
                </div>
                {dados.anexo_url &&
                  <div
                    style={{
                      display: "flex",
                      height: "30px",
                      border: "1px solid #D5DBE1",
                      boxShadow: width() !== "mobile" ? "0.1px 0.1px  #08899E" : "",
                      borderRadius: "5px",
                      marginBottom: "20px",
                      marginTop: "20px",
                      padding: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#F2F3F4",
                    }}
                  >
                    <a href={dados.anexo_url} target="_blank" rel="noopener noreferrer">{dados.anexar_doc_tipo}</a>
                  </div>
                  // <Dropper
                  //   name="file"
                  //   title={`${dados.anexar_doc_tipo}`}
                  //   data={dados.anexo_url}
                  //   // change={handleChange}
                  //   // del={handleDelete}
                  //   disabled={true}
                  //   accept={"application/pdf,image/*,text/plain"}
                  //   // warning="ADE - Doc. Auxiliar de Averbação"
                  //   field="file"
                  //   telaAux="averbacao"
                  // />
                }
              </>}
          </>

          {aguarde && (
            <Modal size={"sm"} show={true} animation={false} backdrop="static">
              <Modal.Body
                className="maxWidth750 mt-10"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Spinner animation="border" />
                {"  "}
                Aguarde...
              </Modal.Body>
            </Modal>
          )}
          {loaded &&
            <div title={!collapsed ? 'Clique para expandir' : "Clique para fechar"}>
              <CardCollapse isOpen={collapsed} hideHeader title={"Histórico de Alterações"} chield={() => getHistorico()}
                setCollapse={(collapse) => setCollapsed(!collapse)} bodyToggle={true}
                forceCollapse={props.dados.status != "Aguardando Analise"} />
            </div>
          }
        </Modal.Body>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", borderTop: "1px solid lightGray" }}>
          <div >
            <Button color="danger" onClick={handleCancelarProposta} title="Cancelar proposta">
              Cancelar
            </Button>
          </div>
          <div style={{ display: "flex", }}>
            {props.dados.status == "Aguardando Analise" &&
              <>
                <Button color="success" onClick={handlePost} style={{ marginLeft: "5px" }}>
                  Resolver
                </Button>
                <Button color="danger" onClick={handleRetornar} title={"Retornar pendência para Solução"} style={{ marginLeft: "5px", marginRight: "5px" }}>
                  Retornar
                </Button>
              </>
            }
            <Button color="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    user: state.dados.user
  };
}

export default connect(mapStateToProps)(ResolverPendencia);


