import React from "react";

import { Table } from 'reactstrap'

import { useDashboardData } from './hooks/DashboardDataContext'
import { convertToBrl } from './convertNumbers'
import style from './dashboard.module.css'

export default function DailyAverageTable() {
    const { businessDateInformation } = useDashboardData()

    return (
        <Table bordered className={style.table}>
            <thead className={style.tableHead}>
                <tr>
                    <th className="text-center" colSpan={4}>
                        Média Diaria - Últimos 7 dias
                    </th>
                </tr>
            </thead>
            <tbody className={style.tableBody}>
                <tr>
                    <td>Dias Úteis</td>
                    <td>Este mês</td>
                    <td>Mês Anterior</td>
                    <td>Val %</td>
                </tr>
                <tr>
                    <td>{businessDateInformation && businessDateInformation.days}</td>
                    <td>
                        {businessDateInformation && convertToBrl(businessDateInformation.last7)}
                    </td>
                    <td>
                        {businessDateInformation && convertToBrl(businessDateInformation.lastMonth7)}
                    </td>
                    <td>{businessDateInformation && businessDateInformation.perc}%</td>
                </tr>
            </tbody>
        </Table>
    )
}
