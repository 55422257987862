import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { sendData } from "../../helpers/auth";
import { formatCurrency, getCookie, exportAsImage, Aguarde } from "../../helpers/general";
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA } from "../../helpers/config";
import { getItemCrypt } from '../../helpers/auth'
// import Simulator from './formsimulation'
import Simulator from "./simulator";
import { connect } from "react-redux";
import { setProposta, setCliente } from "../../store/actions/dados";
import { post } from "axios";

function Confirm(props) {
  const [alterar, setAlterar] = useState(false);
  const [data, setData] = useState({});
  const [simulation, setSimulation] = useState({});
  const [uuid, setUuid] = useState({});
  const [gravando, setGravando] = useState(false);

  function toggleClose() {
    if (props.onCloseEv) props.onCloseEv();
  }

  const gravarScreenShot = async (screenShot, uuid) => {
    if (uuid) {
      //-- Excluindo documento caso exista
      await sendData({
        uri: uriList("docs-remove"),
        content: { uuid, tipo: "alteracao" },
        method: "POST",
      })

      console.log("result.gravarScreenShot(1): ", screenShot);
      let dObj = new FormData();
      let [latitude, longitude] = getCookie({ label: "geolocation" })
        ? getCookie({ label: "geolocation" }).split(",")
        : ["", ""];

      dObj.append("tipo", "alteracao");
      dObj.append("proposta_uuid", uuid);
      dObj.append("file", screenShot);
      dObj.append("ip", getCookie({ label: "ip" }));
      dObj.append("geolocation", JSON.stringify({ latitude, longitude }));
      console.log("result.gravarScreenShot(2)");

      const url = uriList("documentos");
      const config = {
        headers: {
          Accept: "*",
          key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
          "content-type": "multipart/form-data",
          "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
          "captcha": getItemCrypt(TOKEN_CAPTCHA),
        },
      };

      console.log("result.gravarScreenShot(3)");
      post(url, dObj, config)
        .then((response) => {
          console.log("Result.gravarScreenShot(ok)");
        })
        .catch((err) => {
          console.log("Result.gravarScreenShot(error)");
        });
      console.log("result.gravarScreenShot(4)");
    }
  };

  async function toggleGravarProposta(accept = false) {
    let screenShot = await exportAsImage(
      "proposal-change",
      "proposalchange.jpg",
      props?.user?.name
    );

    console.log("confirm.toggleGravarProposta(1)")
    setGravando(true);
    console.log("confirm.toggleGravarProposta(1.1)")
    simulation["ip"] = getCookie({ label: "ip" });
    console.log("confirm.toggleGravarProposta(1.2)")
    simulation["gravar"] = "true";
    simulation["uuid"] = uuid;
    simulation["operador_id"] = props.user?.pessoa_id;

    console.log("confirm.toggleGravarProposta(2)")
    if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0)
      simulation["agente_id"] = props.user?.pessoa_id;
    if (props.user) simulation["user"] = props.user;

    console.log("confirm.toggleGravarProposta(3)")
    console.log("confirm.toggleGravarProposta(1): ", simulation);
    sendData({
      uri: uriList("simulador"),
      content: simulation,
      signal: null,
      method: "POST",
    })
      .then(async (res) => {
        props.alterarProposta(res);

        //-- Gravando tela de simulação
        if (screenShot)
          await gravarScreenShot(screenShot, uuid);

        if (accept && props.onAccept) props.onAccept();
        setGravando(false);
      })
      .catch((e) => {
        setGravando(false);
        console.log("Erro ao gravar proposta");
        console.log("Erro: ", e.message);
      });
  }

  function toggleAccept() {
    if (simulation) toggleGravarProposta(true);
  }

  function toggleSimulation(dados, simulacao) {
    console.log('confirm.toggleSimulation(dados): ', dados)
    console.log('confirm.toggleSimulation(simulacao): ', simulacao)

    const _data = data;
    _data["prazo"] = dados.prazo;
    _data["valor_parcelas"] = dados.valor_parcelas;
    _data["valor_liquido"] = dados.valor_liquido;
    _data["convenio_id"] = dados.convenio_id;
    _data["convenio"] = dados.convenio;
    _data["tabela_id"] = dados.tabela_id;

    simulacao["convenio_id"] = dados.convenio_id
    simulacao["tabela_id"] = dados.tabela_id
    simulacao["convenio"] = dados.convenio

    setData(dados);
    setSimulation(simulacao);

    setAlterar(false);
  }

  useEffect(() => {
    let uuid = props.proposta;
    setData(props.detalhes);
    setUuid(uuid);
    console.log("Dados da proposta(useEffect - confirm): \n", props.detalhes);

  }, []);

  return (
    <>
      <Modal
        size="md"
        show={true}
        animation={false}
        backdrop="static"
        centered={props.alterarProposta}
      >
        <Modal.Header
          style={{
            fontSize: 30,
            padding: 0,
            justifyContent: "center",
            color: "white",
            backgroundColor: "#1C8085",
          }}
        >
          <strong>{!alterar ? "Sua Simulação" : "Nova Simulação"}</strong>
        </Modal.Header>

        <Modal.Body
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F7F7F7",
            textAlign: "center",
            align: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!alterar && (
            <div id="proposal-change">
              <div>
                <h6 className="no-margin">Convênio</h6>
                <h4
                  className="font-weight-bold m-0"
                  style={{ color: "#077097" }}
                >
                  {data.convenio}
                </h4>
              </div>
              <br />
              <div id="exibir">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "line",
                  }}
                >
                  <div>
                    <h6 className="no-margin">Valor Total</h6>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "line",
                      }}
                    >
                      <h4
                        className="font-weight-bold m-0"
                        style={{ color: "#077097" }}
                      >
                        R$ {format(data.valor_principal)}
                      </h4>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <h6 className="no-margin">Quantidade de Parcelas</h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "line",
                    }}
                  >
                    <h4
                      className="font-weight-bold m-0"
                      style={{ color: "#077097" }}
                    >
                      {data.prazo}x
                    </h4>
                  </div>
                </div>
                <br />
                <div>
                  <h6 className="no-margin">Valor da Parcela</h6>
                  <h2
                    className="font-weight-bold m-0"
                    style={{ color: "#10A0D6" }}
                  >
                    R$ {format(data.valor_parcelas)}
                  </h2>
                </div>
                {parseFloat(data?.saldo_devedor || 0) > 0
                  &&
                  <>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "line",
                      }}
                    >
                      <div>
                        <h5 className="no-margin">Saldo Devedor</h5>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "line",
                          }}
                        >
                          <h5
                            className="m-0"
                          >
                            R$ {format(data.saldo_devedor)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </>}
              </div>
            </div>
          )}

          {alterar && !gravando && (
            <Simulator
              onSubmit={toggleSimulation}
              data={data}
              alteracao={true}
            />
          )}

          {gravando && <Aguarde />
          }
        </Modal.Body>
        <Modal.Footer
          style={{
            padding: "0px",
            display: "flex",
            backgroundColor: "#EAEAEA",
          }}
        >
          <Button
            color="secondary"
            onClick={toggleClose}
            style={{ marginRight: "auto" }}
            disabled={gravando}
          >
            Fechar
          </Button>
          {!alterar && !gravando && (
            <FaEdit
              size={32}
              color="#077097"
              hint="Alterar dados"
              style={{ marginRight: "auto", cursor: "pointer" }}
              onClick={() => setAlterar(true)}
            />
          )}

          {!alterar && (
            <Button color="primary" onClick={toggleAccept} disabled={gravando}>
              Confirmar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const format = (value) => {
  value = formatCurrency(value);

  return value;
};

function mapStateToProps(state) {
  return {
    detalhes: state.dados.detalhes,
    proposta: state.dados.proposta,
    user: state.dados.user,
    alterarProposta: state.dados.alterarProposta,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Confirm);
