import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Buttons } from "./../../../layout";
import { formatCurrency } from "../../../../helpers/general";
import Simulator from "./simulator";

const Form = ({ ...props }) => {
  const [simulacoes, setSimulacoes] = useState(props.simulacoes);
  const [clickedTr, setClickedTr] = useState(null);
  const [data, setData] = useState(props.data);
  const [escolher, setEscolher] = useState(true);

  useEffect(() => {
    setClickedTr(null);
  }, [escolher]);

  function handleSimulacao(_simulacao) {
    console.log("selSimulacao.handleSimulacao: ", _simulacao);
    // if (!_simulacao) return;

    setClickedTr(_simulacao);
  }

  function goBack() {
    if (props.onCloseEv) props.onCloseEv();
  }

  function submit() {
    if (props.handle) props.handle(clickedTr);
  }

  function ModalBody() {
    return (
      <>
        <div
          className="form-group col-md-12"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="maxWidth750"
            style={{
              maxHeight: "500px",
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            <table border="1" style={{ cursor: "pointer" }}>
              <thead style={{ position: "sticky", top: "0px" }}>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "60px" }}
                  >
                    Prazo
                  </td>
                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "100px" }}
                  >
                    Vlr Parcela
                  </td>
                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "100px" }}
                  >
                    Vlr Total
                  </td>
                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    IOF
                  </td>
                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    Juros(%)
                  </td>
                </tr>
              </thead>
              <tbody>
                {simulacoes &&
                  simulacoes.map((simulacao, i) => (
                    <tr
                      key={i}
                      className={`${
                        clickedTr?.simulationId === simulacao.simulationId
                          ? "bg-secondary text-light"
                          : ""
                      }`}
                      onClick={() => {
                        handleSimulacao(simulacao);
                      }}
                      onDoubleClick={() => {
                        handleSimulacao(simulacao);
                      }}
                    >
                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "60px" }}
                      >
                        {simulacao.operationPeriod}
                      </td>
                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "100px" }}
                      >{`R$ ${formatCurrency(
                        parseFloat(simulacao.trancheValue).toFixed(2)
                      )}`}</td>
                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "100px" }}
                      >{`R$ ${formatCurrency(
                        parseFloat(simulacao.requestedValue).toFixed(2)
                      )}`}</td>
                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "80px" }}
                      >
                        {`R$ ${formatCurrency(
                          parseFloat(simulacao.iofTotalValue).toFixed(2)
                        )}`}
                      </td>
                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "80px" }}
                      >
                        {`${formatCurrency(
                          parseFloat(simulacao.monthInterestRate).toFixed(2)
                        )}`}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Modal
        size="md"
        show={true}
        onHide={() => props.onCloseEv()}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Simulação</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #f2f2f2",
              marginBottom: "15px",
              color: "blue",
              cursor: "pointer",
              backgroundColor: "#F1F0F0", 
              borderRadius: "4px",
            }}
          >
            <span onClick={() => setEscolher(!escolher)}>
              {escolher ? "Alterar Simulação?" : "Escolher Simulação?"}
            </span>
          </div>
          {!escolher && <ModalBody />}
          {escolher && (
            <Simulator
              data={data}
              dados={props.dados}
              handleSimulacao={handleSimulacao}
              handle={props.handle}
            />
          )}
          <Buttons
            first="Voltar"
            second={escolher ? "Selecionar" : "Avançar"}
            goBack={goBack}
            submit={submit}
            disabledSecond={!clickedTr}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    detalhes: state.dados.detalhes,
    proposta: state.dados.proposta,
  };
}

export default connect(mapStateToProps)(Form);
