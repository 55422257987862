import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";

import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
import { sendData } from "../../../../helpers/auth";
import { getCookie, Aguarde, getTiposDocumentos, width } from "../../../../helpers/general";
import { connect } from "react-redux";
import { suppressDeprecationWarnings } from "moment";
import { confirmSwal } from "../../../swal";

let descricoes = [
  "Documento(s) ilegivel",
  "Documento(s) irregular",
  "Documento(s) desatualizado",
  "Documento(s) arquivo inválido",

  "Informacoes divergentes",
  "Informacoes incompletas",

  "Averbacao nao realizada",
  "Inconformidade com o banco",
  "Senha(s) invalida(s)",
  "Suspeita de fraude",
  "Fora do prazo para formalizacao",
  "Token expirado/invalido/inexistente",
  "Cliente nao autorizou",
  "Erro de digitacao",
  "Solicitacao Diretoria",
  "Outros"
];

const IncluirPendencia = ({ ...props }) => {
  const [warning, setWarning] = useState({ descricao: 0, observacao: 0 })
  const [show, setShow] = useState(true);
  const [gravando, setGravando] = useState(false);
  const [tipos,] = useState(getTiposDocumentos(props?.user).filter(x => x.anexavel))
  const [modoManual, setModoManual] = useState((['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) < 0));
  const [templates, setTemplates] = useState([])
  const [clickedTr, setClickedTr] = useState({})
  const [data, setData] = useState({
    data: new Date().toISOString().substr(0, 10),
    descricao: "",
    observacao: "",
    status: "Pendente",
    proposta_uuid: props.proposta,
    anexar_doc_tipo: "",
    ip: getCookie({ label: "ip" }),
  });

  function toggleModo() {
    setModoManual(!modoManual);
  }

  async function getTemplates() {
    await sendData({ uri: uriList("templatesIndex"), content: { tipo: "pendencias" }, method: "POST", })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setTemplates(res);
      })
      .catch((e) => {
        alert("Erro ao buscar Templates.");
      });
  }

  useEffect(() => {
    getTemplates();
  }, []);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close);
  }

  function validarDados() {
    let _warning = { descricao: 0, observacao: 0 }

    if (!data.descricao)
      _warning = { descricao: 1 }

    if (!data.observacao)
      _warning = { ..._warning, observacao: 1 }

    console.log("IncluirPendencia.validarDados():", _warning)
    setWarning(_warning)

    return !_warning.descricao && !_warning.observacao;
  }

  async function handlePostPend(e) {
    if (modoManual)
      await handlePost(e)
    else
      await handlePostTemplate(clickedTr)
  }

  async function handlePost(e) {
    e.preventDefault();
    console.log("Auxiliar.handlePost(1):", data);

    if (validarDados()) {
      setGravando(true);
      console.log("Auxiliar.handlePost(2)");
      let uri = props.uri;
      // let uri = 'pendenciar'
      // if (props.tela === 'bloquear')
      //   uri = 'bloquear'
      // else if (props.tela === 'cancelar')
      //   uri = 'cancelar'

      // console.log(data)
      // console.log(data)
      sendData({
        uri: uriList(uri),
        content: { ...data, multSelect: props.detalhes.multSelect },
        signal: null,
        method: "POST",
      })
        .then((res) => {
          setGravando(false);
          console.log("Auxiliar.handlePost(3)");
          handleClose();
        })
        .catch((e) => {
          setGravando(false);
          console.log("Auxiliar.handlePost(4)");
          // console.log("Deu errado")
          console.log(e);
          return false;
        });
    }
  }

  async function handlePostTemplate(_clickedTr) {
    console.log("Auxiliar.handlePostTemplate(1):", data);
    function callback(e) {
      if (!e)
        return

      setClickedTr(_clickedTr)

      setGravando(true);
      console.log("Auxiliar.handlePostTemplate(2)");
      let uri = props.uri;
      const content = {
        data: new Date().toISOString().substr(0, 10),
        descricao: _clickedTr?.descricao,
        observacao: _clickedTr?.observacao,
        anexar_doc_tipo: _clickedTr?.anexar_doc_tipo,
        status: "Pendente",
        proposta_uuid: props.proposta,
        ip: getCookie({ label: "ip" }),
        multSelect: props.detalhes.multSelect
      };
      console.log("Auxiliar.handlePostTemplate(3):", content);

      sendData({
        uri: uriList(uri),
        content,
        signal: null,
        method: "POST",
      })
        .then((res) => {
          setGravando(false);
          console.log("Auxiliar.handlePost(3)");
          handleClose();
        })
        .catch((e) => {
          setGravando(false);
          console.log("Auxiliar.handlePost(4)");
          // console.log("Deu errado")
          console.log(e);
          return false;
        });
    }

    confirmSwal({ title: "Confirma a inclusão da pendência?", text: _clickedTr?.descricao, callback })
  }

  return (
    <>
      {gravando && <Aguarde legenda={"Aguarde, gravando dados..."} />}

      <Modal
        size="md"
        show={show}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{props.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          {modoManual &&
            <div>
              <div className="row">
                <div className="col-12">
                  <label className="m-0 font-90">Descrição</label>
                  <select
                    className="form-control"
                    defaultValue={data.descricao}
                    style={{ borderColor: warning?.descricao ? "red" : "", }}
                    onChange={(e) => setData({ ...data, descricao: e.target.value })}
                    autoFocus
                    required
                  >
                    <option value="">Escolha uma opção...</option>
                    {descricoes.map((item, key) => {
                      return (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label className="m-0 font-90">Anexar</label>
                  <select
                    className="form-control"
                    onChange={(e) => setData({ ...data, anexar_doc_tipo: e.target.value })}
                  >
                    <option value="">Escolha uma opção...</option>
                    {tipos.map((item, key) => {
                      return (
                        <option key={key} value={item.value}>
                          {item.nome}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label className="m-0 font-90">Observações</label>
                  <textarea
                    type="textArea"
                    className="form-control"
                    style={{ borderColor: warning.observacao ? "red" : "", }}
                    value={data.observacao}
                    required
                    rows="5"
                    onChange={(e) => setData({ ...data, observacao: e.target.value })}
                  />
                </div>
              </div>
            </div>}
          {!modoManual &&
            <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
              <ListGroup>
                {templates.map((template, index) => (
                  <ListGroup.Item
                    key={index}
                    style={{
                      color: "#636363", paddingTop: "10px", paddingBottom: "10px", cursor: "pointer",
                      backgroundColor: index % 2 === 0 ? "#E6E7E7" : ""
                    }}
                    onClick={() => handlePostTemplate(template)}
                  >
                    <div className="row"  >
                      <div className="col-12">
                        <div className="row col-12 " >
                          <strong className="mr-1">{template?.descricao}</strong>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) ? "space-between" : "flex-end", width: "100%" }}>
            {(['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
              <div>
                <Button color="info" onClick={toggleModo}>{`Modo ${modoManual ? 'Template' : 'Manual'}`}</Button>
              </div>
            }
            <div >
              <Button color="success" onClick={handlePostPend}>
                Gravar
              </Button>
              <Button
                style={{ marginLeft: "5px" }}
                color="secondary"
                onClick={(e) => {
                  handleClose(e, true);
                }}
              >
                Fechar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(IncluirPendencia);
