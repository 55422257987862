import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form/simulator/simulator";
import { swalError } from "../../components/swal";
import {
  sendForm,
  setSession,
  sendData,
  getData,
  clearSession,
  gravarIp,
} from "../../helpers/auth";
import { verifyValue } from "../../helpers/validation";
import { criptografar, descriptografar } from "../../helpers/crypto";
import { uriList, enviroment } from "../../helpers/config";
import {
  saveCookie,
  getCookie,
  width,
  formatCurrency,
} from "../../helpers/general";

import { connect } from "react-redux";
import {
  setProposta,
  setCliente,
  setSimulacao,
} from "../../store/actions/dados";
import * as moment from "moment";

function Simulator({ ...props }) {
  let dataIni = {
    convenio_id: props.alteracao ? props.data.convenio_id : 0,
    tipo: "valor",
    valor: props.alteracao ? formatCurrency(props.data.valor_liquido) : "0",
    parcelas: "",
    prazo: props.alteracao ? props.data.prazo : 0,
    tabela_id: props.alteracao ? props.data.tabela_id : 0,
    rangeValor: props.alteracao ? formatCurrency(props.data.valor_liquido) : 0,
    rangePrazo: props.alteracao ? props.data.prazo : 0,
    contrato: props.alteracao ? props.data.contrato : "0",
    operacao: props.alteracao ? props.data.operacao : "CONTRATO NOVO",
    cpf: props.alteracao ? props.data.cpf : "",
    ip: "",
    proposta_uuid: props.alteracao ? props.data.proposta_uuid : "",
  };
  const [data, setData] = useState(dataIni);
  const [warningCPF, setWarningCPF] = useState(false);

  const [showAlert, setAlert] = useState({
    convenio: 0,
    tipo: 0,
    valor: 0,
    prazo: 0,
  });

  const [simulador, setSimulador] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [convenioList, setConvenioList] = useState("");
  const [prazoSteps, setPrazoSteps] = useState(false);
  const [valorLabel, setValorLabel] = useState({
    message: "",
    def: props.alteracao
      ? "Valor"
      : props.user
        ? "Valor do Empréstimo"
        : `De quanto você precisa?`,
  });
  // const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [showStatus, setShowStatus] = useState(false);
  const [tabelas, setTabelas] = useState([]);
  const [convenio_id, setConvenio_id] = useState(
    props.alteracao ? props.data.convenio_id : 0
  );
  const [tabela_id, setTabela_id] = useState(
    props.alteracao ? props.data.tabela_id : 0
  );
  const [operacao, setOperacao] = useState(
    props.alteracao ? props.data.operacao : "CONTRATO NOVO"
  );

  const openInfoModal = ({ title, text, list }) => {
    setModal(
      <Modal
        title={title}
        text={text}
        list={list}
        onClick={() => setModal("")}
      />
    );
  };

  const _setOperacao = (operacao) => {
    if (operacao) {
      setData({ ...data, operacao: operacao });
      setOperacao(operacao);
    }
  };

  const handlechange = (e, innerData, alternative) => {
    let target = e.currentTarget || alternative;

    setData({ ...data, [target.name]: innerData || target.value });
    setData({ ...data, [target.name]: innerData || target.value });

    clearAlert(target);
  };

  const handleFT = (e) => {
    let [prazo, alerta] = verifyValue(e);

    setData({
      ...data,
      prazo: prazoSteps[prazo].prazo,
      rangePrazo: prazo ? prazo : 0,
    });
    setAlert({ ...showAlert, prazo: alerta });

    return false;
  };

  const handleValor = (e) => {
    let name = e.currentTarget.name;

    let val =
      name === "valor"
        ? formatCurrency(e.currentTarget.value)
        : e.currentTarget.value;
    let valParsed =
      name === "valor" && val
        ? val.replace(".", "").replace(",", ".")
        : parseFloat(val).toFixed(2);

    setData({
      ...data,
      valor: formatCurrency(valParsed),
      rangeValor: valParsed ? valParsed : 0,
    });
    setAlert({
      ...showAlert,
      valor: valParsed > 500000 || valParsed <= 0 ? 1 : 0,
    });
  };

  const clearAlert = (target) => {
    let field = target.name;

    setAlert({ ...showAlert, [field]: 0 });
  };

  const changeValorLabel = (e) => {
    let tipo = e.currentTarget.value;
    setData({ ...data, valor: 0, tipo });
    // setData({ ...dataIni, tipo });

    e.currentTarget.value === "parcela"
      ? setValorLabel({
        ...valorLabel,
        message: props.user
          ? "Valor das Parcelas"
          : "Quanto você quer pagar?",
      })
      : setValorLabel({ ...valorLabel, message: valorLabel.def });
  };

  const handleConvenio = (e) => {
    let convenio = convenioList.filter(
      (convenio) => convenio.id == e.currentTarget.value
    );
    if (convenio && convenio.length > 0) {
      setConvenio(convenio[0]);
      console.log("simulator.handleConvenio(1): ", convenio[0])
    }
  };

  const handleTabela = (e) => {
    setTabela(tabelas.filter((tab) => tab.id == e.currentTarget.value)[0]);
  };

  const handleContrato = (e) => {
    setData({ ...data, contrato: e.currentTarget.value });
  };

  const handleCpf = (e) => {
    if (e && e.currentTarget) setData({ ...data, cpf: e.currentTarget.value });
    else setData({ ...data, cpf: e });
  };

  const toogleClient = (e, nova) => {
    // e.preventDefault();

    if (nova) {
      setSimulador(true);
    } else {
      let cliente = getCookie({ label: "cliente_id" });
      if (cliente) props.alterarCliente(cliente);

      if (
        e.currentTarget.dataset.posicao &&
        e.currentTarget.dataset.posicao.toLowerCase() !== "simulacao"
      ) {
        props.alterarProposta(isClient[e.currentTarget.id]);
        setShowStatus(true);
      } else {
        props.alterarProposta(isClient[e.currentTarget.id]);

        setIsClient(false);
        props.jump(2);
      }
    }
  };

  const submit = (e) => {
    if (warningCPF) {
      swalError({ title: "CPF inválido, favor digitar novamente." });
      return false;
    }

    console.log("simulator.Submit(data): ", data)
    console.log("simulator.Submit(convenio_id): ", convenio_id)
    console.log("simulator.Submit(tabela_id): ", tabela_id)

    if (!data.cpf) delete data.cpf;
    let [res, obj] = sendForm(data);
    let newData = JSON.parse(JSON.stringify(data));
    // console.log("Submit - Simulator - 1.1: ", res, obj)
    if (!res) {
      setAlert({ ...obj });

      return false;
    }

    newData.valor = newData.valor.replace(".", "").replace(",", ".");
    newData.valor = parseFloat(newData.valor);
    newData.parcelas = newData.valor / newData.prazo;
    let submitData = JSON.parse(JSON.stringify(newData));

    delete submitData.parcelas;
    delete submitData.rangePrazo;
    delete submitData.rangeValor;

    submitData.convenio_id = convenio_id || newData.convenio_id;
    submitData.tabela_id = tabela_id || newData.tabela_id;
    submitData.operador_id = props.user?.pessoa_id;
    submitData.convenio = newData.convenio
    if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0)
      submitData.agente_id = props.user?.pessoa_id;

    console.log("simulator.submit(Simulando):", submitData);
    sendData({
      uri: uriList("simulador"),
      content: submitData,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        // console.log("Simulou: ", res)
        res.tipo = submitData.tipo;
        if (res.status && res.status > 200) {
          console.log("Erro ao simular: ", res);
          swalError({
            title: `Erro ao realizar simulação! \n${res.erro ? res.erro : res.message
              }`,
          });
          return false;
        }

        setSession({ obj: res, key: "status" });
        setSession({ obj: submitData, key: "resultado" });
        // props.alterarSimulacao(res)
        if (props.onSubmit) props.onSubmit(res, submitData);
        else {
          if (props.user) props.jump(1);
          else props.toggleStep(1);
        }
      })
      .catch((e) => {
        console.log("Erro ao simular: ", e);
        if (enviroment === "dev") {
          setSession({ obj: newData, key: "status" });
          props.toggleStep(1);
        }
      });
  };

  async function _getTabelas(convenio_id) {
    if (props.user) {
      await sendData({
        uri: uriList("listarTabelas"),
        content: {
          convenio_id: convenio_id || data.convenio_id,
          operacao: props.data?.operacao || "CONTRATO NOVO",
        },
        method: "POST",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          console.log("simulator._getTabelas(ok)")
          setTabelas(res);

          if (res[0]?.id) setTabela(res[0]);
          else if (props.data && props.data.tabela_id) setTabela(props.data);
        })
        .catch((e) => {
          alert("Erro ao obter Tabelas.\n" + e.message);
        });
    }
  }

  function setTabela(tabela) {
    console.log("simulator.SetTabela...: ", tabela);
    let _tabela_id = tabela.tabela_id || tabela.id;
    console.log("simulator.SetTabela(tabela_id): ", _tabela_id);
    setData({ ...data, tabela_id: _tabela_id });
    setTabela_id(_tabela_id);
    _setOperacao(tabela.operacao);
  }

  useEffect(() => {
    gravarIp();
    // fetch("https://api.ipify.org?format=json")
    //     .then((res) => res.json())
    //     .then((data) => {
    //         if (!data.ip) throw new Error();
    //         else {
    //             setData((previous) => ({ ...previous, ip: data.ip }));
    //         }
    //     })
    async function _getData() {
      if (props.proposta && !props.user) {
        setShowStatus(true);
      } else {
        // let ip = getCookie({ label: 'ip' })
        if (!props.user) clearSession();

        // await sendData({
        //   uri: uriList("convenios"),
        //   content: { tipo_credito: "CONSIGNADO" },
        //   signal: null,
        //   method: "GET",
        // })
        getData({
          uri: uriList("conveniosConsignado"),
        })
          .then((res) => {
            if (res.status && res.status > 200) throw new Error();
            if (!Array.isArray(res)) throw new Error();

            setConvenioList(res);

            if (props.alteracao && props.data.convenio_id)
              setConvenio(props.data);
            else setConvenio(res[0]);
          })
          .catch((e) => setConvenioList([]));
      }
    }

    _getData();
  }, []);

  function setConvenio(convenio) {
    console.log("simulator.setConvenio: ", convenio)

    let convenio_id = convenio.convenio_id || convenio.id;
    console.log("simulator.setConvenio(convenio_id): ", convenio_id)
    setData({ ...data, convenio_id });
    setConvenio_id(convenio_id);
    if (props.user) _getTabelas(convenio_id);
    else _getPrazos(convenio.tabelapadrao_id);
  }

  useEffect(() => {
    if (tabela_id) {
      _getPrazos(tabela_id);
    }
  }, [tabela_id]);

  async function _getPrazos(tabela_id) {
    console.log("simulator._getPrazos: ", tabela_id)
    getData({ uri: uriList("prazos") + "/" + tabela_id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        if (res[0]) {
          setPrazoSteps(res);
          setData((previous) => ({
            ...previous,
            prazo: res[0] && res[0].prazo,
          }));
        }

        if (props.data.prazo) setData({ ...data, prazo: props.data.prazo });
      })
      .catch((e) => {
        return false;
      });
  }

  function RenderSimulator() {
    return (
      <>
        <Form
          data={data}
          warning={showAlert}
          modal={openInfoModal}
          changeLabel={changeValorLabel}
          clearWarning={clearAlert}
          change={handlechange}
          valor={handleValor}
          ft={handleFT}
          label={valorLabel}
          tabelaList={tabelas}
          convenio={handleConvenio}
          convenio_id={convenio_id}
          tabela_id={tabela_id}
          tabela={handleTabela}
          contrato={handleContrato}
          cpf={handleCpf}
          operacao={operacao}
          convenioList={convenioList}
          prazoSteps={prazoSteps}
          warningCPF={warningCPF}
          setWarningCPF={setWarningCPF}
          alteracao={props.alteracao}
        />

        <div style={{ padding: "0 20px" }} className="row buttons">
          <button
            style={{
              fontSize: "1.1em",
              margin: props.alteracao ? "" : "15px 0",
              backgroundColor: "#3E6C84",
            }}
            className="btn btn-info text-light font-weight-bold col-12"
            onClick={(e) => submit(e, data)}
          >
            Simular
          </button>
        </div>
      </>
    );
  }

  function RenderSelect() {
    return (
      <>
        {loaded ? (
          isClient ? (
            <div
              style={{
                marginTop: "2%",
                padding: "1.2em 0",
                // maxHeight: document.body.clientHeight * 0.80 + 'px',
                maxHeight: document.body.clientHeight * 0.65 + "px",
                overflowY: width() === "mobile" ? "auto" : "hidden",
              }}
              onMouseOver={(e) => (e.currentTarget.style.overflowY = "auto")}
              onMouseOut={(e) => (e.currentTarget.style.overflowY = "hidden")}
            >
              <button
                onClick={(e) => toogleClient(e, true)}
                className="btn btn-lg d-block mx-auto btn-outline-info col-md-6 col-10 mb-3 font-weight-bold"
                style={{ borderWidth: "3px" }}
              >
                <p className="m-0">
                  Nova <br /> Proposta
                </p>
              </button>

              {isClient.map((client, i) => (
                <div>
                  <div style={{ display: "flex", flexDirection: "line" }}>
                    <div
                      onClick={toogleClient}
                      key={client.uuid}
                      data-posicao={client.posicao}
                      id={i}
                      className="btn btn-lg d-block mx-auto btn-outline-secondary col-md-6 col-10 mb-3 font-weight-bold"
                      style={{
                        borderRadius: "15px",
                        border: "3px solid #797776",
                        borderColor:
                          client.posicao.toLowerCase() !== "simulacao"
                            ? "#077097"
                            : "#05535D",
                      }}
                    >
                      {client.cliente_id &&
                        saveCookie({
                          label: "cliente_id",
                          content: client.cliente_id,
                        })}
                      <p className="m-0">
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {client.posicao.toLowerCase() !== "simulacao"
                            ? "Acompanhar"
                            : "Continuar"}
                        </div>

                        <span
                          className="font-weight-normal"
                          style={{ fontSize: ".76em" }}
                        >
                          {/* {props.user && <div><div>{client.cliente}</div> <div>{client.cpf + " - " + client.convenio}</div></div>} */}
                          R${" "}
                          {formatCurrency(
                            parseFloat(client.valor_liquido).toFixed(2)
                          )}{" "}
                          - {client.prazo}x -{" "}
                          {client.status_data &&
                            moment(client.status_data).format(
                              "DD/MM/YYYY"
                            )}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            RenderSimulator()
          )
        ) : (
          <div className="text-center text-info my-3">
            <span className="d-block lead">Carregando Dados da Proposta!</span>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <fieldset className="col-12">
        <h4
          className="display-4 text-center font-weight-bold"
          style={{ marginBottom: "15px", color: "#3E6C84" }}
        >
          {props.title}
        </h4>

        {modal}
        {props.alteracao || props.user || simulador
          ? RenderSimulator()
          : RenderSelect()}
        {showStatus && <Redirect to="./status" />}
      </fieldset>
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
    alterarSimulacao(simulacao) {
      dispatch(setSimulacao(simulacao));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Simulator);
