import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal, { swalError } from "../../../swal";
import {
  sendMultiPartData,
  sendData,
  getData,
  pureGetData,
} from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Pagination, FilterSelect, InputLabelAlert } from "../../../layout";
import GerirDocumentos from "./gerirDocumentos";
import { capitalize } from "../../../../helpers/general";

import { connect } from "react-redux";

const swalOptions = {
  title: "Deseja realmente excluir o registro selecionado?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    Documentos,
    actualPage,
    sortArrObj,
    handlePage,
    callback,
    setClickedTr,
    clickedTr,
    setDocumentos,
  } = props;

  const [show, setShow] = useState(false);

  const handleNew = () => {
    setShow(true);
  };

  const handleEdit = () => {};
  const handleClose = async () => {
    setShow(false);
    if (props.refresh) props.refresh();
  };

  return (
    <>
      {show && <GerirDocumentos onCloseEv={handleClose} />}
      <div className="col-md-4 py-1 px-0">
        <button
          onClick={() => handleNew()}
          className="btn btn-sm text-light"
          title="Nova Pessoa"
          style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
        >
          <i className="mdi mdi-plus-box" />
        </button>
        <button
          onClick={() => window.print()}
          className="btn btn-sm text-light"
          title="Imprimir"
          style={{ backgroundColor: "#00bdff" }}
        >
          <i className="mdi mdi-printer" />
        </button>
      </div>

      <table className="table table-bordered table-striped">
        <thead>
          <tr
            title="Clique para ordenar"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <th scope="col" className="font-80">
              #
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Descrição
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("cpf", "string")}
            >
              URL
            </th>
          </tr>
        </thead>
        <tbody>
          {Documentos?.length > 0 ? (
            Documentos.map(
              (documentos, i) =>
                i >= actualPage * 10 &&
                i < (actualPage + 1) * 10 && (
                  <tr
                    key={i}
                    className={`${
                      clickedTr === documentos.id
                        ? "bg-secondary"
                        : ""
                    }`}
                    onClick={() => { 
                      setClickedTr(documentos.id);
                    }}
                    onDoubleClick={() => {
                      handleEdit(documentos.id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClickedTr(documentos.id);
                    }}
                  >
                    <td>
                      <a
                        href={documentos.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i + 1}{" "}
                      </a>
                    </td>
                    <td>
                      <a
                        href={documentos.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {capitalize(documentos.descricao)}
                      </a>
                    </td>
                    <td>
                      <a
                        href={documentos.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {documentos.url}
                      </a>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td>Sem dados</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-left">
              Total de documentos: {Documentos?.length}
            </td>

            <td colSpan="8"></td>
          </tr>
        </tfoot>
      </table>

      <Pagination
        detailed={Documentos}
        actual={actualPage}
        changePage={handlePage}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
  };
}

// export default Form;

export default connect(mapStateToProps)(Form);
