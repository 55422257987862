import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/documents";

import { setSession, getSession, sendForm, sendData, gravarEtapaProposta } from "../../helpers/auth";
import { Buttons } from "../../components/layout";
import { uriList } from "../../helpers/config";
import { getCookie, getLocation } from "../../helpers/general";
import { connect } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";

function Documents({ ...props }) {
  const [data, setData] = useState({
    d_pessoal: "",
    contracheque: "",
    c_bancario: "",
    c_endereco: "",
    d_pessoalv: "",
    ded: "",
    outros: "",
    proposta_uuid: props.proposta,
    cliente_id: props.cliente || props.clienteDados.id,
    ip: getCookie({ label: "ip" }),
  });

  const [showAlert, setAlert] = useState({
    d_pessoal: 0,
    d_pessoalv: 0,
    contracheque: 0,
    c_bancario: 0,
    c_endereco: 0,
    ded: 0,
    outros: 0,
  });

  const [loaded, setLoaded] = useState(true);
  const [aguarde, setAguarde] = useState(false);

  function handleChange(field, innerData) {
    console.log("Documents(handleChange)");
    let obj = Object.assign(innerData);

    setData({ ...data, [field]: obj });
    setAlert({ ...showAlert, [field]: 0 });
  }

  function handleDelete(field) {
    console.log("Excluindo documento(handleDelete)");
    let uuid = props.proposta;
    let cliente_id = props.cliente || props.clienteDados.id;
    setData({ ...data, [field]: "" });

    function sendDocs() {
      sendData({
        uri: uriList("docs-remove"),
        content: { uuid, cliente_id, tipo: field },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          setData({ ...data, [field]: "" });
        })
        .catch((e) => {
          console.log("Erro ao excluir anexos: ", e);
          sendDocs();
        });
    }

    sendDocs();
  }

  async function submit(e, to) {
    let _alert = {
      d_pessoal: data.d_pessoal ? 0 : 1,
      contracheque:
        data.contracheque ||
          props.docPessoal ||
          props.detalhes.tipo_convenio == "ORIGINAL"
          ? 0
          : 1,
      c_bancario:
        data.c_bancario ||
          props.docPessoal ||
          props.detalhes.tipo_convenio == "ORIGINAL"
          ? 0
          : 1,
      ded:
        data.ded ||
          props.docPessoal ||
          props.detalhes.tipo_convenio == "ORIGINAL"
          ? 0
          : 1,
    };
    setAlert(_alert);

    console.log("Documents(submit): ", _alert);
    if (_alert.d_pessoal || _alert.contracheque || _alert.c_bancario) {
      return false;
    }

    console.log("Documents(1) - after test ");
    let [res, obj] = sendForm(e, data);
    // res =
    //   res &&
    //   data.d_pessoal &&
    //   (data.contracheque || props.docPessoal) &&
    //   (data.c_bancario || props.docPessoal);
    if (!res) {
      if (e.preventDefault) e.preventDefault();

      return false;
    }

    //-- Gravando etapa de documentos
    console.log("simulator.submit(gravou documentos): ", props.proposta);
    await gravarEtapaProposta({ uuid: props.proposta, gravou_documentos: 1 });

    setSession({ obj: data, key: "documentos" });
    props.toggleStep(1);
  }

  function goBack(to) {
    setSession({ obj: data, key: "documentos" });
    props.toggleStep(-1);
  }

  function Aguardar(aguarde = true) {
    setAguarde(aguarde);
  }

  useEffect(() => {
    console.log("documents.useEffect(0): ", props.proposta);
    console.log("documents.useEffect(1): ", props.clienteDados);
    let storeData = getSession({ key: "documentos" });

    sendData({
      uri: uriList("docs-show"),
      content: {
        uuid: props.proposta,
        // cliente_id: props.cliente || props.clienteDados.id, //-- Retirado para não utilizar documentos antigos de cliente em propostas novas
        data: props.data,
      },
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        if (res.length > 0) {
          for (let i in res) {
            if (res[i].tipo !== "selfie") {
              setData((previous) => ({
                ...previous,
                [res[i].tipo]: [
                  {
                    ext: "docs",
                    tipo: res[i].tipo,
                    preview: res[i].url,
                    url: res[i].url,
                    contentType: res[i].tipo_conteudo,
                  },
                ],
              }));
            }
          }
        }

        setLoaded(false);
      })
      .catch((e) => {
        if (storeData) setData({ ...storeData });
        setLoaded(false);
      });

    getLocation();
  }, []);

  return (
    <>
      {!props.editar_proposta && (
        <fieldset className="col-12"
          style={{
            overflowY: props.preencherDados ? 'auto' : 'visible',
            maxHeight: props.preencherDados ? '700px' : 'none',
          }}>
          <h4
            className="display-4 text-center font-weight-bold"
            style={{ marginBottom: "15px", color: "#3E6C84" }}
          >
            {props.title}
          </h4>
          <Form
            data={data}
            change={handleChange}
            del={handleDelete}
            warning={showAlert}
            aguardar={Aguardar}
            // disabled={!loaded}
            portabilidade={props.portabilidade}
            docPessoal={props.docPessoal}
            leilaoInss={props.leilaoInss}
          />
          <Buttons goBack={goBack} submit={submit} />
        </fieldset>
      )}
      {props.editar_proposta && (
        <div className="container-md col-12">
          <Form
            data={data}
            change={handleChange}
            del={handleDelete}
            warning={showAlert}
            // disabled={!loaded}
            aguardar={Aguardar}
            proposta={true}
            portabilidade={props.portabilidade}
            docPessoal={props.docPessoal}
          />
        </div>
      )}

      {aguarde && (
        <Modal size={"sm"} show={true} animation={false} backdrop="static">
          <Modal.Body
            className="maxWidth750"
            style={{
              width: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Spinner animation="border" />
            {"  "}
            Aguarde...
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

export default connect(mapStateToProps)(Documents);
