import React, { useState, useEffect, useCallback } from "react";

import Form from '../../components/form/admin/Reapresentacoes';

function Reapresentacoes({ ...props }) {

    return (
        <>
            <Form />
        </>
    );
};

export default Reapresentacoes;
