import React, { useState, useEffect, useRef } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import * as moment from "moment";
import { connect } from "react-redux";
import { PDFDocument } from 'pdf-lib';
import { decode } from 'js-base64';


import { sendData, getData } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { formatCurrency, width, Aguarde, capitalize, handleGetUrlFromBase64 } from "../../../../helpers/general";
import './checklist.css'
import { confirmSwal, swalError, swalMessage } from "../../../swal";
import { Link } from "react-router-dom";


const _maxHeight = "300px"

function CompConformidade(props) {
    const [emitente, setEmitente] = useState(null)
    const [auditAssinatura, setAuditAssinatura] = useState(false)
    const [consultaMargem, setConsultaMargem] = useState(false)
    const [antiFraude, setAntiFraude] = useState(false)
    const [showAntiFraude, setShowAntiFraude] = useState(false)
    const [aguarde, setAguarde] = useState(false)
    const [dadosConf, setDadosConf] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [loadedDocs, setLoadedDocs] = useState(false)
    // const [ccbUrl, setCcbUrl] = useState(false)
    const [assinatura, setAssinatura] = useState(false)
    const [documentos, setDocumentos] = useState([])
    const [key, setKey] = useState("home");
    const [firstPage, setFirstPage] = useState(false)
    const [urlDoc, setUrlDoc] = useState(false)
    const [extDoc, setExtDoc] = useState(false)
    const [analiseAutomatica, setAnaliseAutomatica] = useState(false)
    const [analiseRealizada, setAnaliseRealizada] = useState(false)
    const [analiseAutomaticaOK, setAnaliseAutomaticaOK] = useState(false)
    const [checagemCredLink, setChecagemCredLink] = useState(false)
    const [credlinkData, setCredlinkData] = useState(false)
    const [analiseOCR, setAnaliseOCR] = useState(false)
    const [showAnaliseOCR, setShowAnaliseOCR] = useState(false)

    useEffect(() => {
        getDados()
    }, [])

    function getComparacaoPerc(data) {
        console.log("CompConformidade.getComparacaoPerc(0): ", data)
        // Verifica se o objeto data e a chave comparacao existem antes de tentar acessar comparacaoPerc
        if (data?.comparacao?.comparacaoPerc) {
            return "Comparacao: " + data.comparacao.comparacaoPerc;
        }
        else if (data?.comparacao?.comparacaoPerc) {
            return "Comparacao: " + data.comparacao.comparacaoPerc;
        }
        else {
            // Retorna null ou alguma outra indicação de que comparacaoPerc não está disponível
            return "";
        }
    }

    const handleUrlDoc = (url) => {
        if (!url)
            return false

        const ext = url.split('.').pop();
        setExtDoc(ext?.toLowerCase())
        setUrlDoc(url)
        console.log("CompConformidade.handleUrlDoc(0): ", url)
    }

    const handlePrintFirstPage = async () => {
        if (firstPage) return false
        // setAguarde(true)
        await sendData({
            uri: uriList('copiar1PaginaCcb'),
            content: { proposta_uuid: props?.proposta, convenio: props?.detalhes?.tipo_convenio, cpf: props?.detalhes?.cpf },
            signal: null,
            method: "POST",
        })
            .then(async (res) => {
                // console.log("PreencherConformidade.handleAntifraude(1): ", res.data)
                if (res?.data) {
                    setFirstPage(res.data)
                    // await downloadPDF(res?.data)
                    // await printPDF(res?.data)
                }
            })
            .catch((e) => {
                console.log(e);
                return false;
            });
        // setAguarde(false)
    }

    const highlightedJSON = (jsonObj) =>
        Object.keys(jsonObj).map((key) => {
            const value = jsonObj[key];
            let valueType = typeof value;
            const isSimpleValue =
                ["string", "number", "boolean"].includes(valueType) || !value;
            if (isSimpleValue && valueType === "object") {
                valueType = "null";
            }
            return (
                <div key={key} className="line" style={{ fontSize: "18px !important" }}>
                    <span className="key" style={{ fontSize: "18px !important" }}>{key}:</span>
                    {isSimpleValue ? (
                        <span className={valueType} style={{ fontSize: "18px !important" }}>{`${value}`}</span>
                    ) : (
                        highlightedJSON(value)
                    )}
                </div>
            );
        });

    const handleAntifraude = async () => {
        async function callback(e) {
            console.log("PreencherConformidade.handleAntifraude(0): ", e)
            if (!e) return false;

            console.log("PreencherConformidade.handleAntifraude(1)")
            setAguarde(true)
            await sendData({
                uri: uriList('checarAntiFraude'),
                content: { proposta_uuid: props?.proposta, cpf: props?.detalhes?.cpf, forca: true },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    console.log("PreencherConformidade.handleAntifraude(1): ", JSON.stringify(res))
                    if (res?.status > 200) {
                        swalError({ title: "Erro ao Analisar documento.", text: JSON.stringify(res?.message || res) });
                    }
                    else
                        handleConsultaAntifraude()
                })
                .catch((e) => {

                    console.log(JSON.stringify(e));
                    return false;
                });
            setAguarde(false)
        }
        confirmSwal({ callback, title: "Confirma a execução do processo?" });
    }

    const handleConsultaAntifraude = async (_getDados = true) => {
        console.log("PreencherConformidade.handleConsultaAntifraude(0)")
        // if (antiFraude) return setShowAntiFraude(true)
        setAguarde(true)
        await sendData({
            uri: uriList('consultarAntiFraude'),
            content: { proposta_uuid: props?.proposta },
            signal: null,
            method: "POST",
        })
            .then((res) => {
                console.log("PreencherConformidade.handleConsultaAntifraude(1): ", res)
                if (res?.status > 200) throw new Error()
                setAntiFraude(res?.data || res)
                setShowAntiFraude(true)
                getDados()
            })
            .catch((e) => {
                console.log(e);
                return false;
            });

        setAguarde(false)
    }

    const handleChecarCredLink = async (forcar = false) => {
        console.log("[PreencherConformidade.handleChecarCredLink(0)]")
        // setAguarde(true)
        await sendData({
            uri: uriList('checarCredLink'),
            content: { cpf: props?.detalhes?.cpf, forcar: forcar || false },
            signal: null,
            method: "POST",
        })
            .then((res) => {
                console.log("PreencherConformidade.handleChecarCredLink(1): ", res)
                if (res?.status > 200) throw new Error()
                setChecagemCredLink(res?.data || res)
                setCredlinkData((res?.data || res || {}))
            })
            .catch((e) => {
                console.log("[PreencherConformidade.handleChecarCredLink(error)]: ", e)
                return false;
            });

        // setAguarde(false)
    }

    const handleAuditAssinatura = async () => {
        setAguarde(true)
        await sendData({ uri: uriList('listarAuditAssinatura'), content: { uuid: props.proposta }, method: "POST" })
            .then(res => {
                console.log("PreencherConformidade.handleAuditAssinatura: ", res)
                if (res?.status?.toLowerCase() == 'not') {
                    throw new Error();
                }
                let _res = res?.data?.parts[0]
                console.log("PreencherConformidade.handleAuditAssinatura(res): ", _res)
                setAuditAssinatura(_res)
                getDados()
            })
            .catch(e => {
                console.log("Erro: ", e)
                return false;
            });
        setAguarde(false)
    }

    const handleAnaliseAutomatica = async () => {
        setAguarde(true)
        await sendData({ uri: uriList('analiseAutomatica'), content: { uuid: props.proposta, forcar: true }, method: "POST" })
            .then(res => {
                console.log("PreencherConformidade.handleAnaliseAutomatica: ", res)
                // if (res?.status?.toLowerCase() == 'not') {
                //     throw new Error();
                // }
                let _res = res?.dados
                console.log("PreencherConformidade.handleAnaliseAutomatica(res): ", _res)
                setAnaliseAutomatica(_res)
                setAnaliseAutomaticaOK(_res?.status == 200)
                getDados()

                // swalMessage({ title: "Análise Automática realizada com sucesso." })
            })
            .catch(e => {
                console.log("Erro: ", e)
                return false;
            });
        setAguarde(false)
    }

    const handleAprovarAnalise = async () => {
        setAguarde(true)
        await sendData({ uri: uriList('aprovarAnalise'), content: { uuid: props.proposta }, method: "POST" })
            .then(res => {
                console.log("PreencherConformidade.handleAprovarAnalise: ", res)
                // if (res?.status?.toLowerCase() == 'not') {
                //     throw new Error();
                // }
                let _res = res?.dados
                console.log("PreencherConformidade.handleAprovarAnalise(res): ", _res)
                // setAnaliseAutomatica(_res)
                getDados()
            })
            .catch(e => {
                console.log("Erro: ", e)
                return false;
            });
        setAguarde(false)
    }

    const handleConsultaMargem = async () => {
        setAguarde(true)
        await sendData({ uri: uriList("consultarMargem"), content: { uuid: props.proposta }, method: "POST" })
            .then((res) => {
                if (res.status && parseInt(res.status) != 200) {
                    swalError({
                        title: "Erro ao Consultar Margem do Cliente.",
                        text: JSON.stringify(res.data),
                    });
                    throw new Error();
                }
                setConsultaMargem(res.data)
                console.log("consultaAverbaçãoPortabilidade: ", res.data);
            })
            .catch((e) => {
                console.log("ConsultarMargem - erro: Erro na consulta de margem");
                console.log(e);
                // alert('Erro ao excluir Configuracoes.')
            });
        setAguarde(false)
    }

    const getDados = async () => {
        console.log("PreencherConformidade.getDados(0)")
        // return
        if (props.detalhes?.cliente_id) {
            const content = { id: props.detalhes?.cliente_id }
            console.log("PreencherConformidade.getDados(content): ", content)
            setAguarde(true)
            await sendData({
                uri: uriList('pessoa'),
                content,
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    if (res.status && parseInt(res?.status) > 200) throw new Error();
                    console.log("PreencherConformidade.getDados: ", res?.data)
                    if (res?.data)
                        setEmitente(res.data);
                })
                .catch((e) => {
                    console.log(e);
                    return false;
                });

            console.log("PreencherConformidade.getDados(conformidade-cpf): ", props.detalhes?.cpf)
            await sendData({
                uri: uriList('conformidadeshow'),
                content: { cpf: props.detalhes?.cpf, proposta_uuid: props.proposta },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    console.log("PreencherConformidade.getDados(conformidade-res): ", res)
                    if (res?.status && parseInt(res?.status) > 200) throw new Error();
                    if (res) {
                        setDadosConf(res);
                        setAssinatura(res?.assinatura || res?.dados_assinatura)

                        if (res.conformidadeapv && props.setConformidadeApv)
                            props.setConformidadeApv(res.conformidadeapv)

                        if (res.credlink_data)
                            setCredlinkData(res.credlink_data)
                    }
                })
                .catch((e) => {
                    console.log("PreencherConformidade.getDados(conformidade-erro)")
                    console.log(e);
                    return false;
                });

            await sendData({
                uri: uriList('checarAnaliseRealizada'),
                content: { cpf: props.detalhes?.cpf, proposta_uuid: props.proposta },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    console.log("PreencherConformidade.getDados(checarAnaliseRealizada): ", res)
                    if (res?.status && parseInt(res?.status) > 200) throw new Error();
                    if (res) {
                        setAnaliseRealizada(true)
                    }
                })
                .catch((e) => {
                    console.log("PreencherConformidade.getDados(checarAnaliseRealizada-erro)")
                    console.log(e);
                    return false;
                });
            setAguarde(false)
            setLoaded(true)
            if (props.handleLoaded)
                props.handleLoaded(true)

            console.log('PreencherConformidade.getDados(docs-show)')
            await sendData({
                uri: uriList("docs-show"),
                content: { uuid: props.proposta, cliente_id: props.detalhes.cliente_id },
                method: "POST",
            })
                .then((res) => {
                    if (res.status && res.status > 200) throw new Error();

                    console.log("Documentos: ", res)
                    setDocumentos(res.sort((a, b) => a?.tipo?.id - b?.tipo?.id));
                    setLoadedDocs(true)
                    console.log('PreencherConformidade.getDados(docs-show): ', res)
                })
                .catch((e) => {
                    console.log('PreencherConformidade.getDados(docs-show): Erro')
                    setLoadedDocs(true)
                    return false;
                });
        }
    }

    const GetAssinatura = () => {
        return (
            <div className=" ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Biometria validada em base pública:</span> {!assinatura ? "" : dadosConf?.checado_basepublica ? "SIM" : 'NÃO'}
                </div>
                <div>
                    <button
                        className="btn-small"
                        onClick={handleAuditAssinatura}>
                        Visualizar
                    </button>
                </div>
            </div>
        )
    }

    const GetAnaliseAutomatica = () => {
        return (
            <div className=" ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Análise Automática de Proposta: </span><span>{`${analiseRealizada ? 'Análise OK' : 'Não OK/Não Realizada'}`}</span>
                </div>
                <div style={{ display: "flex" }}>
                    <div>
                        <button
                            className="btn-small"
                            title="Faz a Análise Automática da Proposta"
                            onClick={handleAnaliseAutomatica}>
                            Analisar
                        </button>
                    </div>

                    {!analiseAutomaticaOK &&
                        <div>
                            <button
                                color="success"
                                className="btn-small-success ml-1"
                                title="Faz a Análise Automática da Proposta"
                                onClick={handleAprovarAnalise}>
                                Aprovar Análise
                            </button>
                        </div>
                    }

                </div>
            </div>
        )
    }

    const AntiFraude = () => {
        console.log("PreencherConformidade.AntiFraude(0): ", antiFraude)
        if (!antiFraude) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    {/* <h6 className="text-center">Análise de Documento</h6> */}
                    <span className="title-json" >Análise de Documento</span>
                    <div style={{ fontSize: "18px !important", width: "100%", maxHeight: _maxHeight, overflow: "auto" }}>
                        {highlightedJSON(antiFraude)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setShowAntiFraude(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const ConsultaMargem = () => {
        console.log("PreencherConformidade.ConsultaMargem(0): ", consultaMargem)
        if (!consultaMargem) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    <span className="title-json" style={{ fontSize: "18px !important" }}>Consulta de Margem</span>
                    <div style={{ fontSize: "18px !important", width: "100%", maxHeight: _maxHeight, overflow: "auto" }}>
                        {highlightedJSON(consultaMargem)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setConsultaMargem(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const AuditAssinatura = () => {
        console.log("PreencherConformidade.auditAssinatura(0): ", props.detalhes)
        if (!auditAssinatura) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    <span className="title-json" >Auditoria de Assinatura</span>
                    <div style={{ fontSize: "10px", width: "100%", maxHeight: _maxHeight, overflow: "auto" }}>
                        {highlightedJSON(auditAssinatura)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setAuditAssinatura(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const GetChecagemCredLink = () => {
        return (
            <div className=" ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Checar dados na CredLink</span>
                    <span className="ml-1">{credlinkData ? getComparacaoPerc(credlinkData.data || credlinkData) : "Não Consultado"}</span>
                </div>
                <div style={{ fontSize: "12px" }}>
                    <button
                        className="btn-small"
                        onClick={() => handleChecarCredLink(false)}>
                        Checar
                    </button>
                    {credlinkData &&
                        <button
                            className="btn-small ml-1"
                            onClick={() => handleChecarCredLink(true)}>
                            Refazer Checagem
                        </button>
                    }
                </div>
            </div>
        )
    }

    const ChecagemCredLink = () => {
        console.log("PreencherConformidade.ChecagemCredLink(0): ", props.detalhes)
        if (!checagemCredLink) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    <span className="title-json" style={{ fontSize: "18px !important" }} >Checagem CredLink</span>
                    <div style={{ fontSize: "18px", width: "100%", maxHeight: width() == "mobile" ? _maxHeight : "350px", overflow: "auto" }}>
                        {highlightedJSON(checagemCredLink)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setChecagemCredLink(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const AnaliseAutomatica = () => {
        console.log("PreencherConformidade.auditAssinatura(0): ", props.detalhes)
        if (!analiseAutomatica) return (<></>);

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    <span className="title-json" >Análise Automática</span>
                    <div style={{ fontSize: "10px", width: "100%", maxHeight: _maxHeight, overflow: "auto" }}>
                        {/* <div>Teste</div> */}
                        {highlightedJSON(analiseAutomatica)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setAnaliseAutomatica(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const GetAntifraude = () => {
        const analiseAprovada = (dadosConf?.dados_antifraude || antiFraude)?.StatusOK
        const analise = (dadosConf?.dados_antifraude || antiFraude)
        const analisando = (analise && !analise.Status) || analise?.Status == 0 || analise?.Status == 1 || analise?.Status == 2
        console.log("PreencherConformidade.GetAntifraude(teste): ", analiseAprovada)
        console.log("PreencherConformidade.GetAntifraude(analise): ", analise)
        return (
            <div className=" ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Análise do documento realizada:</span> {analise && !analisando ? "SIM" : analisando ? "ANALISANDO" : 'NÃO'}
                    {analise &&
                        !analisando &&
                        <span style={{
                            color: analiseAprovada ? "darkgreen" : "darkred", fontWeight: "bold",
                            fontSize: "13px", marginLeft: "5px"
                        }}>{`(Análise ${analiseAprovada ? "Aprovada" : "Não Aprovada"})`}</span>}
                </div>
                <div style={{ fontSize: "12px" }}>
                    {!dadosConf?.dados_antifraude && !antiFraude &&
                        <button
                            className="btn-small"
                            onClick={handleAntifraude}>
                            Analisar</button>}
                    {(dadosConf?.dados_antifraude || antiFraude) &&
                        <button
                            className={"btn-small " + ((!dadosConf?.dados_antifraude && !antiFraude) ? "ml-2" : "")}
                            onClick={handleConsultaAntifraude}
                        >
                            Visualizar</button>}
                    {analise &&
                        !analise?.StatusOK &&
                        ["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) >= 0 &&
                        <button
                            className={"btn-small ml-2"}
                            onClick={handleAntifraude}
                        >
                            Re-Analisar</button>}
                </div>
            </div>
        )
    }

    const GetConsultaMargem = () => {
        return (
            <div className=" ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Margem consignável</span>
                </div>
                <div style={{ fontSize: "12px" }}>
                    <button
                        className="btn-small"
                        onClick={handleConsultaMargem}>
                        Consultar
                    </button>
                </div>
            </div>
        )
    }

    const GetEmitente = () => {
        return (
            <div className="col-12" style={{ border: "1px solid #ccc", fontSize: "12px", padding: "5px" }}>
                {/* <div className="text-center mb-1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Dados cadastrados no sistema
                </div> */}
                <div>
                    <span style={{ fontWeight: "bold" }}>Nome:</span> {emitente?.nome}<br />
                    <span style={{ fontWeight: "bold" }}>CPF:</span> {emitente?.cpf} <span style={{ fontWeight: "bold" }}>D. Nascimento:</span> {moment(emitente?.ddn).format("DD/MM/YYYY")}<br />
                    <span style={{ fontWeight: "bold" }}>Banco:</span> {emitente?.banco}
                    <span style={{ fontWeight: "bold", marginLeft: "2px" }}>Agência:</span> {emitente?.agencia}
                    <span style={{ fontWeight: "bold", marginLeft: "2px" }}>Conta:</span> {emitente?.conta} <br />
                    <span style={{ fontWeight: "bold" }}>Tipo de Conta:</span> {emitente?.t_conta} <br />
                    <span style={{ fontWeight: "bold" }}>Mat./Benefício:</span> {emitente?.matricula} <br />
                    <span style={{ fontWeight: "bold" }}>Celular:</span> {emitente?.celular} <span style={{ fontWeight: "bold" }}>Telefone:</span> {emitente?.telefone}<br />
                    <span style={{ fontWeight: "bold" }}>email:</span> {emitente?.email} <br />
                    <span style={{ fontWeight: "bold" }}>Vlr Líquido:</span> {formatCurrency(dadosConf?.valor_liquido || props.detalhes?.valor_liquido)}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>Prazo:</span> {props.detalhes?.prazo}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>Vlr Parcelas:</span> {formatCurrency(dadosConf?.valor_parcelas || props.detalhes?.valor_parcelas)} <br />
                    <div style={{ borderTop: "1px solid", marginTop: "5px" }}>
                        <span style={{ fontWeight: "bold", }}>Endereço:</span> {emitente?.endereco + ', ' + emitente.cidade + '-' + emitente.uf + '. CEP: ' + emitente.cep} <br />
                        {dadosConf?.endereco &&
                            <>
                                {/* <hr /> */}
                                <div className="text-center" style={{ fontSize: "12px", backgroundColor: "#CFCDCD", padding: "2px", borderRadius: "2px" }}>
                                    <span style={{ fontWeight: "bold" }}>Documento Assinado em:</span> <br />
                                    {dadosConf.endereco}
                                </div>
                            </>
                        }
                    </div>
                </div>

            </div>
        )
    }

    const Documentos = () => {
        let _urlDoc = null
        console.log("CompConformidade.Documentos: ", documentos)
        return (
            <>
                <ul style={{ listStyleType: "square", }}>
                    {documentos?.map((row, i) => {
                        // console.log("CompConformidade.Documentos(row): ", row)
                        //-- Atribuindo url da CCB
                        if (row.tipo == "ccb" || row.tipo == "ccb_port" || row.tipo == "ccb_refinport")
                            handlePrintFirstPage()

                        if (row.url)
                            return (
                                <>
                                    <li style={{ marginTop: "15px" }}>
                                        {/* <a href={row.url} target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} onClick={() => setUrlDoc(row.url)}> */}
                                        <a style={{ cursor: "pointer" }} onClick={() => handleUrlDoc(row.url)}>
                                            {capitalize(row.descricao || row.tipo)}
                                        </a>
                                    </li>
                                </>
                            );
                    })}
                </ul>


            </>
        )
    }

    const handleAnaliseOCR = async () => {
        async function callback(e) {
            console.log("PreencherConformidade.handleAnaliseOCR(0): ", e)
            if (!e) return false;

            console.log("PreencherConformidade.handleAnaliseOCR(1)")
            setAguarde(true)
            await sendData({
                uri: uriList('checarAnaliseOCR'),
                content: { proposta_uuid: props?.proposta, cpf: props?.detalhes?.cpf, forca: true },
                signal: null,
                method: "POST",
            })
                .then((res) => {
                    console.log("PreencherConformidade.handleAnaliseOCR(1): ", JSON.stringify(res))
                    if (res?.status > 200) {
                        swalError({ title: "Erro ao Analisar documento.", text: JSON.stringify(res?.message || res) });
                    }
                    else
                        handleConsultaAnaliseOCR()
                })
                .catch((e) => {

                    console.log(JSON.stringify(e));
                    return false;
                });
            setAguarde(false)
        }
        confirmSwal({ callback, title: "Confirma a execução do processo?" });
    }

    const handleConsultaAnaliseOCR = async (_getDados = true) => {
        console.log("PreencherConformidade.handleConsultaAnaliseOCR(0)")
        // if (AnaliseOCR) return setShowAnaliseOCR(true)
        setAguarde(true)
        await sendData({
            uri: uriList('consultarAnaliseOCR'),
            content: { proposta_uuid: props?.proposta },
            signal: null,
            method: "POST",
        })
            .then((res) => {
                console.log("PreencherConformidade.handleConsultaAnaliseOCR(1): ", res)
                if (res?.status > 200) throw new Error()
                setAnaliseOCR(res?.data || res)
                setShowAnaliseOCR(true)
                getDados()
            })
            .catch((e) => {
                console.log(e);
                return false;
            });

        setAguarde(false)
    }

    const AnaliseOCR = () => {
        console.log("PreencherConformidade.AnaliseOCR(0): ", analiseOCR)
        if (!analiseOCR) return;

        return (
            <div className="mt-3" style={{ display: "block" }} >
                <div className="card">
                    {/* <h6 className="text-center">Análise de Documento</h6> */}
                    <span className="title-json" >Análise de Documento</span>
                    <div style={{ fontSize: "18px !important", width: "100%", maxHeight: _maxHeight, overflow: "auto" }}>
                        {highlightedJSON(analiseOCR)}
                    </div>
                </div>
                <div className="mt-1" style={{ fontSize: "12px" }}>
                    <button
                        // className="btn-small"
                        onClick={() => { setShowAnaliseOCR(false) }}>
                        Fechar
                    </button>
                </div>
            </div>
        );
    }

    const GetAnaliseOCR = () => {
        const analiseAprovada = (dadosConf?.dados_AnaliseOCR || analiseOCR)?.StatusOK
        const analise = (dadosConf?.dados_AnaliseOCR || analiseOCR)
        const analisando = (analise && !analise.Status) || analise?.Status == 0 || analise?.Status == 1 || analise?.Status == 2
        console.log("PreencherConformidade.GetAnaliseOCR(teste): ", analiseAprovada)
        console.log("PreencherConformidade.GetAnaliseOCR(analise): ", analise)
        return (
            <div className=" ml-2" style={{ display: "block" }}>
                <div>
                    <span style={{ fontWeight: "bold" }}>Análise do documento realizada:</span> {analise && !analisando ? "SIM" : analisando ? "ANALISANDO" : 'NÃO'}
                    {analise &&
                        !analisando &&
                        <span style={{
                            color: analiseAprovada ? "darkgreen" : "darkred", fontWeight: "bold",
                            fontSize: "13px", marginLeft: "5px"
                        }}>{`(Análise ${analiseAprovada ? "Aprovada" : "Não Aprovada"})`}</span>}
                </div>
                <div style={{ fontSize: "12px" }}>
                    {!dadosConf?.dados_AnaliseOCR && !analiseOCR &&
                        <button
                            className="btn-small"
                            onClick={handleAnaliseOCR}>
                            Analisar</button>}
                    {(dadosConf?.dados_AnaliseOCR || analiseOCR) &&
                        <button
                            className={"btn-small " + ((!dadosConf?.dados_AnaliseOCR && !analiseOCR) ? "ml-2" : "")}
                            onClick={handleConsultaAnaliseOCR}
                        >
                            Visualizar</button>}
                    {analise &&
                        !analise?.StatusOK &&
                        ["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) >= 0 &&
                        <button
                            className={"btn-small ml-2"}
                            onClick={handleAnaliseOCR}
                        >
                            Re-Analisar</button>}
                </div>
            </div>
        )
    }

    return (
        <>
            {aguarde && <Aguarde />}
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                // style={{ border: "none" }}
                onSelect={(k) => setKey(k)}
            >

                <Tab eventKey="home" title="Principal">
                    <div
                        className="form"
                        style={{
                            // overflow: "hidden",
                            overflowY: "auto",
                            border: "0.5px solid",
                            borderTop: "0",
                            borderColor: "lightGray",
                            padding: "10px",
                            // minHeight: "400px",
                            maxHeight: width() == "mobile" ? "450px" : "",
                        }}
                    >
                        {loaded &&
                            <>

                                <GetEmitente />
                                {!showAntiFraude && !showAnaliseOCR && !auditAssinatura && !consultaMargem && !analiseAutomatica && !checagemCredLink &&
                                    <div className="d-flex col-12 mt-1">
                                        <div className="col-6 pt-3 pb-3 gap-5" style={{ border: "1px solid #ccc", gap: "10px", fontSize: "12px", padding: "5px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <GetAssinatura />
                                            <GetAntifraude />
                                            <GetConsultaMargem />
                                        </div>
                                        <div className="col-6 pt-3 pb-3 gap-5" style={{ border: "1px solid #ccc", gap: "10px", fontSize: "12px", padding: "5px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <GetChecagemCredLink />
                                            <GetAnaliseAutomatica />
                                            {/* <GetAnaliseOCR /> */}
                                        </div>
                                    </div>
                                }
                                {showAntiFraude && <AntiFraude />}
                                {/* {showAnaliseOCR && <AnaliseOCR />} */}
                                {auditAssinatura && <AuditAssinatura />}
                                {consultaMargem && <ConsultaMargem />}
                                {analiseAutomatica && <AnaliseAutomatica />}
                                {checagemCredLink && <ChecagemCredLink />}
                            </>
                        }
                        {!loaded && <div>Carregando...</div>}
                    </div>
                </Tab>

                <Tab eventKey="docs" title="Documentos">
                    <div style={{
                        width: "100%", fontSize: "16px", padding: "5px", textAlign: "center", border: "0.5px solid",
                        borderTop: "0",
                        borderColor: "lightGray",
                    }}>
                        <span style={{ textAlign: "center", fontWeight: "bold", color: props?.detalhes?.documentos_checados == "SIM" ? "green" : "red" }}>
                            {props?.detalhes?.documentos_checados == "SIM" ? "!! Documentos aprovados !!" : ""}
                        </span>
                    </div>
                    <div
                        className="form"
                        style={{
                            overflow: "hidden",
                            border: "0.5px solid",
                            borderTop: "0",
                            borderColor: "lightGray",
                            padding: "10px",
                            minHeight: "400px",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >

                        <div style={{ width: "25%" }}>
                            {loadedDocs &&
                                <Documentos />
                            }
                            {!loadedDocs && <div>Carregando documentos...</div>}
                        </div>
                        {urlDoc &&
                            <div style={{ width: "100%", border: "1px solid #E5E5E5", marginLeft: "10px", borderRadius: "5px" }}>
                                {['pdf', 'json'].indexOf(extDoc) >= 0 && <embed src={urlDoc} style={{ width: "100%", height: "400px", padding: "2px" }}
                                    onDoubleClick={() => window.open(urlDoc)} />}
                                {['pdf', 'json'].indexOf(extDoc) < 0 && <img src={urlDoc} style={{ width: "100%", height: "400px", padding: "2px" }}
                                    onDoubleClick={() => window.open(urlDoc)} />}
                            </div>}
                    </div>
                </Tab>
                {firstPage &&
                    <Tab eventKey="ccb" title="Ccb Pág1">
                        <>
                            <a href={handleGetUrlFromBase64(firstPage)}
                                download={`ccb_${props?.detalhes?.cedula_numero || props?.proposta}.pdf`}
                                target="_blank" rel="noopener noreferrer"
                                style={{ cursor: "pointer", fontStyle: "italic", textDecoration: "underline", marginLeft: "5px", marginBottom: "5px" }} >Acessar arquivo da CCB</a>
                            <div
                                className="form"
                                style={{
                                    overflow: "hidden",
                                    border: "0.5px solid",
                                    borderTop: "0",
                                    borderColor: "lightGray",
                                    padding: "10px",
                                    minHeight: "400px",
                                }}
                            >
                                <embed src={`data:application/pdf;base64,${firstPage}`} style={{ width: "100%", height: "500px" }} width={50} />
                            </div>
                        </>
                    </Tab>
                }
            </Tabs>
        </>
    );
}

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes,
        cliente: state.dados.cliente,
        user: state.dados.user,
    };
}

export default connect(mapStateToProps,)(CompConformidade);

