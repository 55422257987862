import React, { useState } from "react";
import Table from "../../table.js";
import { connect } from "react-redux";
import { getCookie, width } from "../../../helpers/general";
import ModalContent from "../../../components/layout/ModalContent";
import * as moment from "moment";

const Form = ({ ...props }) => {
  const [modalSicoob, setModalSicoob] = useState(true);

  return (
    <>
      {props.detalhes.tipo_convenio == "SICOOB" && modalSicoob && (
        <ModalContent title="Atenção" onCloseEv={() => setModalSicoob(false)}>
          Linha de crédito destinada para cooperados do Sicoob Executivo. Para
          contratar será necessário fazer sua associação e abertura de conta
          corrente. O valor do crédito contratado não precisa ser efetivado em
          sua conta sicoob.
          <br />
          <br />
          <i>OBS: Desconsidere essa mensagem caso já seja associado</i>
        </ModalContent>
      )}

      <div className="form" id="simulation-result">
        {!props.preencherCli && !props.admin ? (
          <span
            className="float-left mb-2"
            onClick={() => props.setDetails(!props.details)}
          >
            <button
              id="details-button"
              className="btn btn-sm custom-button text-left"
            >
              &#9776; Detalhes
            </button>
          </span>
        ) : (
          <br />
        )}
        {props.preencherCli ? (
          <div className="row no-margin font-weight-bold">
            <div className="col-12">
              Cliente:{" "}
              <span style={{ fontSize: "15px" }}>
                <strong>{props.userData.cliente}</strong>
              </span>
            </div>
            <div className="col-12">
              CPF:{" "}
              <span style={{ fontSize: "15px" }}>
                <strong>{props.userData.cpf}</strong>
              </span>
            </div>

            <div className="col-12">
              Tipo Operação:{" "}
              <span style={{ fontSize: "15px" }}>
                <strong>{props.userData.operacao} </strong>
              </span>
              {width() == "mobile" ? <br /> : ""}
              Data:{" "}
              <span style={{ fontSize: "15px" }}>
                <strong>
                  {moment(props.userData.created_at).format("DD/MM/YYYY")}
                </strong>
              </span>
            </div>
            <hr />
          </div>
        ) : (
          ""
        )}

        {props.admin ? (
          <Table
            matrix={props.result}
            bordered={true}
            detailed={true}
            operacao={props.userData.operacao}
            admin={props.admin}
            currency={getCookie({ label: "currency" })}
            portabilidade={props.portabilidade}
          />
        ) : !props.details && !props.preencherCli ? (
          !props.result ? (
            props.goBack()
          ) : (
            <Table
              matrix={props.result}
              bordered={false}
              detailed={false}
              operacao={props.userData.operacao}
              admin={props.admin}
              currency={getCookie({ label: "currency" })}
              portabilidade={props.portabilidade}
            />
          )
        ) : !props.userData ? (
          <h4 className="lead display-4">Carregando Detalhes</h4>
        ) : (
          <>
            <Table
              matrix={props.userData}
              bordered={true}
              detailed={true}
              operacao={props.userData.operacao}
              admin={props.admin}
              currency={getCookie({ label: "currency" })}
              portabilidade={props.portabilidade}
            />
          </>
        )}
        {!props.admin && (
          <div
            style={{
              display: "flex",
              border: "0.5px solid",
              width: "100%",
              padding: "2px",
              marginTop: "2px",
              fontWeight: "bold",
              color: "#686868",
              alignItems: "center",
              textAlign: "justify",
            }}
          >
            <i
              className="mdi mdi-alert"
              style={{
                color: "orange",
                fontSize: "40px",
                marginRight: "5px",
                fontSize: "25px",
              }}
            />

            <span style={{ fontSize: "11px" }}>
              {props.detalhes.tipo_convenio == "ORIGINAL" ||
              props.tipo_convenio == "ORIGINAL"
                ? `Declaro saber que, em decorrência do prazo necessário para liberação
          dos recursos, o Valor de cada Parcela poderá ser reduzido,
          considerando que, da Data de Emissão até a data de liberação dos
          recursos.`
                : `Proposta sujeita a análise. Valores acima descritos poderão sofrer
            alterações diárias até a data de efetivação do crédito. Não efetue
            pagamento a intermediários. Comunicação com o time InConta somente por
            canais oficiais.`}
              {/* { props.detalhes.tipo_convenio != "ORIGINAL" &&
          } */}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    preencherCli: state.dados.preencherCli,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(Form);
