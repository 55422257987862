import React, { useState, useEffect, useCallback } from "react";

import Form from '../../components/form/admin/Retencoes';

function Retencoes({ ...props }) {

    return (
        <>
            <Form />
        </>
    );
};

export default Retencoes;
