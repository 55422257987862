import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import * as moment from "moment";
import { connect } from "react-redux";

function MapView(props) {
  console.log("MapView: ", props.data);
  let d = new Date(props.data.created_at);
  const mapRef = useRef();
  
  // useEffect(() => {
  //   new window.google.maps.Map(mapRef.current, {
  //     center: { lat: props.data.latitude, lng: props.data.longitude },
  //     zoom: 1,
  //   });
  // }, []);

  function handleClose() {
    if (props.onCloseEv) props.onCloseEv();
  }
  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>Localização do Histórico</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="maxWidth750">
        <div>
          <div style={{ width: "100%", height: "100%" }} ref={mapRef}></div>
          <div className="mb-3">
            <div>
              <strong className="mr-1">Cliente:</strong>
              <span>
                {props.detalhes.cliente} | {props.detalhes.cpf}
              </span>
            </div>
            <div>
              <strong className="mr-1">Proposta:</strong>
              {props.detalhes.uuid} | {props.detalhes.operacao}
            </div>
            <div>
              <strong className="mr-1">Histórico:</strong>
              {props.data.log}
            </div>
            <div>
              <strong className="mr-1">
                {props.data.usarioi ? "Usuário e Data:" : "Data:"}{" "}
              </strong>
              {props.data.usarioi ? props.data.usarioi + " | " : ""}
              {moment(d).format("DD/MM/YYYY") + " | " + moment(d).format("LTS")}
            </div>
          </div>
          <hr />
          <div>
            <strong>Endereço:</strong> {props.data.geolocation_endereco}
          </div>
          <div style={{ fontSize: "15px" }}>
            <strong>Geolocalizacao:</strong> lat:{props.data.latitude} | long:
            {props.data.longitude}
          </div>
          <div>
            <strong>IP:</strong> {props.data.ip}
          </div>
        </div>
        <div className="mt-3">
          <Button
            className="btn"
            color="primary"
            disabled={!props.data.latitude}
            onClick={() =>
              window.open(
                `https://www.google.com.br/maps/@${props.data.latitude},${props.data.longitude},16z`,
                "Localização",
                "height=2000,width=2000"
              )
            }
          >
            Abrir Google
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(MapView);
