import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Buttons } from "../../../layout";
import { formatCurrency, width } from "../../../../helpers/general";
import * as moment from "moment";

let iniSel = {
  qtdade: 0,
  totalParcelas: 0,
  totalLiquido: 0,
  totalTAC: 0,
  totalIOF: 0,
};
const Form = ({ ...props }) => {
  const [simulacoes, setSimulacoes] = useState(props.simulacoes);
  const [selecteds, setSelecteds] = useState(iniSel);
  const [selectAll, setSelectAll] = useState(false);

  function goBack() {
    if (props.onCloseEv) props.onCloseEv();
  }

  useEffect(() => {
    calcularTotais();
  }, [simulacoes]);

  function handleSelectAll(select) {
    let _selectAll = select;
    let _simulacoes = simulacoes;
    setSelectAll(!selectAll);
    _simulacoes = _simulacoes.map((simulacao) => {
      return {
        ...simulacao,
        selected: simulacao?.valor_liquido > 0 ? _selectAll : false,
      };
    });

    console.log("handleSelectAll", _selectAll, _simulacoes);
    setSimulacoes(_simulacoes);
  }

  function submit() {
    let _simulacoes = simulacoes.filter((simulacao) => {
      return simulacao.selected;
    });

    if (props.handle) props.handle(_simulacoes);
  }

  function calcularTotais() {
    let _selecteds = iniSel;
    let _selectAll = true;
    for (let index = 0; index < simulacoes.length; index++) {
      const element = simulacoes[index];
      _selectAll = _selectAll && element.selected;
      if (element.selected) {
        _selecteds = {
          qtdade: parseFloat(_selecteds.qtdade) + 1,
          totalParcelas:
            _selecteds.totalParcelas + parseFloat(element.valor_parcelas),
          totalLiquido:
            _selecteds.totalLiquido + parseFloat(element.valor_liquido),
          totalTAC: _selecteds.totalTAC + parseFloat(element.tac),
          totalIOF: _selecteds.totalIOF + parseFloat(element.iof),
        };
      }
    }

    setSelectAll(_selectAll);
    setSelecteds(_selecteds);
    console.log("calcularTotais", _selecteds);
  }

  // useEffect(() => {
  //   calcularTotais();
  // }, [simulacoes]);

  function handle(simulacao, i) {
    // console.log("Fgts.selSimulacao.handle: ", simulacao);
    let _simulacoes = simulacoes;
    let _selected = simulacao?.valor_liquido ? !simulacao.selected : false;
    simulacao = { ...simulacao, selected: _selected };
    // console.log("Fgts.selSimulacao.handle: ", simulacao);
    _simulacoes[i] = simulacao;
    setSimulacoes(_simulacoes);

    calcularTotais();
  }

  function ModalBody() {
    return (
      <>
        <div
          className="form-group col-md-12"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="maxWidth750"
            style={{
              maxHeight: "500px",
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            <table border="1" style={{ cursor: "pointer" }}>
              <thead style={{ position: "sticky", top: "0px" }}>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="text-left font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    Vencimento
                  </td>
                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "100px" }}
                  >
                    {width() != "mobile" ? "Vlr Parcela" : "Parcela"}
                  </td>

                  {width() != "mobile" && (
                    <>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "80px" }}
                      >
                        Tarifa
                      </td>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "80px" }}
                      >
                        IOF
                      </td>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "80px" }}
                      >
                        Juros(%)
                      </td>
                    </>
                  )}

                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "100px" }}
                  >
                    {width() != "mobile" ? "Vlr Crédito" : "Crédito"}
                  </td>
                  <td
                    className="text-center font-weight-bold"
                    style={{ width: "50px" }}
                  >
                    <input
                      // type="switch"
                      type="checkbox"
                      checked={selectAll}
                      style={{ width: "15px", height: "15px" }}
                      // value={simulacao.selected}
                      onChange={() => handleSelectAll(!selectAll)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {simulacoes &&
                  simulacoes.map((simulacao, i) => (
                    <tr key={i}>
                      <td
                        id={i}
                        className="text-left"
                        style={{ width: "80px" }}
                      >
                        {moment(simulacao.primeiro_vencimento).format(
                          "DD/MM/YYYY"
                        )}
                        {/* {new Date(
                          simulacao.primeiro_vencimento
                        ).toLocaleDateString()} */}
                      </td>
                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "100px" }}
                      >{` ${formatCurrency(
                        parseFloat(simulacao.valor_parcelas).toFixed(2)
                      )}`}</td>
                      {width() != "mobile" && (
                        <>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "80px" }}
                          >{` ${formatCurrency(
                            parseFloat(simulacao.tac).toFixed(2)
                          )}`}</td>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "80px" }}
                          >
                            {` ${formatCurrency(
                              parseFloat(simulacao.iof).toFixed(2)
                            )}`}
                          </td>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "80px" }}
                          >
                            {`${formatCurrency(
                              parseFloat(simulacao.taxa_juros_mensal).toFixed(2)
                            )}`}
                          </td>
                        </>
                      )}

                      <td
                        id={i}
                        className="text-right"
                        style={{ width: "100px", backgroundColor: "#f2f2f2" }}
                      >{` ${formatCurrency(
                        parseFloat(simulacao.valor_liquido).toFixed(2)
                      )}`}</td>

                      <td
                        id={i}
                        className="text-center"
                        style={{
                          width: "50px",
                          verticalAlign: "middle",
                        }}
                      >
                        {simulacao.valor_liquido > 0 && (
                          <input
                            type="checkbox"
                            style={{ height: "15px", width: "15px" }}
                            checked={simulacao.selected}
                            // value={simulacao.selected}
                            onChange={() => handle(simulacao, i)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td className="text-right " style={{ width: "80px" }}>
                    {selecteds.qtdade}
                  </td>
                  <td className="text-right " style={{ width: "100px" }}>
                    {` ${formatCurrency(
                      parseFloat(selecteds.totalParcelas).toFixed(2)
                    )}`}
                  </td>
                  {width() != "mobile" && (
                    <>
                      <td className="text-right " style={{ width: "80px" }}>
                        {` ${formatCurrency(
                          parseFloat(selecteds.totalTAC).toFixed(2)
                        )}`}
                      </td>
                      <td className="text-right " style={{ width: "80px" }}>
                        {` ${formatCurrency(
                          parseFloat(selecteds.totalIOF).toFixed(2)
                        )}`}
                      </td>
                      <td
                        className="text-right "
                        style={{ width: "80px" }}
                      ></td>
                    </>
                  )}

                  <td
                    className="text-right font-weight-bold"
                    style={{ width: "100px", backgroundColor: "#f2f2f2" }}
                  >
                    {` ${formatCurrency(
                      parseFloat(selecteds.totalLiquido).toFixed(2)
                    )}`}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Modal
        size="lg"
        show={true}
        onHide={() => props.onCloseEv()}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Parcelas Disponíveis</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <ModalBody />
          <Buttons
            first="Voltar"
            second={"Avançar"}
            goBack={goBack}
            submit={submit}
            disabledSecond={!selecteds.qtdade}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    detalhes: state.dados.detalhes,
    proposta: state.dados.proposta,
  };
}

export default connect(mapStateToProps)(Form);
