/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as moment from 'moment'
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
import { confirmSwal, swalError } from "../../../swal";
import { sendData } from "../../../../helpers/auth";
import { Aguarde, } from "../../../../helpers/general";

let tipos = [
  "Inclusão de Propostas",
  "Inclusão de Agentes",
];

const IncluirSolicitacao = ({ ...props }) => {
  const [item, setItem] = useState(props.item);
  const [show, setShow] = useState(true);
  const [executando, setExecutando] = useState(false);

  function handleClose(e, close) {
    if (e?.preventDefault) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close);
  }

  async function handleExecutar(e) {
    e.preventDefault();
    async function callback(e) {
      if (!e) return;
      setExecutando(true)

      const content = {
        uuid: item?.uuid,
        item
      }

      await sendData({ uri: uriList("executarSolicitacao"), content, method: "POST" })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) {
            swalError({ title: `Erro ao executar solicitações!`, text: res.data || res.message })
          };
          setExecutando(false);
          handleClose(e, false);
        })
        .catch((e) => {
          setExecutando(false);
          console.log(e)
          // alert("Erro ao executar solicitação.");
        });
    }

    confirmSwal({ callback, title: `Confirma execução da solicitação?` })
  }

  async function handleCancelar(e) {
    e.preventDefault();
    async function callback(e) {
      if (!e) return;
      setExecutando(true)

      const content = {
        uuid: item?.uuid,
        item
      }

      await sendData({ uri: uriList("cancelarSolicitacao"), content, method: "POST" })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setExecutando(false);
          handleClose(e, false);
        })
        .catch((e) => {
          setExecutando(false);
          console.log(e)
          alert("Erro ao cancelar solicitação.");
        });
    }

    confirmSwal({ callback, title: `Confirma o cancelamento da solicitação?` })
  }

  return (
    <>
      <Modal
        size="md"
        show={show}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Executar/Cancelar Solicitação</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          {executando && <Aguarde legenda={"Aguarde, executando solicitação..."} />}
          <div className="card"
            style={{
              border: `1px solid ${"#FFA500"}`,
              opacity: "100%",
              borderRadius: "5px",
              padding: "5px",
              marginBottom: "15px",
            }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Criação: {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}</div>
              <div>Id: {item?.id}</div>
            </div>
            <div>
              Uuid: {item?.uuid}
            </div>
            <div>
              Usuário: {item?.nome_usuario}
            </div>
            {item?.url_arquivo &&
              <div>
                <a
                  href={item?.url_arquivo}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Clique para abrir o Arquivo
                </a>

              </div>}
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button color="success" onClick={handleExecutar} style={{ width: "150px" }} title="Clique para executar a solicitação">
              Executar
            </Button>
            <Button color="danger" onClick={handleCancelar} style={{ width: "150px" }} title="Clique para cancelar a solicitação">
              Cancelar
            </Button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>

          <Button
            color="secondary"
            onClick={(e) => {
              handleClose(e, true);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};


export default IncluirSolicitacao
