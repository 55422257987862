import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
import { swalError, confirmSwal } from "../../../swal";
import { sendMultiPartData, sendData } from "../../../../helpers/auth";
import { getCookie, Aguarde, getTiposDocumentos } from "../../../../helpers/general";

const IncluirRetencao = ({ ...props }) => {
  const [show, setShow] = useState(true);
  const [gravando, setGravando] = useState(false);
  const [data, setData] = useState({
    tipo: "",
    status: "pendente",
    observacao: "",
    descricao: "",
    ip: getCookie({ label: "ip" }),
  });
  const [file, setFile] = useState(null);

  function handleClose(e, close, _ok = false) {
    if (e?.preventDefault) e.preventDefault();
    setShow(false);
    if (props.onCloseEv) props.onCloseEv(close, _ok);
  }

  async function handlePost(e) {
    e.preventDefault();
    async function callback(e) {
      if (!e) return;
      setGravando(true)
      let dObj = new FormData();
      dObj.append("tipo", data?.tipo);
      dObj.append("observacao", data?.observacao || data?.descricao || data?.tipo);
      dObj.append("descricao", data?.descricao || data?.tipo);
      dObj.append("file", file);
      dObj.append("ip", data?.ip);
      dObj.append("status", data?.status);

      console.log("IncluirRetencao - dObj: ", dObj);
      await sendMultiPartData({
        uri: uriList("gravarRetencao"),
        content: dObj,
        method: 'POST'
      })
        .then(async (res) => {
          if (res.status && parseInt(res.status) > 200)
            throw new Error(res.message[0].message);

          // await handleExecutar(res)
          handleClose(e, true, true);
          setGravando(false);
        })
        .catch((e) => {
          setGravando(false);
          alert(e);
        });
    }

    if (!data?.tipo || !file)
      return swalError({ title: "Informe todos os campos" });

    confirmSwal({ callback, title: `Confirma gravação do registro?` })
  }

  const excluirArquivo = (e) => {
    e.preventDefault();
    setFile(null);
  }

  return (
    <>
      {gravando && <Aguarde legenda={"Aguarde, gravando dados..."} />}
      <Modal
        size="md"
        show={show}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Incluir solicitação</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          {gravando &&
            <Aguarde legenda={"Aguarde, gravando solicitação..."} />}

          <div className="row">
            <div className="col-12">
              <label className="m-0 font-90">Descrição</label>
              <input
                type="text"
                className="form-control"
                value={data.descricao}
                required
                onChange={(e) => setData({ ...data, descricao: e.target.value })}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label className="m-0 font-90">Observações</label>
              <textarea
                type="textArea"
                className="form-control"
                value={data.observacao}
                required
                rows="5"
                onChange={(e) => setData({ ...data, observacao: e.target.value })}
              />
            </div>
          </div>

          <div className="row" style={{
            heigth: "50px", alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center",
            boerder: "1px solid #E4E4E5", borderRadius: "10% !important",
          }}>
            <div className="col-md-12" style={{ cursor: "pointer", }}>
              <label className="m-0 font-90">Arquivo</label>
              <div style={{
                backgroundColor: !file ? "white" : "#6ECEDE", borderRadius: "10% !important",
                alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center",
                border: "0.5px solid #E4E4E5",
              }}>
                {file &&
                  <i className="mdi mdi-delete"
                    heigth="60px"
                    style={{ margin: "10px auto", color: "#EA3827", cursor: "pointer", }}
                    title="Clique para excluir o arquivo"
                    onClick={excluirArquivo}
                  >
                    <span style={{ color: "white" }}> Clique para excluir</span>
                  </i>

                }
                {!file &&
                  <Dropzone
                    style={{ width: "100%", heigth: "100%" }}
                    name={props.name}
                    onDrop={(acceptedFiles) => {
                      try {
                        // console.log("dropzone - ini");
                        let files = acceptedFiles.map((file, i) => {
                          let url = URL.createObjectURL(file);
                          let ext = file.name.split(".");
                          ext = ext[ext.length - 1];

                          file.preview = url;
                          file.ext = ext;

                          setFile(file);
                          return file;
                        });
                      } catch (e) {
                        // console.log("Erro ao fazer OnDrop....: ", e);

                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className={
                          "drop-box"
                        }
                        style={{ width: "100%" }}
                      >
                        <div
                          {...getRootProps({
                            onFocus: (e) => (e.currentTarget.style.outline = "none"),
                          })}
                        >
                          <input
                            {...getInputProps({
                              multiple: false,
                              onClick: (e) => props.data && e.preventDefault(),
                              // accept: "image/jpg,image/jpeg,image/png",
                              accept: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain",
                              disabled: props.disabled,
                            })}
                          />

                          <h5 className="text-black text-center"
                            title="Clique para fazer upload do Arquivo"
                          ><i className="mdi mdi-upload" />Clique para fazer Upload</h5>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handlePost}>
            Incluir Retencao
          </Button>
          <Button
            color="secondary"
            onClick={(e) => {
              handleClose(e, true);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default IncluirRetencao
