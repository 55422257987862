const orgaosList = [
    { value: "SSP", descricao: "SSP - Secretaria de Segurança Pública" },
    { value: "OAB", descricao: "OAB - Ordem dos Advogados do Brasil" },
    { value: "PC", descricao: "PC - Policia Civil" },
    { value: "DGPC", descricao: "DGPC - Diretoria Geral de Policia Civil" },
    { value: "PM", descricao: "PM - Polícia Militar" },
    { value: "SPTC", descricao: "SPTC - Superintendência de Polícia Técnico-Científica" },
    { value: "CBM", descricao: "CBM - Corpo de Bombeiros Militar" },
    { value: "Detran", descricao: "Detran - Departamento Estadual de Transito" },
    { value: "CR", descricao: "Conselhos Regionais" },
    { value: "IFP", descricao: "IFP - Instituto Félix Pacheco" },
    { value: "IPF", descricao: "IPF - Instituto Pereira Faustino" },
    { value: "IML", descricao: "IML - Instituto Médico-Legal" },
    { value: "MTE", descricao: "MTE - Ministério do Trabalho e Emprego" },
    { value: "MMA", descricao: "MMA - Ministério da Marinha" },
    { value: "MAE", descricao: "MAE - Ministério da Aeronáutica" },
    { value: "MEX", descricao: "MEX - Ministério do Exército" },
    { value: "POF", descricao: "POF - Polícia Federal" },
    { value: "SES", descricao: "SES - Carteira de Estrangeiro" },
    { value: "SJS", descricao: "SJS - Secretaria da Justiça e Segurança" },
    { value: "SJTS", descricao: "SJTS - Secretaria da Justiça do Trabalho e Segurança" },
    { value: "OUTROS", descricao: "Outros (inclusive exterior)" },
]

function desativarSistema() {
    // Obter data atual
    var dataAtual = new Date();
    var horaAtual = dataAtual.getHours();

    if (horaAtual >= 19 || horaAtual < 8) {
        return true; // A hora atual está entre 19h e 8h da manhã
    }

    // Verificar se é um final de semana (sábado ou domingo)
    if (dataAtual.getDay() === 0 || dataAtual.getDay() === 6) {
        return true; // Desativa o sistema
    }

    // Verificar se é um feriado
    // Aqui você pode adicionar sua lógica para verificar feriados específicos
    var feriados = [
        new Date(2023, 0, 1), // Exemplo: Ano Novo
        new Date(2023, 3, 21), // Exemplo: Tiradentes
        new Date(2023, 4, 1), // Exemplo: Dia do Trabalho
        // Adicione mais feriados conforme necessário
    ];

    for (var i = 0; i < feriados.length; i++) {
        var feriado = feriados[i];
        if (
            dataAtual.getFullYear() === feriado.getFullYear() &&
            dataAtual.getMonth() === feriado.getMonth() &&
            dataAtual.getDate() === feriado.getDate()
        ) {
            return true; // Desativa o sistema
        }
    }

    return false; // Não é final de semana nem feriado, mantém o sistema ativado
}

function obterQuantidadeDiasMesAtual() {
    const hoje = new Date();
    const ultimoDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
    return ultimoDiaDoMes.getDate();
}

function calcularDiferencaEmDias(dataInicial, dataFinal) {
    const dataInicio = new Date(dataInicial);
    const dataFim = new Date(dataFinal);

    const diferencaEmTempo = Math.abs(dataFim - dataInicio);
    const diferencaEmDias = Math.ceil(diferencaEmTempo / (1000 * 60 * 60 * 24));

    return diferencaEmDias;
}

function temPermissao(user, _permissao) {
    if (user?.tipo == "ADMINISTRADOR" || user?.tipo == "OPERADOR")
        return true

    const permissoes = user?.permissoes || user?.controle_acesso?.permissoes;
    console.log("sidebar.temPermissao(user): ", user)
    console.log("sidebar.temPermissao(Permissões): ", { permissoes, _permissao })
    if (!permissoes) return false;

    const _temPermissao = permissoes.some(permissao => _permissao.includes(permissao.name))
    console.log("sidebar.temPermissao(_temPermissao): ", _temPermissao)
    return _temPermissao
}


function base64URLToGuid(base64url) {
    if (!base64url) return null;
    // Converte a string Base64URL de volta para Base64 (substitui - por + e _ por /)
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');

    // Adiciona padding de volta (se necessário)
    const paddedBase64 = base64.padEnd(base64.length + (base64.length % 4), '=');

    // Converte a string Base64 em um Buffer
    const buffer = Buffer.from(paddedBase64, 'base64');

    // Converte o Buffer para uma string hexadecimal
    const hex = buffer.toString('hex');

    // Formata o hex para o formato de GUID (8-4-4-4-12)
    const guid = `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;

    return guid;
}

module.exports = {
    orgaosList,
    desativarSistema,
    obterQuantidadeDiasMesAtual,
    calcularDiferencaEmDias,
    temPermissao,
    base64URLToGuid
}