import React, { useState, useEffect } from "react";
import { InputAlert, InputLabelAlert } from "../../layout";
import Modal from "../../../components/modal";
import { connect } from "react-redux";

function Form({ ...props }) {
  const [modal, setModal] = useState("");
  const [habCpf] = useState(!props.data.nome);

  useEffect(() => {
    console.log("simulator.data(form):", props.data)
  }, []);

  const openInfoModal = (setHelp) => {
    const text = [
      "Favor informar a senha para reserva e averbação de margem consigável.",
      "A Senha será utilizada única e exclusivamente para reserva e averbação de margem consignável.",
      "A RBCB não utiliza a senha do servidor para nenhum outro fim.",
    ];

    if (setHelp) setHelp(true);

    setModal(
      <Modal
        title={"Saiba mais"}
        text={text}
        list={true}
        onClick={() => {
          setModal("");
          if (setHelp) setHelp(false);
        }}
      />
    );
  };

  const handleCpf = (e) => {
    e.preventDefault();
    props.CPF(e)
  }

  return (
    <>
      {modal}
      <>
        <div className="row">
          <div className="form-group col-md-5">
            <InputLabelAlert
              type="tel"
              label="CPF"
              id="cpf"
              value={props.data.cpf}
              handle={handleCpf}
              onBlur={props.onBlur}
              autoComplete="off"
              warning={props.warning.cpf}
              length="14"
              focus={!props.data.cpf}
              disabled={(props.alterarProposta && !habCpf)}
              onContextMenu={(e) => e.preventDefault()}
            />

            <InputAlert
              display={props.alternate.cpf}
              messages={props.alternateMessage.cpf}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <InputLabelAlert
              type="text"
              label="Nome"
              autoComplete="off"
              id="nome"
              minLength={10}
              value={props.data.nome}
              handle={props.nome}
              // focus={!props.loadData?.disableName && ((props.alterarProposta && !habCpf) || props.data.cpf)}
              focus={false}
              disabled={props.loadData?.disableName && props.data?.nome}
              warning={props.warning.nome}
              length="150"
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="civil" className="no-margin">
              Estado Civil
            </label>
            <select
              className="form-control custom-select"
              name="civil"
              id="civil"
              minLength={5}
              value={props.data.civil}
              focus={props.loadData?.disableName}
              style={{
                borderColor: props.warning.civil ? "red" : "",
                textAlign: "left",
              }}
              onChange={props.change}
            >
              <option value="CASADO">Casado</option>
              <option value="SOLTEIRO">Solteiro</option>
              <option value="DIVORCIADO">Divorciado</option>
              <option value="VIUVO">Viúvo</option>
              <option value="UNITAO">União Estável</option>
              <option value="OUTROS">Outros</option>
            </select>
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="sexo" className="no-margin">
              Sexo
            </label>
            <select
              className="form-control custom-select"
              name="sexo"
              id="sexo"
              minLength={5}
              value={props.data.sexo}
              disabled={props.loadData?.disableName && props.data.sexo}
              style={{
                borderColor: props.warning.sexo ? "red" : "",
                textAlign: "left",
              }}
              onChange={props.change}
            >
              <option value="MASCULINO">Masculino</option>

              <option value="FEMININO">Feminino</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <InputLabelAlert
              type="tel"
              label="Data de Nascimento"
              field={props.alternateMessage.ddn}
              id="ddn"
              value={props.data.ddn}
              handle={props.ddn}
              warning={props.warning.ddn}
              disabled={props.loadData?.disableName && props.data.ddn}
              length="10"
              focus={false}
              onContextMenu={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
            />

            <InputAlert
              display={props.alternate.ddn}
              messages={props.alternateMessage.ddn}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="ufn" className="no-margin">
              UF de Nascimento
            </label>
            <select
              id="ufn"
              name="ufn"
              minLength={2}
              value={props.data.ufn}
              onChange={props.change}
              style={{
                borderColor: props.warning.ufn ? "red" : "",
                textAlign: "left",
              }}
              className="form-control custom-select"
            >
              {[...props.estados.estados].map((estados, i) => (
                <option key={`${estados.sigla}${i}}`} value={estados.sigla}>
                  {estados.nome}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="cidaden" className="no-margin">
              Cidade de Nascimento
            </label>
            <select
              id="cidaden"
              name="cidaden"
              minLength={3}
              // defaultValue={props.data.cidaden}
              // value={props.data.cidaden}
              onChange={props.change}
              style={{
                borderColor: props.warning.cidade ? "red" : "",
                textAlign: "left",
              }}
              className="form-control custom-select"
            >
              {props.data.ufn &&
                [...props.estados.estados].map(
                  (estados) =>
                    estados.sigla === props.data.ufn &&
                    estados.cidades.map((cidades, i) => (
                      <option
                        selected={cidades && props.data.cidaden &&
                          cidades.toUpperCase() ==
                          props.data.cidaden.toUpperCase()
                        }
                        key={`${cidades}-$i`}
                        value={cidades}
                      >
                        {cidades}
                      </option>
                    ))
                )}
            </select>
          </div>

          <div className="form-group col-md-6">
            <InputLabelAlert
              type="text"
              label="Matrícula"
              id="matricula"
              value={props.data.matricula}
              handle={props.change}
              warning={props.warning.matricula}
              length="50"
              minLength={5}
              focus={false}
              preventDefault={true}
            />
          </div>

          {/* {props.convenio != "siape" && (
            <div className="form-group col-md-3 col-12">
              <InputLabelAlert
                type="password"
                label="Senha Averbação"
                id="senha"
                value={props.data.senha}
                handle={props.change}
                warning={props.warning.senha}
                length="100"
                minLength={3}
                lowercase={true}
                focus={false}
                onContextMenu={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
                help={openInfoModal}
                tipText={
                  "A senha será utilizada única e exclusivamente para reserva e averbação de margem consignável"
                }
              />
            </div>
          )} */}
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <InputLabelAlert
              type="text"
              label="Nome da Mãe"
              id="mae"
              minLength={10}
              value={props.data.mae}
              handle={props.change}
              // disabled={props.loadData?.disableName && props.data.mae}
              warning={props.warning.mae}
              length="150"
              focus={false}
            />
          </div>

          <div className="form-group col-md-3">
            <InputLabelAlert
              type="text"
              label="Mat. Pensionista"
              id="matricula_pensionista"
              value={props.data.matricula_pensionista}
              handle={props.change}
              warning={props.warning.matricula_pensionista}
              length="50"
              focus={false}
            />
          </div>

          <div className="form-group col-md-3">
            {/* <input id="salario" type="number" step="0.010" value = {props.data.salario} onChange = {props.change}/>                     */}
            <InputLabelAlert
              type="tel"
              label="Renda B. Mensal"
              id="salario"
              cifrao={true}
              value={props.data.salario}
              step="0.010"
              handle={props.change}
              onChange={props.data.salario}
              warning={props.warning.salario}
              onBlur={props.onBlur}
              maxValue={50000}
              tipText={
                "Renda Bruta Mensal é o valor recebido antes da incidência de descontos."
              }
              focus={false}
            />
          </div>
        </div>
        {props.registroRFInvalido &&
          <div className="row mt-2 ml-1 mr-1">
            <span style={{
              width: "100%", borderRadius: "5px", backgroundColor: "#E6E6E6",
              border: "1.5px solid #9F9F9F", fontWeight: "bold", color: "red", textAlign: "center"
            }}>!!! Cliente com CPF irregular na Receita Federal !!!</span>
          </div>}
      </>
    </>
  );
}

// export default Form;

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    alterarProposta: state.dados.alterarProposta,
  };
}

export default connect(mapStateToProps)(Form);
