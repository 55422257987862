import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { width, formatCurrency, Aguarde } from "../../helpers/general";
// import * as moment from 'moment'
// import { setClienteDados } from '../../store/actions/dados';
import { sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import Geolocalizacao from "../../helpers/geolocalizacao";

function ConfirmPropOrg(props) {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(props.data);
  const [aguarde, setAguarde] = useState(false);
  const [getLocation, setGetLocation] = useState(false);

  function toggleAccept() {
    if (props.onAccept) props.onAccept();
  }

  // const Aguarde = () => {
  //   return (
  //     <Modal
  //       size={"md"}
  //       scrollable
  //       show={true}
  //       animation={false}
  //       backdrop="static"
  //       centered
  //     >
  //       <Modal.Body
  //         className="maxWidth750"
  //         style={{
  //           width: "100%",
  //           justifyContent: "center",
  //           textAlign: "center",
  //         }}
  //       >
  //         <Spinner animation="border" />
  //         {"  "}
  //         Aguarde enquanto o processo é finalizado...
  //       </Modal.Body>
  //     </Modal>
  //   );
  // };

  useEffect(() => {
    console.log("!!!!Autorizando Operação de Crédito!!!!", props.data);
  }, []);

  function submit() {
    if (checked) {
      setAguarde(true);
      sendData({
        uri: uriList("informarAssinatura"),
        content: {
          uuid: props.data.proposta_uuid || props.data.uuid,
          dados: data,
          semCcb: true,
        },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          console.log("Confirmar assinatura:", res);
          toggleAccept();
          setAguarde(false);
          // setInterval(() => setAguarde(false), 50000);
        })
        .catch((e) => {
          console.log("autorizar operação de crédito(Erro): ", e);
          setAguarde(false);
          // setInterval(() => setAguarde(false), 50000);
        });
    }
  }

  return (
    <>
      {!getLocation && (
        <Geolocalizacao onCloseEv={() => setGetLocation(true)} />
      )}
      {getLocation && (
        <Modal
          size={width() !== "mobile" ? "lg" : "sm"}
          show={true}
          animation={true}
          // backdrop="static"
          centered={true}
        >
          <Modal.Header
            className="formsign-header-custom text-center pt-2 pb-2"
            style={{ fontSize: "25px" }}
          >
            <strong>Operação de Crédito</strong>
          </Modal.Header>
          <Modal.Body style={{ padding: "5px" }}>
            <div className="form" style={{ overflow: "hidden" }}>
              <div className="form-group row" style={{ marginBottom: "0px" }}>
                <div className="col-sm-12">
                  <h6
                    className="text-justify mb-1"
                    style={{
                      margin: "3px",
                      fontSize: width() == "mobile" ? "small" : "",
                    }}
                  >
                    Pagarei por esta operação, em moeda corrente nacional, ao
                    credor Banco Original S.A. (“Original”), CNPJ
                    92.894.922/0001-08, com sede na Rua Porto União, nº 295,
                    Brooklin Paulista, São Paulo/SP - CEP 04568-020, ou à sua
                    ordem, a quantia líquida, certa e exigível correspondente ao
                    Valor do Empréstimo acrescido dos Juros Remuneratórios,
                    capitalizados mensalmente, desde a data da liberação do
                    Empréstimo em minha Conta, dos tributos, atuais ou que
                    venham a incidir, de eventual seguro, se contratado e
                    financiado, e dos demais encargos devidos, conforme as
                    regras e condições estabelecidas nesta operação. Concordo
                    com o financiamento do Imposto Sobre Operações Financeiras
                    (IOF) e com a emissão de boletos bancários para pagamento
                    das parcelas mensais.
                  </h6>
                  <h5 style={{ textAlign: "center" }}>
                    Atenção, a assinatura da Proposta não é garantia de sua
                    aprovação
                  </h5>
                </div>
              </div>

              <div className="form-group row mt-1 mb-0">
                <div className="col-sm-12">
                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td text-center font-weight-bold"
                        style={{ width: "100%", backgroundColor: "lightgray" }}
                      >
                        DADOS PESSOAIS DO SOLICITANTE
                      </td>
                    </tr>
                  </table>

                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td"
                        style={{
                          width: "100%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Nome Completo: {data.nome}
                      </td>
                    </tr>
                  </table>

                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td"
                        style={{
                          width: "30%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        CPF: {data.cpf}
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "40%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Doc. Pessoal: {data.num_doc}
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "30%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Órgão Emissor: {data.orgao}
                      </td>
                    </tr>
                  </table>

                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td"
                        style={{
                          width: "78%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        End.: {data.endereco}
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "22%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Cep: {data.cep}
                      </td>
                    </tr>
                  </table>

                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td"
                        style={{
                          width: "40%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Bairro: {data.bairro}
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "40%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Cidade: {data.cidade}
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "20%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        UF: {data.uf}
                      </td>
                    </tr>
                  </table>

                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td"
                        style={{
                          width: "15%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Telefone(s)
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "42.5%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Fixo: {data.telefone}
                      </td>
                      <td
                        className="detailed-td"
                        style={{
                          width: "42.5%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Celular: {data.celular}
                      </td>
                    </tr>
                  </table>

                  <table
                    className="table c-table c-table-bordered"
                    style={{ marginBottom: "0px", borderCollapse: "separate" }}
                  >
                    <tr className="tr-custom" key="row1">
                      <td
                        className="detailed-td"
                        style={{
                          width: "100%",
                          fontSize: width() == "mobile" ? "xx-small" : "",
                        }}
                      >
                        Email: {data.email}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div
                className="form-group row"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div className="col-sm-12">
                  <div className="custom-control mt-2 custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      checked={checked}
                      onChange={(e) => setChecked(!checked)}
                      name="connected"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      <strong>Autorizar Contratação?</strong>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ">
                {aguarde ? (
                  <Aguarde />
                ) : (
                  <button
                    style={{ backgroundColor: "#3E6C84", width: "100%" }}
                    onClick={submit}
                    disabled={!checked || aguarde}
                    className="btn btn-md btn-info font-weight-bold"
                  >
                    Avançar
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ConfirmPropOrg;
