import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import style from './dashboard.module.css'


const RenderQuarterTick = (tickProps) => {
    const { x, y, payload, yearAndMidMonth } = tickProps
    const { value, offset } = payload

    const date = new Date(value)
    const month = date.getMonth()
    const year = date.getFullYear()

    if (yearAndMidMonth[0].midMonth === month) {
        return (
            <g>
                <text fill="#fff" x={x - 10} y={y + 10} textAnchor="middle" fontSize={10}>
                    {year}
                </text>
            </g>
        )
    }

    if (yearAndMidMonth.length > 1) {
        if (yearAndMidMonth[1].midMonth && yearAndMidMonth[1].midMonth === month) {
            return (
                <g>
                    <text fill="#fff" x={x - 10} y={y + 10} textAnchor="middle" fontSize={10}>
                        {year}
                    </text>
                </g>
            )
        }
    }

    return null
}

const YAxisRightTick = (props) => {
    const { x, y, stroke, payload } = props
    const value = payload.value

    return (
        <g transform={`translate(${x + 10},${y - 12})`}>
            <text
                x={-17}
                y={0}
                dy={16}
                textAnchor="center"
                fill="#fff"
                fontSize={10}
                fontWeight={700}
                alignmentBaseline="auto"
            >
                {value}
            </text>
            {value !== 'Dez' && <path d={`M-15,23 L${30},23`} stroke="#fff" strokeWidth="2" />}
        </g>
    )
}

export default function LineChartInfo({ data }) {
    const demoUrl = 'https://codesandbox.io/s/line-chart-connect-nulls-sqp96'

    let yearAndMidMonth = []

    // Encontra todos os anos únicos na matriz de dados
    const anosUnicos = [...new Set(data.map((item) => item.date.split('-')[0]))]

    // Função para calcular a data do meio de um ano
    function calcularDataMeio(ano) {
        const datasDoAno = data.filter((item) => item.date.startsWith(ano))
        const dataInicio = new Date(Math.min(...datasDoAno.map((item) => new Date(item.date))))
        const dataFim = new Date(Math.max(...datasDoAno.map((item) => new Date(item.date))))
        return new Date((dataInicio.getTime() + dataFim.getTime()) / 2)
    }

    // Encontra o mês correspondente a cada data do meio para cada ano
    anosUnicos.forEach((ano) => {
        const dataMeio = calcularDataMeio(ano)
        const mesMeio = dataMeio.getMonth()
        let obj = { year: Number(ano), midMonth: Number(mesMeio) }

        yearAndMidMonth.push(obj)
        // return { midMonth: mesMeio }
    })

    return (
        <div className={style.productionHistoryContainer}>
            <ResponsiveContainer width="100%">
                <LineChart
                    // title="Histórico De Produção"
                    width={500}
                    height={200}
                    data={data}
                    margin={{
                        top: 70,
                        right: 10,
                        left: 10,
                        bottom: 0,
                    }}
                >
                    <YAxis padding={{ top: 15, bottom: 15 }} hide />
                    <XAxis
                        xAxisId={0}
                        dataKey="month"
                        tick={YAxisRightTick}
                        interval={0}
                        tickLine={false}
                        axisLine={false}
                        stroke={'#000'}
                        mirror
                    />
                    <XAxis
                        type="category"
                        xAxisId={1}
                        dataKey="date"
                        tick={<RenderQuarterTick yearAndMidMonth={yearAndMidMonth} />}
                        allowDuplicatedCategory={false}
                        axisLine={false}
                        tickLine={false}

                        interval={0}
                    />

                    <Tooltip cursor={false} />

                    <Line
                        type="linear"
                        dataKey="gross"
                        dot={false}
                        strokeWidth={4}
                        stroke="#3E6C84"
                    />
                    <Line
                        type="linear"
                        dataKey="net"
                        dot={false}
                        strokeWidth={4}
                        stroke="#D9D9D9"
                    />

                    <text x="45" y="35" fontSize="18" fontWeight={600} fill="#fff">
                        Histórico de Produção
                    </text>

                    <svg>
                        <circle cx={100} cy={55} r={5} fill="#D9D9D9" />
                        <text x="110" y="60" fontSize="12" fontWeight={700} fill="#fff">
                            <rect></rect>
                            Total Líquido
                        </text>
                    </svg>
                    <svg>
                        <circle cx={100} cy={75} r={5} fill="#3E6C84" />
                        <text x="110" y="80" fontSize="12" fontWeight={700} fill="#FFF">
                            Total Bruto
                        </text>
                    </svg>
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}
