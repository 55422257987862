import { Collapse, } from 'reactstrap';
import React, { useState } from "react";
import { width } from "../../helpers/general";


function CardCollapse(props) {
    const [toggleQuestion, setToggleQuestion] = useState(props.isOpen ? 1 : 0)

    function handleToggle(area) {
        let collapse = toggleQuestion && !props.forceCollapse ? 0 : 1

        if (area != "body" || props.bodyToggle) {
            setToggleQuestion(collapse)

            if (props.setCollapse)
                props.setCollapse(!collapse)
        }
    }

    // e9ecef
    return (
        <>
            <div className={props.className} style={{ backgroundColor: "#D5DBE1", borderRadius: "5px" }} tytle={props.tip}>
                <div className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={handleToggle} tytle={props.tip}>
                    <h5 className='font-weight-bold mr-1'>{props.title}</h5>
                    {!props.forceCollapse && <h5><i className={`mdi ${toggleQuestion == 1 ? 'mdi-arrow-up-bold' : 'mdi-arrow-down-bold'}`} /></h5>}
                </div>
            </div>
            {/* <Collapse isOpen={toggleQuestion === 1 ? true : false} >
                <div style={{ overflowX: "auto", cursor: props.bodyToggle ? "pointer" : "" }}                    >
                    <div style={{
                        overflow: props.overflow || "hidden",
                        cursor: props.bodyToggle ? "pointer" : ""
                    }} >
                        {props.chield && <props.chield />}
                    </div>
                </div>
            </Collapse> */}
        </>
    )
}

export default CardCollapse;