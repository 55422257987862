import "./Card.css";
import React from "react";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { capitalize } from "../../../../helpers/general";
import { connect } from "react-redux";
import { useEffect } from 'react'

import Swal from "../../../swal";

const swalOptions = {
  title: "Deseja realmente excluir o Documento?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

const Card = ({ ...props }) => {
  console.log("Cards.show: ", props);
  const handleUpload = () => { };

  useEffect(() => {
    console.log(props.user?.tipo)
  }, []);

  const JSONObj = ({ ...props }) => {
    let pdf = props && props.url && props.url.indexOf(".pdf") > -1;
    return (
      <>
        {
          <div
            style={{
              height: "100%",
              width: "100%",
              cursor: "pointer",
              padding: "5px",
            }}
            className={`lead text-info text-left ${props.thisClass}`}
            title={
              props.url
                ? "Download: " +
                props.descricao +
                (props.titulo ? " - " + props.titulo : "")
                : "Clique para fazer upload"
            }
            onClick={() => {
              !props.url && handleUpload();
            }}
          >
            <span
              style={{ padding: "0 0", width: "100%", height: "100%" }}
              className="d-block"
            >
              <a href={props.url} target="_blank" download>
                <img
                  alt="..."
                  style={{ width: "100%", height: "100%", float: "left" }}
                  src={props.url}
                />
              </a>
            </span>
          </div>
        }
      </>
    );
  };

  const handleDelete = async (isConfirm, swal, id) => {
    if (isConfirm && props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <>
      <div className="Card" name={props.name}>
        <div className={`Title `}>
          <span
            style={{
              fontWeight: props?.normal ? "" : "bold",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {props.descricao?.slice(0, 15)?.toLowerCase()}
          </span>
          {['OPERADOR', 'ADMINISTRADOR'].indexOf(props.user?.tipo) >= 0 &&
            props.tipo != "simulacao" &&
            props.tipo != "termos-lgpd" &&
            props.tipo != "alteracao" &&
            props.tipo != "inss_in100" &&
            props.tipo != "autorizacaoport" &&
            props.tipo != "autorizacaoportpdf" &&
            (!props.detalhes?.enviada || ['OPERADOR', 'ADMINISTRADOR'].indexOf(props.user?.tipo) >= 0) &&
            (
              <div>
                <Swal
                  options={swalOptions}
                  id={props.id}
                  title="Exclusão de Registro"
                  callback={handleDelete}
                  className="btn btn-sm btn-danger"
                  style={{ marginLeft: "1px", cursor: "pointer" }}
                >
                  <i className="mdi mdi-delete"></i>
                </Swal>
              </div>
            )}
        </div>
        <JSONObj
          url={props.url}
          del={props.del}
          mobile={false}
          name="image"
          titulo={props.titulo}
          tipo={props.tipo}
          descricao={capitalize(props.descricao)}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(Card);
