import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { saveCookie } from "./general";
import { swalError } from "../components/swal";

let latitude, longitude;
const Geolocalizacao = (props) => {
  const [show, setShow] = useState(false);
  const [erro, setErro] = useState(false);

  function Error() {
    swalError({
      title: `Não foi possível obter a GeoLocalização`,
      text: "Verifique se o seu navegador está permitindo acesso ao GPS",
    });
    setErro(true);
  }

  async function _getLocation() {
    // console.log("gelocalização(1) ");
    setErro(false);
    if (navigator.geolocation) {
      // console.log("gelocalização(1.1) ");
      navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;

        if (longitude != 0) {
          saveCookie({
            label: "geolocation",
            content: [latitude, longitude],
          });
          setShow(true);
          setTimeout(() => handleClose(), 700);
        } else Error();
      }, Error);
    } else {
      console.log("position(1).error");
      Error();
    }

    return false;
  }

  async function handleClose() {
    if (props.onCloseEv) props.onCloseEv();
  }

  useEffect(() => {
    _getLocation();

    console.log("Geolocalizacao(useEffect)");
  });

  return (
    <div>
      <Modal
        size="md"
        show={true}
        onHide={handleClose}
        centered
        animation={true}
        backdrop="static"
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Modal.Title>
            <span>Geolocalização</span>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              padding: "10px",
            }}
          >
            {!erro && !show && (
              <span>
                <h3>Obtendo Geolocalização...</h3>
              </span>
            )}
            {erro && (
              <span style={{ fontWeight: "bold", color: "red" }}>
                <h2>Não foi possível obter a GeoLocalização</h2>
                <h4>Clique no botão abaixo novamente...</h4>
              </span>
            )}
            {show && (
              <span style={{ fontWeight: "bold", color: "green" }}>
                Latitude: {latitude} <br />
                longitude: {longitude}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            color="primary"
            onClick={_getLocation}
            style={{ width: "100px" }}
            disabled={show}
          >
            Obter
          </Button>
          {/* <Button
            color="secondary"
            onClick={handleClose}
            style={{ width: "100px" }}
          >
            Fechar
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Geolocalizacao;
