import React, { useState, useEffect, useCallback } from "react";

import Form from '../../components/form/admin/emails';

import { getData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import { DefaultOrder, sortByString, sortByNumber, compareDate } from '../../helpers/general';

function Emails({ ...props }) {
    const [emails, setEmails] = useState([]); 
    const [emailsC, setEmailsC] = useState([]);
    const [total, setTotal] = useState(false);
    const [actualPage, setActualPage] = useState(0);
    const [filterOptions, setFilterOptions] = useState(false);
    const [dateVisible, setDateVisible] = useState('todos');
    const [modal, setModal] = useState(false);
    const [clickedTr, setClickedTr] = useState("")
    const [ascDesc, setAscDesc] = useState(true);

    const handleOption = (e) => {
        let label = e.currentTarget.dataset.label;
        let value = e.currentTarget.value
        let newDetailed;

        if (value !== "todos") {

            newDetailed = emails.filter(
                (proposta) => proposta[label] === value
            );


            setEmails(newDetailed);
            setActualPage(0);
        } else {
            setEmails(emailsC);
            setActualPage(0);
        }
    };

    const handlePage = (e) => {
        setActualPage(e.currentTarget.value);
    };

    const createFilter = (obj) => {
        let newObj = {
            nome: [],
            sigla: [],
        };

        for (let o of obj) {
            for (let i in o) {
                if (newObj[i]) {
                    newObj[i].push(o[i]);
                }
            }
        }

        for (let i in newObj) {
            newObj[i] = [...new Set(newObj[i])];
        }

        setFilterOptions(newObj);
    };

    const _emails = useCallback(() => {
        getData({ uri: uriList("emails") })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();
                // setEmails(res.map((emails) => emails));
                DefaultOrder('emails', res.map((emails) => emails), setEmails)
                createFilter(res.map((emails) => emails))
                setEmailsC(res)
            })
            .catch((e) => {
                return false;
            });
    })

    useEffect(() => { 
        _emails()
    }, []);

    const sortArrObj = (key, type) => {
        let arr = [...emails];
        let asc = !ascDesc

        arr = arr.sort((a, b) => {
            if (type === 'string') {
                if (asc) {
                    setAscDesc(asc);
                    return sortByString(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByString(b, a, key, type);
                }
            } else if (type === 'numeric') {
                if (asc) {
                    setAscDesc(asc);
                    return sortByNumber(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByNumber(b, a, key, type);
                }
            } else {
                if (asc) {
                    setAscDesc(asc);
                    return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
                } else {
                    setAscDesc(asc);
                    return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
                }
            }
        });

        setEmails(arr);
    };

    return (
        <>
            <Form
                filterOptions={filterOptions}
                handleOption={handleOption}
                setDateVisible={setDateVisible}
                setModal={setModal}
                setClickedTr={setClickedTr}
                clickedTr={clickedTr}
                sortArrObj={sortArrObj}
                setEmails={setEmails}
                emails={emails}
                actualPage={actualPage}
                handlePage={handlePage}
                dateVisible={dateVisible}
                total={total}
                callback={_emails}
                context={props.context} />

            {modal}
        </>
    );
};

export default Emails;
