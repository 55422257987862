import React, { useState, useEffect } from "react";

import SimulatorLayout from "../components/layout";
import { connect } from "react-redux";
import {
  setUser,
  setAlterarProposta,
  setCliente,
  setProposta,
} from "../store/actions/dados";

import Data from "../views/simulator/data";
import Address from "../views/simulator/address";
import Contact from "../views/simulator/contact";
import Complement from "../views/simulator/complement";
import Bank from "../views/simulator/bank";
import Documents from "../views/simulator/documents";
import { sendData } from "../helpers/auth";
import { uriList } from "../helpers/config";
import Status from "./status";
import SimulacaoFgts from "../components/form/admin/Fgts";

function Fgts({ ...props }) {
  const [data, setData] = useState({});
  const [result, setResult] = useState({});
  const [enviando, setEnviando] = useState(false);
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 7,
  });

  useEffect(() => {
    if (!props.alterar) _iniciar();
    else {
      console.log("Fgts - detalhes: ", props.detalhes.posicao);
      if (["simulacao"].indexOf(props.detalhes.posicao.toLowerCase()) >= 0)
        setStep({ ...step, cur: 1 });
      else setStep({ ...step, cur: step.maxStep });
    }
  }, []);

  function _iniciar() {
    props.setProposta("limpar");
    props.setAlterarProposta(false, props.detalhes);
    props.alterarCliente(null);
  }

  const toggleStep = async (n, dados, result) => {
    console.log("Fgts - toggleStep: ", n);
    if (dados) setData(dados);
    if (result) setResult(result);
    if (!n || n === undefined) n = 1;
    let _next = step.cur + n;
    if (_next >= 0 && _next <= step.maxStep) setStep({ ...step, cur: _next });

    //-- Enviando proposta para o cliente
    if (step.cur >= step.maxStep) {
      if (props.user?.tipo == "AGENTE") return setStep({ ...step, cur: 0 });
      console.log(
        "Fgts.toggleStep - enviando proposta para o cliente"
      );
      setEnviando(true);

      await sendData({
        uri: uriList("enviarParaAssinatura"),
        content: { proposta_uuid: props.proposta || dados.uuid, sms: true },
        method: "POST",
      })
        .then((res) => {
          setEnviando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();
        })
        .catch((e) => {
          setEnviando(false);
        });

      if (props.buscarPropostas) {
        props.buscarPropostas();
      }

      return setStep({ ...step, cur: 0 });
    }
  };

  function reRender() {
    // setStep({ ...step, cur: -1 })
    setStep({ ...step, cur: 0 })
  }

  const RenderScreen = ({ ...props }) =>
  ({
    0: <SimulacaoFgts toggleStep={toggleStep} reRender={reRender} />,
    1: (
      <Data
        title="Dados Pessoais"
        toggleStep={toggleStep}
        admin={true}
        Fgts={true}
      />
    ),

    2: (
      <Address
        title="Endereço"
        toggleStep={toggleStep}
        admin={true}
        Fgts={true}
      />
    ),

    3: (
      <Contact
        title="Contato"
        toggleStep={toggleStep}
        admin={true}
        Fgts={true}
      />
    ),

    4: (
      <Bank
        title="Dados Bancários"
        toggleStep={toggleStep}
        admin={true}
        Fgts={true}
      />
    ),

    5: (
      <Complement
        title="Complemento"
        toggleStep={toggleStep}
        admin={true}
        Fgts={true}
      />
    ),

    6: (
      <Documents
        title="Documentos"
        toggleStep={toggleStep}
        admin={true}
        Fgts={true}
      />
    ),

    7: (
      <Status
        preencherCli={false}
        data={props.detalhes}
        Fgts={true}
        toggleStep={toggleStep}
        enviando={enviando}
      />
    ),
  }[props.step] || (
      <>
        <h1>404!</h1>
        <h4>Esse formulário: {props.step}não existe! </h4>
      </>
    ));

  return (
    <>
      {step.cur >= step.maxStep ? (
        <RenderScreen step={step.cur} />
      ) : (
        <SimulatorLayout admin={true}>
          <RenderScreen step={step.cur} />
        </SimulatorLayout>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Fgts);
