import React from "react";
import { useDashboardData } from './hooks/DashboardDataContext'
import { convertNumberWithMagnitude } from './convertNumbers'
import styles from './dashboard.module.css'



export default function AsideStatistic() {
    const { monthStatistic } = useDashboardData()

    return (
        <div className={styles.statisticContainer}>
            {/* <div className={styles.statisticContent}>
                <span className={styles.title}>Total de Produção</span>
                <span className={styles.value}>
                    {monthStatistic && convertNumberWithMagnitude(monthStatistic.totalProduction)}
                </span>
            </div> */}
            <div className={styles.statisticContent}>
                <span className={styles.title}> Qtd de Operações</span>
                <span className={styles.value}>
                    {monthStatistic && monthStatistic.operationQuantity}
                </span>
            </div>
            <div className={styles.statisticContent}>
                <span className={styles.title}>Ticket Médio</span>
                <span className={styles.value}>
                    {monthStatistic && convertNumberWithMagnitude(monthStatistic.averageTicket)}
                </span>
            </div>
            <div className={styles.statisticContent}>
                <span className={styles.title}>Taxa Média</span>
                <span className={styles.value}>
                    {monthStatistic && convertNumberWithMagnitude(monthStatistic.averageRate)} %
                </span>
            </div>
            <div className={styles.statisticContent}>
                <span className={styles.title}> Prazo Médio</span>
                <span className={styles.value}>
                    {monthStatistic && convertNumberWithMagnitude(monthStatistic.averageLeadTime)}
                </span>
            </div>
            <div className={styles.statisticContent}>
                <span className={styles.title}> Qtd de Clientes</span>
                <span className={styles.value}>
                    {monthStatistic && monthStatistic.customerQuantity}
                </span>
            </div>
        </div>
    )
}
