import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "../../layout";
import { formatCurrency, Aguarde, width } from "../../../helpers/general";
import { bancos } from "../../../helpers/bancos";
import { sendData, getData } from "../../../helpers/auth";
import { uriFst } from "../../../helpers/config";
import { bancosCNPJ } from "../../../helpers/bancos";
import Select from 'react-select'

const customStyles = {
  control: () => ({
    width: 250,
  }),
}

const Form = ({ ...props }) => {
  let _disabled =
    !props.portabilidade && (props.data.automatico || props.automatico);


  return (
    <>
      {props.simulacao && (
        <Aguarde legenda={"Aguarde, simulando proposta..."} />
      )}
      {!props.simulacao && props.carregando && (
        <Aguarde legenda={"Aguarde, carregando dados..."} />
      )}
      {!props.carregando && !props.simulacao && (
        <div className="form">
          {!props.portabilidade && (
            <>
              <div className="row">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Valor Total"
                    id="valor_total"
                    // cifrao={true}
                    disabled={true}
                    warning={props.warning.valor_total}
                    value={formatCurrency(props.detalhes.valor_total)}
                    msgErro={"É necessário informar o Valor Total"}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Prazo"
                    id="prazo"
                    disabled={true}
                    warning={props.warning.prazo}
                    value={props.detalhes.prazo}
                    msgErro={"É necessário informar o Prazo"}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Valor Parcela"
                    id="valor_parcelas"
                    // cifrao={true}
                    disabled={true}
                    warning={props.warning.valor_parcela}
                    value={formatCurrency(props.detalhes.valor_parcelas)}
                    msgErro={"É necessário informar o Valor da Parcela"}
                    focus={false}
                  />
                </div>
              </div>
              <hr />
            </>
          )}
          {props.portabilidade && (
            <>
              <div className="row">
                <div className="form-group col-md-4">
                  <InputLabelAlert
                    type="tel"
                    label="CPF"
                    id="cpf"
                    value={props.data.cpf}
                    handle={props.handleCpf}
                    warning={props.warning.cpf}
                    autoComplete="off"
                    length="14"
                    msgErro={"É necessário informar o CPF"}
                    focus={true}
                    onContextMenu={(e) => e.preventDefault()}
                  />

                  <InputAlert
                    display={props.msg && props.msg.cpf}
                    messages={"CPF inválido."}
                  />
                </div>
              </div>
              {props.portabilidade && (
                <>
                  <div
                    style={{
                      pointerEvents: "none",
                      opacity: "0.5",
                      background: "#CCC",
                      textAlign: "center",
                    }}
                  >
                    Dados do Novo Contrato
                  </div>
                  {/* <hr className="mt-1" /> */}

                  <div className="form-group">
                    <div className="row" style={{ position: "relative" }}>
                      <div className="form-group col-md-6">
                        <label htmlFor="convenio_id" className="no-margin">
                          Convênio
                        </label>
                        <select
                          defaultValue={props.data.convenio_id}
                          onChange={(e) =>
                            props.handleConvenio(e.currentTarget.value)
                          }
                          name="convenio_id"
                          id="convenio_id"
                          style={{
                            borderColor: props.warning.banco ? "red" : "",
                            textAlign: "left",
                          }}
                          className="form-control custom-select"
                        >
                          {!props.convenioList && <option>Carregando...</option>}
                          {props.convenioList ? (
                            props.convenioList.map((convenio, index) => (
                              <option
                                key={index}
                                title={convenio.sigla}
                                value={convenio.id}
                              >
                                {convenio.nome.toUpperCase()}
                              </option>
                            ))
                          ) : (
                            <option>Carregando...</option>
                          )}
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="tabela_id" className="no-margin">
                          Tabela
                        </label>
                        <select
                          defaultValue={props.data.tabela_id}
                          onChange={(e) =>
                            props.handleTabela(e.currentTarget.value)
                          }
                          name="tabela_id"
                          id="tabela_id"
                          style={{
                            borderColor: props.warning.banco ? "red" : "",
                            textAlign: "left",
                          }}
                          className="form-control custom-select"
                        >
                          {!props.tabelaList && <option>Carregando...</option>}
                          {props.tabelaList ? (
                            props.tabelaList.map((tabela, index) => (
                              <option
                                key={`tabela_id-${tabela.id}`}
                                title={tabela.nome}
                                value={tabela.id}
                              >
                                {tabela.nome}
                              </option>
                            ))
                          ) : (
                            <option>Carregando...</option>
                          )}
                        </select>
                        <InputAlert
                          display={props?.warning?.tabela_id}
                          messages={"É necessário informar a Tabela."}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-4">
                        <InputLabelAlert
                          type="tel"
                          label="Valor da Parcela"
                          id="valor_parcela"
                          // cifrao={true}
                          value={props.data.valor_parcela}
                          step="0.010"
                          handle={props.change}
                          warning={props.warning.valor_parcela}
                          onChange={props.change}
                          msgErro={"É necessário informar o Valor da Parcela"}
                          focus={false}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="prazo" className="no-margin">
                          Prazo
                        </label>
                        <select
                          id="prazo"
                          name="prazo"
                          defaultValue={props.data.prazo}
                          onChange={(e) => props.handlePrazo(e.currentTarget.value)}
                          style={{ textAlign: "left" }}
                          className="form-control custom-select"
                        >
                          {props.prazoSteps &&
                            props.prazoSteps.map((prazo, i) => (
                              <option
                                key={`${prazo.prazo}${i}}`}
                                value={prazo.prazo}
                              >
                                {prazo.prazo}
                              </option>
                            ))}
                        </select>
                        <InputAlert
                          display={props?.warning?.prazo}
                          messages={"É necessário informar o Prazo."}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group">
                  <hr />
                  <span className="text-center span-del d-block mt-4">
                    <h6>
                      "Os valores acima são estimados, podendo sofrer alterações
                      após a chegada do saldo devedor."
                    </h6>
                  </span>
                </div> */}
                </>
              )}
            </>
          )}
          {props.portabilidade &&
            <div
              style={{
                pointerEvents: "none",
                opacity: "0.5",
                background: "#CCC",
                textAlign: "center",
              }}
            >
              {"Dados da Portabilidade"}
            </div>
          }
          <div className="row">
            <div
              className={
                props.portabilidade
                  ? "form-group col-md-3"
                  : "form-group col-md-3"
              }
            >
              <InputLabelAlert
                type="tel"
                label={width() != "mobile" ? "Saldo Dev." : "Saldo Devedor"}
                id="saldo_devedor"
                title="Saldo Devedor do Contrato Portado"
                value={props.data.saldo_devedor}
                step="0.010"
                handle={props.change}
                onChange={props.data.saldo_devedor}
                warning={props.warning.saldo_devedor}
                onBlur={props.onBlur}
                disabled={_disabled}
                msgErro={"É necessário informar o Saldo Devedor"}
                focus={!props.portabilidade}
              />
            </div>
            {!props.portabilidade && (
              <div className="form-group col-md-3">
                <InputLabelAlert
                  type="tel"
                  label="Valor Líquido"
                  id="valor_liquido"
                  // cifrao={true}
                  value={props.data.valor_liquido}
                  step="0.010"
                  handle={props.change}
                  onChange={props.data.valor_liquido}
                  warning={props.warning.valor_liquido}
                  onBlur={props.onBlur}
                  disabled={_disabled}
                  msgErro={"É necessário informar o Valor Líquido"}
                  focus={false}
                />
              </div>
            )}

            <div
              className={
                props.portabilidade
                  ? "form-group col-md-3"
                  : "form-group col-md-3"
              }
            >
              <InputLabelAlert
                type="tel"
                label={
                  props.portabilidade ?
                    width() != "mobile" ? "P. Restantes" : "Parcelas Restantes" : width() != "mobile" ? "Qtd Parcelas" : "Quantidade de Parcelas"
                }
                id="parcelas_total"
                cifrao={false}
                value={props.data.parcelas_total}
                handle={props.change}
                onChange={props.data.parcelas_total}
                warning={props.warning.parcelas_total}
                onBlur={props.onBlur}
                disabled={_disabled}
                title={"Parcelas Restantes do Contrato Portado"}
                msgErro={`É necessário informar ${props.portabilidade
                  ? "as Parcelas Restantes"
                  : "a Quantidade de Parcelas"
                  }`}
                focus={false}
              />
            </div>
            {!props.portabilidade && (
              <div className={"form-group col-md-3"}>
                <InputLabelAlert
                  type="tel"
                  label="Parcela Original"
                  id="parcela_original"
                  // cifrao={true}
                  value={props.data.parcela_original}
                  step="0.010"
                  handle={props.change}
                  onChange={props.data.parcela_original}
                  warning={props.warning.parcela_original}
                  onBlur={props.onBlur}
                  disabled={_disabled}
                  msgErro={"É necessário informar a Parcela Original"}
                  focus={false}
                />
              </div>
            )}
            {props.portabilidade && (
              <>
                <div className="form-group col-md-6">
                  <label htmlFor="banco" className="no-margin">
                    Banco Portado
                  </label>
                  <Select
                    options={bancosCNPJ}
                    placeholder="Selecione um banco"
                    value={props.selectedBanco}
                    onChange={props.handleBanco}
                    isSearchable={true}
                  // styles={customStyles}
                  />
                  <InputAlert
                    display={props.warning.banco_portado}
                    messages={"É necessário informar o Banco Portado."}
                  />
                  {/* <select
                    id="banco_codigo"
                    name="banco_codigo"
                    value={props.data.banco_codigo}
                    onChange={props.handleBanco}
                    style={{
                      borderColor: props.warning.banco ? "red" : "",
                      textAlign: "left",
                    }}
                    className="form-control custom-select"
                    // className="form-control selectpicker"
                  >
                    {<option value="todos" selected={!props.data.banco_codigo}>Todos</option>}
                    {bancosCNPJ && bancosCNPJ.map((_banco, i) => (
                      <option key={`${_banco.ispb}${i}}`} value={_banco.codigo} selected={props.data.banco_codigo == _banco.codigo} >
                        {(_banco.nome + ' - ' + _banco.cnpj).slice(0, 60)}
                      </option>
                    ))}
                  </select> */}
                  {/* <select class="form-control selectpicker">
                    <option>Mustard</option>
                    <option>Ketchup</option>
                    <option>Barbecue</option>
                  </select> */}

                  {/* <InputLabelAlert
                  type="tel"
                  label="ISPB"
                  id="ispb"
                  value={props.data.ispb}
                  handle={props.change}
                  autoComplete="off"
                  onContextMenu={(e) => e.preventDefault()}
                /> */}
                </div>
                {!props.exibir_contratos_port &&
                  <>
                    <div className="form-group col-md-4">
                      <InputLabelAlert
                        type="tel"
                        label="Contrato Portado"
                        id="contrato_portado"
                        value={props.data.contrato_portado}
                        handle={props.handleChange}
                        warning={props.warning.contrato_portado}
                        msgErro={"É necessário informar o Saldo Devedor"}
                        focus={false}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <InputLabelAlert
                        type="tel"
                        label="Parcela Portada"
                        id="parcela_portada"
                        // cifrao={true}
                        value={props.data.parcela_portada}
                        handle={props.handleChange}
                        warning={props.warning.parcela_portada}
                        msgErro={"É necessário informar o Valor da Parcela Portada"}
                        focus={false}
                      />
                    </div>
                    {/* <div className="form-group col-md-3">
                      <InputLabelAlert
                        type="tel"
                        label="Orgao Portado"
                        id="orgao_portado"
                        value={props.data.orgao_portado}
                        handle={props.handleChange}
                        focus={false}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <InputLabelAlert
                        type="tel"
                        label="Convênio Portado"
                        id="convenio_portado"
                        value={props.data.convenio_portado}
                        handle={props.handleChange}
                        focus={false}
                      />
                    </div> */}
                  </>}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
