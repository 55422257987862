import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import Dropper from "../../../dropzone";
import { post } from "axios";
import { getCookie, getLocation, Aguarde } from "../../../../helpers/general";
import { uriList } from "../../../../helpers/config";
import { InputLabelAlert } from "../../../layout";
import { swalError } from "../../../swal";
import { sendMultiPartData } from "../../../../helpers/auth";

function Documents({ ...props }) {
  const [documento, setDocumento] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [dataArquivo, setDataArquivo] = useState(null);
  const [descricao, setDescricao] = useState("");

  useEffect(() => {
    getLocation();
  }, []);

  const handleClose = () => {
    if (props.onCloseEv) props.onCloseEv();
  };

  const Enviando = () => {
    return <Aguarde />;
  };

  const handleGravar = async () => {
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];

    if (documento[0]) {
      setEnviando(true);
      let dObj = new FormData();

      dObj.append("file", documento[0]);
      dObj.append("descricao", descricao);
      dObj.append("ip", getCookie({ label: "ip" }));
      dObj.append("data_arquivo", documento[0].lastModifiedDate);
      dObj.append("geolocation", JSON.stringify({ latitude, longitude }));

      await sendMultiPartData({
        uri: uriList("documentos-geral"),
        content: dObj,
        method: "POST",
      })
        .then((res) => {
          setEnviando(false);
          if (props.onCloseEv) props.onCloseEv(true);
        })
        .catch((e) => {
          setEnviando(false);
        });
    } else {
      swalError({ title: "É necessário anexar o documento." });
    }
  };

  const handleDelete = (e) => {
    setDocumento(false);
  };

  const handleChange = (e) => {
    setDocumento(e);
    setDataArquivo(e.lastModifiedDate);
  };

  function handleChangeDesc(e, innerData) {
    let target = e.currentTarget;
    let value = innerData || target.value;

    setDescricao(value?.toUpperCase());
  }

  return (
    <>
      <Modal
        size="md"
        show={true}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Upload de Documentos</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          {enviando ? (
            <Enviando />
          ) : (
            <div>
              <InputLabelAlert
                type="text"
                label="Descrição do Documento"
                id="descricao"
                length="200"
                // warning={props.warning.num_doc}
                value={descricao}
                handle={handleChangeDesc}
                focus={false}
              />

              <br />
              <Dropper
                name="documento"
                title={"Download de documento"}
                data={documento}
                change={handleChange}
                del={handleDelete}
                disabled={false}
                accept={"*/*"}
                field="documento"
                telaAux="upload"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handleGravar}>
            {" "}
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
  };
}

export default connect(mapStateToProps)(Documents);
